<template>
  <v-card
    elevation="10"
    data-v-step="26"
    :min-height="!isMobile ? 740 : undefined"
  >
    <AppSkeleton :condition="loadingNotification" type="notificationProperties">
      <template v-slot:skeleton-content>
        <div
          v-if="!isMobile"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 740px;
          "
        >
          <div>
            <v-card-title>
              <v-row align="center">
                <h3 class="primary--text">
                  <v-icon x-large class="primary--text">
                    mdi-identifier
                  </v-icon>
                  {{ notification.id }}
                </h3>
                <v-spacer />
                <AlertDialog
                  data-v-step="23"
                  v-if="!isSharedAccess"
                  :asset="notification"
                  objectType="notifications"
                  :isDisabled="
                    isOperatorUser && !$store.state.user?.processNotification
                  "
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isAdminUser && !isColectivoUser"
                      elevation="1"
                      fab
                      small
                      class="secondary--text ma-1"
                      v-bind="attrs"
                      v-on="on"
                      id="list-operators"
                      :disabled="loadingData"
                      :color="operatorsListFlag ? 'warning' : 'primary'"
                      @click.stop="switchOperatorsListFlag()"
                    >
                      <v-icon> mdi-account-group </v-icon>
                    </v-btn>
                  </template>
                  <div>
                    {{ $t('notificationProperties.showOperators') }}
                  </div>
                </v-tooltip>

                <NotificationMetadata
                  v-if="notification.metadatas && !isColectivoUser"
                  :notification="notification"
                />
              </v-row>
            </v-card-title>
            <v-list dense data-v-step="21">
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.status') }}:
                </v-list-item-content>
                <v-chip
                  medium
                  class="white--text"
                  :color="getNotificationStatus(notification).color"
                >
                  <span class="headline">
                    {{ $t(getNotificationStatus(notification).status) }}
                  </span>
                </v-chip>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.concept') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end headline">
                  {{ notification.concepto }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!isSharedAccess">
                <v-list-item-content data-v-step="24" class="title">
                  {{ $t('notificationProperties.manager') }}:
                </v-list-item-content>

                <v-list-item-content
                  class="align-end headline"
                  v-if="!isSharedAccess"
                >
                  <template
                    v-if="
                      notification.managerAccepted === false &&
                      notification.manager
                    "
                  >
                    <v-col
                      v-if="notification.manager.id !== $store.state.user?.id"
                    >
                      <v-row>
                        <span class="body-1 text--secondary">
                          {{ $t('waitManager') }}
                        </span>
                        <span
                          v-if="notification.manager.disabled"
                          class="body-1 text--secondary"
                        >
                          {{ $t('userDisabled') }}
                        </span>
                      </v-row>
                      <v-row align="center">
                        <AppAvatar
                          class="ml-2"
                          :userName="`${notification.manager?.name} ${notification.manager?.surname}`"
                          :userAvatar="notification.manager?.avatarURL"
                          avatarSize="30"
                          iconSize="24"
                          avatarColor="primary"
                          initialsColor="secondary"
                          :overlapIconColor="
                            notification.manager.disabled ? 'error' : 'warning'
                          "
                          :showWarn="
                            notification.manager.disabled ||
                            !notification.managerAccepted
                          "
                        />
                        <span class="ml-1 body-1 text--secondary">
                          {{
                            `${notification.manager?.name} ${notification.manager?.surname}`
                          }}
                        </span>
                        <v-btn
                          v-if="
                            !isOperatorUser ||
                            $store.state.user?.processNotification
                          "
                          icon
                          @click="removeManager(notification.id)"
                          id="remove-manager"
                        >
                          <v-icon> mdi-window-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-row v-else>
                      <v-col cols="8">
                        <span class="body-1">
                          {{ $t('acceptResponsibility') }}
                        </span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end">
                        <v-btn
                          class="mx-3"
                          color="primary"
                          icon
                          plain
                          @click="() => acceptNotification(notification)"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                          class="mx-3"
                          color="error"
                          icon
                          plain
                          @click="() => declineNotification(notification)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>

                  <AppSkeleton
                    v-else-if="
                      !isOperatorUser || $store.state.user?.processNotification
                    "
                    :condition="loadingOperators"
                    type="assignManager"
                  >
                    <template v-slot:skeleton-content>
                      <v-select
                        v-model="selectedOperator"
                        @change="assignManager()"
                        prepend-icon="mdi-account"
                        :items="filteredPosibleManagers"
                        item-value="id"
                        solo
                        clearable
                        :label="$t('assignManager')"
                        id="assign-manager"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                data-v-step="104"
                                class="my-0 py-0"
                                v-model="managerSearch"
                                clearable
                                prepend-icon="mdi-magnify"
                                :label="$t('searchField')"
                                single-line
                                hide-details
                              >
                              </v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template slot="selection" slot-scope="data">
                          {{ `${data.item.name} ${data.item.surname}` }}
                          <AppAvatar
                            class="ml-2"
                            :userName="`${data.item.name} ${data.item.surname}`"
                            :userAvatar="data.item.avatarURL"
                            avatarSize="30"
                            iconSize="24"
                            avatarColor="primary"
                            initialsColor="secondary"
                          />
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          <AppAvatar
                            class="mr-3"
                            :userName="`${data.item.name} ${data.item.surname}`"
                            :userAvatar="data.item.avatarURL"
                            avatarSize="30"
                            iconSize="24"
                            avatarColor="primary"
                            initialsColor="secondary"
                            :id="
                              `${data.item.name} ${data.item.surname}` ==
                              `${$store.state.user.name} ${$store.state.user.surname}`
                                ? 'admin-manager'
                                : 'operator-manager'
                            "
                          />
                          {{ `${data.item.name} ${data.item.surname}` }}
                        </template>
                      </v-select>
                    </template>
                  </AppSkeleton>
                  <template v-else>
                    <v-list-item-content
                      class="align-end subtitle-1"
                      v-if="notification.manager"
                    >
                      <span class="font-weight-black">
                        {{
                          `${notification.manager?.name} ${notification.manager?.surname}`
                        }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-content class="align-end subtitle-1" v-else>
                      <span class="font-weight-black">
                        {{ $t('noManagerAssigned') }}
                      </span>
                    </v-list-item-content>
                  </template>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.receiver') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <span>{{ notification.nombre_titular }} </span>
                  <span class="font-weight-black">
                    {{ notification.nif_titular }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="notification.origen !== 'MANUAL'">
                <v-list-item-content class="title">
                  {{
                    notification.origen === 'SEGSOCIAL'
                      ? $t('notificationProperties.idSegSocial')
                      : $t('notificationProperties.idDehu')
                  }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <span>{{ notification.identificador }} </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.notificationType') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end headline">
                  <span>
                    <v-icon medium color="primary">
                      {{ getNotificationType(notification).icon }}
                    </v-icon>
                    {{ $t(getNotificationType(notification).name) }}
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="docLink !== ''">
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.notificationDocLink') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end headline">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <a :href="docLink" target="_blank">{{
                          $t('notificationProperties.notificationDocLinkBtn')
                        }}</a>
                      </span>
                    </template>
                    <div>{{ docLink }}</div>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.orgSender') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <span class="font-weight-black">{{
                    notification.codigo_organismo_emisor
                  }}</span>
                  {{ notification.nombre_organismo_emisor }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item data-v-step="22">
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.orgSenderSource') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <span class="font-weight-black">
                    {{ notification.codigo_organismo_emisor_raiz }}
                  </span>
                  <span> {{ notification.nombre_organismo_emisor_raiz }} </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="notification.fecha_puesta_disposicion">
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.acceptSent') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(
                      new Date(notification.fecha_puesta_disposicion),
                      'i18nDateAndHourSeconds'
                    )
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="notification.fecha_aceptacion">
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.acceptDate') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(
                      new Date(notification.fecha_aceptacion),
                      'i18nDateAndHourSeconds'
                    )
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="
                  notification.fecha_expiracion &&
                  notification.fecha_expiracion < new Date()
                "
              >
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.expirationDate') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(
                      new Date(notification.fecha_expiracion),
                      'i18nDateAndHourSeconds'
                    )
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="
                  notification.fecha_expiracion &&
                  notification.fecha_expiracion >= new Date()
                "
              >
                <v-list-item-content class="title">
                  {{ $t('notificationProperties.remaining') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <TimerCountdown
                    v-if="notification.fecha_puesta_disposicion"
                    :starttime="notification.fecha_puesta_disposicion"
                    :endtime="notification.fecha_expiracion"
                    hideMessage="true"
                  >
                  </TimerCountdown>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="
                  !isSharedAccess &&
                  (!isOperatorUser || $store.state.user?.processNotification)
                "
              >
                <v-list-item-content class="title">
                  {{ $t('observation') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <v-row no-gutters align="center">
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        v-model="observation"
                        :rows="observation.length < 80 ? 2 : 6"
                        :class="[
                          'mb-0',
                          observation.length < 80 ? 'auto-grow' : '',
                        ]"
                        :value="notification.observation"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        (notification.observation &&
                          observation !== notification.observation) ||
                        (!notification.observation && observation)
                      "
                    >
                      <v-btn
                        block
                        class="mt-n7"
                        color="primary"
                        @click.stop="confirmObservation()"
                      >
                        {{ $t('saveObservation') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-content class="title">
                  {{ $t('observation') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{ notification.observation }}
                </v-list-item-content>
              </v-list-item>

              <v-progress-linear
                v-if="loadingData"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
            </v-list>
          </div>
          <v-card-actions v-if="notification && !isSharedAccess">
            <v-dialog
              v-if="!notification.document && !notification.isComparecida"
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2 secondary--text"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingData"
                  id="open-notif"
                >
                  {{ $t('openNotification') }}
                  <v-icon right dark> mdi-lock-open-variant-outline </v-icon>
                </v-btn>
              </template>

              <v-card
                v-if="
                  !isOperatorUser || $store.state.user?.downloadNotification
                "
              >
                <v-card-title class="text-h5 grey lighten-2">
                  <v-container>
                    <v-row justify="space-between" align="center">
                      <span>
                        {{ $t('confirmAcceptanceTitle') }}
                      </span>
                      <v-btn fab @click="dialog = false" small plain>
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-card-title>
                <v-card-text>
                  {{ $t('confirmAcceptanceText') }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="confirmAcceptNotification()"
                    color="primary"
                    text
                    id="confirm-open-notif"
                  >
                    {{ $t('confirmAcceptance') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else>
                <v-card-title class="text-h5 grey lighten-2">
                  {{ $t('noDownloadPermissionTitle') }}
                </v-card-title>
                <v-card-text>
                  {{ $t('noDownloadPermissionText') }}
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
            <v-btn
              v-if="
                !notification.document &&
                notification.isComparecida &&
                userCanAttach
              "
              @click="addDocsDialog = true"
              color="primary"
              class="ma-2 secondary--text"
              id="attach-files"
            >
              <v-icon class="mr-2" dark>
                mdi-file-document-plus-outline
              </v-icon>
              {{ $t('attachFiles') }}
            </v-btn>
            <v-btn
              v-if="userCanChangeStatus"
              @click="changeStatusDialog = true"
              color="primary"
              class="ma-2 secondary--text"
              id="change-status"
            >
              <v-icon class="mr-2" dark> mdi-swap-horizontal </v-icon>
              {{ $t('changeStatus') }}
            </v-btn>

            <v-dialog v-model="shareDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-v-step="25"
                  color="primary"
                  class="ma-2 secondary--text"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingData"
                  id="share-notif-button"
                >
                  <v-icon right dark> mdi-share </v-icon>
                  {{ $t('notificationProperties.share') }}
                </v-btn>
              </template>

              <v-card
                v-if="!isOperatorUser || $store.state.user?.processNotification"
              >
                <v-card-title class="text-h5 grey lighten-2">
                  {{ $t('confirmShareTitle') }}
                </v-card-title>
                <v-tabs v-model="tab" fixed-tabs>
                  <v-tab id="share-via-link-tab">{{
                    $t('shareViaLink')
                  }}</v-tab>
                  <v-tab id="share-via-email-tab">{{
                    $t('shareViaEmail')
                  }}</v-tab>
                </v-tabs>
                <v-form
                  ref="shareForm"
                  v-model="validShareForm"
                  lazy-validation
                  v-if="tab === 0"
                >
                  <v-container>
                    <v-card-text>
                      {{ $t('confirmShareTextLink') }}
                    </v-card-text>
                    <v-text-field
                      v-model="shareIdentifier"
                      :counter="100"
                      :label="$t('shareIdentifier')"
                      required
                      :rules="shareIdentifierRules"
                      id="share-identifier"
                    ></v-text-field>
                  </v-container>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="shareNotification('link')"
                      :disabled="loadingData"
                      color="primary"
                      text
                      id="confirm-share-link-notif"
                    >
                      {{ $t('confirmShareAndCopy') }}
                      <v-icon class="ml-1" small> mdi-content-copy </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-form>
                <v-form
                  ref="shareForm"
                  v-model="validShareForm"
                  lazy-validation
                  v-if="tab === 1"
                >
                  <v-container>
                    <v-card-text class="mb-0 pb-0">
                      {{ $t('confirmShareText') }}
                    </v-card-text>
                    <!-- 1844 -->
                    <v-row class="mt-0 pt-0">
                      <v-col align="right" class="mt-0 pt-0">
                        <v-btn
                          @click="getCompanyEmail()"
                          :disabled="loadingData"
                          color="primary"
                          id="add-email-company-button"
                        >
                          {{ $t('addEmailCompany') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- hasta aqui -->
                    <v-row align="center">
                      <v-col cols="12">
                        <v-text-field
                          v-model="shareEmail"
                          :label="$t('email')"
                          required
                          id="share-email-address"
                          @keydown="checkKeyPress"
                        ></v-text-field>
                        <v-chip
                          v-for="email in emailList"
                          :key="email"
                          close
                          @click:close="removeEmail(email)"
                        >
                          {{ email }}
                        </v-chip>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="messageText"
                          :counter="200"
                          :label="$t('message')"
                          id="share-email-text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="shareNotification('email')"
                      :disabled="loadingData"
                      color="primary"
                      text
                      id="confirm-share-notif"
                    >
                      {{ $t('confirmShareEmail') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
              <v-card v-else>
                <v-card-title class="text-h5 grey lighten-2">
                  {{ $t('noProcessPermissionTitle') }}
                </v-card-title>
                <v-card-text>
                  {{ $t('noProcessPermissionText') }}
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </div>

        <!-- Version Movil -->
        <div v-else>
          <v-card-title>
            <v-col cols="12" align="center">
              <h3 class="primary--text">
                <v-icon x-large class="primary--text">mdi-identifier</v-icon
                >{{ notification.id }}
              </h3>
            </v-col>

            <v-row class="mt-3 d-flex justify-center">
              <v-col align="right">
                <AlertDialog
                  v-if="!isSharedAccess"
                  :asset="notification"
                  objectType="notifications"
                  :isDisabled="
                    isOperatorUser && !$store.state.user?.processNotification
                  "
                />
              </v-col>
            </v-row>

            <v-row class="mt-3 d-flex justify-center">
              <v-col align="left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isAdminUser && !isColectivoUser"
                      elevation="1"
                      fab
                      small
                      class="secondary--text ma-1"
                      v-bind="attrs"
                      v-on="on"
                      id="list-operators"
                      :disabled="loadingData"
                      :color="operatorsListFlag ? 'warning' : 'primary'"
                      @click.stop="switchOperatorsListFlag()"
                    >
                      <v-icon> mdi-account-group </v-icon>
                    </v-btn>
                  </template>
                  <div>
                    {{ $t('notificationProperties.showOperators') }}
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-list dense>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" no-gutters>
                  <v-row align="center" no-gutters>
                    <v-col cols="6" no-gutters>
                      <span class="title">
                        {{ $t('notificationProperties.status') }}:
                      </span>
                    </v-col>
                    <v-col cols="6" align="end">
                      <v-chip
                        medium
                        class="white--text"
                        :color="getNotificationStatus(notification).color"
                      >
                        <span>
                          {{ $t(getNotificationStatus(notification).status) }}
                        </span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  <v-list-item-title class="title mr-4">
                    {{ $t('notificationProperties.notificationType') }}:
                  </v-list-item-title>
                </v-col>
                <v-col cols="6" align="end">
                  <v-icon medium color="primary">
                    {{ getNotificationType(notification).icon }}
                  </v-icon>
                  <span class="ml-1 body-1">
                    {{ $t(getNotificationType(notification).name) }}
                  </span>
                </v-col>
              </v-row>

              <!-- Concepto -->
              <v-row no-gutters class="mt-3">
                <v-col no-gutters>
                  <v-list-item-title class="title">
                    {{ $t('notificationProperties.concept') }}:
                  </v-list-item-title>
                  <span class="align-end">
                    {{ notification.concepto }}
                  </span>
                </v-col>
              </v-row>

              <!-- Campo para asignar responsable -->
              <v-row no-gutters class="mt-3" v-if="!isSharedAccess">
                <v-col no-gutters>
                  <span class="title">
                    {{ $t('notificationProperties.manager') }}:
                  </span>
                  <template v-if="!isSharedAccess">
                    <template
                      v-if="
                        notification.managerAccepted === false &&
                        notification.manager
                      "
                    >
                      <v-col
                        v-if="notification.manager.id !== $store.state.user?.id"
                      >
                        <v-row>
                          <span class="body-1 text--secondary">
                            {{ $t('waitManager') }}
                          </span>
                        </v-row>
                        <v-row align="center">
                          <AppAvatar
                            class="ml-2"
                            :userName="`${notification.manager?.name} ${notification.manager?.surname}`"
                            :userAvatar="notification.manager?.avatarURL"
                            avatarSize="30"
                            iconSize="24"
                            avatarColor="primary"
                            initialsColor="secondary"
                          />
                          <span class="ml-1 body-1 text--secondary">
                            {{
                              `${notification.manager?.name} ${notification.manager?.surname}`
                            }}
                          </span>
                          <v-btn
                            v-if="
                              !isOperatorUser ||
                              $store.state.user?.processNotification
                            "
                            icon
                            @click="removeManager()"
                            id="remove-manager"
                          >
                            <v-icon> mdi-window-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                      <v-row v-else>
                        <v-col cols="8">
                          <span class="body-1">
                            {{ $t('acceptResponsibility') }}
                          </span>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-end">
                          <v-btn
                            class="px-0"
                            color="primary"
                            icon
                            plain
                            @click="() => acceptNotification(notification)"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                          <v-btn
                            class="px-0"
                            color="error"
                            icon
                            plain
                            @click="() => declineNotification(notification)"
                            id="remove-manager"
                          >
                            <v-icon>mdi-window-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <AppSkeleton
                      v-else-if="
                        !isOperatorUser ||
                        $store.state.user?.processNotification
                      "
                      :condition="loadingOperators"
                      type="assignManager"
                    >
                      <template v-slot:skeleton-content>
                        <v-select
                          v-model="selectedOperator"
                          @change="assignManager()"
                          prepend-icon="mdi-account"
                          :items="posibleManagers"
                          item-value="id"
                          solo
                          clearable
                          :label="$t('assignManager')"
                        >
                          <template slot="selection" slot-scope="data">
                            <span>
                              {{ `${data.item.name} ${data.item.surname}` }}
                            </span>
                            <AppAvatar
                              class="ml-2"
                              :userName="`${data.item.name} ${data.item.surname}`"
                              :userAvatar="data.item.avatarURL"
                              avatarSize="30"
                              iconSize="24"
                              avatarColor="primary"
                              initialsColor="secondary"
                            />
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            <AppAvatar
                              class="mr-3"
                              :userName="`${data.item.name} ${data.item.surname}`"
                              :userAvatar="data.item.avatarURL"
                              avatarSize="30"
                              iconSize="24"
                              avatarColor="primary"
                              initialsColor="secondary"
                            />
                            {{ `${data.item.name} ${data.item.surname}` }}
                          </template>
                        </v-select>
                      </template>
                    </AppSkeleton>
                    <template v-else>
                      <v-list-item-content
                        class="align-end subtitle-1"
                        v-if="notification.manager"
                      >
                        {{
                          `${notification.manager?.name} ${notification.manager?.surname}`
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end subtitle-1" v-else>
                        {{ $t('noManagerAssigned') }}
                      </v-list-item-content>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-container>

            <!-- Elementos desplegables -->
            <v-list-group append-icon="mdi-menu-down" fluid>
              <!-- Botón para desplegar la lista -->
              <template v-slot:activator>
                <v-row no-gutters>
                  <v-list-item-title class="title">
                    {{ $t('moreInfo') }}...
                  </v-list-item-title>
                </v-row>
              </template>

              <!-- Campo NIF -->
              <v-list-item>
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title mb-1">
                      {{ $t('notificationProperties.receiver') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      <span>{{ notification.nombre_titular }} </span>
                      <span class="font-weight-black">
                        {{ notification.nif_titular }}
                      </span>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- Campo ID Externo -->
              <v-list-item v-if="notification.origen !== 'MANUAL'">
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title mb-1">
                      {{
                        notification.origen === 'SEGSOCIAL'
                          ? $t('notificationProperties.idSegSocial')
                          : $t('notificationProperties.idDehu')
                      }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      <span>{{ notification.identificador }} </span>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- Campo organismo emisor -->
              <v-list-item>
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title mb-1">
                      {{ $t('notificationProperties.orgSender') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      <span class="font-weight-black">
                        {{ notification.codigo_organismo_emisor }}
                      </span>
                      {{ notification.nombre_organismo_emisor }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- Campo organismo emisor raíz -->
              <v-list-item>
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title mb-1">
                      {{ $t('notificationProperties.orgSenderSource') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      <span class="font-weight-black"
                        >{{ notification.codigo_organismo_emisor_raiz }}
                      </span>
                      <span
                        >{{ notification.nombre_organismo_emisor_raiz }}
                      </span>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- Campo fecha puesta de disposición -->
              <v-list-item v-if="notification.fecha_puesta_disposicion">
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title">
                      {{ $t('notificationProperties.acceptSent') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      {{
                        $d(
                          new Date(notification.fecha_puesta_disposicion),
                          'i18nDateAndHourSeconds'
                        )
                      }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- Campo fecha aceptación -->
              <v-list-item v-if="notification.fecha_aceptacion">
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title">
                      {{ $t('notificationProperties.acceptDate') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      {{
                        $d(
                          new Date(notification.fecha_aceptacion),
                          'i18nDateAndHourSeconds'
                        )
                      }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                v-else-if="
                  notification.fecha_expiracion &&
                  notification.fecha_expiracion < new Date()
                "
              >
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title">
                      {{ $t('notificationProperties.expirationDate') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      {{
                        $d(
                          new Date(notification.fecha_expiracion),
                          'i18nDateAndHourSeconds'
                        )
                      }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                v-else-if="
                  notification.fecha_expiracion &&
                  notification.fecha_expiracion >= new Date()
                "
              >
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title">
                      {{ $t('notificationProperties.remaining') }}:
                    </span>
                    <v-list-item-content class="align-end subtitle-1">
                      <TimerCountdown
                        v-if="notification.fecha_puesta_disposicion"
                        :starttime="notification.fecha_puesta_disposicion"
                        :endtime="
                          new Date(
                            notification.fecha_puesta_disposicion
                          ).setDate(
                            new Date(
                              notification.fecha_puesta_disposicion
                            ).getDate() + 10
                          )
                        "
                        hideMessage="true"
                      >
                      </TimerCountdown>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                v-if="
                  !isSharedAccess &&
                  (!isOperatorUser || $store.state.user?.processNotification)
                "
              >
                <v-container>
                  <v-row class="mb-2" justify="space-between" align="center">
                    <v-list-item-content class="title">
                      {{ $t('observation') }}:
                    </v-list-item-content>
                  </v-row>
                  <v-row class="mt-2" justify="space-between" align="center">
                    <v-list-item-content class="align-end subtitle-1">
                      <v-row no-gutters align="center">
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            v-model="observation"
                            :rows="observation.length < 80 ? 4 : 6"
                            :class="[
                              'mb-0',
                              observation.length < 80 ? 'auto-grow' : '',
                            ]"
                            :value="notification.observation"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="
                            (notification.observation &&
                              observation !== notification.observation) ||
                            (!notification.observation && observation)
                          "
                        >
                          <v-btn
                            block
                            class="mt-n7"
                            color="primary"
                            @click.stop="confirmObservation()"
                          >
                            {{ $t('saveObservation') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-row>
                </v-container>
              </v-list-item>
              <v-list-item v-else>
                <v-row no-gutters class="mt-2">
                  <v-col no-gutters>
                    <span class="title"> {{ $t('observation') }}: </span>
                    <v-list-item-content class="align-end subtitle-1">
                      {{ notification.observation }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-progress-linear v-if="loadingData" indeterminate color="primary">
          </v-progress-linear>

          <!-- Dialog para abrir notificaciones -->
          <v-dialog
            v-if="!notification.document && !notification.isComparecida"
            v-model="dialog"
            width="320"
          >
            <v-card
              v-if="!isOperatorUser || $store.state.user?.downloadNotification"
            >
              <v-card-title class="text-h5 grey lighten-2">
                <v-container>
                  <v-row justify="space-between" align="center">
                    <span>
                      {{ $t('confirmAcceptanceTitle') }}
                    </span>
                    <v-btn fab @click="dialog = false" small plain>
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card-title>
              <v-card-text>
                {{ $t('confirmAcceptanceText') }}
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="confirmAcceptNotification()"
                  color="primary"
                  text
                >
                  {{ $t('confirmAcceptance') }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title class="text-h5 grey lighten-2">
                {{ $t('noDownloadPermissionTitle') }}
              </v-card-title>
              <v-card-text>
                {{ $t('noDownloadPermissionText') }}
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
          <v-dialog v-model="shareDialog" width="500">
            <v-card
              v-if="!isOperatorUser || $store.state.user?.processNotification"
            >
              <v-tabs v-model="tab" fixed-tabs>
                <v-tab>{{ $t('link') }}</v-tab>
                <v-tab>{{ $t('email') }}</v-tab>
              </v-tabs>
              <v-form
                ref="shareForm"
                v-model="validShareForm"
                lazy-validation
                v-if="tab === 0"
              >
                <v-container>
                  <v-card-text>
                    {{ $t('confirmShareTextLink') }}
                  </v-card-text>
                  <v-text-field
                    v-model="shareIdentifier"
                    :counter="100"
                    :label="$t('shareIdentifier')"
                    required
                    :rules="shareIdentifierRules"
                    id="share-identifier"
                  ></v-text-field>
                </v-container>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="shareNotification('link')"
                    :disabled="loadingData"
                    color="primary"
                    text
                    id="confirm-share-link-notif"
                  >
                    {{ $t('generateLink') }}
                    <v-icon class="ml-1" small> mdi-content-copy </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-form
                ref="shareForm"
                v-model="validShareForm"
                lazy-validation
                v-if="tab === 1"
              >
                <v-container>
                  <v-card-text>
                    {{ $t('confirmShareText') }}
                  </v-card-text>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="shareEmail"
                        :label="$t('email')"
                        required
                        id="share-email"
                        @keydown="checkKeyPress"
                      ></v-text-field>
                      <v-chip
                        v-for="email in emailList"
                        :key="email"
                        close
                        @click:close="removeEmail(email)"
                      >
                        {{ email }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="messageText"
                        :counter="200"
                        :label="$t('message')"
                        id="share-email-text"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="shareNotification('email')"
                    :disabled="loadingData"
                    color="primary"
                    text
                    id="confirm-share-notif"
                  >
                    {{ $t('confirmShareEmail') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
            <v-card v-else>
              <v-card-title class="text-h5 grey lighten-2">
                {{ $t('noProcessPermissionTitle') }}
              </v-card-title>
              <v-card-text>
                {{ $t('noProcessPermissionText') }}
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>

          <!-- Botones abrir, compartir y adjuntar archivos -->
          <v-card-actions v-if="notification && !isSharedAccess" class="p-0">
            <v-row no-gutters justify="center" align="center">
              <v-btn
                v-if="!notification.document && !notification.isComparecida"
                color="primary"
                class="ma-2 secondary--text"
                @click="dialog = true"
              >
                {{ $t('openNotification') }}
                <v-icon right dark> mdi-lock-open-variant-outline </v-icon>
              </v-btn>
              <v-btn
                v-if="
                  !notification.document &&
                  notification.isComparecida &&
                  userCanAttach
                "
                @click="addDocsDialog = true"
                color="primary"
                class="ma-2 secondary--text"
              >
                <v-icon dark> mdi-file-document-plus-outline </v-icon>
                {{ $t('attachFiles') }}
              </v-btn>
              <v-btn
                v-if="userCanChangeStatus"
                @click="changeStatusDialog = true"
                color="primary"
                class="ma-2 secondary--text"
              >
                <v-icon dark> mdi-swap-horizontal </v-icon>
                {{ $t('changeStatus') }}
              </v-btn>
              <v-btn
                @click="shareDialog = true"
                color="blue-grey"
                class="my-4"
                icon
              >
                <v-icon dark> mdi-share </v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </template>
    </AppSkeleton>
    <AddDocsDialog
      :notification="notification"
      @closeAddDocsDialog="closeAddDocsDialog"
      v-if="addDocsDialog && userCanAttach"
    />
    <ChangeStatusDialog
      :notification="notification"
      @closeChangeStatusDialog="closeChangeStatusDialog"
      v-if="changeStatusDialog && userCanChangeStatus"
    />
    <OperatorsGridModal
      :operators="operators"
      :showDialog="operatorsListFlag"
      :title="$t('notificationProperties.operatorsTitle')"
      @dialog-closed="closeGrid"
      v-if="operatorsListFlag"
    />
  </v-card>
</template>

<script>
import {
  getNotificationStatus,
  getNotificationType,
  acceptNotification,
  synchronizeNotification,
  assignManager,
  possibleManagers,
  managerAcceptNotification,
  managerDeclineNotification,
  updateObservation,
  operatorsCanViewNotification,
} from '@/services/notification-service'
import {
  createShareCode,
  createShareCodeLink,
} from '@/services/share-codes-service'
import { mapMutations } from 'vuex'
import rules from '@/utils/rules'
import { getUserOperators } from '@/services/operator-service'
import AlertDialog from '@/components/alert/AlertDialog'
import { mapGetters } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import TimerCountdown from '@/components/commons/TimerCountdown'
import { createTrace } from '@/services/trace-service'
import { getUser } from '@/services/user-service'
import { validateCompanyByCif } from '@/services/user-service'
import AddDocsDialog from './AddDocsDialog.vue'
import ChangeStatusDialog from './ChangeStatusDialog.vue'
import NotificationMetadata from './NotificationMetadata.vue'
import OperatorsGridModal from '@/components/operators/OperatorsGridModal'

export default {
  props: {
    notification: Object,
    loadingNotification: Boolean,
    isSharedAccess: Boolean,
    shared: Array,
  },
  components: {
    AlertDialog,
    TimerCountdown,
    AddDocsDialog,
    ChangeStatusDialog,
    NotificationMetadata,
    OperatorsGridModal,
  },
  watch: {
    async notification(newValue) {
      if (newValue) {
        this.observation = this.notification.observation
          ? this.notification.observation
          : ''
        if (!this.isSharedAccess) {
          // Si es operador pasamos el parent
          const parentUserId = this.isOperatorUser
            ? this.$store.state.user.parentRef.id
            : this.$store.state.user.id
          const parentUser = await getUser(parentUserId)

          const operators = await getUserOperators(parentUserId)
          this.posibleManagers = await possibleManagers(
            operators,
            newValue,
            parentUser
          )
          this.loadingOperators = false
        }
        if (!this.refreshed) {
          await this.refreshIfNeeded()
          this.refreshed = true
        }
        if (newValue.document && newValue.document['enlace_documento']) {
          this.docLink = newValue.document['enlace_documento']
        }
      }
      this.getManager()
    },
  },
  async mounted() {
    this.getManager()
    await this.refreshIfNeeded()
  },
  data() {
    return {
      selectedOperator: {},
      loadingData: false,
      posibleManagers: [],
      emailList: [],
      dialog: false,
      shareDialog: false,
      addDocsDialog: false,
      changeStatusDialog: false,
      loadingOperators: true,
      shareEmail: '',
      shareIdentifier: '',
      validShareForm: false,
      messageText: '',
      tab: 0,
      observation: '',
      editingObservation: false,
      shareIdentifierRules: [() => this.shareIdentifier !== ''],
      emailRules: [rules.required, rules.email],
      operatorsListFlag: false,
      usersOperators: [],
      notificationsWithOperators: [],
      managerSearch: '',
      refreshed: false,
      docLink: '',
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isAdminUser',
      'isOperatorUser',
      'isColectivoUser',
    ]),
    filteredPosibleManagers() {
      if (!this.posibleManagers.length) return
      return this.posibleManagers.filter((manager) => {
        // String de búsqueda en mayúscula
        const searchCap = this.managerSearch
          ? this.managerSearch.toUpperCase()
          : ''
        const { name, surname } = manager

        // Guardamos los elementos en mayúscula
        let nameCap = name?.toUpperCase()
        let surnameCap = surname?.toUpperCase()
        // Filtramos
        //console.log(searchCap + ' on ' + nameCap + ' ' + surnameCap)
        if (nameCap?.includes(searchCap) || surnameCap?.includes(searchCap))
          return manager
      })
    },
    userCanAttach() {
      /*
      Desde que existe DEHUC, no permitimos por defecto subir una notificación comparecida ya que puede dar conflicto
      de archivos al obtenerse por el DEHUC posteriormente (principalmente si coincide la subida con el trabajo del job)
      Pero para casos de incidencias que los ficheros no se han obtenidos (principalmente enlaces extraños), para permitir
      que se agreguen manualmente, habría que establecer a mano a la notificación a true los flag isComparecida y allowedAttach
      */
      return (
        (!this.isOperatorUser ||
          this.$store.state.user.processNotification ||
          this.$store.state.user.downloadNotification) &&
        this.notification.allowedAttach
      )
    },
    userCanChangeStatus() {
      return (
        this.notification.document &&
        (this.isAdminUser ||
          this.$store.state.user?.processNotification ||
          (!this.isAdminUser &&
            this.notification.manager &&
            this.notification.manager.id === this.$store.state.user?.id))
      )
    },
    operators() {
      return this.notificationsWithOperators[0]?.operators
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setSnackbar']),
    checkKeyPress(event) {
      if (event.key === ' ' || event.key === ',' || event.key === 'Enter') {
        event.preventDefault()
        return this.addEmail()
      }
    },
    addEmail() {
      const email = this.shareEmail.trim()
      if (email.length > 0 && !this.emailList.includes(email)) {
        if (this.validateAddress(email)) this.emailList.push(email)
        else {
          const message = this.$t('invalidEmail')
          this.setSnackbar({ position: 'top', type: 'error', message })
          return false
        }
      }
      this.shareEmail = ''
      return true
    },
    removeEmail(email) {
      const index = this.emailList.indexOf(email)
      if (index !== -1) {
        this.emailList.splice(index, 1)
      }
    },
    async confirmObservation() {
      if (this.notification.observation !== this.observation) {
        await updateObservation(this.notification.id, this.observation)

        const message = this.$t('observationUpdatedSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
      }

      this.editingObservation = false
    },
    closeAddDocsDialog() {
      this.addDocsDialog = false
    },
    closeChangeStatusDialog() {
      this.changeStatusDialog = false
    },
    async copyURL(linkID) {
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id
      try {
        await navigator.clipboard.writeText(
          window.location.origin +
            '/sharedNotification/' +
            userId +
            '/' +
            this.notification.id +
            '/' +
            linkID
        )

        const message = this.$t('sharedLinkClipboardSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('sharedLinkClipboardError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
    getManager() {
      this.selectedOperator = this.notification.manager
        ? this.notification.manager.id
        : {}
    },
    async assignManager() {
      await assignManager(
        this.notification.id,
        this.selectedOperator,
        this.selectedOperator === this.$store.state.user.id
      )
      // TODO: Saved in case we need to track notification MANAGER
      // await createTrace(this.$store.state.user.id, {
      //   actionType: 'assignManager',
      //   manager: this.selectedOperator,
      //   createdAt: new Date(),
      //   notificationId: this.notification.id,
      // })
    },
    async removeManager() {
      await assignManager(this.notification.id)
      this.selectedOperator = {}

      // TODO: Saved in case we need to track notification MANAGER
      // await createTrace(this.$store.state.user.id, {
      //   actionType: 'removedManager',
      //   manager: this.selectedOperator,
      //   createdAt: new Date(),
      //   notificationId: this.notification.id,
      // })
    },
    async shareNotification(type) {
      if (!this.$refs.shareForm?.validate()) {
        return
      }
      try {
        if (type === 'email') {
          if (this.emailList.length === 0 && !this.shareEmail) return
          // Check previo de validacion de correos electronicos

          // Añadimos en cualquier caso el email que esta escrito en el v-text-field,
          // sin tener que escribir coma y espacio
          this.emailList =
            this.emailList.length === 0
              ? this.shareEmail
                ? [this.shareEmail]
                : []
              : [...this.emailList, this.shareEmail].filter(Boolean)

          // Se realiza una segunda comprobacion de mails válidos
          const allEmailsValid = this.emailList.every((address) =>
            this.validateAddress(address)
          )

          if (!allEmailsValid) {
            const message = this.$t('invalidEmail')
            this.setSnackbar({ position: 'top', type: 'error', message })
            this.emailList = []
            this.shareEmail = ''
            return
          }
        }
        this.shareDialog = false
        this.loadingData = true

        const sharedCode =
          type == 'link'
            ? await createShareCodeLink(
                this.notification.id,
                this.shareIdentifier
              )
            : await createShareCode(
                this.notification.id,
                this.emailList,
                this.messageText
              )
        //TODO: Saved in case we need to track notification SHARING
        // const sharedCode = ''
        // type == 'link'
        //   ? await createTrace(this.$store.state.user.id, {
        //     actionType: 'createdShareLink',
        //     createdAt: new Date(),
        //     notificationId: this.notification.id,
        //   })
        //   : await createTrace(this.$store.state.user.id, {
        //     actionType: 'createdShareCode',
        //     createdAt: new Date(),
        //     notificationId: this.notification.id,
        //   })

        this.$refs.shareForm?.reset()
        this.loadingData = false
        this.emailList = []
        this.shareEmail = ''
        // Copy to clipboard
        if (type == 'link') this.copyURL(sharedCode.id)

        const message = this.$t('sharedNotificationSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = this.$t('sharedNotificationError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    validateAddress(address) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return regex.test(address)
    },
    async confirmAcceptNotification() {
      try {
        this.dialog = false
        this.loadingData = true
        await acceptNotification(this.notification)
        await createTrace(this.$store.state.user.id, {
          actionType: 'openNotification',
          createdAt: new Date(),
          notificationId: this.notification.id,
          notificationConcept: this.notification.concepto || '',
        })
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          timeout: '20000',
          position: 'top',
          type: 'warning',
          message,
        })
      }
    },
    async acceptNotification(notification) {
      await managerAcceptNotification(notification.id)
    },
    async declineNotification(notificacion) {
      await managerDeclineNotification(notificacion.id)
    },
    async switchOperatorsListFlag() {
      if (!this.operatorsListFlag) {
        this.loadingData = true
        this.usersOperators = await getUserOperators()

        const auxNotifications = [this.notification]
        this.notificationsWithOperators = await operatorsCanViewNotification(
          this.usersOperators,
          auxNotifications
        )

        await new Promise((r) => setTimeout(r, 100))
        this.loadingData = false
      }
      this.operatorsListFlag = !this.operatorsListFlag
    },
    async getCompanyEmail() {
      this.loadingData = true
      // Si es operador pasamos el parent
      const parentUserId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id
      const response = await validateCompanyByCif(
        parentUserId,
        this.notification.nif_titular
      )
      //console.log('validateCompanyByCif() response: ', response)
      this.shareEmail =
        response && response.email !== undefined ? response.email : ''
      this.loadingData = false
      if (this.shareEmail !== '') {
        this.addEmail()
      } else {
        const message = this.$t('companyEmailEmpty')
        this.setSnackbar({ position: 'top', type: 'warning', message })
      }
    },
    closeGrid() {
      this.operatorsListFlag = false
    },
    async refreshIfNeeded() {
      if (this.notification.estado === 'Aceptado') {
        if (this.notification?.document) {
          await synchronizeNotification(this.notification)
        } else {
          await acceptNotification(this.notification)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
