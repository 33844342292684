import { render, staticRenderFns } from "./ResumeCard.vue?vue&type=template&id=46e7b86e"
import script from "./ResumeCard.vue?vue&type=script&lang=js"
export * from "./ResumeCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports