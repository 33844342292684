<template>
  <v-autocomplete
    @input="setGroups"
    dense
    v-model="selectedGroups"
    :items="loadedGroups"
    color="primary"
    :filter="customFilter"
    :no-data-text="$t('emptyGroup')"
    :label="$t('selectGroups')"
    item-value="id"
    item-text="name"
    :disabled="disabled || loading"
    prepend-icon="mdi-account-group"
    :menu-props="{ bottom: true, offsetY: true }"
    id="groups-input-autocomplete"
    :rules="rules"
    :multiple="multiple"
    clearable
  >
    <template v-slot:item="{ item }">
      <span :id="'group-operator-option-' + item.name">
        {{ item.name }}
      </span>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip small class="ma-1" :disabled="disabled">
        {{ item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>
<script>
import { listGroups, listUserGroups } from '@/services/groups-service'
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useUserGroups: {
      type: Boolean,
      default: false,
    },
    objectGroups: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedGroups: undefined,
      loadedGroups: [],
    }
  },
  async created() {
    if (this.groups.length === 0) {
      this.loading = true
      this.loadedGroups = this.useUserGroups
        ? await listUserGroups()
        : await listGroups()
      this.loading = false
    } else this.loadedGroups = this.groups

    if (this.objectGroups.length !== 0)
      this.selectedGroups = this.multiple
        ? this.objectGroups
        : this.objectGroups[0]
  },
  methods: {
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilter(item, queryText) {
      const name = item.name
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText)
    },
    /**
     * Sets the groups in the parent component
     * @param {Array} groups Groups ids to set.
     */
    setGroups(groups) {
      this.$emit('setGroups', groups)
    },
  },
}
</script>
