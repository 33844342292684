<template>
  <v-dialog :value="true" persistent max-width="900">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center" class="my-2">
          <h2>{{ $t('attachFiles') }}</h2>
          <DialogButton section="notifications" subsection="uploadFiles" />
        </v-row>
      </v-card-title>
      <v-card-text class="body-1">
        <v-col class="black--text">
          <p class="ma-0 mt-2">
            <b>1.</b> {{ $t('uploadDocs1') }}
            <a href="https://dehu.redsara.es/login" target="_blank">
              {{ $t('link') }}
            </a>
          </p>
          <v-row no-gutters align="center">
            <p class="ma-0 mt-2">
              <b>2.</b> {{ $t('uploadDocs2') }}
              <b>{{ notification.identificador }}</b>
            </p>
            <v-btn
              class="ma-0 mt-2"
              @click="copyId"
              color="primary"
              text
              id="confirm-share-link-notif"
            >
              {{ $t('copyId') }}
              <v-icon class="ml-1" small> mdi-content-copy </v-icon>
            </v-btn>
          </v-row>
          <p class="ma-0 mt-2"><b>3.</b> {{ $t('uploadDocs3') }}</p>
          <p class="ma-0 mt-2"><b>4.</b> {{ $t('uploadDocs4') }}</p>
          <p class="ma-0 mt-2"><b>5.</b> {{ $t('uploadDocs5') }}</p>
          <p class="ma-0 mt-2"><b>6.</b> {{ $t('uploadDocs6') }}</p>
          <v-form ref="docsForm" v-model="validDocsForm" lazy-validation>
            <v-col>
              <v-file-input
                accept="application/pdf"
                chips
                multiple
                :label="$t('mainDocument')"
                prepend-icon="mdi-file-document-outline"
                v-model="mainDoc"
                :placeholder="$t('attachMainDoc')"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip close @click:close="deleteChip(index, 'mainDoc')">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-file-input
                v-if="getNotificationType(notification).name == 'notification'"
                accept="application/pdf"
                chips
                :label="$t('notificationProperties.acuse')"
                prepend-icon="mdi-receipt-text-check-outline"
                v-model="acuseDoc"
                :placeholder="$t('attachAcuse')"
              />
              <v-file-input
                chips
                multiple
                :label="$t('annexes')"
                prepend-icon="mdi-file-multiple"
                v-model="annexesDoc"
                :placeholder="$t('attachAnnexes')"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip close @click:close="deleteChip(index, 'annexesDoc')">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fecha"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha"
                    :label="$t('notificationProperties.acceptDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[notEmpty]"
                    id="add-doc-date"
                    persistent-hint
                    :hint="$t('acceptDateHint')"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  @click:date="$refs.menu.save(fecha)"
                  v-model="fecha"
                  :locale="$t('javascriptLocale')"
                  :min="
                    notification.fecha_puesta_disposicion
                      .toISOString()
                      .substring(0, 10)
                  "
                  :max="new Date().toISOString().substring(0, 10)"
                  no-title
                  scrollable
                  :rules="dateRules"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-form>
        </v-col>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          width="150px"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          width="150px"
          :disabled="loading"
          :loading="loading"
          @click="performAction"
          id="confirm-upload-files"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  getNotificationType,
  uploadComparecidaFiles,
} from '@/services/notification-service'
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import DialogButton from '@/components/documentation/buttons/DialogButton'

export default {
  props: {
    notification: Object,
  },
  components: {
    DialogButton,
  },
  data() {
    return {
      mainDoc: undefined,
      acuseDoc: undefined,
      annexesDoc: undefined,
      mainDocB64: [],
      acuseDocB64: [],
      annexesDocB64: [],
      fecha: '',
      menu: false,
      validDocsForm: false,
      loading: false,
      dateRules: [() => this.fecha !== ''],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
    closeDialog() {
      this.$emit('closeAddDocsDialog')
      this.$refs.docsForm?.reset()
      this.$refs.docsForm?.resetValidation()
      this.loading = false
    },
    deleteChip(index, key) {
      this[key].splice(index, 1)
      this[key + 'B64'].splice(index, 1)
    },
    async copyId() {
      try {
        await navigator.clipboard.writeText(this.notification.identificador)
        const message = this.$t('notificationIdClipboardSuccessful')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('notificationIdClipboardError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    async performAction() {
      if (!this.$refs.docsForm?.validate() || !this.userCanAttach) return
      this.loading = true

      try {
        await uploadComparecidaFiles(
          this.notification.id,
          this.mainDocB64,
          this.acuseDocB64,
          this.annexesDocB64,
          this.fecha
        )

        const message = this.$t('filesUploaded')
        this.setSnackbar({ position: 'top', type: 'success', message })

        this.closeDialog()
        this.loading = false
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
        this.loading = false
      }
    },
    severalToBase64(files, key) {
      for (const file of files) this.toBase64(file, key)
    },
    toBase64(file, key) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const fileInfo = {
          name: file.name,
          size: file.size,
          mimeType: file.type,
          base64: e.target.result.split(',')[1],
        }
        this[key].push(fileInfo)
      }
      reader.readAsDataURL(file)
    },
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    documentsUploaded: function () {
      return this.mainDoc !== undefined && this.fecha !== ''
    },
    userCanAttach() {
      return (
        !this.isOperatorUser ||
        this.$store.state.user.processNotification ||
        this.$store.state.user.downloadNotification
      )
    },
  },
  watch: {
    mainDoc: function (newValue) {
      this.mainDocB64 = []
      this.severalToBase64(newValue, 'mainDocB64')
    },
    annexesDoc: function (newValue) {
      this.annexesDocB64 = []
      this.severalToBase64(newValue, 'annexesDocB64')
    },
    acuseDoc: function (newValue) {
      this.acuseDocB64 = []
      this.toBase64(newValue, 'acuseDocB64')
    },
  },
}
</script>
