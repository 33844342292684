import store from '@/store/index'
import moment from 'moment'
import { listAllNotifications } from '@/services/notification-service'

const fakeData = false

const cifs = {
  G38900569: 'Bar las tapas',
  W2675552J: 'Cervezas Cibeles',
  N2057946B: 'Comunidad La Luz',
  S4029792A: 'Jugueterias ilusiones',
  G57608143: 'Mariscos Recio',
  W0465421F: 'Planet Express',
  W2139044H: 'Chocolates Wonka',
  V41635962: 'Regalos Polo Norte',
  W2779868E: 'Cerveza Duff',
  H17901117: 'Empresas Orcorp',
  B42464156: 'Stark Industries',
  B71963201: 'Vought',
  F26795864: 'ACME',
  P1032155B: 'Daily Planet',
  B50611847: 'Tecnicos de Iinvestigacion Aeroterraquea',
  P7782813E: 'Pollos hermanos',
  W3056685E: 'Umbrella Corporation',
  B20188181: 'Banco de hierro',
  C29100104: 'Invernalia',
  U52040326: 'Vault-Tec Corporation',
  J06742316: 'Wayne Enterprises',
  H12297966: 'Sirius Cybernetics Corp',
}

const operators = {
  ID1: 'Enrique Pastor',
  ID2: 'Maite Figeroa',
  ID3: 'Amador Rivas',
  ID4: 'Javier Maroto',
  ID5: 'Fermin Trujillo',
  ID6: 'Antonio Recio',
  ID7: 'Rosario Parrales',
  ID8: 'Agustin Gordillo',
}

const rootEmitter = {
  ABCDEF001: 'inventado',
  E00130201: 'inventado', // 'dgt'
  E03100601: 'dgt',
  E03099201: 'dgt',
  E03099301: 'dgt',
  E03099401: 'dgt',
  E03099501: 'dgt',
  E03102501: 'dgt',
  E03099601: 'dgt',
  E03099701: 'dgt',
  E03099801: 'dgt',
  E03099901: 'dgt',
  E03104001: 'dgt',
  E03100001: 'dgt',
  E03100101: 'dgt',
  E03100201: 'dgt',
  E03103101: 'dgt',
  E03100301: 'dgt',
  E03100401: 'dgt',
  E03100501: 'dgt',
  E03100701: 'dgt',
  E03100801: 'dgt',
  E03100901: 'dgt',
  E03101001: 'dgt',
  E03101101: 'dgt',
  E03101201: 'dgt',
  E03101301: 'dgt',
  E03101401: 'dgt',
  E03101701: 'dgt',
  E03102701: 'dgt',
  E03101501: 'dgt',
  E03101601: 'dgt',
  E03101801: 'dgt',
  E03101901: 'dgt',
  E03102001: 'dgt',
  E03102101: 'dgt',
  E03102301: 'dgt',
  E03102401: 'dgt',
  E03102601: 'dgt',
  E03102801: 'dgt',
  E03102901: 'dgt',
  E03103201: 'dgt',
  E03103301: 'dgt',
  E03103401: 'dgt',
  E03103501: 'dgt',
  E03103001: 'dgt',
  E03103601: 'dgt',
  E03103701: 'dgt',
  E03103801: 'dgt',
  E03103901: 'dgt',
  E03104101: 'dgt',
  E03104201: 'dgt',
  E03102201: 'dgt',
  E04724101: 'dgt',
  E04670601: 'dgt',
  E04957101: 'dgt',
  E04752101: 'dgt',
  E03104301: 'dgt',
  E04981701: 'dgt',
  E04724201: 'dgt',
  E04670301: 'dgt',
  E04670401: 'dgt',
  E04670801: 'dgt',
  E04670501: 'dgt',
  E03104401: 'dgt',
  E04670701: 'dgt',
  E04670901: 'dgt',
  E04671001: 'dgt',
  E04738701: 'dgt',
  E04671101: 'dgt',
  E04753204: 'dgt',
  E03098701: 'dgt',
  E04847303: 'dgt',
  E04632002: 'dgt',
  EA0008923: 'dgt',
  EA0008924: 'dgt',
  EA0019644: 'dgt',
  EA0008925: 'dgt',
  EA0008927: 'dgt',
  E04847402: 'dgt',
  EA0008928: 'dgt',
  EA0009426: 'dgt',
  E04889801: 'dgt',
  EA0009418: 'dgt',
  EA0008926: 'dgt',
  EA0003335: 'dgt',
  EA0008863: 'dgt',
  EA0043267: 'dgt',
  EA0043268: 'dgt',
  E05024001: 'aeat',
  E05034001: 'aeat',
  EA0028025: 'aeat',
  EA0028031: 'aeat',
  EA0028037: 'aeat',
  EA0028045: 'aeat',
  EA0028051: 'aeat',
  EA0028056: 'aeat',
  EA0028062: 'aeat',
  EA0028068: 'aeat',
  EA0028076: 'aeat',
  EA0028082: 'aeat',
  EA0028087: 'aeat',
  EA0028093: 'aeat',
  EA0028098: 'aeat',
  EA0028102: 'aeat',
  EA0028107: 'aeat',
  EA0028116: 'aeat',
  EA0028120: 'aeat',
  EA0028123: 'aeat',
  EA0028126: 'aeat',
  EA0028130: 'aeat',
  EA0028134: 'aeat',
  EA0028138: 'aeat',
  EA0028142: 'aeat',
  EA0028146: 'aeat',
  EA0028150: 'aeat',
  EA0028157: 'aeat',
  EA0028161: 'aeat',
  EA0028165: 'aeat',
  EA0028171: 'aeat',
  EA0028175: 'aeat',
  EA0028179: 'aeat',
  EA0028182: 'aeat',
  EA0028186: 'aeat',
  EA0028190: 'aeat',
  EA0028194: 'aeat',
  EA0028198: 'aeat',
  EA0028202: 'aeat',
  EA0028206: 'aeat',
  EA0028210: 'aeat',
  EA0028214: 'aeat',
  EA0028218: 'aeat',
  EA0028222: 'aeat',
  EA0028227: 'aeat',
  EA0028233: 'aeat',
  EA0028237: 'aeat',
  EA0028245: 'aeat',
  EA0028249: 'aeat',
  EA0028253: 'aeat',
  EA0028257: 'aeat',
  EA0028261: 'aeat',
  EA0028265: 'aeat',
  EA0028269: 'aeat',
  A01034449: 'aeat',
  A04013592: 'aeat',
  A04013624: 'aeat',
  A04026912: 'aeat',
  A04027070: 'aeat',
  A06029660: 'aeat',
  A06029663: 'aeat',
  A14028282: 'aeat',
  A14028296: 'aeat',
  A14028316: 'aeat',
  LA0011101: 'aeat',
  LA0009516: 'aeat',
  L01280604: 'aeat',
  LA0000751: 'aeat',
  LA0007435: 'aeat',
  LA0007889: 'aeat',
  L01300336: 'aeat',
  LA0013018: 'aeat',
  LA0000499: 'aeat',
  LA0004956: 'aeat',
  LA0004976: 'aeat',
  L02000014: 'aeat',
  L02000018: 'aeat',
  LA0011140: 'aeat',
  L02000027: 'aeat',
  LA0004287: 'aeat',
  L02000049: 'aeat',
  LA0015240: 'aeat',
  LA0000408: 'aeat',
  LA0016943: 'aeat',
  LA0016948: 'aeat',
  A05032840: 'aeat',
  A10017552: 'aeat',
  A10017581: 'aeat',
  A10017582: 'aeat',
  A10025574: 'aeat',
  A10025575: 'aeat',
  A10025576: 'aeat',
  A10025578: 'aeat',
  A10033115: 'aeat',
  A10033321: 'aeat',
  E05188501: 'aeat',
  EA0028512: 'aeat',
  LA0006136: 'aeat',
  LA0015187: 'aeat',
  A01002820: 'aeat',
  A04003003: 'aeat',
  A06002853: 'aeat',
  A14002961: 'aeat',
  L01090597: 'aeat',
  L01280066: 'aeat',
  L01280587: 'aeat',
  L01280796: 'aeat',
  L01300308: 'aeat',
  L01380380: 'aeat',
  L02000002: 'aeat',
  L02000003: 'aeat',
  L02000013: 'aeat',
  L02000016: 'aeat',
  L02000021: 'aeat',
  L02000024: 'aeat',
  L02000036: 'aeat',
  L02000047: 'aeat',
  L03070008: 'aeat',
  L03350004: 'aeat',
  L03380011: 'aeat',
  A05003638: 'aeat',
  A10002983: 'aeat',
  E04990101: 'aeat',
  E00003901: 'justice',
  E00130701: 'justice',
  E00131304: 'justice',
  E00131405: 'justice',
  E04613801: 'justice',
  E05039303: 'justice',
  E05077001: 'justice',
  EA0041029: 'justice',
  EA0041447: 'justice',
  EA0041513: 'justice',
  EA0041712: 'justice',
  EA0041713: 'justice',
  A04013546: 'justice',
  A04022933: 'justice',
  A04027016: 'justice',
  A06004049: 'justice',
  A06004050: 'justice',
  A06029659: 'justice',
  A06029665: 'justice',
  A06029670: 'justice',
  A14013868: 'justice',
  A14028285: 'justice',
  LA0014676: 'justice',
  L02000022: 'justice',
  LA0000561: 'justice',
  LA0000965: 'justice',
  I00000123: 'justice',
  I00000124: 'justice',
  I00001149: 'justice',
  I00001150: 'justice',
  I00000182: 'justice',
  I00000832: 'justice',
  A04022055: 'justice',
  A04022386: 'justice',
  A06004210: 'justice',
  A10017557: 'justice',
  A10017611: 'justice',
  A10017612: 'justice',
  A10017783: 'justice',
  A10017785: 'justice',
  A10017786: 'justice',
  A10017787: 'justice',
  A10023426: 'justice',
  A10023427: 'justice',
  A10023428: 'justice',
  A10025294: 'justice',
  A10025295: 'justice',
  E04820002: 'justice',
  EA0041034: 'justice',
  I00002120: 'justice',
  A10023429: 'justice',
  A10037038: 'justice',
  EA0022471: 'justice',
  EA0043294: 'justice',
  EA0041039: 'justice',
  EA0023121: 'justice',
  A05003250: 'justice',
  EA0041052: 'justice',
  A01004360: 'justice',
  A06004044: 'justice',
  EA0042233: 'justice',
  EA0041065: 'justice',
  A10023436: 'justice',
  A10037054: 'justice',
  J99999999: 'justice',
  EA0023039: 'justice',
  EA0041082: 'justice',
  EA0041066: 'justice',
  A10023440: 'justice',
  A10039574: 'justice',
  A01025636: 'justice',
  E04799402: 'justice',
  A06029668: 'justice',
  A05033188: 'justice',
  A10039575: 'justice',
  EA0042917: 'justice',
  A10037040: 'justice',
  A06029664: 'justice',
  EA0041038: 'justice',
  E05072501: 'ss',
  EA0042298: 'ss',
  E05066501: 'ss',
  E00003301: 'otherMinistries',
  E00003601: 'otherMinistries',
  E00003801: 'otherMinistries',
  E05024801: 'otherMinistries',
  E05024101: 'otherMinistries',
  E05024401: 'otherMinistries',
  E05023901: 'otherMinistries',
  E05024301: 'otherMinistries',
  E05068901: 'otherMinistries',
  E05067101: 'otherMinistries',
  E05065601: 'otherMinistries',
  E05071601: 'otherMinistries',
  E05068001: 'otherMinistries',
  E05071301: 'otherMinistries',
  E05070101: 'otherMinistries',
  E05070401: 'otherMinistries',
  E05072201: 'otherMinistries',
  E05073401: 'otherMinistries',
  E05189101: 'otherMinistries',
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}
const baseNotification = {
  id: '',
  nombre_organismo_emisor_raiz: 'Administración de Justicia',
  lastActionByRef: {
    converter: null,
    _key: {
      path: {
        segments: [
          'projects',
          'goodok-69b27',
          'databases',
          '(default)',
          'documents',
          'users',
          'jOaTePgwvxR55mXm9xybKMKRdKJ2',
        ],
        offset: 5,
        len: 2,
      },
    },
    type: 'document',
    firestore: {
      app: {
        _isDeleted: false,
        _options: {
          apiKey: 'AIzaSyCT2oTqhplzSOl3ThBz2WWdb_RrFbHI1IQ',
          authDomain: 'goodok-69b27.firebaseapp.com',
          projectId: 'goodok-69b27',
          storageBucket: 'goodok-69b27.appspot.com',
          messagingSenderId: '816479913009',
          appId: '1:816479913009:web:2416489c584e42879a0110',
          measurementId: 'G-HH3WC1WCSG',
        },
        _config: {
          name: '[DEFAULT]',
          automaticDataCollectionEnabled: false,
        },
        _name: '[DEFAULT]',
        _automaticDataCollectionEnabled: false,
        _container: {
          name: '[DEFAULT]',
          providers: {},
        },
      },
      databaseId: {
        projectId: 'goodok-69b27',
        database: '(default)',
      },
      settings: {
        host: 'firestore.googleapis.com',
        ssl: true,
        ignoreUndefinedProperties: false,
        cacheSizeBytes: 41943040,
        experimentalForceLongPolling: false,
        experimentalAutoDetectLongPolling: true,
        experimentalLongPollingOptions: {},
        useFetchStreams: true,
      },
    },
  },
  document: {
    nombre_documento: 'MENU PICOTEO B.pdf',
    csv_resguardo: 'MANUAL-26636734334763009',
    fecha_evento: {
      seconds: 1694642400,
      nanoseconds: 0,
    },
    items: [
      {
        type: 'NotificationItem',
        '#Id': '22286765687570436',
      },
    ],
    '#Id': '22286765687570436',
    identificador: 'MANUAL-R1605879D-20230914090043',
  },
  nombre_organismo_emisor: 'Administración de Justicia',
  nombre_titular: 'Empresa Pepito',
  estado: 'Aceptado',
  concepto: 'Notificación del test 1',
  nif_usuario_gran_destinatario: 'W2560778I',
  origen: 'MANUAL',
  codigo_organismo_emisor: 'J99999999',
  tipo_envio: '2',
  nif_gran_destinatario: 'W2560778I',
  nif_titular: 'R1605879D',
  codigo_organismo_emisor_raiz: 'J99999999',
  administrationLevel: 'J',
  identificador: 'MANUAL-R1605879D-20230914090043',
  nif_peticion: 'W2560778I',
  estadoOriginal: 'Aceptado',
}

function generateRndDate() {
  // '2023-09-13T22:00:00.000Z'
  //
  var out =
    '' +
    202 +
    getRandomInt(4) +
    '-' +
    (1 + getRandomInt(12) + '').padStart(2, '0') +
    '-' +
    (1 + getRandomInt(28) + '').padStart(2, '0') +
    'T22:00:00.000Z'
  return out
}

export async function loadAllNotifications(isOperatorUser) {
  if (!fakeData) {
    return await listAllNotifications(
      isOperatorUser ? store.state.user.parentRef.id : store.state.user.id,
      store.state.user
    )
  }

  var randomNotifications = []
  var max = 1450 + getRandomInt(400)
  var cifsKeys = Object.keys(cifs)
  var cifsCount = cifsKeys.length + 1
  for (let i = 0; i < max; i++) {
    var notification = JSON.parse(JSON.stringify(baseNotification))
    //var disposicion = 1577833200 + getRandomInt(100000000) // se parece a 3 años
    //notification.id = '00000000000' + i
    let idCif = getRandomInt(cifsCount)
    if (idCif < cifsKeys.length) {
      var currentCif = cifsKeys[idCif]
      notification.nif_titular = currentCif
      notification.nombre_titular = cifs[currentCif]
    } else {
      notification.nif_titular = ''
      notification.nombre_titular = ''
    }

    var emitterKeys = Object.keys(rootEmitter)
    var rootEmitterCount = emitterKeys.length
    var idEmitter = getRandomInt(rootEmitterCount)
    var emitterCode = emitterKeys[idEmitter]
    var emitterName = rootEmitter[emitterCode]

    if (i % 5 == 0) {
      // para simular el problema de undefined posterior
      notification.nombre_organismo_emisor = 'inventado'
      notification.nombre_organismo_emisor_raiz = 'inventado'
      notification.codigo_organismo_emisor = 'ACBDEF001'
      notification.codigo_organismo_emisor_raiz = 'ACBDEF001'
    } else {
      notification.nombre_organismo_emisor = emitterName
      notification.nombre_organismo_emisor_raiz = emitterName
      notification.codigo_organismo_emisor = emitterCode
      notification.codigo_organismo_emisor_raiz = emitterCode
    }

    //Seleccionar operador
    var operatorsKeys = Object.keys(operators)
    var operatorsCount = operatorsKeys.length
    let idOperator = getRandomInt(operatorsCount)
    var currentOperator = operatorsKeys[idOperator]
    notification.nif_gran_destinatario = currentOperator
    //console.log('Id de operador: ' + currentOperator)
    notification.lastActionByRef = []
    notification.lastActionByRef.id = currentOperator.toString()
    //console.log('Guardado: ' + notification.lastActionByRef.id)

    //entorno DEV
    //notification.id = '26636734334763009'
    //entorno BACK
    notification.id = '22286765687570436'

    //var disposicionDate = new Date(disposicion)
    //var strdateDis = disposicionDate.format("yyyy-MM-dd'T'HH:mm:ssXXX")
    notification.fecha_puesta_disposicion = moment(generateRndDate())
    notification.tipo_envio = '' + (1 + getRandomInt(2)) // Valor 1 para las Comunicaciones, Valor 2 para las Notificaciones
    if (notification.tipo_envio === '1') {
      notification.concepto = 'Comunicación del test 1'
    }
    if (i % 4 == 0) {
      if (i % 7 == 0) {
        notification.conceptoTipo = 'smac'
      }
      if (i % 7 == 1) {
        notification.conceptoTipo = 'resLiqProv'
      }
      if (i % 7 == 2) {
        notification.conceptoTipo = 'AcImSaInTri'
      }
      if (i % 7 == 3) {
        notification.conceptoTipo = 'LiInDeDeEx'
      }
      if (i % 7 == 4) {
        notification.conceptoTipo = 'smaDiEmCuBac'
      }
      if (i % 7 == 5) {
        notification.conceptoTipo = 'embargoSS'
      }
      if (i % 7 == 6) {
        notification.conceptoTipo = 'DiEmCuBa'
      }
    }
    if (i % 4 == 1) {
      notification.metadatas = []
      notification.metadatas.push({ tipo: 'embargoSS' })
    }
    if (i % 4 == 2) {
      notification.metadatas = []
      notification.metadatas.push({ tipo: 'AEAT_RE02' })
      notification.metadatas.push({ tipo: 'requerimiento' })
    }
    if (i % 2 == 0) {
      notification.estado = 'Aceptado'
      notification.estadoOriginal = 'Aceptado'
      notification.fecha_aceptacion = notification.fecha_puesta_disposicion
    } else {
      if (i % 5 == 0) {
        notification.estado = 'Expirada'
        notification.estadoOriginal = 'Expirada'
        if (notification.tipo_envio == '2') {
          notification.fecha_expiracion = notification.fecha_puesta_disposicion
        }
      } else {
        notification.estado = 'Pendiente'
        notification.estadoOriginal = 'Pendiente'
      }
    }
    randomNotifications[i] = notification
  }
  return randomNotifications
}

export async function loadStoreNotifications() {
  if (!fakeData) {
    return store.state.notifications
  }
  return loadAllNotifications(true)
}
