<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6" class="pr-8">
        <v-text-field
          data-v-step="104"
          class="my-0 py-0"
          v-model="search"
          clearable
          prepend-icon="mdi-magnify"
          :label="$t('searchField')"
          single-line
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selectedOperatorsRows"
          :loading="loading"
          :headers="computedHeaders"
          :items="filteredOperators"
          :items-per-page="8"
          :footer-props="{ 'items-per-page-options': [8, 25, 50 /*-1*/] }"
          class="elevation-1 mx-4 px-4"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <AppAvatar
              :userName="item.name"
              :userAvatar="item.avatar"
              avatarColor="primary"
              initialsColor="secondary"
              avatarSize="40"
              iconSize="24"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
    selectedOperators: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOperatorsRows: [],
      search: '',
    }
  },
  mounted() {
    this.selectedOperatorsRows = this.selectedOperators
  },
  methods: {},
  computed: {
    computedHeaders() {
      return this.isMobile
        ? [
            {
              text: this.$t('avatar'),
              value: 'avatar',
              width: '60px',
            },
            { text: this.$t('csv.operators.name'), value: 'name' },
          ]
        : [
            {
              text: this.$t('avatar'),
              value: 'avatar',
              width: '100px',
            },
            { text: this.$t('csv.operators.name'), value: 'name' },
          ]
    },
    filteredOperators() {
      if (!this.operators.length) return
      return this.operators.filter((operator) => {
        // String de búsqueda en mayúscula
        const searchCap = this.search ? this.search.toUpperCase() : ''

        const { name } = operator

        // Guardamos los elementos en mayúscula
        let dataCap = [name?.toUpperCase()]

        // Filtramos
        for (let i = 0; i < dataCap.length; i++)
          if (dataCap[i]?.includes(searchCap)) return operator
      })
    },
  },
}
</script>
