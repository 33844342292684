<template>
  <v-card elevation="10" :min-height="!isMobile ? 740 : undefined">
    <AppSkeleton :condition="loadingSignature" type="signatureProperties">
      <template v-slot:skeleton-content>
        <div
          v-if="!isMobile"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 740px;
          "
        >
          <div>
            <v-card-title data-v-step="701">
              <v-row align="center">
                <v-col class="d-flex justify-end">
                  <h3 class="primary--text">
                    <v-icon x-large class="primary--text">
                      mdi-identifier
                    </v-icon>
                    <span>{{ signature.id }}</span>
                  </h3>
                  <v-spacer></v-spacer>
                  <AlertDialog
                    data-v-step="702"
                    :asset="signature"
                    objectType="signatures"
                    :isDisabled="
                      isClienteDespachoUser ||
                      signature.status === 'signed' ||
                      signature.status === 'abandoned' ||
                      signature.status == 'abandonedByDate'
                    "
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-list dense>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.status') }}:
                </v-list-item-content>
                <v-chip
                  medium
                  class="white--text"
                  :color="getSignatureStatus(signature).color"
                >
                  <span class="headline">
                    {{ $t(getSignatureStatus(signature).status) }}
                  </span>
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.purpose') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end headline">
                  {{ signature.purpose }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.signatureType') }}:
                </v-list-item-content>
                <v-list-item-content align="align-end headline">
                  {{ $t(signature.signatureType) }}
                  <small v-if="signature.signatureType === 'joint'">
                    ({{ signature.signaturesRequired }}
                    {{ $t('signaturesRequired').toLowerCase() }})
                  </small>
                </v-list-item-content>
              </v-list-item>

              <!-- Clientes a firmar -->
              <v-list-item>
                <v-list-item-content class="title">
                  {{
                    clients.length === 1
                      ? $t('signatureProperties.signer')
                      : $t('signatureProperties.signers')
                  }}:
                </v-list-item-content>
                <v-list-item-content class="align-end headline">
                  <v-list v-if="clients.length === 1">
                    <v-list-item v-for="item in clients" :key="item.id">
                      <v-list-item-title>
                        {{ item.name.substring(0, 1) + '.' }}
                        {{ item.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-end">
                        <v-icon :color="item.signed ? 'green' : 'grey'">
                          mdi-signature-freehand
                        </v-icon>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <v-menu v-else offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on">
                        {{ $t('clients') }} <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="item in clients" :key="item.id">
                        <v-list-item-title>
                          {{ item.name.substring(0, 1) + '.' }}
                          {{ item.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-end">
                          <v-icon :color="item.signed ? 'green' : 'grey'">
                            mdi-signature-freehand
                          </v-icon>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="signature.cif">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.cif') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <span>{{ signature.cif.empresa }} </span>
                  <span class="font-weight-black">
                    {{ signature.cif.numeroDocIdentidad }}
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="signature.requester">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.requester') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  <v-row align="center">
                    <v-col cols="2">
                      <AppAvatar
                        class="mr-3"
                        :userName="`${signature.requester.name} ${signature.requester.surname}`"
                        :userAvatar="signature.requester.avatarURL"
                        avatarSize="50"
                        iconSize="24"
                        avatarColor="primary"
                        initialsColor="secondary"
                      />
                    </v-col>
                    <v-col cols="10">
                      <span class="font-weight-black overline">
                        {{
                          `${signature.requester.name} ${signature.requester.surname}`
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="signature.observationRequester">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.observationRequester') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{ signature.observationRequester }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="signature.observationClient">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.observationClient') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{ signature.observationClient }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="signature.createdAt">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.createdAt') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(new Date(signature.createdAt), 'i18nDateAndHourSeconds')
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="signature.dateDeferred">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.dateDeferred') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(
                      new Date(signature.dateDeferred),
                      'i18nDateAndHourSeconds'
                    )
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.dateLimit') }}:
                </v-list-item-content>
                <v-list-item-content
                  class="align-end font-weight-black subtitle-1"
                >
                  {{
                    $d(new Date(signature.dateLimit), 'i18nDateAndHourSeconds')
                  }}

                  <TimerCountdown
                    v-if="
                      signature.dateLimit &&
                      ['urgent', 'pending', 'deferred'].includes(
                        signature.status
                      )
                    "
                    :starttime="new Date()"
                    :endtime="
                      new Date(signature.dateLimit).setDate(
                        new Date(signature.dateLimit).getDate()
                      )
                    "
                    hideMessage="true"
                  >
                  </TimerCountdown>
                </v-list-item-content>
              </v-list-item>

              <v-progress-linear
                v-if="loadingData"
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-list>
          </div>
          <v-row data-v-step="703" class="align-end justify-start">
            <!-- SIGN ACTION -->
            <v-col
              cols="3"
              v-if="
                // Solo pueden firmar los clientes que no hayan firmado ya
                ($store.state.user.parentRef &&
                  signature.clientsToSign.some(
                    (client) =>
                      client.id === $store.state.user.id && !client.signed
                  ) &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.deferred' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.abandoned' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.rejected' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.signed') ||
                getSignatureStatus(signature).status ===
                  'signatureStatus.incidence'
              "
            >
              <!-- Signature request can be SIGNED only if client-->
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="ma-2 secondary--text"
                  :disabled="loadingData"
                  @click="sendVerification()"
                  id="sign-signature"
                >
                  {{ $t('sign') }}
                  <v-icon right dark> mdi-signature-freehand </v-icon>
                </v-btn>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogSign"
                  width="500"
                >
                  <v-form
                    v-if="!signature.clientIsSigning"
                    ref="signForm"
                    v-model="validForm"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmSignTitle') }}
                            </span>
                            <v-btn fab @click="dialogSign = false" small plain>
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text
                        v-if="
                          authorizationType === 'otp' &&
                          communicationType === 'sms'
                        "
                      >
                        {{ $t('confirmSignTextSMSOTP') }}
                      </v-card-text>
                      <v-card-text
                        v-else-if="
                          authorizationType === 'otp' &&
                          communicationType === 'mail'
                        "
                      >
                        {{ $t('confirmSignTextEmailOTP') }}
                      </v-card-text>
                      <v-card-text v-else-if="authorizationType === 'pin'">
                        {{ $t('confirmSignTextPIN') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="6">
                          <v-text-field
                            :label="$t('code')"
                            prepend-icon="mdi-dialpad"
                            :value="pinInput"
                            @input="pinInput = $event"
                            dense
                            :rules="[notEmpty]"
                            autocomplete="new-password"
                            id="input-pin"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmSignSignature()"
                          color="primary"
                          text
                          id="confirm-sign-signature"
                        >
                          {{ $t('confirmSignAcceptance') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>
            <!-- REJECT ACTION -->
            <v-col
              cols="3"
              v-if="
                // Solo pueden rechazar los clientes que no hayan firmado ya
                $store.state.user.parentRef &&
                signature.clientsToSign.some(
                  (client) =>
                    client.id === $store.state.user.id && !client.signed
                ) &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.deferred' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.rejected' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogReject"
                  persistent
                  width="800"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="reject-signature"
                    >
                      {{ $t('rejectSignature') }}
                      <v-icon right dark> mdi-file-document-alert </v-icon>
                    </v-btn>
                  </template>
                  <v-form
                    ref="rejectForm"
                    v-model="validForm"
                    lazy-validation
                    class="hide-overflow"
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmRejectTitle') }}
                            </span>
                            <v-btn
                              fab
                              @click="dialogReject = false"
                              small
                              plain
                            >
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        {{ $t('confirmRejectText') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="10">
                          <v-text-field
                            :label="$t('observationClient')"
                            prepend-icon="mdi-text-box-plus-outline"
                            :value="observationClient"
                            @input="observationClient = $event"
                            dense
                            :rules="[notEmpty]"
                            id="observation-client-input"
                            autocomplete="false"
                          />
                        </v-col>
                        <v-col cols="10">
                          <SignatureAttachedFiles
                            @update-files-attached="updateFilesAttached"
                            :alreadyUploadedFiles="signature.documents"
                            :applyRules="false"
                            :disabled="loadingData"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmRejectSignature()"
                          color="primary"
                          text
                          id="confirm-reject-signature"
                        >
                          {{ $t('confirmRejectAcceptance') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>
            <!-- FORWARD ACTION -->
            <v-col
              cols="3"
              v-if="
                // Solo pueden reenviar la solicitud los solicitantes, CLIENTES NO
                signature.requester?.id === $store.state.user.id &&
                !$store.state.user.parentRef &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status ===
                  'signatureStatus.rejected'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogForward"
                  width="800"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="forward-signature"
                    >
                      {{ $t('forward') }}
                      <v-icon right dark> mdi-send </v-icon>
                    </v-btn>
                  </template>
                  <v-form ref="forwardForm" v-model="validForm" lazy-validation>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmForwardTitle') }}
                            </span>
                            <v-btn
                              fab
                              @click="dialogForward = false"
                              small
                              plain
                            >
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        {{ $t('confirmForwardText') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="10">
                          <v-text-field
                            :label="
                              $t('signatureProperties.observationRequester')
                            "
                            prepend-icon="mdi-text-box-plus-outline"
                            :value="observationRequester"
                            @input="observationRequester = $event"
                            dense
                            :rules="[notEmpty]"
                            id="observation-requester-input"
                            autocomplete="false"
                          />
                        </v-col>
                        <v-col cols="8">
                          <v-menu
                            dense
                            ref="menuDateLimit"
                            v-model="menuDateLimit"
                            :close-on-content-click="false"
                            :return-value.sync="dateLimit"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="mr-2"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateLimit"
                                :label="$t('changeDateLimit')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              @click:date="$refs.menuDateLimit.save(dateLimit)"
                              v-model="dateLimit"
                              :locale="$t('javascriptLocale')"
                              :min="new Date().toISOString().substring(0, 10)"
                              no-title
                              scrollable
                              :rules="[notEmpty]"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox
                            dense
                            v-model="deferredSignature"
                            :label="$t('deferredSignature')"
                            class="mt-2"
                          >
                          </v-checkbox>
                        </v-col>
                        <transition class="blue" name="fade">
                          <v-col cols="10" sm="10" v-if="deferredSignature">
                            <v-menu
                              dense
                              ref="menuDateSignature"
                              v-model="menuDateSignature"
                              :close-on-content-click="false"
                              :return-value.sync="dateDeferredSignature"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              class="mr-2"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="dateDeferredSignature"
                                  v-model="dateDeferredSignature"
                                  :label="$t('changeDeferredDate')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  id="date-date"
                                  :rules="deferredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @click:date="
                                  $refs.menuDateSignature.save(
                                    dateDeferredSignature
                                  )
                                "
                                v-model="dateDeferredSignature"
                                :locale="$t('javascriptLocale')"
                                :min="new Date().toISOString().substring(0, 10)"
                                no-title
                                scrollable
                                :rules="[notEmpty]"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </transition>
                        <v-col cols="10">
                          <SignatureAttachedFiles
                            @update-files-attached="updateFilesAttached"
                            :alreadyUploadedFiles="signature.documents"
                            :applyRules="false"
                            :disabled="loadingData"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmForwardSignature()"
                          color="primary"
                          text
                          id="confirm-forward-signature"
                        >
                          {{ $t('confirmForward') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>
            <!-- ABANDON ACTION -->
            <v-col
              cols="3"
              v-if="
                !$store.state.user.parentRef &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <!-- Signature request can be abandonded only if requester wants to -->
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogAbandon"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="abandon-signature"
                    >
                      {{ $t('abandonSignature') }}
                      <v-icon right dark> mdi-note-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmAbandonTitle') }}
                          </span>
                          <v-btn fab @click="dialogAbandon = false" small plain>
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmAbandonText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmAbandonSignature()"
                        color="primary"
                        text
                        id="confirm-abandon-signature"
                      >
                        {{ $t('confirmAbandonAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- COMPLETE DELETION -->
            <v-col
              cols="3"
              v-if="
                signature.requester?.id === $store.state.user.id &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <!-- Signature request can be abandonded only if requester wants to -->
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogDeletion"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="delete-signature"
                    >
                      {{ $t('deleteSignature') }}
                      <v-icon right dark> mdi-select-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmDeletionTitle') }}
                          </span>
                          <v-btn
                            fab
                            @click="dialogDeletion = false"
                            small
                            plain
                          >
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmDeletionText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmDeletionSignature()"
                        color="primary"
                        text
                        id="confirm-delete-signature"
                      >
                        {{ $t('confirmDeletionAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- REVOCATION ACTION -->
            <v-col
              cols="3"
              v-if="
                signature.requester?.id !== $store.state.user.id &&
                (getSignatureStatus(signature).status ===
                  'signatureStatus.deferred' ||
                  getSignatureStatus(signature).status !==
                    'signatureStatus.signed') &&
                signature.signatureType === 'joint' &&
                clientAlreadySigned()
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogRevocation"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                    >
                      {{ $t('revokeSignature') }}
                      <v-icon right dark> mdi-file-undo </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmRevocationTitle') }}
                          </span>
                          <v-btn
                            fab
                            @click="dialogRevocation = false"
                            small
                            plain
                          >
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmRevocationText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmRevocationSignature()"
                        color="primary"
                        text
                      >
                        {{ $t('confirmRevocationAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- CANCEL ACTION -->
            <v-col
              cols="3"
              v-if="
                signature.requester?.id !== $store.state.user.id &&
                getSignatureStatus(signature).status ===
                  'signatureStatus.deferred'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogCancel"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                    >
                      {{ $t('cancelSignature') }}
                      <v-icon right dark> mdi-note-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmCancelTitle') }}
                          </span>
                          <v-btn fab @click="dialogCancel = false" small plain>
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmCancelText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmAbandonSignature()"
                        color="primary"
                        text
                      >
                        {{ $t('confirmCancelAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- Handle Rejection dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleRejection"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('rejectReasonModifications') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleRejection = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10">
                    <v-alert outlined color="red">
                      <b>{{ $t('rejectReasonInput') }} - </b>
                      {{ signature.observationClient }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="addedDocuments.length !== 0" cols="10" sm="10">
                    <v-alert outlined color="black">
                      <b>{{ $t('addedFiles') }}</b>
                      <br />
                      <ul>
                        <li
                          v-for="(document, index) in addedDocuments"
                          :key="index"
                        >
                          {{ document.name }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>

            <!-- Handle Forwarded Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleForwarded"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('forwardedReasonModifications') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleForwarded = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10">
                    <v-alert outlined color="red">
                      <b>{{ $t('forwardedReasonInput') }} - </b>
                      {{ signature.observationRequester }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="addedDocuments.length !== 0" cols="10" sm="10">
                    <v-alert outlined color="black">
                      <b>{{ $t('addedFiles') }}</b>
                      <br />
                      <ul>
                        <li
                          v-for="(document, index) in addedDocuments"
                          :key="index"
                        >
                          {{ document.name }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
            <!-- Handle Deferred Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleDeferred"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('deferredSignatureTitle') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleDeferred = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10" v-if="signature.dateDeferred">
                    <v-alert outlined color="black">
                      <b>{{ $t('deferredSignatureText') }} </b>
                      {{
                        $d(new Date(signature.dateDeferred), 'i18nDateAndHour')
                      }}
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
            <!-- Handle Incidence Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleIncidence"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('incidenceSignatureTitle') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleIncidence = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10" v-if="signature.dateDeferred">
                    <v-alert outlined color="red">
                      <b>{{ $t('incidenceSignatureText') }} </b>
                      {{
                        $d(new Date(signature.dateDeferred), 'i18nDateAndHour')
                      }}
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-row>
        </div>

        <!-- Version Movil -->
        <div
          v-else
          style="
            display: ;
            flex-direction: column;
            justify-content: space-between;
            height: auto;
          "
        >
          <div>
            <v-card-title>
              <v-col align="center">
                <v-col cols="12" class="d-flex justify-end">
                  <h4 class="primary--text">
                    <v-icon x-large class="primary--text">
                      mdi-identifier
                    </v-icon>
                    {{ signature.id }}
                  </h4>
                </v-col>
                <AlertDialog
                  :asset="signature"
                  objectType="signatures"
                  :isDisabled="isClienteDespachoUser"
                />
              </v-col>
            </v-card-title>
            <v-list dense>
              <v-container>
                <v-list-item>
                  <v-list-item-content class="title">
                    {{ $t('signatureProperties.status') }}:
                  </v-list-item-content>
                  <v-chip
                    medium
                    class="white--text"
                    :color="getSignatureStatus(signature).color"
                  >
                    <span>
                      {{ $t(getSignatureStatus(signature).status) }}
                    </span>
                  </v-chip>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="title">
                    {{ $t('signatureProperties.purpose') }}:
                  </v-list-item-content>
                  <v-list-item-content align="align-end subtitle-1">
                    {{ signature.purpose }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="title">
                    {{ $t('signatureProperties.signatureType') }}:
                  </v-list-item-content>
                  <v-list-item-content class="align-end subtitle-1">
                    {{ $t(signature.signatureType) }}
                    <small v-if="signature.signatureType === 'joint'">
                      ({{ signature.signaturesRequired }}
                      {{ $t('signaturesRequired').toLowerCase() }})
                    </small>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="align-end subtitle-1">
                    <v-list v-if="clients.length === 1">
                      <v-list-item v-for="item in clients" :key="item.id">
                        <v-list-item-title>
                          {{ item.name.substring(0, 1) + '.' }}
                          {{ item.surname }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-end">
                          <v-icon :color="item.signed ? 'green' : 'grey'">
                            mdi-signature-freehand
                          </v-icon>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                    <v-menu v-else offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on">
                          {{ $t('signatureProperties.signers') }}
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in clients" :key="item.id">
                          <v-list-item-title>
                            {{ item.name.substring(0, 1) + '.' }}
                            {{ item.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="text-end">
                            <v-icon :color="item.signed ? 'green' : 'grey'">
                              mdi-signature-freehand
                            </v-icon>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="title">
                    {{ $t('signatureProperties.cif') }}:
                  </v-list-item-content>
                  <v-list-item-content class="align-end subtitle-1">
                    <span>{{ signature.cif.empresa }} </span>
                    <span class="font-weight-black">
                      {{ signature.cif.numeroDocIdentidad }}
                    </span>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="signature.requester">
                  <v-list-item-content class="title">
                    {{ $t('signatureProperties.requester') }}:
                  </v-list-item-content>
                  <v-list-item-content class="align-end subtitle-1">
                    <span>
                      {{
                        `${signature.requester.name} ${signature.requester.surname}`
                      }}
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-container>
            </v-list>
            <!-- Elementos desplegables -->
            <v-list-group append-icon="mdi-menu-down" fluid>
              <!-- Botón para desplegar la lista -->
              <template v-slot:activator>
                <v-row no-gutters>
                  <v-list-item-title class="title">
                    {{ $t('moreInfo') }}...
                  </v-list-item-title>
                </v-row>
              </template>
              <v-list-item v-if="signature.observationRequester">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.observationRequester') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{ signature.observationRequester }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="signature.observationClient">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.observationClient') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{ signature.observationClient }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="signature.createdAt">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.createdAt') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(new Date(signature.createdAt), 'i18nDateAndHourSeconds')
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="signature.dateDeferred">
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.dateDeferred') }}:
                </v-list-item-content>
                <v-list-item-content class="align-end subtitle-1">
                  {{
                    $d(
                      new Date(signature.dateDeferred),
                      'i18nDateAndHourSeconds'
                    )
                  }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="title">
                  {{ $t('signatureProperties.dateLimit') }}:
                </v-list-item-content>
                <v-list-item-content
                  class="align-end font-weight-black subtitle-1"
                >
                  {{
                    $d(new Date(signature.dateLimit), 'i18nDateAndHourSeconds')
                  }}

                  <TimerCountdown
                    v-if="
                      signature.dateLimit &&
                      ['urgent', 'pending', 'deferred'].includes(
                        signature.status
                      )
                    "
                    :starttime="new Date()"
                    :endtime="
                      new Date(signature.dateLimit).setDate(
                        new Date(signature.dateLimit).getDate()
                      )
                    "
                    hideMessage="true"
                  >
                  </TimerCountdown>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-progress-linear
              v-if="loadingData"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </div>
          <v-row class="align-end justify-start">
            <!-- SIGN ACTION -->
            <v-col
              cols="4"
              v-if="
                // Solo pueden firmar los clientes que no hayan firmado ya
                ($store.state.user.parentRef &&
                  signature.clientsToSign.some(
                    (client) =>
                      client.id === $store.state.user.id && !client.signed
                  ) &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.deferred' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.abandoned' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.rejected' &&
                  getSignatureStatus(signature).status !==
                    'signatureStatus.signed') ||
                getSignatureStatus(signature).status ===
                  'signatureStatus.incidence'
              "
            >
              <!-- Signature request can be SIGNED only if client-->
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="ma-2 secondary--text"
                  :disabled="loadingData"
                  @click="sendVerification()"
                  id="sign-signature"
                >
                  {{ $t('sign') }}
                  <v-icon right dark> mdi-signature-freehand </v-icon>
                </v-btn>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogSign"
                  width="500"
                >
                  <v-form ref="signForm" v-model="validForm" lazy-validation>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmSignTitle') }}
                            </span>
                            <v-btn fab @click="dialogSign = false" small plain>
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text
                        v-if="
                          authorizationType === 'otp' &&
                          communicationType === 'sms'
                        "
                      >
                        {{ $t('confirmSignTextSMSOTP') }}
                      </v-card-text>
                      <v-card-text
                        v-else-if="
                          authorizationType === 'otp' &&
                          communicationType === 'mail'
                        "
                      >
                        {{ $t('confirmSignTextEmailOTP') }}
                      </v-card-text>
                      <v-card-text v-else-if="authorizationType === 'pin'">
                        {{ $t('confirmSignTextPIN') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="6">
                          <v-text-field
                            :label="$t('code')"
                            prepend-icon="mdi-dialpad"
                            :value="pinInput"
                            @input="pinInput = $event"
                            dense
                            :rules="[notEmpty]"
                            autocomplete="new-password"
                            id="input-pin"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmSignSignature()"
                          color="primary"
                          text
                          id="confirm-sign-signature"
                        >
                          {{ $t('confirmSignAcceptance') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>
            <!-- REJECT ACTION -->
            <v-col
              cols="4"
              v-if="
                // Solo pueden rechazar los clientes que no hayan firmado ya
                $store.state.user.parentRef &&
                signature.clientsToSign.some(
                  (client) =>
                    client.id === $store.state.user.id && !client.signed
                ) &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.deferred' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.rejected' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogReject"
                  persistent
                  width="800"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="abandon-signature"
                    >
                      {{ $t('rejectSignature') }}
                      <v-icon right dark> mdi-file-document-alert </v-icon>
                    </v-btn>
                  </template>
                  <v-form
                    ref="rejectForm"
                    v-model="validForm"
                    lazy-validation
                    class="hide-overflow"
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmRejectTitle') }}
                            </span>
                            <v-btn
                              fab
                              @click="dialogReject = false"
                              small
                              plain
                            >
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        {{ $t('confirmRejectText') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="10">
                          <v-text-field
                            :label="$t('observationClient')"
                            prepend-icon="mdi-text-box-plus-outline"
                            :value="observationClient"
                            @input="observationClient = $event"
                            dense
                            :rules="[notEmpty]"
                            id="signature-observation"
                            autocomplete="false"
                          />
                        </v-col>
                        <v-col cols="10">
                          <SignatureAttachedFiles
                            @update-files-attached="updateFilesAttached"
                            :alreadyUploadedFiles="signature.documents"
                            :applyRules="false"
                            :disabled="loadingData"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmRejectSignature()"
                          color="primary"
                          text
                        >
                          {{ $t('confirmRejectAcceptance') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- FORWARD ACTION -->
            <v-col
              cols="4"
              v-if="
                // Solo pueden reenviar la solicitud los solicitantes, CLIENTES NO
                signature.requester?.id === $store.state.user.id &&
                !$store.state.user.parentRef &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status ===
                  'signatureStatus.rejected'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogForward"
                  width="800"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="abandon-signature"
                    >
                      {{ $t('forward').slice(0, 2) }}...
                      <v-icon right dark> mdi-send </v-icon>
                    </v-btn>
                  </template>
                  <v-form ref="forwardForm" v-model="validForm" lazy-validation>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        <v-container>
                          <v-row justify="space-between" align="center">
                            <span>
                              {{ $t('confirmForwardTitle') }}
                            </span>
                            <v-btn
                              fab
                              @click="dialogForward = false"
                              small
                              plain
                            >
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        {{ $t('confirmForwardText') }}
                      </v-card-text>
                      <v-row justify="center">
                        <v-col cols="10">
                          <v-text-field
                            :label="
                              $t('signatureProperties.observationRequester')
                            "
                            prepend-icon="mdi-text-box-plus-outline"
                            :value="observationRequester"
                            @input="observationRequester = $event"
                            dense
                            :rules="[notEmpty]"
                            id="signature-observation"
                            autocomplete="false"
                          />
                        </v-col>
                        <v-col cols="8">
                          <v-menu
                            dense
                            ref="menuDateLimit"
                            v-model="menuDateLimit"
                            :close-on-content-click="false"
                            :return-value.sync="dateLimit"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="mr-2"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateLimit"
                                :label="$t('changeDateLimit')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              @click:date="$refs.menuDateLimit.save(dateLimit)"
                              v-model="dateLimit"
                              :locale="$t('javascriptLocale')"
                              :min="new Date().toISOString().substring(0, 10)"
                              no-title
                              scrollable
                              :rules="[notEmpty]"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox
                            dense
                            v-model="deferredSignature"
                            :label="$t('deferredSignature')"
                            class="mt-2"
                          >
                          </v-checkbox>
                        </v-col>
                        <transition class="blue" name="fade">
                          <v-col cols="10" sm="10" v-if="deferredSignature">
                            <v-menu
                              dense
                              ref="menuDateSignature"
                              v-model="menuDateSignature"
                              :close-on-content-click="false"
                              :return-value.sync="dateDeferredSignature"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              class="mr-2"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="dateDeferredSignature"
                                  v-model="dateDeferredSignature"
                                  :label="$t('changeDeferredDate')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  id="date-date"
                                  :rules="deferredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @click:date="
                                  $refs.menuDateSignature.save(
                                    dateDeferredSignature
                                  )
                                "
                                v-model="dateDeferredSignature"
                                :locale="$t('javascriptLocale')"
                                :min="new Date().toISOString().substring(0, 10)"
                                no-title
                                scrollable
                                :rules="[notEmpty]"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </transition>
                        <v-col cols="10">
                          <SignatureAttachedFiles
                            @update-files-attached="updateFilesAttached"
                            :alreadyUploadedFiles="signature.documents"
                            :applyRules="false"
                            :disabled="loadingData"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="confirmForwardSignature()"
                          color="primary"
                          text
                          id="confirm-reject-signature"
                        >
                          {{ $t('confirmForward') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-actions>
            </v-col>
            <!-- ABANDON ACTION -->
            <v-col
              cols="4"
              v-if="
                !$store.state.user.parentRef &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <!-- Signature request can be abandonded only if requester wants to -->
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogAbandon"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="abandon-signature"
                    >
                      {{ $t('abandonSignature').slice(0, 2) }}...
                      <v-icon right dark> mdi-note-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmAbandonTitle') }}
                          </span>
                          <v-btn fab @click="dialogAbandon = false" small plain>
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmAbandonText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmAbandonSignature()"
                        color="primary"
                        text
                        id="confirm-abandon-signature"
                      >
                        {{ $t('confirmAbandonAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- COMPLETE DELETION -->
            <v-col
              cols="4"
              v-if="
                signature.requester?.id === $store.state.user.id &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.abandoned' &&
                getSignatureStatus(signature).status !==
                  'signatureStatus.signed'
              "
            >
              <!-- Signature request can be abandonded only if requester wants to -->
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogDeletion"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                      id="Deletion-signature"
                    >
                      {{ $t('deleteSignature').slice(0, 2) }}...
                      <v-icon right dark> mdi-select-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmDeletionTitle') }}
                          </span>
                          <v-btn
                            fab
                            @click="dialogDeletion = false"
                            small
                            plain
                          >
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmDeletionText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmDeletionSignature()"
                        color="primary"
                        text
                        id="confirm-Deletion-signature"
                      >
                        {{ $t('confirmDeletionAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- REVOCATION ACTION -->
            <v-col
              cols="4"
              v-if="
                signature.requester?.id !== $store.state.user.id &&
                (getSignatureStatus(signature).status ===
                  'signatureStatus.deferred' ||
                  getSignatureStatus(signature).status !==
                    'signatureStatus.signed') &&
                signature.signatureType === 'joint' &&
                clientAlreadySigned()
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogRevocation"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                    >
                      {{ $t('revokeSignature') }}
                      <v-icon right dark> mdi-file-undo </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmRevocationTitle') }}
                          </span>
                          <v-btn
                            fab
                            @click="dialogRevocation = false"
                            small
                            plain
                          >
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmRevocationText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmRevocationSignature()"
                        color="primary"
                        text
                      >
                        {{ $t('confirmRevocationAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- CANCEL ACTION -->
            <v-col
              cols="4"
              v-if="
                signature.requester?.id !== $store.state.user.id &&
                getSignatureStatus(signature).status ===
                  'signatureStatus.deferred'
              "
            >
              <v-card-actions>
                <v-dialog
                  v-if="!signature.document"
                  v-model="dialogCancel"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 secondary--text"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loadingData"
                    >
                      {{ $t('cancelSignature') }}
                      <v-icon right dark> mdi-note-remove </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      <v-container>
                        <v-row justify="space-between" align="center">
                          <span>
                            {{ $t('confirmCancelTitle') }}
                          </span>
                          <v-btn fab @click="dialogCancel = false" small plain>
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      {{ $t('confirmCancelText') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="confirmAbandonSignature()"
                        color="primary"
                        text
                      >
                        {{ $t('confirmCancelAcceptance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-col>

            <!-- Handle Rejection dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleRejection"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('rejectReasonModifications') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleRejection = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10">
                    <v-alert outlined color="red">
                      <b>{{ $t('rejectReasonInput') }} - </b>
                      {{ signature.observationClient }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="addedDocuments.length !== 0" cols="10" sm="10">
                    <v-alert outlined color="black">
                      <b>{{ $t('addedFiles') }}</b>
                      <br />
                      <ul>
                        <li
                          v-for="(document, index) in addedDocuments"
                          :key="index"
                        >
                          {{ document.name }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>

            <!-- Handle Forwarded Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleForwarded"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('forwardedReasonModifications') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleForwarded = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10">
                    <v-alert outlined color="red">
                      <b>{{ $t('forwardedReasonInput') }} - </b>
                      {{ signature.observationRequester }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="addedDocuments.length !== 0" cols="10" sm="10">
                    <v-alert outlined color="black">
                      <b>{{ $t('addedFiles') }}</b>
                      <br />
                      <ul>
                        <li
                          v-for="(document, index) in addedDocuments"
                          :key="index"
                        >
                          {{ document.name }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
            <!-- Handle Deferred Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleDeferred"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('deferredSignatureTitle') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleDeferred = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10" v-if="signature.dateDeferred">
                    <v-alert outlined color="black">
                      <b>{{ $t('deferredSignatureText') }} </b>
                      {{
                        $d(new Date(signature.dateDeferred), 'i18nDateAndHour')
                      }}
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
            <!-- Handle Incidence Dialog -->
            <v-card-actions>
              <v-dialog
                v-if="!signature.document"
                v-model="dialogHandleIncidence"
                width="800"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span>
                          {{ $t('incidenceSignatureTitle') }}
                        </span>
                        <v-btn
                          fab
                          @click="dialogHandleIncidence = false"
                          small
                          plain
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-col cols="10" sm="10" v-if="signature.dateDeferred">
                    <v-alert outlined color="red">
                      <b>{{ $t('incidenceSignatureText') }} </b>
                      {{
                        $d(new Date(signature.dateDeferred), 'i18nDateAndHour')
                      }}
                    </v-alert>
                  </v-col>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-row>
        </div>
      </template>
    </AppSkeleton>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TimerCountdown from '@/components/commons/TimerCountdown'
import getErrorText from '@/utils/get-error-text'
import {
  getSignatureStatus,
  signSignatureRequest,
  updateSignatureStatus,
  updateSignature,
  sendSignatureOTP,
  updateSignatureDocumentNeeds,
  revokeClientSignatures,
  deleteSignature,
} from '@/services/signatures-service'
import {
  getWalletCommunicationType,
  getWalletAuthorizationType,
} from '@/services/wallet-service'
import SignatureAttachedFiles from '@/components/signatures/SignatureAttachedFiles'
import rules from '@/utils/rules'
import { getUserInfo } from '@/services/user-service'
import AlertDialog from '@/components/alert/AlertDialog'
import lodash from 'lodash'

export default {
  props: {
    signature: Object,
    loadingSignature: Boolean,
    shared: Array,
    history: Array,
  },
  components: {
    TimerCountdown,
    SignatureAttachedFiles,
    AlertDialog,
  },
  data() {
    return {
      selectedOperator: {},
      loadingData: false,
      dialogSign: false,
      dialogAbandon: false,
      dialogReject: false,
      dialogForward: false,
      dialogCancel: false,
      dialogDeletion: false,
      dialogRevocation: false,
      dialogHandleRejection: false,
      dialogHandleForwarded: false,
      dialogHandleDeferred: false,
      dialogHandleIncidence: false,
      loadingOperators: true,
      menuDateLimit: false,
      menuDateSignature: false,
      clients: [],
      validForm: false,
      pinInput: '',
      communicationType: '',
      authorizationType: '',
      observationClient: '',
      observationRequester: '',
      messageText: '',
      tab: 0,
      filesAttached: [],
      parentFiles: [],
      addedDocuments: [],
      updatedDocumentNeeds: [],
      originalDocuments: [],
      filesToDelete: [],
      dateLimit: '',
      dateDeferredSignature: '',
      deferredSignature: false,
      deferredRules: [
        (v) =>
          (v &&
            this.dateLimit &&
            new Date(v).getTime() > new Date(this.dateLimit).getTime() &&
            // La fecha diferida tiene que ser como máximo dos semanas despues de la fecha limite
            new Date(v).getTime() <=
              new Date(this.dateLimit).getTime() + 14 * 24 * 60 * 60 * 1000) ||
          this.$t('deferredDateNotValid'),
      ],
    }
  },
  async mounted() {
    if (this.signature && this.signature.documents) {
      if (
        getSignatureStatus(this.signature).status ===
        'signatureStatus.incidence'
      )
        this.dialogHandleIncidence = true
      if (
        getSignatureStatus(this.signature).status === 'signatureStatus.rejected'
      ) {
        this.addedDocuments = this.signature.documents.filter(
          (elem) => elem.addedDocument === true
        )
        this.dialogHandleRejection = true
      }
      if (
        getSignatureStatus(this.signature).status ===
          'signatureStatus.pending' &&
        this.signature.isForwarded
      ) {
        this.addedDocuments = this.signature.documents.filter(
          (elem) => elem.addedDocument === true
        )
        this.dialogHandleForwarded = true
      }
      if (
        getSignatureStatus(this.signature).status === 'signatureStatus.deferred'
      )
        this.dialogHandleDeferred = true

      // Cargamos los documentos originales
      this.originalDocuments = [...this.signature.documents]
      // this.originalDocuments = lodash.cloneDeep
      // Cargamos los firmantes
      this.clients = await Promise.all(
        this.signature.clientsToSign.map(async (client) => {
          return {
            ...(await getUserInfo(client.id)),
            signed: client.signed,
          }
        })
      )
    }
  },
  watch: {
    signature: async function (newValue) {
      if (newValue) {
        // Cargamos los firmantes, para volver a mostrar el listado con la firma actualizada
        this.clients = await Promise.all(
          this.signature.clientsToSign.map(async (client) => {
            return {
              ...(await getUserInfo(client.id)),
              signed: client.signed,
            }
          })
        )
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser', 'isClienteDespachoUser']),
  },
  methods: {
    ...mapMutations(['setDialog', 'setSnackbar']),
    ...rules,
    clientAlreadySigned() {
      const client = this.signature.clientsToSign.find(
        (client) => client.id === this.$store.state.user.id
      )
      return client.signed
    },
    updateFilesAttached(newFilesAttached) {
      // Crear una matriz de objetos que contengan los documentos que han cambiado necesidad de firma
      this.updatedDocumentNeeds = this.originalDocuments.filter((document) => {
        const matchingFile = newFilesAttached.find(
          (file) => file.dmsDocId === document.dmsDocId
        )
        if (
          matchingFile &&
          matchingFile.needSignature !== document.needSignature
        ) {
          return true
        }
        return false
      })

      // Limpiamos files Attached
      this.filesAttached = []

      // Documentos a añadir
      const addedFiles = newFilesAttached.filter(
        (document) =>
          document.alreadyUploadedToDMS === false ||
          document.addedDocument === false
      )

      // Encontrar aquel elemento que ha sido borrado, esto se hace uno a uno
      const documentoABorrar = newFilesAttached.find(
        (document) => document.deleteFromDMS === true
      )
      if (documentoABorrar) {
        this.filesToDelete.push(documentoABorrar)
      }

      this.filesAttached.push(...addedFiles, ...this.filesToDelete)
    },
    // confirmDeletionSignature
    async confirmDeletionSignature() {
      try {
        this.dialogDeletion = false
        this.loadingData = true

        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        // Llamos a la function a través del service para borrar del sistema
        await deleteSignature(userId, this.signature.id)

        this.loadingData = false
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.deletionSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    // Enviar verificacion OTP
    async sendVerification() {
      if (this.signature.clientIsSigning) {
        const message = this.$t('clientAlreadySigning')
        this.setSnackbar({
          position: 'top',
          type: 'warning',
          message,
        })
        return
      }
      this.dialogSign = true
      this.authorizationType = await getWalletAuthorizationType(
        this.$store.state.user.id,
        // El id del wallet debe coincidir con el id de la empresa (Firestore)
        this.signature.cifRef.id
      )
      // Envio de OTP
      if (this.authorizationType === 'otp') {
        try {
          this.communicationType = await getWalletCommunicationType(
            this.$store.state.user.id,
            // El id del wallet debe coincidir con el id de la empresa (Firestore)
            this.signature.cifRef.id
          )

          await sendSignatureOTP(this.signature.id, {
            text:
              this.communicationType === 'sms'
                ? this.$t('signatureSMStext')
                : '',
            purpose: this.signature.purpose,
            communicationType: this.communicationType,
            walletId: this.signature.cifRef.id,
          })
        } catch (err) {
          this.loadingData = false
          const message = getErrorText(err.toString())
          this.setSnackbar({
            position: 'top',
            type: 'error',
            message,
          })
        }
      }
    },
    // Confirmar firma
    async confirmSignSignature() {
      if (!this.$refs.signForm?.validate()) return
      try {
        this.dialogSign = false
        this.loadingData = true

        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id
        // Comprobar que el usuario actual esta dentro de los posibles firmantes
        if (
          !this.signature.clientsToSign.filter(
            (client) => client.id === this.$store.state.user.id
          ).length
        )
          throw new Error('No se encuentra entre los clientes a firmar')
        // En caso de ser firma diferida y no estar en estado de incidencia,
        // mandamos a la cola de diferidas para ejecutar acción de firma en la fecha diferida
        if (
          this.signature.dateDeferred &&
          this.signature.dateDeferred > new Date() &&
          this.signature.status !== 'incidence'
        ) {
          try {
            // Aceptamos firma y esperamos a firmar hasta fecha diferida,
            // el cambiar sendToDeferredQueue lanza un evento (ver logica en event.js)
            const dataDeferred = {
              authorizationType: this.authorizationType,
              dmsOperationId: this.signature.dmsOperationId,
              clientHistoryAction: this.$store.state.user.id,
              clientToSignId: this.$store.state.user.id,
              requesterId: this.$store.state.user.parentRef.id,
              // El id del wallet debe coincidir con el id de la empresa (Firestore)
              walletId: this.signature.cifRef.id,
              sendToDeferredQueue: true,
              isForwarded: false,
              pinInput: this.pinInput,
            }
            await updateSignature(userId, this.signature.id, dataDeferred)
            this.loadingData = false
            this.dialogHandleDeferred = true
          } catch (err) {
            this.loadingData = false
            const message = getErrorText(err.toString())
            this.setSnackbar({
              position: 'top',
              type: 'error',
              message,
            })
          }
        } else {
          // Firmar directamente
          await signSignatureRequest(userId, this.signature.id, {
            authorizationType: this.authorizationType,
            // El id del wallet debe coincidir con el id de la empresa (Firestore)
            walletId: this.signature.cifRef.id,
            documents: this.signature.documents,
            pinInput: this.pinInput,
          })
          this.loadingData = false
          this.$router.replace('/signatures')
          // Mostramos snackbar con el mensaje de exito
          const message = this.$t('signatureActions.signedSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
        }
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    // Confirmar anulacion
    async confirmRevocationSignature() {
      try {
        this.dialogRevocation = false
        this.loadingData = true

        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        // TODO FUNCTION borrar tarea de la cola
        // deleteTaskFromQueueFunction
        await revokeClientSignatures(userId, this.signature.id)
        // await revokeDMSdocuments((userId, this.signature.id)
        this.loadingData = false
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.revocationSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    // Confirmar reenvío
    async confirmForwardSignature() {
      if (!this.$refs.forwardForm?.validate()) return
      try {
        this.dialogForward = false
        this.loadingData = true
        // Llamamos a la function donde añade los nuevos documentos al DMS
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        const dataUpdate = {
          dmsOperationId: this.signature.dmsOperationId,
          observationRequester: this.observationRequester,
          isForwarded: true,
          newStatus: 'pending',
        }
        // En caso de cambio de fechas
        if (this.dateDeferredSignature)
          dataUpdate.dateDeferred = new Date(this.dateDeferredSignature)
        if (this.dateLimit) dataUpdate.dateLimit = new Date(this.dateLimit)
        if (this.filesAttached.length !== 0)
          dataUpdate.documents = this.filesAttached

        // Llamamos a la función de actualizar la solicitud de firma
        await this.checkSignatureDocumentNeeds()
        if (this.noDocumentsToSign()) throw new Error(`no-documents-to-send`)
        await updateSignature(userId, this.signature.id, dataUpdate)

        this.loadingData = false
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.forwardedSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    // Confirmar reject
    async confirmRejectSignature() {
      if (!this.$refs.rejectForm?.validate()) return
      try {
        this.dialogReject = false
        this.loadingData = true
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        const dataReject = {
          dmsOperationId: this.signature.dmsOperationId,
          observationClient: this.observationClient,
          clientHistoryAction: this.$store.state.user.id,
          isForwarded: false,
          newStatus: 'rejected',
        }
        if (this.filesAttached.length !== 0)
          dataReject.documents = this.filesAttached

        await this.checkSignatureDocumentNeeds()
        if (this.noDocumentsToSign()) throw new Error(`no-documents-to-send`)
        // Llamamos a la function donde añade los nuevos documentos al DMS
        await updateSignature(userId, this.signature.id, dataReject)

        this.loadingData = false
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.rejectionSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
        return
      }
    },
    noDocumentsToSign() {
      const allDocumentsHaveChanged = this.originalDocuments.every((elem) =>
        this.updatedDocumentNeeds.some((otherElem) =>
          lodash.isEqual(elem, otherElem)
        )
      )
      const hasFalseNeedSignature = this.originalDocuments.some(
        (obj) => obj.needSignature === false
      )
      const someDocumentToSignIfAllChanged =
        allDocumentsHaveChanged && hasFalseNeedSignature
      if (someDocumentToSignIfAllChanged) return false

      const allDocumentsToBeRemoved =
        this.filesAttached.length !== 0 &&
        this.filesAttached.every(
          (file) => file && file.deleteFromDMS === true
        ) &&
        this.originalDocuments.every((elem) =>
          this.filesAttached.some(
            (otherElem) => elem.dmsDocId === otherElem.dmsDocId
          )
        )
      const noDocumentNeedSignature =
        this.signature.documents.every(
          (document) => document.needSignature === false
        ) &&
        (this.filesAttached.length !== 0
          ? this.filesAttached.every(
              (document) => document.needSignature === false
            )
          : true)

      return (
        allDocumentsToBeRemoved ||
        allDocumentsHaveChanged ||
        noDocumentNeedSignature
      )
    },
    async checkSignatureDocumentNeeds() {
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id
      for (let document of this.updatedDocumentNeeds) {
        await updateSignatureDocumentNeeds(
          userId,
          this.signature.id,
          document.dmsDocId
        )
      }
    },
    getSignatureStatus(signature) {
      return getSignatureStatus(signature)
    },
    // Confirmar Abandono de Solicitud de firma
    async confirmAbandonSignature() {
      try {
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id
        this.dialogAbandon = false
        this.dialogCancel = false
        this.loadingData = true
        await updateSignatureStatus(userId, this.signature.id, 'abandoned')

        this.loadingData = false
        // Redirect to Signature List
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.abandonSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-overflow {
  overflow-x: hidden;
}
</style>
