<template>
  <v-container>
    <v-row v-if="!isMobile">
      <v-col cols="4" v-for="filter in filters" v-bind:key="filter.id">
        <FilterCard :loadingTableData="loadingTableData" :filter="filter" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="6" v-for="filter in filters" v-bind:key="filter.id">
        <FilterCard :loadingTableData="loadingTableData" :filter="filter" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterCard from '@/components/filter/FilterCard'
import { mapGetters } from 'vuex'

export default {
  props: {
    filters: Array,
    loadingTableData: Boolean,
  },
  components: {
    FilterCard,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss"></style>
