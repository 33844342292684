<template>
  <v-dialog :value="true" persistent max-width="1400">
    <v-card overflow="hidden">
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1">
            <v-btn
              icon
              small
              @click="reversed = !reversed"
              id="close-automated-task-dialog"
            >
              <v-icon> mdi-swap-vertical-bold </v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center" cols="10">
            <v-row class="d-flex justify-center" align="center">
              {{ $t('automatedTasksActions.title.history') }} -
              {{ automatedTask.name }}
              <span class="body-2 ml-2 mt-1">({{ history.length }})</span>
              <DialogButton
                section="automatedTasks"
                subsection="history"
                classStr="ml-4"
              />
            </v-row>
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn
              icon
              small
              @click="closeDialog"
              id="close-automated-task-dialog"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <AppSkeleton :condition="loading" type="notificationList">
        <template v-slot:skeleton-content>
          <v-card-text v-if="history.length === 0" style="text-align: center">
            <span> La tarea no ha sido ejecutada </span>
          </v-card-text>
          <v-card-text max-height="200" v-else>
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="historyComputed"
              :items-per-page="10"
              class="elevation-1"
            >
              <!-- Execution date slot -->
              <template v-slot:[`item.executedAt`]="{ item }">
                <v-container>
                  <v-row align="center">
                    {{ $d(item.executedAt, 'i18nDateAndHourSeconds') }}
                  </v-row>
                </v-container>
              </template>
              <!-- action slot -->
              <template v-slot:[`item.action`]="{ item }">
                <v-container>
                  <v-row align="center">
                    {{ $t('actions.titles.' + item.action) }}
                  </v-row>
                </v-container>
              </template>
              <!-- subjectType slot -->
              <template v-slot:[`item.subjectType`]="{ item }">
                <v-container>
                  <v-row align="center">
                    {{ $t(item.subjectType) }}
                  </v-row>
                </v-container>
              </template>
              <!-- subjectId slot -->
              <template v-slot:[`item.subjectId`]="{ item }">
                <v-container>
                  <v-row align="center">
                    <a
                      :href="`${item.subjectType}/${item.subjectId}`"
                      target="_blank"
                    >
                      {{ item.subjectId }}
                    </a>
                  </v-row>
                </v-container>
              </template>
              <!-- subjectId slot -->
              <template v-slot:[`item.targets`]="{ item }">
                <v-container>
                  <v-row align="center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ getTargetsShortData(item.targets) }}
                        </span>
                      </template>
                      <div style="white-space: pre-line; max-width: 400px">
                        {{ getTargetsData(item.targets) }}
                      </div>
                    </v-tooltip>
                  </v-row>
                </v-container>
              </template>
              <!-- result slot -->
              <template v-slot:[`item.results`]="{ item }">
                <v-container>
                  <v-row align="center">
                    <template v-if="item.results">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ getResultShortData(item.results) }}
                          </span>
                        </template>
                        <div style="white-space: pre-line; max-width: 400px">
                          {{ getResultData(item.results) }}
                        </div>
                      </v-tooltip>
                      <v-btn
                        x-small
                        icon
                        class="ml-2"
                        @click="copyResult(item.results)"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span v-else>
                      {{ $t('success') }}
                    </span>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-card-text>
        </template>
      </AppSkeleton>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getAutomatedTaskHistorySubscription } from '../../services/automated-tasks-service'
import DialogButton from '@/components/documentation/buttons/DialogButton'

export default {
  props: {
    automatedTask: Object,
    operators: Array,
  },
  components: {
    DialogButton,
  },
  async created() {
    this.automatedTaskHistoryUnsubscribe =
      await getAutomatedTaskHistorySubscription(this.automatedTask.id, this)
  },
  data() {
    return {
      history: [],
      headers: [
        {
          text: this.$t('executionDate'),
          align: 'start',
          sortable: true,
          value: 'executedAt',
        },
        {
          text: this.$t('action'),
          align: 'start',
          sortable: true,
          value: 'action',
        },
        {
          text: this.$t('subjectType'),
          align: 'start',
          sortable: true,
          value: 'subjectType',
        },
        {
          text: this.$t('subjectId'),
          align: 'start',
          sortable: true,
          value: 'subjectId',
        },
        {
          text: this.$t('data'),
          align: 'start',
          sortable: true,
          value: 'targets',
        },
        {
          text: this.$t('result'),
          align: 'start',
          sortable: true,
          value: 'results',
        },
      ],
      automatedTaskHistoryUnsubscribe: () => {},
      loading: true,
      reversed: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Closes the dialog
     */
    closeDialog() {
      this.$emit('close')
    },
    /**
     * Gets the operator name and surname given his Id.
     * @param {String} operatorId Id of the operator.
     */
    getOperatorNameSurnameFromId(operatorId) {
      const operator = this.operators.find((o) => o.id === operatorId)
      return operator ? operator.name + ' ' + operator.surname : ''
    },
    /**
     * Gets a shorterned version of the string.
     * @param {String} text String to short.
     * @param {String} maxLength Maximum length of the string before shorting.
     * @returns String shortened.
     */
    getShortText(text, maxLength = 25) {
      return text?.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text
    },
    /**
     * Given the targets it returns a summary.
     * @param {Array} targets List of targets.
     */
    getTargetsShortData(targets) {
      const strs = []

      for (const target of targets)
        if (target.name === 'alert')
          strs.push(this.getShortText(target.data.text, 40))
        else if (target.name === 'email')
          strs.push(this.getShortText(target.data.emails, 40))
        else if (target.name === 'operator')
          strs.push(
            this.getShortText(
              this.getOperatorNameSurnameFromId(target.data),
              40
            )
          )
        else if (target.name === 'text')
          strs.push(this.getShortText(target.data, 40))
        else if (target.name === 'httpsRequest')
          strs.push(
            this.getShortText(
              this.$t('method') +
                ': ' +
                target.data.method +
                ' - URL:' +
                target.data.url,
              40
            )
          )

      return strs.join(' - ')
    },
    /**
     * Given the targets it returns all the data related.
     * @param {Array} targets List of targets.
     */
    getTargetsData(targets) {
      const strs = []

      for (const target of targets)
        if (target.name === 'alert')
          strs.push(
            `- ${this.$t('daysAfter')}: ${target.data.daysAfter}\n- ${this.$t(
              'text'
            )}: ${target.data.text}`
          )
        else if (target.name === 'email')
          strs.push(
            `- ${this.$t('emails')}: ${target.data.emails}\n- ${this.$t(
              'emailSubject'
            )}: ${target.data.subject}\n- ${this.$t('emailText')}: ${
              target.data.emailText
            }`
          )
        else if (target.name === 'operator')
          strs.push(
            `- ${this.$t('justName')}: ${this.getOperatorNameSurnameFromId(
              target.data
            )}`
          )
        else if (target.name === 'text')
          strs.push(`- ${this.$t('text')}: ${target.data}`)
        else if (target.name === 'httpsRequest')
          strs.push(
            `- ${this.$t('method')}: ${target.data.method}\n- URL: ${
              target.data.url
            }\n- ${this.$t('headers')}: ${target.data.headers
              .map((h) => `\n    * ${h.headerTitle}: ${h.headerValue}`)
              .join('')}\n- ${this.$t('bodyJSON')}: ${target.data.body}`
          )

      return strs.join(' - ')
    },
    /**
     * Given the targets it returns a summary.
     * @param {Array} targets List of targets.
     */
    getResultShortData(results) {
      const strs = []
      for (const result of results)
        if (result.status) {
          strs.push(this.$t('statusCode') + ': ' + result.status)
        } else if (result.error) {
          strs.push(
            this.$t('error') + ': ' + this.getShortText(result.error, 30)
          )
        } else if (result.includes('failed')) {
          strs.push(this.$t('error') + ': ' + this.getShortText(result, 30))
        } else strs.push(this.$t('success'))

      return strs.join(' - ')
    },
    /**
     * Given the targets it returns all the data related.
     * @param {Array} targets List of targets.
     */
    getResultData(results) {
      const strs = []

      for (const result of results)
        if (!result.error && !result.includes('failed'))
          strs.push(
            '- ' +
              this.$t('statusCode') +
              ': ' +
              (result.status ? result.status : 'OK') +
              '\n' +
              (result.response
                ? this.$t('response') +
                  ': ' +
                  (' - ' + result.response
                    ? this.getShortText(JSON.stringify(result.response), 50)
                    : 'no data') +
                  '\n'
                : '')
          )
        else if (result.error)
          strs.push('- ' + this.$t('error') + ': ' + result.error)
        else if (result.includes('failed'))
          strs.push('- ' + this.$t('error') + ': ' + result)
      return strs.join(' - ')
    },
    /**
     * Copies the result in the clipboard.
     * @param {string} response Data to copy.
     */
    async copyResult(response) {
      try {
        await navigator.clipboard.writeText(JSON.stringify(response, null, 2))

        const message = this.$t('responseCopiedSuccesfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('responseCopiedError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    historyComputed() {
      const history = this.history.map((h) => ({
        ...h,
        executedAt: new Date(h.executedAt.toDate()),
      }))

      return this.reversed ? history.reverse() : history
    },
  },
  watch: {},
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
