<template style="position: sticky">
  <v-row>
    <HomeDoc
      id="documentation-home"
      v-if="$route.query.section === 'home' || !$route.query.section"
    />

    <!-- Operators documentation -->
    <OperatorsCreateDoc
      id="documentation-operator-create"
      v-if="$route.query.section === 'operators' && !$route.query.subsection"
    />
    <OperatorsCreateCSVDoc
      id="documentation-operator-create-csv"
      v-if="
        $route.query.section === 'operators' &&
        $route.query.subsection === 'createCSV'
      "
    />
    <OperatorsCreateDoc
      v-if="
        $route.query.section === 'operators' &&
        $route.query.subsection === 'create'
      "
    />
    <OperatorsUpdateDoc
      id="documentation-operator-update"
      v-if="
        $route.query.section === 'operators' &&
        $route.query.subsection === 'update'
      "
    />
    <OperatorsDisableDoc
      id="documentation-operator-disable"
      v-if="
        $route.query.section === 'operators' &&
        $route.query.subsection === 'disable'
      "
    />
    <OperatorsManageAdminsDoc
      id="documentation-operator-disable"
      v-if="
        (isSuperAdminUser || isSuperOperatorUser) &&
        $route.query.section === 'operators' &&
        $route.query.subsection === 'manageAdmins'
      "
    />
    <!-- Notifications documentation -->
    <NotificationListDoc
      v-if="
        $route.query.section === 'notifications' && !$route.query.subsection
      "
    />
    <NotificationListDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'notificationList'
      "
    />
    <NotificationMassiveActionsDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'notificationMassiveActions'
      "
    />
    <NotificationDetailDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'notificationDetail'
      "
    />
    <NotificationResponsabilityDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'responsability'
      "
    />
    <NotificationOpenDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'notificationOpen'
      "
    />
    <NotificationChangeStatusDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'changeStatus'
      "
    />
    <NotificationShareDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'notificationShare'
      "
    />
    <NotificationAddDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'addNotification'
      "
    />
    <NotificationUploadFilesDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'uploadFiles'
      "
    />
    <AlertCreateDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'alertCreate'
      "
    />
    <AlertModifyDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'alertModify'
      "
    />

    <NotificationAddExtraDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'addExtraDocument'
      "
    />
    <NotificationAddObservationDoc
      v-if="
        $route.query.section === 'notifications' &&
        $route.query.subsection === 'addObservation'
      "
    />
    <!-- Dashboard documentation -->
    <DashboardDoc
      v-if="$route.query.section === 'dashboard' && !$route.query.subsection"
    />
    <DashboardNotificationsDoc
      v-if="
        $route.query.section === 'dashboard' &&
        $route.query.subsection === 'notifications'
      "
    />
    <DashboardAssignmentsDoc
      v-if="
        $route.query.section === 'dashboard' &&
        $route.query.subsection === 'assignments'
      "
    />
    <DashboardAlertsDoc
      v-if="
        $route.query.section === 'dashboard' &&
        $route.query.subsection === 'alerts'
      "
    />
    <DashboardSignaturesDoc
      v-if="
        $route.query.section === 'dashboard' &&
        $route.query.subsection === 'signatures'
      "
    />

    <!-- Operators documentation -->
    <SignatureListDoc
      id="documentation-operator-create"
      v-if="$route.query.section === 'signatures' && !$route.query.subsection"
    />
    <SignatureListDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureList'
      "
    />

    <SignatureCreateRequestDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'createSignature'
      "
    />

    <SignatureDetailDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureDetail'
      "
    />

    <SignatureSignDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureSign'
      "
    />

    <SignatureAbandonDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureAbandon'
      "
    />

    <SignatureDeleteDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureDelete'
      "
    />

    <SignatureRejectDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureReject'
      "
    />

    <SignatureForwardDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureForward'
      "
    />

    <SignatureIncidenceStatusDoc
      v-if="
        $route.query.section === 'signatures' &&
        $route.query.subsection === 'signatureIncidence'
      "
    />

    <!-- Documentacion de Configuracion -->
    <ConfigurationDoc
      v-if="
        $route.query.section === 'configuration' && !$route.query.subsection
      "
    />
    <ConfigurationEmailOptionsDoc
      v-if="
        $route.query.section === 'configuration' &&
        $route.query.subsection === 'emailOptions'
      "
    />
    <ConfigurationUploadCertificateDoc
      v-if="
        $route.query.section === 'configuration' &&
        $route.query.subsection === 'uploadCertificate'
      "
    />
    <ConfigurationLicensesDoc
      v-if="
        $route.query.section === 'configuration' &&
        $route.query.subsection === 'licenses'
      "
    />
    <!-- Documentacion de empresas -->
    <CompaniesDoc
      v-if="$route.query.section === 'companies' && !$route.query.subsection"
    />
    <CompaniesCreateDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'create'
      "
    />
    <CompaniesUpdateDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'update'
      "
    />
    <CompaniesDeleteDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'delete'
      "
    />
    <CompaniesOperatorsDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'operators'
      "
    />
    <CompaniesExportDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'export'
      "
    />
    <CompaniesCreateCSVDoc
      v-if="
        $route.query.section === 'companies' &&
        $route.query.subsection === 'createCSV' &&
        !isEmpresaUser
      "
    />

    <!-- Documentacion de CLIENTES -->
    <ClientsDoc
      v-if="$route.query.section === 'clients' && !$route.query.subsection"
    />
    <ClientsCreateDoc
      v-if="
        $route.query.section === 'clients' &&
        $route.query.subsection === 'create'
      "
    />
    <ClientsUploadDoc
      v-if="
        $route.query.section === 'clients' &&
        $route.query.subsection === 'upload'
      "
    />
    <ClientsDeleteDoc
      v-if="
        $route.query.section === 'clients' &&
        $route.query.subsection === 'delete'
      "
    />
    <ClientsEditDoc
      v-if="
        $route.query.section === 'clients' && $route.query.subsection === 'edit'
      "
    />

    <!-- Documentacion de Tareas automatizadas -->
    <AutomatedTasksDoc
      v-if="
        $route.query.section === 'automatedTasks' && !$route.query.subsection
      "
    />
    <AutomatedTasksActionsDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'actions'
      "
    />
    <AutomatedTasksConditionsDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'conditions'
      "
    />
    <AutomatedTasksTargetsDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'targets'
      "
    />
    <AutomatedTasksCreationDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'creation'
      "
    />
    <AutomatedTasksClonationDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'clonation'
      "
    />
    <AutomatedTasksUpdateDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'update'
      "
    />
    <AutomatedTasksDeleteDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'deletion'
      "
    />
    <AutomatedTasksHistoryDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'history'
      "
    />
    <AutomatedTasksExamplesDoc
      v-if="
        $route.query.section === 'automatedTasks' &&
        $route.query.subsection === 'examples'
      "
    />

    <!-- Groups documentation -->
    <GroupsDoc
      v-if="$route.query.section === 'groups' && !$route.query.subsection"
    />
    <GroupsCreateDoc
      v-if="
        $route.query.section === 'groups' &&
        $route.query.subsection === 'create'
      "
    />
    <GroupsUpdateDoc
      v-if="
        $route.query.section === 'groups' &&
        $route.query.subsection === 'update'
      "
    />
    <GroupsDeleteDoc
      v-if="
        $route.query.section === 'groups' &&
        $route.query.subsection === 'delete'
      "
    />
    <GroupsUnregisteredCifsDoc
      v-if="
        $route.query.section === 'groups' &&
        $route.query.subsection === 'unregisteredCifs'
      "
    />

    <!-- Analiticas -->
    <AnalyticsDoc
      v-if="$route.query.section === 'analytics' || !$route.query.subsection"
    />
  </v-row>
</template>

<script>
import HomeDoc from '@/components/documentation/sections/HomeDoc'
import OperatorsCreateDoc from '@/components/documentation/sections/operators/OperatorsCreateDoc'
import OperatorsCreateCSVDoc from '@/components/documentation/sections/operators/OperatorsCreateCSVDoc'
import OperatorsUpdateDoc from '@/components/documentation/sections/operators/OperatorsUpdateDoc'
import OperatorsDisableDoc from '@/components/documentation/sections/operators/OperatorsDisableDoc'
import OperatorsManageAdminsDoc from '@/components/documentation/sections/operators/OperatorsManageAdminsDoc'
import DashboardDoc from '@/components/documentation/sections/dashboard/DashboardDoc'
import DashboardNotificationsDoc from '@/components/documentation/sections/dashboard/DashboardNotificationsDoc'
import DashboardAssignmentsDoc from '@/components/documentation/sections/dashboard/DashboardAssignmentsDoc'
import DashboardAlertsDoc from '@/components/documentation/sections/dashboard/DashboardAlertsDoc'
import DashboardSignaturesDoc from '@/components/documentation/sections/dashboard/DashboardSignaturesDoc'
import NotificationListDoc from '@/components/documentation/sections/notifications/NotificationListDoc'
import NotificationMassiveActionsDoc from '@/components/documentation/sections/notifications/NotificationMassiveActionsDoc'
import NotificationDetailDoc from '@/components/documentation/sections/notifications/NotificationDetailDoc'
import NotificationResponsabilityDoc from '@/components/documentation/sections/notifications/NotificationResponsabilityDoc'
import NotificationOpenDoc from '@/components/documentation/sections/notifications/NotificationOpenDoc'
import NotificationChangeStatusDoc from '@/components/documentation/sections/notifications/NotificationChangeStatusDoc'
import NotificationShareDoc from '@/components/documentation/sections/notifications/NotificationShareDoc'
import NotificationAddDoc from '@/components/documentation/sections/notifications/NotificationAddDoc'
import NotificationUploadFilesDoc from '@/components/documentation/sections/notifications/NotificationUploadFilesDoc'
import AlertCreateDoc from '@/components/documentation/sections/AlertCreateDoc'
import AlertModifyDoc from '@/components/documentation/sections/AlertModifyDoc'
import NotificationAddExtraDoc from '@/components/documentation/sections/notifications/NotificationAddExtraDoc'
import NotificationAddObservationDoc from '@/components/documentation/sections/notifications/NotificationAddObservationDoc'
import ConfigurationDoc from '@/components/documentation/sections/configuration/ConfigurationDoc'
import ConfigurationEmailOptionsDoc from '@/components/documentation/sections/configuration/ConfigurationEmailOptionsDoc'
import ConfigurationUploadCertificateDoc from '@/components/documentation/sections/configuration/ConfigurationUploadCertificateDoc'
import ConfigurationLicensesDoc from '@/components/documentation/sections/configuration/ConfigurationLicensesDoc'
import CompaniesDoc from '@/components/documentation/sections/companies/CompaniesDoc'
import CompaniesCreateDoc from '@/components/documentation/sections/companies/CompaniesCreateDoc'
import CompaniesCreateCSVDoc from '@/components/documentation/sections/companies/CompaniesCreateCSVDoc'
import CompaniesUpdateDoc from '@/components/documentation/sections/companies/CompaniesUpdateDoc'
import CompaniesDeleteDoc from '@/components/documentation/sections/companies/CompaniesDeleteDoc'
import CompaniesOperatorsDoc from '@/components/documentation/sections/companies/CompaniesOperatorsDoc'
import CompaniesExportDoc from '@/components/documentation/sections/companies/CompaniesExportDoc'
import SignatureCreateRequestDoc from '@/components/documentation/sections/signatures/SignatureCreateRequestDoc'
import SignatureListDoc from '@/components/documentation/sections/signatures/SignatureListDoc'
import SignatureDetailDoc from '@/components/documentation/sections/signatures/SignatureDetailDoc'
import SignatureAbandonDoc from '@/components/documentation/sections/signatures/SignatureAbandonDoc'
import SignatureSignDoc from '@/components/documentation/sections/signatures/SignatureSignDoc'
import SignatureDeleteDoc from '@/components/documentation/sections/signatures/SignatureDeleteDoc'
import SignatureRejectDoc from '@/components/documentation/sections/signatures/SignatureRejectDoc'
import SignatureForwardDoc from '@/components/documentation/sections/signatures/SignatureForwardDoc'
import SignatureIncidenceStatusDoc from '@/components/documentation/sections/signatures/SignatureIncidenceStatusDoc'
import ClientsDoc from '@/components/documentation/sections/clients/ClientsDoc'
import ClientsCreateDoc from '@/components/documentation/sections/clients/ClientsCreateDoc'
import ClientsUploadDoc from '@/components/documentation/sections/clients/ClientsUploadDoc'
import ClientsDeleteDoc from '@/components/documentation/sections/clients/ClientsDeleteDoc'
import ClientsEditDoc from '@/components/documentation/sections/clients/ClientsEditDoc'
import AutomatedTasksDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksDoc'
import AutomatedTasksActionsDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksActionsDoc'
import AutomatedTasksConditionsDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksConditionsDoc'
import AutomatedTasksTargetsDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksTargetsDoc'
import AutomatedTasksCreationDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksCreationDoc'
import AutomatedTasksClonationDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksClonationDoc'
import AutomatedTasksUpdateDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksUpdateDoc'
import AutomatedTasksDeleteDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksDeleteDoc'
import AutomatedTasksHistoryDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksHistoryDoc'
import AutomatedTasksExamplesDoc from '@/components/documentation/sections/automatedTasks/AutomatedTasksExamplesDoc'
import GroupsDoc from '@/components/documentation/sections/groups/GroupsDoc'
import GroupsCreateDoc from '@/components/documentation/sections/groups/GroupsCreateDoc'
import GroupsUpdateDoc from '@/components/documentation/sections/groups/GroupsUpdateDoc'
import GroupsDeleteDoc from '@/components/documentation/sections/groups/GroupsDeleteDoc'
import GroupsUnregisteredCifsDoc from '@/components/documentation/sections/groups/GroupsUnregisteredCifsDoc'
import AnalyticsDoc from '@/components/documentation/sections/analytics/AnalyticsDoc'
import { mapGetters } from 'vuex'

export default {
  components: {
    HomeDoc,
    OperatorsCreateDoc,
    OperatorsCreateCSVDoc,
    OperatorsUpdateDoc,
    OperatorsDisableDoc,
    OperatorsManageAdminsDoc,
    DashboardDoc,
    DashboardNotificationsDoc,
    DashboardAssignmentsDoc,
    DashboardAlertsDoc,
    DashboardSignaturesDoc,
    NotificationListDoc,
    NotificationMassiveActionsDoc,
    NotificationDetailDoc,
    NotificationResponsabilityDoc,
    NotificationOpenDoc,
    NotificationChangeStatusDoc,
    NotificationShareDoc,
    NotificationAddDoc,
    NotificationUploadFilesDoc,
    AlertCreateDoc,
    AlertModifyDoc,
    NotificationAddExtraDoc,
    NotificationAddObservationDoc,
    ConfigurationDoc,
    ConfigurationEmailOptionsDoc,
    ConfigurationUploadCertificateDoc,
    ConfigurationLicensesDoc,
    CompaniesDoc,
    CompaniesCreateDoc,
    CompaniesCreateCSVDoc,
    CompaniesUpdateDoc,
    CompaniesDeleteDoc,
    CompaniesOperatorsDoc,
    CompaniesExportDoc,
    SignatureCreateRequestDoc,
    SignatureListDoc,
    SignatureDetailDoc,
    SignatureAbandonDoc,
    SignatureSignDoc,
    SignatureDeleteDoc,
    SignatureRejectDoc,
    SignatureForwardDoc,
    SignatureIncidenceStatusDoc,
    ClientsDoc,
    ClientsCreateDoc,
    ClientsUploadDoc,
    ClientsDeleteDoc,
    ClientsEditDoc,
    AutomatedTasksDoc,
    AutomatedTasksActionsDoc,
    AutomatedTasksConditionsDoc,
    AutomatedTasksTargetsDoc,
    AutomatedTasksCreationDoc,
    AutomatedTasksClonationDoc,
    AutomatedTasksUpdateDoc,
    AutomatedTasksDeleteDoc,
    AutomatedTasksHistoryDoc,
    AutomatedTasksExamplesDoc,
    GroupsDoc,
    GroupsCreateDoc,
    GroupsUpdateDoc,
    GroupsDeleteDoc,
    GroupsUnregisteredCifsDoc,
    AnalyticsDoc,
  },
  computed: {
    ...mapGetters([
      'isOperatorsUser',
      'isEmpresaUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
    ]),
  },
}
</script>
