import { Firestore, WalletDoc } from '@/firebase-exports'

/**
 * Obtiene el tipo de comunicación de la cartera del usuario y devuelve el valor correspondiente.
 * @param {string} userId - El ID del usuario.
 * @param {string} walletId - El ID de la cartera.
 * @returns {Promise<string>} - El tipo de comunicación de la cartera.
 */
export async function getWalletCommunicationType(userId, walletId) {
  const docSnap = await Firestore.getDoc(WalletDoc(userId, walletId))
  if (!docSnap.exists()) return
  const document = docSnap.data()

  // Delete some back variables that could be unsafe to store in front
  delete document.billing_password
  delete document.billing_username
  delete document.certificate_password
  delete document.certificate_pin
  delete document.certificate_user
  delete document.telefono
  delete document.pass
  delete document.username

  return document.communicationType
}

/**
 * Obtiene el tipo de autorización de la cartera del usuario y devuelve el valor correspondiente.
 * @param {string} userId - El ID del usuario.
 * @param {string} walletId - El ID de la cartera.
 * @returns {Promise<string>} - El tipo de autorización de la cartera.
 */
export async function getWalletAuthorizationType(userId, walletId) {
  const docSnap = await Firestore.getDoc(WalletDoc(userId, walletId))
  if (!docSnap.exists()) return
  const document = docSnap.data()

  // Delete some back variables that could be unsafe to store in front
  delete document.billing_password
  delete document.billing_username
  delete document.certificate_password
  delete document.certificate_pin
  delete document.certificate_user
  delete document.telefono
  delete document.pass
  delete document.username

  return document.authorizationType
}
