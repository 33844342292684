<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.clients.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.clients.paragraph1') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.paragraph2') }}
    </p>

    <ul>
      <li
        @click="goDoc('clients', 'create')"
        class="custom-link"
        v-if="!isClienteDespachoUser"
      >
        <h3>
          <b>{{ $t('documentation.texts.clients.li1') }}</b>
        </h3>
      </li>
      <li @click="goDoc('clients', 'upload')" class="custom-link">
        <h3>
          <b>{{ $t('documentation.texts.clients.li2') }}</b>
        </h3>
      </li>
      <li @click="goDoc('clients', 'delete')" class="custom-link">
        <h3>
          <b>{{ $t('documentation.texts.clients.li3') }}</b>
        </h3>
      </li>
      <li @click="goDoc('clients', 'edit')" class="custom-link">
        <h3>
          <b>{{ $t('documentation.texts.clients.li4') }}</b>
        </h3>
      </li>
    </ul>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isClienteDespachoUser']),
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
