<template>
  <v-dialog v-model="dialogModel" :max-width="dialog.maxWidth" persistent>
    <v-card>
      <v-card-title class="text-h5">
        {{ dialog.title }}
      </v-card-title>
      <v-card-text>
        {{ dialog.message }}
        <small v-if="dialog.error"><br /><br />{{ dialog.error }}</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accept" text @click="closeDialog">
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    dialog: Object,
  },
  methods: {
    ...mapMutations(['setDialog']),
    closeDialog() {
      this.setDialog({ ...this.dialog, isDisplayed: false })
    },
  },
  computed: {
    dialogModel: {
      get: function () {
        return this.dialog.isDisplayed
      },
      set: function () {
        this.closeDialog()
      },
    },
  },
}
</script>
