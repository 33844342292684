<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <AppTour
          :finishFunction="tourFinishFunction"
          :autoStart="!$store.state.user.tourSignaturesFinished"
          :stepsProp="steps"
          name="signaturesTour"
        >
        </AppTour>
        <SignatureDetail
          data-v-step="700"
          v-if="signature"
          :signature="signature"
          :history="history"
          :loadingSignature="loadingSignature"
          :loadingHistory="loadingHistory"
        >
        </SignatureDetail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignatureDetail from '@/components/signatures/SignatureDetail'
import { listHistorySubscription } from '@/services/history-service'
import { getSignatureSubscription } from '@/services/signatures-service'
import { mapGetters, mapMutations } from 'vuex'
import { signatureDetailSteps } from '@/utils/tour-utils.js'
import { updateUserInfo } from '@/services/user-service'
import AppTour from '@/components/app-bar/AppTour'

export default {
  components: {
    SignatureDetail,
    AppTour,
  },
  async mounted() {
    //si el usuario es operador se debe coger el id del padre para pedir notificaciones
    this.userSignaturesId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id
    await this.getSignature()
    await this.getHistory()
  },
  destroyed() {
    if (this.historyUnsubscribe) this.historyUnsubscribe()
    if (this.signatureUnsubscribe) this.signatureUnsubscribe()
  },
  data() {
    return {
      userSignaturesId: '',
      signature: {},
      history: [],
      signatureUnsubscribe: () => {},
      historyUnsubscribe: () => {},
      loadingSignature: true,
      loadingHistory: true,
      steps: signatureDetailSteps(),
    }
  },
  computed: {
    ...mapGetters(['isOperatorUser', 'isAdminUser']),
  },
  methods: {
    ...mapMutations(['setNotFound']),
    async getSignature() {
      this.signatureUnsubscribe = await getSignatureSubscription(
        this.userSignaturesId,
        this.$route.params.id,
        this
      )

      // If the signatureUnsubscribe is undefined it means this signature doesn't exist
      if (!this.signatureUnsubscribe) {
        return this.setNotFound(true)
      }

      // If the user is not allowed to see the signature, set it to not found
      if (!this.isAdminUser) {
        // Waiting subscription data is retrieved
        while (this.loadingSignature) {
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        if (
          this.signature.requesterRef.id !== this.$store.state.user.id &&
          !this.signature.clientsToSign.some(
            (client) => client.id === this.$store.state.user.id
          )
        )
          return this.setNotFound(true)
      }
    },
    async getHistory() {
      this.historyUnsubscribe = await listHistorySubscription(
        this.userSignaturesId,
        'signatures',
        this.$route.params.id,
        this,
        'asc'
      )

      // If the historynUnsubscribe is undefined it means this history doesn't exist
      if (!this.historyUnsubscribe) this.history = []
    },
    async tourFinishFunction() {
      await updateUserInfo(this.$store.state.user.id, {
        tourSignaturesFinished: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
