<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.signatures.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.signatures.createSignature.title') }}
    </h2>
    <p>{{ $t('documentation.texts.signatures.createSignature.paragraph1') }}</p>
    <v-img style="width: 100%; max-width: 400px" contain :src="pic1Name" />
    <p>{{ $t('documentation.texts.signatures.createSignature.paragraph2') }}</p>
    <p>
      <b>{{
        $t('documentation.texts.signatures.createSignature.simpleSignature')
      }}</b>
    </p>
    <p style="padding-left: 45px">
      {{ $t('documentation.texts.signatures.createSignature.paragraph3') }}
    </p>
    <p>
      <b>
        {{
          $t('documentation.texts.signatures.createSignature.multipleSignature')
        }}</b
      >
    </p>
    <p style="padding-left: 45px">
      {{ $t('documentation.texts.signatures.createSignature.paragraph4') }}
    </p>
    <p>
      <b>
        {{
          $t('documentation.texts.signatures.createSignature.jointSignature')
        }}
      </b>
    </p>
    <p style="padding-left: 45px">
      {{ $t('documentation.texts.signatures.createSignature.paragraph5') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="picJoint" />
    <p>
      <b>
        {{
          $t('documentation.texts.signatures.createSignature.deferredSignature')
        }}
      </b>
    </p>
    <p style="padding-left: 45px">
      {{
        $t('documentation.texts.signatures.createSignature.deferredParagraph')
      }}
    </p>
    <p>
      {{
        $t('documentation.texts.signatures.createSignature.purposeSignature')
      }}
    </p>

    <p>
      {{
        $t(
          'documentation.texts.signatures.createSignature.observationSignature'
        )
      }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picSignatureForm"
    />
    <p>
      <b>
        {{
          $t('documentation.texts.signatures.createSignature.uploadFilesTitle')
        }}
      </b>
    </p>
    <p style="padding-left: 45px">
      {{
        $t(
          'documentation.texts.signatures.createSignature.uploadFilesParagraph'
        )
      }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picUploadFiles1"
    />
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picUploadFiles2"
    />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picSignatureMenu'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picSignatureForm'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picSignatureUpload1'
        ) +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picSignatureUpload2'
        ) +
        '.png')
    },
    picJoint() {
      return require('@/assets/documentation/signatures/' +
        this.$t('documentation.texts.signatures.createSignature.picJoint') +
        '.png')
    },
    picSignatureForm() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picSignatureForm'
        ) +
        '.png')
    },
    picUploadFiles1() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picUploadFiles1'
        ) +
        '.png')
    },
    picUploadFiles2() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.createSignature.picUploadFiles2'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
