<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <MaintenanceCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MaintenanceCard from '@/components/maintenance/MaintenanceCard'
export default {
  components: {
    MaintenanceCard,
  },
}
</script>

<style lang="scss" scoped></style>
