<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <h2 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.update.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.automatedTasks.update.p1') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.update.p2') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.update.p3') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic2Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.update.p4') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic3Name"
    />
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.update.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.update.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.update.pic3') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
