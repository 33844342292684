<template>
  <div>
    <v-avatar :color="avatarColor" :size="avatarSize">
      <v-icon v-if="isGroup" :style="`font-size: ${iconSize}px;`">
        mdi-account-group-outline
      </v-icon>
      <span
        v-else-if="!userAvatar"
        :class="`${initialsColor}--text`"
        :style="`font-size: ${initialsSize}px`"
      >
        <template v-if="userName">
          {{
            userName
              .split(' ')
              .map((n) => n[0])
              .join('')
              .substring(0, 3)
          }}
        </template>
      </span>
      <v-img v-else elevation="2" :src="userAvatar" />
    </v-avatar>
    <v-avatar
      v-if="userRole"
      :color="overlapAvatarColor"
      :size="overlapAvatarSize"
      :style="{
        'margin-left': `${-avatarSize / 2.5}px`,
        'margin-top': `${avatarSize / 1.5}px`,
        position: 'absolute',
      }"
    >
    </v-avatar>
    <v-icon
      v-if="showWarn"
      :style="{
        'font-size': `${overlapIconSize}px`,
        'margin-left': `${-avatarSize / 2.5}px`,
        'margin-top': `${avatarSize / 1.5}px`,
        position: 'absolute',
      }"
      :color="overlapIconColor"
      >mdi-alert
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    avatarColor: {
      type: String,
      default: 'secondary',
    },
    initialsColor: {
      type: String,
      default: 'primary',
    },
    initialsSize: Number,
    avatarSize: String,
    iconSize: String,
    isGroup: Boolean,
    userAvatar: String,
    userName: String,
    userRole: String,
    showWarn: Boolean,

    overlapAvatarColor: {
      type: String,
      default: 'primary',
    },
    overlapIconColor: {
      type: String,
      default: 'warning',
    },
    overlapAvatarSize: {
      type: String,
      default: '25',
    },
    overlapIconSize: {
      type: String,
      default: '20',
    },
  },
}
</script>

<style lang="scss" scoped></style>
