<template>
  <v-dialog :value="showDialog" persistent max-width="1100">
    <v-card class="pa-4">
      <v-card-title style="word-break: break-word">
        <v-row justify="center" class="mb-5">
          {{ $t('emailsConfiguration.dialogTitle') }}
          <DialogButton section="configuration" subsection="emailOptions" />
        </v-row>
      </v-card-title>

      <v-row class="" justify="center">
        <v-col
          class="ma-5"
          cols="12"
          :lg="isFirmasActivas ? 5 : 10"
          v-if="isNotificacionesActivas"
        >
          <h3 class="d-flex justify-center">{{ $t('notifications') }}</h3>
          <v-checkbox
            v-model="notificationsAllCheckBox"
            :label="$t('emailsConfiguration.selectAllCheckbox')"
            id="notifications-all-checkbox"
          >
          </v-checkbox>

          <v-card class="pa-4">
            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificationNew"
              :label="$t('emailsConfiguration.notificationNew')"
              id="notification-new"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificationHasBeenOpened"
              :label="$t('emailsConfiguration.notificationHasBeenOpened')"
              id="notification-has-been-opened"
            />

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificacionAboutToExpire"
              :label="$t('emailsConfiguration.notificacionAboutToExpire')"
              id="notificacion-about-to-expire"
            >
            </v-checkbox>

            <v-checkbox
              v-if="isSuperAdminUser"
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificationSetResponsible"
              :label="$t('emailsConfiguration.notificationSetResponsible')"
              id="notification-set-responsible"
            >
            </v-checkbox>

            <v-checkbox
              v-if="isSuperAdminUser"
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificationNoLongerResponsible"
              :label="$t('emailsConfiguration.notificationNoLongerResponsible')"
              id="notification-no-longer-responsible"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.notificationYouAreResponsible"
              :label="$t('emailsConfiguration.notificationYouAreResponsible')"
              id="notification-you-are-responsible"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="
                emailsConfiguration.notificationYouAreNoLongerResponsible
              "
              :label="
                $t('emailsConfiguration.notificationYouAreNoLongerResponsible')
              "
              id="notification-you-are-no-longer-responsible"
            >
            </v-checkbox>
          </v-card>
        </v-col>

        <v-col
          class="ma-5"
          cols="12"
          :lg="isNotificacionesActivas ? 5 : 10"
          v-if="isFirmasActivas"
        >
          <h3 class="d-flex justify-center">{{ $t('signatures') }}</h3>
          <v-checkbox
            v-model="signaturesAllCheckBox"
            :label="$t('emailsConfiguration.selectAllCheckbox')"
            id="signatures-all-checkbox"
          >
          </v-checkbox>

          <v-card class="pa-4">
            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureNewRequest"
              :label="$t('emailsConfiguration.signatureNewRequest')"
              id="signature-new-request"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureRequestAboutToExpire"
              :label="$t('emailsConfiguration.signatureRequestAboutToExpire')"
              id="signature-request-about-to-expire"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureRequestSigned"
              :label="$t('emailsConfiguration.signatureRequestSigned')"
              id="signature-request-signed"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureRequestRejected"
              :label="$t('emailsConfiguration.signatureRequestRejected')"
              id="signature-request-rejected"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureRequestSubmittedForReview"
              :label="
                $t('emailsConfiguration.signatureRequestSubmittedForReview')
              "
              id="signature-request-submited-for-review"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureRequestAbandoned"
              :label="$t('emailsConfiguration.signatureRequestAbandoned')"
              id="signature-request-abandoned"
            >
            </v-checkbox>

            <v-checkbox
              class="mb-0 mt-0 pb-0 pt-0"
              v-model="emailsConfiguration.signatureIncidence"
              :label="$t('emailsConfiguration.signatureIncidence')"
              id="signature-incidence"
            >
            </v-checkbox>
          </v-card>
        </v-col>
      </v-row>

      <v-card-actions class="headline justify-center">
        <v-btn
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          :disabled="loading"
          id="close-emails-dialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="performAction"
          :disabled="loading"
          :loading="loading"
          id="confirm-emails-dialog"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateUserInfo } from '@/services/user-service'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    showDialog: Boolean,
    emailsConfigurationProp: Object,
  },
  components: {
    DialogButton,
  },
  mounted() {
    // Si existe la variable en el usuario, combinamos los valores predefinidos con los del usuario
    // De esta forma, si algún valor no estuviera en la variable del usuario, mantendría el predefinido
    if (this.emailsConfigurationProp)
      this.emailsConfiguration = {
        ...this.emailsConfiguration,
        ...this.emailsConfigurationProp,
      }
  },
  data() {
    return {
      emailsConfiguration: {
        notificationNew: false,
        notificationHasBeenOpened: false,
        notificationYouAreNoLongerResponsible: false,
        notificationSetResponsible: false,
        notificationYouAreResponsible: false,
        notificationNoLongerResponsible: false,
        notificacionAboutToExpire: false,
        signatureRequestRejected: false,
        signatureIncidence: false,
        signatureRequestSubmittedForReview: false,
        signatureNewRequest: false,
        signatureRequestAbandoned: false,
        signatureRequestSigned: false,
        signatureRequestAboutToExpire: false,
      },
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    closeDialog() {
      this.$emit('closeEmailsDialog')
    },
    async performAction() {
      try {
        this.loading = true
        await updateUserInfo(this.$store.state.user.id, {
          emailsConfiguration: this.emailsConfiguration,
        })
        this.closeDialog()
        this.loading = false

        const message = this.$t('emailsConfiguration.updateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.loading = true

        const message = this.$t('emailsConfiguration.updateError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isSuperAdminUser',
      'isNotificacionesActivas',
      'isFirmasActivas',
    ]),
    notificationsAllCheckBox: {
      get: function () {
        // compute the value for the notifications main checkbox
        return (
          this.emailsConfiguration.notificationNew &&
          this.emailsConfiguration.notificationHasBeenOpened &&
          this.emailsConfiguration.notificacionAboutToExpire &&
          (this.isSuperAdminUser
            ? this.emailsConfiguration.notificationSetResponsible
            : true) &&
          (this.isSuperAdminUser
            ? this.emailsConfiguration.notificationNoLongerResponsible
            : true) &&
          this.emailsConfiguration.notificationYouAreResponsible &&
          this.emailsConfiguration.notificationYouAreNoLongerResponsible
        )
      },
      set: function (val) {
        // update all of the notifications checkboxes to the new value of the main checkbox.
        this.emailsConfiguration.notificationNew = val
        this.emailsConfiguration.notificationHasBeenOpened = val
        this.emailsConfiguration.notificacionAboutToExpire = val
        if (this.isSuperAdminUser) {
          this.emailsConfiguration.notificationSetResponsible = val
          this.emailsConfiguration.notificationNoLongerResponsible = val
        }
        this.emailsConfiguration.notificationYouAreResponsible = val
        this.emailsConfiguration.notificationYouAreNoLongerResponsible = val
      },
    },
    signaturesAllCheckBox: {
      get: function () {
        // compute the value for the signatures main checkbox
        return (
          this.emailsConfiguration.signatureNewRequest &&
          this.emailsConfiguration.signatureRequestAboutToExpire &&
          this.emailsConfiguration.signatureRequestSigned &&
          this.emailsConfiguration.signatureRequestRejected &&
          this.emailsConfiguration.signatureRequestSubmittedForReview &&
          this.emailsConfiguration.signatureRequestAbandoned &&
          this.emailsConfiguration.signatureIncidence
        )
      },
      set: function (val) {
        // update all of the signatures checkboxes to the new value of the main checkbox.
        this.emailsConfiguration.signatureNewRequest = val
        this.emailsConfiguration.signatureRequestAboutToExpire = val
        this.emailsConfiguration.signatureRequestSigned = val
        this.emailsConfiguration.signatureRequestRejected = val
        this.emailsConfiguration.signatureRequestSubmittedForReview = val
        this.emailsConfiguration.signatureRequestAbandoned = val
        this.emailsConfiguration.signatureIncidence = val
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
