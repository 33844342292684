<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <h2 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.conditions.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.automatedTasks.conditions.p1') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 400px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      :src="pic1Name"
    />
    <ul class="mt-3">
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.conditions.titleli1') }}</b
        >: {{ $t('documentation.texts.automatedTasks.conditions.textli1') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic2Name"
      />
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.conditions.titleli2') }}</b
        >: {{ $t('documentation.texts.automatedTasks.conditions.textli2') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic3Name"
      />
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.conditions.titleli3') }}</b
        >: {{ $t('documentation.texts.automatedTasks.conditions.textli3') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic4Name"
      />
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.conditions.titleli4') }}</b
        >: {{ $t('documentation.texts.automatedTasks.conditions.textli4') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic5Name"
      />
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.conditions.titleli5') }}</b
        >: {{ $t('documentation.texts.automatedTasks.conditions.textli5') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic6Name"
      />
    </ul>
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic5') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.conditions.pic6') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
