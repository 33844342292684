<template>
  <v-row class="d-flex justify-center align-center">
    <v-col class="d-flex justify-end align-center">
      <span class="body-2 mr-2 font-weight-light">
        {{ $t('rowsPerPage') }}:
      </span>
      <v-select
        single-line
        style="max-width: 60px"
        :items="pageSizes"
        v-model="pageSize"
        @input="pageSize = $event"
        class="h-small"
      >
        <template v-slot:selection="{ item }">
          <span class="body-2 mx-2">
            {{ item }}
          </span>
        </template>
      </v-select>
      <span class="body-2 mx-2 font-weight-regular">
        {{ page * pageSize + 1 }}-{{ (page + 1) * pageSize }} {{ $t('of') }}
        {{ items.length }}
      </span>
      <v-btn small icon @click="page -= 1" :disabled="page === 0">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        small
        icon
        @click="page += 1"
        :disabled="(page + 1) * pageSize >= items.length"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    initialPageSize: Number,
    pageSizes: Array,
    items: Array,
    search: String,
  },
  data() {
    return {
      page: 0,
      pageSize: this.initialPageSize ?? this.pageSizes[0],
      prevPageBeforeSearch: 0,
    }
  },
  beforeMount() {
    this.$emit('setPageSize', this.pageSize)
    this.$emit('setPage', this.page)
  },
  watch: {
    page: function (newValue) {
      this.$emit('setPage', newValue)
    },
    pageSize: function (newValue) {
      this.$emit('setPageSize', newValue)
      this.$emit('setPage', 0)
      this.page = 0
    },
    search: function (newValue) {
      if (newValue) {
        if (this.page !== 0) this.prevPageBeforeSearch = this.page
        this.page = 0
      } else {
        this.page = this.prevPageBeforeSearch
      }
    },
  },
}
</script>
