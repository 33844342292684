<template>
  <div>
    <v-card data-v-step="11" class="mx-auto" outlined>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="6" xl="auto" class="order-0">
            <v-row align="baseline" class="ml-1">
              <v-icon class="mr-1">mdi-robot</v-icon>
              <span class="headline">{{ $t('automatedTasks') }}</span>
              <span class="subtitle-2 font-weight-light ml-1">
                ({{ automatedTasksCount }})
              </span>
              <div class="mx-2" />
              <DialogButton
                section="automatedTasks"
                subsection="main"
                :title="$t('automatedTasks')"
                hideSubtitle
              />
            </v-row>
          </v-col>
          <v-col
            class="d-flex justify-end align-center order-1 order-xl-2"
            cols="6"
            xl="auto"
          >
            <v-tooltip bottom v-if="isAdminUser">
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="primary secondary--text ml-1 mt-1 mb-1"
                  fab
                  elevation="1"
                  small
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  :disabled="loading"
                  @click.stop="openAutomatedTaskDialog({}, 'create')"
                  id="create-automated-task"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('automatedTasksActions.title.create') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>

      <v-list-item>
        <v-list-item-content>
          <v-row class="pa-3">
            <v-text-field
              class="mr-1"
              v-model="search"
              prepend-icon="mdi-magnify"
              clearable
              :label="$t('searchAutomatedTasks')"
              single-line
              hide-details
              :disabled="loading"
            />
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <AppSkeleton :condition="loading" type="automatedTaskList">
        <template v-slot:skeleton-content>
          <v-list
            v-if="automatedTasks.length"
            max-height="400"
            class="overflow-y-auto"
          >
            <v-list-item
              :key="i"
              v-for="(automatedTask, i) in pageAutomatedTasks"
              @click.stop="openAutomatedTaskDialog(automatedTask, 'display')"
              :id="'at-' + automatedTask.name"
            >
              <v-row
                :class="isMobile ? 'mb-3' : ''"
                justify="center"
                align="center"
              >
                <v-col cols="6">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>
                        {{ automatedTask.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-col>

                <v-col cols="auto">
                  <v-chip
                    :style="!isMobile ? 'width: 100px' : ''"
                    :color="automatedTask.active ? 'processing' : 'warningLow'"
                    class="white--text justify-center"
                  >
                    <v-icon small>
                      {{ automatedTask.active ? 'mdi-play' : 'mdi-pause' }}
                    </v-icon>
                    <span class="ml-1" v-if="!isMobile">
                      {{ automatedTask.active ? $t('active') : $t('paused') }}
                    </span>
                  </v-chip>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="
                          () =>
                            editAutomatedTask(
                              automatedTask.id,
                              !automatedTask.active
                            )
                        "
                        :id="
                          'automated-task-' +
                          (automatedTask.active ? 'pause' : 'play')
                        "
                      >
                        <v-icon>
                          {{ automatedTask.active ? 'mdi-pause' : 'mdi-play' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <div>
                      {{
                        $t(
                          'automatedTasksActions.title.' +
                            (automatedTask.active ? 'pause' : 'play')
                        )
                      }}
                    </div>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" md="3" lg="2" v-if="isAdminUser">
                  <v-row :justify="isMobile ? 'center' : 'end'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'delete-automated-task-' + automatedTask.name"
                          @click.stop="handleCloneAutomatedTask(automatedTask)"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('automatedTasksActions.title.clone') }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          id="automated-task-history"
                          @click.stop="
                            openAutomatedTaskHistoryDialog(automatedTask)
                          "
                        >
                          <v-icon>mdi-clipboard-text-clock</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('automatedTasksActions.title.history') }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'update-automated-task-' + automatedTask.name"
                          @click.stop="
                            openAutomatedTaskDialog(automatedTask, 'update')
                          "
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('automatedTasksActions.title.update') }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'delete-automated-task-' + automatedTask.name"
                          @click.stop="
                            openAutomatedTaskDialog(automatedTask, 'delete')
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('automatedTasksActions.title.delete') }}
                      </div>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-card-text style="text-align: center" v-else>
            <span>
              {{ $t('noAutomatedTasks') }}
            </span>
          </v-card-text>
        </template>
      </AppSkeleton>
      <v-divider />
      <v-card-actions class="headline justify-end" style="max-height: 50px">
        <PaginateBar
          :initialPageSize="10"
          :pageSizes="[10, 25, 50]"
          :search="search"
          :items="filteredAutomatedTasks"
          @setPage="(value) => (page = value)"
          @setPageSize="(value) => (pageSize = value)"
        />
      </v-card-actions>
    </v-card>

    <AutomatedTaskDialog
      v-if="automatedTaskDialog"
      :automatedTasks="automatedTasks"
      :automatedTask="selectedAutomatedTask"
      :action="action"
      :actions="actions"
      :conditions="conditions"
      :targets="targets"
      :cifs="cifs"
      :operators="operators"
      :filters="filters"
      :groups="groups"
      @closeAutomatedTaskDialog="() => (automatedTaskDialog = false)"
    />
    <AutomatedTaskHistoryDialog
      v-if="automatedTaskHistoryDialog"
      :automatedTask="selectedAutomatedTask"
      :operators="operators"
      @close="() => (automatedTaskHistoryDialog = false)"
    />
  </div>
</template>

<script>
import PaginateBar from '@/components/commons/PaginateBar'
import { mapGetters, mapMutations } from 'vuex'
import AutomatedTaskDialog from '@/components/automated-tasks/AutomatedTaskDialog'
import AutomatedTaskHistoryDialog from '@/components/automated-tasks/AutomatedTaskHistoryDialog'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { updateAutomatedTask } from '@/services/automated-tasks-service'
import { cloneAutomatedTask } from '../../services/automated-tasks-service'
import getErrorText from '@/utils/get-error-text'

export default {
  props: {
    automatedTasks: Array,
    actions: Array,
    conditions: Array,
    targets: Array,
    cifs: Array,
    operators: Array,
    filters: Array,
    groups: Array,
    loading: Boolean,
  },
  components: {
    AutomatedTaskDialog,
    AutomatedTaskHistoryDialog,
    DialogButton,
    PaginateBar,
  },
  data() {
    return {
      page: 0,
      pageSize: 0,
      search: '',
      selectedAutomatedTask: undefined,
      action: '',
      automatedTaskDialog: false,
      automatedTaskHistoryDialog: false,
      refresh: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Opens the dialog to create/update/display/delete an automated task.
     * @param {Object} automatedTask Automated task to open (empty when creating)
     * @param {String} action .Action to perform (create, update, display, delete).
     */
    openAutomatedTaskDialog(automatedTask, action) {
      this.selectedAutomatedTask = automatedTask
      this.action = action

      this.automatedTaskDialog = true
    },
    /**
     * Opens the dialog of the history.
     * @param {Object} automatedTask Automated task to open (empty when creating)
     * @param {String} action .Action to perform (create, update, display, delete).
     */
    openAutomatedTaskHistoryDialog(automatedTask) {
      this.selectedAutomatedTask = automatedTask
      this.automatedTaskHistoryDialog = true
    },
    /**
     * Changes the active value of the automated task.
     * @param {string} id Id of the automated task.
     * @param {boolean} active Value to assign.
     */
    async editAutomatedTask(id, active) {
      await updateAutomatedTask(id, { active })
    },
    /**
     * Clones an automated task creating a new one with the name ... - 1
     * @param {Object} automatedTask Automated task to clone.
     */
    async handleCloneAutomatedTask(automatedTask) {
      // Creating the name
      let i = 1
      while (
        this.automatedTasks.some((at) =>
          at.name.includes(`${automatedTask.name} (${i})`)
        )
      )
        i++

      // Clonning the task with the name
      try {
        await cloneAutomatedTask(automatedTask, `${automatedTask.name} (${i})`)
        const message = this.$t('automatedTasksActions.success.clone')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    automatedTasksCount() {
      return this.automatedTasks.length
    },
    filteredAutomatedTasks() {
      this.refresh

      const searchUpperCase = this.search ? this.search.toUpperCase() : ''
      let filtered = this.automatedTasks?.filter((automatedTask) =>
        automatedTask.name?.toUpperCase().includes(searchUpperCase)
      )

      return filtered
    },
    pageAutomatedTasks() {
      return this.filteredAutomatedTasks.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      )
    },
  },
}
</script>
<style></style>
