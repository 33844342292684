<template>
  <v-card min-height="600" elevation="5" class="rounded-b-xl">
    <v-container data-v-step="100">
      <v-row>
        <v-col class="text-right" cols="12">
          <v-tabs :color="tabColor" v-model="tab" fixed-tabs>
            <v-tab id="pending-tab-dashboard">{{ $t('pending') }}</v-tab>
            <v-tab id="urgent-tab-dashboard">{{ $t('urgent') }}</v-tab>
            <v-tab id="expired-tab-dashboard">{{ $t('expired') }}</v-tab>
            <v-tab id="accepted-tab-dashboard">{{ $t('accepted') }}</v-tab>
          </v-tabs>
          <NotificationsListElement
            v-if="tab == 0 && pending"
            :notificationsList="pending"
          >
          </NotificationsListElement>
          <NotificationsListElement
            v-if="tab == 1 && urgent"
            :notificationsList="urgent"
          >
          </NotificationsListElement>
          <NotificationsListElement
            v-if="tab == 2 && expired"
            :notificationsList="expired"
          >
          </NotificationsListElement>
          <NotificationsListElement
            v-if="tab == 3 && accepted"
            :notificationsList="accepted"
          >
          </NotificationsListElement>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import NotificationsListElement from '@/components/dashboard/dashboardElements/notifications/NotificationsListElement'
import { getNotificationStatus } from '@/services/notification-service'
export default {
  components: {
    NotificationsListElement,
  },
  props: {
    pending: { type: Array },
    urgent: { type: Array },
    expired: { type: Array },
    accepted: { type: Array },
  },
  data() {
    return {
      tab: 1,
    }
  },
  computed: {
    tabColor() {
      switch (this.tab) {
        case 0:
          return 'warningLow'
        case 1:
          return 'warning'
        case 2:
          return 'red'
        case 3:
          return 'accept'
        default:
          return 'accept'
      }
    },
  },
  methods: {
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
  },
}
</script>

<style lang="scss" scoped></style>
