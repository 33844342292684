<template>
  <v-card class="pa-5" elevation="0">
    <vue-pdf-embed
      v-if="selectedFile.name.endsWith('.pdf')"
      :source="fileSrc"
    />
    <v-img
      v-else-if="selectedFile.name.endsWith('.jpeg' || '.jpg' || '.png')"
      :src="fileSrc"
    />
  </v-card>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    selectedFile: Object,
  },
  computed: {
    fileSrc() {
      return URL.createObjectURL(this.selectedFile.file)
    },
  },
}
</script>
<style lang="scss" scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f5f5; /* set a background color for the image container */
}
// .image-container {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
</style>
