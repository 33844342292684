<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.signatures.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.signatures.signatureForward.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.signatures.signatureForward.paragraph1') }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picForwardForm"
    />
    <p>
      {{ $t('documentation.texts.signatures.signatureForward.paragraph2') }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picForwardHistory"
    />

    <p>
      {{ $t('documentation.texts.signatures.signatureForward.paragraph3') }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picForwardPopUp"
    />
  </v-col>
</template>

<script>
export default {
  computed: {
    picForwardForm() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureForward.picForwardForm'
        ) +
        '.png')
    },
    picForwardHistory() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureForward.picForwardHistory'
        ) +
        '.png')
    },
    picForwardPopUp() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureForward.picForwardPopUp'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
