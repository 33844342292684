<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.dashboard.notifications.title') }}
    </h1>
    <p>{{ $t('documentation.texts.dashboard.notifications.paragraph1') }}</p>
    <h2 class="primary--text">
      {{ $t('documentation.texts.dashboard.notifications.title1') }}
    </h2>
    <v-img
      style="
        width: 100%;
        max-width: 600px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p>{{ $t('documentation.texts.dashboard.notifications.paragraph2') }}</p>
    <ul>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.notifications.li1Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.notifications.li1') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.notifications.li2Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.notifications.li2') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.notifications.li3Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.notifications.li3') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.notifications.li4Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.notifications.li4') }}
      </li>
    </ul>
    <br />
    <h2 class="primary--text">
      {{ $t('documentation.texts.dashboard.notifications.title2') }}
    </h2>
    <v-img
      style="
        width: 100%;
        max-width: 400px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic2Name"
    />
    <p>{{ $t('documentation.texts.dashboard.notifications.paragraph3') }}</p>
    <p>{{ $t('documentation.texts.dashboard.notifications.paragraph4') }}</p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.notifications.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.notifications.pic2') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
