<template>
  <v-col>
    <h1 class="primary--text">
      {{ $t('documentation.texts.analytics.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.analytics.p1') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 260px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.analytics.p2') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 1060px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic2Name"
    />
    <p>
      {{ $t('documentation.texts.analytics.p3') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 1060px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic3Name"
    />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/analytics/' +
        this.$t('documentation.texts.analytics.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/analytics/' +
        this.$t('documentation.texts.analytics.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/analytics/' +
        this.$t('documentation.texts.analytics.pic3') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
