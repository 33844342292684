<template>
  <v-col style="max-width: 900px">
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.massiveActions.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.massiveActions.p1') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.massiveActions.p2') }}
    </p>
    <v-img style="width: 100%; max-width: 800px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.notifications.massiveActions.p3') }}
    </p>
    <ul>
      <li>
        <b>
          -
          {{
            $t('documentation.texts.notifications.massiveActions.li1Title')
          }}</b
        >: {{ $t('documentation.texts.notifications.massiveActions.li1') }}
        <v-img style="width: 100%; max-width: 200px" contain :src="pic3Name" />
      </li>
      <li>
        <b>
          -
          {{
            $t('documentation.texts.notifications.massiveActions.li2Title')
          }}</b
        >: {{ $t('documentation.texts.notifications.massiveActions.li2') }}
        <v-img style="width: 100%; max-width: 400px" contain :src="pic4Name" />
      </li>
      <li>
        <b>
          -
          {{
            $t('documentation.texts.notifications.massiveActions.li3Title')
          }}</b
        >: {{ $t('documentation.texts.notifications.massiveActions.li3') }}
        <v-img style="width: 100%; max-width: 400px" contain :src="pic5Name" />
      </li>
      <li>
        <b>
          -
          {{
            $t('documentation.texts.notifications.massiveActions.li4Title')
          }}</b
        >: {{ $t('documentation.texts.notifications.massiveActions.li4') }}
        <v-img style="width: 100%; max-width: 400px" contain :src="pic6Name" />
      </li>
      <li>
        <b>
          -
          {{
            $t('documentation.texts.notifications.massiveActions.li5Title')
          }}</b
        >: {{ $t('documentation.texts.notifications.massiveActions.li5') }}
        <v-img style="width: 100%; max-width: 400px" contain :src="pic7Name" />
      </li>
    </ul>
    <!-- <v-img style="width: 100%; max-width: 600px" contain :src="pic1Name" /> -->
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.li1Pic') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.li2Pic') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.li3Pic') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.li4Pic') +
        '.png')
    },
    pic7Name() {
      return require('@/assets/documentation/notifications/massive-actions/' +
        this.$t('documentation.texts.notifications.massiveActions.li5Pic') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
