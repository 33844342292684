<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <h2 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.targets.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.automatedTasks.targets.p1') }}
    </p>
    <ul class="mt-3">
      <li class="mt-4">
        -
        <b>{{ $t('documentation.texts.automatedTasks.targets.title1li') }}</b
        >: {{ $t('documentation.texts.automatedTasks.targets.li1') }}
      </li>
      <ul>
        <li>
          -
          <b>{{
            $t('documentation.texts.automatedTasks.targets.li1-title1')
          }}</b
          >: {{ $t('documentation.texts.automatedTasks.targets.li1-text1') }}
        </li>
        <li>
          -
          <b>{{
            $t('documentation.texts.automatedTasks.targets.li1-title2')
          }}</b
          >: {{ $t('documentation.texts.automatedTasks.targets.li1-text2') }}
        </li>
      </ul>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic1Name"
      />
      <li class="mt-4">
        -
        <b>{{ $t('documentation.texts.automatedTasks.targets.title2li') }}</b
        >: {{ $t('documentation.texts.automatedTasks.targets.li2') }}
      </li>
      <ul>
        <li>
          -
          <b>{{
            $t('documentation.texts.automatedTasks.targets.li2-title1')
          }}</b
          >: {{ $t('documentation.texts.automatedTasks.targets.li2-text1') }}
        </li>
        <li>
          -
          <b>{{
            $t('documentation.texts.automatedTasks.targets.li2-title2')
          }}</b
          >: {{ $t('documentation.texts.automatedTasks.targets.li2-text2') }}
        </li>
        <li>
          -
          <b>{{
            $t('documentation.texts.automatedTasks.targets.li2-title3')
          }}</b
          >: {{ $t('documentation.texts.automatedTasks.targets.li2-text3') }}
        </li>
      </ul>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic2Name"
      />
      <li class="mt-4">
        -
        <b>{{ $t('documentation.texts.automatedTasks.targets.title3li') }}</b
        >: {{ $t('documentation.texts.automatedTasks.targets.li3') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic3Name"
      />
      <li class="mt-4">
        -
        <b>{{ $t('documentation.texts.automatedTasks.targets.title4li') }}</b
        >: {{ $t('documentation.texts.automatedTasks.targets.li4') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic4Name"
      />
      <li class="mt-4">
        -
        <b>{{ $t('documentation.texts.automatedTasks.targets.title5li') }}</b
        >: {{ $t('documentation.texts.automatedTasks.targets.li5') }}
      </li>
      <v-img
        style="
          width: 100%;
          max-width: 800px;
          align-self: center;
          border-radius: 10px;
        "
        contain
        class="mb-5"
        :src="pic5Name"
      />
    </ul>
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.targets.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.targets.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.targets.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.targets.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.targets.pic5') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
