import {
  CIFDoc,
  CIFsCollection,
  Database,
  Firestore,
  GroupCheckExistence,
  GroupDoc,
  GroupsCollection,
  OperatorDoc,
} from '@/firebase-exports'
import store from '@/store/index'

/**
 * Returns a list of the groups.
 * @returns Gets a list of the groups.
 */
export async function listGroups(id = undefined) {
  const userId = id
    ? id
    : store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const querySnapshot = await Firestore.getDocs(GroupsCollection(userId))
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

/**
 * Gets the unregistered cifs of a company.
 * @param {Array} group Group that contains the unregistered cifs.
 * @returns Array with the unregistered cifs.
 */
export async function getUnregisteredCifs(group) {
  const unregisteredCifs = group.unregisteredCifs ?? []

  return await Promise.all(
    unregisteredCifs.map(async (ref) => ({
      id: ref.id,
      ...(await Firestore.getDoc(ref)).data(),
    }))
  )
}

/**
 * Returns a list of the user groups.
 * @returns User groups.
 */
export async function listUserGroups() {
  return await Promise.all(
    store.state.user.groupsRef?.map(async (ref) => {
      const doc = await Firestore.getDoc(ref)
      return { id: doc.id, ...doc.data() }
    }) ?? []
  )
}

/**
 * Returns an object with the group
 * @param {Object} ref Reference of the group.
 * @returns Object with the group.
 */
export async function getGroup(ref) {
  const doc = await Firestore.getDoc(ref)
  return { id: doc.id, ...doc.data() }
}

/**
 * Given a group it returns the unregistered cifs of this group.
 * @param {Object} unregisteredCifs List of unregisteredCifs.
 * @returns List with the unregistered cifs of the group.
 */
export async function getGroupUnregisteredCifs(unregisteredCifs = []) {
  return await Promise.all(
    unregisteredCifs?.map(async (ref) => ({
      id: ref.id,
      ...(await Firestore.getDoc(ref)).data(),
    }))
  )
}

/**
 * Returns an object with the groups and an array with the groups
 * @returns Object with groups.
 */
export async function getGroups() {
  const groupsMap = {}

  const groups = await listGroups()
  groups.forEach((group) => (groupsMap[group.id] = group))

  return { groupsMap, groups }
}

/**
 * Creates a subscription to the groups of a user.
 * @param {Object} context Context of the view.
 * @param {Boolean?} loadUnregistered Flag to load the unregistered clients.
 * @returns Unsubscribe method to the subscription.
 */
export function getGroupsSubscription(context, loadUnregistered = false) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  var groupUnsubscribe = Firestore.onSnapshot(
    Firestore.query(GroupsCollection(userId), Firestore.orderBy('name')),
    (snapShot) => {
      const groups = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      context.groups = groups

      if (loadUnregistered)
        context.groups.forEach(
          async (group) =>
            (group.unregisteredCifs = await getGroupUnregisteredCifs(
              group.unregisteredCifs
            ))
        )
    },
    (error) => {
      throw error
    }
  )
  return groupUnsubscribe
}

/**
 * Creates a group for the logged user.
 * @param {Object} data Data of the group.
 * @param {Array} operatorsId Operators that need to be added into the group.
 * @param {Array} cifsId Cifs that need to be added into the group.
 */
export const createGroup = async (data, operatorsId = []) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const ref = await Firestore.addDoc(GroupsCollection(userId), data)

  const batch = Firestore.writeBatch(Database)

  operatorsId.forEach((id) =>
    batch.update(OperatorDoc(id), {
      groupsRef: Firestore.arrayUnion(ref),
    })
  )
  await batch.commit()
}

/**
 * Updates a group.
 * @param {string} groupId Id of the group to update.
 * @param {Object} data Data of the group.
 */
export const updateGroup = async (
  groupId,
  data,
  previousGroupOperators = [],
  operatorsId = []
) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  // Looking for the operators to add. Looking for the operators that are not in previousGroupOperators
  const operatorsToAdd = operatorsId.filter(
    (id) => !previousGroupOperators.map((operator) => operator.id).includes(id)
  )

  // Looking for the operators to delete. Looking for the operators that are not in operatorsId
  const operatorsToRemove = previousGroupOperators
    .map((operator) => operator.id)
    .filter((id) => !operatorsId.includes(id))

  const batch = Firestore.writeBatch(Database)

  // Updating the operators
  operatorsToAdd.forEach((id) =>
    batch.update(OperatorDoc(id), {
      groupsRef: Firestore.arrayUnion(GroupDoc(userId, groupId)),
    })
  )
  operatorsToRemove.forEach((id) =>
    batch.update(OperatorDoc(id), {
      groupsRef: Firestore.arrayRemove(GroupDoc(userId, groupId)),
    })
  )

  batch.update(GroupDoc(userId, groupId), data)
  await batch.commit()
}

/**
 * Deletes an group and removes the reference from the operators.
 * @param {String} groupId Id of the group to delete.
 * @param {Array} operators Operators that are in the group.
 */
export const deleteGroup = async (groupId, operators = []) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  // Deleting the reference in the operators
  operators.forEach(({ id }) =>
    batch.update(OperatorDoc(id), {
      groupsRef: Firestore.arrayRemove(GroupDoc(userId, groupId)),
    })
  )

  // Deleting the group
  batch.delete(GroupDoc(userId, groupId))

  await batch.commit()
}

/**
 * Adds a cif to a group
 * @param {String} cifId Id of the cif to add.
 * @param {String} groupId Id of the group where the cif
 * is going to be added.
 */
export const addCifToGroup = async (cifId, groupId) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  // Updating the group
  await Firestore.updateDoc(GroupDoc(userId, groupId), {
    cifsRef: Firestore.arrayUnion(CIFDoc(userId, cifId)),
  })
}

/**
 * Remove a cif from a group
 * @param {String} cifId Id of the cif to add.
 * @param {String} groupId Id of the group where the cif
 * is going to be added.
 */
export const removeCifFromGroup = async (cifId, groupId) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  // Updating the group
  await Firestore.updateDoc(GroupDoc(userId, groupId), {
    cifsRef: Firestore.arrayRemove(CIFDoc(userId, cifId)),
  })
}

/**
 * Adds a cif to a group
 * @param {String} cifId Id of the cif to add.
 * @param {Array} groupsId Array of ids of the groups where the cif must be included.
 * is going to be added.
 */
export const addCifToGroups = async (cifId, groupsId) => {
  for (const groupId of groupsId) await addCifToGroup(cifId, groupId)
}

/**
 * Checks if a cif exists in another group
 * @param {String} numeroDocIdentidadGoverment id of the company.
 * @returns False if the cif does not exists or the id of the existing cif.
 */
export const checkCifExistence = async (numeroDocIdentidad) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const docs = await Firestore.getDocs(
    Firestore.query(
      CIFsCollection(userId),
      Firestore.where('numeroDocIdentidad', '==', numeroDocIdentidad)
    )
  )

  return docs.empty ? false : docs.docs[0].id
}

/**
 * Checks if a cif exists in another group
 * @param {String} toCheck Type of object to check:
 * - cif
 * - operator
 * @param {String} email Email of the operator to be created.
 * @returns False if the operator does not exists or the id of the operator.
 */
export const checkOperatorExistence = async (toCheck, email) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const { data } = await GroupCheckExistence({
    toCheck,
    email,
    userId,
  })

  return data
}

/**
 * Function that removes a cif from the unregistered cifs list.
 * @param {String} cifId Id of the cif to remove.
 * @param {String} groupId Id of the group that has the unregistered cif.
 */
export const removeUnregistered = async (cifId, groupId) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await Firestore.updateDoc(GroupDoc(userId, groupId), {
    unregisteredCifs: Firestore.arrayRemove(CIFDoc(userId, cifId)),
  })
}

/**
 * Function that returns a map with all the groups of each company.
 * @param {Array} groups List of groups.
 * @param {Array} cifs List of cifs.
 * @returns Object where the key is the id of cif and the value
 * are the groups where the cif is added.
 */
export const getCifGroupMap = (groups, cifs) => {
  const map = {}

  cifs.forEach((cif) => {
    const cifGroups = []
    groups.forEach((group) => {
      if (group.cifsRef.some((ref) => ref.id === cif.id)) cifGroups.push(group)
    })

    map[cif.id] = cifGroups
  })

  return map
}
