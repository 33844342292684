import store from '@/store/index'
import { getCifsSimplified } from '@/services/cifs-service'
import { getBilling } from '@/services/billing-service'

const fake = false

const fakePlan = {
  notificationsUsed: 200,
  notificationsPaid: 500,
  companiesUsed: 150,
  companiesPaid: 250,
  signsUsed: 401,
  signsPaid: 500,
  certificatesUsed: 1500,
  certificatesPaid: 1500,
}

var cifs = []
var consum = 0

export async function loadAllLicenseData() {
  if (fake) {
    return fakePlan
  }

  const userId = store.getters.isOperatorUser
    ? store.state.user.parentRef.id
    : store.state.user.id

  cifs = await getCifsSimplified(userId)
  consum = await getBilling()

  var data = {
    notificationsUsed: store.state.plan.notificationsUsed,
    notificationsPaid: store.state.plan?.notificationsMax,
    companiesUsed: cifsCountNotifications(),
    companiesPaid: store.state.plan?.notificationsMaxCifs,
    signsUsed: store.state.plan.signaturesUsed,
    signsPaid: store.state.plan?.signaturesMax,
    certificatesUsed: certsCountSignatures(),
    certificatesPaid: store.state.plan?.signaturesMaxCerts,
    //operadores basta con la cuenta del servicio de operadores
  }
  //console.log(data)
  return data
}

function cifsCountNotifications() {
  if (!store.getters.isEmpresaUser) {
    return cifs.reduce((acc, cif) => {
      return (
        acc +
        (cif.notificacionesActivas ? (cif.granDestinatario ? consum : 1) : 0)
      )
    }, 0)
  } else {
    return cifs.filter((c) => c.notificacionesActivas).length
  }
}

function certsCountSignatures() {
  return cifs.reduce(
    (acc, cif) =>
      acc + (cif.allowedToSign ? Object.keys(cif.allowedToSign).length : 0),
    0
  )
}
