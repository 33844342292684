<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.operators.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.operators.update.updateTitle') }}
    </h2>
    <p>{{ $t('documentation.texts.operators.update.paragraph1') }}</p>
    <v-img style="width: 100%; max-width: 1200px" contain :src="pic1Name" />
    <p>{{ $t('documentation.texts.operators.update.paragraph2') }}</p>
    <v-img style="width: 100%; max-width: 1200px" contain :src="pic2Name" />
    <p>{{ $t('documentation.texts.operators.update.paragraph3') }}</p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/operators/' +
        this.$t('documentation.texts.operators.update.picUpdatePencil') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/operators/' +
        this.$t('documentation.texts.operators.update.picUpdateFields') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
