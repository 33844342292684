<template>
  <v-list v-if="signaturesList.length != 0" subheader two-line>
    <v-list-item
      v-for="signature in signaturesList"
      :key="signature.id"
      id="signature-item-dashboard"
      @click="
        $router.push({
          name: 'SignatureDetailView',
          params: {
            id: signature.id,
          },
        })
      "
    >
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="4" md="4">
            <v-row justify="center" align="center">
              <v-col class="ma-0 pa-0" cols="12" md="6">
                <v-chip
                  class="white--text"
                  :color="getSignatureStatus(signature).color"
                >
                  <span>
                    {{ $t(getSignatureStatus(signature).status) }}
                  </span>
                </v-chip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" md="6">
                <div class="ml-2 mr-2 subtitle">
                  {{ $d(signature.dateLimit, 'i18nDate') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8" md="8" class="pl-5">
            <v-row justify="center" align="center">
              <v-col class="ma-0 pa-0" cols="12" md="6">
                <v-list-item-content
                  class="ma-0 pa-0 text-left align-self-start"
                >
                  <v-list-item-title>
                    {{ $t('signatureProperties.requester') }}:
                    {{
                      `${signature.requester.name} ${signature.requester.surname}`
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('company') }}: {{ signature.cif.empresa }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" md="6">
                <v-list-item-subtitle>
                  {{ signature.purpose }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-list-item>
  </v-list>
  <v-container v-else style="text-align: center">
    <b>
      <span> {{ $t('emptyTypeSignaturesList') }} </span>
    </b>
  </v-container>
</template>

<script>
import { getSignatureStatus } from '@/services/signatures-service'

export default {
  props: {
    signaturesList: {
      type: Array,
    },
  },
  methods: {
    getSignatureStatus(signature) {
      return getSignatureStatus(signature)
    },
  },
}
</script>

<style lang="scss" scoped></style>
