<template>
  <v-card>
    <v-card-title :class="isMobile ? 'ma-0 pa-0' : ''">
      <v-container fluid>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          v-if="!isMobile"
        >
          <v-col cols="4" no-gutters>
            <v-row align="baseline">
              <span v-if="operatorsListFlag" class="my-0 mx-2 warning">
                {{ `&nbsp;  ${$t('modeShowOperatorsNotification')} &nbsp;` }}
              </span>
              <span v-else class="my-0 mx-2">
                {{ $t('navigationPage.notifications') }}
              </span>
              <span
                v-if="!loadAllNotifications"
                class="my-0 subtitle-2 text--secondary"
              >
                {{ $t('last90Days') }}
              </span>
              <v-btn
                data-v-step="103"
                v-if="!loadAllNotifications"
                rounded
                small
                :loading="loadingAll"
                class="mx-2 secondary--text"
                color="primary"
                :disabled="false"
                @click="loadAll"
              >
                {{ $t('loadPrevious') }}
              </v-btn>
              <DialogButton
                section="notifications"
                subsection="notificationList"
              />
            </v-row>
            <v-row
              align="baseline"
              v-if="$store.state.user.lastRetrievedNotifications"
            >
              <span style="color: grey; font-size: 14px" class="pl-2 pr-1 mt-3">
                {{ $t('lastUpdated') }}:
                {{
                  $d(
                    $store.state.user.lastRetrievedNotifications.toDate(),
                    'i18nDateAndHour'
                  )
                }}
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    style="text-transform: none"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="updateNotifications()"
                    :disabled="
                      $store.state.user.needsCertEmpresa ||
                      $store.state.progress?.message ===
                        'updatingNotificationsText'
                    "
                  >
                    <v-icon
                      v-if="
                        $store.state.progress?.message !==
                        'updatingNotificationsText'
                      "
                    >
                      mdi-reload
                    </v-icon>
                    <v-icon v-else> mdi-autorenew mdi-spin </v-icon>
                  </v-btn>
                </template>
                <div>
                  {{ $t('refreshNow') }}
                </div>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="4" no-gutters>
            <AppPlanProgress
              v-if="isAdminUser"
              icon="mdi-mailbox"
              :current="notificationsUsed"
              :max="notificationsMax"
            />
          </v-col>
          <v-col cols="2" no-gutters>
            <v-text-field
              data-v-step="104"
              class="my-0 py-0"
              v-model="search"
              @change="searchChange"
              clearable
              prepend-icon="mdi-magnify"
              :label="$t('searchField')"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdminUser && !isColectivoUser"
                  elevation="1"
                  fab
                  small
                  class="secondary--text ma-1"
                  v-bind="attrs"
                  v-on="on"
                  id="list-operators"
                  :disabled="loadingData"
                  :color="operatorsListFlag ? 'warning' : 'primary'"
                  @click.stop="switchOperatorsListFlag()"
                >
                  <v-icon> mdi-account-group </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('notificationActions.title.operatorsList') }}
              </div>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  class="primary secondary--text ma-1"
                  v-bind="attrs"
                  v-on="on"
                  id="export-data"
                  @click.stop="exportNotifications()"
                >
                  <v-icon> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('notificationActions.title.export') }}
              </div>
            </v-tooltip>
            <MassiveActionsButton
              v-if="isAdminUser && !isColectivoUser"
              :actions="[
                'changeStatus',
                'openNotification',
                'setResponsible',
                'setAlert',
                'setObservation',
                'downloadFiles',
              ]"
              :items="selectedRows"
              @actionSelected="(value) => (massiveActionSelected = value)"
              @setMassiveActionData="setMassiveActionData"
              @removeLastItem="selectedRows.pop()"
              @removeItems="(items) => removeItems(items)"
            />
            <NotificationDialog
              v-if="
                !$store.getters.isDemoEnv &&
                (!isOperatorUser || $store.state.user?.processNotification)
              "
              action="create"
              :notification="{}"
              :cifs="loadedCifs.filter((c) => c.notificacionesActivas)"
            />
          </v-col>
        </v-row>
        <v-col v-else>
          <v-row class="mb-1" align="baseline">
            <span class="my-0 mx-2">
              {{ $t('navigationPage.notifications') }}
            </span>
            <span
              v-if="!loadAllNotifications"
              class="my-0 subtitle-2 text--secondary"
            >
              {{ $t('last90Days') }}
            </span>
          </v-row>
          <v-row class="mb-1 d-flex justify-end">
            <v-btn
              v-if="!loadAllNotifications"
              rounded
              small
              :loading="loadingAll"
              class="mx-2 secondary--text"
              color="primary"
              :disabled="false"
              @click="loadAll"
            >
              {{ $t('loadPrevious') }}
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="my-0 py-0"
              v-model="search"
              @change="searchChange"
              clearable
              prepend-icon="mdi-magnify"
              :label="$t('searchField')"
              single-line
              hide-details
            >
            </v-text-field>
          </v-row>
          <v-row class="mt-3 d-flex justify-center">
            <v-col>
              <AppPlanProgress
                class="ml-4 mr-4"
                icon="mdi-mailbox"
                textSize="h5"
                :current="notificationsUsed"
                :max="notificationsMax"
              />
            </v-col>
          </v-row>
          <v-row class="mt-3 d-flex justify-center">
            <v-col align="center">
              <v-btn
                elevation="1"
                fab
                small
                class="primary secondary--text ma-1"
                id="export-data"
                @click.stop="exportNotifications()"
              >
                <v-icon> mdi-file-export </v-icon>
              </v-btn>
              <MassiveActionsButton
                v-if="isAdminUser"
                :actions="[
                  'changeStatus',
                  'openNotification',
                  'setResponsible',
                  'setAlert',
                  'setObservation',
                  'downloadFiles',
                ]"
                :items="selectedRows"
                @actionSelected="(value) => (massiveActionSelected = value)"
                @setMassiveActionData="setMassiveActionData"
                @removeLastItem="selectedRows.pop()"
                @removeItems="(items) => removeItems(items)"
              />
              <NotificationDialog
                v-if="
                  !$store.getters.isDemoEnv &&
                  (!isOperatorUser || $store.state.user?.processNotification)
                "
                action="create"
                :notification="{}"
                :cifs="loadedCifs.filter((c) => c.notificacionesActivas)"
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-3 d-flex justify-center"
            v-if="$store.state.user.lastRetrievedNotifications"
          >
            <span style="color: grey; font-size: 14px" class="pl-2 pr-1">
              <b>{{ $t('lastUpdated') }} </b>:
              {{
                $d(
                  $store.state.user.lastRetrievedNotifications.toDate(),
                  'i18nDateAndHour'
                )
              }}
            </span>
            <v-btn
              icon
              x-small
              class="mt-2"
              style="text-transform: none"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click.stop="updateNotifications()"
              :disabled="$store.state.progress.isDisplayed"
            >
              <v-icon v-if="!$store.state.progress.isDisplayed">
                mdi-reload
              </v-icon>
              <v-icon v-else> mdi-autorenew mdi-spin </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-container>
      <v-spacer></v-spacer>
    </v-card-title>

    <AppSkeleton data-v-step="102" :condition="loading" type="notificationList">
      <template v-slot:skeleton-content>
        <v-data-table
          v-model="selectedRows"
          :loading="loadingData"
          :headers="headers"
          :items="filteredNotifications"
          :items-per-page="5"
          @click:row="openNotificationDetail"
          :footer-props="{ 'items-per-page-options': [5, 25, 50 /*-1*/] }"
          :options="options"
          @update:options="optionsChange"
          @item-selected="checkSelectedItem"
          @toggle-select-all="checkSelectedItems"
          class="elevation-1"
          :show-select="!!massiveActionSelected"
        >
          <template v-slot:[`item.operators`]="{ item }">
            <span v-if="item.operators">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-chip
                      class="ma-2 white--text"
                      :color="
                        item.operators.length == 0
                          ? 'error'
                          : item.operators.length == 1
                          ? 'warning'
                          : 'primary'
                      "
                    >
                      {{ item.operators.length }}
                    </v-chip>
                  </span>
                </template>
                <div width="300">
                  <!-- mostrar avatar y nombre operador -->
                  <ul v-if="item.operators.length">
                    <li
                      v-for="(operator, index) in item.operators"
                      :key="index"
                    >
                      <v-row align="center">
                        <AppAvatar
                          class="mr-2 mt-3 mb-3"
                          :userName="operator.name"
                          :userAvatar="operator.avatar"
                          avatarSize="30"
                          iconSize="24"
                          avatarColor="primary"
                          initialsColor="secondary"
                        />
                        <span class="mr-2 mt-3 mb-3">
                          {{ operator.name }}
                        </span>
                      </v-row>
                    </li>
                  </ul>
                  <ul v-else>
                    <li>
                      {{ $t('whitoutOperators') }}
                    </li>
                  </ul>
                </div>
              </v-tooltip>
            </span>
          </template>

          <template v-slot:[`item.alert`]="{ item }">
            <v-container>
              <v-row align="center">
                <AlertIcon :large="true" v-if="item.alert" :alert="item.alert">
                </AlertIcon>
                <AlertDialog
                  v-else-if="
                    !isOperatorUser || $store.state.user?.processNotification
                  "
                  :asset="item"
                  :isDisabled="
                    isOperatorUser && !$store.state.user?.processNotification
                  "
                  objectType="notifications"
                />
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  item.id.substring(0, 3) +
                  '...' +
                  item.id.substring(item.id.length - 3)
                }}</span>
              </template>
              <div width="300">
                {{ item.id }}
              </div>
            </v-tooltip>
          </template>
          <template v-if="!isMobile" v-slot:[`item.estado`]="{ item }">
            <v-container>
              <v-row>
                <v-chip
                  class="ma-2 white--text"
                  :color="getNotificationStatus(item).color"
                  id="enter-notif-detail"
                >
                  {{ $t(getNotificationStatus(item).status) }}

                  <v-tooltip
                    max-width="400"
                    bottom
                    v-if="item.aproximateOpenningTime"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1"
                        indeterminate
                        :size="12"
                        :width="1"
                        color="white"
                      />
                    </template>
                    <div width="300">
                      {{ $t('notificationOpensIn') }}:
                      <TimerCountdown
                        :starttime="new Date()"
                        :endtime="item.aproximateOpenningTime.toDate()"
                        :hideMessage="true"
                      />
                    </div>
                  </v-tooltip>
                </v-chip>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:[`item.estado`]="{ item }">
            <v-container>
              <v-row align="center">
                <AlertIcon v-if="item.alert" :alert="item.alert"></AlertIcon>

                <v-chip
                  class="ma-2 white--text"
                  :color="getNotificationStatus(item).color"
                >
                  {{ $t(getNotificationStatus(item).status) }}
                </v-chip>
                <AppAvatar
                  v-if="item.manager"
                  :userName="`${item.manager?.name} ${item.manager?.surname}`"
                  :userAvatar="item.manager?.avatarURL"
                  avatarSize="30"
                  iconSize="24"
                  avatarColor="primary"
                  initialsColor="secondary"
                />
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.tipo_envio`]="{ item }">
            <span>
              {{ $t(getNotificationType(item).name) }}
            </span>
          </template>
          <template v-slot:[`item.manager`]="{ item }">
            <span v-if="item.manager">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <AppAvatar
                      v-if="item.manager"
                      :userName="`${item.manager?.name} ${item.manager?.surname}`"
                      :userAvatar="item.manager?.avatarURL"
                      avatarSize="30"
                      fab
                      small
                      iconSize="24"
                      avatarColor="primary"
                      initialsColor="secondary"
                      :showWarn="item.manager.disabled || !item.managerAccepted"
                      :overlapIconColor="
                        item.manager.disabled ? 'error' : 'warning'
                      "
                    />
                  </span>
                </template>
                <div width="300">
                  {{ `${item.manager.name} ${item.manager.surname}` }}
                </div>
                <div width="300" v-if="!item.managerAccepted">
                  {{ $t('waitManager') }}
                </div>
                <div width="300" v-if="item.manager.disabled">
                  {{ $t('userDisabled') }}
                </div>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:[`item.fecha_puesta_disposicion`]="{ item }">
            {{ $d(item.fecha_puesta_disposicion, 'i18nDate') }}
          </template>
          <template v-slot:[`item.fecha_aceptacion`]="{ item }">
            <span v-if="item.fecha_aceptacion">
              {{ $d(item.fecha_aceptacion, 'i18nDate') }}
            </span>
            <span v-else-if="item.fecha_expiracion">
              <TimerCountdown
                :starttime="item.fecha_puesta_disposicion"
                :endtime="item.fecha_expiracion"
              >
              </TimerCountdown>
            </span>
          </template>
          <template v-slot:[`item.contenido`]="{ item }">
            <v-container>
              <v-row class="mr-1">
                <v-col cols="4">
                  <!--         CONTENIDO            -->
                  <v-btn
                    v-if="
                      item.document &&
                      (!isOperatorUser || $store.state.user.processNotification)
                    "
                    icon
                    color="red lighten-2"
                    dark
                  >
                    <v-icon @click.stop="getNotification(item)">
                      mdi-file-document
                    </v-icon>
                  </v-btn>
                  <v-tooltip
                    v-else-if="
                      item.document &&
                      (!isOperatorUser ||
                        !$store.state.user.processNotification)
                    "
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-document
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('contentNoProcessPermissionText', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-document
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('acceptToDocumentRequired', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <!--           ANEXOS             -->
                  <v-dialog
                    v-if="
                      item.document &&
                      item.document.referenciaAnexos &&
                      (!isOperatorUser || $store.state.user.processNotification)
                    "
                    v-model="dialog"
                    width="800"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-file-document-multiple </v-icon>
                      </v-btn>
                    </template>

                    <v-card
                      v-if="
                        !isMobile &&
                        (!isOperatorUser ||
                          $store.state.user.processNotification)
                      "
                    >
                      <v-card-title class="text-h5 grey lighten-2">
                        {{ $t('annexes') }}
                      </v-card-title>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col
                            v-for="anexe in item.document.referenciaAnexos"
                            v-bind:key="anexe.Id"
                            cols="4"
                          >
                            <v-card class="mx-auto pa-1">
                              <v-container fill-height fluid>
                                <v-row
                                  class="grey lighten-2"
                                  align="center"
                                  justify="center"
                                >
                                  <v-card-title>
                                    {{ anexe.nombre }}
                                  </v-card-title>
                                </v-row>
                                <v-row align="center" justify="center">
                                  <v-icon
                                    x-large
                                    class="ma-3"
                                    color="blue lighten-2"
                                  >
                                    mdi-file-document
                                  </v-icon>
                                </v-row>
                                <v-row align="center" justify="center">
                                  <v-btn
                                    @click.stop="getAnnexe(item, anexe)"
                                    class="ma-3"
                                    text
                                  >
                                    {{ $t('download') }}
                                    <v-icon x-large color="blue lighten-2">
                                      mdi-download
                                    </v-icon>
                                  </v-btn>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!--@click="getAnnexe(item)"-->
                        <v-btn color="primary" text @click="dialog = false">
                          {{ $t('close') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-tooltip
                    v-else-if="
                      item.document &&
                      item.document.referenciaAnexos &&
                      (!isOperatorUser ||
                        !$store.state.user.processNotification)
                    "
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-document-multiple
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('contentNoProcessPermissionText', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="item.document && !item.document.referenciaAnexos"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-document-multiple
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('noAnnexesAvailable', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-document-multiple
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('acceptToAnnexesRequired', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <!--           ACUSE              -->
                  <v-btn
                    v-if="
                      getNotificationType(item).name == 'notification' &&
                      item.document &&
                      (!isOperatorUser || $store.state.user.processNotification)
                    "
                    icon
                    color="green lighten-2"
                    dark
                  >
                    <v-icon @click.stop="getAck(item)"> mdi-file-sign </v-icon>
                  </v-btn>
                  <v-tooltip
                    v-else-if="
                      getNotificationType(item).name == 'notification' &&
                      item.document &&
                      (!isOperatorUser ||
                        !$store.state.user.processNotification)
                    "
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-sign
                      </v-icon>
                    </template>
                    <span>
                      {{
                        $t('contentNoProcessPermissionText', [
                          $t(getNotificationType(item).name),
                        ])
                      }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="
                      getNotificationType(item).name == 'notification' &&
                      !item.document
                    "
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-sign
                      </v-icon>
                    </template>
                    <span> {{ $t('acceptToAckRequired') }} </span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                      >
                        mdi-file-sign
                      </v-icon>
                    </template>
                    <span> {{ $t('ackOnlyNotifications') }} </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </template>
    </AppSkeleton>
    <!--  <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn @click="createTestNotification">Crear Notification</v-btn>
      </v-col>
    </v-row>-->

    <TermsToAcceptDialog v-if="!isTermsAccepted" />
  </v-card>
</template>

<script>
import {
  updateNotifications,
  listAllNotifications,
  getNotificationStatus,
  getNotificationType,
  openNotification,
  openAck,
  openAnnexe,
  operatorsCanViewNotification,
} from '@/services/notification-service'

import AlertDialog from '@/components/alert/AlertDialog'
import AlertIcon from '@/components/alert/AlertIcon'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'
import TimerCountdown from '@/components/commons/TimerCountdown'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import {
  demoDocumentBase64,
  demoAckBase64,
  demoAnnexeBase64,
} from '@/services/notification-demo-service'
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import NotificationDialog from '@/components/notifications/dialog/NotificationDialog'
import MassiveActionsButton from '@/components/massive-actions/MassiveActionsButton'
import { checkItem } from '@/utils/massive-actions'
import { getUserOperators } from '@/services/operator-service'
import TermsToAcceptDialog from '@/components/commons/TermsToAcceptDialog'

export default {
  props: {
    loading: Boolean,
    cifs: Array,
    notificationsMax: Number,
    notificationsUsed: Number,
  },
  components: {
    TimerCountdown,
    AlertDialog,
    DialogButton,
    AlertIcon,
    AppPlanProgress,
    NotificationDialog,
    MassiveActionsButton,
    TermsToAcceptDialog,
  },
  data() {
    return {
      loadAllNotifications: false,
      loadingAll: false,
      loadingData: false,
      dialog: false,
      search: '',
      loadedCifs: [],
      options: {},
      massiveActionSelected: false,
      selectedRows: [],
      selectedRowsCopy: [],
      massiveActionData: {},
      showWarn: true,
      operatorsListFlag: false,
      usersOperators: [],
      notificationsWithOperators: [],
    }
  },
  methods: {
    ...mapMutations([
      'setSnackbar',
      'setNotificationsListOptions',
      'setNotificationsSearch',
    ]),
    /**
     * Checks that the selected notification fulfills the conditions to
     * be selected. This function is called only when 1 row is selected.
     * @param {Object} param0 Object containing the following values:
     * - item: The selected notification.
     * - value: If the selected notification is going to be checked or not.
     */
    async checkSelectedItem({ item, value }) {
      // We only check when the row is going to be checked
      if (value) {
        const result = await checkItem(
          this.massiveActionSelected,
          this.massiveActionData,
          item
        )

        // Removing the item from the list
        if (!result) {
          const index = this.selectedRows.findIndex((row) => row.id === item.id)
          if (index > -1) this.selectedRows.splice(index, 1)
        }

        this.selectedRowsCopy = [...this.selectedRows]
      }
    },
    /**
     * Checks that the selected notifications fulfills the conditions to
     * be selected. This function is called only when the "check all" button
     * is pressed.
     * @param {Object} param0 Object containing the following attributes:
     * - items: Selected notifications.
     * - value: If the notifications are going to be checked or not.
     */
    async checkSelectedItems({ items, value }) {
      for (const item of items) this.checkSelectedItem({ item, value })

      // If after checking we have the same number of elements
      // we must remove the items of that "page"
      if (this.selectedRows.length === this.selectedRowsCopy.length) {
        // Removing the page items
        for (const item of items) {
          const index = this.selectedRows.findIndex((row) => row.id === item.id)
          if (index > -1) this.selectedRows.splice(index, 1)
        }

        // Storing the copy of the items
        this.selectedRowsCopy = [...this.selectedRows]
      }
    },
    /**
     * Sets the massive action data.
     * @param {string} action Action selected
     */
    setMassiveActionData(data) {
      this.massiveActionData = data
    },
    /**
     * Given some indexes, remove them from a list.
     * @param {Array} indexes Array of indexes to remove.
     */
    removeItems(indexes = []) {
      const newList = []
      for (let i = 0; i < this.selectedRows.length; i++)
        if (!indexes.includes(i)) newList.push(this.selectedRows[i])

      this.selectedRows = newList
    },
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
    async loadAll() {
      this.loadingAll = true

      this.allNotifications = await listAllNotifications(
        this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id,
        this.$store.state.user
      )

      this.loadAllNotifications = true
      this.operatorsListFlag = false
      this.loadingAll = false
    },
    openNotificationDetail(value) {
      this.$router.push({
        name: 'NotificationDetailView',
        params: { id: value.id },
      })
    },
    async getNotification(notification) {
      //loop every item in main document (for zips)
      notification.document.items.forEach(async (doc) => {
        try {
          this.loadingData = true
          const response = this.$store.getters.isDemoEnv
            ? demoDocumentBase64()
            : await openNotification(notification, doc)
          const blob = new Blob([Buffer.from(response, 'base64')], {
            type: 'application/pdf',
            //type: doc['Mime-Type'], //TODO: Para el futuro si el DMS de Addalia lo acaben guardando
          })
          saveAs(blob, `document_${doc['#Id']}.pdf`)
          //saveAs(blob, doc.nombre) //TODO: Para el futuro si el DMS de Addalia lo acaben guardando
          this.loadingData = false
        } catch (err) {
          this.loadingData = false
          const message = getErrorText(err.toString())
          this.setSnackbar({
            position: 'top',
            type: 'error',
            message,
          })
        }
      })
    },
    async getAck(notification) {
      try {
        this.loadingData = true
        const response = this.$store.getters.isDemoEnv
          ? demoAckBase64()
          : await openAck(notification)

        const blob = new Blob([Buffer.from(response, 'base64')], {
          type: 'application/pdf',
        })
        saveAs(blob, `Acuse_${notification.id}.pdf`)

        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    async getAnnexe(notification, annexe) {
      try {
        this.loadingData = true
        const response = this.$store.getters.isDemoEnv
          ? demoAnnexeBase64()
          : await openAnnexe(notification, annexe) // Annexe objeto completo del anexo seleccionado
        const blob = new Blob([Buffer.from(response, 'base64')], {
          type: annexe['Mime-Type'],
        })
        saveAs(blob, annexe.nombre)

        this.loadingData = false
      } catch (err) {
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
        this.loadingData = false
      }
    },
    optionsChange(options) {
      this.setNotificationsListOptions(options)
    },
    searchChange(search) {
      this.setNotificationsSearch(search ? search : '') //If clearable, store empty string instead of null
    },
    updateNotifications() {
      updateNotifications(true)
    },
    exportNotifications() {
      var exportNotifications = this.filteredNotifications.map(
        (notification) => {
          /*console.log(
            'notification.fecha_puesta_disposicion=' +
              notification.fecha_puesta_disposicion +
              ' , notification.fecha_aceptacion=' +
              notification.fecha_aceptacion
          )*/
          return {
            identificador: notification.identificador,
            estado: notification.estado,
            concepto: notification.concepto,
            fecha_puesta_disposicion:
              notification.fecha_puesta_disposicion.toLocaleDateString(
                this.$i18n.locale,
                {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }
              ),
            fecha_aceptacion: notification.fecha_aceptacion
              ? notification.fecha_aceptacion.toLocaleDateString(
                  this.$i18n.locale,
                  {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                )
              : '',
            nif_titular: notification.nif_titular,
            nombre_titular: notification.nombre_titular,
            nif_peticion: notification.nif_peticion,
            nombre_organismo_emisor: notification.nombre_organismo_emisor,
            codigo_organismo_emisor: notification.codigo_organismo_emisor,
            nombre_organismo_emisor_raiz:
              notification.nombre_organismo_emisor_raiz,
            codigo_organismo_emisor_raiz:
              notification.codigo_organismo_emisor_raiz,
          }
        }
      )
      if (exportNotifications.length < 1) {
        const message = this.$t('nothingToExport')
        this.setSnackbar({ position: 'top', type: 'info', message })
        return
      }
      const replacer = (key, value) => (value === null ? '' : value) // valores undefined

      //header auxiliar con el nombre de las variables
      const header = [
        'identificador',
        'estado',
        'concepto',
        'fecha_puesta_disposicion',
        'fecha_aceptacion',
        'nif_titular',
        'nombre_titular',
        'nif_peticion',
        'nombre_organismo_emisor',
        'codigo_organismo_emisor',
        'nombre_organismo_emisor_raiz',
        'codigo_organismo_emisor_raiz',
      ]
      //header a mostrar en el csv
      const header2 = [
        this.$i18n.t('csv.notifications.identificador'),
        this.$i18n.t('csv.notifications.estado'),
        this.$i18n.t('csv.notifications.concepto'),
        this.$i18n.t('csv.notifications.fecha_puesta_disposicion'),
        this.$i18n.t('csv.notifications.fecha_aceptacion'),
        this.$i18n.t('csv.notifications.nif_titular'),
        this.$i18n.t('csv.notifications.nombre_titular'),
        this.$i18n.t('csv.notifications.nif_peticion'),
        this.$i18n.t('csv.notifications.nombre_organismo_emisor'),
        this.$i18n.t('csv.notifications.codigo_organismo_emisor'),
        this.$i18n.t('csv.notifications.nombre_organismo_emisor_raiz'),
        this.$i18n.t('csv.notifications.codigo_organismo_emisor_raiz'),
      ]

      const csv = [
        header2.join(';'), // asignamos separador
        ...exportNotifications.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        ),
      ].join('\r\n')
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([csv], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = 'notifications.csv'
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
      const message = this.$t('exportComplete')
      this.setSnackbar({ position: 'top', type: 'success', message })
      return
    },
    async switchOperatorsListFlag() {
      if (!this.operatorsListFlag) {
        this.loadingData = true
        this.usersOperators = await getUserOperators()

        const auxNotifications = [...this.notifications]
        this.notificationsWithOperators = await operatorsCanViewNotification(
          this.usersOperators,
          auxNotifications
        )

        await new Promise((r) => setTimeout(r, 1000))
        this.loadingData = false
      }
      this.operatorsListFlag = !this.operatorsListFlag
    },
    buildCustomHeaders() {
      const auxCustomHeader = [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: this.$t('opers'),
          value: 'operators',
          width: '20px',
        },
        {
          text: this.$t('alert'),
          value: 'alert',
          sort: (a, b) =>
            a && b ? b.date - a.date : a && !b ? -1 : !a && b ? 1 : 0,
        },
        {
          text: this.$t('resp'),
          value: 'manager',
          width: '20px',
          sort: (a, b) =>
            a && b ? a.id.localeCompare(b.id) : a && !b ? -1 : !a && b ? 1 : 0,
        },
        {
          text: this.$t('company'),
          value: 'nombre_titular',
          width: '40px',
        },
        { text: 'NIF', value: 'nif_titular' },
        {
          text: this.$t('dispatchDate'),
          value: 'fecha_puesta_disposicion',
          width: '20px',
        },
        { text: this.$t('state'), value: 'estado' },
        { text: this.$t('dateAcceptance'), value: 'fecha_aceptacion' },
        { text: this.$t('issuer'), value: 'concepto', width: '250px' },
        {
          text: this.$t('issuingAuthority'),
          value: 'nombre_organismo_emisor',
        },
        {
          text: this.$t('rootAuthority'),
          value: 'nombre_organismo_emisor_raiz',
        },
        { text: this.$t('notificationType'), value: 'tipo_envio' },
        { text: this.$t('content'), value: 'contenido', sortable: false },
      ]
      if (!this.operatorsListFlag) auxCustomHeader.splice(1, 1)
      return auxCustomHeader
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isOperatorUser',
      'isAdminUser',
      'isColectivoUser',
      'isTermsAccepted',
    ]),
    notifications() {
      if (this.operatorsListFlag) {
        return this.notificationsWithOperators
      }

      return this.loadAllNotifications
        ? this.allNotifications
        : this.$store.state.notifications
    },
    headers() {
      return this.isMobile
        ? [
            { text: this.$t('company'), value: 'nombre_titular' },
            { text: 'NIF', value: 'nif_titular' },
            { text: this.$t('issuer'), value: 'concepto', width: '300px' },
            { text: this.$t('state'), value: 'estado' },
          ]
        : this.buildCustomHeaders()
    },
    filteredNotifications() {
      if (!this.notifications.length) return
      return this.notifications.filter((item) => {
        // Ignorar las que no pertenezcan a mi empresas cargadas (borradas, no asignadas a mi operador)
        if (
          !this.loadedCifs.some(
            (cif) =>
              cif.numeroDocIdentidad === item.nif_titular ||
              cif.numeroDocIdentidad === item.nif_peticion
          )
        )
          return false

        // String de búsqueda en mayúscula
        const searchCap = this.search ? this.search.toUpperCase() : ''

        const {
          codigo_organismo_emisor,
          codigo_organismo_emisor_raiz,
          concepto,
          fecha_puesta_disposicion,
          fecha_aceptacion,
          id,
          identificador,
          nif_titular,
          nombre_organismo_emisor,
          nombre_organismo_emisor_raiz,
          nombre_titular,
          tipo_envio,
          manager,
          alert,
        } = item

        // Guardamos los elementos en mayúscula
        let dataCap = [
          this.$t(getNotificationStatus(item).status).toUpperCase(),
          codigo_organismo_emisor?.toUpperCase(),
          codigo_organismo_emisor_raiz?.toUpperCase(),
          concepto?.toUpperCase(),
          this.$d(fecha_puesta_disposicion, 'i18nDate').toUpperCase(),
          fecha_aceptacion
            ? this.$d(fecha_aceptacion, 'i18nDate').toUpperCase()
            : '',
          id?.toUpperCase(),
          identificador?.toUpperCase(),
          nif_titular?.toUpperCase(),
          nombre_organismo_emisor?.toUpperCase(),
          nombre_organismo_emisor_raiz?.toUpperCase(),
          nombre_titular?.toUpperCase(),
          this.$t(getNotificationType({ tipo_envio }).name).toUpperCase(),
        ]

        // Si hay elementos de manager los guardamos en la lista (email todo lo anterior a @)
        const managerData = manager
          ? [
              manager.email.split('@')[0].toUpperCase(),
              `${manager.name} ${manager.surname}`.toUpperCase(),
            ]
          : false
        if (managerData) dataCap = [...dataCap, ...managerData]

        // Si hay elementos de alerta los guardamos en la lista
        const alertData = alert
          ? [
              this.$d(alert.date, 'i18nDate').toUpperCase(),
              alert.text.toUpperCase(),
            ]
          : false
        if (alertData) dataCap = [...dataCap, ...alertData]

        // Filtramos
        for (let i = 0; i < dataCap.length; i++)
          if (dataCap[i]?.includes(searchCap)) return item
      })
    },
  },
  created() {
    this.options = this.$store.state.notificationsListOptions
    this.search = this.$store.state.notificationsSearch
  },
  watch: {
    cifs: function (newValue) {
      if (newValue) {
        this.loadedCifs = newValue
      }
    },
  },
}
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
