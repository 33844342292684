<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <div>
            {{ $t('importantNews') }}
            <span class="pl-1 subtitle-2">
              {{ $t('xOfN', [page + 1, news.length]) }}
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog"> mdi-close </v-icon>
        </v-card-title>

        <v-carousel
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          class="pa-5"
          height="auto"
          v-model="page"
        >
          <v-carousel-item v-for="newItem in news" v-bind:key="newItem.id">
            <v-sheet class="pa-3" color="grey lighten-3" height="100%" tile>
              <v-row justify="space-between">
                <v-col cols="auto">
                  <strong>{{ newItem.title }}</strong>
                  <v-icon
                    v-if="newItem.usersRead.indexOf($store.state.user.id) == -1"
                    class="blue--text ml-2"
                    small
                  >
                    mdi-circle
                  </v-icon>
                </v-col>
                <v-col cols="auto">
                  <span>{{ $d(newItem.releaseDate) }}</span>
                </v-col>
                <v-col class="mb-5" cols="12">
                  <span style="white-space: pre-line">
                    {{ newItem.body }}
                  </span>
                  <v-btn
                    v-if="newItem.url"
                    x-small
                    outlined
                    :href="newItem.url"
                    target="_blank"
                  >
                    {{ $t('seeMore') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row justify="space-between">
            <v-col class="ma-0 pa-3" cols="12" md="auto">
              <v-btn color="primary" text @click="markAllAsRead()">
                {{ $t('markAllAsRead') }}
              </v-btn>
            </v-col>
            <v-col class="ma-0 pa-3" cols="12" md="auto">
              <v-btn color="primary" text @click="markAsRead(news[page])">
                {{ $t('markAsRead') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { markAsRead } from '@/services/news-service'
export default {
  props: {
    news: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: true,
      page: 0,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    async markAsRead(item) {
      if (item.usersRead.indexOf(this.$store.state.user.id) == -1)
        await markAsRead(item.id)
    },
    markAllAsRead() {
      this.news.forEach(async (item) => {
        if (
          item.push &&
          item.usersRead.indexOf(this.$store.state.user.id) == -1
        )
          await markAsRead(item.id)
      })
      this.closeDialog()
    },
  },
}
</script>
<style lang="scss" scoped></style>
