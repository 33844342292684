<template>
  <v-container>
    <v-row v-if="statusType !== 'expired'">
      <div v-if="!hideMessage">{{ message }}</div>
      {{ days }} {{ wordString.day }} {{ hours }}h {{ ' ' + minutes }}m{{
        ' ' + seconds
      }}s
    </v-row>
    <v-row v-if="statusType === 'expired'">
      <div>{{ statusText }}</div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['starttime', 'endtime', 'hideMessage'],
  data: function () {
    return {
      timer: '',
      wordString: {},
      start: '',
      end: '',
      interval: '',
      days: '',
      minutes: '',
      hours: '',
      seconds: '',
      message: '',
      statusType: '',
      statusText: '',
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.initCounter()
    },
  },
  mounted() {
    this.initCounter()
  },
  methods: {
    initCounter: function () {
      this.wordString = {
        day: this.$t('timer.day'),
        hours: this.$t('timer.hours'),
        minutes: this.$t('timer.minutes'),
        seconds: this.$t('timer.seconds'),
        expired: this.$t('timer.expired'),
        running: this.$t('timer.running'),
        upcoming: this.$t('timer.upcoming'),
        status: {
          expired: this.$t('timer.status.expired'),
          running: this.$t('timer.status.running'),
          upcoming: this.$t('timer.status.upcoming'),
        },
      }

      this.start = new Date(this.starttime).getTime()
      this.end = new Date(this.endtime).getTime()
      // Update the count down every 1 second
      this.timerCount(this.start, this.end)
      this.interval = setInterval(() => {
        this.timerCount(this.start, this.end)
      }, 1000)
    },
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime()

      // Find the distance between now an the count down date
      var distance = start - now
      var passTime = end - now

      if (distance < 0 && passTime < 0) {
        this.message = this.wordString.expired
        this.statusType = 'expired'
        this.statusText = this.wordString.status.expired
        clearInterval(this.interval)
        return
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime)
        this.message = this.wordString.running
        this.statusType = 'running'
        this.statusText = this.wordString.status.running
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance)
        this.message = this.wordString.upcoming
        this.statusType = 'upcoming'
        this.statusText = this.wordString.status.upcoming
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000)
    },
  },
}
</script>

<style lang="scss" scoped></style>
