<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          <v-col cols="12" align="center">
            <v-row class="body-1 mb-1" justify="center" align="center">
              {{ $t('signers') }}
              <DialogButton section="clients" />
            </v-row>
            <div>
              {{ cif.empresa }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <AppSkeleton :condition="loading" type="historyList">
        <template v-slot:skeleton-content>
          <v-card-text v-if="clients.length > 0">
            <v-list height="450" class="overflow-y-auto">
              <v-list-item
                v-for="client in clients"
                :key="client.id"
                @click="openClientDialog(client, 'display')"
              >
                <v-row align="center">
                  <v-list-item-icon class="mr-4">
                    <AppAvatar
                      :userName="client.name"
                      avatarColor="primary"
                      initialsColor="secondary"
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ client.name }} {{ client.surname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      client.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-col class="d-flex justify-end">
                      <v-tooltip bottom v-if="!hasAccepted(client.id)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            color="warningLow"
                            id="warning-no-certificate"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <div>
                          {{ $t('clientNeedsToAuthorize') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom v-if="!hasAccepted(client.id)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            elevation="1"
                            icon
                            @click.stop="openClientDialog(client, 'update')"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('clientActions.title.update') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            elevation="1"
                            icon
                            @click.stop="openClientDialog(client, 'delete')"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('clientActions.title.delete') }}
                        </div>
                      </v-tooltip>
                    </v-col>
                  </v-list-item-content>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-text style="text-align: center" v-else>
            <span>
              {{ $t('noClients') }}
            </span>
          </v-card-text>
        </template>
      </AppSkeleton>
      <v-card-actions class="headline justify-center">
        <v-btn
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text"
          @click="openClientDialog(null, 'create')"
          id="add-client"
          :disabled="tooManyCerts"
        >
          {{ $t('addClient') }}
        </v-btn>
      </v-card-actions>
      <div style="display: flex; justify-content: center" v-if="tooManyCerts">
        <p style="color: red; font-size: 12px">
          {{ $t('tooManyCertsDialog') }}
        </p>
      </div>
    </v-card>

    <!-- Dialog to create/update an specific client -->
    <ClientDialog
      v-if="clientDialog"
      :cif="cif"
      :client="selectedClient"
      :action="action"
      @closeClientDialog="closeClientDialog"
    />
  </v-dialog>
</template>

<script>
import { getCompanyClientsWalletSubscription } from '@/services/client-service'
import { mapGetters, mapMutations } from 'vuex'
import ClientDialog from './ClientDialog.vue'
import DialogButton from '@/components/documentation/buttons/DialogButton'
export default {
  props: {
    cif: Object,
    tooManyCerts: Boolean,
  },
  components: { ClientDialog, DialogButton },
  destroyed() {
    if (this.clientsUnsubscribe) this.clientsUnsubscribe()
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id
    this.clientsUnsubscribe = await getCompanyClientsWalletSubscription(
      userId,
      this.cif,
      this
    )
  },
  data() {
    return {
      clients: undefined,
      clientsUnsubscribe: () => {},
      clientDialog: false,
      selectedClient: undefined,
      action: '',
      loading: true,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeClientsDialog')
    },
    openClientDialog(client, action) {
      this.selectedClient = client
      this.action = action
      this.clientDialog = true
    },
    /**
     * Function to close the dialog of an specific client
     */
    closeClientDialog() {
      this.selectedClient = false
      this.clientDialog = undefined
    },
    /**
     * Function called when confirm is pressed. Denys the responsibility.
     */
    async performAction() {},
    /**
     * Checks if the client has already authorized the admin.
     * @param {string} clientId Id of the client.
     */
    hasAccepted(clientId) {
      if (!this.cif.allowedToSign) return true

      const allowedId = Object.keys(this.cif.allowedToSign).find(
        (allowedId) => allowedId === clientId
      )
      return this.cif.allowedToSign[allowedId]
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
  },
  watch: {},
}
</script>
