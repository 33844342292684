<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.companies.delete.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic4Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph5') }}
    </p>
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph6') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic5Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph7') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic6Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph8') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic7Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph9') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic8Name" />
    <p>
      {{ $t('documentation.texts.companies.delete.paragraph10') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic10Name" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic5') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic6') +
        '.png')
    },
    pic7Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic7') +
        '.png')
    },
    pic8Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic8') +
        '.png')
    },
    pic9Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic9') +
        '.png')
    },
    pic10Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.delete.pic10') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
