import { render, staticRenderFns } from "./SignaturePropertiesCard.vue?vue&type=template&id=30759120&scoped=true"
import script from "./SignaturePropertiesCard.vue?vue&type=script&lang=js"
export * from "./SignaturePropertiesCard.vue?vue&type=script&lang=js"
import style0 from "./SignaturePropertiesCard.vue?vue&type=style&index=0&id=30759120&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30759120",
  null
  
)

export default component.exports