<template>
  <v-container>
    <div>
      <h1>AVISO LEGAL</h1>
      <p></p>
      <h2>I. Información General</h2>
      <p></p>
      <p>
        Addalia Technology, S.L. (en adelante el prestador) con domicilio en C/
        Agustín de Foxá, 20 - 28036 Madrid, teléfono de contacto 91.033.93.29 y
        fax 91.033.95.77, con CIF B-84606649, e inscrita en el Registro
        Mercantil de Madrid, tomo 22.779, folio 114, sección 8, hoja M-407.678,
        inscripción 5, en la actualidad esta empresa opera a través del nombre
        de dominio addalia.com
      </p>
      <p></p>
      <h2>II. Objeto del Contrato</h2>
      <p>
        El acceso al presente sitio web atribuye al navegante la condición de
        usuario, y acepta expresamente conocer, haber leído y comprender la
        totalidad de la documentación legal expuesta.
      </p>
      <p>
        Todos los contenidos, así como la configuración, documentación legal y
        elementos que conforman este sitio web, podrán ser modificados sin
        previo aviso por parte del prestador, por lo que el propietario
        recomienda al usuario leerla cada vez que acceda al sitio web.
      </p>
      <p></p>
      <h2>III. Del sitio web y responsabilidad</h2>
      <p>
        El prestador se exime de cualquier tipo de responsabilidad derivada de
        la información publicada en su sitio web, siempre que esta información
        haya sido manipulada o introducida por un tercero ajeno a la
        organización.
      </p>
      <p>
        Este sitio web ha sido revisado y probado para que funcione
        correctamente. En principio, puede garantizarse el correcto
        funcionamiento los 365 días del año, 24 horas al día. No obstante, el
        prestador no descarta la posibilidad de que existan ciertos errores de
        programación, o que acontezcan causas de fuerza mayor, catástrofes
        naturales, huelgas, o circunstancias semejantes que hagan imposible el
        acceso a la página web.
      </p>
      <p></p>
      <h2>IV. Propiedad Intelectual</h2>
      <p>
        Todo el sitio web, incluyendo a título enunciativo pero no limitativo su
        programación, edición, compilación y demás elementos necesarios para su
        funcionamiento, los diseños, logotipos, texto y/o gráficos se encuentran
        protegidos por derechos y registros de propiedad intelectual e
        industrial. Es estrictamente necesario contar con la autorización
        escrita del prestador para su reproducción parcial o total, así como
        para la explotación, distribución y comercialización.
      </p>
      <p>
        Los logotipos ajenos al prestador que pudieran aparecer en el sitio web,
        pertenecen a sus respectivos propietarios, siendo ellos mismos
        responsables de cualquier posible controversia que pudiera suscitarse.
        La colocación de los mismos en el sitio web ha sido llevada a cabo
        previa aceptación por parte de los titulares de los mismos.
      </p>
      <p>
        Las reclamaciones que pudieran interponerse por los usuarios en relación
        con posibles incumplimientos de los derechos de propiedad intelectual o
        industrial, así como sobre cualquiera de los contenidos del sitio web
        deberán dirigirse a través del formulario de contacto de la web
        addalia.com
      </p>
      <p></p>
      <h2>V. Ley Aplicable y Jurisdicción</h2>
      <p>
        Para la resolución de todas las controversias o cuestiones relacionadas
        con el presente sitio web o de las actividades en el desarrolladas, será
        de aplicación la legislación española, a la que se someten expresamente
        las partes, siendo competentes para la resolución de todos los
        conflictos derivados o relacionados con el uso de del presente sitio
        web, los Juzgados y Tribunales de Madrid y en su caso, los tribunales
        arbitrales a los que se encuentre adherido el prestador en el momento de
        producirse la controversia.
      </p>
    </div>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
h1,
h2 {
  color: var(--v-primary-base);
}
</style>
