<template>
  <v-dialog :value="true" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          {{ $t(title) }}
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="center" class="mb-5">
          <b> {{ cif.empresa }} </b>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        {{ $t('exportMode') }}
        <v-radio-group v-model="selectedOption">
          <v-radio :label="$t('exportCifsCsvFile')" value="exportCsv">
          </v-radio>
          <div class="optionHint">
            {{
              action === 'exportCifs'
                ? $t('exportCifsCsvHint')
                : $t('exportCifCsvHint')
            }}
          </div>
          <br />
          <v-radio :label="$t('exportCifsZipFile')" value="exportZip">
          </v-radio>
          <div class="optionHint">
            {{
              action === 'exportCifs'
                ? $t('exportCifsZipHint')
                : $t('exportCifZipHint')
            }}
          </div>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-export-dialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || !selectedOption"
          @click="performAction"
          id="confirm-export"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import { exportDmsNotifications } from '@/services/cifs-service'
import { getUserClientsDict } from '@/services/client-service'
import { getGroups, getCifGroupMap } from '@/services/groups-service'
import lodash from 'lodash'

export default {
  props: {
    cifs: Array,
    cif: Object,
    action: String,
    title: String,
    toDeleteExclude: Boolean,
  },
  data() {
    return {
      selectedOption: undefined,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    closeDialog() {
      this.$emit('closeCifExportDialog')
    },
    async performAction() {
      this.loading = true

      if (this.selectedOption === 'exportCsv') {
        if (this.action === 'exportCifs') this.exportCsvCifs(this.cifs)
        if (this.action === 'exportCif') this.exportCsvCif(this.cif)
      }
      if (this.selectedOption === 'exportZip') {
        const exportedCifs =
          this.action === 'exportCifs'
            ? (this.toDeleteExclude
                ? this.cifs.filter((c) => !c.deletionDate)
                : this.cifs
              ).map((cif) => cif.numeroDocIdentidad)
            : [this.cif.numeroDocIdentidad]

        await this.exportZip(exportedCifs)
      }

      this.closeDialog()
      this.loading = false
    },
    async exportZip(cifs) {
      try {
        await exportDmsNotifications(cifs)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('exportCompleteZip')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    /**
     * Función asíncrona que exporta datos de las empresas en un csv
     */
    async exportCsvCifs() {
      // var cifsData = { ...this.cifs }
      //var cifsData = [...this.cifs] no hace copia profunda, se estabn copiando cosas por referencia

      const cifs = this.toDeleteExclude
        ? this.cifs.filter((cif) => !cif.deletionDate)
        : this.cifs

      var cifsData = lodash.cloneDeep(cifs)

      const clients = await getUserClientsDict(
        this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id
      )
      const { groups } = await getGroups()
      const groupMap = await getCifGroupMap(groups, cifs)

      var exportCifs = await Promise.all(
        cifsData.map(async (cif) => {
          // Obtenemos los grupos
          if (groupMap[cif.id]) {
            cif.groups = groupMap[cif.id].map((group) => {
              return group.name
            })
          } else cif.groups = []

          // Obtenemos los usuarios para notificaciones
          if (cif.notificacionesActivas) {
            cif.allowedNotifications = cif.users
              .filter(
                (user) =>
                  user.cifsPermissions[cif.numeroDocIdentidad]
                    ?.notificacionesActivas
              )
              .map((user) => {
                return user.name + ' ' + user.surname
              })
          } else cif.allowedNotifications = []

          // Obtenemos los usuarios para firmas
          if (cif.firmasActivas) {
            cif.allowedSignatures = cif.users
              .filter(
                (user) =>
                  user.cifsPermissions[cif.numeroDocIdentidad]?.firmasActivas
              )
              .map((user) => {
                return user.name + ' ' + user.surname
              })

            // Obtenemos los clientes autorizados para firmar
            if (cif.allowedToSign) {
              cif.allowedToSignIds = Object.keys(cif.allowedToSign)
                .filter((clientId) => cif.allowedToSign[clientId])
                .map((authorized) => {
                  const user = clients[authorized]
                  return user.name + ' ' + user.surname
                })
            } else cif.allowedToSignIds = []
          } else cif.allowedSignatures = cif.allowedToSignIds = []

          //eliminamos campos indeseados
          delete cif.id
          delete cif.password
          delete cif.identificador
          delete cif.users
          delete cif.tipo
          delete cif.nombre
          delete cif.apellidos
          delete cif.telefono
          delete cif.direccion
          delete cif.codigoPostal
          delete cif.provincia
          delete cif.email
          delete cif.username
          return cif
        })
      )

      if (exportCifs.length < 1) {
        const message = this.$t('nothingToExport')
        this.setSnackbar({ position: 'top', type: 'info', message })
        return
      }
      const replacer = (key, value) => (value === null ? '' : value) // valores undefined
      const header = [
        //header auxiliar con el nombre de las variables
        ...(!this.toDeleteExclude ? ['deletionDate'] : []),
        'empresa',
        'tipoDocIdentidad',
        'numeroDocIdentidad',
        'groups',
        //'afiliado',
        'notificacionesActivas',
        'allowedNotifications',
        'firmasActivas',
        'allowedSignatures',
        'allowedToSignIds',
      ]

      const header2 = [
        //header a mostrar en el csv
        ...(!this.toDeleteExclude
          ? [this.$i18n.t('csv.companies.deletePending')]
          : []),
        this.$i18n.t('csv.companies.empresa'),
        this.$i18n.t('csv.companies.tipoDocIdentidad'),
        this.$i18n.t('csv.companies.numeroDocIdentidad'),
        this.$i18n.t('csv.companies.groups'),
        //this.$i18n.t('csv.companies.afiliado'),
        this.$i18n.t('csv.companies.notificacionesActivas'),
        this.$i18n.t('csv.companies.allowedNotifications'),
        this.$i18n.t('csv.companies.firmasActivas'),
        this.$i18n.t('csv.companies.allowedSignatures'),
        this.$i18n.t('csv.companies.allowedToSignIds'),
      ]

      const csv = [
        header2.join(';'), // asignamos separador
        ...exportCifs.map((row) =>
          header
            .map((fieldName) => {
              if (fieldName === 'deletionDate')
                return row['deletionDate'] ? 'true' : 'false'
              else return JSON.stringify(row[fieldName], replacer)
            })
            .join(';')
        ),
      ].join('\r\n')
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([csv], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = 'cifs.csv'
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
      const message = this.$t('exportComplete')
      this.setSnackbar({ position: 'top', type: 'success', message })
      return
    },
    /**
     * Función que exporta a un csv las notificaciones del CIF seleccionado
     * @param {Object} cif - Objeto que contiene el número de identificación fiscal (NIF) cuyas notificaciones se quieres obtener
     */
    exportCsvCif(cif) {
      const exportNotifications = this.$store.state.notifications
        .filter(
          (notification) => notification.nif_titular == cif.numeroDocIdentidad
        )
        .map((notification) => {
          return {
            identificador: notification.identificador,
            estado: notification.estado,
            concepto: notification.concepto,
            fecha_puesta_disposicion:
              notification.fecha_puesta_disposicion.toLocaleDateString(
                this.$i18n.locale,
                {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }
              ),
            fecha_aceptacion: notification.fecha_aceptacion
              ? notification.fecha_aceptacion.toLocaleDateString(
                  this.$i18n.locale,
                  {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                )
              : '',
            nif_titular: notification.nif_titular,
            nombre_titular: notification.nombre_titular,
            nif_peticion: notification.nif_peticion,
            nombre_organismo_emisor: notification.nombre_organismo_emisor,
            codigo_organismo_emisor: notification.codigo_organismo_emisor,
            nombre_organismo_emisor_raiz:
              notification.nombre_organismo_emisor_raiz,
            codigo_organismo_emisor_raiz:
              notification.codigo_organismo_emisor_raiz,
          }
        })
      if (exportNotifications.length < 1) {
        const message = this.$t('nothingToExport')
        this.setSnackbar({ position: 'top', type: 'info', message })
        return
      }
      const replacer = (key, value) => (value === null ? '' : value) // valores undefined

      //header auxiliar con el nombre de las variables
      const header = [
        'identificador',
        'estado',
        'concepto',
        'fecha_puesta_disposicion',
        'fecha_aceptacion',
        'nif_titular',
        'nombre_titular',
        'nif_peticion',
        'nombre_organismo_emisor',
        'codigo_organismo_emisor',
        'nombre_organismo_emisor_raiz',
        'codigo_organismo_emisor_raiz',
      ]
      //header a mostrar en el csv
      const header2 = [
        this.$i18n.t('csv.notifications.identificador'),
        this.$i18n.t('csv.notifications.estado'),
        this.$i18n.t('csv.notifications.concepto'),
        this.$i18n.t('csv.notifications.fecha_puesta_disposicion'),
        this.$i18n.t('csv.notifications.fecha_aceptacion'),
        this.$i18n.t('csv.notifications.nif_titular'),
        this.$i18n.t('csv.notifications.nombre_titular'),
        this.$i18n.t('csv.notifications.nif_peticion'),
        this.$i18n.t('csv.notifications.nombre_organismo_emisor'),
        this.$i18n.t('csv.notifications.codigo_organismo_emisor'),
        this.$i18n.t('csv.notifications.nombre_organismo_emisor_raiz'),
        this.$i18n.t('csv.notifications.codigo_organismo_emisor_raiz'),
      ]

      const csv = [
        header2.join(';'), // asignamos separador
        ...exportNotifications.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        ),
      ].join('\r\n')
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([csv], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = cif.numeroDocIdentidad + '.csv'
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
      const message = this.$t('exportComplete')
      this.setSnackbar({ position: 'top', type: 'success', message })
      return
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}

.optionHint {
  color: grey;
  font-size: 12px;
}
</style>
