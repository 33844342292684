import { render, staticRenderFns } from "./CifsUnregisteredGroups.vue?vue&type=template&id=61e55d0a"
import script from "./CifsUnregisteredGroups.vue?vue&type=script&lang=js"
export * from "./CifsUnregisteredGroups.vue?vue&type=script&lang=js"
import style0 from "./CifsUnregisteredGroups.vue?vue&type=style&index=0&id=61e55d0a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports