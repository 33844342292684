/**
 * Function to get the base64 of several files and store the base64 in the context.
 * @param {Array} files Array containing the files to have the base64 files.
 * @param {String} key Key belonging to the context where the files in base64 must be stored.
 * @param {Object} context Context of the component where the function is called.
 */
export const severalToBase64 = (files, key, context) => {
  for (const file of files) this.toBase64(file, key, context)
}

/**
 * Function to get the base64 of files and store the base64 in the context.
 * @param {Object} file Object containing the files to have the base64 files.
 * @param {String} key Key belonging to the context where the files in base64 must be stored.
 * @param {Object} context Context of the component where the function is called.
 */
export const toBase64 = (file, key, context) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    const fileInfo = {
      name: file.name,
      size: file.size,
      mimeType: file.type,
      base64: e.target.result.split(',')[1],
    }
    context[key].push(fileInfo)
  }
  reader.readAsDataURL(file)
}

/**
 * Function to get the base64 of files.
 * @param {Object} file Object containing the files to have the base64 files.
 * @returns A promise with the file info
 */
export const toBase64Async = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const fileInfo = {
        name: file.name,
        size: file.size,
        mimeType: file.type,
        base64: e.target.result.split(',')[1],
      }
      resolve(fileInfo)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}
