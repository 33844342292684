var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":!_vm.selectedAction,"close-on-click":!_vm.selectedAction,"rounded":"","value":_vm.shown},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tooltip',{attrs:{"max-width":"400","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip, attrs: attrsTooltip }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary secondary--text ma-1",style:({
            'box-shadow': _vm.selectedAction
              ? '0 0 10px 3px ' +
                _vm.$vuetify.theme.currentTheme.primary +
                ' !important'
              : '',
          }),attrs:{"elevation":"1","fab":"","small":"","id":"actions-multiple","disabled":_vm.initLoading},on:{"click":function($event){_vm.shown = true}}},'v-btn',{ ...attrs, ...attrsTooltip },false),{ ...on, ...onTooltip }),[_c('v-icon',[_vm._v(" mdi-checkbox-multiple-marked ")])],1)]}}],null,true)},[_c('div',{attrs:{"width":"300"}},[_vm._v(" "+_vm._s(_vm.$t('massiveAction'))+" ")])])]}}])},[(!_vm.selectedAction)?[_c('v-list',{attrs:{"width":"300"}},_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:'massive-action-' + index + '-' + item,attrs:{"value":index},on:{"click":function($event){return _vm.setAction(item)}}},[_c('v-list-item-title',{style:({
            color:
              item === _vm.selectedAction
                ? _vm.$vuetify.theme.currentTheme.primary
                : '',
          })},[_vm._v(" "+_vm._s(_vm.$t('massiveActions.titles.' + item))+" ")])],1)}),1)]:_c('v-card',{attrs:{"width":"300","max-height":"600"}},[_c('v-card-text',[_c('b',[_vm._v(_vm._s(_vm.$t('massiveActions.titles.' + _vm.selectedAction)))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.items.length)+" "+_vm._s(_vm.$t('elements')))]),_c('div',{staticClass:"my-5"}),_c('v-form',{ref:"massiveActionForm",attrs:{"lazy-validation":"","loading":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.validMassiveActionForm),callback:function ($$v) {_vm.validMassiveActionForm=$$v},expression:"validMassiveActionForm"}},[(_vm.isChangeStatus)?_c('ChangeStatusOptions',{on:{"setStatus":(newStatus) => _vm.setData(newStatus, 'status')}}):_vm._e(),(_vm.isSetResponsible)?_c('OperatorsOptions',{attrs:{"operators":_vm.operators},on:{"setOperator":(newOperator) => _vm.setData(newOperator, 'operator')}}):_vm._e(),(_vm.isSetAlert)?_c('AlertOptions',{on:{"setAlert":(newAlert) => _vm.setData(newAlert, 'alert')}}):_vm._e(),(_vm.isSetObservation)?_c('ObservationOptions',{on:{"setObservation":(newObservation) => _vm.setData(newObservation, 'observation')}}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"small":"","icon":"","color":"success","disabled":_vm.noItemsSelected || _vm.loading,"loading":_vm.loading},on:{"click":_vm.performAction}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"error"},on:{"click":_vm.removeAction}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }