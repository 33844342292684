import store from '@/store/index'
import {
  Firestore,
  Database,
  NotificationsDoc,
  SignaturesDoc,
  HistoryCollection,
  HistoryDoc,
  UsersDoc,
} from '@/firebase-exports'

/**
 * Devuelve el estado de alerta para una notificación o firma electrónica, según la fecha de emisión.
 * @param {Object} date - La fecha de la Alerta.
 * @returns {Object} - Un objeto que contiene la información del estado de alerta.
 */
export function getAlertStatus(alert) {
  const currentDate = Date.now()
  if (!alert) {
    return {
      color: 'warningLow',
      icon: 'mdi-bell-outline',
      status: '',
    }
  }
  // Si la fecha de hoy es posterior a la emision + 10 dias es que ha expirado
  else if (
    currentDate > new Date(alert.date).setDate(new Date(alert.date).getDate())
  ) {
    return {
      color: 'error',
      icon: 'mdi-bell-alert',
      status: 'alertStatus.expired',
    }
  } else if (
    currentDate <
      new Date(alert.date).setDate(new Date(alert.date).getDate()) &&
    new Date(alert.date).setDate(new Date(alert.date).getDate() - 3) <
      currentDate
  ) {
    return {
      color: 'warning',
      icon: 'mdi-bell-ring',
      status: 'alertStatus.urgent',
    }
  } else {
    return {
      color: 'accept',
      icon: 'mdi-bell',
      status: 'alertStatus.pending',
    }
  }
}

/**
 * Crea una alerta para una notificación o firma electrónica.
 * @param {string} assetId - El ID del Asset.
 * @param {Object} alert - El objeto de alerta.
 * @param {string} objectType - El tipo de objeto (notificación o firma electrónica).
 * @returns {Promise} - Una promesa que se resuelve cuando la operación ha finalizado.
 */
export async function alertCreate(assetId, alert, objectType) {
  // Validar que se haya proporcionado un ID de notificación
  if (!assetId || typeof assetId !== 'string') {
    throw new Error('El ID de la notificación es inválido')
  }

  // Validar que se haya proporcionado un objeto de alerta
  if (!alert || typeof alert !== 'object') {
    throw new Error('El objeto de alerta es inválido')
  }

  // Validar que se haya proporcionado un tipo de objeto válido
  if (!['notifications', 'signatures'].includes(objectType)) {
    throw new Error('El tipo de objeto es inválido')
  }
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  if (objectType === 'notifications') {
    batch.update(NotificationsDoc(userId, assetId), { alert })
  } else if (objectType === 'signatures') {
    batch.update(SignaturesDoc(userId, assetId), { alert })
  }

  batch.set(
    HistoryDoc(
      userId,
      objectType,
      assetId,
      Firestore.doc(HistoryCollection(userId, assetId, objectType)).id
    ),
    {
      actionType: 'alertCreate',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
      ...alert,
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Modifica una alerta para una notificación o firma electrónica.
 * @param {string} assetId - El ID de asset.
 * @param {Object} alert - El objeto de alerta.
 * @param {string} objectType - El tipo de objeto (notificación o firma electrónica).
 * @returns {Promise} - Una promesa que se resuelve cuando la operación ha finalizado.
 */
export async function alertUpdate(assetId, alert, objectType) {
  // Validar que se haya proporcionado un ID de notificación
  if (!assetId || typeof assetId !== 'string') {
    throw new Error('El ID de la notificación es inválido')
  }

  // Validar que se haya proporcionado un objeto de alerta
  if (!alert || typeof alert !== 'object') {
    throw new Error('El objeto de alerta es inválido')
  }

  // Validar que se haya proporcionado un tipo de objeto válido
  if (!['notifications', 'signatures'].includes(objectType)) {
    throw new Error('El tipo de objeto es inválido')
  }

  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  if (objectType === 'notifications') {
    batch.update(NotificationsDoc(userId, assetId), { alert })
  } else if (objectType === 'signatures') {
    batch.update(SignaturesDoc(userId, assetId), { alert })
  }

  batch.set(
    HistoryDoc(
      userId,
      objectType,
      assetId,
      Firestore.doc(HistoryCollection(userId, objectType, assetId)).id
    ),
    {
      actionType: 'alertModify',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
      ...alert,
    },
    { merge: true }
  )

  return await batch.commit()
}

/**
 * Marca una alerta como completada para una notificación o firma electrónica.
 * @param {string} assetId - El ID del Asset.
 * @param {Object} alert - El objeto de alerta.
 * @param {string} objectType - El tipo de objeto (notificación o firma electrónica).
 * @returns {Promise} - Una promesa que se resuelve cuando la operación ha finalizado.
 */
export async function alertDone(assetId, alert, objectType) {
  // Validar que se haya proporcionado un ID de notificación
  if (!assetId || typeof assetId !== 'string') {
    throw new Error('El ID de la notificación es inválido')
  }

  // Validar que se haya proporcionado un objeto de alerta
  if (!alert || typeof alert !== 'object') {
    throw new Error('El objeto de alerta es inválido')
  }

  // Validar que se haya proporcionado un tipo de objeto válido
  if (!['notifications', 'signatures'].includes(objectType)) {
    throw new Error('El tipo de objeto es inválido')
  }

  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const batch = Firestore.writeBatch(Database)

  if (objectType === 'notifications') {
    batch.update(NotificationsDoc(userId, assetId), {
      alert: Firestore.deleteField(),
    })
  } else if (objectType === 'signatures') {
    batch.update(SignaturesDoc(userId, assetId), {
      alert: Firestore.deleteField(),
    })
  }

  batch.set(
    HistoryDoc(
      userId,
      objectType,
      assetId,
      Firestore.doc(HistoryCollection(userId, objectType, assetId)).id
    ),
    {
      actionType: 'alertDone',
      createdAt: Firestore.serverTimestamp(),
      userRef: UsersDoc(store.state.user.id),
      ...alert,
    },
    { merge: true }
  )

  return await batch.commit()
}
