<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8">
        <FilterList :filters="filters" :loadingTableData="loadingTableData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listDefaultFilters } from '@/services/filter-service'
import FilterList from '@/components/filter/FilterList'

export default {
  components: {
    FilterList,
  },
  data() {
    return {
      loadingTableData: true,
      filters: [],
    }
  },
  async mounted() {
    this.filters = (await listDefaultFilters()).filter(function (filter) {
      return filter.name != 'noFilter'
    })

    await new Promise((resolve) => setTimeout(resolve, 300))
    this.loadingTableData = false
  },
}
</script>
