import {
  Firestore,
  UsersDoc,
  CIFDoc,
  WalletDoc,
  DeleteWallet,
  UsersCollection,
  ClientCreate,
  UpdateWallet,
  UpdatePin,
} from '@/firebase-exports'

/**
 * Function to create a client
 * @param {string} userId Id of the parent (admin).
 * @param {string} name Name of the client.
 * @param {string} surname Lastname of the client.
 * @param {string} email Emai of the client.
 */
export async function createClient(userId, name, surname, email) {
  return (
    await ClientCreate({
      userId,
      name,
      surname,
      email,
    })
  ).data
}

/**
 * Function to deny the responsibility.
 * @param {string} userId Id of the parent of the client.
 * @param {Object} cif Object where the information of the cif is stored.
 * @param {string} clientId Id of the client.
 */
export const clientDenyResponsibility = async (userId, clientId, cifId) => {
  await DeleteWallet({
    userId,
    clientId,
    walletId: cifId,
  })
}

/**
 * Gets the data of the wallet.
 * @param {string} clientId Id of the client who has the wallet.
 * @param {string} walletId Id of the wallet to get.
 */
export const getWallet = async (clientId, walletId) => {
  const walletDoc = await Firestore.getDoc(WalletDoc(clientId, walletId))
  return { id: walletDoc.id, ...walletDoc.data() }
}

/**
 * Function that gets a subscription of the clients of a company given the Id.
 * @param {string} parentId Id of the admin
 * @param {string} cif Cif data of the company to get their clients.
 * @param {Object} context Context that has th "clients" variable where it is
 * going to be stored.
 * @returns {function} Function that closes the subscription.
 */
export const getCompanyClientsWalletSubscription = (parentId, cif, context) => {
  var cifsUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      UsersCollection,
      Firestore.where('tipo', '==', 'clienteDespacho'),
      Firestore.where('parentRef', '==', UsersDoc(parentId)),
      Firestore.where('cifsRef', 'array-contains', CIFDoc(parentId, cif.id))
    ),
    (snapShot) => {
      const clients = snapShot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      context.clients = clients
        .filter(
          (client) =>
            client.cifsPermissions[cif.numeroDocIdentidad].firmasActivas
        )
        .sort((a, b) => a.name.localeCompare(b.name))

      if (context.loading)
        setTimeout(() => {
          context.loading = false
        }, 500)
    },
    (error) => {
      throw error
    }
  )
  return cifsUnsubscribe
}

/**
 * Gets the clients of the current user stored in an object
 * where the key is the id and the value is the document (id included).
 * @param {string} userId  Id of the main user to get their clients
 * @returns Clients of a user but stored in an object.
 */
export async function getUserClientsDict(userId) {
  const docsSnap = await Firestore.getDocs(
    Firestore.query(
      UsersCollection,
      Firestore.where('tipo', '==', 'clienteDespacho'),
      Firestore.where('parentRef', '==', UsersDoc(userId))
    )
  )

  const clients = {}

  docsSnap.docs.forEach((doc) => {
    clients[doc.id] = { id: doc.id, ...doc.data() }
  })

  return clients
}

/**
 * Function to update the client object
 * @param {string} clientId Id of the client to update.
 * @param {Object} data Data to update in the client.
 */
export const updateClient = async (clientId, data) => {
  await Firestore.updateDoc(UsersDoc(clientId), data)
}

/**
 * Function to update the data of the client.
 * @param {string} clientId Id of the client.
 * @param {string} walletId Id of the wallet
 * (it is the same one as the id of the company - cifId)
 * @param {Object} data Object containing the data to update.
 */
export const updateClientWallet = async (clientId, walletId, data) => {
  await Firestore.updateDoc(WalletDoc(clientId, walletId), data)
}

/**
 * Function to update a certificate.
 * @param {string} userId Admin user Id.
 * @param {string} clientId Client's id.
 * @param {string} walletId Wallet's Id.
 */
export const deleteClientWallet = async (userId, clientId, walletId) => {
  await DeleteWallet({
    userId,
    clientId,
    walletId,
  })
}

/**
 * Function that checks if the client has to authorize any company.
 * @param {String} clientId Id of the client.
 * @param {Array} cifsRef Array of references of cifs.
 * @param {Obj} cifsPermissions Object of permissions of cifs.
 * @returns Boolean value that represents if the user has to authorize any company.
 */
export const hasToAuthorize = async (clientId, cifsRef, cifsPermissions) => {
  for (const ref of cifsRef) {
    const doc = await Firestore.getDoc(ref)
    const cif = { id: doc.id, ...doc.data() }

    // Check GD pending authorization
    if (cifsPermissions[cif.numeroDocIdentidad].notificacionesActivas) {
      if (!cif.certificateExpiration) return true
    }

    // Check Wallet pending authorization
    if (cifsPermissions[cif.numeroDocIdentidad].firmasActivas) {
      if (!cif.allowedToSign) continue

      // Checking if he has to authorize the company
      if (
        !cif.allowedToSign[clientId] &&
        cif.allowedToSign[clientId] !== undefined
      )
        return true
    }
  }

  return false
}

/**
 * Updates the wallet info given some data.
 * @param {Object} data Object containing the data of the of the wallet
 * as well as some mandatory fields:
 * (mandatory) userId: Id of the parent
 * (mandatory) clientId: Id of the client
 * (mandatory) cifId: Id of the company of the wallet
 * certificateType: Type of the new certificate
 * certificate: Base64 of the certificate
 * certificateUser: Certificate user
 * certificatePassword: password of the certificate
 * pin: New pin
 * telefono: New phone
 */
export async function updateWallet(data) {
  await UpdateWallet(data)
}

/**
 * Function to update the pin. This function is called by the
 * parent of a client.
 * @param {string} clientId Admin user Id.
 * @param {string} walletId Wallet's Id.
 * @param {string} pin New pin.
 */
export const updatePin = async (clientId, walletId, pin) => {
  await UpdatePin({
    clientId,
    walletId,
    pin,
  })
}
