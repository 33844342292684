<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <h2 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.actions.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.automatedTasks.actions.p1') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 400px;
        align-self: center;
        border-radius: 10px;
      "
      class="ml-4 mb-4"
      contain
      :src="pic1Name"
    />
    <ul class="mt-3">
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li1Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li1') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li2Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li2') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li3Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li3') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li4Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li4') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li5Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li5') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.actions.li6Title') }}</b>
        {{ $t('documentation.texts.automatedTasks.actions.li6') }}
      </li>
    </ul>
    <p class="mt-3">
      {{ $t('documentation.texts.automatedTasks.actions.p2') }}
    </p>
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.actions.pic1') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
