<template>
  <v-card class="pa-3">
    <v-form ref="helpForm" v-model="validForm" lazy-validation>
      <v-container>
        <v-row align="center" justify="center">
          <h1>{{ $t('help.title') }}</h1>
        </v-row>
        <v-row align="center" justify="center">
          <h3 class="headline mb-1">{{ $t('help.info') }}</h3>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="helpForm.subject"
              :rules="subjectRules"
              :label="$t('help.subject')"
              prepend-icon="mdi-format-title"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="helpForm.message"
              :rules="messageRules"
              :label="$t('help.message')"
              prepend-icon="mdi-card-text-outline"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="helpForm.type"
              :items="types"
              color="primary"
              :label="$t('help.type')"
              item-value="id"
              :rules="typeRules"
              prepend-icon="mdi-format-list-bulleted-type"
            >
              <template v-slot:item="{ item }">
                {{ $t(`help.types.${item.name}`) }}
              </template>
              <template v-slot:selection="{ item }">
                {{ $t(`help.types.${item.name}`) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="helpForm.priority"
              :items="priority"
              color="primary"
              :label="$t('help.priority')"
              item-value="id"
              :rules="priorityRules"
              prepend-icon="mdi-arrow-up-down"
            >
              <template v-slot:item="{ item }">
                {{ $t(`help.priorities.${item.name}`) }}
              </template>
              <template v-slot:selection="{ item }">
                {{ $t(`help.priorities.${item.name}`) }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              :disabled="loading"
              :loading="loading"
              class="primary secondary--text"
              @click="sendForm"
            >
              {{ $t('help.formButton') }}
              <template v-slot:loader>
                <v-progress-circular indeterminate size="20" width="2" />
                <span class="ml-2">{{ $t('help.sendingForm') }}</span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import rules from '@/utils/rules'
import { redmineTypes, redminePriority } from '@/utils/redmine-utils.js'
import { HelpFormFunction } from '@/firebase-exports'
import { mapMutations } from 'vuex'

export default {
  watch: {
    '$i18n.locale': function () {
      this.$refs.helpForm.resetValidation()
    },
  },
  data() {
    return {
      helpForm: {},
      types: redmineTypes(),
      priority: redminePriority(),
      loading: false,
      subjectRules: [rules.required],
      messageRules: [rules.required],
      typeRules: [rules.required],
      priorityRules: [rules.required],
      validForm: true,
    }
  },
  methods: {
    ...mapMutations(['setDialog', 'setSnackbar']),
    initDefaultData() {
      this.helpForm.subject = ''
      this.helpForm.message = ''
      this.helpForm.type = redmineTypes(true).id
      this.helpForm.priority = redminePriority(true).id

      if (this.$refs.helpForm) this.$refs.helpForm.resetValidation()
    },
    async sendForm() {
      if (!this.$refs.helpForm.validate()) return

      this.loading = true
      try {
        await HelpFormFunction(this.helpForm)

        const title = this.$t('help.title')
        const message = this.$t('help.formSuccess')
        this.setDialog({ title, message })

        //this.$refs.helpForm.reset()
        this.initDefaultData()
      } catch (err) {
        const message = this.$t('help.formError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }

      this.loading = false
    },
  },
  created() {
    this.initDefaultData()
  },
}
</script>
