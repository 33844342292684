<template>
  <v-list
    v-if="notificationList.length != 0"
    subheader
    two-line
    height="450"
    :style="{ 'overflow-y': scrollList }"
  >
    <v-list-item
      v-for="notification in notificationList"
      :key="notification.id"
      @click="
        $router.push({
          name: 'NotificationDetailView',
          params: {
            id: notification.id,
          },
        })
      "
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <AppAvatar
              v-if="notification.manager"
              :userName="`${notification.manager.name} ${notification.manager.surname}`"
              :userAvatar="notification.manager.avatarURL"
              avatarSize="30"
              iconSize="24"
              avatarColor="primary"
              initialsColor="secondary"
            />
          </span>
        </template>
        <div width="300">
          {{ `${notification.manager.name} ${notification.manager.surname}` }}
        </div>
      </v-tooltip>

      <v-chip
        class="white--text mr-3 ml-3"
        :color="getNotificationStatus(notification).color"
      >
        <v-icon :class="getNotificationStatus(notification).color" dark>
          {{ getNotificationType(notification).icon }}
        </v-icon>
        <span class="ml-1">{{
          $t(getNotificationStatus(notification).status)
        }}</span>
      </v-chip>
      <div class="ml-3 mr-3 subtitle">
        {{ $d(notification.fecha_puesta_disposicion, 'i18nDate') }}
      </div>
      <v-list-item-content>
        <v-list-item-title>
          {{ notification.nombre_organismo_emisor }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ notification.nombre_organismo_emisor_raiz }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-container v-else style="text-align: center">
    <b>
      <span> {{ $t('emptyTypeNotificationList') }} </span>
    </b>
  </v-container>
</template>
<script>
import {
  getNotificationStatus,
  getNotificationType,
} from '@/services/notification-service'

export default {
  props: {
    notificationList: {
      type: Array,
    },
  },
  methods: {
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
  },
  computed: {
    scrollList() {
      return this.notificationList.length > 5 ? 'scroll' : 'hidden'
    },
  },
}
</script>
