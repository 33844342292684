/* import { Firestore, LegalSettings } from '@/firebase-exports'

/**
 * Obtiene el objeto legal de settings.
 *
 * @returns {Promise<Object|undefined>} Una promesa que se resuelve con el objeto legal de la configuracion, o undefined si no existe
 */
/* export async function legalSettings() {
    let response = await Firestore.getDoc(LegalSettings)
    console.log('legalSettings() response=', response)
    const { legal } = response.data()
    console.log('legalSettings() legal=', legal)
    return legal ? legal : undefined
  }
 */

import { Firestore, LegalSettings } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Crea una subscripción al documento 'legal' de la aplicación, situado en la coleccion settings,
 * actualizando el mapa de modelos de contrato en la tienda de estado (store).
 * Esta función escucha los cambios y los refleja automáticamente en el estado de la aplicación.
 *
 * @returns {Function} Función para cancelar la subscripción. Al llamar a esta función, se detendrá la escucha de cambios en los contratos de la aplicación.
 */
export function getAppLegalSubscription() {
  const appLegalUnsubscribe = Firestore.onSnapshot(
    LegalSettings,
    (doc) => {
      //console.log('setContractModel map=', doc.data().contractModel)
      store.commit('setContractModel', doc.data().contractModel)
    },
    (error) => {
      throw error
    }
  )
  return appLegalUnsubscribe
}
