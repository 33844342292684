<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.clients.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.clients.create.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.clients.create.paragraph1') }}
    </p>
    <v-img
      style="width: 100%; max-width: 800px; align-self: center"
      contain
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.clients.create.paragraph2') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.create.paragraph2-1') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.create.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.clients.create.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.clients.create.paragraph5') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic4Name" />
    <p>
      {{ $t('documentation.texts.clients.create.paragraph6') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.create.paragraph7') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic5Name" />
    <p>
      {{ $t('documentation.texts.clients.create.paragraph8') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.create.paragraph9') }}
    </p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic5') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic6') +
        '.png')
    },
    pic7Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.create.pic7') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
