<template>
  <div>
    <AppSkeleton :condition="cifs === undefined" max-width="300" type="card">
      <template v-slot:skeleton-content>
        <v-card class="mx-auto" outlined color="transparent">
          <v-container>
            <v-row justify="space-between" align="center">
              <div class="noteBoxes typeBlue" style="display: flex">
                <img src="@/assets/info.png" class="note-box-icon" />
                <div style="width: 100%">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: baseline;
                      justify-content: space-between;
                    "
                  >
                    <h3>{{ $t('cifsNotRegistered') }}</h3>
                    <v-checkbox
                      class="pa-0 ma-0 ml-9"
                      v-model="showHidden"
                      :label="$t('showHiddenCifs')"
                    />
                  </div>
                  <p></p>
                  <p>
                    {{ $t('cifsNotRegisteredInfo') }}
                  </p>
                  <ul>
                    <li :key="cif.cif" v-for="cif in filteredCifs">
                      <span
                        :style="{
                          color: cif.rejected
                            ? 'grey'
                            : !$vuetify.theme.dark
                            ? '#000000DE'
                            : '#FFFFFF',
                        }"
                      >
                        • {{ cif.name }} ({{ cif.cif }})
                      </span>
                      <v-btn
                        plain
                        small
                        @click="createCompany(cif)"
                        :loading="loadingCreate"
                        :disabled="loadingCreate || loadingIgnore"
                      >
                        {{ $t('add') }}
                      </v-btn>
                      <v-btn
                        v-if="!cif.rejected"
                        plain
                        small
                        @click="updateIgnore(cif, true)"
                        :loading="loadingIgnore"
                        :disabled="loadingCreate || loadingIgnore"
                      >
                        {{ $t('ignore') }}
                      </v-btn>
                      <v-btn
                        v-if="cif.rejected"
                        plain
                        small
                        @click="updateIgnore(cif, false)"
                        :loading="loadingIgnore"
                        :disabled="loadingCreate || loadingIgnore"
                      >
                        {{ $t('show') }}
                      </v-btn>
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </AppSkeleton>

    <CifDialog
      :cifs="cifs"
      :groups="groups"
      :groupMap="groupMap"
      :cif="selectedCif"
      :action="'createUnregistered'"
      :title="'companiesActions.title.createUnregistered'"
      :notificationsMaxCifs="notificationsMaxCifs"
      :cifsCountNotifications="cifsCountNotifications"
      @closeCifDialog="() => (cifDialog = false)"
      v-if="cifDialog"
    />
  </div>
</template>

<script>
import CifDialog from '@/components/cifs/CifDialog'
import getErrorText from '@/utils/get-error-text'
import {
  // createCIF,
  ignoreUnregisteredCif,
} from '../../services/cifs-service'
import { mapMutations } from 'vuex'
import { getTypeByNumber } from '@/utils/identity-number'
export default {
  props: {
    cifs: Array,
    groups: Array,
    groupMap: Object,
    notificationsMaxCifs: Number,
    cifsCountNotifications: Number,
  },
  components: {
    CifDialog,
  },
  data() {
    return {
      unregisteredCifs: [...this.cifs],
      loadingIgnore: false,
      loadingCreate: false,
      cifDialog: false,
      selectedCif: {},
      showHidden: false,
    }
  },
  computed: {
    filteredCifs() {
      return this.showHidden
        ? this.cifs
        : this.cifs.filter((cif) => !cif.rejected)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Updates the rejected status of a cif object.
     * @param {Object} cif The company to update.
     * @param {boolean} value The new rejected status.
     */
    async updateIgnore(cif, value) {
      this.loadingIgnore = true
      try {
        cif.rejected = value
        // Encontrar el índice del objeto cif en la lista this.unregisteredCifs
        const index = this.unregisteredCifs.findIndex(
          (item) => item.cif === cif.cif
        )
        if (index !== -1) {
          // Actualizar el estado de rejected en la lista this.unregisteredCifs
          // originalmente estaba asi:
          // this.unregisteredCifs[index].rejected = value
          // y se cambia por esto:
          // { ...cif, rejected: value } es el nuevo objeto que reemplazará al objeto existente en la posición index.
          // Utilizamos el spread operator (...cif) para copiar todas las propiedades del objeto cif y luego
          // sobrescribimos la propiedad rejected con el nuevo value.
          // Uso de this.$set para garantizar la reactividad, práctica recomendada en Vue.js
          this.$set(this.unregisteredCifs, index, { ...cif, rejected: value })
          await ignoreUnregisteredCif(this.unregisteredCifs)
          const message = this.$t('cifIgnored')
          this.setSnackbar({ position: 'top', type: 'success', message })
        } else {
          console.log(
            'error, index=-1, no se ha resuelto el index del cif: ',
            cif.cif
          )
        }
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
      this.loadingIgnore = false
    },
    /**
     * Creates a company
     * @param {object} cif object with the company to create.
     */
    async createCompany(cif) {
      this.cifDialog = true
      // const userId = this.isOperatorUser
      //   ? this.$store.state.user.parentRef.id
      //   : this.$store.state.user.id

      this.selectedCif = {
        empresa: cif.name,
        numeroDocIdentidad: cif.cif,
        tipoDocIdentidad: getTypeByNumber(cif.cif),
        firmasActivas: false,
        notificacionesActivas: true,
        granDestinatario: false,
      }

      // this.loadingCreate = true
      // try {
      //   const cifData = {
      //     empresa: this.cifs[index].name,
      //     numeroDocIdentidad: this.cifs[index].cif,
      //     tipoDocIdentidad: getTypeByNumber(this.cifs[index].cif),
      //     firmasActivas: false,
      //     notificacionesActivas: true,
      //     granDestinatario: false,
      //   }
      //   await createCIF(userId, cifData)
      //   const message = this.$t('companiesActions.success.create')
      //   this.setSnackbar({ position: 'top', type: 'success', message })
      // } catch (error) {
      //   const message = getErrorText(error.message)
      //   this.setSnackbar({
      //     position: 'top',
      //     type: 'error',
      //     message,
      //   })
      // }
      // this.loadingCreate = false
    },
  },
  watch: {
    cifs: function (newValue) {
      if (newValue) this.unregisteredCifs = [...this.cifs]
    },
  },
}
</script>

<style>
.noteBoxes {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.typeBlue {
  border-color: #0096c7;
  background-color: rgba(0, 150, 199, 0.1);
}

.note-box-icon {
  width: 50px;
  height: 30px;
  padding-right: 20px;
}
</style>
