<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.automatedTasks.p1') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.p2') }}
    </p>
    <ul>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.titleLi1') }}</b
        >: {{ $t('documentation.texts.automatedTasks.textLi1') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.titleLi2') }}</b
        >: {{ $t('documentation.texts.automatedTasks.textLi2') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.titleLi3') }}</b
        >: {{ $t('documentation.texts.automatedTasks.textLi3') }}
      </li>
    </ul>
    <p class="mt-3">
      {{ $t('documentation.texts.automatedTasks.p3') }}
    </p>
    <ul>
      <li>- {{ $t('documentation.texts.automatedTasks.li4') }}</li>
      <li>- {{ $t('documentation.texts.automatedTasks.li5') }}</li>
      <li>- {{ $t('documentation.texts.automatedTasks.li6') }}</li>
    </ul>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p class="mt-3">
      {{ $t('documentation.texts.automatedTasks.p4') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.p5') }}
    </p>

    <ul>
      <li @click="goDoc('automatedTasks', 'actions')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li7') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'conditions')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li8') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'targets')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li9') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'creation')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li10') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'update')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li11') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'deletion')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li12') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'history')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li13') }}</b>
      </li>
      <li @click="goDoc('automatedTasks', 'examples')" class="custom-link">
        - <b>{{ $t('documentation.texts.automatedTasks.li14') }}</b>
      </li>
    </ul>
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.pic1') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
