<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <NotificationDetail
          v-if="notification"
          :notification="notification"
          :loadingNotification="loadingNotification"
          :isSharedAccess="true"
        >
        </NotificationDetail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationDetail from '@/components/notifications/NotificationDetail'
import { getNotificationFromSharedCode } from '@/services/share-codes-service'
import { mapMutations } from 'vuex'
import { setBrand } from '@/utils/brand-utils'

export default {
  components: {
    NotificationDetail,
  },
  async mounted() {
    try {
      this.notification = await this.getNotification()
      if (this.notification.customBrand) setBrand(this.notification.customBrand)
      await new Promise((resolve) => setTimeout(resolve, 5000))
    } catch {
      return this.setNotFound(true)
    }
  },
  data() {
    return {
      notification: {},
      loadingNotification: true,
    }
  },
  methods: {
    ...mapMutations(['setNotFound']),
    //se obtienes las variables de la url
    async getNotification() {
      const notification = await getNotificationFromSharedCode(
        this.$route.params.userId,
        this.$route.params.id,
        this.$route.params.shareCode
      )
      this.loadingNotification = false
      return notification
    },
  },
}
</script>

<style lang="scss" scoped></style>
