<template>
  <v-card>
    <v-form ref="signatureForm" v-model="validForm" lazy-validation>
      <v-container class="pa-10">
        <v-row align="center" justify="center">
          <h1>{{ $t('navigationPage.createSignature') }}</h1>
          <DialogButton
            section="signatures"
            text="signatures"
            subsection="createSignature"
          />
        </v-row>
        <v-row class="pt-3" align="center" justify="center">
          <!-- Autocomplete for Companies -->
          <v-col class="pt-2 pb-2" cols="12">
            <v-autocomplete
              dense
              v-model="companyCIF"
              :items="companies"
              color="primary"
              :filter="customCompanyFilter"
              :no-data-text="$t('emptySignatureCompanies')"
              :label="$t('companies')"
              item-value="numeroDocIdentidad"
              :disabled="confirmLoading"
              prepend-icon="mdi-account-group-outline"
              :menu-props="{ bottom: true, offsetY: true }"
              id="signature-companies"
              :rules="[notEmpty]"
              @input="companySelection"
            >
              <template v-slot:item="{ item }">
                <span id="signature-select-company">
                  {{ item.empresa }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.empresa }}
              </template>
            </v-autocomplete>
          </v-col>

          <!-- Signature type -->
          <v-radio-group
            v-model="signatureType"
            :rules="[notEmpty]"
            :disabled="confirmLoading"
          >
            <v-row align="center" justify="center">
              <v-col
                class="justify-center align-center pb-0 pt-0 mb-0 mt-0"
                cols="12"
                md="4"
              >
                <v-radio :label="$t('single')" value="single" />
              </v-col>
              <v-col
                class="justify-center align-center pb-0 pt-0 mb-0 mt-0"
                cols="12"
                md="4"
              >
                <v-tooltip :disabled="clients.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-radio
                        :disabled="clients.length <= 1"
                        :label="$t('multiple')"
                        value="multiple"
                    /></span>
                  </template>
                  <span>{{ $t('signatureProperties.needMoreSigners') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                class="justify-center align-center pb-0 pt-0 mb-0 mt-0"
                cols="12"
                md="4"
              >
                <v-tooltip :disabled="clients.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-radio
                        :disabled="clients.length <= 1"
                        :label="$t('joint')"
                        value="joint"
                      />
                    </span>
                  </template>
                  <span>{{ $t('signatureProperties.needMoreSigners') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-radio-group>

          <!-- Autocomplete for Clients -->
          <v-col
            class="pt-2 pb-2"
            :md="signatureType === 'joint' ? 10 : 12"
            :cols="signatureType === 'joint' ? 8 : 12"
          >
            <v-autocomplete
              ref="selectClients"
              dense
              :items="clients"
              v-model="clientsToSignIds"
              color="primary"
              :label="$t('signatureProperties.signers')"
              :no-data-text="$t('emptySigners')"
              item-value="id"
              prepend-icon="mdi-account-circle-outline"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="completeName"
              id="signature-clients"
              :rules="
                signatureType === 'single'
                  ? selectClientsSingleRules
                  : signatureType === 'multiple'
                  ? selectClientsMultipleRules
                  : signatureType === 'joint'
                  ? selectClientsJointRules
                  : noRules
              "
              :disabled="confirmLoading || !companyCIF"
              :chips="signatureType !== 'single' ? true : false"
              :multiple="signatureType !== 'single' ? true : false"
            >
              <template
                v-slot:item="{ item }"
                v-if="signatureType === 'single'"
              >
                <span id="signature-select-clients">
                  {{ item.name }} {{ item.surname }}
                </span>
              </template>

              <template
                v-slot:selection="{ item }"
                v-if="signatureType === 'single'"
              >
                {{ item.name }} {{ item.surname }}
              </template>
              <template
                v-slot:selection="data"
                v-else-if="
                  signatureType === 'multiple' || signatureType === 'joint'
                "
              >
                <v-chip
                  class="mb-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeChip(data.item)"
                  :disabled="confirmLoading"
                >
                  {{ data.item.name.substring(0, 1) + '.' }}
                  {{ data.item.surname }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="signatureType === 'joint'"
            class="pt-2 pb-2"
            cols="4"
            md="2"
          >
            <v-text-field
              ref="jointNumber"
              v-model="jointNumber"
              type="number"
              :rules="jointNumberRules"
              :label="$t('minimumJointSignatures')"
              :disabled="
                confirmLoading || !companyCIF || !clientsToSignIds.length
              "
            >
            </v-text-field>
          </v-col>

          <v-col class="pt-2 pb-2" cols="12">
            <v-text-field
              :label="$t('signaturePurpose')"
              prepend-icon="mdi-card-text-outline"
              :value="signaturePurpose"
              @input="signaturePurpose = $event"
              dense
              :disabled="confirmLoading"
              autocomplete="false"
              :rules="[notEmpty]"
              id="signature-purpose"
            />
          </v-col>
          <v-row class="pl-3 pt-0" justify="start">
            <v-col cols="12" lg="5">
              <v-menu
                dense
                ref="menuDateLimit"
                v-model="menuDateLimit"
                :close-on-content-click="false"
                :return-value.sync="dateLimit"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateLimit"
                    :label="$t('selectDateLimit')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    id="signature-date"
                    :disabled="confirmLoading"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="(date) => $emit('date-selected', date)"
                  @click:date="$refs.menuDateLimit.save(dateLimit)"
                  v-model="dateLimit"
                  :locale="$t('javascriptLocale')"
                  :min="new Date().toISOString().substring(0, 10)"
                  :max="maxDateLimit"
                  no-title
                  scrollable
                  :rules="[notEmpty]"
                  id="date-picker"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <transition class="blue" name="fade">
              <v-col cols="12" lg="5" v-if="deferredSignature">
                <v-menu
                  dense
                  ref="menuDateSignature"
                  v-model="menuDateSignature"
                  :close-on-content-click="false"
                  :return-value.sync="dateDeferredSignature"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :disabled="confirmLoading"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      ref="dateDeferredSignature"
                      v-model="dateDeferredSignature"
                      :label="$t('selectDateSignature')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="date-date"
                      :disabled="confirmLoading"
                      :rules="deferredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="
                      $refs.menuDateSignature.save(dateDeferredSignature)
                    "
                    v-model="dateDeferredSignature"
                    :locale="$t('javascriptLocale')"
                    :min="minDeferredDate"
                    :max="maxDeferredDate"
                    no-title
                    scrollable
                    :rules="[notEmpty]"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </transition>
            <v-col cols="12" lg="2">
              <v-checkbox
                dense
                v-model="deferredSignature"
                :label="$t('deferredSignature')"
                :disabled="confirmLoading"
              >
              </v-checkbox>
            </v-col>
          </v-row>

          <v-col class="pt-2 pb-3" cols="12">
            <v-textarea
              counter
              :label="$t('observation')"
              prepend-icon="mdi-text-box-plus-outline"
              :value="observation"
              @input="observation = $event"
              dense
              :rules="observationRules"
              :disabled="confirmLoading"
              id="signature-observation"
              autocomplete="false"
              rows="3"
            />
          </v-col>
          <v-col class="pt-2 pb-3" cols="12">
            <SignatureAttachedFiles
              @update-files-attached="updateFilesAttached"
              :applyRules="true"
              :disabled="confirmLoading"
              :errors="
                tooManySignatures
                  ? $t('tooManySignatures')
                  : !countToSign && countAnnexed
                  ? $t('docsNotValid')
                  : []
              "
            />
          </v-col>
          <v-col cols="12" align="center" justify="center">
            <v-btn
              rounded
              color="accept"
              class="white--text"
              width="150px"
              :loading="confirmLoading"
              :disabled="
                confirmLoading ||
                !validForm ||
                !countToSign ||
                tooManySignatures
              "
              @click="performAction"
              id="confirm-signature"
            >
              {{ $t('confirm') }}
              <template v-slot:loader>
                <v-progress-circular indeterminate size="20" width="2" />
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-spacer></v-spacer>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { createSignature } from '@/services/signatures-service'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import SignatureAttachedFiles from '@/components/signatures/SignatureAttachedFiles'
import { getUserInfo } from '@/services/user-service'

export default {
  props: {
    loadingTableData: Boolean,
    filters: Array,
    companies: Array,
    operators: Array,
    signaturesMax: Number,
    signaturesUsed: Number,
  },
  components: {
    DialogButton,
    SignatureAttachedFiles,
  },
  data() {
    return {
      menuDateLimit: false,
      menuDateSignature: false,
      deferredSignature: false,
      validForm: false,
      needSignature: [],
      infoType: false,
      observation: '',
      signaturePurpose: '',
      dateDeferredSignature: '',
      dateLimit: '',
      cifRefId: '',
      companyCIF: '',
      allowedToSignIds: [],
      clientsToSignIds: [],
      filesAttached: [],
      clients: [],
      confirmLoading: false,
      signatureType: 'single',
      jointNumber: 2,
      companyIsSelected: false,
      noRules: [],
      selectClientsSingleRules: [
        (v) => {
          if (Array.isArray(v)) {
            return (v && v.length > 0) || this.$t('minimumSingleClients')
          }
          return !!v || this.$t('minimumSingleClients')
        },
      ],
      selectClientsMultipleRules: [
        (v) => (v && v.length > 1) || this.$t('minimumMultipleClients'),
      ],
      selectClientsJointRules: [
        (v) => (v && v.length > 1) || this.$t('minimumJointClients'),
      ],
      deferredRules: [
        (v) =>
          (v &&
            this.dateLimit &&
            new Date(v).getTime() > new Date(this.dateLimit).getTime() &&
            // La fecha diferida tiene que ser como máximo dos semanas despues de la fecha limite
            new Date(v).getTime() <=
              new Date(this.dateLimit).getTime() + 14 * 24 * 60 * 60 * 1000) ||
          this.$t('deferredDateNotValid'),
      ],
      jointNumberRules: [
        (v) =>
          (v && v <= this.clientsToSignIds.length && v > 1) ||
          this.$t('invalidJointNumber'),
      ],
      observationRules: [
        (v) => v.length <= 500 || this.$t('observationNotValid'),
      ],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    ...rules,
    updateFilesAttached(newFilesAttached) {
      this.filesAttached = newFilesAttached
    },
    removeChip(item) {
      const index = this.clientsToSignIds.indexOf(item.id)
      if (index >= 0) {
        this.clientsToSignIds.splice(index, 1)
      }
    },
    async companySelection() {
      if (this.companyCIF) {
        const companySelected = this.companies.find(
          (elem) => elem.numeroDocIdentidad === this.companyCIF
        )
        this.cifRefId = companySelected?.id
        this.companyIsSelected = true

        this.allowedToSignIds = Object.keys(companySelected?.allowedToSign)
          .filter((id) => companySelected?.allowedToSign[id])
          .map((id) => id)

        this.clients = await Promise.all(
          this.allowedToSignIds.map(async (id) => await getUserInfo(id))
        )

        this.clients.map(
          (client) => (client.completeName = client.name + ' ' + client.surname)
        )
      } else {
        this.cifRefId = ''
        this.companyIsSelected = false
        this.allowedToSignIds = []
        this.clients = []
      }
    },
    async performAction() {
      // Comprobamos los campos del formulario
      if (!this.$refs.signatureForm?.validate()) return
      if (
        !this.$refs.signatureForm?.validate() ||
        !this.countToSign ||
        this.tooManySignatures
      )
        return

      this.setLoading(true)

      try {
        this.confirmLoading = true
        // Seleccionamos ID de usuario
        const userID = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        // Convertimos a array en caso de ser firma unica
        const clientsToSign =
          this.signatureType === 'single'
            ? [this.clientsToSignIds]
            : this.clientsToSignIds

        const dataCreate = {
          dateLimit: new Date(this.dateLimit),
          purpose: this.signaturePurpose,
          observationRequester: this.observation,
          observationClient: '',
          signatureType: this.signatureType,
          jointNumber: this.jointNumber,
          clientsToSign: clientsToSign.map((id) => {
            return { id: id, signed: false }
          }),
          status: 'pending',
          cifRefId: this.cifRefId,
          walletId: this.cifRefId,
          documents: this.filesAttached,
        }
        if (this.deferredSignature)
          dataCreate.dateDeferred = new Date(this.dateDeferredSignature)
        // Llamamos a la funcion de crear solicitud
        await createSignature(userID, dataCreate)

        // Redirigimos al listado de firmas
        this.$router.replace('/signatures')
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('signatureActions.success')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
        this.setLoading(false)
      }
    },
    customCompanyFilter(item, queryText) {
      const name = item.empresa.toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.includes(searchText)
    },
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    countToSign() {
      return this.filesAttached.filter((f) => f.needSignature === true).length
    },
    countAnnexed() {
      return this.filesAttached.filter((f) => f.needSignature === false).length
    },
    tooManySignatures() {
      return this.signaturesMax
        ? this.countToSign *
            (this.signatureType === 'joint' ? this.jointNumber : 1) >
            this.signaturesMax - this.signaturesUsed
        : false
    },
    minDeferredDate() {
      return !this.dateLimit
        ? new Date().toISOString().substring(0, 10)
        : new Date(
            new Date(this.dateLimit).setDate(
              new Date(this.dateLimit).getDate() + 1
            )
          )
            .toISOString()
            .substring(0, 10)
    },
    maxDateLimit() {
      if (this.deferredSignature) {
        const currentDate = new Date()
        const maxDate = new Date(
          currentDate.setDate(currentDate.getDate() + 27)
        )
        return maxDate.toISOString().substring(0, 10)
      } else return undefined
    },
    maxDeferredDate() {
      const date = new Date(this.dateLimit)
      const currentDate = new Date()

      const maxPosibleDate = new Date(
        currentDate.setDate(currentDate.getDate() + 28)
      )
      const maxSinceLimit = new Date(date.setDate(date.getDate() + 14))
      const maxDate =
        maxSinceLimit.getTime() < maxPosibleDate.getTime()
          ? maxSinceLimit
          : maxPosibleDate
      return maxDate.toISOString().substring(0, 10)
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.signatureForm.resetValidation()
    },
    signatureType() {
      this.clientsToSignIds = []
      this.jointNumber = 2
      this.$refs.selectClients.resetValidation()
    },
    companyCIF() {
      this.clientsToSignIds = []
      this.jointNumber = 2
      this.$refs.selectClients.resetValidation()
    },
    clientsToSignIds() {
      if (this.signatureType === 'joint' && this.clientsToSignIds.length > 1) {
        this.$refs.jointNumber.validate()
      }
    },
    deferredSignature(newValue) {
      if (newValue)
        if (new Date(this.dateLimit) > new Date(this.maxDateLimit))
          this.dateLimit = ''
    },
    dateLimit() {
      if (this.dateDeferredSignature)
        this.$refs.dateDeferredSignature?.validate()
    },
    needSignature(value) {
      this.infoType = !value
    },
    infoType(value) {
      this.needSignature = !value
    },
    filesAttached(value) {
      this.filesAttached = value
    },
  },
}
</script>

<style lang="scss">
.small-select .v-select__selections {
  font-size: 12px;
  line-height: 1;
}

.small-select .v-list-item__title {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-file-input__text {
  display: none;
}
ul {
  list-style-type: none;
}
</style>
