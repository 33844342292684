<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{
        $t(
          'documentation.texts.notifications.responsability.responsabilityTitle'
        )
      }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.responsability.paragraph1') }}
    </p>
    <v-img style="width: 400px; max-width: 700px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.responsability.paragraph2') }}
    </p>
    <v-img style="width: 400px; max-width: 700px" contain :src="pic2Name" />
    <br />
    <p>
      {{ $t('documentation.texts.notifications.responsability.paragraph3') }}
    </p>
    <v-img style="width: 400px; max-width: 1500px" contain :src="pic3Name" />
    <br />
    <p>
      {{ $t('documentation.texts.notifications.responsability.paragraph4') }}
    </p>
    <v-img style="width: 400px; max-width: 1500px" contain :src="pic4Name" />
    <br />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.responsability.picResponsabilityEmpty'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.responsability.picResponsabilityAssigned'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.responsability.picResponsabilityList'
        ) +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.responsability.picResponsabilityHistory'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
