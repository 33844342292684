<template>
  <v-select
    v-model="newStatus"
    :label="$t('newStatus')"
    :items="availableStatus"
    variant="underlined"
    :rules="[notEmpty]"
  >
    <template v-slot:selection="data">
      {{ $t(data.item) }}
    </template>
    <template v-slot:item="data">
      {{ $t(data.item) }}
    </template>
  </v-select>
</template>

<script>
import rules from '@/utils/rules'

export default {
  data() {
    return {
      newStatus: undefined,
    }
  },
  methods: {
    ...rules,
  },
  computed: {
    availableStatus() {
      return [
        'notificationStatus.accepted',
        'notificationStatus.processing',
        'notificationStatus.completed',
      ]
    },
  },
  watch: {
    newStatus: function (newValue) {
      if (newValue) this.$emit('setStatus', newValue)
    },
  },
}
</script>
