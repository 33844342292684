<template>
  <v-row :class="!isMobile ? 'mr-0' : ''">
    <v-col cols="12" class="d-flex align-items-start ma-0 pa-0 mt-5">
      <p class="body-1">
        <b>{{ $t('administrationRoles') }}:</b>
      </p>
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.manageUsers"
        :label="$t('manageUsers')"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.manageCompanies"
        :label="$t('manageCompanies')"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0" v-if="!userIsInGroup">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.manageGroups"
        :label="$t('manageGroups')"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0" v-if="!userIsInGroup">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.manageAutomatedTasks"
        :label="$t('manageAutomatedTasks')"
      />
    </v-col>
    <!-- <v-col cols="12" md="4" sm="4" class="ma-0 pa-0">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.manageFilters"
        :label="$t('manageFilters')"
      />
    </v-col> -->
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.uploadCertificates"
        :label="$t('uploadCertificates')"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled || cannotEdit"
        v-model="adminRoles.allowedAnalytics"
        :label="$t('seeAnalytics')"
      />
    </v-col>
    <v-col cols="12" md="4" sm="4" class="ma-0 pa-0" v-if="!userIsInGroup">
      <v-checkbox
        class="ma-0 pa-0"
        :disabled="loading || disabled"
        v-model="adminRoles.manageAdmins"
        :label="$t('manageAdmins')"
        @click="changeAdminsCheckboxes()"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    selectedGroups: Array,
    userAdminRoles: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    disabled: Boolean,
  },
  mounted() {
    if (Object.keys(this.userAdminRoles).length)
      this.adminRoles = { ...this.userAdminRoles }
    this.cannotEdit =
      this.userAdminRoles.manageAdmins && !this.selectedGroups.length
  },
  data() {
    return {
      adminRoles: {
        manageAdmins: false,
        manageUsers: false,
        manageCompanies: false,
        manageGroups: false,
        manageAutomatedTasks: false,
        manageFilters: false,
        uploadCertificates: false,
        allowedAnalytics: false,
      },
      cannotEdit: false,
    }
  },
  methods: {
    changeAdminsCheckboxes() {
      if (this.adminRoles.manageAdmins === true) {
        // Ponemos todos los permisos de checkbox a true
        // en caso de 'Gestionar administradores'
        this.cannotEdit = true
        this.adminRoles = Object.fromEntries(
          Object.keys(this.adminRoles).map((key) => [key, true])
        )
      } else {
        this.cannotEdit = false
        this.adminRoles = Object.fromEntries(
          Object.keys(this.adminRoles).map((key) => [key, false])
        )
      }
    },
  },

  computed: {
    ...mapGetters(['isMobile', 'isSuperAdminUser', 'isSuperOperatorUser']),
    userIsInGroup() {
      return (
        this.$store.state.user.groupsRef?.length > 0 ||
        this.selectedGroups?.length > 0
      )
    },
  },
  watch: {
    adminRoles: {
      handler: function (newValue) {
        if (newValue) this.$emit('setAdminRoles', newValue)
      },
      deep: true,
    },
    selectedGroups: {
      handler: function (newValue) {
        if (newValue?.length > 0) {
          // En caso de tener grupo asignado, le quitamos la opcion de 
          // gestionar administradores, grupos y tareas automaticas
          this.adminRoles.manageAdmins = false
          this.adminRoles.manageGroups = false
          this.adminRoles.manageAutomatedTasks = false
          this.cannotEdit = false
        }
      },
      deep: true,
    },
  },
}
</script>
