<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{
        $t(
          `documentation.texts.${
            isEmpresaUser ? 'companiesGD' : 'companies'
          }.title`
        )
      }}
    </h1>
    <p>
      {{
        $t(
          `documentation.texts.${
            isEmpresaUser ? 'companiesGD' : 'companies'
          }.paragraph1`
        )
      }}
    </p>
    <p>
      {{
        $t(
          `documentation.texts.${
            isEmpresaUser ? 'companiesGD' : 'companies'
          }.paragraph2`
        )
      }}
    </p>
    <p v-if="!isEmpresaUser">
      {{ $t(`documentation.texts.companies.paragraph4`) }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <p>
      {{
        $t(
          `documentation.texts.${
            isEmpresaUser ? 'companiesGD' : 'companies'
          }.paragraph3`
        )
      }}
    </p>

    <ul>
      <b>
        <li @click="goDoc('companies', 'create')" class="custom-link">
          {{
            $t(
              `documentation.texts.${
                isEmpresaUser ? 'companiesGD' : 'companies'
              }.link1`
            )
          }}
        </li>
      </b>
      <b>
        <li @click="goDoc('companies', 'update')" class="custom-link">
          {{
            $t(
              `documentation.texts.${
                isEmpresaUser ? 'companiesGD' : 'companies'
              }.link2`
            )
          }}
        </li>
      </b>
      <b>
        <li @click="goDoc('companies', 'delete')" class="custom-link">
          {{
            $t(
              `documentation.texts.${
                isEmpresaUser ? 'companiesGD' : 'companies'
              }.link3`
            )
          }}
        </li>
      </b>
      <b>
        <li @click="goDoc('companies', 'operators')" class="custom-link">
          {{
            $t(
              `documentation.texts.${
                isEmpresaUser ? 'companiesGD' : 'companies'
              }.link4`
            )
          }}
        </li>
      </b>
      <b>
        <li @click="goDoc('companies', 'export')" class="custom-link">
          {{ $t('documentation.texts.companies.link5') }}
        </li>
      </b>
    </ul>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isEmpresaUser', 'isEmpresaUser']),
    pic1Name() {
      return require('@/assets/documentation/companies/' +
        (!this.isEmpresaUser
          ? this.$t('documentation.texts.companies.pic2')
          : this.$t('documentation.texts.companiesGD.pic1')) +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
