<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.companies.operators.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.companies.operators.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.companies.operators.paragraph2') }}
    </p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.operators.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.operators.pic2') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
