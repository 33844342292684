import {
  Firestore,
  FiltersCollection,
  FilterDoc,
  UserFiltersCollection,
  UserFiltersDoc,
} from '@/firebase-exports'
import store from '@/store/index'
import i18n from '@/i18n'

/**
 * Crea un filtro de usuario.
 * @param {Object} filter El filtro a crear.
 * @returns La respuesta de la creación del filtro.
 */
export async function createUserFilter(userId, filter) {
  var response = await Firestore.addDoc(UserFiltersCollection(userId), filter)
  return response
}

/**
 * Obtiene un filtro de usuario por su ID.
 * @param {String} userId El ID del usuario
 * @param {string} filterId El ID del filtro.
 * @returns El filtro de usuario correspondiente al ID.
 */
export async function getUserFilter(userId, filterId) {
  let filter = undefined
  const docSnap = await Firestore.getDoc(UserFiltersDoc(userId, filterId))
  if (docSnap._document === null) return false

  const document = docSnap.data()
  if (document) filter = { filterId, ...document }
  return filter
}

/**
 * Crea un filtro.
 * @param {Object} filter El filtro a crear.
 * @returns La respuesta de la creación del filtro.
 */
export async function createFilter(filter) {
  var response = await Firestore.addDoc(FiltersCollection(), filter)
  return response
}

/**
 * Obtiene un filtro de usuario por su ID.
 * @param {string} id El ID del filtro.
 * @returns El filtro de usuario correspondiente al ID.
 */
export async function getFilter(id) {
  var filter = undefined
  const docSnap = await Firestore.getDoc(FilterDoc(id))
  const document = docSnap.data()
  if (document) filter = { id, ...document }
  return filter
}

/**
 * Maps the filters into an object
 * @param {Array} filters Array of filters.
 * @returns Object with the filters. Keys are the filter's id.
 */
export function mapFilters(filters = []) {
  const filtersMap = {}
  filters.forEach((filter) => (filtersMap[filter.id] = filter))
  return filtersMap
}

/**
 * Obtiene la lista de filtros (predefinidos y los de usuario).
 * @param {String} userId El ID del usuario del que queremos obtener los filtros personalizados
 * @param {boolean} [mixedSort=false] Indica si queremos ordenar la mezcla final por orden alfabético
 * @returns La lista de filtros obtenida.
 */
export async function listFilters(userId, mixedSort = false) {
  const filters = await listDefaultFilters()
  const userFilters = await listUserFilters(userId)

  return mixedSort
    ? [...filters, ...userFilters].sort(
        // Dejar siempre en primera posición el "Ver Todo" para evitar problemas en el código
        // Luego ordenamos los demás por orden alfabético
        (a, b) =>
          (b.name === 'noFilter') - (a.name === 'noFilter') ||
          (userFilters.find((filter) => filter.id === a.id)
            ? a.name
            : i18n.t(`filters.${a.name}`)
          ).localeCompare(
            userFilters.find((filter) => filter.id === b.id)
              ? b.name
              : i18n.t(`filters.${b.name}`)
          )
      )
    : [...filters, ...userFilters]
}

/**
 * Obtiene la lista de filtros predeterminados.
 * @returns La lista de filtros predeterminados.
 */
export async function listDefaultFilters() {
  try {
    return (await Firestore.getDocs(Firestore.query(FiltersCollection()))).docs
      .map((doc) => ({ id: doc.id, path: doc.ref.path, ...doc.data() }))
      .sort(
        // Dejar siempre en primera posición el "Ver Todo" para evitar problemas en el código
        // Luego ordenamos para dejar "Resto Ministerios" el último, y los demás por orden alfabético
        (a, b) =>
          (b.name === 'noFilter') - (a.name === 'noFilter') ||
          (a.name === 'otherMinistries') - (b.name === 'otherMinistries') ||
          i18n.t(`filters.${a.name}`).localeCompare(i18n.t(`filters.${b.name}`))
      )
  } catch (error) {
    return []
  }
}

/**
 * Obtiene la lista de filtros de usuario.
 * @param {string} userIdParam El ID del usuario.
 * @returns La lista de filtros de usuario obtenida.
 */
export async function listUserFilters(userIdParam) {
  const userId =
    userIdParam ??
    (store.state.user.parentRef
      ? store.state.user.parentRef.id
      : store.state.user.id)

  try {
    const docSnap = await Firestore.getDocs(
      Firestore.query(UserFiltersCollection(userId))
    )
    return docSnap.docs
      .map((doc) => ({
        id: doc.id,
        path: doc.ref.path,
        ...doc.data(),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  } catch (error) {
    return []
  }
}

/**
 * Obtiene los filtros predefinidos.
 * @returns Los filtros predefinidos.
 */
export function getPredefinedFilters(/*userId = undefined*/) {
  var filters = []

  filters.push({
    title: i18n.t('filters.dgt'),
    codigo_organismo_emisor: 'E00130201',
    codigo_organismo_emisor_raiz: 'E00130201',
    nombre_organismo_emisor: 'Jefatura Central de Tráfico',
    nombre_organismo_emisor_raiz: 'Jefatura Central de Tráfico',
  })

  filters.push({
    title: i18n.t('filters.aeat'),
    codigo_organismo_emisor: 'EA0028512',
    codigo_organismo_emisor_raiz: 'E05188501',
    nombre_organismo_emisor: 'Agencia Estatal de Administración Tributaria',
    nombre_organismo_emisor_raiz: 'Ministerio de Hacienda y Función Pública',
  })

  filters.push({
    title: i18n.t('filters.justice'),
    codigo_organismo_emisor: 'J99999999',
    codigo_organismo_emisor_raiz: 'J99999999',
    nombre_organismo_emisor: 'Administración de Justicia',
    nombre_organismo_emisor_raiz: 'Administración de Justicia',
  })

  filters.push({
    title: i18n.t('filters.ss'),
    codigo_organismo_emisor: 'EA0042298',
    codigo_organismo_emisor_raiz: 'E05072501',
    nombre_organismo_emisor: 'Tesorería General de la Seguridad Social',
    nombre_organismo_emisor_raiz:
      'Ministerio de Inclusión, Seguridad Social y Migraciones',
  })

  // Añadimos los filtros que hay del usuario padre
  // TODO: think if we want to show personalized filters (branch 1567)
  // if (userId) filters.push(...listUserFilters(userId))

  return filters
}

/**
 * Gives the filter of an admin.
 * @returns Admin filter.
 */
export function getFilterAdminFilter() {
  return {
    readNotification: true,
    downloadNotification: true,
    processNotification: true,
    adminPermissions: true,
    sourceEntityAllowed: [],
    autonomicAllowed: [],
    administrationLevelAllowed: [],
  }
}

export async function isUserFilter(filterId) {
  const docRef = Firestore.doc(UserFiltersCollection(filterId))
  const docSnap = await docRef.get()
  return docSnap.exists
}
