<template>
  <v-col style="max-width: 1200px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.configuration.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.configuration.uploadCertificate.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.configuration.uploadCertificate.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 1200px" contain :src="pic1Name" />
    <br />
    <p>
      {{ $t('documentation.texts.configuration.uploadCertificate.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 400px" contain :src="pic2Name" />

    <p>
      {{ $t('documentation.texts.configuration.uploadCertificate.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 400px" contain :src="pic3Name" />
    <br />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdminUser']),
    pic1Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t(
          'documentation.texts.configuration.uploadCertificate.picWarning'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t(
          'documentation.texts.configuration.uploadCertificate.picUploadCertificate'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t(
          'documentation.texts.configuration.uploadCertificate.picSuccessCertificate'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
