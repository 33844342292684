<template>
  <div>
    <AppSkeleton :condition="cifs === undefined" max-width="300" type="card">
      <template v-slot:skeleton-content>
        <v-card class="mx-auto" outlined color="transparent">
          <v-container>
            <v-row justify="space-between" align="center">
              <div class="noteBoxes typeBlue" style="display: flex">
                <img src="@/assets/info.png" class="note-box-icon" />
                <div>
                  <h3>{{ $t('missingNotifications') }}</h3>
                  <p></p>
                  <p>
                    {{ $t('yourCurrentGroupNotifications') }}
                  </p>
                  <ul>
                    <li :key="i" v-for="(cif, i) in cifs">
                      • {{ cif.empresa }} ({{ cif.numeroDocIdentidad }})
                      <v-tooltip max-width="400" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="handleClick(cif)"
                          >
                            <v-icon>mdi-account-multiple-plus</v-icon>
                          </v-btn>
                        </template>
                        <div width="300">
                          {{ $t('addCifToGroup', { groupName: group.name }) }}
                        </div>
                      </v-tooltip>
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </AppSkeleton>
  </div>
</template>

<script>
import { addCifToGroup, removeUnregistered } from '@/services/groups-service'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'

export default {
  props: {
    cifs: Array,
    group: Object,
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async handleClick(cif) {
      try {
        await addCifToGroup(cif.id, this.group.id)
        await removeUnregistered(cif.id, this.group.id)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('cifAddedToGroupSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.$emit('reload')
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style>
.noteBoxes {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.typeBlue {
  border-color: #0096c7;
  background-color: rgba(0, 150, 199, 0.1);
}

.note-box-icon {
  width: 50px;
  height: 30px;
  padding-right: 20px;
}
</style>
