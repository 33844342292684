<template>
  <v-data-table
    v-model="selectedOperatorsTracesRows"
    :loading="loading"
    :headers="computedHeaders"
    :items="operatorsTraces"
    @click:row="handleRowClick"
    :items-per-page="5"
    :footer-props="{ 'items-per-page-options': [5, 25, 50 /*-1*/] }"
    class="elevation-1"
    :show-select="showSelect"
  >
    <template v-slot:[`item.actionType`]="{ item }">
      {{ $t('csv.trace.' + item.actionType) }}
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      {{ $d(item.createdAt, 'i18nDateAndHourSeconds') }}
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    mobileHeaders: {
      type: Array,
      default: () => [],
    },
    operatorsTraces: {
      type: Array,
      default: () => [],
    },
    selectedOperatorsTraces: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOperatorsTracesRows: [],
    }
  },
  mounted() {
    //this.selectedOperatorsTracesRows = this.selectedOperatorsTraces
  },
  methods: {
    /**
     * Emits the set event when the row is clicked
     * @param {Object} item Notification clicked.
     */
    handleRowClick(item) {
      this.$emit('clickRow', item)
      const index = this.selectedOperatorsTracesRows.findIndex(
        (n) => n.id === item.id
      )
      if (index > -1) this.selectedOperatorsTracesRows.splice(index, 1)
      else this.selectedOperatorsTracesRows.push(item)
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    computedHeaders() {
      const headers = [
        {
          text: this.$t('id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: this.$t('operator'), value: 'operator' },
        { text: this.$t('action'), value: 'actionType' },
        { text: this.$t('date'), value: 'createdAt' },
        { text: this.$t('ip'), value: 'ip' },
        { text: this.$t('city'), value: 'city' },
        { text: this.$t('country'), value: 'country' },
      ]

      return this.isMobile && this.mobileHeaders.length > 0
        ? headers.filter((h) => this.mobileHeaders.includes(h.value))
        : headers.filter((h) => this.headers.includes(h.value))
    },
  },
  watch: {
    selectedOperatorsTracesRows: function (newValue, previousValue) {
      if (newValue && previousValue) this.$emit('setSelectedRows', newValue)
    },
  },
}
</script>
