export function getExpirationDate(clientData) {
  return clientData.plan.expirationLicense.toDate().toISOString().slice(0, 10)
}

export function calculateRenewalDate(clientData, days) {
  const expirationDate = clientData.plan.expirationLicense.toDate()
  // Restar los días, T-30
  expirationDate.setDate(expirationDate.getDate() - days)
  return expirationDate.toISOString().slice(0, 10)
}

export function formatDate(dateAAAAMMDD) {
  // Divide la cadena en partes usando el guion como separador
  const [year, month, day] = dateAAAAMMDD.split('-')

  // Reorganiza las partes en el formato a mostrar al usuario como parte del mensaje, p.e. "14/09/2024"
  // Nota: he sustituido el guion por la barra porque si no al mostrarlo en el banner se parten en dos palabras
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

export function isLicenseExpirationReached(clientData) {
  const licenseExpirationISO = clientData.plan.expirationLicense
    .toDate()
    .toISOString()
    .slice(0, 10)
  const today = new Date().toISOString().slice(0, 10)

  // Convierte las cadenas de texto a objetos Date solo con la parte de la fecha (sin horas)
  const expiration = new Date(licenseExpirationISO)
  const current = new Date(today)
  return expiration <= current
}

export function calculateEndGracePeriod(clientData, days) {
  const expirationDate = clientData.plan.expirationLicense.toDate()
  // Sumar un periodo de gracia de 30 dias
  expirationDate.setDate(expirationDate.getDate() + days)
  return expirationDate.toISOString().slice(0, 10)
}

export function calculateDaysRemainingToDisable(clientData, days) {
  const today = new Date().toISOString().slice(0, 10)

  // la fecha limite es la finalizacion del periodo de gracia de 30 dias tras el vencimiento de la licencia
  const limit = new Date(calculateEndGracePeriod(clientData, days))
  const current = new Date(today)

  // Calcula la diferencia en milisegundos entre ambas fechas
  const differenceInTime = limit.getTime() - current.getTime()

  // Convierte la diferencia a días (ignorando horas, minutos y segundos)
  const daysRemaining = differenceInTime / (1000 * 3600 * 24)
  return daysRemaining
}
