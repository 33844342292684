<template>
  <v-dialog :value="true" persistent max-width="1000">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          <div>
            {{ isEdit ? $t('editCertificate') : $t('confirmAuthorization') }}
          </div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" align="center">
          <v-alert
            v-if="wallet.username"
            outlined
            :color="certificateColor"
            class="mx-4 d-flex justify-center"
            width="500px"
            align="center"
          >
            <b>{{ certificateTitle }} </b>
            <span v-if="certificateExpiration">
              <i style="display: block">
                {{ $t('certificateExpiration.expirationDate') }}
                {{ $d(certificateExpiration.toDate(), 'i18nDate') }}
              </i>
            </span>
          </v-alert>
        </v-col>
        <v-form
          ref="clientForm"
          v-model="validClientForm"
          @submit.prevent="() => {}"
          lazy-validation
        >
          <v-row class="mt-2">
            <v-col cols="12" sm="4" align="center">
              <h3>
                <v-icon> mdi-certificate </v-icon>
                {{ $t('certificateOrigin') }}
              </h3>
              <v-radio-group
                style="max-width: 190px"
                v-model="certificateType"
                :rules="[notEmpty]"
                :disabled="loading"
              >
                <v-radio :label="$t('local')" value="local" />
                <v-radio
                  :label="$t('centralized')"
                  value="centralized"
                  v-show="false"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="4" align="center">
              <h3>
                <v-icon> mdi-message-fast </v-icon>
                {{ $t('communicationType') }}
              </h3>
              <v-radio-group
                style="max-width: 190px"
                v-model="communicationType"
                :rules="[notEmpty]"
                :disabled="loading"
              >
                <v-radio :label="$t('email')" value="mail" />
                <v-radio :label="$t('sms')" value="sms" />
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="4" align="center">
              <h3>
                <v-icon> mdi-book-settings </v-icon>
                {{ $t('authorizationType') }}
              </h3>
              <v-radio-group
                style="max-width: 190px"
                v-model="authorizationType"
                :rules="[notEmpty]"
                :disabled="loading"
              >
                <v-radio :label="$t('pin')" value="pin" />
                <v-radio :label="$t('otp')" value="otp" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              :lg="
                communicationType === 'sms' && authorizationType === 'pin'
                  ? 6
                  : 12
              "
            >
              <v-text-field
                :label="$t('phone')"
                prepend-icon="mdi-phone"
                v-model="telefono"
                :value="telefono"
                dense
                autocomplete="false"
                id="company-phone-user"
                :disabled="loading"
                :rules="[maxCharacters(maxChars), notEmpty, phoneNumber]"
                v-if="communicationType === 'sms'"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              :lg="
                communicationType === 'sms' && authorizationType === 'pin'
                  ? 6
                  : 12
              "
            >
              <v-text-field
                :label="$t('pin')"
                prepend-icon="mdi-numeric"
                v-model="pin"
                :value="pin"
                dense
                autocomplete="false"
                id="company-pin-user"
                :disabled="loading"
                :hint="
                  walletHasPin ? $t('walletHasPin') : $t('numCharactersPin')
                "
                persistent-hint
                :rules="
                  walletHasPin
                    ? [numCharacters(5), onlyNumbers]
                    : [numCharacters(5), notEmpty, onlyNumbers]
                "
                v-if="authorizationType === 'pin'"
              />
            </v-col>
          </v-row>
          <v-col
            cols="12"
            v-if="!noTypeSelected && certificateType === 'centralized'"
          >
            <v-text-field
              :label="$t('certificateUser')"
              prepend-icon="mdi-file-document-outline"
              :value="certificateUser"
              @input="certificateUser = $event"
              :rules="[notEmpty]"
              :disabled="loading"
              dense
              autocomplete="false"
              id="company-certificate-link"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="!noTypeSelected && certificateType === 'local'"
          >
            <v-file-input
              accept=".p12,.pfx"
              chips
              :label="$t('certificate')"
              prepend-icon="mdi-file-document-outline"
              @click:append="showPassword = !showPassword"
              v-model="certificate"
              :placeholder="$t('attachCertificate')"
              :disabled="loading"
              :hint="wallet.username ? $t('certificateAttached') : ''"
              persistent-hint
              :clearable="false"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  close
                  @click:close="removeCertificate()"
                  :disabled="loading"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" v-if="!noTypeSelected">
            <v-text-field
              :label="$t('certificatePassword')"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              autocomplete="new-password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-form-textbox-password"
              :rules="certificateAttached || isAccept ? [notEmpty] : []"
              :value="certificatePassword"
              @input="certificatePassword = $event"
              :disabled="loading"
              dense
              id="company-certificate-password"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || (isEdit && !walletDataChanged)"
          @click="performAction"
          id="confirm-cif"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <AuthorizeDialog
      v-if="authorizeDialog"
      @authorize="performAction"
      @cancel="() => (authorizeDialog = false)"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { toBase64 } from '@/utils/file'
import { getWallet, updateWallet } from '@/services/client-service'
import getErrorText from '@/utils/get-error-text'
import { uploadCertificate } from '@/services/cifs-service'
import rules from '@/utils/rules'
import AuthorizeDialog from '@/components/cifs/clients/AuthorizeDialog'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'

export default {
  props: {
    cif: Object,
    action: String,
  },
  components: {
    AuthorizeDialog,
  },
  async created() {
    const wallet = await getWallet(this.$store.state.user.id, this.cif.id)
    this.authorizationType = wallet.authorizationType
    this.communicationType = wallet.communicationType
    this.certificateType = wallet.certificateType
    this.certificateUser = wallet.certificateUser
    this.telefono = wallet.telefono

    this.wallet = wallet
    this.certificateExpiration = this.wallet.certificateExpiration
  },
  data() {
    return {
      certificateExpiration: undefined,
      authorizationType: undefined,
      communicationType: undefined,
      certificateType: undefined,
      certificateUser: undefined,
      certificate: undefined,
      editCertificate: false,
      certificateB64: [],
      showPassword: false,
      certificatePassword: undefined,
      telefono: undefined,
      loading: false,
      wallet: {},
      validClientForm: false,
      maxChars: 100,
      pin: undefined,
      authorizeDialog: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Removes the selected certificate
     */
    removeCertificate() {
      this.certificate = undefined
      this.certificateB64 = []
    },
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeAcceptAuthorizationDialog')
    },
    /**
     * Function to upload the certificate
     */
    async performAction() {
      if (!this.$refs.clientForm?.validate()) return

      try {
        // If there is not a certficate and the user accepts there must be an error.
        // The user must provide a certificate
        this.loading = true
        if (
          !this.wallet.certificateType &&
          !this.certificateUser &&
          this.certificateB64.length === 0
        )
          return this.setSnackbar({
            position: 'top',
            type: 'error',
            message: this.$t('certificateMandatory'),
          })

        if (this.action === 'accept') {
          // If the authorize dialog is not shown then we show it
          if (!this.authorizeDialog) {
            this.authorizeDialog = true
            return
          }
          // If the authorize dialog has been shown and the user has accepted hide it
          else this.authorizeDialog = false

          if (
            this.certificateB64.length > 0 ||
            this.certificateType === 'centralized'
          ) {
            // If there is a new certificate uploaded or the user changed
            await uploadCertificate(
              this.$store.state.user.parentRef.id,
              this.$store.state.user.id,
              this.cif.id,
              this.certificateType,
              this.communicationType,
              this.authorizationType,
              this.certificateB64[0] ? this.certificateB64[0].base64 : '',
              this.certificatePassword,
              this.certificateUser,
              this.$store.state.user.name,
              this.$store.state.user.surname,
              this.$store.state.user.email,
              this.telefono ? this.telefono : '',
              this.pin ? this.pin : ''
            )
          }
        } else if (this.action === 'edit') {
          // Only if the data of the wallet has changed, we update it.

          await updateWallet({
            userId: this.$store.state.user.parentRef.id,
            clientId: this.$store.state.user.id,
            cifId: this.cif.id,
            ...(this.certificateB64[0] || this.certificateUser
              ? { certificateType: this.certificateType }
              : {}),
            ...(this.certificateB64[0]
              ? { certificate: this.certificateB64[0].base64 }
              : {}),
            ...(this.certificateUser
              ? { certificateUser: this.certificateUser }
              : {}),
            ...(this.certificatePassword
              ? { certificatePassword: this.certificatePassword }
              : {}),
            ...(this.pin ? { pin: this.pin } : {}),
            communicationType: this.communicationType,
            authorizationType: this.authorizationType,
            telefono: this.telefono,
          })
        }

        const message = this.$t('clientActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    /**
     * Checks if no certificate type has been selected
     */
    noTypeSelected() {
      return !this.certificateType
    },
    /**
     * Checks if the action is edit
     */
    isEdit() {
      return this.action === 'edit'
    },
    /**
     * Checks if the action is accept
     */
    isAccept() {
      return this.action === 'accept'
    },
    /**
     * Checks if the loaded wallet has already a pin
     */
    walletHasPin() {
      return !!this.wallet.pin
    },
    /**
     * Checks if a certificate has been attached.
     */
    certificateAttached() {
      return !!(this.certificate || this.certificateUser)
    },
    walletDataChanged() {
      return (
        this.wallet.communicationType !== this.communicationType ||
        this.wallet.authorizationType !== this.authorizationType ||
        this.wallet.telefono !== this.telefono ||
        this.certificateB64[0] ||
        this.certificateUser ||
        this.pin
      )
    },
    certificateColor() {
      return certificateColor(this.wallet.certificateExpiration?.toDate())
    },
    certificateTitle() {
      return certificateTitle(this.wallet.certificateExpiration?.toDate())
    },
  },
  watch: {
    /**
     * Watcher to check when a certificate is added so that
     * it can be saved in the base64 format.
     */
    certificate: function (newValue) {
      if (newValue) {
        this.certificateB64 = []
        toBase64(newValue, 'certificateB64', this)
      }
    },
  },
}
</script>
