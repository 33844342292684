<template>
  <v-card data-v-step="200" min-height="180" elevation="5" class="rounded-t-xl">
    <v-container class="pa-6" fill-height fluid>
      <v-row class="mb-0 pb-0" justify="center" align="baseline">
        <v-col cols="12" lg="4">
          <v-row align="center" justify="center">
            <h1 class="ml-5 mr-4">{{ $t('signatures') }}</h1>
            <DialogButton section="dashboard" subsection="signatures" />
          </v-row>
        </v-col>
        <v-col cols="12" lg="8">
          <AppPlanProgress
            icon="mdi-draw"
            class="ml-4 mr-4"
            :current="signaturesUsed"
            :max="signaturesMax"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0" align="center" justify="center">
        <v-col
          v-if="!isMobile"
          class="title d-flex justify-center align-center pt-3"
          cols="2"
        >
          <v-hover v-slot="{ hover }">
            <v-icon
              :class="hover ? 'accept--text' : ''"
              @click="$router.push({ name: 'SignaturesView' })"
              size="80px"
              id="signatures-inbox-dashboard"
            >
              mdi-draw-pen
            </v-icon>
          </v-hover>
        </v-col>
        <v-col class="text-right pa-2 mt-2" cols="12" md="10" lg="10">
          <v-row justify="center" align="center">
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-1"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="body-1 d-flex justify-start align-center">
                {{ $t('signatureStatus.signed') }}: <v-spacer />
                <span>
                  <v-chip small class="bold body-1 accept white--text ml-2">
                    {{ signedCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-1"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="d-flex body-1 justify-start align-center">
                {{ $t('signatureStatus.abandoned') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text black ml-2">
                    {{ abandonedCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-1"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="body-1 d-flex justify-start align-center">
                {{ $t('signatureStatus.urgent') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 warning ml-2">
                    {{ urgentCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-2"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="d-flex body-1 justify-start align-center">
                {{ $t('signatureStatus.pending') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text warningLow ml-2">
                    {{ pendingCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-2"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="d-flex body-1 justify-start align-center">
                {{ $t('signatureStatus.rejected') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text error ml-2">
                    {{ rejectedCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col
              class="text-right pb-1 pl-3 pr-3 pt-2"
              cols="6"
              md="6"
              lg="4"
            >
              <div class="body-1 d-flex justify-start align-center">
                {{ $t('signatureStatus.deferred') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 processing white--text ml-2">
                    {{ deferredCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DialogButton from '@/components/documentation/buttons/DialogButton'
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import { mapGetters } from 'vuex'

export default {
  components: {
    DialogButton,
    AppPlanProgress,
  },
  props: {
    signedCount: {
      type: Number,
      default: 0,
    },
    deferredCount: {
      type: Number,
      default: 0,
    },
    urgentCount: {
      type: Number,
      default: 0,
    },
    rejectedCount: {
      type: Number,
      default: 0,
    },
    pendingCount: {
      type: Number,
      default: 0,
    },
    abandonedCount: {
      type: Number,
      default: 0,
    },
    signaturesUsed: {
      type: Number,
      default: 0,
    },
    signaturesMax: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
