<template>
  <v-list
    v-if="assetList.length != 0"
    subheader
    two-line
    height="450"
    :style="{ 'overflow-y': scrollList }"
  >
    <v-list-item
      v-for="signature in assetList"
      :key="signature.id"
      @click="
        $router.push({
          name: 'SignatureDetailView',
          params: {
            id: signature.id,
          },
        })
      "
    >
      <v-list-item-content class="pa-3">
        <v-btn
          elevation="0"
          rounded
          class="text-right"
          :color="getAlertStatus(signature.alert).color"
        >
          <v-icon :class="getAlertStatus(signature.alert).color" dark>
            {{ getAlertStatus(signature.alert).icon }}
          </v-icon>
          <span class="ml-3">
            {{ $t(getAlertStatus(signature.alert).status) }}
          </span>
          <v-spacer />
          <span>
            {{ $d(signature.alert?.date, 'i18nDate') }}
          </span>
        </v-btn>
        <v-tooltip max-width="400" v-if="signature.alert" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2 subtitle" v-bind="attrs" v-on="on">
              {{
                signature.alert.text.length > 37
                  ? signature.alert.text.substring(0, 37) + '...'
                  : signature.alert.text
              }}
            </div>
          </template>
          <div width="300">
            <h2>{{ $d(signature.alert.date) }}</h2>
            {{ signature.alert.text }}
          </div>
        </v-tooltip>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-container v-else style="text-align: center">
    <b>
      <span> {{ $t('emptyTypeAlertList') }} </span>
    </b>
  </v-container>
</template>
<script>
import { getAlertStatus } from '@/services/alert-service'

export default {
  props: {
    assetList: {
      type: Array,
    },
  },
  methods: {
    getAlertStatus(status) {
      return getAlertStatus(status)
    },
  },
  computed: {
    scrollList() {
      return this.assetList.length > 5 ? 'scroll' : 'hidden'
    },
  },
}
</script>
