<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" md="6" lg="4">
            <NotificationStats
              :acceptedCount="acceptedNotifications.length"
              :pendingCount="pendingNotifications.length"
              :urgentCount="urgentNotifications.length"
              :expiredCount="expiredNotifications.length"
              class="mb-4"
            >
            </NotificationStats>
            <NotificationsList
              data-v-step="301"
              :pending="pendingNotifications.slice(0, 7)"
              :urgent="urgentNotifications.slice(0, 7)"
              :expired="expiredNotifications.slice(0, 7)"
              :accepted="acceptedNotifications.slice(0, 7)"
              class="mt-4 v-step-1"
            >
            </NotificationsList>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <ManagerStats
              :acceptedCount="assignedAcceptedNotifications.length"
              :urgentCount="assignedUrgentNotifications.length"
              :pendingCount="assignedPendingNotifications.length"
              :expiredCount="assignedExpiredNotifications.length"
              class="mb-4"
            >
            </ManagerStats>
            <ManagerList
              class="ma-1"
              :toAccept="assignedNotificationsNotAccepted"
              :accepted="assignedAcceptedNotifications"
              :urgent="assignedUrgentNotifications"
              :pending="assignedPendingNotifications"
              :expired="assignedExpiredNotifications"
            >
            </ManagerList>

            <NotificationsToAcceptDialog
              v-if="assignedNotificationsNotAccepted.length > 0"
              :toAccept="assignedNotificationsNotAccepted"
              :shake-enabled="true"
            />

            <TermsToAcceptDialog v-if="!isTermsAccepted" />
            <LicenseRenewedDialog v-if="isLicenseJustRenewed" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <AlertStats
              assetType="notifications"
              :totalCount="withAlertNotifications.length"
              :urgentCount="withAlertUrgentNotifications.length"
              :pendingCount="withAlertPendingNotifications.length"
              :expiredCount="withAlertExpiredNotifications.length"
              class="mb-4"
            >
            </AlertStats>
            <AlertsList
              AlertListElement="AlertListElementNotifications"
              :urgent="withAlertUrgentNotifications"
              :expired="withAlertExpiredNotifications"
              :pending="withAlertPendingNotifications"
              class="mt-4"
            >
            </AlertsList>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertStats from '@/components/dashboard/dashboardElements/common/AlertStats'
import AlertsList from '@/components/dashboard/dashboardElements/common/AlertsList'
import ManagerStats from '@/components/dashboard/dashboardElements/notifications/ManagerStats'
import NotificationsList from '@/components/dashboard/dashboardElements/notifications/NotificationsList'
import NotificationStats from '@/components/dashboard/dashboardElements/notifications/NotificationStats'
import ManagerList from '@/components/dashboard/dashboardElements/notifications/ManagerList'
import { getNotificationStatus } from '@/services/notification-service'
import { getAlertStatus } from '@/services/alert-service'
import NotificationsToAcceptDialog from '@/components/dashboard/dashboardElements/notifications/NotificationsToAcceptDialog'
import TermsToAcceptDialog from '@/components/commons/TermsToAcceptDialog'
import LicenseRenewedDialog from '@/components/commons/LicenseRenewedDialog'

import { mapGetters } from 'vuex'

export default {
  components: {
    AlertsList,
    AlertStats,
    ManagerStats,
    NotificationsList,
    NotificationStats,
    ManagerList,
    NotificationsToAcceptDialog,
    TermsToAcceptDialog,
    LicenseRenewedDialog,
  },
  computed: {
    ...mapGetters(['isTermsAccepted', 'isLicenseJustRenewed']),
    notifications() {
      return this.$store.state.notifications
    },
    //----------------Notifications-------------------
    pendingNotifications() {
      return this.notifications.filter((notification) => {
        return (
          getNotificationStatus(notification).status ==
            'notificationStatus.pending' ||
          getNotificationStatus(notification).status ==
            'notificationStatus.appeared'
        )
      })
    },
    acceptedNotifications() {
      return this.notifications.filter((notification) => {
        return (
          getNotificationStatus(notification).status ==
            'notificationStatus.accepted' ||
          getNotificationStatus(notification).status ==
            'notificationStatus.processing' ||
          getNotificationStatus(notification).status ==
            'notificationStatus.completed'
        )
      })
    },
    urgentNotifications() {
      return this.notifications.filter((notification) => {
        return (
          getNotificationStatus(notification).status ==
          'notificationStatus.urgent'
        )
      })
    },
    expiredNotifications() {
      return this.notifications.filter((notification) => {
        return (
          getNotificationStatus(notification).status ==
          'notificationStatus.expired'
        )
      })
    },
    //----------------Alerts-------------------
    withAlertNotifications() {
      return this.notifications
        .filter((notification) => {
          return notification.alert
        })
        .sort(this.compareAlertDate)
    },
    withAlertUrgentNotifications() {
      return this.withAlertNotifications.filter((notification) => {
        return getAlertStatus(notification.alert).status == 'alertStatus.urgent'
      })
    },
    withAlertPendingNotifications() {
      return this.withAlertNotifications.filter((notification) => {
        return (
          getAlertStatus(notification.alert).status == 'alertStatus.pending'
        )
      })
    },
    withAlertExpiredNotifications() {
      return this.withAlertNotifications.filter((notification) => {
        return (
          getAlertStatus(notification.alert).status == 'alertStatus.expired'
        )
      })
    },

    //------Manager-------
    assignedNotifications() {
      return this.notifications
        .filter((notification) => {
          if (notification.manager) {
            return notification.manager.id == this.$store.state.user.id
          }
        })
        .sort(this.compareDate)
    },
    assignedAcceptedNotifications() {
      return this.assignedNotifications.filter((notification) => {
        return (
          getNotificationStatus(notification).status ==
            'notificationStatus.accepted' ||
          getNotificationStatus(notification).status ==
            'notificationStatus.processing' ||
          getNotificationStatus(notification).status ==
            'notificationStatus.completed'
        )
      })
    },
    assignedNotificationsNotAccepted() {
      return this.notifications
        .filter((notification) => {
          return (
            notification.managerRef?.id == this.$store.state.user.id &&
            notification.managerAccepted === false
          )
        })
        .sort(this.compareDate)
    },
    assignedUrgentNotifications() {
      return this.notifications
        .filter((notification) => {
          if (notification.manager) {
            return notification.manager.id == this.$store.state.user.id
          }
        })
        .filter((notification) => {
          return (
            getNotificationStatus(notification).status ==
            'notificationStatus.urgent'
          )
        })
    },
    assignedPendingNotifications() {
      return this.notifications
        .filter((notification) => {
          if (notification.manager) {
            return notification.manager.id == this.$store.state.user.id
          }
        })
        .filter((notification) => {
          return (
            getNotificationStatus(notification).status ==
              'notificationStatus.pending' ||
            getNotificationStatus(notification).status ==
              'notificationStatus.appeared'
          )
        })
    },
    assignedExpiredNotifications() {
      return this.notifications
        .filter((notification) => {
          if (notification.manager) {
            return notification.manager.id == this.$store.state.user.id
          }
        })
        .filter((notification) => {
          return (
            getNotificationStatus(notification).status ==
            'notificationStatus.expired'
          )
        })
    },
  },
  methods: {
    compareNotificationDate(a, b) {
      if (
        new Date(a.fecha_puesta_disposicion) <
        new Date(b.fecha_puesta_disposicion)
      ) {
        return -1
      }
      if (
        new Date(a.fecha_puesta_disposicion) >
        new Date(b.fecha_puesta_disposicion)
      ) {
        return 1
      }
      return 0
    },
    compareAlertDate(a, b) {
      if (new Date(a.alert.date) < new Date(b.alert.date)) {
        return -1
      }
      if (new Date(a.alert.date) > new Date(b.alert.date)) {
        return 1
      }
      return 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
