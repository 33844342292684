<template>
  <v-container fluid>
    <template v-if="!isMobile">
      <v-row justify="center">
        <v-col cols="6" lg="5">
          <SignaturePropertiesCard
            v-if="signature.documents"
            :loadingSignature="loadingSignature"
            :signature="signature"
            :history="history"
          >
          </SignaturePropertiesCard>
        </v-col>
        <v-col v-if="signature.documents" cols="6" lg="4">
          <SignatureFiles :signature="signature" />
        </v-col>

        <v-col cols="4" lg="3">
          <SignatureHistory
            data-v-step="705"
            v-if="tab == 0"
            :loadingHistory="loadingHistory"
            :history="history"
          />
        </v-col>
      </v-row>
    </template>
    <!-- Versión móvil -->
    <template v-else>
      <SignaturePropertiesCard
        v-if="signature.documents"
        :loadingSignature="loadingSignature"
        :signature="signature"
        :history="history"
      >
      </SignaturePropertiesCard>
      <v-spacer class="my-5" />
      <SignatureFiles v-if="signature.documents" :signature="signature" />
      <v-spacer class="my-5" />
      <SignatureHistory
        v-if="tab == 0"
        :loadingHistory="loadingHistory"
        :history="history"
      />
    </template>
  </v-container>
</template>

<script>
import SignaturePropertiesCard from '@/components/signatures/detail/SignaturePropertiesCard'
import SignatureHistory from '@/components/commons/AssetHistory'
import SignatureFiles from '@/components/signatures/detail/SignatureFiles'
import { mapGetters } from 'vuex'

export default {
  props: {
    signature: Object,
    loading: Boolean,
    history: Array,
    loadingSignature: Boolean,
    loadingHistory: Boolean,
  },
  components: {
    SignaturePropertiesCard,
    SignatureFiles,
    SignatureHistory,
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
