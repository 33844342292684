import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import i18n from '@/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: { t: (key, ...params) => i18n.t(key, params) },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1D9EA4',
        secondary: '#FFFFFF',
        success: '#00C853', //green accent-4
        info: 'E0E0E0', // grey-lighten-2
        warning: '#FF6F00',
        warningLow: '#FFC400',
        error: '#D70A0A',
        errorLigth: '#E66A35',
        accept: '#1D9EA4',
        appeared: '#FFA500',
        progress: '#C4D826',
        processing: '#1E81B0',
        completed: '#021691',
      },
      dark: {
        primary: '#1D9EA4',
        secondary: '#EEEEEE',
        success: '#00C853', //green accent-4
        info: 'E0E0E0', // grey-lighten-2
        warning: '#FF6F00',
        warningLow: '#FFC400',
        error: '#D70A0A',
        errorLigth: '#E66A35',
        accept: '#1D9EA4',
        appeared: '#FFA500',
        progress: '#C4D826',
        processing: '#1E81B0',
        completed: '#021691',
      },
    },
  },
})
