import {
  canOperatorBeManager,
  getNotificationStatusFromI18n,
} from '@/services/notification-service'

/**
 * Checks if the item can be added.
 * @param {string} selectedAction Action to perform massively.
 * @param {Object} massiveActionData Data of the massive action.
 * @param {Object} item Item to checked.
 * @returns True if the item can be added, false if not.
 */
export async function checkItem(selectedAction, massiveActionData, item) {
  // Checking the "changeStatus" action
  if (selectedAction === 'changeStatus') {
    return (
      !!item.document && // The notification is open
      ((getNotificationStatusFromI18n(massiveActionData.status) ===
        'Completado' &&
        !item.isCompleted) || // If completed is selected, the notification status is not
        (getNotificationStatusFromI18n(massiveActionData.status) ===
          'En Trámite' &&
          !item.isProcessing) || // If Processing is selected, the notification status is not
        getNotificationStatusFromI18n(massiveActionData.status) !== item.estado) // If expired/accepted is selected, the notification status is not
    )
  }
  // Checking the "openNotification" action
  else if (selectedAction === 'openNotification') {
    return !item.document // Notification must be closed
  }
  // Checking the "setResponsible" action
  else if (selectedAction === 'setResponsible') {
    return (
      !item.managerRef && // Does not have a manager
      massiveActionData.operator?.id && // Operator is loaded
      (await canOperatorBeManager(massiveActionData.operator, item))
    )
  }
  // Checking the "setAlert" action
  else if (selectedAction === 'setAlert') {
    return !item.alert // The notification does not have an alert
  }
  // Checking the "setObservation" action
  else if (selectedAction === 'setObservation') {
    return !item.observation // The notification does not have an observation
  }
  // Checking the "downloadFiles" action
  else if (selectedAction === 'downloadFiles') {
    return item.document
  }
}
