var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-avatar',{attrs:{"color":_vm.avatarColor,"size":_vm.avatarSize}},[(_vm.isGroup)?_c('v-icon',{style:(`font-size: ${_vm.iconSize}px;`)},[_vm._v(" mdi-account-group-outline ")]):(!_vm.userAvatar)?_c('span',{class:`${_vm.initialsColor}--text`,style:(`font-size: ${_vm.initialsSize}px`)},[(_vm.userName)?[_vm._v(" "+_vm._s(_vm.userName .split(' ') .map((n) => n[0]) .join('') .substring(0, 3))+" ")]:_vm._e()],2):_c('v-img',{attrs:{"elevation":"2","src":_vm.userAvatar}})],1),(_vm.userRole)?_c('v-avatar',{style:({
      'margin-left': `${-_vm.avatarSize / 2.5}px`,
      'margin-top': `${_vm.avatarSize / 1.5}px`,
      position: 'absolute',
    }),attrs:{"color":_vm.overlapAvatarColor,"size":_vm.overlapAvatarSize}}):_vm._e(),(_vm.showWarn)?_c('v-icon',{style:({
      'font-size': `${_vm.overlapIconSize}px`,
      'margin-left': `${-_vm.avatarSize / 2.5}px`,
      'margin-top': `${_vm.avatarSize / 1.5}px`,
      position: 'absolute',
    }),attrs:{"color":_vm.overlapIconColor}},[_vm._v("mdi-alert ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }