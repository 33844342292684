<template>
  <div id="Operators" :style="styleData">
    <apexchart
      type="bar"
      :height="newHeight"
      :options="series.chartOptions"
      :series="series.series"
    ></apexchart>
    <v-icon
      v-if="series.loading"
      style="top: -10%; left: 50%; z-index: 999; position: relative"
    >
      mdi-autorenew mdi-spin
    </v-icon>
    <OperatorsGeo
      v-if="true"
      chartname="n1"
      :title="$t('operatorsByDate.title')"
      height="600"
      :dataLayers="series.layers"
    ></OperatorsGeo>
    <v-select
      class="mt-2"
      :label="$t('operatorsByDate.selectTitle')"
      v-model="selectedUsers"
      :items="series.users"
      outlined
      chips
      deletable-chips
      multiple
      :clearable="true"
      :menu-props="{ top: true, offsetY: true }"
      item-text="title"
      item-value="value"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.title }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ selectedUsers.length - 1 }} operadores)
        </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import OperatorsGeo from '@/components/analytics/graphs/OperatorsGeo'

const valueForAll = 0

const yearDivision = 'year'
const yearTimeGroup = 'YYYY'

const monthDivision = 'month'
const monthTimeGroup = 'YYYY-MM'

const weekDivision = 'isoWeek'
const weekTimeGroup = 'GGGG-WW'

const dayDivision = 'day'
const dayTimeGroup = 'YY-MM-DD'

const dateStringFormat = 'YYYYMMDD'

const dateDivisionLevels = {
  year: 0,
  month: 1,
  week: 2,
  day: 3,
}

export default {
  components: {
    apexchart: VueApexCharts,
    OperatorsGeo,
  },
  props: {
    chartname: String,
    title: String,
    operators: Array,
    height: String,
    dateFrom: String,
    dateTo: String,
  },
  data() {
    var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    return {
      actualDateFrom: '',
      actualDateTo: '',
      detailDateFrom: '',
      detailDateTo: '',
      timeGroup: yearTimeGroup,
      initialTimeUnit: yearTimeGroup,
      timeUnit: yearDivision,
      selectedUsers: [0],
      colors: ['80c7fd', '008FFB', '80f1cb', '00E396', 'e3928d', 'eb4034'],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          stacked: false,
        },
        theme: {
          mode: theme,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [],
        },
        colors: [
          '#80c7fd',
          '#008FFB',
          '#80f1cb',
          '#00E396',
          '#e3928d',
          '#eb4034',
        ],
        noData: {
          text: this.$t('graphs.noData'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
      },
      newHeight: parseInt(this.height) - 90,
      styleData: {
        /*'overflow-y: auto; max-height:' +
        parseInt(this.height) +
        'px;max-height:' +
        parseInt(this.height) +
        'px',*/
        height: '950px',
        width: '100%',
      },
      emptyFeature: {
        id: 0,
        name: '',
        type: 'marker',
        coords: [],
      },
      emptyLayers: [
        {
          id: 0,
          name: 'Operadores',
          active: true,
          features: [],
        },
      ],
    }
  },

  computed: {
    series() {
      //console.log('series computed operators: ', this.operators)

      if (this.operators === undefined || this.operators.length === 0) {
        return {
          chartOptions: this.chartOptions,
          series: [],
          loading: true,
          users: [{ title: this.$t('operatorsByDate.selectAll'), value: 0 }],
          layers: JSON.parse(JSON.stringify(this.emptyLayers)),
        }
      }
      let dataMap = []
      let users = []

      var dataLayers = JSON.parse(JSON.stringify(this.emptyLayers))
      this.buildOperatorsSeries(users, dataMap)

      var series = []
      var dataKeyList = []
      this.generateDataKeyList().forEach((element) => {
        dataKeyList.push(element)
      })
      this.buildGraphsSeries(dataKeyList, series, users, dataMap, dataLayers)

      let pack = {
        chartOptions: this.createCustomOptions(dataKeyList),
        series: series,
        users: users,
        loading: false,
        layers: dataLayers,
      }
      //console.log('series calculadas', series)
      //console.log('custom options', customOptions)

      return pack
    },
  },
  methods: {
    createCustomOptions(dataKeyList) {
      return {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config)
              setTimeout(() => {
                this.zoomNextLevel(
                  dataKeyList[config.dataPointIndex],
                  config.seriesIndex
                )
              }, 10)
            },
            xAxisLabelClick: (event, chartContext, config) => {
              //console.log(config)
              setTimeout(() => {
                this.zoomNextLevel(dataKeyList[config.labelIndex])
              }, 10)
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 25,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: [
                {
                  icon: '<span class="mdi mdi-undo"/>',
                  index: 0,
                  title: 'Atrás',
                  class: '',
                  click: () => {
                    this.zoomPreviousLevel()
                  },
                },
              ],
            },
          },
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: dataKeyList,
          labels: {
            show: true,
          },
          tickPlacement: 'on',
        },
        noData: {
          text: this.$t('graphs.noData'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
        title: {
          text: this.title + ': ' + this.$t('graphs.timeUnit.' + this.timeUnit),
          align: 'left',
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
      }
    },
    buildGraphsSeries(dataKeyList, series, users, dataMap, dataLayers) {
      var seriesIndividual = []
      var seriesTotal = {
        name: this.$t('operatorsByDate.selectAll'),
        data: [],
      }

      var layerIndividual = []
      //var layerTotal = []

      users.forEach((element) => {
        var layerData = []
        var seriesData = {
          name: '',
          data: [], //[0,0,0,0] 2023-10 (0)
        }

        if (element.title !== this.$t('operatorsByDate.selectAll')) {
          seriesData.name = element.title
          dataKeyList.forEach((key, index) => {
            if (!seriesTotal.data[index]) {
              seriesTotal.data[index] = 0
            }
            if (dataMap[key][element.value]) {
              seriesData.data.push(dataMap[key][element.value].count)
              dataMap[key][element.value].geo.forEach((geoData) => {
                if (geoData.name !== '') {
                  layerData.push(geoData)
                }
              })
              seriesTotal.data[index] =
                seriesTotal.data[index] + dataMap[key][element.value].count
              /* TRas la plani, la serie total no se muestra en el gráfico
              dataMap[key][element.value].geo.forEach((geoData) => {
                if (geoData.name !== '') {
                  geoData.color = this.colors[0]
                  layerTotal.push(geoData)
                }
              })*/
            } else {
              seriesData.data.push(0)
            }
          })
          this.selectedUsers.find((user, idx) => {
            if (user === element.value) {
              this.operators
              //console.log(idx)
              seriesIndividual.push(seriesData)
              layerData.forEach((geoData) => {
                var idxColor
                if (idx > 5) {
                  idxColor = 0
                } else {
                  idxColor = idx
                }
                geoData.color = this.colors[idxColor]
                layerIndividual.push(geoData)
              })
            }
          })
        }
      })

      dataLayers[0].features = []
      this.selectedUsers.find((user) => {
        if (user === 0) {
          series.push(seriesTotal)
          /* Tras la planificación, eliminamos la capa Total del mapa
          layerTotal.forEach((feature) => {
            dataLayers[0].features.push(feature)
          })*/
        }
      })
      seriesIndividual.forEach((element) => {
        series.push(element)
        layerIndividual.forEach((feature) => {
          dataLayers[0].features.push(feature)
        })
      })

      dataLayers[0].features = this.removeDuplicates(dataLayers[0].features)
      // console.log('antes de agrupar', dataLayers[0].features)
      dataLayers[0].features = this.groupSameCoordinates(dataLayers[0].features)
      // console.log('despues de agrupar', dataLayers[0].features)
      dataLayers[0].features = this.recalculateCoordenates(
        dataLayers[0].features
      )
      // console.log('despues de recalcular', dataLayers[0].features)
    },
    removeDuplicates(data) {
      let unique = []
      data.forEach((element) => {
        if (!unique.includes(element)) {
          unique.push(element)
        }
      })
      return unique
    },
    groupSameCoordinates(data) {
      // Objeto para almacenar los elementos agrupados
      let elementosAgrupados = {}

      // Recorrer el array original
      data.forEach((objeto) => {
        // Crear una clave única para agrupar por x, y e id
        let clave = `${objeto.coords[0]}-${objeto.coords[1]}-${objeto.id}`
        // console.log(clave)

        // Verificar si la clave ya existe en el objeto agrupado
        if (Object.prototype.hasOwnProperty.call(elementosAgrupados, clave)) {
          // Incrementar el valor de 'ocurrencias'
          elementosAgrupados[clave].ocurrencias =
            elementosAgrupados[clave].ocurrencias + 1

          // Actualizar min y max
          elementosAgrupados[clave].min = Math.min(
            elementosAgrupados[clave].min,
            objeto.date.valueOf()
          )
          elementosAgrupados[clave].max = Math.max(
            elementosAgrupados[clave].max,
            objeto.date.valueOf()
          )
        } else {
          // Si la clave no existe, agregar el objeto al objeto agrupado
          elementosAgrupados[clave] = {
            ...objeto,
            ocurrencias: 1,
            min: objeto.date.valueOf(),
            max: objeto.date.valueOf(),
          }
        }
      })

      // Convertir el objeto agrupado de nuevo a un array
      let arrayAgrupado = Object.values(elementosAgrupados)

      return arrayAgrupado
    },

    recalculateCoordenates(arrayOriginal) {
      // Objeto para almacenar los elementos agrupados por x e y
      let elementosAgrupados = {}
      let nuevoArray = {}

      // Recorrer el array original
      arrayOriginal.forEach((objeto) => {
        // Crear una clave única para agrupar por x e y
        let clave = `${objeto.coords[0]}-${objeto.coords[1]}`
        let clave2 = `${objeto.coords[0]}-${objeto.coords[1]}-${objeto.id}`
        // Verificar si la clave ya existe en el objeto agrupado
        if (Object.prototype.hasOwnProperty.call(nuevoArray, clave)) {
          // Verificar si el id es distinto
          if (nuevoArray[clave].id !== objeto.id) {
            elementosAgrupados[clave2] = {
              ...objeto,
            }
            nuevoArray[clave] = {
              ...objeto,
            }
            // Recalcular nuevos valores de x e y
            elementosAgrupados[clave2].coords[0] =
              parseFloat(elementosAgrupados[clave2].coords[0]).toFixed(3) +
              parseInt(this.generarHashSencillo(objeto.id))
            elementosAgrupados[clave2].coords[1] =
              parseFloat(elementosAgrupados[clave2].coords[1]).toFixed(3) +
              parseInt(this.generarHashSencillo(objeto.id))

            elementosAgrupados[clave2].name =
              elementosAgrupados[clave2].name +
              '<p style="text-align: center;"> ' +
              this.$t('operatorsByDate.from') +
              ': ' +
              moment(elementosAgrupados[clave2].min).format('DD/MM/YYYY') +
              ' ' +
              this.$t('operatorsByDate.to') +
              ': ' +
              moment(elementosAgrupados[clave2].max).format('DD/MM/YYYY') +
              '</p>' +
              '<p style="text-align: center;">' +
              elementosAgrupados[clave2].ocurrencias +
              ' ' +
              this.$t('operatorsByDate.access') +
              '</p>'
          }
        } else {
          // Si la clave no existe, agregar el objeto al objeto agrupado
          elementosAgrupados[clave2] = {
            ...objeto,
          }
          nuevoArray[clave] = {
            ...objeto,
          }
          elementosAgrupados[clave2].name =
            elementosAgrupados[clave2].name +
            '<p style="text-align: center;"> desde: ' +
            moment(elementosAgrupados[clave2].min).format('DD/MM/YYYY') +
            ' hasta:' +
            moment(elementosAgrupados[clave2].max).format('DD/MM/YYYY') +
            '</p>' +
            '<p style="text-align: center;">' +
            elementosAgrupados[clave2].ocurrencias +
            ' accesos</p>'
        }
      })

      // Convertir el objeto agrupado de nuevo a un array
      let arrayAgrupado = Object.values(elementosAgrupados)

      return arrayAgrupado
    },
    generarHashSencillo(cadena) {
      let hash = 0
      for (let i = 0; i < cadena.length; i++) {
        const char = cadena.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash |= 0 // Convierte a un entero de 32 bits
      }
      if (hash < 0) {
        return hash * -1
      }
      return hash
    },
    buildOperatorsSeries(users, dataMap) {
      users.push({ title: this.$t('operatorsByDate.selectAll'), value: 0 })
      this.generateDataKeyList().forEach((element) => {
        dataMap[element] = []
      })
      this.operators.forEach((operator) => {
        users.push({
          title: operator.name + ' ' + operator.surname,
          value: operator.id,
        })

        if (operator.traces) {
          operator.traces.forEach((trace) => {
            let indexTimeGroup = moment(trace.createdAt).format(this.timeGroup)
            if (dataMap[indexTimeGroup]) {
              if (dataMap[indexTimeGroup][operator.id] === undefined) {
                dataMap[indexTimeGroup][operator.id] = { count: 1, geo: [] }
                dataMap[indexTimeGroup][operator.id].geo.push(
                  this.recordGeoData(operator, trace)
                )
              } else {
                dataMap[indexTimeGroup][operator.id].count =
                  dataMap[indexTimeGroup][operator.id].count + 1
                dataMap[indexTimeGroup][operator.id].geo.push(
                  this.recordGeoData(operator, trace)
                )
              }
            }
          })
        }
      })
    },

    recordGeoData(operator, trace) {
      //console.log('dataLayers', dataLayers)
      //console.log('trace', trace)
      let newGeo = JSON.parse(JSON.stringify(this.emptyFeature))
      if (trace.ipGeo !== undefined) {
        newGeo.name =
          '<p style="text-align: center;"> ' +
          operator.name +
          '</p><p style="text-align: center;">' +
          operator.surname +
          '</p>'
        newGeo.coords.push(trace.ipGeo.latitud)
        newGeo.coords.push(trace.ipGeo.longitud)
        newGeo.date = trace.createdAt
        newGeo.id = operator.id
        //console.log('geolocalizacion', newGeo)
        //console.log('this.emptyFeature', this.emptyFeature)
      }
      return newGeo
    },

    generateDataKeyList() {
      let currentMoment = moment(this.actualDateFrom)
      let endMoment = moment(this.actualDateTo)
      let keyList = []
      while (currentMoment.isSameOrBefore(endMoment, this.timeUnit)) {
        // console.log(this.timeGroup,':',currentMoment.format(this.timeGroup))
        keyList.push(currentMoment.format(this.timeGroup))
        currentMoment.add(1, this.timeUnit)
      }
      return keyList
    },
    setTimeGroup(newTimeGroup, newTimeUnit) {
      this.timeGroup = newTimeGroup
      this.timeUnit = newTimeUnit
    },

    getReceivedDateGroup(notification) {
      let dateString = notification.fecha_puesta_disposicion
      let out = moment(dateString).format(this.timeGroup)
      return out
    },
    isBetweenDates(dateString) {
      let actual = moment(dateString, this.timeGroup)
      let from = moment(this.detailDateFrom)
      let to = moment(this.detailDateTo)
      return !(actual.isBefore(from) || actual.isAfter(to))
    },
    zoomNextLevel(selectedX, selectedIndex) {
      if (this.timeUnit === yearDivision) {
        this.zoomMonthsLevel(selectedX)
      } else if (this.timeUnit === monthDivision) {
        this.zoomWeeksLevel(selectedX)
      } else if (this.timeUnit === weekDivision) {
        this.zoomDaysLevel(selectedX)
      } else if (this.timeUnit === dayDivision) {
        let part = selectedX.split('-')
        let prefixYear = '20'
        if (!dayTimeGroup.startsWith('YY-')) {
          prefixYear = ''
        }
        this.detailDateFrom = moment(
          prefixYear + part[0] + part[1] + part[2]
        ).format(dateStringFormat)
        this.detailDateTo = moment(
          prefixYear + part[0] + part[1] + part[2]
        ).format(dateStringFormat)

        let title =
          this.$t('graphs.operatorsTracesGridTitle') +
          this.$d(
            new Date(moment(this.detailDateFrom).format('YYYY-MM-DD')),
            'i18nDate'
          )

        let clickedUser = valueForAll
        if (selectedIndex) {
          if (this.selectedUsers[selectedIndex] === valueForAll) {
            clickedUser = valueForAll
          } else {
            clickedUser = this.selectedUsers[selectedIndex]
          }
        }

        this.$emit('detail-level', this.detailDateFrom, title, clickedUser)
      }
    },
    zoomPreviousLevel() {
      if (
        dateDivisionLevels[this.initialTimeUnit] >=
        dateDivisionLevels[this.timeUnit]
      ) {
        return
      }
      if (this.timeUnit === monthDivision) {
        this.resetDates()
      } else if (this.timeUnit === weekDivision) {
        let year = moment(this.actualDateFrom).format(yearTimeGroup)
        this.zoomMonthsLevel(year)
      } else if (this.timeUnit === dayDivision) {
        let month = moment(this.actualDateFrom).format(monthTimeGroup)
        this.zoomWeeksLevel(month)
      }
    },
    zoomMonthsLevel(selectedYear) {
      this.actualDateFrom = selectedYear + '0101'
      this.actualDateFrom = this.startDate(this.actualDateFrom, this.dateFrom)
      this.actualDateTo = this.dateOrToday(selectedYear + '1231')
      this.actualDateTo = this.endDate(this.actualDateTo, this.dateTo)
      this.setTimeGroup(monthTimeGroup, monthDivision)
    },
    zoomWeeksLevel(selectedMonth) {
      let parts = selectedMonth.split('-')
      this.actualDateFrom = parts[0] + parts[1] + '01'
      let lastDayOfMonth = moment(this.actualDateFrom)
        .endOf(monthDivision)
        .format(dateStringFormat)
      this.actualDateTo = this.dateOrToday(lastDayOfMonth)
      this.setTimeGroup(weekTimeGroup, weekDivision)
    },
    zoomDaysLevel(selectedWeek) {
      let weekMoment = moment(selectedWeek, weekTimeGroup)
      this.actualDateFrom = weekMoment.format(dateStringFormat)
      let lastDayOfWeek = moment(this.actualDateFrom)
        .add(6, dayDivision)
        .format(dateStringFormat)
      this.actualDateTo = this.dateOrToday(lastDayOfWeek)
      this.setTimeGroup(dayTimeGroup, dayDivision)
    },
    dateOrToday(currentDateTo) {
      if (moment().isBefore(moment(currentDateTo))) {
        return moment().format(dateStringFormat)
      }
      return currentDateTo
    },
    startDate(currentDateFrom, initialDateFrom) {
      if (moment(currentDateFrom).isBefore(moment(initialDateFrom))) {
        return moment(initialDateFrom).format(dateStringFormat)
      }
      return currentDateFrom
    },
    endDate(currentDateTo, initialDateTo) {
      if (moment(currentDateTo).isAfter(moment(initialDateTo))) {
        return moment(initialDateTo).format(dateStringFormat)
      }
      return currentDateTo
    },
    resetDates() {
      this.actualDateFrom = this.dateFrom
      this.actualDateTo = this.dateTo
      this.setTimeGroup(yearTimeGroup, yearDivision)
    },
    setInitialLevel() {
      let rangeDays = moment(this.dateTo).diff(moment(this.dateFrom), 'days')
      //console.log(rangeDays)
      if (rangeDays > 365) {
        this.setTimeGroup(yearTimeGroup, yearDivision)
      } else {
        this.setTimeGroup(monthTimeGroup, monthDivision)
      }
      this.initialTimeUnit = this.timeUnit
    },
    setInitialValues() {
      this.actualDateFrom = this.dateFrom
      this.actualDateTo = this.dateTo
      this.setInitialLevel()
    },
  },
  created() {
    this.setInitialValues()
  },
  watch: {
    dateFrom: {
      deep: true,
      handler: function () {
        this.setInitialValues()
      },
    },
  },
}
</script>
