import { Firestore, UsersDoc, UsersPlanRetrieve } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Obtiene el plan de usuario asociado a un ID de usuario específico.
 *
 * @param {string} userId - El ID del usuario para el cual se está recuperando el plan.
 * @returns {Promise<Object|undefined>} Una promesa que se resuelve con el plan del usuario, o undefined si no tiene plan.
 */
export async function getUserPlan(userId) {
  let response = await Firestore.getDoc(UsersDoc(userId))
  const { plan } = response.data()

  return plan ? plan : undefined
}

/**
 * Crea una subscripción al plan de un usuario, actualizando el estado en la tienda cada vez que hay un cambio.
 *
 * @param {string} userId - El ID del usuario cuyo plan se está suscribiendo.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function planSubscription(userId) {
  var unsub = Firestore.onSnapshot(UsersDoc(userId), async (docSnap) => {
    const { plan } = docSnap.data()

    // Guardamos en el store
    store.commit('setPlan', plan ? plan : undefined)
    store.commit('setPlanUnsubscribe', unsub)
  })

  return unsub
}

/**
 * Recupera el plan actual del usuario desde el servidor.
 *
 * @returns {Promise<Object>} Una promesa que se resuelve con los detalles del plan del usuario.
 */
export async function usersPlanRetrieve() {
  return await UsersPlanRetrieve({
    userId: store.state.user.parentRef
      ? store.state.user.parentRef.id
      : store.state.user.id,
  })
}

/**
 * Actualiza el plan del usuario. Si se fuerza la actualización o la fecha de vencimiento del plan ha pasado, se recupera el plan actualizado.
 *
 * @param {boolean} [forceUpdate=false] - Indica si se debe forzar la actualización del plan.
 */
export async function updatePlan(forceUpdate = false) {
  if (
    forceUpdate ||
    dateElapsed(store.state.plan?.expirationLicense?.toDate())
  ) {
    usersPlanRetrieve().catch((e) => void e) // Catch to ignore errors in front
  }
}

/**
 * Verifica si una fecha ha pasado con respecto a la fecha y hora actual.
 *
 * @param {Date} date - La fecha a verificar.
 * @returns {boolean} Verdadero si la fecha actual es mayor o igual a la fecha dada.
 */
export function dateElapsed(date) {
  const now = Date.now()

  return now >= date?.getTime()
}
