<template>
  <v-col cols="12" :lg="hasToBeShort ? 6 : 12" :md="hasToBeShort ? 6 : 12">
    <!-- Input when the input type is email -->
    <template
      v-if="
        inputType === 'email' ||
        inputType === 'emailNoInput' ||
        inputType === 'groupEmails'
      "
    >
      <v-col cols="12" v-if="inputType === 'email'">
        <v-text-field
          :disabled="disabled"
          :label="`${$t('emails')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-at"
          :value="emailData.emails"
          @input="(v) => setEmailData(v, 'emails')"
          :rules="[notEmpty, maxCharacters(maxChars), emailsComa]"
          dense
          autocomplete="false"
          id="automated-task-additional-email"
          :hint="$t('emailsComaHint')"
        />
      </v-col>
      <v-col cols="12" v-if="inputType === 'groupEmails'">
        <GroupAutocomplete
          @setGroups="(groups) => setEmailData(groups, 'groups')"
          :disabled="disabled"
          :objectGroups="emailData.groups"
          :groups="groups"
          :multiple="true"
          :rules="[listNotEmpty]"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :disabled="disabled"
          :label="`${$t('replyTo')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-at"
          :value="emailData.replyTo"
          @input="(v) => setEmailData(v, 'replyTo')"
          :rules="[email]"
          dense
          autocomplete="false"
          id="automated-task-additional-email-replyTo"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :disabled="disabled"
          :label="`${$t('emailSubject')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-email-outline"
          :value="emailData.subject"
          @input="(v) => setEmailData(v, 'subject')"
          :rules="[notEmpty, maxCharacters(maxChars)]"
          dense
          autocomplete="false"
          id="automated-task-additional-subject"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          :disabled="disabled"
          :label="`${$t('text')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-text"
          :value="emailData.emailText"
          @input="(v) => setEmailData(v, 'emailText')"
          auto-grow
          rows="1"
          counter
          :rules="[notEmpty, maxCharacters(maxChars * 10)]"
          dense
          autocomplete="false"
          id="automated-task-additional-email-text"
        />
      </v-col>
    </template>

    <!-- Input when the input type is operator and filter -->
    <v-autocomplete
      v-if="inputType === 'operator'"
      @input="setOperator"
      dense
      v-model="chosenOperator"
      :items="possibleOperators"
      color="primary"
      :filter="customFilterOperators"
      :no-data-text="$t('emptyOperators')"
      :label="$t('selectOperator')"
      item-value="id"
      item-text="name"
      :disabled="disabled"
      prepend-icon="mdi-account"
      :menu-props="{ bottom: true, offsetY: true }"
      id="automated-tasks-autocomplete-operator"
      :rules="[notEmpty]"
    >
      <template v-slot:item="{ item }">
        <span id="automated-tasks-operator-option">
          {{ item.name + ' ' + item.surname }}
        </span>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip small class="ma-1" :disabled="disabled">
          {{ item.name + ' ' + item.surname }}
        </v-chip>
      </template>
    </v-autocomplete>

    <!-- Input when the input type is alert -->
    <template v-if="inputType === 'alert'">
      <v-col cols="12">
        <v-text-field
          :disabled="disabled"
          :label="`${$t('daysAfter')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-clock-outline"
          :value="alertData.daysAfter"
          @input="(v) => setAlertData(v, 'daysAfter')"
          :rules="[notEmpty, maxNumberValue(10000)]"
          dense
          autocomplete="false"
          type="number"
          min="0"
          id="automated-task-days-after-target-input"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          :disabled="disabled"
          :label="`${$t('text')} - ${$t(
            'targets.titles.' + chosenTarget.name
          )}`"
          prepend-icon="mdi-text"
          :value="alertData.text"
          auto-grow
          rows="1"
          counter
          @input="(v) => setAlertData(v, 'text')"
          :rules="[notEmpty, maxCharacters(maxChars * 10)]"
          dense
          autocomplete="false"
          id="automated-task-additional-target-text"
        />
      </v-col>
    </template>

    <!-- Input when the input type is text -->
    <v-textarea
      v-if="inputType === 'text'"
      :disabled="disabled"
      :label="`${$t('text')} - ${$t('targets.titles.' + chosenTarget.name)}`"
      prepend-icon="mdi-pencil"
      :value="text"
      @input="setData"
      :rules="[notEmpty, maxCharacters(maxChars * 10)]"
      dense
      auto-grow
      rows="1"
      counter
      autocomplete="false"
      id="automated-task-text-observation"
    />

    <!-- Input when the input type is httpsRequest -->
    <template v-if="inputType === 'httpsRequest'">
      <!-- Method and url -->
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            @input="(v) => setRequestData(v, 'method')"
            dense
            v-model="requestData.method"
            :items="methods"
            color="primary"
            :label="$t('method')"
            :disabled="disabled"
            prepend-icon="mdi-meteor"
            :menu-props="{ bottom: true, offsetY: true }"
            id="automated-tasks-autocomplete-request-method"
            :rules="[notEmpty]"
          />
        </v-col>
        <v-col cols="9">
          <v-text-field
            :disabled="disabled"
            :label="`URL - ${$t('targets.titles.' + chosenTarget.name)}`"
            prepend-icon="mdi-link"
            :value="requestData.url"
            @input="(v) => setRequestData(v, 'url')"
            :rules="[notEmpty, maxCharacters(maxChars * 10)]"
            dense
            autocomplete="false"
            id="automated-task-days-after-target-input"
          />
        </v-col>
      </v-row>

      <!-- Headers -->
      <v-row
        :key="i + 'header-addition'"
        v-for="(header, i) in requestData.headers"
        :id="'at-header-' + i"
      >
        <v-col cols="5">
          <v-text-field
            :disabled="disabled"
            :label="`${$t('headerTitle')} - ${$t(
              'targets.titles.' + chosenTarget.name
            )}`"
            prepend-icon="mdi-key-outline"
            :value="header.headerTitle"
            @input="header.headerTitle = $event"
            :rules="[notEmpty, maxCharacters(maxChars * 10)]"
            dense
            autocomplete="false"
            id="automated-task-days-after-target-input"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :disabled="disabled"
            :label="`${$t('headerValue')} - ${$t(
              'targets.titles.' + chosenTarget.name
            )}`"
            prepend-icon="mdi-label-outline"
            :value="header.headerValue"
            @input="header.headerValue = $event"
            :rules="[notEmpty, maxCharacters(maxChars * 10)]"
            dense
            autocomplete="false"
            id="automated-task-days-after-target-input"
          />
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="removeHeader(i)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- New header -->
      <v-row>
        <v-col cols="5">
          <v-text-field
            :disabled="disabled"
            :label="`${$t('headerTitle')} - ${$t(
              'targets.titles.' + chosenTarget.name
            )}`"
            prepend-icon="mdi-key"
            :value="headerTitle"
            @input="headerTitle = $event"
            :rules="[maxCharacters(maxChars * 10)]"
            dense
            autocomplete="false"
            id="automated-task-days-after-target-input"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :disabled="disabled"
            :label="`${$t('headerValue')} - ${$t(
              'targets.titles.' + chosenTarget.name
            )}`"
            prepend-icon="mdi-label"
            :value="headerValue"
            @input="headerValue = $event"
            :rules="[maxCharacters(maxChars * 10)]"
            dense
            autocomplete="false"
            id="automated-task-days-after-target-input"
          />
        </v-col>
        <v-col cols="1">
          <v-btn icon color="success" @click="addHeader">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Body -->
      <v-row>
        <v-col cols="12">
          <v-textarea
            :disabled="disabled"
            :label="`${$t('bodyJSON')} - ${$t(
              'targets.titles.' + chosenTarget.name
            )}`"
            prepend-icon="mdi-code-json"
            :value="requestData.body"
            @input="(v) => setRequestData(v, 'body')"
            :rules="[maxCharacters(maxChars * 100)]"
            dense
            auto-grow
            rows="3"
            counter
            autocomplete="false"
            id="automated-task-text-observation"
          />
        </v-col>
      </v-row>
    </template>

    <!-- State selector -->
    <v-select
      v-if="inputType === 'state'"
      prepend-icon="mdi-toggle-switch"
      v-model="chosenStatus"
      :disabled="disabled"
      @input="setStatus"
      :label="$t('newStatus')"
      :items="availableStatus"
      variant="underlined"
      :rules="[notEmpty]"
    >
      <template v-slot:selection="{ item }">
        {{ $t(item) }}
      </template>
      <template v-slot:item="{ item }">
        {{ $t(item) }}
      </template>
    </v-select>
  </v-col>
</template>

<script>
import GroupAutocomplete from '@/components/groups/GroupAutocomplete'
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import {
  operatorHasCifs,
  operatorHasFilterById,
} from '../../services/operator-service'

export default {
  props: {
    targets: Array,
    chosenTarget: Object,
    chosenConditions: Array,
    disabled: Boolean,
    totalAdditionalFields: Number,
    fieldIndex: Number,
    operators: Array,
    filters: Array,
    groups: Array,
    conditionsAdditionalData: Object,
  },
  components: {
    GroupAutocomplete,
  },
  mounted() {
    // Setting the allowed target type of the given target
    const targetFound = this.targets.find(
      (target) => target.name === this.chosenTarget.name
    )
    if (targetFound) this.inputType = targetFound.inputType

    // Setting the data based on the input type
    if (this.inputType === 'operator')
      this.chosenOperator = this.chosenTarget.data
    if (this.inputType === 'email') {
      this.emailData.groups = []
      this.emailData.emails = this.chosenTarget.data?.emails
      this.emailData.replyTo = this.chosenTarget.data?.replyTo ?? ''
      this.emailData.subject = this.chosenTarget.data?.subject
      this.emailData.emailText = this.chosenTarget.data?.emailText
    }
    if (this.inputType === 'emailNoInput') {
      this.emailData.emails = ''
      this.emailData.groups = []
      this.emailData.replyTo = this.chosenTarget.data?.replyTo ?? ''
      this.emailData.subject = this.chosenTarget.data?.subject
      this.emailData.emailText = this.chosenTarget.data?.emailText
    }
    if (this.inputType === 'groupEmails') {
      this.emailData.emails = ''
      this.emailData.groups = this.chosenTarget.data?.groups ?? []
      this.emailData.replyTo = this.chosenTarget.data?.replyTo ?? ''
      this.emailData.subject = this.chosenTarget.data?.subject
      this.emailData.emailText = this.chosenTarget.data?.emailText
    }
    if (this.inputType === 'alert') {
      this.alertData.text = this.chosenTarget.data?.text
      this.alertData.daysAfter = this.chosenTarget.data?.daysAfter
    }
    if (this.inputType === 'text') this.text = this.chosenTarget.data
    if (this.inputType === 'httpsRequest' && this.chosenTarget.data)
      this.requestData = this.chosenTarget.data
    if (this.inputType === 'state' && this.chosenTarget.data)
      this.chosenStatus = this.chosenTarget.data
  },
  data() {
    return {
      methods: ['GET', 'POST', 'PUT', 'DELETE'],
      availableStatus: [
        'notificationStatus.processing',
        'notificationStatus.completed',
      ],
      inputType: {},
      maxChars: 100,
      emailData: {
        groups: undefined,
        emails: undefined,
        replyTo: undefined,
        subject: undefined,
        emailText: undefined,
      },
      alertData: {
        text: undefined,
        daysAfter: undefined,
      },
      requestData: {
        url: undefined,
        method: undefined,
        headers: [],
        body: undefined,
      },
      headerTitle: undefined,
      headerValue: undefined,
      text: undefined,
      chosenOperator: undefined,
      chosenStatus: undefined,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Removed a chip
     * @param {Object} item Item to remove.
     */
    remove(item, subject) {
      const index = this[subject].indexOf(item)
      if (index >= 0) this[subject].splice(index, 1)
    },
    /**
     * Sets the data in the parent variable.
     * @param {any} data Data to set.
     */
    setData(data) {
      this.$emit('setData', data)
    },
    /**
     * Sets an email in the input and in the parent variable.
     * @param {String} data Email to set.
     */
    setEmailData(data, key) {
      this.$set(this.emailData, key, data)
      this.$emit('setData', this.emailData)
    },
    /**
     * Sets the alert data in the input and in the parent variable.
     * @param {String} data Email to set.
     */
    setAlertData(data, key) {
      this.$set(this.alertData, key, data)
      this.$emit('setData', this.alertData)
    },
    /**
     * Sets the request data in the input and in the parent variable.
     * @param {String} data Email to set.
     */
    setRequestData(data, key) {
      this.$set(this.requestData, key, data)

      const requestData = {
        ...this.requestData,
        body: this.requestData.body ?? '',
      }

      this.$emit('setData', requestData)
    },
    /**
     * Sets an operator in the input and in the parent variable.
     * @param {Array} operators selected.
     */
    setOperator(data) {
      this.$emit('setData', data)
    },
    /**
     * Sets the new status.
     * @param {String} data Status selected.
     */
    setStatus(data) {
      this.$emit('setData', data)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterOperators(item, queryText) {
      const name = item.name.toLowerCase() + ' ' + item.surname.toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText)
    },
    /**
     * Adds a header into the list and resets the inputs
     */
    addHeader() {
      if (!this.headerTitle || !this.headerValue) return

      this.requestData.headers.push({
        headerTitle: this.headerTitle,
        headerValue: this.headerValue,
      })
      this.headerTitle = undefined
      this.headerValue = undefined
    },
    /**
     * Removes a header.
     * @param {int} index Index to remove.
     */
    removeHeader(index) {
      this.requestData.headers.splice(index, 1)
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser', 'isOperatorUser']),
    hasToBeShort() {
      return (
        (this.fieldIndex % 2 === 0 &&
          this.fieldIndex + 1 < this.totalAdditionalFields) ||
        (this.fieldIndex % 2 !== 0 &&
          this.fieldIndex + 1 <= this.totalAdditionalFields)
      )
    },
    possibleOperators() {
      if (
        !this.chosenConditions?.includes('newNotificationByFilter') &&
        !this.chosenConditions?.includes('newNotificationByCifs')
      )
        return this.operators

      if (
        (!this.chosenConditions?.includes('newNotificationByFilter') ||
          this.conditionsAdditionalData.newNotificationByFilter?.length ===
            0) &&
        (!this.chosenConditions?.includes('newNotificationByCifs') ||
          this.conditionsAdditionalData?.newNotificationByCifs?.length === 0)
      )
        return []

      let operators = [...this.operators]

      operators = operators.filter(
        (operator) =>
          operatorHasCifs(
            operator,
            this.conditionsAdditionalData['newNotificationByCifs']
          ) &&
          operatorHasFilterById(
            operator,
            this.conditionsAdditionalData['newNotificationByFilter'],
            this.filters
          )
      )

      return operators
    },
  },
  watch: {
    chosenTarget(newValue) {
      this.inputType = newValue.inputType
    },
    'conditionsAdditionalData.newNotificationByFilter': {
      handler: function () {
        this.chosenOperator = undefined
      },
      deep: true,
    },
    'conditionsAdditionalData.newNotificationByCifs': {
      handler: function () {
        this.chosenOperator = undefined
      },
      deep: true,
    },
  },
}
</script>
