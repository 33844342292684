<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="secondary" v-bind="attrs" v-on="on">
        <v-badge
          v-if="unreadNews.length"
          color="white--text warningLow"
          overlap
          :content="unreadNews.length"
        >
          <v-icon id="news-menu">
            {{ icon }}
          </v-icon>
        </v-badge>
        <v-icon v-else id="news-menu">
          {{ icon }}
        </v-icon>

        <span>{{ $t(`navigationPage.${title}`) }}</span>
      </v-btn>
    </template>
    <v-card class="mx-auto" max-width="700" min-width="400" v-if="news.length">
      <v-expansion-panels
        style="max-height: 700px"
        class="overflow-y-auto"
        accordion
      >
        <v-expansion-panel v-for="(item, index) in news" :key="index">
          <v-expansion-panel-header @click="markAsRead(item)">
            <v-container>
              <v-row justify="space-between">
                <v-col cols="auto">
                  <strong> {{ item.title }} </strong>
                  <v-icon
                    v-if="item.usersRead.indexOf($store.state.user.id) == -1"
                    class="blue--text ml-2"
                    small
                  >
                    mdi-circle
                  </v-icon>
                </v-col>
                <v-col cols="auto">
                  <span>{{ $d(item.releaseDate) }}</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  {{ item.body.slice(0, 100) }}
                  <v-icon> mdi-menu-down </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span style="white-space: pre-line"> {{ item.body }} </span>
            <v-btn
              v-if="item.url"
              class="ml-2"
              x-small
              outlined
              :href="item.url"
              target="_blank"
            >
              {{ $t('seeMore') }}
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card class="mx-auto" max-width="auto" v-else>
      <strong class="font-italic">{{ $t('noNews') }}</strong>
    </v-card>
  </v-menu>
</template>

<script>
import { markAsRead } from '@/services/news-service'
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  methods: {
    async markAsRead(item) {
      await markAsRead(item.id)
    },
  },
  computed: {
    news() {
      return this.$store.state.news
    },
    unreadNews() {
      var context = this
      return this.$store.state.news.filter(function (el) {
        return el.usersRead.indexOf(context.$store.state.user.id) == -1
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
