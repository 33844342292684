<template>
  <v-col style="max-width: 1200px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.configuration.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.configuration.licenses.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.configuration.licenses.paragraph1') }}
    </p>
    <p></p>
    <v-img style="width: 100%; max-width: 1200px" contain :src="pic1Name" />
    <p></p>
    <p>{{ $t('documentation.texts.configuration.licenses.paragraph2') }}</p>
    <ul>
      <li>
        -
        <b>
          {{ $t('documentation.texts.configuration.licenses.li1Title') }}
        </b>
        : {{ $t('documentation.texts.configuration.licenses.li1Text') }}
      </li>
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.configuration.licenses.li2Title') }}
        </b>
        : {{ $t('documentation.texts.configuration.licenses.li2Text') }}
      </li>
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.configuration.licenses.li3Title') }}
        </b>
        : {{ $t('documentation.texts.configuration.licenses.li3Text') }}
      </li>
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.configuration.licenses.li4Title') }}
        </b>
        : {{ $t('documentation.texts.configuration.licenses.li4Text') }}
      </li>
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.configuration.licenses.li5Title') }}
        </b>
        : {{ $t('documentation.texts.configuration.licenses.li5Text') }}
      </li>
      <p></p>
    </ul>
    <p>
      {{ $t('documentation.texts.configuration.licenses.paragraph3') }}
    </p>
    <br />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdminUser']),
    pic1Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.licenses.pic1') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
