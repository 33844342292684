import {
  Firestore,
  ShareCodesCollection,
  ShareCodesDoc,
  GetSharedNotification,
  UsersDoc,
} from '@/firebase-exports'
import store from '@/store/index'
import { getUserWithRef } from './user-service'

/**
 * Crea un código de compartición.
 *
 * Esta función crea un código de compartición que se puede utilizar para compartir una notificación con otros usuarios.
 * El código de compartición se almacena en la colección de códigos de compartición en Firestore.
 *
 * @param {string} notificationId - El ID de la notificación que se va a compartir.
 * @param {string} receiverEmail - El correo electrónico del receptor al que se va a enviar la notificación compartida.
 * @param {string} messageText - El texto del mensaje que se va a enviar junto con la notificación compartida.
 *
 * @returns {Promise} Retorna una promesa que se resuelve con el documento recién creado en Firestore.
 *
 * @throws {Error} Lanza un error si no se puede crear el código de compartición.
 */
export async function createShareCode(
  notificationId,
  receiverEmail,
  messageText
) {
  // En el caso de ser operador siempre será el id del parent
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  return await Firestore.addDoc(ShareCodesCollection(userId, notificationId), {
    valid: true,
    createdAt: Firestore.serverTimestamp(),
    accessCounter: 0,
    receiverEmail: receiverEmail.join(', '),
    messageText,
    userRef: UsersDoc(store.state.user.id),
  })
}

/**
 * Crea un enlace de código de compartición para una notificación.
 *
 * Esta función genera un enlace de código de compartición que se puede utilizar para compartir acceso a una notificación específica.
 *
 * @param {string} notificationId - El ID de la notificación que se va a compartir.
 * @param {string} shareIdentifier - Identificador único para el enlace de compartición.
 * @returns {Promise<Object>} Una promesa que se resuelve con el documento recién creado en Firestore.
 */
export async function createShareCodeLink(notificationId, shareIdentifier) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  return await Firestore.addDoc(ShareCodesCollection(userId, notificationId), {
    valid: true,
    createdAt: Firestore.serverTimestamp(),
    accessCounter: 0,
    shareIdentifier,
    userRef: UsersDoc(store.state.user.id),
  })
}

/**
 * Lista los códigos de compartición asociados a una notificación.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} notificationId - El ID de la notificación.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de códigos de compartición.
 */
export async function listShareCodes(userId, notificationId) {
  const querySnap = await Firestore.getDocs(
    ShareCodesCollection(userId, notificationId)
  )
  return querySnap.docs
    .map((doc) => {
      const document = doc.data()
      if (document.createdAt) document.createdAt = document.createdAt.toDate()
      return { id: doc.id, ...document }
    })
    .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds)
}

/**
 * Crea una subscripción a los códigos de compartición de una notificación, actualizando el contexto con los cambios en tiempo real.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} notificationId - El ID de la notificación.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function listShareCodesSubscription(
  userId,
  notificationId,
  context
) {
  var shareCodesUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      ShareCodesCollection(userId, notificationId),
      Firestore.orderBy('createdAt', 'asc')
    ),
    async (querySnapshot) => {
      context.shared = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          if (document.createdAt)
            document.createdAt = document.createdAt.toDate()
          if (document.userRef)
            document.user = await getUserWithRef(document.userRef)
          return { id: doc.id, ...document }
        })
      )
      if (context.loadingShareCodes) context.loadingShareCodes = false
    },
    (error) => {
      throw error
    }
  )
  return shareCodesUnsubscribe
}

/**
 * Elimina un código de compartición específico asociado a una notificación.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} notificationId - El ID de la notificación.
 * @param {string} shareCode - El ID del código de compartición a eliminar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando el código de compartición ha sido eliminado.
 */
export async function deleteShareCode(userId, notificationId, shareCode) {
  return await Firestore.deleteDoc(
    ShareCodesDoc(userId, notificationId, shareCode)
  )
}

/**
 * Recupera una notificación compartida utilizando un código de compartición.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} notificationId - El ID de la notificación.
 * @param {string} shareCode - El código de compartición.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos de la notificación compartida.
 */
export async function getNotificationFromSharedCode(
  userId,
  notificationId,
  shareCode
) {
  return (await GetSharedNotification({ userId, notificationId, shareCode }))
    .data
}

/**
 * Actualiza un código de compartición específico asociado a una notificación.
 *
 * @param {string} notificationId - El ID de la notificación.
 * @param {string} shareCodeId - El ID del código de compartición a actualizar.
 * @param {Object} updateData - Los datos de actualización para el código de compartición.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta de la actualización.
 */
export async function updateShareCode(notificationId, shareCodeId, updateData) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id
  var response = await Firestore.updateDoc(
    ShareCodesDoc(userId, notificationId, shareCodeId),
    updateData
  )
  return response
}
