<template>
  <v-row cols="12">
    <v-col cols="12">
      <v-file-input
        ref="fileInput"
        multiple
        dense
        :clearable="false"
        truncate-length="15"
        @change="attachFiles"
        :placeholder="$t('uploadFiles')"
        :label="$t('uploadFiles')"
        :disabled="disabled"
        :error-messages="errors"
      >
        <!-- :rules="applyRules ? fileRules : noRules" -->
        <template v-slot:append>
          {{ $t('numberAttached') + ': ' + `${filesAttachedChild.length}` }}
        </template>
        <template v-slot:selection></template>
      </v-file-input>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <span v-if="countToSign">
        <b>{{ $t('documentsToSign') }} </b>
        <span class="subtitle-2 font-weight-light"> ({{ countToSign }}) </span>
      </span>
      <ul>
        <li
          v-for="(file, index) in filesAttachedChild"
          :key="index"
          class="pa-0 ma-0"
        >
          <v-row
            v-if="filesAttachedChild[index].needSignature"
            align="center"
            justify="center"
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col class="ma-0" cols="5">
              <i
                ><b>{{ file.name }}</b></i
              >
            </v-col>
            <v-col cols="5">
              <v-checkbox
                class="pa-0 ma-0"
                dense
                hide-details
                :label="$t('documentToSign')"
                v-model="filesAttachedChild[index].needSignature"
                :disabled="disabled"
                @click="changeNeeds(index)"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon class="ma-0">
                    <v-icon
                      class="pa-0 ma-0"
                      v-bind="attrs"
                      v-on="on"
                      id="delete-file"
                      :disabled="disabled"
                      @click.stop="deleteFile(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </template>
                <div>
                  {{ $t('deleteFile') }}
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </li>
      </ul>
      <br />
      <span v-if="countAnnexed">
        <b>{{ $t('attachedFiles') }}</b>
        <span class="subtitle-2 font-weight-light"> ({{ countAnnexed }}) </span>
      </span>
      <ul>
        <li
          v-for="(file, index) in filesAttachedChild"
          :key="index"
          class="pa-0 ma-0"
        >
          <v-row
            v-if="!filesAttachedChild[index].needSignature"
            align="center"
            justify="center"
            class="pt-0 ma-0"
            no-gutters
          >
            <v-col class="ma-0" cols="5">
              <i>
                <b> {{ file.name }}</b>
              </i>
            </v-col>
            <v-col cols="5">
              <v-checkbox
                v-if="filesAttachedChild[index].mimeType === 'application/pdf'"
                class="pa-0 ma-0"
                dense
                hide-details
                :label="$t('documentToSign')"
                v-model="filesAttachedChild[index].needSignature"
                :disabled="disabled"
                @click.prevent="changeNeeds(index)"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon class="ma-0">
                    <v-icon
                      class="pa-0 ma-0"
                      v-bind="attrs"
                      v-on="on"
                      id="delete-file"
                      :disabled="disabled"
                      @click.stop="deleteFile(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </template>
                <div>
                  {{ $t('deleteFile') }}
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </li>
      </ul>
    </v-col>
    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <v-container>
            <v-row justify="space-between" align="center">
              <span>
                {{ $t('confirmDeleteTitle') }}
              </span>
              <v-btn fab @click="dialogDelete = false" small plain>
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text> {{ $t('confirmDeleteText') }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete()" color="primary" text>
            {{ $t('confirmDelete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    alreadyUploadedFiles: Array,
    applyRules: Boolean,
    disabled: Boolean,
    errors: [],
  },
  components: {},
  async mounted() {
    if (this.alreadyUploadedFiles)
      this.filesAttachedChild = [...this.alreadyUploadedFiles]
  },
  data() {
    return {
      dialogDelete: false,
      filesAttachedChild: [],
      documentsContent: [],
      fileRules: [() => this.filesAttachedChild.length !== 0],
      noRules: [],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    changeNeeds(index) {
      if (this.filesAttachedChild[index].mimeType === 'application/pdf') {
        !this.filesAttachedChild[index].needSignature
        this.$emit('update-files-attached', this.filesAttachedChild)
      }
    },
    truncateText(text) {
      const extensionStart = text.lastIndexOf('.')
      const extension = text.substring(extensionStart)
      if (text.length - extension.length <= 12) return text
      return text.substring(0, 20 - extension.length) + '...' + extension
    },
    deleteFile(index) {
      this.deleteIndex = index
      this.dialogDelete = true
    },
    confirmDelete() {
      // Eliminar el archivo seleccionado
      if (this.filesAttachedChild[this.deleteIndex].alreadyUploadedToDMS) {
        this.filesAttachedChild[this.deleteIndex].deleteFromDMS = true
        this.filesAttachedChild[this.deleteIndex].needSignature = false
      }
      this.$emit('update-files-attached', this.filesAttachedChild)
      this.filesAttachedChild.splice(this.deleteIndex, 1)
      // Cerrar el diálogo de confirmación
      this.dialogDelete = false
    },
    cancelDelete() {
      // Cancelar la eliminación del archivo seleccionado
      this.dialogDelete = false
      this.deleteIndex = null
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const fileInfo = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            base64: e.target.result.split(',')[1],
          }
          this.filesAttachedChild.push({
            name: fileInfo.name,
            base64: fileInfo.base64,
            mimeType: fileInfo.mimeType,
            addedDocument: false,
            alreadyUploadedToDMS: false,
            needSignature:
              fileInfo.mimeType === 'application/pdf' ? true : false,
          })
          resolve(this.filesAttachedChild)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async attachFiles() {
      await Promise.all(
        this.$refs.fileInput.internalValue.map(async (element) => {
          await this.toBase64(element)
        })
      )

      // if (!arr.every((obj) => !/\s/.test(obj.name)));

      this.filesAttachedChild = this.filesAttachedChild.map((document) => {
        return { ...document, name: document.name.replace(/\s/g, '_') }
      })
      this.$emit('update-files-attached', this.filesAttachedChild)

      await new Promise((resolve) => setTimeout(resolve, 1))
      window.scrollTo(0, document.body.scrollHeight)
    },
  },
  computed: {
    fileNames() {
      return this.files.map((file) => file.name).join(', ')
    },
    countToSign() {
      return this.filesAttachedChild.filter((f) => f.needSignature === true)
        .length
    },
    countAnnexed() {
      return this.filesAttachedChild.filter((f) => f.needSignature === false)
        .length
    },
  },
  watch: {
    needSignature(value) {
      this.infoType = !value
    },
    infoType(value) {
      this.needSignature = !value
    },
    files(value) {
      this.files = value
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-file-input__text {
  display: none;
}
ul {
  list-style-type: none;
}
</style>
