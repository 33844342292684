import { listDefaultFilters } from '@/services/filter-service'

const fake = false

const fakeEmitters = {}

export async function loadAllRootEmitters() {
  if (fake) {
    return fakeEmitters
  }
  let emitters = {}
  let filters = (await listDefaultFilters()).filter(function (filter) {
    return filter.name != 'noFilter'
  })

  filters.forEach((filter) => {
    filter.sourceEntityAllowed.forEach((entityAllowed) => {
      emitters[entityAllowed] = filter.name
    })
  })
  return emitters
}
