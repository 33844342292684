<template>
  <v-container>
    <div>
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <p></p>
      <h2>
        <strong>¿Quién es el Responsable de Tratamiento de sus datos?</strong>
      </h2>
      <p>
        El Responsable de Tratamiento de sus datos es
        <strong>Addalia Technology, S.L. </strong>(en adelante El Prestador) con
        domicilio en C/ Agustín de Foxá, 20 – 28036 Madrid, teléfono de contacto
        91.033.93.29, con CIF B-84606649, e inscrita en el Registro Mercantil de
        Madrid, tomo 22.779, folio 114, sección 8, hoja M-407.678, inscripción
        5. En cumplimiento de la regulación vigente en materia de Protección de
        Datos, informa que se compromete con el respeto y protección de los
        datos de carácter personal y la intimidad del usuario.
      </p>
      <p></p>
      <h2>
        <strong>¿Con qué finalidad tratamos sus datos personales?</strong>
      </h2>
      <p>
        En<strong> ADDALIA</strong> tratamos la información que nos facilitan
        las personas interesadas con el fin de gestionar nuestra relación
        comercial y mejorar su experiencia de usuario, en concreto:
      </p>
      <p>
        Tramitar pedidos, encargos, presupuestos o cualquier otro tipo de
        petición que sea solicitada por el usuario a través de la página web, o
        vía telefónica.
      </p>
      <p>
        Envío de publicidad o promociones, electrónicas o en formato físico,
        relacionadas con los productos y/o servicios ofertados en el sitio web,
        y/o los contratados por el usuario.
      </p>
      <p>
        Envío de boletines de noticias de la empresa, en la que se informará
        sobre novedades del sector concreto al que se dedica el prestador,
        acuerdos estratégicos con terceras empresas y nuevos productos
        ofertados.
      </p>
      <p>
        Para ello, elaboraremos un “perfil comercial”, en base a la información
        facilitada. No se tomarán decisiones automatizadas en base a dicho
        perfil.
      </p>
      <p></p>
      <h2><strong>¿Durante cuánto tiempo conservaremos sus datos?</strong></h2>
      <p>
        Los datos personales proporcionados se conservarán mientras se mantenga
        la relación mercantil y no se solicite su supresión por el interesado.
      </p>
      <p></p>
      <h2>
        <strong>
          ¿Cuál es la legitimación para el tratamiento de sus datos?
        </strong>
      </h2>
      <p>
        La base legal para el tratamiento de sus datos es la materialización de
        la relación comercial y/o la ejecución del contrato de relación
        comercial entre usted y <strong>ADDALIA</strong>.
      </p>
      <p>
        Por otro lado, la oferta prospectiva de productos y servicios está
        basada en el consentimiento que se le solicita, sin que en ningún caso
        la retirada de este consentimiento condicione la ejecución de los
        contratos suscritos entre ambas partes.
      </p>
      <p></p>
      <h2><strong>¿A qué destinatarios se comunicarán sus datos?</strong></h2>
      <p>
        Los datos se comunicarán únicamente a los trabajadores y departamentos
        de
        <strong>ADDALIA</strong> para fines administrativos internos, incluido
        el tratamiento de datos personales de clientes, proveedores o empleados.
      </p>
      <p>
        No se cederán datos a terceros. En caso de que, de forma puntual, sea
        necesario ceder información a terceros, se solicitará un consentimiento
        para cada caso concreto.
      </p>
      <p></p>
      <h2>
        <strong>¿Cuáles son sus derechos cuando nos facilita sus datos?</strong>
      </h2>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si en
        <strong>ADDALIA</strong> estamos tratando datos personales que les
        conciernan, o no.
      </p>
      <p>
        Las personas interesadas tienen derecho a si acceder a sus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
      </p>
      <p>
        En determinadas circunstancias, los interesados podrán solicitar la
        limitación del tratamiento de sus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones.
      </p>
      <p>
        En determinadas circunstancias y por motivos relacionados con su
        situación particular, los interesados podrán oponerse al tratamiento de
        sus datos.
        <strong>ADDALIA</strong> dejará de tratar los datos, salvo por motivos
        legítimos imperiosos, o el ejercicio o la defensa de posibles
        reclamaciones.
      </p>
      <p>
        En cumplimiento de lo establecido en la legislación vigente sobre
        Protección de Datos de Carácter Personal, le informamos que puede
        ejercer sus derechos de acceso, rectificación, cancelación, oposición o
        supresión de sus datos de carácter personal en la siguiente dirección:
      </p>
      <p><strong>Addalia Technology, S.L.</strong></p>
      <p>C/ Agustín de Foxá, 20 - 28036 Madrid,</p>
      <p>E-mail: <a href="mailto:rgpd@addalia.com">rgpd@addalia.com</a></p>
      <p>Teléfono de contacto: 910 33 93 29</p>
      <p></p>
      <h2><strong>¿Cómo hemos obtenido sus datos?</strong></h2>
      <p>
        Los datos personales que tratamos en <strong>ADDALIA</strong> proceden
        de usted.
      </p>
      <p>Las categorías de datos que se tratan son:</p>
      <ul>
        <li>Datos de identificación</li>
        <li>Códigos o claves de identificación</li>
        <li>Direcciones postales o electrónicas</li>
        <li>Información comercial</li>
        <li>Datos económicos</li>
      </ul>
      <p></p>
      <p>No se tratan datos especialmente protegidos.</p>
    </div>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
h1,
h2 {
  color: var(--v-primary-base);
}
</style>
