<template>
  <v-card min-height="600" elevation="5" class="rounded-b-xl">
    <v-container data-v-step="101">
      <v-row>
        <v-col class="text-right" cols="12">
          <v-tabs :color="tabColor" v-model="tab" fixed-tabs>
            <v-tab v-show="toAccept.length > 0" id="to-accept-tab-dashboard">
              <strong color="red">
                <b> {{ $t('toAccept') }} </b>
              </strong>
            </v-tab>
            <v-tab>{{ $t('pending') }}</v-tab>
            <v-tab>{{ $t('urgent') }}</v-tab>
            <v-tab>{{ $t('expired') }}</v-tab>
            <v-tab>{{ $t('accepted') }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <ManagerNotificationsToAccept
        v-if="tab === 0 && toAccept"
        :notificationList="toAccept"
      />
      <ManagerListElement
        v-if="tab === 1 && pending"
        :notificationList="pending"
      />
      <ManagerListElement
        v-if="tab === 2 && urgent"
        :notificationList="urgent"
      />
      <ManagerListElement
        v-if="tab === 3 && expired"
        :notificationList="expired"
      />
      <ManagerListElement
        v-if="tab === 4 && accepted"
        :notificationList="accepted"
      />
    </v-container>
  </v-card>
</template>
<script>
import { getNotificationStatus } from '@/services/notification-service'
import ManagerListElement from '@/components/dashboard/dashboardElements/notifications/ManagerListElement'
import ManagerNotificationsToAccept from '@/components/dashboard/dashboardElements/notifications/ManagerNotificationsToAccept'

export default {
  components: {
    ManagerListElement,
    ManagerNotificationsToAccept,
  },
  props: {
    toAccept: {
      type: Array,
    },
    expired: {
      type: Array,
    },
    pending: {
      type: Array,
    },
    urgent: {
      type: Array,
    },
    accepted: {
      type: Array,
    },
  },
  watch: {
    toAccept(newValue) {
      if (newValue && this.isFirstLoad) {
        this.isFirstLoad = false
        this.onToAcceptLoaded()
      }
    },
  },
  mounted() {
    this.tab = this.toAccept.length > 0 ? 0 : 2
    if (this.toAccept.length) {
      this.isFirstLoad = false
      this.onToAcceptLoaded()
    }
  },
  data() {
    return {
      tab: 2,
      isFirstLoad: true, // flag para controlar una unica asignacion
    }
  },
  computed: {
    tabColor() {
      switch (this.tab) {
        case 0:
          return 'warningLow'
        case 1:
          return 'warningLow'
        case 2:
          return 'warning'
        case 3:
          return 'red'
        case 4:
          return 'accept'
        default:
          return 'accept'
      }
    },
  },
  methods: {
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    onToAcceptLoaded() {
      //console.log('toAccept has been loaded:', this.toAccept.length)
      // ejecuta el código una vez que la propiedad this.toAccept esté cargada, en mounted() aun no lo está
      this.tab = this.toAccept.length > 0 ? 0 : 2
    },
  },
}
</script>

<style lang="scss" scoped></style>
