<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <SignatureStats
              :signedCount="signedSignatures.length"
              :deferredCount="deferredSignatures.length"
              :urgentCount="urgentSignatures.length"
              :rejectedCount="rejectedSignatures.length"
              :pendingCount="pendingSignatures.length"
              :abandonedCount="abandonedSignatures.length"
              :signaturesUsed="signaturesUsed"
              :signaturesMax="signaturesMax"
              class="mb-4"
            >
            </SignatureStats>
            <SignaturesList
              :signed="signedSignatures.slice(0, 7)"
              :deferred="deferredSignatures.slice(0, 7)"
              :urgent="urgentSignatures.slice(0, 7)"
              :rejected="rejectedSignatures.slice(0, 7)"
              :pending="pendingSignatures.slice(0, 7)"
              :abandoned="abandonedSignatures.slice(0, 7)"
              :incidence="incidenceSignatures.slice(0, 7)"
              class="mt-4"
            >
            </SignaturesList>
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <AlertStats
              assetType="signatures"
              :totalCount="withAlertSignatures.length"
              :urgentCount="withAlertUrgentSignatures.length"
              :pendingCount="withAlertPendingSignatures.length"
              :expiredCount="withAlertExpiredSignatures.length"
              class="mb-4"
            >
            </AlertStats>
            <AlertsList
              AlertListElement="AlertListElementSignatures"
              :urgent="withAlertUrgentSignatures"
              :expired="withAlertExpiredSignatures"
              :pending="withAlertPendingSignatures"
              class="mt-4"
            >
            </AlertsList>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignatureStats from '@/components/dashboard/dashboardElements/signatures/SignatureStats'
import SignaturesList from '@/components/dashboard/dashboardElements/signatures/SignaturesList'
import AlertStats from '@/components/dashboard/dashboardElements/common/AlertStats'
import AlertsList from '@/components/dashboard/dashboardElements/common/AlertsList'
import { getAlertStatus } from '@/services/alert-service'
import { getSignatureStatus } from '@/services/signatures-service'

export default {
  components: {
    SignatureStats,
    SignaturesList,
    AlertStats,
    AlertsList,
  },
  computed: {
    signatures() {
      return this.$store.state.signatures
    },
    withAlertSignatures() {
      return this.signatures.filter((signature) => !!signature.alert)
    },
    withAlertUrgentSignatures() {
      return this.signatures.filter(
        (signature) =>
          getAlertStatus(signature.alert).status == 'alertStatus.urgent'
      )
    },
    withAlertPendingSignatures() {
      return this.signatures.filter(
        (signature) =>
          getAlertStatus(signature.alert).status == 'alertStatus.pending'
      )
    },
    withAlertExpiredSignatures() {
      return this.signatures.filter(
        (signature) =>
          getAlertStatus(signature.alert).status == 'alertStatus.expired'
      )
    },
    signedSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.signed'
      )
    },
    deferredSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.deferred'
      )
    },
    urgentSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.urgent'
      )
    },
    rejectedSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.rejected'
      )
    },
    pendingSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.pending'
      )
    },
    abandonedSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.abandoned'
      )
    },
    incidenceSignatures() {
      return this.signatures.filter(
        (signature) =>
          getSignatureStatus(signature).status == 'signatureStatus.incidence'
      )
    },
    signaturesUsed() {
      return this.$store.state.plan?.signaturesUsed
    },
    signaturesMax() {
      return this.$store.state.plan?.signaturesMax
    },
  },
  methods: {
    compareSignatureDate(a, b) {
      if (new Date(a.createdAt) < new Date(b.createdAt)) {
        return -1
      }
      if (new Date(a.createdAt) > new Date(b.createdAt)) {
        return 1
      }
      return 0
    },
    compareAlertDate(a, b) {
      if (new Date(a.alert.date) < new Date(b.alert.date)) {
        return -1
      }
      if (new Date(a.alert.date) > new Date(b.alert.date)) {
        return 1
      }
      return 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
