<template>
  <v-card elevation="10" class="pt-4">
    <v-row>
      <v-col>
        <v-card-title> {{ $t('shared') }} </v-card-title>
      </v-col>
      <v-col>
        <v-card-subtitle align="right">
          <strong>
            <v-icon @click="changeOrder()"> mdi-swap-vertical-bold </v-icon>
          </strong>
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-list style="height: 628px; overflow-y: scroll; overflow-x: hidden">
      <v-list-item v-for="item in sharedComputed" v-bind:key="item.id">
        <template>
          <v-row>
            <v-col cols="12" class="mb-6">
              <v-row>
                <v-col class="mb-0 pb-0" cols="12">
                  <span v-if="item.receiverEmail">
                    <b>{{ $t('email') }}:</b>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.receiverEmail.length > 40
                              ? item.receiverEmail.substring(0, 40) + '...'
                              : item.receiverEmail
                          }}
                        </span>
                      </template>
                      <span>{{ item.receiverEmail }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="item.shareIdentifier">
                    <b>{{ $t('shareIdentifier') }}:</b>
                    {{ item.shareIdentifier }}
                  </span>
                </v-col>
                <v-col class="mt-0 pt-0" cols="7">
                  <div>
                    <b>{{ $t('dispatchDate') }}:</b>
                    {{ $d(item.createdAt) }}
                  </div>
                  <div v-if="item.user" class="mb-0">
                    <b>{{ $t('sharedBy') }}:</b>
                    {{ `${item.user.name} ${item.user.surname}` }}
                  </div>
                  <div class="mb-0">
                    <b>{{ $t('accessCounter') }}:</b> {{ item.accessCounter }}
                    <br />
                    <v-switch
                      v-model="item.valid"
                      :label="`${$t(item.valid ? 'enabled' : 'disabled')}`"
                      dense
                      class="pa-0 ma-0"
                      @click="updateCode(item)"
                    ></v-switch>
                    <v-btn
                      v-if="item.shareIdentifier"
                      @click="copyURL(item)"
                      class="pa-0 ma-0"
                      text
                      dense
                    >
                      {{ $t('copyClipboard') }}
                      <v-icon class="ml-1" small> mdi-content-copy </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <v-divider></v-divider>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateShareCode } from '@/services/share-codes-service'

export default {
  props: {
    shared: Array,
    loadingShareCodes: Boolean,
    notificationId: String,
  },
  data() {
    return {
      orderFlag: false,
    }
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    sharedComputed() {
      var sortedShared = [...this.shared]
      return this.orderFlag
        ? sortedShared.sort((a, b) => a.createdAt - b.createdAt)
        : sortedShared.sort((a, b) => b.createdAt - a.createdAt)
    },
  },
  methods: {
    changeOrder() {
      this.orderFlag = !this.orderFlag
    },
    ...mapMutations(['setSnackbar']),
    updateCode(item) {
      updateShareCode(this.notificationId, item.id, { valid: item.valid })
    },
    async copyURL(item) {
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id
      try {
        await navigator.clipboard.writeText(
          window.location.origin +
            '/sharedNotification/' +
            userId +
            '/' +
            this.notificationId +
            '/' +
            item.id
        )

        const message = this.$t('sharedLinkClipboardSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('sharedLinkClipboardError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>
