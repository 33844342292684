<template>
  <v-container>
    <v-row v-if="isSuperAdminUser && pdfRef" align="center">
      <v-col class="d-flex align-center">
        <span class="headline mb-1 mr-4">{{ $t('legal.termsOfService') }}</span>
        <v-btn @click="showPdf()" color="primary">
          {{ $t('legal.termsPdfView') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFileURL } from '@/services/storage-service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdminUser', 'isSuperAdminUser', 'isTermsAccepted']),
  },
  data() {
    return {
      pdfRef: '',
      pdfURL: '',
    }
  },
  async mounted() {
    this.pdfRef = this.$store.state.user?.legal?.contractPdfRef
    this.pdfURL = this.pdfRef ? await getFileURL(this.pdfRef) : ''
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async showPdf() {
      //console.log('pdfURL=' + this.pdfURL)
      if (this.pdfURL) {
        window.open(this.pdfURL, '_blank')
      } else {
        const message = this.$t('legal.termsPdfNotFound')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>
