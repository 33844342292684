import { Firestore, BillingDoc } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Obtiene la información de facturación.
 *
 * @returns {Promise} Una promesa que se resuelve con la información de facturación si existe.
 */
export async function getBilling() {
  const docSnap = await Firestore.getDoc(BillingDoc())
  if (!docSnap.exists()) return

  const billing = docSnap.data()

  // Guardamos en el store solo el atributo que necesitamos en esta app de cliente
  store.commit('setAppBilling', {
    consumoLicenciasCifGD: billing.consumoLicenciasCifGD
      ? billing.consumoLicenciasCifGD
      : 20,
  })
  // no devolvemos nada
  //return { ...document }
}
