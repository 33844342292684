<template>
  <v-container>
    <v-dialog v-model="dialog" persistent width="700" max-width="700">
      <div class="shake-wrapper">
        <v-card ref="shakeCard" class="custom-card">
          <v-card-title class="custom-card-title">
            <span class="headline">{{ $t('toAcceptDialogTitle') }}</span>
          </v-card-title>

          <v-card-text class="mt-4 custom-card-text">
            <v-row align="center" style="height: auto" min-height="180px">
              <v-col cols="auto" class="r-1">
                <v-icon large color="yellow">mdi-alert</v-icon>
              </v-col>
              <v-col>
                <span class="mt-4 l-0 headline custom-message-text">
                  {{ $t('toAcceptDialogMsg', { count: toAccept.length }) }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-4 custom-card-actions">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog">
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    toAccept: {
      type: Array,
    },
    shakeEnabled: {
      type: Boolean,
      default: true, // Por defecto, la sacudida está activada
    },
  },
  data() {
    return {
      dialog: false, // Inicialmente el diálogo está cerrado
    }
  },
  mounted() {
    // Abrimos el diálogo cuando el componente se monta
    this.dialog = true
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.applyShake()
          }, 100) // Agregar un pequeño retraso para asegurarse de que el diálogo está completamente renderizado
        })
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    applyShake() {
      this.$nextTick(() => {
        const shakeElement = this.$refs.shakeCard
          ? this.$refs.shakeCard.$el
          : null
        if (this.shakeEnabled && shakeElement && shakeElement.classList) {
          shakeElement.classList.remove('shake')
          void shakeElement.offsetWidth // Forzar el reflow
          shakeElement.classList.add('shake')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-card {
  background-color: #2d2d2d; /* Fondo gris oscuro */
  //background-color: #3a3a3a; /* Fondo un poco más claro */
  color: white; /* Texto blanco */
  overflow-x: hidden; /* Ocultar el desbordamiento horizontal */
}

.custom-card-title {
  background-color: #2d2d2d; /* Fondo gris oscuro */
  color: white;
  font-weight: bold;
}

.custom-card-text {
  background-color: #2d2d2d; /* Fondo gris oscuro */
  color: #c0c0c0; /* Texto gris claro */
}

.custom-message-text {
  color: #c0c0c0; /* Texto gris claro */
}

.custom-card-actions {
  background-color: #3a3a3a; /* Fondo un poco más claro para las acciones */
}

.shake {
  animation: shake 0.5s;
}

.shake-wrapper {
  overflow-x: hidden; /* Ocultar desbordamiento horizontal del contenedor (scrollbar horizontal) */
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
</style>
