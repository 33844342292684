<template>
  <div id="map" :style="styleData"></div>
</template>
<script>
import L from 'leaflet'

export default {
  components: {},
  props: {
    title: String,
    height: String,
    dataLayers: [],
  },
  data() {
    //var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    //console.log('layers:', this.dataLayers)
    return {
      styleData: {
        width: '100%',
        height: parseInt(this.height) + 'px',
        'max-height': parseInt(this.height) + 'px;',
      },
      map: null,
      endIconUrl: '&chf=a,s,ee00FFFF',
      tileLayer: null,
      markers: [],
      customIcon: {
        iconUrl:
          'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|',
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [25, 41], // size of the icon
        iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
        popupAnchor: [1, -34], // point of the icon which will correspond to popup's location
        tooltipAnchor: [16, -28], // point of the icon which will correspond to tooltip's location
        shadowSize: [41, 41], // size of the icon used for shadow
      },
      emptyFeature: {
        id: 0,
        name: '',
        type: '',
        coords: [],
      },
    }
  },

  // computed: {
  //   layers() {
  //     return this.dataLayers
  //   },
  // },
  methods: {
    initLayers() {
      // console.log('empiezo:', new Date().getTime())
      for (var i = 0; i < this.markers.length; i++) {
        this.map.removeLayer(this.markers[i])
      }
      var layersCopy = JSON.parse(JSON.stringify(this.dataLayers))
      layersCopy[0].features.forEach((feature) => {
        var cIcon = JSON.parse(JSON.stringify(this.customIcon))

        cIcon.iconUrl = cIcon.iconUrl + feature.color + this.endIconUrl
        var marker = L.marker(feature.coords, {
          icon: L.icon(cIcon),
        })
        feature.leafletObject = marker
        marker.bindTooltip(feature.name)
        // Quitado a petición de Alfredo
        //marker.bindPopup(feature.name)
        feature.leafletObject.addTo(this.map)
        this.markers.push(feature.leafletObject)
      })
      var group = new L.featureGroup(this.markers)
      if (this.markers.length > 0)
        this.map.fitBounds(group.getBounds().pad(0.45))
      // console.log('termino:', new Date().getTime())
    },
    initMap() {
      // se usa para inicializar el mapa.
      this.map = L.map('map').setView([40.190122, 2.643073], 5)
      this.tileLayer = L.tileLayer(
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
        {
          maxZoom: 20,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
        }
      )
      this.tileLayer.addTo(this.map)
    },
  },
  created() {},
  mounted() {
    this.initMap()
  },
  watch: {
    dataLayers: {
      deep: true,
      handler: function () {
        //console.log('layers:', this.dataLayers)
        this.initLayers()
      },
    },
  },
}
</script>
