<template>
  <v-dialog :value="true" persistent :max-width="dialogWidth">
    <v-card>
      <v-card-title>
        <v-row justify="center">
          <v-col cols="12" align="center">
            <div class="body-1">
              {{
                isCreate
                  ? $t('addCert')
                  : isDelete
                  ? $t('removeCert')
                  : certificate.name
              }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" align="center">
          <v-alert
            v-if="certificate && !isDelete"
            outlined
            :color="certificateColor"
            class="mx-4 d-flex justify-center"
            width="500px"
            align="center"
          >
            <b>{{ certificateTitle }} </b>
            <span v-if="certificate.expiration">
              <i style="display: block">
                {{ $t('certificateExpiration.expirationDate') }}
                {{ $d(certificate.expiration.toDate(), 'i18nDate') }}
              </i>
            </span>
          </v-alert>
        </v-col>

        <template v-if="isDelete">
          <v-col cols="12" align="center">
            <h3>{{ certificate.name }}</h3>
            <p class="body-2">{{ certificate.numeroDocIdentidad }}</p>
            <p>{{ $t('sureRemoveCert') }}</p>
          </v-col>
        </template>

        <template v-if="isCreate || isUpdate">
          <v-form
            ref="certificateForm"
            v-model="validClientForm"
            @submit.prevent="() => {}"
            lazy-validation
          >
            <v-col cols="12" v-if="isCreate">
              <v-text-field
                dense
                :label="$t('nameCollaborator')"
                prepend-icon="mdi-account"
                :rules="[(v) => !!v || $t('requiredField')]"
                :value="name"
                @input="name = $event"
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                dense
                accept=".p12,.pfx"
                chips
                :label="$t('certificate')"
                prepend-icon="mdi-file-document-outline"
                @click:append="showPassword = !showPassword"
                v-model="certificateFile"
                :disabled="loading"
                persistent-hint
                :rules="[(v) => !!v || $t('requiredField')]"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                :label="$t('certificatePassword')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                autocomplete="new-password"
                :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-form-textbox-password"
                :rules="[(v) => !!v || $t('requiredField')]"
                :value="certificatePassword"
                @input="certificatePassword = $event"
                :disabled="loading"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-certificate-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          v-if="!isDisplay"
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="performAction"
          id="confirm-certificate"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  updateUserCertificate,
  deleteUserCertificate,
} from '@/services/user-service'
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'
import { toBase64 } from '@/utils/file'

export default {
  props: {
    certificate: Object,
    action: String,
  },
  data() {
    return {
      name: undefined,
      certificateB64: [],
      certificateFile: undefined,
      certificatePassword: undefined,
      showPassword: false,
      loading: false,
      validClientForm: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeCertificateDialog')
    },
    /**
     * Function to upload create the client
     */
    async performAction() {
      if (
        (this.isCreate || this.isUpdate) &&
        !this.$refs.certificateForm?.validate()
      )
        return

      if ((this.isCreate || this.isUpdate) && this.certificateB64.length === 0)
        return this.setSnackbar({
          position: 'top',
          type: 'error',
          message: this.$t('certificateMandatory'),
        })
      this.loading = true

      if (this.isCreate || this.isUpdate) {
        try {
          // Llamamos al service de user para la subida del certificado
          await updateUserCertificate({
            name: this.name,
            certificateFilename: this.certificateB64[0].name,
            certBase64: this.certificateB64[0].base64,
            certPassword: this.certificatePassword,
            certType: 'alternative',
            id: this.certificate?.id,
          })
          const message = this.$t('uploadCertificateSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
          this.closeDialog()

          // Los operadores, recargar la página para que se refresque el store cuando los nuevos parentAttributes
          if (this.isOperatorUser)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
        } catch (err) {
          const message = getErrorText(err.message)
          this.setSnackbar({ position: 'top', type: 'error', message })
        }
      } else if (this.isDelete) {
        try {
          // Llamamos al service de user para la subida del certificado
          await deleteUserCertificate({
            certType: 'alternative',
            id: this.certificate?.id,
          })
          const message = this.$t('deleteCertificateSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
          this.closeDialog()

          // Los operadores, recargar la página para que se refresque el store cuando los nuevos parentAttributes
          if (this.isOperatorUser)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
        } catch (err) {
          const message = getErrorText(err.message)
          this.setSnackbar({ position: 'top', type: 'error', message })
        }
      }
      this.loading = false
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
    isCreate() {
      return this.action === 'create'
    },
    isUpdate() {
      return this.action === 'update'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    // Gives the dialog width depending on the screen
    dialogWidth() {
      if (this.isDisplay) return 600
      if (!this.isDelete) return 750
      else return 500
    },
    certificateColor() {
      return certificateColor(this.certificate.expiration?.toDate())
    },
    certificateTitle() {
      return certificateTitle(this.certificate.expiration?.toDate())
    },
  },
  watch: {
    /**
     * Watcher to check when a certificate is added so that
     * it can be saved in the base64 format.
     */
    certificateFile: function (newValue) {
      if (newValue) {
        this.certificateB64 = []
        toBase64(newValue, 'certificateB64', this)
      }
    },
  },
}
</script>
