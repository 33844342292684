import moment from 'moment'

export const today = () => {
  return new Date().toISOString().slice(0, 10)
}

export const calculateDate = (days) => {
  return new Date(new Date().setDate(new Date().getDate() + days))
    .toISOString()
    .slice(0, 10)
}

export const ninetyDays = () => {
  return calculateDate(90)
}

export const thirtyDays = () => {
  return calculateDate(30)
}

export const isBetweenDates = (
  dateString,
  detailDateFrom,
  detailDateTo,
  timeFormat
) => {
  let actual = moment(dateString, timeFormat)
  let from = moment(detailDateFrom)
  let to = moment(detailDateTo)
  return !(actual.isBefore(from) || actual.isAfter(to))
}
