<template>
  <v-col>
    <h1 class="primary--text">{{ $t('documentation.texts.groups.title') }}</h1>
    <p>
      {{ $t('documentation.texts.groups.p1') }}
    </p>
    <p>
      {{ $t('documentation.texts.groups.p2') }}
    </p>
    <p>
      {{ $t('documentation.texts.groups.p3') }}
    </p>
    <ul>
      <li @click="goDoc('groups', 'create')" class="custom-link">
        <b>- {{ $t('documentation.texts.groups.li1') }}</b>
      </li>
      <li @click="goDoc('groups', 'update')" class="custom-link">
        <b>- {{ $t('documentation.texts.groups.li2') }}</b>
      </li>
      <li @click="goDoc('groups', 'delete')" class="custom-link">
        <b>- {{ $t('documentation.texts.groups.li3') }}</b>
      </li>
      <li @click="goDoc('groups', 'unregisteredCifs')" class="custom-link">
        <b>- {{ $t('documentation.texts.groups.li4') }}</b>
      </li>
    </ul>
  </v-col>
</template>

<script>
export default {
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '') {
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      } else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>
