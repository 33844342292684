<template>
  <v-fade-transition mode="out-in">
    <v-list-item
      v-if="item.available"
      :key="item.title"
      link
      :class="select() ? 'secondary' : ''"
      @click="clickNavigationDrawerItem"
      :disabled="item.disabled"
    >
      <v-list-item-icon>
        <v-icon
          :class="select() ? 'primary--text text--accent-3' : 'secondary--text'"
        >
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          :class="select() ? 'primary--text text--accent-3' : 'secondary--text'"
        >
          {{ $t(`navigationPage.${item.title}`) }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-fade-transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showDialog: false,
    }
  },
  methods: {
    ...mapMutations(['setNavigationDrawer']),
    clickNavigationDrawerItem() {
      this.$router.push(this.item.routerData)
      if (this.isMobile) this.setNavigationDrawer(false)
    },
    select() {
      return (
        this.item.path === this.$route.path &&
        this.item.routerData.query?.type === this.$route.query.type
      )
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss">
.theme--light.v-list-item:hover::before {
  opacity: 0.04 !important;
}
i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: white;
}
</style>
