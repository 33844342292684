import { Firestore, NewsCollection, NewsDoc } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Crea una subscripción a las noticias relevantes para el usuario, basada en su rol y productos activos.
 * Las noticias son filtradas por fecha y rol/producto del usuario, y los resultados se almacenan en el estado global de la aplicación.
 *
 * @param {number} [days=180] - Número de días hacia atrás desde hoy para filtrar las noticias. Si es 0, no se aplica filtro de fecha.
 * @returns {Function} Función para cancelar la subscripción. Al llamar a esta función, se detendrá la escucha de cambios en las noticias.
 */
export async function newsSubscription(days = 180) {
  var userProducts = []
  if (store.getters.isNotificacionesActivas) userProducts.push('notifications')
  if (store.getters.isFirmasActivas) userProducts.push('signatures')

  var userRol = []
  if (store.getters.isAdminUser) userRol.push('admin')
  if (store.getters.isOperatorUser) userRol.push('operador')
  if (store.getters.isClienteDespachoUser) userRol.push('cliente')

  const todaysDate = new Date()

  const from = new Date().setDate(todaysDate.getDate() - days)
  const fromDate = new Date(from)

  const to = new Date().setDate(todaysDate.getDate())
  const toDate = new Date(to)

  const unsub = Firestore.onSnapshot(
    days != 0
      ? Firestore.query(
          NewsCollection(),
          Firestore.where('targetUser', 'array-contains-any', userRol),
          Firestore.orderBy('releaseDate', 'desc'),
          Firestore.startAt(toDate),
          Firestore.endAt(fromDate)
        )
      : Firestore.query(
          NewsCollection(),
          Firestore.where('targetUser', 'array-contains-any', userRol),
          Firestore.orderBy('releaseDate', 'desc')
        ),
    async (querySnapshot) => {
      let news = (
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const document = doc.data()

            if (document.releaseDate)
              document.releaseDate = document.releaseDate.toDate()

            if (document.endDate) document.endDate = document.endDate.toDate()

            if (document.targetProduct.some((r) => userProducts.includes(r))) {
              return { id: doc.id, ...document }
            }
          })
        )
      )
        .filter((element) => {
          // To skip undefined elements in the promise map than not target the product
          return element !== undefined
        })
        .filter((element) => {
          // To skip elements with end date elapsed
          return element.endDate
            ? new Date().setHours(0, 0, 0, 0) <= element.endDate
            : true
        })

      // Guardamos en el store
      store.commit('setNews', news)
    }
  )

  store.commit('setNewsUnsubscribe', unsub)
  return unsub
}

/**
 * Marca una noticia como leída por el usuario actual. Añade el ID del usuario a la lista de usuarios que han leído la noticia.
 *
 * @param {string} newId - El ID de la noticia a marcar como leída.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la noticia ha sido marcada como leída.
 */
export async function markAsRead(newId) {
  return await Firestore.updateDoc(NewsDoc(newId), {
    usersRead: Firestore.arrayUnion(store.state.user.id),
  })
}
