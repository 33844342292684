<template>
  <v-menu left offset-y open-on-hover z-index="10">
    <template v-slot:activator="{ on, attrs }">
      <template v-if="isUserMenu">
        <v-btn
          id="loggedUser"
          text
          v-bind="attrs"
          v-on="on"
          style="text-transform: none"
        >
          <span style="text-align: right" class="mr-4 secondary--text">
            <h4>
              {{ `${$store.state.user.name} ${$store.state.user.surname}` }}
            </h4>
            <h5>
              {{ $store.state.user.email }}
            </h5>
          </span>
          <AppAvatar
            :userName="`${$store.state.user.name} ${$store.state.user.surname}`"
            :userAvatar="$store.state.user.avatarURL"
            avatarSize="40"
            iconSize="24"
            overlapAvatarColor="secondary"
            overlapIconColor="primary"
          />
        </v-btn>
      </template>
      <v-btn v-else text v-bind="attrs" v-on="on">
        <v-icon
          @click="clickable ? $router.push({ name: clickable }) : undefined"
          :color="color"
          :id="title + '-menu'"
        >
          {{ icon }}
        </v-icon>
        <span
          @click="clickable ? $router.push({ name: clickable }) : undefined"
          :class="color + '--text'"
          >{{ $t(`navigationPage.${title}`) }}
        </span>
      </v-btn>
    </template>
    <v-list color="primary">
      <v-fade-transition mode="out-in" v-for="item in items" :key="item.title">
        <v-list-item
          v-if="item.available"
          :to="item.routerData"
          exact
          :class="selectedItem(item)"
          :disabled="item.disabled"
        >
          <v-list-item-avatar>
            <v-icon :class="selectedText(item)">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title
            :id="
              item.title === 'createSignature'
                ? 'create-signature-menu'
                : item.title === 'mySignatures'
                ? 'signature-list'
                : ''
            "
            :class="selectedText(item)"
          >
            {{ $t(`navigationPage.${item.title}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-fade-transition>
      <template v-if="isUserMenu">
        <div class="pa-2">
          <v-btn
            block
            outlined
            color="secondary"
            @click="$i18n.locale = $i18n.locale === 'es' ? 'en' : 'es'"
          >
            <v-icon>mdi-translate-variant</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $i18n.locale === 'es' ? 'en' : 'es' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn
            id="startTour"
            block
            outlined
            color="secondary"
            @click="$tours.mainTour.start()"
          >
            <v-icon>mdi-wizard-hat</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('navigationPage.startTour') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn
            id="changeTheme"
            block
            outlined
            color="secondary"
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            <v-icon>{{
              $vuetify.theme.dark
                ? 'mdi-weather-sunny'
                : 'mdi-moon-waning-crescent'
            }}</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('navigationPage.changeTheme') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
        <!-- No mostrar boton para cerrar la sesion si el usuario es de tipo singleSignOn -->
        <div class="pa-2" v-if="!isSingleSignOnUser">
          <v-btn id="logOut" block outlined color="secondary" @click="signOut">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-content>
              <v-list-item-title> {{ $t('signOut') }} </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    isUserMenu: Boolean,
    title: String,
    items: Array,
    icon: String,
    color: String,
    clickable: String,
  },
  data() {
    return {
      isOperator: !!this.$store.state.user.parentRef,
    }
  },
  computed: {
    ...mapGetters(['isSingleSignOnUser']),
  },
  methods: {
    ...mapActions(['logout']),
    async signOut() {
      await this.logout()
      this.$router.push({ name: 'LoginView' })
    },
    selectedItem(item) {
      return item.path === this.$route.path &&
        item.routerData.query?.type === this.$route.query.type
        ? 'secondary'
        : ''
    },
    selectedText(item) {
      return item.path === this.$route.path &&
        item.routerData.query?.type === this.$route.query.type
        ? 'primary--text text--accent-3'
        : 'secondary--text'
    },
  },
}
</script>

<style lang="scss"></style>
