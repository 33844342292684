<template>
  <v-dialog :value="showCertificateAlert" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-col cols="11" align="center">
          {{ $t('certificateExpiration.expirationAlertTitle') }}
        </v-col>
        <v-col cols="1">
          <v-btn @click="closeDialog" icon>
            <v-icon> mdi-window-close </v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="center">
          {{ name }}
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-col align="center">
          <p>
            {{ certificateExpirationDialogBody }} ({{
              $d(new Date(certExpiration), 'i18nDate')
            }})
          </p>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { today, thirtyDays, ninetyDays } from '@/utils/date-utils'

export default {
  props: {
    name: String,
    certificateExpiration: Object,
    userId: String,
  },
  data() {
    return {
      closed: false,
      notShow: false,
    }
  },
  methods: {
    async closeDialog() {
      this.closed = true
    },
  },
  computed: {
    certExpiration() {
      return this.certificateExpiration.toDate().toISOString().slice(0, 10)
    },
    showCertificateAlert() {
      if (this.closed) return false

      if (this.certExpiration > ninetyDays()) return false
      else return true
    },
    certificateExpirationDialogBody() {
      if (this.certExpiration < today())
        return this.$t('certificateExpiration.alreadyExpired')
      if (this.certExpiration < thirtyDays())
        return this.$t('certificateExpiration.expirationDialogBodyThirtyDays')
      if (this.certExpiration < ninetyDays())
        return this.$t('certificateExpiration.expirationDialogBodyNinetyDays')

      return ''
    },
  },
}
</script>

<style>
.my-checkbox .v-label {
  font-size: 15px;
}
</style>
