<template>
  <v-col>
    <h1 class="primary--text">{{ $t('documentation.texts.home.title') }}</h1>
    <p>
      {{ $t('documentation.texts.home.paragraph1') }}
    </p>
    <p>
      {{ $t('documentation.texts.home.paragraph2') }}
    </p>
    <p>
      {{ $t('documentation.texts.home.paragraph3') }}
    </p>
    <br />
    <br />
    <v-row>
      <v-col cols="6" lg="3">
        <v-btn
          id="documentation-home-dashboard-btn"
          large
          @click="goDoc('dashboard')"
          class="custom-link"
        >
          {{ $t('documentation.titles.dashboard.titleHome') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="isAdminUser">
        <v-btn
          id="documentation-home-operators-btn"
          large
          @click="goDoc('operators')"
          class="custom-link"
        >
          {{ $t('documentation.titles.operators.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="!isClienteDespachoUser">
        <v-btn
          id="documentation-home-notifications-btn"
          large
          @click="goDoc('notifications')"
          class="custom-link"
          :disabled="!isNotificacionesActivas"
        >
          {{ $t('documentation.titles.notifications.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="isAdminUser">
        <v-btn
          id="documentation-home-companies-btn"
          large
          @click="goDoc('companies')"
          class="custom-link"
        >
          {{ $t('documentation.titles.companies.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3">
        <v-btn
          id="documentation-home-clients-btn"
          large
          @click="goDoc('clients')"
          class="custom-link"
          :disabled="!isFirmasActivas"
        >
          {{ $t('documentation.titles.clients.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3">
        <v-btn
          id="documentation-home-signatures-btn"
          large
          @click="goDoc('signatures')"
          class="custom-link"
          :disabled="!isFirmasActivas"
        >
          {{ $t('documentation.titles.signatures.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="isAdminUser">
        <v-btn
          id="documentation-home-at-btn"
          large
          @click="goDoc('automatedTasks')"
          class="custom-link"
        >
          {{ $t('documentation.titles.automatedTasks.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3">
        <v-btn
          id="documentation-home-config-btn"
          large
          @click="goDoc('configuration')"
          class="custom-link"
        >
          {{ $t('documentation.titles.configuration.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="isAdminUser">
        <v-btn
          id="documentation-home-config-btn"
          large
          @click="goDoc('groups')"
          class="custom-link"
        >
          {{ $t('documentation.titles.groups.title') }}
        </v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="isAllowedAnalytics">
        <v-btn
          large
          @click="goDoc('analytics')"
          :disabled="isColectivoUser"
          class="custom-link"
        >
          {{ $t('documentation.titles.analytics.title') }}
        </v-btn>
      </v-col>
    </v-row>
    <br />
    <br />
    <b>
      <p>
        {{ $t('documentation.texts.home.paragraph4') }}
      </p>
    </b>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <br />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isAdminUser',
      'isOperatorUser',
      'isClienteDespachoUser',
      'isColectivoUser',
      'isNotificacionesActivas',
      'isFirmasActivas',
      'isAllowedAnalytics',
    ]),
    pic1Name() {
      return require('@/assets/documentation/home/' +
        this.$t(
          this.isNotificacionesActivas
            ? 'documentation.texts.home.picZoom'
            : 'documentation.texts.home.picZoom2'
        ) +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '') {
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      } else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>
