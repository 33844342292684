<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <NotPurchased />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotPurchased from '@/components/not-found/NotPurchased'

export default {
  components: {
    NotPurchased,
  },
}
</script>
