<template>
  <AppSplash :condition="waitingAuthUser">
    <template v-slot:app-content>
      <v-app>
        <AppDialog
          v-if="!$store.state.maintenanceMode"
          :dialog="$store.state.dialog"
        />
        <PendingDialog
          v-if="pendingDialog && pendingShown"
          @close="
            () => {
              pendingDialog = false
            }
          "
        />
        <CertificateDialog
          v-if="isAdminUser && $store.state.user?.certificateExpiration"
          :name="$store.state.user.empresa"
          :certificateExpiration="$store.state.user.certificateExpiration"
          :userId="$store.state.user.id"
        />
        <div
          v-for="certificate in $store.state.user?.alternativeCertificates"
          :key="certificate.id"
        >
          <CertificateDialog
            v-if="isAdminUser && certificate.expiration"
            :name="certificate.name"
            :certificateExpiration="certificate.expiration"
            :userId="$store.state.user.id"
          />
        </div>
        <div v-for="cif in $store.state.user?.cifs" :key="cif.identificador">
          <CertificateDialog
            v-if="isAdminUser && cif.certificateExpiration"
            :name="cif.empresa"
            :certificateExpiration="cif.certificateExpiration"
            :userId="$store.state.user.id"
          />
        </div>

        <NewsDialog v-if="urgentNews.length > 0" :news="urgentNews" />
        <AppBar
          v-if="$route.name !== 'LoginView' && !$store.state.maintenanceMode"
        />
        <AppNavigationDrawer />
        <v-main :style="{ 'padding-right': isMobile ? '0px' : undefined }">
          <AppProgress
            v-if="$route.name !== 'LoginView'"
            :items="$store.state.progress"
          />
          <MaintenanceView v-if="$store.state.maintenanceMode" />
          <NotFoundView v-else-if="$store.state.notFound" />
          <router-view v-else :key="$route.fullPath" />
        </v-main>
        <AppFooter
          v-if="$route.name !== 'LoginView' && !$store.state.maintenanceMode"
        />
        <AppSnackbar :snackbar="$store.state.snackbar" />
      </v-app>
    </template>
  </AppSplash>
</template>

<script>
import { getAppMetadataSubscription } from '@/services/metadata-service'
import { getAppLegalSubscription } from '@/services/legal-service'
import AppBar from '@/components/app-bar/AppBar'
import AppNavigationDrawer from '@/components/app-navigation-drawer/AppNavigationDrawer'
import NotFoundView from '@/views/NotFoundView'
import MaintenanceView from '@/views/MaintenanceView'
import AppFooter from '@/components/app-footer/AppFooter'
import AppSplash from '@/components/commons/AppSplash'
import AppSnackbar from '@/components/commons/AppSnackbar'
import AppDialog from '@/components/commons/AppDialog'
import AppProgress from '@/components/commons/AppProgress'
import NewsDialog from '@/components/news/NewsDialog'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Auth } from '@/firebase-exports'
import { updateNotifications } from './services/notification-service'
import { updatePlan } from './services/plan-service'
import { hasToAuthorize } from './services/client-service'
import PendingDialog from '@/components/cifs/clients/PendingDialog'
import CertificateDialog from '@/components/certificate/CertificateDialog'
import { parentuserSubscription } from '@/services/parent-service'
import { getBilling } from '@/services/billing-service'

export default {
  components: {
    AppBar,
    AppNavigationDrawer,
    NotFoundView,
    AppFooter,
    AppSplash,
    AppSnackbar,
    AppDialog,
    AppProgress,
    MaintenanceView,
    NewsDialog,
    PendingDialog,
    CertificateDialog,
  },
  watch: {
    waitingAuthUser: function () {
      this.setTabTitle()
    },
    '$i18n.locale': function () {
      this.setTabTitle()
    },
    '$route.fullPath': function () {
      this.setTabTitle()
    },
    '$store.state.notFound': function () {
      this.setTabTitle()
    },
    '$store.state.user': async function (newValue) {
      if (newValue && newValue.tipo === 'clienteDespacho') {
        if (
          (await hasToAuthorize(
            newValue.id,
            newValue.cifsRef,
            newValue.cifsPermissions
          )) &&
          this.$route.name !== 'CifsView'
        ) {
          this.pendingDialog = true
          this.pendingShown = true
        }
      }
      if (newValue && newValue.disabled === true) {
        const message = this.$t('userDisabled')
        this.setSnackbar({ position: 'top', type: 'error', message })
        await new Promise((resolve) => setTimeout(resolve, 5000))
        await this.logout()
        this.$router.push({ name: 'LoginView' })
      }
    },
    'parentUser.disabled': async function (newValue) {
      if (newValue === true) {
        const message = this.$t('userDisabled')
        this.setSnackbar({ position: 'top', type: 'error', message })
        await new Promise((resolve) => setTimeout(resolve, 5000))
        await this.logout()
        this.$router.push({ name: 'LoginView' })
      }
    },
  },
  data() {
    return {
      unsubscribeMetadata: () => {},
      unsubscribeLegal: () => {},
      parentUserUnsubscribe: () => {},
      waitingAuthUser: true,
      timeOut: null,
      pendingDialog: false,
      pendingShown: false,
      uploadCertificate: false,
      parentUser: undefined,
    }
  },
  methods: {
    ...mapMutations(['setDialog', 'setSnackbar', 'setProgress']),
    ...mapActions(['logout', 'fetchUser']),
    userUnsubscribe() {},
    setTabTitle() {
      var tabTitle = this.$store.state.notFound
        ? this.$t('notFound')
        : this.$store.state.maintenanceMode
        ? this.$t('maintenanceTitle')
        : this.$t(`tabTitle.${this.$route.meta.tabTitle}`)
      if (tabTitle && !this.waitingAuthUser) tabTitle += ' - ' + 'GoodOK'
      else tabTitle = 'GoodOK'
      document.title = tabTitle
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    urgentNews() {
      var context = this
      return this.$store.state.news.filter(function (el) {
        return (
          el.push && el.usersRead.indexOf(context.$store.state.user.id) == -1
        )
      })
    },
  },
  async beforeCreate() {
    Auth.onAuthStateChanged(Auth.getAuth(), async (user) => {
      if (user) {
        try {
          this.userUnsubscribe = await this.fetchUser()
          //Esperamos a que tenga valor el state antes de continuar
          while (!this.$store.state.user) {
            await new Promise((resolve) => setTimeout(resolve, 100))
          }

          if (this.$store.state.user.disabled) {
            const message = this.$t('userDisabled')
            this.setSnackbar({ position: 'top', type: 'error', message })
            await new Promise((resolve) => setTimeout(resolve, 5000))
            await this.logout()
            this.$router.push({ name: 'LoginView' })
            return
          }
          // En caso de ser un operador, suscripción al user parentRef para comprobar si esta disabled desde el management app
          if (this.$store.state.user.parentRef) {
            this.parentUserUnsubscribe = await parentuserSubscription(
              this.$store.state.user.parentRef.id,
              this
            )
            while (!this.parentUser) {
              await new Promise((resolve) => setTimeout(resolve, 100))
            }
          }

          if (!this.$store.getters.isDemoEnv) {
            // Si el user parent esta disabled, no descargar nuevas notificaciones
            if (!this.parentUser?.disabled) {
              updateNotifications()
              updatePlan()
            }
          }

          await getBilling()

          if (
            this.$route.name === 'LoginView' ||
            this.$route.fullPath === '/'
          ) {
            const finalRoute = this.$route.query.redirect || '/dashboard'
            this.$router.replace(finalRoute)
          }
        } catch {
          const message = this.$t('genericError')
          this.setSnackbar({ position: 'top', type: 'error', message })

          await new Promise((resolve) => setTimeout(resolve, 3000))
          this.logout()
        }
      } else {
        if (this.userUnsubscribe) this.userUnsubscribe()
        if (
          this.$route.name !== 'LoginView' &&
          this.$route.name !== 'SharedNotificationDetailView'
        ) {
          const currentPath = this.$route.fullPath
          const redirect = currentPath !== '/' ? currentPath : undefined
          this.$router.push({ name: 'LoginView', query: { redirect } })
        }
      }

      this.waitingAuthUser = false
    })
  },
  mounted() {
    this.unsubscribeMetadata = getAppMetadataSubscription()
    this.unsubscribeLegal = getAppLegalSubscription()
  },
  destroyed() {
    if (this.unsubscribeMetadata) this.unsubscribeMetadata()
    if (this.unsubscribeLegal) this.unsubscribeLegal()
    if (this.parentUserUnsubscribe) this.parentUserUnsubscribe()
  },
  async created() {
    this.setTabTitle()
  },
}
</script>

<style lang="scss">
.subtitle {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0333333333em;
}
.emojicountries {
  font-family: 'Twemoji Country Flags', 'Roboto';
}
</style>
