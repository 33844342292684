<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="9"
        md="7"
        lg="5"
        xl="4"
        align="center"
        justify="center"
      >
        <v-card outlined color="transparent">
          <v-form ref="loginForm">
            <v-container fill-height fluid>
              <v-row align="center" justify="center" v-if="!is2FA">
                <v-col cols="12" v-if="!isMobile">
                  <v-img
                    contain
                    class="mx-auto"
                    :src="getImageSource"
                    max-width="300"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    :disabled="loading"
                    :rules="userRules"
                    :label="$t('username')"
                    :dark="isMobile"
                    id="input-username"
                  />
                </v-col>
                <v-col cols="12" v-if="!isResetPassword">
                  <v-text-field
                    v-model="password"
                    :disabled="loading"
                    :rules="passwordRules"
                    :label="$t('password')"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="on"
                    :append-icon="passwordIcons[showPassword]"
                    @click:append="showPassword = !showPassword"
                    :dark="isMobile"
                    id="input-password"
                  />
                  <v-checkbox
                    v-model="keepSession"
                    :disabled="loading"
                    :label="$t('keepSession')"
                    :dark="isMobile"
                    id="input-session"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    :class="
                      isMobile
                        ? 'secondary primary--text'
                        : 'primary secondary--text'
                    "
                    block
                    @click="isResetPassword ? userResetPassword() : userLogin()"
                    :dark="isMobile"
                    id="login"
                  >
                    {{ $t(isResetPassword ? 'resetPassword' : 'login') }}
                    <template v-slot:loader>
                      <v-progress-circular indeterminate size="20" width="2" />
                      <span class="ml-2">
                        {{
                          $t(
                            isResetPassword ? 'resettingPassword' : 'loggingIn'
                          )
                        }}
                      </span>
                    </template>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span
                    @click="changeIsResetPassword"
                    :class="
                      loading
                        ? 'grey--text'
                        : isMobile
                        ? 'secondary--text'
                        : 'primary--text'
                    "
                    :style="{
                      cursor: loading ? 'default' : 'pointer',
                    }"
                  >
                    {{ $t(isResetPassword ? 'login' : 'forgotPassword') }}
                  </span>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" v-else>
                <v-col cols="12" v-if="!isMobile">
                  <v-img
                    contain
                    class="mx-auto"
                    :src="getImageSource"
                    max-width="300"
                  />
                </v-col>
                <v-col cols="12" v-if="!sent2FA">
                  <v-select
                    v-model="selected2FA"
                    :items="resolver2FA?.hints"
                    item-text="phoneNumber"
                    :rules="codeRules"
                    :label="$t('select2FA')"
                    id="input-select2FA"
                    return-object
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" v-else>
                  <v-text-field
                    v-model="code2FA"
                    :disabled="loading"
                    :rules="codeRules"
                    :label="$t('code2FA')"
                    :dark="isMobile"
                    id="input-code"
                  />
                </v-col>
                <div id="recaptcha-containter"></div>
                <v-col cols="12">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    :class="
                      isMobile
                        ? 'secondary primary--text'
                        : 'primary secondary--text'
                    "
                    block
                    @click="sent2FA ? verify2FA() : send2FA()"
                    :dark="isMobile"
                    id="2fa"
                  >
                    {{ $t(sent2FA ? 'verify2FA' : 'send2FA') }}
                    <template v-slot:loader>
                      <v-progress-circular indeterminate size="20" width="2" />
                      <span class="ml-2">
                        {{ $t(sent2FA ? 'verifying2FA' : 'sending2FA') }}
                      </span>
                    </template>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span
                    @click="exit2FA"
                    :class="
                      loading
                        ? 'grey--text'
                        : isMobile
                        ? 'secondary--text'
                        : 'primary--text'
                    "
                    :style="{
                      cursor: loading ? 'default' : 'pointer',
                    }"
                  >
                    {{ $t('exit2FA') }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from '@/firebase-exports'
import { createTrace } from '@/services/trace-service'
import { getIp } from '@/utils/ip-utils.js'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'
//import { getUserByEmail } from '@/services/user-service'
import { validateUserSingleSignOn } from '@/services/user-service'

export default {
  data() {
    return {
      authData: undefined,
      recaptchaVerifier: undefined,
      loading: false,
      username: '',
      password: '',
      showPassword: false,
      keepSession: false,
      isResetPassword: false,
      is2FA: false,
      resolver2FA: undefined,
      selected2FA: undefined,
      sent2FA: false,
      verification2FA: undefined,
      code2FA: '',
      userRules: [rules.required, rules.email],
      passwordRules: [rules.required],
      codeRules: [rules.required],
      passwordIcons: { true: 'mdi-eye', false: 'mdi-eye-off' },
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async userLogin(token = undefined) {
      if (!token && !this.$refs.loginForm.validate()) return

      this.loading = true

      try {
        if (!this.keepSession)
          await Auth.setPersistence(
            this.authData,
            Auth.browserSessionPersistence
          )

        const { user } = await Auth.signInWithEmailAndPassword(
          this.authData,
          token ? token.username : this.username,
          token ? token.password : this.password
        )

        getIp()
          .then(async (ip) => {
            await createTrace(user.uid, {
              actionType: 'logged',
              createdAt: new Date(),
              ip,
            })
          })
          .catch(async () => {
            await createTrace(user.uid, {
              actionType: 'logged',
              createdAt: new Date(),
            })
          })
      } catch (err) {
        if (err.message.includes('auth/multi-factor-auth-required')) {
          // The user is a multi-factor user. Second factor challenge is required.
          this.resolver2FA = Auth.getMultiFactorResolver(this.authData, err)
          this.is2FA = true
          this.loading = false
          this.$refs.loginForm.resetValidation()
        } else {
          const message = getErrorText(err.message)
          this.setSnackbar({ position: 'top', type: 'error', message })
          this.loading = false
        }
      }
    },
    async send2FA() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true

      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new Auth.RecaptchaVerifier(
          this.authData,
          'recaptcha-containter',
          {
            size: 'invisible',
          }
        )
      }

      // Note that only phone second factors are currently supported.
      if (
        this.selected2FA.factorId == Auth.PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        try {
          // To renable button after 5 seconds if user close captcha
          setTimeout(() => {
            this.loading = false
          }, 5000)

          // Send SMS verification code
          const phoneAuthProvider = new Auth.PhoneAuthProvider(this.authData)
          this.verification2FA = await phoneAuthProvider.verifyPhoneNumber(
            {
              multiFactorHint: this.selected2FA,
              session: this.resolver2FA.session,
            },
            this.recaptchaVerifier
          )
          this.loading = false
          this.sent2FA = true
          this.$refs.loginForm.resetValidation()
        } catch (err) {
          // Controlamos que errores mostramos en snackbar para evitar lanzar snackbar inncesario por error de captcha
          if (err.message.includes('too-many-requests')) {
            const message = getErrorText(err.message)
            this.setSnackbar({ position: 'top', type: 'error', message })
          }

          this.loading = false
        }
      }
    },
    async verify2FA() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true

      const cred = Auth.PhoneAuthProvider.credential(
        this.verification2FA,
        this.code2FA
      )
      const multiFactorAssertion =
        Auth.PhoneMultiFactorGenerator.assertion(cred)

      try {
        // Verify SMS verification code
        const { user } = await this.resolver2FA.resolveSignIn(
          multiFactorAssertion
        )

        await createTrace(user.uid, {
          actionType: 'logged',
          createdAt: new Date(),
        })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.loading = false
      }
    },
    async userResetPassword() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true
      this.password = ''

      try {
        this.authData.languageCode = this.$i18n.locale

        // No permitir restablecer contraseña si el usuario es de tipo singleSignOn
        const result = await validateUserSingleSignOn(this.username)
        if (result?.singleSignOn) {
          this.loading = false
          return this.setSnackbar({
            position: 'top',
            type: 'error',
            message: this.$t('userSingleSignOnPassword'),
          })
        }

        await Auth.sendPasswordResetEmail(this.authData, this.username)
        this.loading = false
        this.isResetPassword = false
        const message = this.$t('passwordResetSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.loading = false
      }
    },
    changeIsResetPassword() {
      if (this.loading) return
      this.$refs.loginForm.resetValidation()
      this.isResetPassword = !this.isResetPassword
    },
    exit2FA() {
      if (this.loading) return
      this.resolver2FA = undefined
      this.selected2FA = undefined
      this.sent2FA = false
      this.verification2FA = undefined
      this.code2FA = ''
      this.$refs.loginForm.resetValidation()
      this.is2FA = false
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    getImageSource() {
      if (this.$store.state.brand?.loginLogo?.startsWith('http')) {
        return this.$store.state.brand.loginLogo
      } else {
        return require('@/assets/login-logo.png')
      }
    },
  },
  mounted() {
    this.authData = Auth.getAuth()

    if (this.$route.query.token)
      this.userLogin(
        JSON.parse(
          Buffer.from(this.$route.query.token, 'base64').toString('utf8')
        )
      )
  },
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
