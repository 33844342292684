<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.signatures.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.signatures.signatureDelete.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.signatures.signatureDelete.paragraph1') }}
    </p>
    <v-img
      style="width: 100%; max-width: 600px"
      contain
      :src="picJointDetail"
    />
    <v-img style="width: 100%; max-width: 600px" contain :src="picDelete" />
  </v-col>
</template>

<script>
export default {
  computed: {
    picDelete() {
      return require('@/assets/documentation/signatures/' +
        this.$t('documentation.texts.signatures.signatureDelete.picDelete') +
        '.png')
    },
    picJointDetail() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureDelete.picJointDetail'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
