export const getTypeByNumber = (idNumber) => {
  var DNI_REGEX = /^(\d{8})([A-Z])$/
  var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
  var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/
  var NIFM_REGEX = /^[M]\d{7}[A-Z]$/

  if (idNumber.match(DNI_REGEX)) return 'DNI'
  else if (idNumber.match(CIF_REGEX)) return 'CIF'
  else if (idNumber.match(NIE_REGEX)) return 'NIE'
  else if (idNumber.match(NIFM_REGEX)) return 'NIF-M'
}
