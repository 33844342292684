<template>
  <v-card min-height="600" elevation="5" class="rounded-b-xl">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-tabs :color="tabColor" v-model="tab" fixed-tabs>
            <v-tab v-show="incidence.length" id="incidence-tab-dashboard">
              {{ $t('signatureStatus.incidence') }}
            </v-tab>
            <v-tab id="pending-tab-dashboard">
              {{ $t('signatureStatus.pending') }}
            </v-tab>
            <v-tab id="urgent-tab-dashboard">
              {{ $t('signatureStatus.urgent') }}
            </v-tab>
            <v-tab id="rejected-tab-dashboard">
              {{ $t('signatureStatus.rejected') }}
            </v-tab>
            <v-tab id="deferred-tab-dashboard">
              {{ $t('signatureStatus.deferred') }}
            </v-tab>
            <v-tab id="signed-tab-dashboard">
              {{ $t('signatureStatus.signed') }}
            </v-tab>
            <v-tab id="abandoned-tab-dashboard">
              {{ $t('signatureStatus.abandoned') }}
            </v-tab>
          </v-tabs>
          <SignaturesListElement
            v-if="tab == 0 && incidence"
            :signaturesList="incidence"
          >
          </SignaturesListElement>

          <SignaturesListElement
            v-if="tab == 1 && pending"
            :signaturesList="pending"
          >
          </SignaturesListElement>
          <SignaturesListElement
            v-if="tab == 2 && urgent"
            :signaturesList="urgent"
          >
          </SignaturesListElement>
          <SignaturesListElement
            v-if="tab == 3 && rejected"
            :signaturesList="rejected"
          >
          </SignaturesListElement>
          <SignaturesListElement
            v-if="tab == 4 && deferred"
            :signaturesList="deferred"
          >
          </SignaturesListElement>
          <SignaturesListElement
            v-if="tab == 5 && signed"
            :signaturesList="signed"
          >
          </SignaturesListElement>
          <SignaturesListElement
            v-if="tab == 6 && abandoned"
            :signaturesList="abandoned"
          >
          </SignaturesListElement>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SignaturesListElement from '@/components/dashboard/dashboardElements/signatures/SignaturesListElement'
export default {
  components: {
    SignaturesListElement,
  },
  props: {
    signed: { type: Array },
    deferred: { type: Array },
    urgent: { type: Array },
    rejected: { type: Array },
    pending: { type: Array },
    abandoned: { type: Array },
    incidence: { type: Array },
  },
  data() {
    return {
      tab: this.incidence.length ? 0 : 1,
    }
  },
  computed: {
    tabColor() {
      switch (this.tab) {
        case 0:
          return 'red'
        case 1:
          return 'warningLow'
        case 2:
          return 'warning'
        case 3:
          return 'red'
        case 4:
          return 'accept'
        default:
          return 'accept'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
