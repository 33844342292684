<template>
  <div style="width: 100%">
    <apexchart
      type="bar"
      :height="height"
      :options="series.chartOptions"
      :series="series.series"
    ></apexchart>
    <v-icon
      v-if="series.loading"
      style="top: -40%; left: 50%; z-index: 999; position: relative"
    >
      mdi-autorenew mdi-spin
    </v-icon>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { isBetweenDates } from '@/utils/date-utils'

const yearDivision = 'year'
const yearTimeGroup = 'YYYY'

const monthDivision = 'month'
const monthTimeGroup = 'YYYY-MM'

const weekDivision = 'isoWeek'
const weekTimeGroup = 'GGGG-WW'

const dayDivision = 'day'
const dayTimeGroup = 'YY-MM-DD'

const dateStringFormat = 'YYYYMMDD'

const dateDivisionLevels = {
  year: 0,
  month: 1,
  week: 2,
  day: 3,
}
// Valor 1 para las Comunicaciones, Valor 2 para las Notificaciones

const typeComunication = '1'
const typeNotification = '2'

const notificationTypeComunication = 'Comunicacion'
const notificationTypeNotification = 'Notificacion'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartname: String,
    title: String,
    notifications: Array,
    height: String,
    dateFrom: String,
    dateTo: String,
  },
  data() {
    var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    return {
      filteredNotifications: [],
      actualDateFrom: '',
      actualDateTo: '',
      detailDateFrom: '',
      detailDateTo: '',
      timeGroup: yearTimeGroup,
      initialTimeUnit: yearTimeGroup,
      timeUnit: yearDivision,
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          stacked: false,
        },
        theme: {
          mode: theme,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: [],
        },
        colors: [
          '#80c7fd',
          '#008FFB',
          '#80f1cb',
          '#00E396',
          '#e3928d',
          '#eb4034',
        ],
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
      },
    }
  },

  computed: {
    series() {
      //console.log(this.notifications)
      if (this.notifications === undefined || this.notifications.length === 0) {
        return {
          chartOptions: this.chartOptions,
          series: [],
          loading: true,
        }
      }

      let dataMap = {}
      for (let notificationIdx in this.notifications) {
        let notification = this.notifications[notificationIdx]
        let type = this.parseType(notification.tipo_envio)
        if (type === '') {
          console.log('unexpected notification type')
          continue
        }
        //Tras hablarlo con Javi, sacamos las comunicaciones de la ecuación, ya que no tiene tipo en el presente
        if (type === notificationTypeNotification) {
          let dateString = this.getReceivedDateGroup(notification)
          this.updateDataMap(dataMap, dateString, notification, type)
        }
      }

      // Generating series
      let categories = []
      let series_resLiqProv = {
        name: 'resLiqProv',
        data: [],
      }
      let series_AcImSaInTri = {
        name: 'AcImSaInTri',
        data: [],
      }
      let series_LiInDeDeEx = {
        name: 'LiInDeDeEx',
        data: [],
      }
      let series_requerimiento = {
        name: 'requerimiento',
        data: [],
      }
      let series_DiEmCuBa = {
        name: 'DiEmCuBa',
        data: [],
      }
      let series_AEAT_RE02 = {
        name: 'AEAT_RE02',
        data: [],
      }
      let series_embargoSS = {
        name: 'embargoSS',
        data: [],
      }
      let series_smac = {
        name: 'smac',
        data: [],
      }

      let xkeys = []

      let dataKeyList = this.generateDataKeyList()
      for (let i = 0; i < dataKeyList.length; i++) {
        let dataKey = dataKeyList[i]
        categories.push(dataKey)
        let values = dataMap[dataKey]
        if (!values) {
          values = {
            resLiqProv: 0,
            AcImSaInTri: 0,
            LiInDeDeEx: 0,
            requerimiento: 0,
            DiEmCuBa: 0,
            AEAT_RE02: 0,
            embargoSS: 0,
            smac: 0,
          }
        }

        series_resLiqProv.data.push(values.resLiqProv)
        series_AcImSaInTri.data.push(values.AcImSaInTri)
        series_LiInDeDeEx.data.push(values.LiInDeDeEx)
        series_requerimiento.data.push(values.requerimiento)
        series_DiEmCuBa.data.push(values.DiEmCuBa)
        series_AEAT_RE02.data.push(values.AEAT_RE02)
        series_embargoSS.data.push(values.embargoSS)
        series_smac.data.push(values.smac)

        xkeys.push(dataKey)
      }

      series_resLiqProv.name = this.$t(
        'metadata.types.' + series_resLiqProv.name
      )
      series_AcImSaInTri.name = this.$t(
        'metadata.types.' + series_AcImSaInTri.name
      )
      series_LiInDeDeEx.name = this.$t(
        'metadata.types.' + series_LiInDeDeEx.name
      )
      series_requerimiento.name = this.$t(
        'metadata.types.' + series_requerimiento.name
      )
      series_DiEmCuBa.name = this.$t('metadata.types.' + series_DiEmCuBa.name)
      series_AEAT_RE02.name = this.$t('metadata.types.' + series_AEAT_RE02.name)
      series_embargoSS.name = this.$t('metadata.types.' + series_embargoSS.name)
      series_smac.name = this.$t('metadata.types.' + series_smac.name)

      let series = [
        series_resLiqProv,
        series_AcImSaInTri,
        series_LiInDeDeEx,
        series_requerimiento,
        series_DiEmCuBa,
        series_AEAT_RE02,
        series_embargoSS,
        series_smac,
      ]

      let pack = {}
      if (this.notifications.length === 0) {
        pack = {
          chartOptions: this.createCustomOptions(xkeys),
          series: [],
          loading: true,
        }
      } else {
        pack = {
          chartOptions: this.createCustomOptions(xkeys),
          series: series,
          loading: false,
        }
      }
      return pack
    },
  },
  methods: {
    createCustomOptions(xkeys) {
      // Building chart options
      return {
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
        title: {
          text: this.title + ': ' + this.$t('graphs.timeUnit.' + this.timeUnit),
          align: 'left',
        },
        xaxis: {
          categories: xkeys,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config)
              setTimeout(() => {
                this.zoomNextLevel(xkeys[config.dataPointIndex])
              }, 10)
            },
            xAxisLabelClick: (event, chartContext, config) => {
              //console.log(config)
              setTimeout(() => {
                this.zoomNextLevel(xkeys[config.labelIndex])
              }, 10)
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 25,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [
                {
                  icon: '<span class="mdi mdi-undo"/>',
                  index: 0,
                  title: 'Atrás',
                  class: '',
                  click: () => {
                    this.zoomPreviousLevel()
                  },
                },
              ],
            },
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
      }
    },
    parseType(type) {
      switch (type) {
        case typeComunication:
          return notificationTypeComunication
        case typeNotification:
          return notificationTypeNotification
        default:
          return ''
      }
    },
    getNotificationTypes(notification) {
      var types = []
      if (notification.conceptoTipo !== undefined) {
        types.push(notification.conceptoTipo)
      }
      if (notification.metadatas !== undefined) {
        notification.metadatas.forEach((metadata) => {
          if (metadata.tipo !== undefined) {
            types.push(metadata.tipo)
          }
        })
      }
      return types
    },
    updateDataMap(dataMap, dateString, notification, type) {
      if (type === typeComunication) {
        return //las comunicaciones no tienes tipo, solo interesan las notificaciones
      }
      if (dataMap[dateString] === undefined) {
        dataMap[dateString] = {
          resLiqProv: 0,
          AcImSaInTri: 0,
          LiInDeDeEx: 0,
          requerimiento: 0,
          DiEmCuBa: 0,
          AEAT_RE02: 0,
          embargoSS: 0,
          smac: 0,
        }
      }
      this.getNotificationTypes(notification).forEach((type) => {
        //Si no tienen tipo las ignoramos
        dataMap[dateString][type] += 1
      })
    },
    generateDataKeyList() {
      let currentMoment = moment(this.actualDateFrom)
      let endMoment = moment(this.actualDateTo)
      let keyList = []
      while (currentMoment.isSameOrBefore(endMoment, this.timeUnit)) {
        // console.log(this.timeGroup,':',currentMoment.format(this.timeGroup))
        keyList.push(currentMoment.format(this.timeGroup))
        currentMoment.add(1, this.timeUnit)
      }
      return keyList
    },
    setTimeGroup(newTimeGroup, newTimeUnit) {
      this.timeGroup = newTimeGroup
      this.timeUnit = newTimeUnit
    },
    getDateGroup(notification, state) {
      let dateString
      if (state == 'Aceptado') {
        dateString = notification.fecha_aceptacion
      } else if (state == 'Expirada') {
        if (
          notification.tipo_envio === typeComunication &&
          (notification.fecha_expiracion === '' ||
            notification.fecha_expiracion === undefined)
        ) {
          dateString = this.generateComunicationExpiration(
            notification.fecha_puesta_disposicion
          )
        } else {
          dateString = notification.fecha_expiracion
        }
      }
      let out = moment(dateString).format(this.timeGroup)
      return out
    },
    generateComunicationExpiration(date) {
      return moment(date).add(10, dayDivision).format(dateStringFormat)
    },
    getReceivedDateGroup(notification) {
      let dateString = notification.fecha_puesta_disposicion
      let out = moment(dateString).format(this.timeGroup)
      return out
    },
    getEstado(notification) {
      return notification.estado
    },
    filterNotifications() {
      this.filteredNotifications = this.notifications.filter((notification) => {
        //Si es una comunicación no me interesa
        if (notification.tipo_envio === typeComunication) {
          return false
        }
        //Si no tiene tipo no me interesa
        if (this.getNotificationTypes(notification).length === 0) {
          return false
        }

        // received date
        let receivedDateString = moment(
          notification.fecha_puesta_disposicion
        ).format(this.timeGroup)

        return isBetweenDates(
          receivedDateString,
          this.detailDateFrom,
          this.detailDateTo,
          this.timeGroup
        )
      })
      //console.log(this.filteredNotifications)
    },
    zoomNextLevel(selectedX) {
      if (this.timeUnit === yearDivision) {
        this.zoomMonthsLevel(selectedX)
      } else if (this.timeUnit === monthDivision) {
        this.zoomWeeksLevel(selectedX)
      } else if (this.timeUnit === weekDivision) {
        this.zoomDaysLevel(selectedX)
      } else if (this.timeUnit === dayDivision) {
        let part = selectedX.split('-')
        let prefixYear = '20'
        if (!dayTimeGroup.startsWith('YY-')) {
          prefixYear = ''
        }
        this.detailDateFrom = moment(
          prefixYear + part[0] + part[1] + part[2]
        ).format(dateStringFormat)
        this.detailDateTo = moment(
          prefixYear + part[0] + part[1] + part[2]
        ).format(dateStringFormat)

        this.filterNotifications()
        //console.log('', this.filteredNotifications)
        let title =
          this.$t('graphs.notificationsGridTitle') +
          this.$d(
            new Date(moment(this.detailDateFrom).format('YYYY-MM-DD')),
            'i18nDate'
          )
        this.$emit('detail-level', this.filteredNotifications, title)
      }
    },
    zoomPreviousLevel() {
      if (
        dateDivisionLevels[this.initialTimeUnit] >=
        dateDivisionLevels[this.timeUnit]
      ) {
        return
      }
      if (this.timeUnit === monthDivision) {
        this.resetDates()
      } else if (this.timeUnit === weekDivision) {
        let year = moment(this.actualDateFrom).format(yearTimeGroup)
        this.zoomMonthsLevel(year)
      } else if (this.timeUnit === dayDivision) {
        let month = moment(this.actualDateFrom).format(monthTimeGroup)
        this.zoomWeeksLevel(month)
      }
    },
    zoomMonthsLevel(selectedYear) {
      this.actualDateFrom = selectedYear + '0101'
      this.actualDateFrom = this.startDate(this.actualDateFrom, this.dateFrom)
      this.actualDateTo = this.dateOrToday(selectedYear + '1231')
      this.actualDateTo = this.endDate(this.actualDateTo, this.dateTo)
      this.setTimeGroup(monthTimeGroup, monthDivision)
    },
    zoomWeeksLevel(selectedMonth) {
      let parts = selectedMonth.split('-')
      this.actualDateFrom = parts[0] + parts[1] + '01'
      let lastDayOfMonth = moment(this.actualDateFrom)
        .endOf(monthDivision)
        .format(dateStringFormat)
      this.actualDateTo = this.dateOrToday(lastDayOfMonth)
      this.setTimeGroup(weekTimeGroup, weekDivision)
    },
    zoomDaysLevel(selectedWeek) {
      let weekMoment = moment(selectedWeek, weekTimeGroup)
      this.actualDateFrom = weekMoment.format(dateStringFormat)
      let lastDayOfWeek = moment(this.actualDateFrom)
        .add(6, dayDivision)
        .format(dateStringFormat)
      this.actualDateTo = this.dateOrToday(lastDayOfWeek)
      this.setTimeGroup(dayTimeGroup, dayDivision)
    },
    dateOrToday(currentDateTo) {
      if (moment().isBefore(moment(currentDateTo))) {
        return moment().format(dateStringFormat)
      }
      return currentDateTo
    },
    startDate(currentDateFrom, initialDateFrom) {
      if (moment(currentDateFrom).isBefore(moment(initialDateFrom))) {
        return moment(initialDateFrom).format(dateStringFormat)
      }
      return currentDateFrom
    },
    endDate(currentDateTo, initialDateTo) {
      if (moment(currentDateTo).isAfter(moment(initialDateTo))) {
        return moment(initialDateTo).format(dateStringFormat)
      }
      return currentDateTo
    },
    resetDates() {
      this.actualDateFrom = this.dateFrom
      this.actualDateTo = this.dateTo
      this.setTimeGroup(yearTimeGroup, yearDivision)
    },
    setInitialLevel() {
      let rangeDays = moment(this.dateTo).diff(moment(this.dateFrom), 'days')
      //console.log(rangeDays)
      if (rangeDays > 365) {
        this.setTimeGroup(yearTimeGroup, yearDivision)
      } else {
        this.setTimeGroup(monthTimeGroup, monthDivision)
      }
      this.initialTimeUnit = this.timeUnit
    },
    setInitialValues() {
      this.actualDateFrom = this.dateFrom
      this.actualDateTo = this.dateTo
      this.setInitialLevel()
    },
  },
  created() {
    this.setInitialValues()
  },
  watch: {
    dateFrom: {
      deep: true,
      handler: function () {
        this.setInitialValues()
      },
    },
  },
}
</script>
