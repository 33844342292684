<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip v-if="isCreate" bottom>
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            elevation="1"
            fab
            small
            class="primary secondary--text ma-1"
            v-bind="{ ...attrs, ...attrsTooltip }"
            v-on="{ ...on, ...onTooltip }"
            id="create-notification"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <div>{{ $t('notificationActions.title.create') }}</div>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          <div>
            {{ isCreate && $t('notificationActions.title.create') }}
            <DialogButton
              v-if="isCreate"
              section="notifications"
              subsection="addNotification"
            />
          </div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="notificationForm"
          v-model="validNotificationForm"
          @submit.prevent="() => {}"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-select
                prepend-icon="mdi-office-building"
                v-model="cif"
                item-text="empresa"
                :items="cifs"
                :label="$t('cifs')"
                :rules="[notEmpty]"
                dense
                return-object
                :disabled="loading"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters align="baseline">
                <v-select
                  v-model="filter"
                  prepend-icon="mdi-filter"
                  item-text="title"
                  :items="filters"
                  :label="$t('tabTitle.filters')"
                  :rules="!customFilter ? [notEmpty] : []"
                  :disabled="loading || customFilter"
                  return-object
                  dense
                >
                </v-select>
                <v-checkbox
                  class="my-checkbox ml-3 mt-0"
                  :disabled="loading"
                  v-model="customFilter"
                  :label="$t('filters.customFilter')"
                  id="custom-filter"
                />
              </v-row>
            </v-col>
            <template v-if="customFilter">
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="codigo_organismo_emisor_raiz"
                  @input="codigo_organismo_emisor_raiz = $event"
                  @keyup="fixAndUppercase('codigo_organismo_emisor_raiz')"
                  :label="$t('notificationProperties.orgSenderSourceCode')"
                  prepend-icon="mdi-numeric"
                  :disabled="loading"
                  :rules="[
                    notEmpty,
                    startByCharacters(['E', 'A', 'L', 'U', 'I', 'J']),
                    numCharacters(9),
                  ]"
                  id="orgSenderSource-input"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="nombre_organismo_emisor_raiz"
                  @input="nombre_organismo_emisor_raiz = $event"
                  :label="$t('notificationProperties.orgSenderSourceName')"
                  prepend-icon="mdi-tree"
                  :disabled="loading"
                  :rules="[notEmpty]"
                  id="orgSenderSource-input"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="codigo_organismo_emisor"
                  @input="codigo_organismo_emisor = $event"
                  @keyup="fixAndUppercase('codigo_organismo_emisor')"
                  :label="$t('notificationProperties.orgSenderCode')"
                  prepend-icon="mdi-numeric"
                  :disabled="loading"
                  :rules="[
                    notEmpty,
                    startByCharacters(['E', 'A', 'L', 'U', 'I', 'J']),
                    numCharacters(9),
                  ]"
                  id="orgSender-input"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="nombre_organismo_emisor"
                  @input="nombre_organismo_emisor = $event"
                  :label="$t('notificationProperties.orgSenderName')"
                  prepend-icon="mdi-source-commit-start-next-local"
                  :disabled="loading"
                  :rules="[notEmpty]"
                  id="orgSender-input"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-text-field
                v-model="concepto"
                @input="concepto = $event"
                :label="$t('notificationProperties.concept')"
                prepend-icon="mdi-file-document-edit"
                :disabled="loading"
                :rules="[notEmpty]"
                id="orgSenderSource-input"
              />
            </v-col>
            <v-col cols="12">
              <v-radio-group
                v-model="tipo_envio"
                :rules="[notEmpty]"
                :disabled="loading"
                :label="$t('notificationType')"
                prepend-icon="mdi-message-bulleted"
              >
                <v-row align="center" justify="center" class="my-1">
                  <v-col
                    class="justify-center align-center pb-0 pt-0 mb-0 mt-0"
                    cols="12"
                    md="6"
                  >
                    <v-radio
                      :disabled="loading"
                      :label="$t('notification')"
                      value="2"
                    />
                  </v-col>
                  <v-col
                    class="justify-center align-center pb-0 pt-0 mb-0 mt-0"
                    cols="12"
                    md="6"
                  >
                    <v-radio
                      :disabled="loading"
                      :label="$t('comunication')"
                      value="1"
                    />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                dense
                ref="menu_fecha_puesta_disposicion"
                v-model="menu_fecha_puesta_disposicion"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_puesta_disposicion"
                    :label="$t('csv.notifications.fecha_puesta_disposicion')"
                    prepend-icon="mdi-calendar-start"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    id="fecha-puesta-disposicion"
                    :disabled="loading"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @click:date="
                    $refs.menu_fecha_puesta_disposicion.save(
                      fecha_puesta_disposicion
                    )
                  "
                  v-model="fecha_puesta_disposicion"
                  :locale="$t('javascriptLocale')"
                  :max="new Date().toISOString().substring(0, 10)"
                  no-title
                  scrollable
                  :rules="[notEmpty]"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                dense
                ref="menu_fecha_aceptacion"
                v-model="menu_fecha_aceptacion"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_aceptacion"
                    :label="$t('acceptDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    id="acceptance-date"
                    :disabled="loading || !fecha_puesta_disposicion"
                    :rules="[notEmpty]"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  @click:date="
                    $refs.menu_fecha_aceptacion.save(fecha_aceptacion)
                  "
                  v-model="fecha_aceptacion"
                  :locale="$t('javascriptLocale')"
                  :min="minFechaAceptacion"
                  :max="maxFechaAceptacion"
                  no-title
                  scrollable
                  :rules="[notEmpty]"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-file-input
                accept="application/pdf"
                chips
                multiple
                :label="$t('mainDocument')"
                prepend-icon="mdi-file-document-outline"
                v-model="mainDoc"
                :placeholder="$t('attachMainDoc')"
                :rules="[listNotEmpty]"
                :disabled="loading"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    close
                    @click:close="deleteChip(index, 'mainDoc')"
                    :disabled="loading"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12" v-if="tipo_envio === '2'">
              <v-file-input
                accept="application/pdf"
                chips
                :label="$t('notificationProperties.acuse')"
                prepend-icon="mdi-receipt-text-check-outline"
                v-model="acuseDoc"
                :placeholder="$t('attachAcuse')"
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                chips
                multiple
                :label="$t('annexes')"
                prepend-icon="mdi-file-multiple"
                v-model="annexesDoc"
                :placeholder="$t('attachAnnexes')"
                :disabled="loading"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    close
                    @click:close="deleteChip(index, 'annexesDoc')"
                    :disabled="loading"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="performAction"
          id="confirm-cif"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import rules from '@/utils/rules'
import { toBase64Async } from '@/utils/file'
import { getPredefinedFilters } from '@/services/filter-service'
import { notificationCreate } from '@/services/notification-service'
export default {
  props: {
    notification: Object,
    action: String,
    cifs: Array,
  },
  components: {
    DialogButton,
  },
  watch: {
    customFilter: function (newValue) {
      if (newValue === true) this.filter = undefined
    },
    fecha_puesta_disposicion: function () {
      if (new Date(this.fecha_aceptacion) < new Date(this.minFechaAceptacion))
        this.fecha_aceptacion = ''
    },
  },
  data() {
    return {
      loading: false,
      validNotificationForm: undefined,
      dialog: false,
      cif: undefined,
      customFilter: false,
      codigo_organismo_emisor: undefined,
      nombre_organismo_emisor: undefined,
      codigo_organismo_emisor_raiz: undefined,
      nombre_organismo_emisor_raiz: undefined,
      concepto: undefined,
      tipo_envio: undefined,
      fecha_puesta_disposicion: '',
      menu_fecha_puesta_disposicion: false,
      fecha_aceptacion: '',
      menu_fecha_aceptacion: false,
      mainDoc: [],
      acuseDoc: undefined,
      annexesDoc: [],
      filter: undefined,
      // TODO: think if we want to show personalized filters (branch 1567)
      filters: getPredefinedFilters(/*this.$store.state.user.id*/),
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Removed an specific document
     * @param {number} index Index where the file to delete is.
     * @param {string} key Key string of the context.
     */
    deleteChip(index, key) {
      this[key].splice(index, 1)
    },
    /**
     * Removes the selected certificate
     */
    removeCertificate() {
      this.certificate = undefined
      this.certificateB64 = []
    },
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.dialog = false
      this.$refs.notificationForm?.reset()
      this.$refs.notificationForm?.resetValidation()
      this.loading = false
    },
    /**
     * Fix field for DIR3 changing it to UpperCase and removing special characters
     */
    fixAndUppercase(field) {
      if (this[field]) {
        const regex = /[^a-zA-Z0-9]/g
        this[field] = this[field].replace(regex, '').toUpperCase()
      }
    },
    /**
     * Gets the base64 and metadata of a file.
     * @param {Object} file File to transform.
     */
    async fileToBase64(file) {
      return await toBase64Async(file)
    },
    /**
     * Function to upload the certificate
     */
    async performAction() {
      if (!this.$refs.notificationForm?.validate()) return

      this.loading = true
      try {
        // Getting the documents
        const mainDocs = await Promise.all(
          this.mainDoc.map(async (file) => await this.fileToBase64(file))
        )
        const annexesDocs = await Promise.all(
          this.annexesDoc.map(async (file) => await this.fileToBase64(file))
        )
        const acuseDoc =
          this.tipo_envio === '2' && this.acuseDoc
            ? await this.fileToBase64(this.acuseDoc)
            : ''

        const notification = {
          codigo_organismo_emisor: this.customFilter
            ? this.codigo_organismo_emisor
            : this.filter.codigo_organismo_emisor,
          codigo_organismo_emisor_raiz: this.customFilter
            ? this.codigo_organismo_emisor_raiz
            : this.filter.codigo_organismo_emisor_raiz,
          nombre_organismo_emisor: this.customFilter
            ? this.nombre_organismo_emisor
            : this.filter.nombre_organismo_emisor,
          nombre_organismo_emisor_raiz: this.customFilter
            ? this.nombre_organismo_emisor_raiz
            : this.filter.nombre_organismo_emisor_raiz,
          concepto: this.concepto,
          fecha_puesta_disposicion: this.fecha_puesta_disposicion,
          fecha_aceptacion: this.fecha_aceptacion,
          cif: this.cif,
          tipo_envio: this.tipo_envio,
          mainDocs,
          acuseDoc,
          annexesDocs,
        }

        const notificationId = await notificationCreate(notification)

        const message = this.$t('notificationActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
        this.$router.push({
          name: 'NotificationDetailView',
          params: { id: notificationId },
        })
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    /**
     * Gets the maximum date of a fecha de aceptación based on the
     * fecha_puesta_disposicion
     */
    maxFechaAceptacion() {
      const date = !this.fecha_puesta_disposicion
        ? new Date()
        : new Date(this.fecha_puesta_disposicion)

      const currentDate = new Date()

      const maxDate =
        this.tipo_envio === '2'
          ? new Date(date.setDate(date.getDate() + 11))
          : new Date()

      return maxDate.getTime() > currentDate.getTime()
        ? currentDate.toISOString().substring(0, 10)
        : maxDate.toISOString().substring(0, 10)
    },
    minFechaAceptacion() {
      return !this.fecha_puesta_disposicion
        ? new Date().toISOString().substring(0, 10)
        : new Date(this.fecha_puesta_disposicion).toISOString().substring(0, 10)
    },
    ...mapGetters(['isMobile']),
    /**
     * Checks if the action is edit
     */
    isCreate() {
      return this.action === 'create'
    },
    /**
     * Checks if the action is accept
     */
    isUpdate() {
      return this.action === 'update'
    },
    /**
     * Checks if the action is accept
     */
    isDelete() {
      return this.action === 'delete'
    },
  },
}
</script>
