<template>
  <v-dialog :value="true" persistent :max-width="isDelete ? 570 : 1000">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{ isDisplay ? group.name : $t(`groupActions.title.${action}`) }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-tooltip bottom v-if="isAdminUser">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDisplay"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  class="primary secondary--text"
                  fab
                  small
                  @click="clickEditGroupInfo"
                  id="edit-group"
                >
                  <v-icon v-if="userIsEditing"> mdi-close </v-icon>
                  <v-icon v-else> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <div>
                {{
                  userIsEditing
                    ? $t('cancelUpdate')
                    : $t('groupActions.title.update')
                }}
              </div>
            </v-tooltip>
            <v-btn
              v-else
              icon
              small
              @click="closeDialog"
              id="close-group-dialog"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="isDelete" class="body-1">
        <v-col align="center">
          <h2 class="mt-1">{{ group.name }}</h2>
          <span>{{ $t('deleteGroup') }}</span>
        </v-col>
      </v-card-text>

      <v-card-text v-else-if="isDisplay || isCreate || isUpdate">
        <v-form
          ref="groupForm"
          v-model="validGroupForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row class="mt-4" align="baseline">
            <v-col cols="12">
              <v-text-field
                :disabled="loading || isDisplayAndUserIsNotEditing"
                :label="$t('groupName')"
                prepend-icon="mdi-account-group"
                :value="name"
                @input="name = $event"
                :rules="[
                  notEmpty,
                  maxCharacters(maxChars),
                  isCreate
                    ? unique(groupNames, 'groupNameAlreadyExists')
                    : unique(
                        groupNamesWithoutCurrent,
                        'groupNameAlreadyExists'
                      ),
                ]"
                dense
                autocomplete="false"
                id="group-name-input"
              />
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-autocomplete
                dense
                v-model="selectedCifs"
                :items="cifs"
                color="primary"
                :filter="customFilterCifs"
                :no-data-text="$t('emptyCifs')"
                :label="$t('selectCifs')"
                item-value="id"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                prepend-icon="mdi-city"
                :menu-props="{ bottom: true, offsetY: true }"
                id="group-autocomplete-cifs"
                :rules="[notEmpty]"
                multiple
                clearable
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selectAll('cifs')"
                    style="cursor: pointer"
                  >
                    <v-list-item-icon class="my-3 mr-2">
                      <v-icon
                        :color="selectedCifs?.length > 0 ? 'primary' : ''"
                      >
                        {{ iconSelectAll('cifs') }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="body-1">
                      {{ $t('selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider light class="mt-0"></v-divider>
                </template>
                <template v-slot:item="{ item }">
                  <span id="group-cif-option">
                    {{ item.numeroDocIdentidad }} - {{ item.empresa }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-1"
                        :disabled="loading"
                        id="group-cif-selected"
                      >
                        {{ item.numeroDocIdentidad }} -
                        {{ getShortText(item.empresa, 18) }}
                      </v-chip>
                    </template>
                    <div width="300">
                      {{ item.numeroDocIdentidad }} -
                      {{ item.empresa }}
                    </div>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-autocomplete
                dense
                v-model="selectedFilters"
                :items="filters"
                color="primary"
                :filter="customFilterFilter"
                :no-data-text="$t('emptyFilters')"
                :label="$t('selectFilters')"
                item-value="id"
                item-text="name"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                prepend-icon="mdi-camera-control"
                :menu-props="{ bottom: true, offsetY: true }"
                id="group-autocomplete-filters"
                :rules="[notEmpty]"
                multiple
                clearable
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selectAll('filters')"
                    style="cursor: pointer"
                  >
                    <v-list-item-icon class="my-3 mr-2">
                      <v-icon
                        :color="selectedFilters?.length > 0 ? 'primary' : ''"
                      >
                        {{ iconSelectAll('filters') }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="body-1">
                      {{ $t('selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider light class="mt-0"></v-divider>
                </template>
                <template v-slot:item="{ item }">
                  <span id="group-filter-option">
                    {{ item.isCustom ? item.name : $t(`filters.${item.name}`) }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip
                    v-if="!item.types"
                    small
                    class="ma-1"
                    :disabled="loading"
                    id="group-filter-selected"
                  >
                    {{ item.isCustom ? item.name : $t(`filters.${item.name}`) }}
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    class="ma-1"
                    :disabled="loading"
                    id="group-filter-selected"
                  >
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-autocomplete
                dense
                v-model="selectedOperators"
                :items="this.operators"
                color="primary"
                :filter="customFilterOperators"
                :no-data-text="$t('emptyOperators')"
                :label="$t('selectOperators')"
                item-value="id"
                item-text="name"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                prepend-icon="mdi-account"
                :menu-props="{ bottom: true, offsetY: true }"
                id="group-autocomplete-operator"
                multiple
                clearable
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selectAll('operators')"
                    style="cursor: pointer"
                  >
                    <v-list-item-icon class="my-3 mr-2">
                      <v-icon
                        :color="selectedOperators?.length > 0 ? 'primary' : ''"
                      >
                        {{ iconSelectAll('operators') }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="body-1">
                      {{ $t('selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider light class="mt-0"></v-divider>
                </template>
                <template v-slot:item="{ item }">
                  <span id="group-operator-option">
                    {{ item.name + ' ' + item.surname }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        small
                        class="ma-1"
                        :disabled="loading"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ getShortText(item.name + ' ' + item.surname, 33) }}
                      </v-chip>
                    </template>
                    <div width="300">
                      {{ item.name + ' ' + item.surname }}
                    </div>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center" v-if="isAdminUser">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-group-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || isDisplayAndUserIsNotEditing"
          @click="performAction"
          id="confirm-group"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
// import {
//   createAutomatedTask,
//   deleteGroup,
//   updateAutomatedTask,
// } from '@/services/groups-service'
import {
  createGroup,
  deleteGroup,
  updateGroup,
} from '@/services/groups-service'
import { CIFDoc, Doc } from '../../firebase-exports'

export default {
  props: {
    group: Object,
    groups: Array,
    operators: Array,
    filters: Array,
    cifs: Array,
    action: String,
    mappedOperators: Object,
    mappedCifs: Object,
  },
  created() {
    this.filterMap = {}
    this.filters.forEach((filter) => (this.filterMap[filter.id] = filter))
  },
  mounted() {
    if (this.group?.id) {
      this.name = this.group.name
      this.selectedCifs = this.group.cifsRef?.map((ref) => ref.id)

      const operators = this.mappedOperators[this.group.id]
      this.selectedOperators = operators?.map((operator) => operator.id) ?? []

      this.selectedFilters = this.group.filtersRef.map((ref) => ref.id)
    }
    this.configured = true
  },
  data() {
    return {
      filterMap: {},
      validGroupForm: false,
      maxChars: 100,
      loading: false,
      userIsEditing: false,
      name: undefined,
      selectedCifs: undefined,
      selectedOperators: undefined,
      selectedFilters: undefined,
      configured: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Shortens the text.
     * @param {String} text Text to shorten.
     * @param {Number} size Size of the text.
     */
    getShortText(text, size = 12) {
      return text.length > size ? text.substring(0, size - 3) + '...' : text
    },
    /**
     * Selects all items of a given subject.
     * @param {String} subject The subject to select items from.
     */
    selectAll(subject) {
      if (subject === 'operators') {
        if (this.selectedOperators?.length === this.operators.length)
          this.selectedOperators = []
        else
          this.selectedOperators = this.operators.map((operator) => operator.id)
      } else if (subject === 'filters') {
        if (this.selectedFilters?.length === this.filters.length)
          this.selectedFilters = []
        else this.selectedFilters = this.filters.map((filter) => filter.id)
      } else if (subject === 'cifs') {
        if (this.selectedCifs?.length === this.cifs.length)
          this.selectedCifs = []
        else this.selectedCifs = this.cifs.map((cif) => cif.id)
      }
    },
    /**
     * Closes the dialog
     */
    closeDialog() {
      this.$emit('closeDialog')
    },
    /**
     * When the pencil button is clicked, the user is allowed to edit.
     */
    clickEditGroupInfo() {
      this.userIsEditing = !this.userIsEditing
    },
    /**
     * Performs the action of the dialog (create, update or delete).
     */
    async performAction() {
      if (!this.isDelete && !this.$refs.groupForm?.validate()) return

      const userId = this.$store.state.user.parentRef
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id

      this.loading = true

      try {
        const message = this.$t('groupActions.success.' + this.action)

        if (this.isCreate) {
          await createGroup(
            {
              name: this.name,
              filtersRef:
                this.selectedFilters?.map((filterId) =>
                  Doc(this.filterMap[filterId].path)
                ) ?? [],
              cifsRef:
                this.selectedCifs?.map((cifId) => CIFDoc(userId, cifId)) ?? [],
            },
            this.selectedOperators,
            this.selectedCifs
          )
        } else if (this.isUpdate || this.isDisplay) {
          await updateGroup(
            this.group.id,
            {
              name: this.name,
              filtersRef:
                this.selectedFilters?.map((filterId) =>
                  Doc(this.filterMap[filterId].path)
                ) ?? [],
              cifsRef:
                this.selectedCifs?.map((cifId) => CIFDoc(userId, cifId)) ?? [],
            },
            this.mappedOperators[this.group.id],
            this.selectedOperators
          )
        } else if (this.isDelete) {
          await deleteGroup(this.group.id, this.mappedOperators[this.group.id])
        }

        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * Given a list of objects with names, it returns a new list of just names.
     * @param {Array} list List that contains objects with names.
     * @returns Array with the names of the given list.
     */
    getNames(list = []) {
      return list.map((item) => item.name)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * Cif version.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterCifs(item, queryText) {
      const empresa = item.empresa.toLowerCase()
      const cif = item.numeroDocIdentidad.toLowerCase()
      const searchText = queryText.toLowerCase()
      return empresa.includes(searchText) || cif.includes(searchText)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterFilter(item, queryText) {
      const name = item.isCustom
        ? item.name.toLowerCase()
        : this.$t(`filters.${item.name}`).toLocaleLowerCase()
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterOperators(item, queryText) {
      const name = item.name.toLowerCase() + ' ' + item.surname.toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText)
    },
    iconSelectAll(subject) {
      if (subject === 'operators') {
        if (this.selectedOperators?.length === this.operators.length)
          return 'mdi-close-box'
        if (this.selectedOperators?.length > 0) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      } else if (subject === 'cifs') {
        if (this.selectedCifs?.length === this.cifs.length)
          return 'mdi-close-box'
        if (this.selectedCifs?.length > 0) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      } else if (subject === 'filters') {
        if (this.selectedFilters?.length === this.filters.length)
          return 'mdi-close-box'
        if (this.selectedFilters?.length > 0) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    isCreate() {
      return this.action === 'create'
    },
    isUpdate() {
      return this.action === 'update'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    isDisplayAndUserIsNotEditing() {
      return this.action === 'display' && !this.userIsEditing
    },
    groupNames() {
      return this.groups?.map((m) => m.name) ?? []
    },
    groupNamesWithoutCurrent() {
      return (
        this.groups
          ?.map((m) => m.name)
          .filter((name) => name !== this.group.name) ?? []
      )
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
