<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <CifsList
          :cifs="cifs"
          :groups="groups"
          :groupMap="groupMap"
          :notificationsMaxCifs="notificationsMaxCifs"
          :signaturesMaxCerts="signaturesMaxCerts"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        v-if="
          cifsUnregistered &&
          (isSuperAdminUser ||
            isSuperOperatorUser ||
            isAdminAndCanManageCompanies)
        "
      >
        <CifsUnregistered
          :cifs="cifsUnregistered"
          :groups="groups"
          :groupMap="groupMap"
          :notificationsMaxCifs="notificationsMaxCifs"
          :signaturesMaxCerts="signaturesMaxCerts"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        v-if="
          groupUnRegisteredCifs?.length > 0 &&
          userGroup &&
          isAdminAndCanManageCompanies
        "
      >
        <CifsUnregisteredGroups
          :cifs="groupUnRegisteredCifs"
          :group="userGroup"
          @reload="loadUnregistered"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CifsList from '@/components/cifs/CifsList'
import CifsUnregistered from '@/components/cifs/CifsUnregistered'
import CifsUnregisteredGroups from '@/components/cifs/CifsUnregisteredGroups'

import {
  getCifsSubscription,
  getClientCifsSubscription,
} from '@/services/cifs-service'
import {
  getCifGroupMap,
  getGroup,
  getGroupsSubscription,
} from '@/services/groups-service'
import { mapGetters, mapMutations } from 'vuex'
import { getGroupUnregisteredCifs } from '../services/groups-service'

export default {
  components: {
    CifsList,
    CifsUnregistered,
    CifsUnregisteredGroups,
  },
  data() {
    return {
      groups: [],
      groupsUnsubscribe: () => {},
      userGroup: undefined,
      groupUnRegisteredCifs: [],
      groupMap: {},
      loading: true,
    }
  },
  async destroyed() {
    if (this.groupsUnsubscribe) this.groupsUnsubscribe()
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    if (!this.$store.state.manageCompanies) {
      if (this.$store.state.user.tipo === 'clienteDespacho')
        await getClientCifsSubscription(userId, this.$store.state.user.id)
      else
        await getCifsSubscription(
          userId,
          !this.isSuperAdminUser &&
            !this.isSuperOperatorUser &&
            !this.isAdminAndCanManageCompanies
            ? this.$store.state.user.id
            : undefined,
          true
        )
    }

    if (
      this.isSuperAdminUser ||
      this.isSuperOperatorUser ||
      (this.isAdminAndCanManageCompanies && !this.isInGroupUser)
    )
      this.groupsUnsubscribe = await getGroupsSubscription(this)
    else if (this.isAdminAndCanManageCompanies && this.isInGroupUser)
      this.loadUnregistered()

    // Hiding the pending
    this.loading = false
    this.setPending({ cifs: false })
  },
  methods: {
    ...mapMutations(['setPending']),
    /**
     * Function that loads the unregistered cifs of a group
     */
    async loadUnregistered() {
      this.userGroup = await getGroup(this.$store.state.user.groupsRef[0])
      this.groupUnRegisteredCifs = await getGroupUnregisteredCifs(
        this.userGroup.unregisteredCifs
      )
    },
  },
  computed: {
    ...mapGetters([
      'isOperatorUser',
      'isAdminUser',
      'isEmpresaUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAdminAndCanManageCompanies',
      'isInGroupUser',
    ]),
    cifs() {
      return this.$store.state.manageCompanies ?? []
    },
    notificationsMaxCifs() {
      return this.$store.state.plan?.notificationsMaxCifs
    },
    signaturesMaxCerts() {
      return this.$store.state.plan?.signaturesMaxCerts
    },
    cifsUnregistered() {
      if (this.isEmpresaUser) return undefined

      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id

      const mainCif = this.cifs?.find((cif) => cif.id === userId)
      const unregisteredCifs = mainCif?.unregisteredCifs

      if (unregisteredCifs?.length)
        return unregisteredCifs.sort((a, b) => a.name.localeCompare(b.name))
      else return undefined
    },
  },
  watch: {
    cifs: function (newValue) {
      if (newValue && this.groups)
        this.groupMap = getCifGroupMap(this.groups, this.cifs)
    },
    groups: function (newValue) {
      if (newValue && this.cifs)
        this.groupMap = getCifGroupMap(this.groups, this.cifs)
    },
  },
}
</script>
