<template>
  <div>
    <v-btn
      v-for="socialNetwork in socials"
      :key="socialNetwork.icon"
      class="mx-4 secondary--text"
      icon
      :href="socialNetwork.url"
      target="_blank"
      :x-small="!isMobile"
    >
      <v-icon> {{ socialNetwork.icon }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
import addaliaSocials from '@/utils/socials.json'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      socials: addaliaSocials,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>
