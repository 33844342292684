import {
  AutomatedTaskDoc,
  AutomatedTaskHistoryCollection,
  AutomatedTasksCollection,
  Firestore,
} from '@/firebase-exports'
import store from '@/store/index'

/**
 * Returns a list of the automated tasks.
 * @returns Gets a list of the automated tasks.
 */
export async function listAutomatedTasks() {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const querySnapshot = await Firestore.getDocs(
    AutomatedTasksCollection(userId)
  )
  return querySnapshot.docs.map((doc) => {
    const document = doc.data()

    return { id: doc.id, ...document }
  })
}

/**
 * Creates a subscription to the automated tasks of a user.
 * @param {Object} context Context of the view.
 * @returns Unsubscribe method to the subscription.
 */
export function getAutomatedTasksSubscription() {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  var automatedTasksUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      AutomatedTasksCollection(userId),
      Firestore.orderBy('name')
    ),
    (snapShot) => {
      let automatedTasks = snapShot.docs.map((doc) => {
        const document = doc.data()

        return { id: doc.id, ...document }
      })

      store.commit('setManageAutomatedTasks', automatedTasks)
    },
    (error) => {
      throw error
    }
  )

  store.commit('setManageAutomatedTasksUnsubscribe', automatedTasksUnsubscribe)
  return automatedTasksUnsubscribe
}

/**
 * Creates an automated task for the logged user.
 * @param {Object} data Data of the automated task.
 */
export const createAutomatedTask = async (data) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await Firestore.addDoc(AutomatedTasksCollection(userId), data)
}

/**
 * Updates an auotmated task.
 * @param {string} automatedTaskId Id of the automated task to update.
 * @param {Object} data Data of the automated task.
 */
export const updateAutomatedTask = async (automatedTaskId, data) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await Firestore.updateDoc(AutomatedTaskDoc(userId, automatedTaskId), data)
}

/**
 * Deletes an automated task.
 * @param {String} automatedTaskId Id of the automated task to delete.
 */
export const deleteAutomatedTask = async (automatedTaskId) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  // Deleting the history
  const history = await listAutomatedTaskHistory(automatedTaskId)
  await Promise.all(history.map(async (h) => await Firestore.deleteDoc(h.ref)))

  // Deleting the automated task
  await Firestore.deleteDoc(AutomatedTaskDoc(userId, automatedTaskId))
}

/**
 * Function to get the history of an automated task
 * @param {String} automatedTaskId Id of the automated task.
 * @returns Array with the history of the automated task.
 */
export const listAutomatedTaskHistory = async (automatedTaskId) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const querySnapshot = await Firestore.getDocs(
    Firestore.query(
      AutomatedTaskHistoryCollection(userId, automatedTaskId),
      Firestore.orderBy('executedAt')
    )
  )
  return querySnapshot.docs.map((doc) => {
    const document = doc.data()

    return { id: doc.id, ...document, ref: doc.ref }
  })
}

/**
 * Creates a subscription to the automated tasks of a user.
 * @param {String} automatedTaskId Id of the automated task.
 * @param {Object} context Context of the view.
 * @returns Unsubscribe method to the subscription.
 */
export function getAutomatedTaskHistorySubscription(automatedTaskId, context) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  var automatedTaskHistoryUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      AutomatedTaskHistoryCollection(userId, automatedTaskId),
      Firestore.orderBy('executedAt', 'desc')
    ),
    async (snapShot) => {
      context.history = snapShot.docs.map((doc) => {
        const document = doc.data()

        return { id: doc.id, ...document }
      })
      if (context.loading)
        await new Promise((resolve) =>
          setTimeout(() => {
            context.loading = false
            resolve()
          }, 350)
        )
    },
    (error) => {
      throw error
    }
  )
  return automatedTaskHistoryUnsubscribe
}

/**
 * Clones an automated task giving it a new name.
 * @param {Object} automatedTask Automated task to clone.
 * @param {String} name Name for the automated task.
 */
export async function cloneAutomatedTask(automatedTask, name) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  delete automatedTask.id

  await Firestore.addDoc(AutomatedTasksCollection(userId), {
    ...automatedTask,
    name,
  })
}
