<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          {{ $t('clientActions.title.accept') }}
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t('legalTextAcceptCertificate') }}</p>
        <p class="body-2">{{ $t('legalTextAcceptCertificate2') }}</p>
        <v-checkbox
          class="my-checkbox mr-3 mt-0 body-2"
          v-model="termsAccepted"
          :label="$t('acceptTermsCertificate')"
          id="accept-terms-certificate"
        />
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || !termsAccepted"
          @click="authorize"
          id="confirm-cif"
        >
          {{ $t('authorize') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    cif: Object,
    action: String,
  },
  data() {
    return {
      loading: false,
      termsAccepted: false,
      showError: false,
    }
  },
  methods: {
    /**
     * Calls to the parent function to close the dialog.
     */
    closeDialog() {
      this.$emit('cancel')
    },
    /**
     * Calls to the parent function to accept the authorization.
     */
    authorize() {
      if (!this.termsAccepted) return
      this.$emit('authorize')
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  watch: {},
}
</script>

<style>
.my-checkbox .v-label {
  font-size: 15px;
}
</style>
