import { render, staticRenderFns } from "./NotificationStats.vue?vue&type=template&id=659ba01e&scoped=true"
import script from "./NotificationStats.vue?vue&type=script&lang=js"
export * from "./NotificationStats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "659ba01e",
  null
  
)

export default component.exports