<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <GroupList
          :groups="groups"
          :operators="operators"
          :filters="filters"
          :cifs="cifs"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import GroupList from '@/components/groups/GroupList'
import { listFilters } from '@/services/filter-service'
import { getGroupsSubscription } from '@/services/groups-service'
import { getOperatorsSubscriptionSimplified } from '@/services/operator-service'
import { getCifsSubscriptionSimplified } from '@/services/cifs-service'
export default {
  components: {
    GroupList,
  },
  data() {
    return {
      loading: true,
      groups: [],
      operators: [],
      filters: [],
      cifs: [],
      groupsUnsubscribe: () => {},
      operatorsUnsubscribe: () => {},
      cifsUnsubscribe: () => {},
    }
  },
  async destroyed() {
    if (this.groupsUnsubscribe) this.groupsUnsubscribe()
    if (this.operatorsUnsubscribe) this.operatorsUnsubscribe()
    if (this.cifsUnsubscribe) this.cifsUnsubscribe()
  },
  async created() {
    const userId = this.$store.state.user.parentRef
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    // Loading the groups subscription
    this.groupsUnsubscribe = await getGroupsSubscription(this, true)
    this.operatorsUnsubscribe = await getOperatorsSubscriptionSimplified(
      userId,
      this
    )
    this.cifsUnsubscribe = await getCifsSubscriptionSimplified(userId, this)
    this.filters = await listFilters()

    // Hiding the pending
    this.loading = false
    this.setPending({ groups: false })
  },
  computed: {},
  methods: {
    ...mapMutations(['setPending']),
  },
}
</script>
