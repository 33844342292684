<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <SignaturesList
          :loading="loading"
          :signaturesMax="signaturesMax"
          :signaturesUsed="signaturesUsed"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignaturesList from '@/components/signatures/SignaturesList'
import { mapGetters } from 'vuex'

export default {
  components: {
    SignaturesList,
  },
  async created() {
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    signaturesUsed() {
      return this.$store.state.plan?.signaturesUsed
    },
    signaturesMax() {
      return this.$store.state.plan?.signaturesMax
    },
  },
  data() {
    return {
      loading: true,
      cifs: [],
    }
  },
}
</script>
