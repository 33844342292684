var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('CifsList',{attrs:{"cifs":_vm.cifs,"groups":_vm.groups,"groupMap":_vm.groupMap,"notificationsMaxCifs":_vm.notificationsMaxCifs,"signaturesMaxCerts":_vm.signaturesMaxCerts,"loading":_vm.loading}})],1),(
        _vm.cifsUnregistered &&
        (_vm.isSuperAdminUser ||
          _vm.isSuperOperatorUser ||
          _vm.isAdminAndCanManageCompanies)
      )?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('CifsUnregistered',{attrs:{"cifs":_vm.cifsUnregistered,"groups":_vm.groups,"groupMap":_vm.groupMap,"notificationsMaxCifs":_vm.notificationsMaxCifs,"signaturesMaxCerts":_vm.signaturesMaxCerts}})],1):_vm._e(),(
        _vm.groupUnRegisteredCifs?.length > 0 &&
        _vm.userGroup &&
        _vm.isAdminAndCanManageCompanies
      )?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('CifsUnregisteredGroups',{attrs:{"cifs":_vm.groupUnRegisteredCifs,"group":_vm.userGroup},on:{"reload":_vm.loadUnregistered}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }