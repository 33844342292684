<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <PrivacyPolicy />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrivacyPolicy from '@/components/legal/PrivacyPolicy'

export default {
  components: {
    PrivacyPolicy,
  },
}
</script>
