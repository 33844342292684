<template>
  <div style="width: 100%">
    <apexchart
      type="treemap"
      :height="height"
      :options="series.chartOptions"
      :series="series.series"
    ></apexchart>
    <v-icon
      v-if="series.loading"
      style="top: -40%; left: 50%; z-index: 999; position: relative"
    >
      mdi-autorenew mdi-spin
    </v-icon>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

import { isBetweenDates } from '@/utils/date-utils'

//const dateStringFormat = 'YYYYMMDD'

// Valor 1 para las Comunicaciones, Valor 2 para las Notificaciones
const typeComunication = '1'
const typeNotification = '2'

const notificationTypeComunication = 'Comunicacion'
const notificationTypeNotification = 'Notificacion'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartname: String,
    title: String,
    type: String,
    notifications: Array,
    rootEmitterCodes: Object,
    height: String,
    dateFrom: String,
    dateTo: String,
  },
  data() {
    var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    return {
      filteredNotifications: [],
      gridNotifications: [],
      actualDateFrom: '',
      actualDateTo: '',
      chartOptions: {
        chart: {
          stacked: true,
        },
        theme: {
          mode: theme,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: [],
        },
        colors: ['#008FFB', '#00E396'],
        noData: {
          text: this.$t('graphs.loading'),
        },
      },
    }
  },

  computed: {
    series() {
      if (this.notifications === undefined || this.notifications.length === 0) {
        return {
          chartOptions: this.chartOptions,
          series: [],
          loading: true,
          filteredNotifications: [],
        }
      }
      let dataMap = {}
      var startDate = this.dateFrom.substring(0, 6) + '01' //YYYYMMDD
      var filteredByDates = this.notifications.filter((notification) => {
        return isBetweenDates(
          notification.fecha_puesta_disposicion,
          startDate,
          this.dateTo,
          this.timeGroup
        )
      })
      filteredByDates.forEach((notification) => {
        let type = this.parseType(notification.tipo_envio)
        if (type === '') {
          console.log('unexpected notification type')
        }
        this.updateDataMap(dataMap, notification, type)
      })
      let series = []
      let dataN = []
      let dataC = []

      Object.keys(dataMap).forEach((key) => {
        if (dataMap[key].Notificacion > 0) {
          dataN.push({
            x: this.$t('filters.' + key),
            y: dataMap[key].Notificacion,
          })
        }
        if (dataMap[key].Comunicacion > 0) {
          dataC.push({
            x: this.$t('filters.' + key),
            y: dataMap[key].Comunicacion,
          })
        }
      })
      // dataN = dataN.filter((element) => {
      //   return element.y > 0
      // })
      if (dataN.length > 0)
        series.push({ name: this.$t('graphs.notificationsLabel'), data: dataN }) // will be on index 0
      if (dataC.length > 0)
        series.push({ name: this.$t('graphs.comunicationsLabel'), data: dataC }) // will be on index 1

      let pack = {
        chartOptions: this.createCustomOptions(series, filteredByDates),
        series: series,
        loading: false,
      }

      //console.log(series)
      return pack
    },
  },
  methods: {
    createCustomOptions(series, filteredByDates) {
      return {
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
        xaxis: {
          categories: [
            this.$t('graphs.notificationsLabel'),
            this.$t('graphs.comunicationsLabel'),
          ],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        legend: {
          show: true,
        },
        chart: {
          legend: {
            show: true,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              setTimeout(() => {
                this.showGrid(
                  config.seriesIndex === 1 ? '1' : '2',
                  series[config.seriesIndex].data[config.dataPointIndex].x,
                  filteredByDates
                )
              }, 10)
            },
          },
          toolbar: {
            show: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
      }
    },
    parseType(type) {
      switch (type) {
        case typeComunication:
          return notificationTypeComunication
        case typeNotification:
          return notificationTypeNotification
        default:
          return ''
      }
    },
    getRootEmitterName(rootCode) {
      var name = this.rootEmitterCodes[rootCode]
      if (name === undefined) {
        name = 'otherEmitters' // clave creada en i18n.filters
      }
      return name
    },
    updateDataMap(dataMap, notification, type) {
      var rootIndex = this.getRootEmitterName(
        notification.codigo_organismo_emisor_raiz
      )

      if (dataMap[rootIndex] === undefined) {
        dataMap[rootIndex] = {
          Comunicacion: 0,
          Notificacion: 0,
        }
      }

      if (type === notificationTypeComunication) {
        dataMap[rootIndex].Comunicacion += 1
      } else {
        dataMap[rootIndex].Notificacion += 1
      }
    },
    generateDataKeyList() {
      let currentMoment = moment(this.actualDateFrom)
      let endMoment = moment(this.actualDateTo)
      let keyList = []
      while (currentMoment.isSameOrBefore(endMoment, this.timeUnit)) {
        keyList.push(currentMoment.format(this.timeGroup))
        currentMoment.add(1, this.timeUnit)
      }
      return keyList
    },
    getEstado(notification) {
      return notification.estado
    },
    showGrid(seriexIndex, emitterName, filteredByDates) {
      let title =
        seriexIndex === '1'
          ? this.$t('graphs.comunicationsLabel')
          : this.$t('graphs.notificationsLabel')
      this.gridNotifications = filteredByDates.filter((notification) => {
        var name = this.getRootEmitterName(
          notification.codigo_organismo_emisor_raiz
        )
        var i18Nname = this.$t('filters.' + name)
        return (
          (emitterName === i18Nname) & (notification.tipo_envio === seriexIndex)
        )
      })

      this.$emit(
        'detail-level',
        this.translateGridNotificationsEmitter(),
        title
      )
    },
    translateGridNotificationsEmitter() {
      var translatedNotifications = []
      this.gridNotifications.forEach((notification) => {
        var newNotification = notification
        newNotification.nombre_organismo_emisor = this.$t(
          'filters.' +
            this.getRootEmitterName(notification.codigo_organismo_emisor_raiz)
        )
        translatedNotifications.push(newNotification)
      })
      return translatedNotifications
    },
    setInitialValues() {
      this.actualDateFrom = this.dateFrom
      this.actualDateTo = this.dateTo
    },
  },
  created() {
    this.setInitialValues()
  },
  watch: {
    dateFrom: {
      deep: true,
      handler: function () {
        this.setInitialValues()
      },
    },
  },
}
</script>
