<template>
  <div id="test">
    <v-dialog v-model="show" persistent>
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <NotificationsTable
          :loading="false"
          :headers="headers"
          :mobileHeaders="mobileHeaders"
          :notifications="notificationParsed"
          @clickRow="notificationClicked"
        ></NotificationsTable>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NotificationsTable from '@/components/notifications/NotificationsTable'

export default {
  components: {
    NotificationsTable,
  },
  props: {
    showDialog: Boolean,
    notifications: [],
    title: String,
  },
  data() {
    return {
      headers: [
        'id',
        'concepto',
        'nif_titular',
        'nombre_titular',
        'fecha_puesta_disposicion',
        'estado',
        'nombre_organismo_emisor',
      ],
      mobileHeaders: [
        'id',
        'concepto',
        'nif_titular',
        'nombre_titular',
        'fecha_puesta_disposicion',
        'estado',
        'nombre_organismo_emisor',
      ],
      mustBeShow: this.showDialog,
    }
  },
  computed: {
    show() {
      return this.showDialog
    },
    notificationParsed() {
      return this.notifications.map((n) => ({
        ...n,
        fecha_puesta_disposicion: new Date(n.fecha_puesta_disposicion),
      }))
    },
  },
  methods: {
    close() {
      this.mustBeShow = false
      this.$emit('dialog-closed')
    },
    notificationClicked(notificationSelected) {
      console.log(notificationSelected)

      window.open('/notification' + '/' + notificationSelected.id, '_blank')
    },
  },
  created() {},
  watch: {},
}
</script>
