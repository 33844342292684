import moment from 'moment'

const fake = false

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

const baseOperator = {
  adminPermissions: false,
  cifsPermissions: {},
  cifsRef: [],
  disabled: false,
  downloadNotification: true,
  email: 'enrique.sanchez+operadortour@addalia.com',
  emailsConfiguration: {},
  filtersRef: [],
  firmasActivas: true,
  id: 'Tt9p8kPEXsRB9VvewWhEQWnmllg1',
  name: 'Enrique',
  notificacionesActivas: false,
  parentRef: {},
  processNotification: true,
  readNotification: true,
  surname: 'Operador Tour',
  tipo: 'operator',
  tourAppFinished: false,
  tourNotificationsFinished: false,
  traces: [],
}

function generateRndDate() {
  '2023-09-13T22:00:00.000Z'
  var out =
    '' +
    (2020 + getRandomInt(4)) +
    '-' +
    (1 + getRandomInt(12) + '').padStart(2, '0') +
    '-' +
    (1 + getRandomInt(28) + '').padStart(2, '0') +
    'T22:00:00.000Z'
  return out
}

export async function loadAllOperators(operators) {
  if (!fake) {
    return operators
  }

  let randomOperators = []
  var max = 10
  for (let i = 0; i < max; i++) {
    var operator = JSON.parse(JSON.stringify(baseOperator))
    operator.name = 'Nombre_' + (i + 1)
    operator.surname = 'Apellidos_' + (i + 1)
    operator.id = 'ID' + (i + 1).toString()
    //console.log('-- Id generado: ' + operator.id)
    randomOperators[i] = generateRamdomTraces(operator)
  }
  return randomOperators
}

function generateRamdomTraces(operator) {
  var max = 100 + getRandomInt(500)
  for (let i = 0; i < max; i++) {
    let newTrace = {
      actionType: 'logged',
      createdAt: '',
      ip: '192.168.1.1',
      ipGeo: {
        ciudad: '',
        latitud: '',
        longitud: '',
        pais: '',
      },
    }
    newTrace.createdAt = moment(generateRndDate())

    if (i % 2 == 0) {
      newTrace.ipGeo.ciudad = 'Madrid'
      newTrace.ipGeo.latitud = parseFloat(40.3907 + getRandomInt(500) / 100000)
      newTrace.ipGeo.longitud = parseFloat(-3.6997 + getRandomInt(500) / 100000)
      newTrace.ipGeo.pais = 'España'
    } else {
      if (i % 5 == 0) {
        newTrace.ipGeo.ciudad = 'Valencia'
        newTrace.ipGeo.latitud = parseFloat(
          39.46975 + getRandomInt(500) / 100000
        )
        newTrace.ipGeo.longitud = parseFloat(
          -0.37739 + getRandomInt(500) / 100000
        )
        newTrace.ipGeo.pais = 'España'
      } else {
        newTrace.ipGeo.ciudad = 'Zaragoza'
        newTrace.ipGeo.latitud = parseFloat(
          41.65606 + getRandomInt(500) / 100000
        )
        newTrace.ipGeo.longitud = parseFloat(
          -0.87734 + getRandomInt(500) / 100000
        )
        newTrace.ipGeo.pais = 'España'
      }
    }
    operator.traces.push(newTrace)
  }
  return operator
}

export function isFake() {
  return fake
}
