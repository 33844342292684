<template>
  <v-card height="180" elevation="5" class="rounded-t-xl">
    <v-container class="pa-5" fill-height fluid>
      <v-row align="center" justify="center">
        <h1 class="ml-5 mr-4">{{ $t('notifications') }}</h1>
        <DialogButton section="dashboard" subsection="notifications" />
      </v-row>
      <v-row align="center">
        <v-col
          v-if="!isMobile"
          class="title d-flex justify-center align-center pt-3"
          cols="2"
        >
          <v-hover v-slot="{ hover }">
            <v-icon
              :class="hover ? 'accept--text' : ''"
              @click="$router.push({ name: 'NotificationsView' })"
              size="80px"
              id="notification-inbox-dashboard"
              >mdi-inbox-arrow-down
            </v-icon>
          </v-hover>
        </v-col>
        <v-col class="text-right pa-2 mt-2" cols="12" md="10" lg="10">
          <v-row>
            <v-col class="text-right" cols="6">
              <div class="body-1 d-flex justify-start align-center">
                {{ $t('notificationStatus.pending') }}: <v-spacer />
                <span>
                  <v-chip small class="bold body-1 warningLow white--text ml-2">
                    {{ pendingCount }}
                  </v-chip>
                </span>
              </div>
              <div class="body-1 mt-3 d-flex justify-start align-center">
                {{ $t('notificationStatus.urgent') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 warning ml-2">
                    {{ urgentCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex mb-3 body-1 justify-start align-center">
                {{ $t('notificationStatus.expired') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text error ml-2">
                    {{ expiredCount }}
                  </v-chip>
                </span>
              </div>
              <div class="d-flex body-1 justify-start align-center">
                {{ $t('notificationStatus.accepted') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text accept ml-2">
                    {{ acceptedCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { mapGetters } from 'vuex'
export default {
  props: {
    pendingCount: {
      type: Number,
      default: 0,
    },
    urgentCount: {
      type: Number,
      default: 0,
    },
    expiredCount: {
      type: Number,
      default: 0,
    },
    acceptedCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DialogButton,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
