import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (store.state.user) {
    const meta = to.meta
    if (!meta.userCanAccess()) {
      if (from.query?.redirect) return next({ path: '/dashboard' })
      return false
    }
  }
  store.commit('setNotFound', false)
  next()
})

router.afterEach(() => {
  if (store.state.isMobile) store.commit('setNavigationDrawer', false)
})

export default router
