<template>
  <v-row class="pa-4" justify="center">
    <v-col class="ma-5" cols="12" lg="5">
      <v-card class="mx-auto">
        <v-card-title
          :style="{
            backgroundColor: $vuetify.theme.themes.light.primary,
            color: $vuetify.theme.themes.light.secondary,
          }"
        >
          <span class="text-h6">
            <strong> {{ $t('loginInfo') }} </strong>
          </span>
        </v-card-title>
        <v-card-text class="py-0">
          <template v-if="loginInfo.length != 0">
            <template>
              <v-timeline
                v-if="loginInfo.length != 0"
                :class="isMobile ? 'ma-1' : 'ma-5'"
                align-center
                dense
                over
                class="pa-5"
              >
                <v-virtual-scroll
                  :items="loginInfo"
                  :item-height="80"
                  height="500"
                  style="overflow-x: hidden"
                >
                  <template v-slot:default="{ item }">
                    <v-row class="pt-5" v-if="item.actionType == 'logged'">
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-timeline-item
                                :icon="getTraceIcon(item.actionType)"
                                key="item.id"
                                fill-dot
                              >
                                <v-row>
                                  <strong>
                                    <h3>
                                      {{
                                        $d(item.createdAt, 'i18nDateAndHour')
                                      }}
                                    </h3>
                                  </strong>
                                </v-row>
                                <v-row v-if="item.ip">
                                  <strong>
                                    {{ $t('ip') + ': ' }}
                                  </strong>
                                  {{ item.ip }}
                                </v-row>
                              </v-timeline-item>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                </v-virtual-scroll>
              </v-timeline>
            </template>
          </template>
          <template v-else>
            <v-col justify="center">
              <b>
                <h3 text-align: center>
                  {{ $t('noRecentActivity') }}
                </h3></b
              >
            </v-col>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="ma-5 pa-3" cols="12" lg="5">
      <v-card>
        <v-card-title
          :style="{
            backgroundColor: $vuetify.theme.themes.light.primary,
            color: $vuetify.theme.themes.light.secondary,
          }"
        >
          <span class="text-h6">
            <strong>{{ $t('openedNotifications') }}</strong>
          </span>
        </v-card-title>
        <v-card-text class="py-0">
          <template v-if="openedNotifications.length != 0">
            <v-timeline
              :class="isMobile ? 'ma-1' : 'ma-5'"
              align-center
              dense
              over
              class="pa-5"
            >
              <v-virtual-scroll
                :items="openedNotifications"
                :item-height="120"
                height="500"
                style="overflow-x: hidden"
              >
                <template v-slot:default="{ item }">
                  <v-row
                    class="pt-5"
                    v-if="item.actionType == 'openNotification'"
                  >
                    <v-timeline-item
                      key="item.id"
                      :icon="getTraceIcon(item.actionType)"
                      fill-dot
                    >
                      <v-row
                        @click="openNotificationDetail(item.notificationId)"
                        :class="isMobile ? 'body-2' : 'body-1'"
                      >
                        <strong>
                          {{ $t('openedAt') + ': ' }}
                        </strong>
                        <strong>
                          {{ $d(item.createdAt, 'i18nDateAndHour') }}
                        </strong>
                      </v-row>
                      <v-row
                        @click="openNotificationDetail(item.notificationId)"
                        v-if="item.notificationId"
                      >
                        <v-tooltip max-width="400" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-container
                              class="body-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-row>
                                <b>
                                  {{ $t('notificationId') + ': ' }}
                                </b>
                                {{
                                  item.notificationId.substring(0, 4) +
                                  '...' +
                                  item.notificationId.substring(
                                    item.notificationId.length - 4
                                  )
                                }}
                              </v-row>
                              <v-row>
                                <b>
                                  {{
                                    $t('notificationProperties.concept') + ': '
                                  }}
                                </b>
                                {{
                                  item.notificationConcept.substring(0, 20) +
                                  '...'
                                }}
                              </v-row>
                            </v-container>
                          </template>
                          <div width="300">
                            <h3>{{ $t('openNotification') }}</h3>
                            {{ item.notificationConcept }}
                          </div>
                        </v-tooltip>
                      </v-row>
                    </v-timeline-item>
                  </v-row>
                </template>
              </v-virtual-scroll>
            </v-timeline>
          </template>
          <template v-else>
            <v-col justify="center">
              <b>
                <h3 text-align: center>
                  {{ $t('noRecentActivity') }}
                </h3>
              </b>
            </v-col>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    loginInfo: Array,
    openedNotifications: Array,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    getTraceIcon(type) {
      switch (type) {
        case 'created':
          return 'mdi-call-received'

        case 'logged':
          return 'mdi-login'

        case 'openNotification':
          return 'mdi-email-open-outline'

        case 'shared':
          return 'mdi-share'

        case 'assignManager':
          return 'mdi-account-hard-hat-outline'

        case 'unassignManager':
          return 'mdi-account-off-outline'

        case 'alertCreate':
          return 'mdi-bell-plus'

        case 'alertDone':
          return 'mdi-bell-check'

        case 'alertModify':
          return 'mdi-bell-ring'

        case 'managerDecline':
          return 'mdi-account-remove'

        case 'managerAccept':
          return 'mdi-account-plus'

        default:
          return ''
      }
    },
    openNotificationDetail(id) {
      this.$router.push({
        name: 'NotificationDetailView',
        params: { id },
      })
    },
  },
}
</script>
