<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.notificationDetail.title') }}
    </h2>
    <p>
      {{
        $t('documentation.texts.notifications.notificationDetail.paragraph1')
      }}
    </p>
    <p>
      {{
        $t('documentation.texts.notifications.notificationDetail.paragraph2')
      }}
    </p>
    <p>
      {{
        $t('documentation.texts.notifications.notificationDetail.paragraph3')
      }}
    </p>
    <p>
      {{
        $t('documentation.texts.notifications.notificationDetail.paragraph4')
      }}
    </p>
    <v-img style="width: 100%; max-width: 1000px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationDetail.p5') }}
    </p>
    <v-img style="width: 100%; max-width: 300px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationDetail.p6') }}
    </p>
    <v-img style="width: 100%; max-width: 500px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationDetail.p7') }}
    </p>
    <ul>
      <li>
        -
        {{ $t('metadata.referencia') }}
      </li>
      <li>
        -
        {{ $t('metadata.importeAPagar') }}
      </li>
      <li>
        -
        {{ $t('metadata.totalAIngresar') }}
      </li>
      <li>
        -
        {{ $t('metadata.codigoSeguroVerificacion') }}
      </li>
      <li>
        -
        {{ $t('metadata.fechaVencimiento') }}
      </li>
      <li>
        -
        {{ $t('metadata.baseSancion') }}
      </li>
      <li>
        -
        {{ $t('metadata.sancionResultante') }}
      </li>
      <li>
        -
        {{ $t('metadata.sancionReducida') }}
      </li>
      <li>
        -
        {{ $t('metadata.importeInteres') }}
      </li>
      <li>
        -
        {{ $t('metadata.types.resLiqProv') }}
      </li>
      <li>
        -
        {{ $t('metadata.types.AcImSaInTri') }}
      </li>
      <li>
        -
        {{ $t('metadata.types.LiInDeDeEx') }}
      </li>
      <li>
        -
        {{ $t('metadata.types.requerimiento') }}
      </li>
    </ul>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationDetail.picNotificationDetail'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t('documentation.texts.notifications.notificationDetail.pic1') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t('documentation.texts.notifications.notificationDetail.pic2') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
