<template>
  <v-container class="ma-2 pa-2" style="width: 100%; max-width: 100%">
    <NotificationsGridModal
      :notifications="detailNotifications"
      :showDialog="openGridNotification"
      :date="detailDate"
      :title="titleGrid"
      @dialog-closed="closeGrid"
    >
    </NotificationsGridModal>

    <OperatorsTracesGridModal
      :operatorsTraces="operatorsTraces"
      :showDialog="openGridOperatorsTraces"
      :date="detailDate"
      :title="titleGrid"
      @dialog-closed="closeGrid"
    >
    </OperatorsTracesGridModal>
    <v-card style="z-index: 0">
      <v-card-title class="ma-1 pa-0">{{
        $t('graphs.dashboard')
      }}</v-card-title>
      <GraphsFilter @data-change="updateGraphData"> </GraphsFilter>
    </v-card>
    <v-card style="z-index: 0" class="ma-0 pa-0">
      <v-card-title class="pa-0"> {{ $t('resumeCard.title') }} </v-card-title>
      <hr />
      <ResumeCard :operators="allOperators" :planData="allPlanData">
      </ResumeCard>
    </v-card>

    <v-card style="z-index: 0">
      <v-card-title class="ma-1 pa-0">{{
        $t('graphs.notificationsSection')
      }}</v-card-title>
      <hr />
      <!--v-row class="ma-2" justify="center"-->
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-row>
              <NotificationsByDate
                v-if="true"
                chartname="n1"
                :title="$t('notificationsByDate.title')"
                height="300"
                :dateFrom="dateFrom"
                :dateTo="dateTo"
                :notifications="allNotifications"
                @detail-level="openNotificationsGrid"
              ></NotificationsByDate>
            </v-row>
            <v-row>
              <NotificationsByEmitter
                v-if="true"
                chartname="n1"
                :title="$t('notificationsByEmitter.title')"
                type="treemap"
                height="300"
                :dateFrom="dateFrom"
                :dateTo="dateTo"
                :notifications="allNotifications"
                :rootEmitterCodes="rootEmitterCodes"
                @detail-level="openGridPerCif"
              ></NotificationsByEmitter>
            </v-row>
          </v-col>

          <!--/v-row-->
          <v-col cols="6">
            <NotificationsByCif
              v-if="true"
              chartname="n1"
              :title="$t('notificationsByCif.title')"
              height="600"
              :notifications="notificationsFilteredBetweenDays"
              @detail-level="openGridPerCif"
            ></NotificationsByCif>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <NotificationsByType
              v-if="true"
              chartname="n1"
              :title="$t('notificationsByType.title')"
              height="300"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :notifications="allNotifications"
              @detail-level="openGridPerCif"
            ></NotificationsByType>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

    <v-card style="z-index: 0">
      <v-card-title class="ma-1 pa-0">{{
        $t('graphs.operatorsSection')
      }}</v-card-title>
      <hr />
      <v-row class="ma-2" justify="center">
        <v-col cols="6">
          <OperatorsByDate
            v-if="true"
            chartname="n1"
            :title="$t('operatorsByDate.title')"
            height="300"
            :dateFrom="dateFrom"
            :dateTo="dateTo"
            :operators="allOperators"
            @detail-level="openOperatorsTracesGrid"
          ></OperatorsByDate>
        </v-col>
        <v-col cols="6">
          <NotificationsByOperator
            v-if="true"
            chartname="n1"
            :title="$t('notificationsByOperator.title')"
            height="300"
            :notifications="notificationsFilteredBetweenDays"
            :operators="allOperators"
            @detail-level="openGridPerCif"
          ></NotificationsByOperator>
        </v-col>
      </v-row>
      <!--v-row class="ma-2" justify="center">
        <v-col cols="6">
          <OperatorsConnected
            chartname="n1"
            :title="$t('notificationsByOperator.title')"
            type="treemap"
            height="350"
            :dateFrom="dateFrom"
            :dateTo="dateTo"
            :notifications="allNotifications"
            :rootEmitterCodes="rootEmitterCodes"
            @detail-level="openGridPerCif"
          ></NotificationsByEmitter>
        </v-col>
      </v-row-->
    </v-card>

    <!-- De aqui para abajo son pruebas para comprobar los componentes si comparten espacio en la misma fila>
    <div id="PRUEBAS">
      <v-card style="z-index: 0">
        <v-row class="ma-2" justify="center">
          <v-col cols="6">
            <NotificationsByOperator
              chartname="n1"
              :title="$t('notificationsByOperator.title')"
              height="350"
              :notifications="notificationsFiltredBetweenDays"
              :operators="allOperators"
              @detail-level="openGridPerCif"
            ></NotificationsByOperator>
          </v-col>
          <v-col cols="6">
            <NotificationsByDate
              chartname="n1"
              :title="$t('notificationsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :notifications="allNotifications"
              @detail-level="openNotificationsGrid"
            ></NotificationsByDate>
          </v-col>
        </v-row>
      </v-card>
      <v-card style="z-index: 0">
        <v-row class="ma-2" justify="center">
          <v-col cols="4">
            <NotificationsByDate
              chartname="n1"
              :title="$t('notificationsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :notifications="allNotifications"
              @detail-level="openNotificationsGrid"
            ></NotificationsByDate>
          </v-col>
          <v-col cols="4">
            <NotificationsByCif
              chartname="n1"
              :title="$t('notificationsByCif.title')"
              height="350"
              :notifications="notificationsFiltredBetweenDays"
              @detail-level="openGridPerCif"
            ></NotificationsByCif>
          </v-col>
          <v-col cols="4">
            <OperatorsByDate
              chartname="n1"
              :title="$t('operatorsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :operators="allOperators"
              @detail-level="openOperatorsTracesGrid"
            ></OperatorsByDate>
          </v-col>
        </v-row>
      </v-card>
      <v-card style="z-index: 0">
        <v-row class="ma-2" justify="center">
          <v-col cols="6">
            <NotificationsByDate
              chartname="n1"
              :title="$t('notificationsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :notifications="allNotifications"
              @detail-level="openNotificationsGrid"
            ></NotificationsByDate>
          </v-col>
          <v-col cols="6">
            <OperatorsByDate
              chartname="n1"
              :title="$t('operatorsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :operators="allOperators"
              @detail-level="openOperatorsTracesGrid"
            ></OperatorsByDate>
          </v-col>
        </v-row>
      </v-card>
      <v-card style="z-index: 0">
        <v-row class="ma-2" justify="center">
          <v-col cols="6">
            <NotificationsByCif
              chartname="n1"
              :title="$t('notificationsByCif.title')"
              height="350"
              :notifications="notificationsFiltredBetweenDays"
              @detail-level="openGridPerCif"
            ></NotificationsByCif>
          </v-col>
          <v-col cols="6">
            <OperatorsByDate
              chartname="n1"
              :title="$t('operatorsByDate.title')"
              height="350"
              :dateFrom="dateFrom"
              :dateTo="dateTo"
              :operators="allOperators"
              @detail-level="openOperatorsTracesGrid"
            ></OperatorsByDate>
          </v-col>
        </v-row>
      </v-card>
    </div>
    < Hasta aqui son pruebas para comprobar los componentes si comparten espacio en la misma fila-->
  </v-container>
</template>

<script>
import NotificationsByDate from '@/components/analytics/graphs/NotificationsByDate'
import NotificationsByType from '@/components/analytics/graphs/NotificationsByType'
import NotificationsByCif from '@/components/analytics/graphs/NotificationsByCif'
import NotificationsByOperator from '@/components/analytics/graphs/NotificationsByOperator'
import OperatorsByDate from '@/components/analytics/graphs/OperatorsByDate'

import ResumeCard from '@/components/analytics/graphs/ResumeCard'
//import OperatorsConnected from '@/components/analytics/graphs/OperatorsConnected'
import NotificationsByEmitter from '@/components/analytics/graphs/NotificationsByEmitter'
import GraphsFilter from '@/components/analytics/graphs/GraphsFilter'
import NotificationsGridModal from '@/components/analytics/graphs/NotificationsGridModal'
import OperatorsTracesGridModal from '@/components/analytics/graphs/OperatorsTracesGridModal'

import { mapGetters } from 'vuex'
import moment from 'moment'
import { isBetweenDates } from '@/utils/date-utils'

// Valor 1 para las Comunicaciones, Valor 2 para las Notificaciones
const typeComunication = '1'
//const typeNotification = '2'

const timeFormat = 'YYYYMMDD'

export default {
  props: {},
  components: {
    NotificationsByDate,
    OperatorsByDate,
    GraphsFilter,
    NotificationsByCif,
    NotificationsGridModal,
    NotificationsByOperator,
    OperatorsTracesGridModal,
    NotificationsByEmitter,
    NotificationsByType,
    ResumeCard,
  },
  data() {
    var to = moment().format('YYYYMMDD')
    return {
      operatorsTraces: [],
      loadingAll: false,
      allNotifications: [],
      allOperators: [],
      allPlanData: {},
      rootEmitterCodes: {},
      dateFrom: '',
      dateTo: to,
      openGridNotification: false,
      openGridOperatorsTraces: false,
      detailDate: '',
      detailNotifications: [],
      titleGrid: '',
    }
  },
  methods: {
    updateGraphData(data) {
      if (data !== null) {
        // console.log('updateGraphData', data.notifications)
        this.dateFrom = data.dateFrom
        this.allNotifications = [...data.notifications]
        this.allOperators = [...data.operators]
        this.rootEmitterCodes = { ...data.rootEmitters }
        this.allPlanData = data.planData
        //console.log(this.allPlanData)
      }
    },
    openNotificationsGrid(date, title) {
      this.openGridNotification = true
      this.detailDate = date
      this.titleGrid = title
      this.detailNotifications = this.filterDetailNotifications(this.detailDate)
    },
    openGridPerCif(cifFilteredNotification, titleGrid) {
      this.openGridNotification = true
      //this.detailDate = date
      this.titleGrid = titleGrid
      this.detailNotifications = cifFilteredNotification
    },
    openOperatorsTracesGrid(date, title, userId) {
      this.openGridOperatorsTraces = true
      this.detailDate = date
      this.titleGrid = title
      this.operatorsTraces = this.filterOperatorsTraces(this.detailDate, userId)
    },
    closeGrid() {
      this.openGridNotification = false
      this.openGridOperatorsTraces = false
    },
    filterOperatorsTraces(detailDate, idOperador) {
      let filteredTraces = []

      this.allOperators.forEach((operator) => {
        operator.traces.forEach((trace) => {
          if (
            (operator.id === idOperador || idOperador === 0) &&
            moment(trace.createdAt).format('DD/MM/YYYY') ===
              moment(detailDate).format('DD/MM/YYYY')
          ) {
            let ipGeo = trace.ipGeo ?? {}
            let city = ipGeo.ciudad ?? ''
            let country = ipGeo.pais ?? ''
            let latitude = ipGeo.latitud ?? ''
            let longitude = ipGeo.longitud ?? ''

            filteredTraces.push({
              actionType: trace.actionType,
              createdAt: trace.createdAt,
              ip: trace.ip,
              operator: operator.name + ' ' + operator.surname,
              id: trace.id,
              city: city,
              country: country,
              latitude: latitude,
              longitude: longitude,
            })
          }
        })
      })
      return filteredTraces
    },
    filterDetailNotifications(detailDate) {
      return this.allNotifications.filter((notification) => {
        let receivedDateString = this.getNotificationReceivedDate(notification)
        // Accepted or expired date
        let state = this.getNotificationEstado(notification)
        let dateString =
          state === 'Aceptado' || state === 'Expirada'
            ? this.getNotificationDateByState(notification, state)
            : moment(0).format('YYYYMMDD')
        return detailDate === receivedDateString || detailDate === dateString
      })
    },
    formatReceivedDate(notification) {
      let dateString = notification.fecha_puesta_disposicion
      let out = moment(dateString).format(timeFormat)
      return out
    },
    getNotificationReceivedDate(notification) {
      let dateString = notification.fecha_puesta_disposicion
      let out = moment(dateString).format('YYYYMMDD')
      return out
    },
    getNotificationEstado(notification) {
      return notification.estado
    },
    getNotificationDateByState(notification, state) {
      let dateString
      if (state == 'Aceptado') {
        dateString = notification.fecha_aceptacion
      } else if (state == 'Expirada') {
        if (
          notification.tipo_envio === typeComunication &&
          (notification.fecha_expiracion === '' ||
            notification.fecha_expiracion === undefined)
        ) {
          dateString = this.generateComunicationExpiration(
            notification.fecha_puesta_disposicion
          )
        } else {
          dateString = notification.fecha_expiracion
        }
      }
      let out = moment(dateString).format('YYYYMMDD')
      return out
    },
    generateComunicationExpiration(date) {
      return moment(date).add(10, 'day').format('YYYYMMDD')
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser', 'isAdminUser']),
    notificationsFilteredBetweenDays() {
      return this.allNotifications.filter((notification) => {
        let receivedDateString = this.getNotificationReceivedDate(notification)
        return isBetweenDates(
          receivedDateString,
          this.dateFrom,
          this.dateTo,
          timeFormat
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
