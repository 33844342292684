var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-2",attrs:{"id":"test"}},[_c('v-row',{staticClass:"justify-center"},[(
        _vm.data !== undefined &&
        _vm.data.notificationsPaid !== undefined &&
        _vm.data.notificationsPaid !== 0
      )?_c('v-col',{attrs:{"col":"1"}},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"variant":"tonal","color":_vm.progressColor(_vm.data.notificationsUsed, _vm.data.notificationsPaid)}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-icon',{staticStyle:{"flex":"auto"},attrs:{"color":"secondary"}},[_vm._v("mdi-mailbox")])],1),_c('v-card-subtitle',{staticClass:"ma-1 justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1em"},attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('resumeCard.card1'))+" ")]),_c('v-card-text',{staticClass:"justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1.5em"}},[_vm._v(_vm._s(_vm.data.notificationsUsed)+" / "+_vm._s(_vm.data.notificationsPaid))])],1)],1):_vm._e(),(
        _vm.data !== undefined &&
        _vm.data.companiesPaid !== undefined &&
        _vm.data.companiesPaid !== 0
      )?_c('v-col',{attrs:{"col":"1"}},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"variant":"tonal","color":_vm.progressColor(_vm.data.companiesUsed, _vm.data.companiesPaid)}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-icon',{staticStyle:{"flex":"auto"},attrs:{"color":"secondary"}},[_vm._v("mdi-city")])],1),_c('v-card-subtitle',{staticClass:"ma-1 justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1em"},attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('resumeCard.card2'))+" ")]),_c('v-card-text',{staticClass:"justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1.5em"}},[_vm._v(_vm._s(_vm.data.companiesUsed)+" / "+_vm._s(_vm.data.companiesPaid))])],1)],1):_vm._e(),(
        _vm.data !== undefined &&
        _vm.data.signsPaid !== undefined &&
        _vm.data.signsPaid !== 0
      )?_c('v-col',{attrs:{"col":"1"}},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"variant":"tonal","color":_vm.progressColor(_vm.data.signsUsed, _vm.data.signsPaid)}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-icon',{staticStyle:{"flex":"auto"},attrs:{"color":"secondary"}},[_vm._v("mdi-draw")])],1),_c('v-card-subtitle',{staticClass:"ma-1 justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1em"},attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('resumeCard.card3'))+" ")]),_c('v-card-text',{staticClass:"justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1.5em"}},[_vm._v(_vm._s(_vm.data.signsUsed)+" / "+_vm._s(_vm.data.signsPaid))])],1)],1):_vm._e(),(
        _vm.data !== undefined &&
        _vm.data.certificatesPaid !== undefined &&
        _vm.data.certificatesPaid !== 0
      )?_c('v-col',{attrs:{"col":"1"}},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"variant":"tonal","color":_vm.progressColor(_vm.data.certificatesUsed, _vm.data.certificatesPaid)}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-icon',{staticStyle:{"flex":"auto"},attrs:{"color":"secondary"}},[_vm._v("mdi-account-key")])],1),_c('v-card-subtitle',{staticClass:"ma-1 justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1em"},attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('resumeCard.card4'))+" ")]),_c('v-card-text',{staticClass:"justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1.5em"}},[_vm._v(_vm._s(_vm.data.certificatesUsed)+" / "+_vm._s(_vm.data.certificatesPaid))])],1)],1):_vm._e(),(_vm.operators !== undefined && _vm.operators.length !== 0)?_c('v-col',{attrs:{"col":"1"}},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"variant":"tonal","color":"primary"}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-icon',{staticStyle:{"flex":"auto"},attrs:{"color":"secondary"}},[_vm._v("mdi-account")])],1),_c('v-card-subtitle',{staticClass:"ma-1 justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1em"},attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('resumeCard.card5'))+" ")]),_c('v-card-text',{staticClass:"justify-center",staticStyle:{"color":"white","display":"flex","flex":"auto","font-size":"1.5em"}},[_vm._v(_vm._s(_vm.operators.length))])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }