var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12","lg":_vm.hasToBeShort ? 6 : 12,"md":_vm.hasToBeShort ? 6 : 12}},[(_vm.inputType === 'days')?_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('days')} - ${_vm.$t(
      'conditions.titles.' + _vm.chosenCondition.name
    )}`,"prepend-icon":"mdi-clock","value":_vm.days,"rules":[_vm.notEmpty, _vm.maxNumberValue(28)],"dense":"","autocomplete":"false","type":"number","min":"1","id":"automated-task-additional-number"},on:{"input":_vm.setDays}}):_vm._e(),(_vm.inputType === 'filter')?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.filters,"color":"primary","filter":_vm.customFilterFilters,"no-data-text":_vm.$t('emptyFilters'),"label":`${_vm.$t('selectFilter')} - ${_vm.$t(
      'conditions.titles.' + _vm.chosenCondition.name
    )}`,"item-value":"path","item-text":"name","disabled":_vm.disabled,"prepend-icon":"mdi-camera-control","menu-props":{ bottom: true, offsetY: true },"id":"automated-tasks-autocomplete-filters-additional-data","rules":[_vm.notEmpty],"multiple":"","clearable":""},on:{"input":_vm.setData},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{attrs:{"id":"automated-task-filter-option"}},[_vm._v(" "+_vm._s(item.isCustom ? item.name : _vm.$t(`filters.${item.name}`))+" ")])]}},{key:"selection",fn:function({ item }){return [(!item.types)?_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","disabled":_vm.disabled,"id":"automated-task-filter-selected"}},[_vm._v(" "+_vm._s(item.isCustom ? item.name : _vm.$t(`filters.${item.name}`))+" ")]):_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","disabled":_vm.disabled,"id":"automated-task-filter-selected"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,2156211775),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),(_vm.inputType === 'cifs')?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.cifsProp,"color":"primary","filter":_vm.customFilterCifs,"no-data-text":_vm.$t('emptyCifs'),"label":`${_vm.$t('selectCifs')} - ${_vm.$t(
      'conditions.titles.' + _vm.chosenCondition.name
    )}`,"item-value":"numeroDocIdentidad","disabled":_vm.disabled,"prepend-icon":"mdi-city","menu-props":{ bottom: true, offsetY: true },"id":"automated-tasks-autocomplete-cifs-additional-data","rules":[_vm.notEmpty],"multiple":""},on:{"input":_vm.setData},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{attrs:{"id":"automated-task-cif-option"}},[_vm._v(" "+_vm._s(item.numeroDocIdentidad)+" - "+_vm._s(item.empresa)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","disabled":_vm.disabled,"id":"automated-task-cif-selected","close":""},on:{"click:close":function($event){return _vm.remove(item.numeroDocIdentidad, 'cifs')}}},[_vm._v(" "+_vm._s(item.numeroDocIdentidad)+" - "+_vm._s(item.empresa)+" ")])]}}],null,false,2941947873),model:{value:(_vm.cifs),callback:function ($$v) {_vm.cifs=$$v},expression:"cifs"}}):_vm._e(),(_vm.inputType === 'words')?_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('words')} - ${_vm.$t(
      'conditions.titles.' + _vm.chosenCondition.name
    )}`,"prepend-icon":"mdi-file-word-box-outline","value":_vm.words,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-condition-additional-data-words","hint":_vm.$t('wordsComaHint')},on:{"input":_vm.setData}}):_vm._e(),(_vm.inputType === 'date')?_c('v-menu',{ref:"additionalDateMenu",attrs:{"dense":"","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":`${_vm.$t('date')} - ${_vm.$t(
          'conditions.titles.' + _vm.chosenCondition.name
        )}`,"prepend-icon":"mdi-calendar","readonly":"","id":"additional-conditional-date","disabled":_vm.disabled,"rules":[_vm.notEmpty]},model:{value:(_vm.additionalDate),callback:function ($$v) {_vm.additionalDate=$$v},expression:"additionalDate"}},'v-text-field',attrs,false),on))]}}],null,false,3691909071),model:{value:(_vm.additionalDateMenu),callback:function ($$v) {_vm.additionalDateMenu=$$v},expression:"additionalDateMenu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$t('javascriptLocale'),"min":new Date().toISOString().substring(0, 10),"no-title":"","scrollable":"","rules":[_vm.notEmpty]},on:{"click:date":_vm.setDate},model:{value:(_vm.additionalDate),callback:function ($$v) {_vm.additionalDate=$$v},expression:"additionalDate"}})],1):_vm._e(),(_vm.inputType === 'notificationType')?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.notificationTypes,"color":"primary","filter":_vm.customFilterTypes,"label":`${_vm.$t('selectNotificationType')} - ${_vm.$t(
      'conditions.titles.' + _vm.chosenCondition.name
    )}`,"item-value":"id","item-text":"name","disabled":_vm.disabled,"prepend-icon":"mdi-camera-control","menu-props":{ bottom: true, offsetY: true },"id":"automated-tasks-autocomplete-notification-type-additional-data","rules":[_vm.notEmpty]},on:{"input":_vm.setData},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{attrs:{"id":"automated-task-notification-type-option"}},[_vm._v(" "+_vm._s(_vm.$t('metadata.types.' + item))+" ")])]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","disabled":_vm.disabled,"id":"automated-task-notification-type-selected"}},[_vm._v(" "+_vm._s(_vm.$t('metadata.types.' + item))+" ")])]}}],null,false,3093166780),model:{value:(_vm.notificationType),callback:function ($$v) {_vm.notificationType=$$v},expression:"notificationType"}}):_vm._e(),(_vm.inputType === 'groups')?_c('v-col',{attrs:{"cols":"12"}},[_c('GroupAutocomplete',{attrs:{"disabled":_vm.disabled,"objectGroups":_vm.groups,"groups":_vm.groupsProp,"multiple":true,"rules":[_vm.listNotEmpty]},on:{"setGroups":_vm.setGroups}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }