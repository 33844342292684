<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <LegalAdvice />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LegalAdvice from '@/components/legal/LegalAdvice'

export default {
  components: {
    LegalAdvice,
  },
}
</script>
