<template>
  <v-menu
    offset-y
    :close-on-content-click="!selectedAction"
    :close-on-click="!selectedAction"
    rounded
    :value="shown"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip max-width="400" bottom>
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            elevation="1"
            fab
            small
            v-bind="{ ...attrs, ...attrsTooltip }"
            v-on="{ ...on, ...onTooltip }"
            :style="{
              'box-shadow': selectedAction
                ? '0 0 10px 3px ' +
                  $vuetify.theme.currentTheme.primary +
                  ' !important'
                : '',
            }"
            class="primary secondary--text ma-1"
            id="actions-multiple"
            :disabled="initLoading"
            @click="shown = true"
          >
            <v-icon> mdi-checkbox-multiple-marked </v-icon>
          </v-btn>
        </template>
        <div width="300">
          {{ $t('massiveAction') }}
        </div>
      </v-tooltip>
    </template>
    <template v-if="!selectedAction">
      <v-list width="300">
        <v-list-item
          v-for="(item, index) in actions"
          :key="'massive-action-' + index + '-' + item"
          :value="index"
          @click="setAction(item)"
        >
          <v-list-item-title
            :style="{
              color:
                item === selectedAction
                  ? $vuetify.theme.currentTheme.primary
                  : '',
            }"
          >
            {{ $t('massiveActions.titles.' + item) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <v-card v-else width="300" max-height="600">
      <v-card-text>
        <b>{{ $t('massiveActions.titles.' + selectedAction) }}</b> -
        <span>{{ items.length }} {{ $t('elements') }}</span>
        <div class="my-5" />
        <v-form
          ref="massiveActionForm"
          v-model="validMassiveActionForm"
          lazy-validation
          :loading="loading"
          @submit.prevent="onSubmit"
        >
          <ChangeStatusOptions
            v-if="isChangeStatus"
            @setStatus="(newStatus) => setData(newStatus, 'status')"
          />
          <OperatorsOptions
            v-if="isSetResponsible"
            :operators="operators"
            @setOperator="(newOperator) => setData(newOperator, 'operator')"
          />
          <AlertOptions
            v-if="isSetAlert"
            @setAlert="(newAlert) => setData(newAlert, 'alert')"
          />
          <ObservationOptions
            v-if="isSetObservation"
            @setObservation="
              (newObservation) => setData(newObservation, 'observation')
            "
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          small
          icon
          color="success"
          :disabled="noItemsSelected || loading"
          :loading="loading"
          @click="performAction"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn small icon color="error" @click="removeAction">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import ChangeStatusOptions from '@/components/massive-actions/additional-data/ChangeStatusOptions'
import OperatorsOptions from '@/components/massive-actions/additional-data/OperatorsOptions'
import AlertOptions from '@/components/massive-actions/additional-data/AlertOptions'
import ObservationOptions from '@/components/massive-actions/additional-data/ObservationOptions'
import {
  massiveActionChangeStatus,
  massiveDownloadFiles,
} from '@/services/massive-actions-service'
import { getNotificationStatusFromI18n } from '@/services/notification-service'
import getErrorText from '../../utils/get-error-text'
import {
  massiveOpenNotification,
  massiveSetAlert,
  massiveSetObservation,
  massiveSetResponsible,
} from '../../services/massive-actions-service'
import { getUserOperators } from '../../services/operator-service'
import { getFilterAdminFilter } from '../../services/filter-service'
import { checkItem } from '@/utils/massive-actions'

export default {
  props: {
    actions: Array,
    items: Array,
  },
  components: {
    ChangeStatusOptions,
    OperatorsOptions,
    AlertOptions,
    ObservationOptions,
  },
  async mounted() {
    this.initLoading = true

    // Adding the base user
    const user = this.$store.state.user
    user.finalRole = getFilterAdminFilter()

    // Getting other operators
    this.operators = [user, ...(await getUserOperators())]

    this.initLoading = false
  },
  data() {
    return {
      loading: false,
      initLoading: false,
      validMassiveActionForm: false,
      shown: false,
      selectedAction: undefined,
      selectedOperator: undefined,
      status: undefined,
      operator: undefined,
      alert: undefined,
      observation: undefined,
      operators: [],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Sets a selected action.
     * @param {String} action Action selected.
     */
    setAction(action) {
      this.selectedAction = action
      this.$emit('actionSelected', action)
    },
    /**
     * Sets the data of the massive action.
     * @param {Object} data Data to be set.
     * @param {string} key Key to stablish.
     */
    setData(data, key) {
      this[key] = data
      this.$emit('setMassiveActionData', {
        [key]: data,
      })
    },
    /**
     * Removes the selected action.
     */
    removeAction() {
      if (this.selectedAction) {
        this.selectedAction = undefined
        this.$emit('actionSelected', false)
        this.$emit('removeItems', [...Array(this.items.length).keys()])
      }
    },
    /**
     * Performs the massive action
     */
    async performAction() {
      if (!this.$refs.massiveActionForm?.validate()) return

      this.loading = true

      // Calling function to enqueue/perform the tasks
      try {
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        // Changing status
        if (this.isChangeStatus)
          await massiveActionChangeStatus(
            userId,
            this.items.map((n) => n.id),
            getNotificationStatusFromI18n(this.status)
          )
        // Openning notifications
        if (this.isOpenNotification)
          await massiveOpenNotification(
            userId,
            this.items.map((n) => n.id)
          )
        // Setting the responsible
        if (this.isSetResponsible)
          await massiveSetResponsible(
            userId,
            this.operator.id,
            this.items.map((n) => n.id)
          )
        // Setting the alert
        if (this.isSetAlert)
          await massiveSetAlert(
            userId,
            this.alert,
            this.items.map((n) => n.id)
          )
        // Setting the observation
        if (this.isSetObservation)
          await massiveSetObservation(
            userId,
            this.observation,
            this.items.map((n) => n.id)
          )
        if (this.isDownloadFiles)
          await massiveDownloadFiles(this.items.map((n) => n.id))

        let message = this.$t('massiveActions.success.' + this.selectedAction)
        if (this.isDownloadFiles) message = this.$t('exportCompleteZip')

        this.setSnackbar({ position: 'top', type: 'success', message })
        this.removeAction()
        this.shown = false

        this.operator = undefined
        this.alert = undefined
        this.observation = undefined
        this.status = undefined
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }

      this.loading = false
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isOperatorUser',
      'isAdminUser',
      'isEmpresaUser',
    ]),
    noItemsSelected() {
      return this.items.length === 0
    },
    isChangeStatus() {
      return this.selectedAction === 'changeStatus'
    },
    isOpenNotification() {
      return this.selectedAction === 'openNotification'
    },
    isSetResponsible() {
      return this.selectedAction === 'setResponsible'
    },
    isSetAlert() {
      return this.selectedAction === 'setAlert'
    },
    isSetObservation() {
      return this.selectedAction === 'setObservation'
    },
    isDownloadFiles() {
      return this.selectedAction === 'downloadFiles'
    },
  },
  watch: {
    operator: async function (newValue) {
      if (newValue && typeof newValue === 'string') {
        this.selectedOperator = this.operators.find(({ id }) => newValue === id)
        this.setData(this.selectedOperator, 'operator')
      }

      if (!newValue) return

      const itemsToRemove = []
      for (let i = 0; i < this.items.length; i++)
        if (
          !(await checkItem(
            'setResponsible',
            { operator: this.selectedOperator },
            this.items[i]
          ))
        )
          itemsToRemove.push(i)
      this.$emit('removeItems', itemsToRemove)
    },
    status: async function (newValue) {
      if (!newValue) return

      const itemsToRemove = []
      for (let i = 0; i < this.items.length; i++)
        if (
          !(await checkItem(
            'changeStatus',
            { status: newValue },
            this.items[i]
          ))
        )
          itemsToRemove.push(i)
      this.$emit('removeItems', itemsToRemove)
    },
    selectedAction: function (newValue, previousValue) {
      if (newValue !== previousValue && previousValue) {
        const itemsToRemove = [...Array(previousValue.length).keys()]
        this.$emit('removeItems', itemsToRemove)
      }
    },
  },
}
</script>
