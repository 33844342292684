import {
  Firestore,
  HistoryCollection,
  HistoryDoc,
  UsersDoc,
} from '@/firebase-exports'
import { getUserWithRef } from '@/services/user-service'

/**
 * Crea un evento de historial para un usuario, tipo de elemento y notificación específicos.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} assetType - Tipo de elemento asociado al evento.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @param {Object} event - Datos del evento a crear.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del evento de historial creado.
 */
export async function createHistoryEvent(
  userId,
  assetType,
  notificationId,
  event
) {
  return await Firestore.addDoc(
    HistoryCollection(userId, assetType, notificationId),
    event
  )
}

/**
 * Crea un evento de historial de tipo 'addDoc'.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @param {string} actorId - El ID del actor que realiza la acción.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del evento de historial creado.
 */
export async function createHistoryEventAddDoc(
  userId,
  notificationId,
  actorId
) {
  return await Firestore.addDoc(HistoryCollection(userId, notificationId), {
    actionType: 'addDoc',
    createdAt: Firestore.serverTimestamp(),
    userRef: UsersDoc(actorId),
  })
}

/**
 * Lista los eventos de historial para un usuario, tipo de activo y notificación específicos.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} assetType - Tipo de activo asociado al evento.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @returns {Promise<Array>} Una promesa que se resuelve con un arreglo de eventos de historial.
 */
export async function listHistory(userId, assetType, notificationId) {
  const querySnap = await Firestore.getDocs(
    HistoryCollection(userId, assetType, notificationId)
  )
  return querySnap.docs
    .map(async (doc) => {
      const data = doc.data()
      if (data.userRef) {
        data.user = await getUserWithRef(data.userRef)
      }
      if (data.targetUserRef) {
        data.targetUser = await getUserWithRef(data.targetUserRef)
      }
      return { id: doc.id, ...data }
    })
    .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds)
}

/**
 * Crea una subscripción a los eventos de historial, actualizando el contexto con los eventos en tiempo real.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} assetType - Tipo de activo asociado al evento.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @param {string} [order='asc'] - Orden de los eventos ('asc' o 'desc').
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function listHistorySubscription(
  userId,
  assetType,
  notificationId,
  context,
  order = 'asc'
) {
  var historyUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      HistoryCollection(userId, assetType, notificationId),
      Firestore.orderBy('createdAt', order)
    ),
    async (querySnapshot) => {
      context.history = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data()

          if (data.userRef) {
            data.user = await getUserWithRef(data.userRef)
          }
          if (data.targetUserRef) {
            data.targetUser = await getUserWithRef(data.targetUserRef)
          }
          return { id: doc.id, ...data }
        })
      )
      if (context.loadingHistory) context.loadingHistory = false
    },
    (error) => {
      throw error
    }
  )
  return historyUnsubscribe
}

/**
 * Obtiene un evento específico del historial.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} assetType - Tipo de activo asociado al evento.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @param {string} historyId - El ID del evento de historial.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del evento de historial.
 */
export async function getHistoryEvent(
  userId,
  assetType,
  notificationId,
  historyId
) {
  const docSnap = await Firestore.getDoc(
    HistoryDoc(userId, assetType, notificationId, historyId)
  )
  return {
    ...docSnap.data(),
    id: docSnap.id,
  }
}

/**
 * Elimina un evento específico del historial.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} assetType - Tipo de activo asociado al evento.
 * @param {string} notificationId - El ID de la notificación asociada al evento.
 * @param {string} historyId - El ID del evento de historial a eliminar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando el evento de historial ha sido eliminado.
 */
export async function deleteHistoryEvent(
  userId,
  assetType,
  notificationId,
  historyId
) {
  return await Firestore.deleteDoc(
    HistoryDoc(userId, assetType, notificationId, historyId)
  )
}
