<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.companies.createCSV.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.companies.createCSV.paragraph1') }}
    </p>
    <v-img
      style="width: 100%; max-width: 900px"
      class="mb-5"
      contain
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.companies.createCSV.paragraph2') }}
    </p>
    <span><b>empresa</b>: {{ $t('csvCifs.company') }}</span>
    <br />
    <span>
      <b>tipoDocIdentidad</b>:
      {{ $t('csvCifs.identificationType') }}
    </span>
    <br />
    <span>
      <b>numeroDocIdentidad</b>:
      {{ $t('csvCifs.identificationNumber') }}
    </span>
    <!-- <br /> <span><b>afiliado</b> ({{ $t('optional') }}): {{ $t('csvAfiliado') }}</span>  -->
    <br />
    <span><b>email</b> ({{ $t('optional') }}): {{ $t('csvCifs.email') }}</span>
    <br />
    <span>
      <b>telefono</b> ({{ $t('optional') }}):
      {{ $t('csvCifs.telefono') }}
    </span>
    <br />
    <br />
    <p>
      {{ $t('documentation.texts.companies.createCSV.paragraph3') }}
    </p>
    <v-img
      style="width: 100%; max-width: 900px"
      class="mb-5"
      contain
      :src="pic2Name"
    />
    <b>
      <span> {{ $t('csvSeparation') }} </span>
    </b>
    <br />
    <br />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.createCSV.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.createCSV.pic2') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
