export const adminSteps = () => {
  return [
    {
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourAdminSteps.step0.title',
      },
      content: 'tourAdminSteps.step0.content',
    },
    {
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourAdminSteps.step1.title',
      },
      content: 'tourAdminSteps.step1.content',
    },
    {
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminSteps.step2.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminSteps.step2.content',
    },
    {
      target: '[data-v-step="3"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminSteps.step3.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminSteps.step3.content',
    },
    {
      target: '[data-v-step="4"]',
      fromPath: 'DashboardView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminSteps.step4.title',
      },
      content: 'tourAdminSteps.step4.content',
    },
    {
      target: '[data-v-step="5"]',
      fromPath: 'DashboardView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminSteps.step5.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminSteps.step5.content',
    },
    {
      target: '[data-v-step="6"]',
      fromPath: 'NotificationsView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminSteps.step6.title',
      },
      content: 'tourAdminSteps.step6.content',
    },
    {
      target: '[data-v-step="7"]',
      fromPath: 'NotificationsView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminSteps.step7.title',
      },
      content: 'tourAdminSteps.step7.content',
    },
    {
      target: '[data-v-step="8"]',
      fromPath: 'NotificationsView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminSteps.step8.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminSteps.step8.content',
    },
    {
      target: '[data-v-step="9"]',
      fromPath: 'OperatorsView',
      destPat: 'FiltersView',
      header: {
        title: 'tourAdminSteps.step9.title',
      },
      content: 'tourAdminSteps.step9.content',
    },
    {
      target: '[data-v-step="10"]',
      fromPath: 'OperatorsView',
      destPat: 'CifsView',
      header: {
        title: 'tourAdminSteps.step10.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminSteps.step10.content',
    },
    {
      target: '[data-v-step="11"]',
      fromPath: 'FiltersView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminSteps.step11.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminSteps.step11.content',
    },
    {
      target: '[data-v-step="12"]',
      fromPath: 'CifsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminSteps.step12.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminSteps.step12.content',
    },
    {
      target: '[data-v-step="13"]',
      fromPath: 'SettingsView',
      header: {
        title: 'tourAdminSteps.step13.title',
      },
      params: {
        placement: 'bottom',
      },
      content: 'tourAdminSteps.step13.content',
      showLogo: true,
    },
  ]
}
export const tourAdminFullServicesSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourAdminCommonsSteps.step0.title',
      },
      content: 'tourAdminCommonsSteps.step0.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourAdminCommonsSteps.step1.title',
      },
      content: 'tourAdminCommonsSteps.step1.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Recuerda, al igual que con las notificaciones, cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminCommonsSteps.step2.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step2.content',
    },
    {
      //"content": "Aquí podrás consultar todas las notificaciones a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="100"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminNotificationsSteps.step100.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminNotificationsSteps.step100.content',
    },
    {
      //"content": "En este apartado tienes clasificadas las notificaciones que tienes asignadas como responsable. En este bloque también tendrás disponible las solicitudes de asignación como responsable que recibas de otros usuarios."
      target: '[data-v-step="101"]',
      fromPath: 'DashboardView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step101.title',
      },
      content: 'tourAdminNotificationsSteps.step101.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las notificaciones."
      target: '[data-v-step="102"]',
      fromPath: 'DashboardView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step102.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminNotificationsSteps.step102.content',
    },
    {
      //"content": "Por defecto se cargan solo las notificaciones recibidas en los últimos 90 días. Pero también puedes consultar la totalidad de las notificaciones recibidas."
      target: '[data-v-step="103"]',
      fromPath: 'NotificationsView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step103.title',
      },
      content: 'tourAdminNotificationsSteps.step103.content',
    },
    {
      //"content": "Puedes hacer búsquedas inteligentes por diversos campos para encontrar notificaciones en las que se encuentre tu clave de búsqueda."
      target: '[data-v-step="104"]',
      fromPath: 'NotificationsView',
      destPat: 'FiltersView',
      header: {
        title: 'tourAdminNotificationsSteps.step104.title',
      },
      content: 'tourAdminNotificationsSteps.step104.content',
    },
    {
      //"content": "Los filtros permiten dar acceso a un operador a solo un tipo concreto de notificación atendiendo al tipo de organismo emisor."
      target: '[data-v-step="105"]',
      fromPath: 'NotificationsView',
      destPat: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourAdminNotificationsSteps.step105.title',
      },
      content: 'tourAdminNotificationsSteps.step105.content',
    },
    {
      //"content": "Aquí podrás consultar todos los procesos de firma abiertos y clasificados por su estado"
      target: '[data-v-step="200"]',
      fromPath: 'DashboardView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step200.title',
      },
      content: 'tourAdminSignaturesSteps.step200.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las firmas."
      target: '[data-v-step="201"]',
      fromPath: 'SignaturesView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step201.title',
      },
      content: 'tourAdminSignaturesSteps.step201.content',
    },
    {
      //"content": "Por defecto se cargan solo las firmas correspondientes a los últimos 90 días. Pero también puedes consultar la totalidad de las firmas creadas."
      target: '[data-v-step="202"]',
      fromPath: 'SignaturesView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step202.title',
      },
      content: 'tourAdminSignaturesSteps.step202.content',
    },
    {
      //"content": "Puedes hacer búsquedas inteligentes por diversos campos para encontrar firmas en las que se encuentre tu clave de búsqueda."
      target: '[data-v-step="203"]',
      fromPath: 'SignaturesView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminSignaturesSteps.step203.title',
      },
      content: 'tourAdminSignaturesSteps.step203.content',
    },
    {
      //"content": "En este apartado puedes gestionar los operadores que trabajarán con la aplicación, y puedes administrar los diferentes permisos."
      target: '[data-v-step="3"]',
      fromPath: 'SignaturesView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminCommonsSteps.step3.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminCommonsSteps.step3.content',
    },
    {
      //"content": "Un operador puede tener tres tipos de permisos, listar y consultar notificaciones (habilitado siempre), firmar notificaciones (es decir abrirlas) y tramitar (poner alertas, asignar responsable o compartir). Además se puede configurar sobre qué empresas y con qué filtros puede acceder un operador."
      target: '[data-v-step="4"]',
      fromPath: 'OperatorsView',
      destPat: 'CifsView',
      header: {
        title: 'tourAdminCommonsSteps.step4.title',
      },
      content: 'tourAdminCommonsSteps.step4.content',
    },
    {
      //"content": "En el apartado empresas se pueden consultar las empresas apoderadas y sus datos."
      target: '[data-v-step="5"]',
      fromPath: 'OperatorsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step5.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step5.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      fromPath: 'CifsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step6.title',
      },
      content: 'tourAdminCommonsSteps.step6.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step7.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step7.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step8.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step8.content',
    },
  ]
}
export const tourAdminNotificationsSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourAdminCommonsSteps.step0.title',
      },
      content: 'tourAdminCommonsSteps.step0.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourAdminCommonsSteps.step1.title',
      },
      content: 'tourAdminCommonsSteps.step1.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Recuerda, al igual que con las notificaciones, cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminCommonsSteps.step2.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step2.content',
    },
    {
      //"content": "Aquí podrás consultar todas las notificaciones a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="100"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminNotificationsSteps.step100.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminNotificationsSteps.step100.content',
    },
    {
      //"content": "En este apartado tienes clasificadas las notificaciones que tienes asignadas como responsable. En este bloque también tendrás disponible las solicitudes de asignación como responsable que recibas de otros usuarios."
      target: '[data-v-step="101"]',
      fromPath: 'DashboardView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step101.title',
      },
      content: 'tourAdminNotificationsSteps.step101.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las notificaciones."
      target: '[data-v-step="102"]',
      fromPath: 'NotificationsView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step102.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminNotificationsSteps.step102.content',
    },
    {
      //"content": "Por defecto se cargan solo las notificaciones recibidas en los últimos 90 días. Pero también puedes consultar la totalidad de las notificaciones recibidas."
      target: '[data-v-step="103"]',
      fromPath: 'NotificationsView',
      destPat: 'NotificationsView',
      header: {
        title: 'tourAdminNotificationsSteps.step103.title',
      },
      content: 'tourAdminNotificationsSteps.step103.content',
    },
    {
      //"content": "Puedes hacer búsquedas inteligentes por diversos campos para encontrar notificaciones en las que se encuentre tu clave de búsqueda."
      target: '[data-v-step="104"]',
      fromPath: 'NotificationsView',
      destPat: 'FiltersView',
      header: {
        title: 'tourAdminNotificationsSteps.step104.title',
      },
      content: 'tourAdminNotificationsSteps.step104.content',
    },
    {
      //"content": "Los filtros permiten dar acceso a un operador a solo un tipo concreto de notificación atendiendo al tipo de organismo emisor."
      target: '[data-v-step="105"]',
      fromPath: 'NotificationsView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminNotificationsSteps.step105.title',
      },
      content: 'tourAdminNotificationsSteps.step105.content',
    },
    {
      //"content": "En este apartado puedes gestionar los operadores que trabajarán con la aplicación, y puedes administrar los diferentes permisos."
      target: '[data-v-step="3"]',
      fromPath: 'NotificationsView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminCommonsSteps.step3.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminCommonsSteps.step3.content',
    },
    {
      //"content": "Un operador puede tener tres tipos de permisos, listar y consultar notificaciones (habilitado siempre), firmar notificaciones (es decir abrirlas) y tramitar (poner alertas, asignar responsable o compartir). Además se puede configurar sobre qué empresas y con qué filtros puede acceder un operador."
      target: '[data-v-step="4"]',
      fromPath: 'OperatorsView',
      destPat: 'CifsView',
      header: {
        title: 'tourAdminCommonsSteps.step4.title',
      },
      content: 'tourAdminCommonsSteps.step4.content',
    },
    {
      //"content": "En el apartado empresas se pueden consultar las empresas apoderadas y sus datos."
      target: '[data-v-step="5"]',
      fromPath: 'OperatorsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step5.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step5.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      fromPath: 'CifsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step6.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminCommonsSteps.step6.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step7.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step7.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step8.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step8.content',
    },
  ]
}
export const tourAdminSignaturesSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourAdminCommonsSteps.step0.title',
      },
      content: 'tourAdminCommonsSteps.step0.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourAdminCommonsSteps.step1.title',
      },
      content: 'tourAdminCommonsSteps.step1.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Recuerda, al igual que con las notificaciones, cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      header: {
        title: 'tourAdminCommonsSteps.step2.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step2.content',
    },
    {
      //"content": "Aquí podrás consultar todos los procesos de firma abiertos y clasificados por su estado"
      target: '[data-v-step="200"]',
      fromPath: 'DashboardView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step200.title',
      },
      content: 'tourAdminSignaturesSteps.step200.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las firmas."
      target: '[data-v-step="201"]',
      fromPath: 'SignaturesView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step201.title',
      },
      content: 'tourAdminSignaturesSteps.step201.content',
    },
    {
      //"content": "Por defecto se cargan solo las firmas correspondientes a los últimos 90 días. Pero también puedes consultar la totalidad de las firmas creadas."
      target: '[data-v-step="202"]',
      fromPath: 'SignaturesView',
      destPat: 'SignaturesView',
      header: {
        title: 'tourAdminSignaturesSteps.step202.title',
      },
      content: 'tourAdminSignaturesSteps.step202.content',
    },
    {
      //"content": "Puedes hacer búsquedas inteligentes por diversos campos para encontrar firmas en las que se encuentre tu clave de búsqueda."
      target: '[data-v-step="203"]',
      fromPath: 'SignaturesView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminSignaturesSteps.step203.title',
      },
      content: 'tourAdminSignaturesSteps.step203.content',
    },
    {
      //"content": "En este apartado puedes gestionar los operadores que trabajarán con la aplicación, y puedes administrar los diferentes permisos."
      target: '[data-v-step="3"]',
      fromPath: 'SignaturesView',
      destPat: 'OperatorsView',
      header: {
        title: 'tourAdminCommonsSteps.step3.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminCommonsSteps.step3.content',
    },
    {
      //"content": "Un operador puede tener tres tipos de permisos, listar y consultar notificaciones (habilitado siempre), firmar notificaciones (es decir abrirlas) y tramitar (poner alertas, asignar responsable o compartir). Además se puede configurar sobre qué empresas y con qué filtros puede acceder un operador."
      target: '[data-v-step="4"]',
      fromPath: 'OperatorsView',
      destPat: 'CifsView',
      header: {
        title: 'tourAdminCommonsSteps.step4.title',
      },
      content: 'tourAdminCommonsSteps.step4.content',
    },
    {
      //"content": "En el apartado empresas se pueden consultar las empresas apoderadas y sus datos."
      target: '[data-v-step="5"]',
      fromPath: 'OperatorsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step5.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step5.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      fromPath: 'CifsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step6.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourAdminCommonsSteps.step6.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step7.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step7.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step8.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step8.content',
    },
  ]
}
export const tourOperatorFullServicesSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourOperatorCommonSteps.step300.title',
      },
      content: 'tourOperatorCommonSteps.step300.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step301.title',
      },
      content: 'tourOperatorCommonSteps.step301.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step302.title',
      },
      content: 'tourOperatorCommonSteps.step302.content',
    },
    {
      //"content": "Aquí podrás consultar todas las notificaciones a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="100"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step400.title',
      },
      content: 'tourOperatorNotificationsSteps.step400.content',
    },
    {
      //"content": "En este apartado tienes clasificadas las notificaciones que tienes asignadas como responsable. En este bloque también tendrás disponible las solicitudes de asignación como responsable que recibas de otros usuarios."
      target: '[data-v-step="101"]',
      destPat: 'NotificationsView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step401.title',
      },
      content: 'tourOperatorNotificationsSteps.step401.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las notificaciones."
      target: '[data-v-step="102"]',
      destPat: 'NotificationsView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'top',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step402.title',
      },
      content: 'tourOperatorNotificationsSteps.step402.content',
    },
    {
      //"content": "Por defecto se cargan solo las notificaciones recibidas en los últimos 90 días. Pero también puedes consultar la totalidad de las notificaciones recibidas."
      target: '[data-v-step="103"]',
      destPat: 'DashboardView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step403.title',
      },
      content: 'tourOperatorNotificationsSteps.step403.content',
    },
    {
      //"content": "Aquí podrás consultar todas las firmas a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="200"]',
      destPat: 'SignaturesView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step500.title',
      },
      content: 'tourOperatorSignaturesSteps.step500.content',
    },
    {
      //"content": "También puedes consultar un listado completo de firmas."
      target: '[data-v-step="201"]',
      destPat: 'SignaturesView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step501.title',
      },
      content: 'tourOperatorSignaturesSteps.step501.content',
    },
    {
      //"content": "Por defecto se cargan solo las firmas creadas en los últimos 90 días. Pero también puedes consultar la totalidad de las firmas."
      target: '[data-v-step="202"]',
      destPat: 'SettingsView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step502.title',
      },
      content: 'tourOperatorSignaturesSteps.step502.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      destPat: 'SettingsView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step303.title',
      },
      content: 'tourOperatorCommonSteps.step303.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      destPat: 'SettingsView',
      fromPath: 'SettingsView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorCommonSteps.step304.title',
      },
      content: 'tourOperatorCommonSteps.step304.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step305.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step305.content',
    },
  ]
}
export const tourOperatorNotificationsSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourOperatorCommonSteps.step300.title',
      },
      content: 'tourOperatorCommonSteps.step300.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step301.title',
      },
      content: 'tourOperatorCommonSteps.step301.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step302.title',
      },
      content: 'tourOperatorCommonSteps.step302.content',
    },
    {
      //"content": "Aquí podrás consultar todas las notificaciones a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="100"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step400.title',
      },
      content: 'tourOperatorNotificationsSteps.step400.content',
    },
    {
      //"content": "En este apartado tienes clasificadas las notificaciones que tienes asignadas como responsable. En este bloque también tendrás disponible las solicitudes de asignación como responsable que recibas de otros usuarios."
      target: '[data-v-step="101"]',
      destPat: 'NotificationsView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step401.title',
      },
      content: 'tourOperatorNotificationsSteps.step401.content',
    },
    {
      //"content": "También puedes consultar un listado completo de las notificaciones."
      target: '[data-v-step="102"]',
      destPat: 'NotificationsView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'top',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step402.title',
      },
      content: 'tourOperatorNotificationsSteps.step402.content',
    },
    {
      //"content": "Por defecto se cargan solo las notificaciones recibidas en los últimos 90 días. Pero también puedes consultar la totalidad de las notificaciones recibidas."
      target: '[data-v-step="103"]',
      destPat: 'SettingsView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorNotificationsSteps.step403.title',
      },
      content: 'tourOperatorNotificationsSteps.step403.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      destPat: 'SettingsView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step303.title',
      },
      content: 'tourOperatorCommonSteps.step303.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      destPat: 'SettingsView',
      fromPath: 'SettingsView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorCommonSteps.step304.title',
      },
      content: 'tourOperatorCommonSteps.step304.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step305.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step305.content',
    },
  ]
}
export const tourOperatorSignaturesSteps = () => {
  return [
    {
      //"content": "Bienvenido al tour rápido por la aplicación de GoodOk."
      target: '[data-v-step="0"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        highlight: false,
      },
      showLogo: true,
      header: {
        title: 'tourOperatorCommonSteps.step300.title',
      },
      content: 'tourOperatorCommonSteps.step300.content',
    },
    {
      //"content": "Esto es el panel, aquí tienes organizado tu trabajo en tres grandes bloques"
      target: '[data-v-step="1"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step301.title',
      },
      content: 'tourOperatorCommonSteps.step301.content',
    },
    {
      //"content": "Las alertas son muy útiles para organizar el trabajo. Aquí podrás consultar todas las alertas creadas por tu equipo. Cuando quedan menos de tres días el estado pasa a ser urgente."
      target: '[data-v-step="2"]',
      destPat: 'DashboardView',
      fromPath: 'DashboardView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step302.title',
      },
      content: 'tourOperatorCommonSteps.step302.content',
    },
    {
      //"content": "Aquí podrás consultar todas las firmas a las que tienes acceso clasificadas por su estado."
      target: '[data-v-step="200"]',
      destPat: 'SignaturesView',
      fromPath: 'NotificationsView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step500.title',
      },
      content: 'tourOperatorSignaturesSteps.step500.content',
    },
    {
      //"content": "También puedes consultar un listado completo de firmas."
      target: '[data-v-step="201"]',
      destPat: 'SignaturesView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step501.title',
      },
      content: 'tourOperatorSignaturesSteps.step501.content',
    },
    {
      //"content": "Por defecto se cargan solo las firmas creadas en los últimos 90 días. Pero también puedes consultar la totalidad de las firmas."
      target: '[data-v-step="202"]',
      destPat: 'SettingsView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorSignaturesSteps.step502.title',
      },
      content: 'tourOperatorSignaturesSteps.step502.content',
    },
    {
      //"content": "Finalmente, en el apartado de configuración se puede gestionar la cuenta del usuario."
      target: '[data-v-step="6"]',
      destPat: 'SettingsView',
      fromPath: 'SignaturesView',
      params: {
        placement: 'right',
      },
      header: {
        title: 'tourOperatorCommonSteps.step303.title',
      },
      content: 'tourOperatorCommonSteps.step303.content',
    },
    {
      //"content": "En el icono de la campana puedes consultar las novedades de la plataforma así como avisos importantes como paradas de mantenimiento."
      target: '[data-v-step="7"]',
      destPat: 'SettingsView',
      fromPath: 'SettingsView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourOperatorCommonSteps.step304.title',
      },
      content: 'tourOperatorCommonSteps.step304.content',
    },
    {
      //"content": "Enhorabuena, has completado satisfactoriamente el tutorial. Puedes repetirlo siempre que quieras accediendo al botón de ayuda."
      target: '[data-v-step="8"]',
      fromPath: 'SettingsView',
      destPat: 'SettingsView',
      header: {
        title: 'tourAdminCommonsSteps.step305.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourAdminCommonsSteps.step305.content',
    },
  ]
}
export const notificationDetailSteps = () => {
  return [
    {
      target: '[data-v-step="20"]', // We're using document.querySelector() under the hood
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      params: {
        highlight: false,
      },
      header: {
        title: 'tourNotificationDetailSteps.step600.title',
        placement: 'right',
      },
      showLogo: true,
      content: 'tourNotificationDetailSteps.step600.content',
    },
    {
      target: '[data-v-step="21"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step601.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step601.content',
    },
    {
      target: '[data-v-step="22"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step602.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step602.content',
    },
    {
      target: '[data-v-step="23"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step603.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step603.content',
    },
    {
      target: '[data-v-step="24"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step604.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step604.content',
    },
    {
      target: '[data-v-step="25"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step605.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step605.content',
    },
    {
      target: '[data-v-step="26"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step606.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step606.content',
    },
    {
      target: '[data-v-step="27"]',
      destPat: 'NotificationDetailView',
      fromPath: 'NotificationDetailView',
      header: {
        title: 'tourNotificationDetailSteps.step607.title',
      },
      params: {
        placement: 'right',
      },
      content: 'tourNotificationDetailSteps.step607.content',
    },
  ]
}
export const signatureDetailSteps = () => {
  return [
    {
      target: '[data-v-step="700"]', // We're using document.querySelector() under the hood
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      params: {
        placement: 'bottom',
      },
      header: {
        title: 'tourSignatureDetailSteps.step700.title',
      },
      showLogo: true,
      content: 'tourSignatureDetailSteps.step700.content',
    },
    {
      target: '[data-v-step="701"]',
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      header: {
        title: 'tourSignatureDetailSteps.step701.title',
      },
      params: {
        placement: 'bottom',
      },
      content: 'tourSignatureDetailSteps.step701.content',
    },
    {
      target: '[data-v-step="702"]',
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      header: {
        title: 'tourSignatureDetailSteps.step702.title',
      },
      params: {
        placement: 'bottom',
      },
      content: 'tourSignatureDetailSteps.step702.content',
    },
    {
      target: '[data-v-step="703"]',
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      header: {
        title: 'tourSignatureDetailSteps.step703.title',
      },
      params: {
        placement: 'top',
      },
      content: 'tourSignatureDetailSteps.step703.content',
    },
    {
      target: '[data-v-step="704"]',
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      header: {
        title: 'tourSignatureDetailSteps.step704.title',
      },
      params: {
        placement: 'bottom',
      },
      content: 'tourSignatureDetailSteps.step704.content',
    },
    {
      target: '[data-v-step="705"]',
      destPat: 'SignatureDetailView',
      fromPath: 'SignatureDetailView',
      header: {
        title: 'tourSignatureDetailSteps.step705.title',
      },
      params: {
        placement: 'bottom',
      },
      content: 'tourSignatureDetailSteps.step705.content',
    },
  ]
}
