<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="enable2FA" inset :label="$t('enable2FA')">
              </v-switch>
            </v-col>
            <template v-if="enable2FA">
              <v-row v-for="(_, i) in multiFactor" :key="i">
                <v-col cols="10">
                  <v-text-field
                    id="phone-input"
                    :disabled="loading"
                    v-model="multiFactor[i]"
                    @input="(data) => setMultifactor(i, data)"
                    :label="$t('phone')"
                    :hint="$t('hintPhone')"
                    prepend-icon="mdi-cellphone-basic"
                    :rules="[required, mobilePhoneNumber]"
                    dense
                    autocomplete="false"
                    @keydown.space.prevent
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    elevation="0"
                    icon
                    rounded
                    @click="removeMultifactor(i)"
                    v-if="multiFactor.length > 1"
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" v-if="enable2FA && multiFactor.length < 5">
                <v-btn
                  elevation="2"
                  outlined
                  rounded
                  small
                  @click="addAnotherMultifactor()"
                >
                  <v-icon>mdi-plus</v-icon> {{ $t('addAnother2FA') }}
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import rules from '@/utils/rules'

export default {
  props: {
    loading: Boolean,
    enable2FAProp: Boolean,
    multiFactorProp: Array,
  },
  data() {
    return {
      enable2FA: this.enable2FAProp ?? false,
      multiFactor: this.multiFactorProp ?? [],
    }
  },
  methods: {
    ...rules,
    setMultifactor(index, value) {
      this.multiFactor[index] = value
      this.$emit('setMultifactor', this.multiFactor)
    },
    addAnotherMultifactor() {
      this.multiFactor.push('')
    },
    removeMultifactor(index) {
      this.multiFactor.splice(index, 1)
    },
  },
  watch: {
    enable2FA: function (newValue) {
      this.$emit('setEnable2FA', newValue)
    },
  },
}
</script>
