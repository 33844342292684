var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.$route.query.section === 'home' || !_vm.$route.query.section)?_c('HomeDoc',{attrs:{"id":"documentation-home"}}):_vm._e(),(_vm.$route.query.section === 'operators' && !_vm.$route.query.subsection)?_c('OperatorsCreateDoc',{attrs:{"id":"documentation-operator-create"}}):_vm._e(),(
      _vm.$route.query.section === 'operators' &&
      _vm.$route.query.subsection === 'createCSV'
    )?_c('OperatorsCreateCSVDoc',{attrs:{"id":"documentation-operator-create-csv"}}):_vm._e(),(
      _vm.$route.query.section === 'operators' &&
      _vm.$route.query.subsection === 'create'
    )?_c('OperatorsCreateDoc'):_vm._e(),(
      _vm.$route.query.section === 'operators' &&
      _vm.$route.query.subsection === 'update'
    )?_c('OperatorsUpdateDoc',{attrs:{"id":"documentation-operator-update"}}):_vm._e(),(
      _vm.$route.query.section === 'operators' &&
      _vm.$route.query.subsection === 'disable'
    )?_c('OperatorsDisableDoc',{attrs:{"id":"documentation-operator-disable"}}):_vm._e(),(
      (_vm.isSuperAdminUser || _vm.isSuperOperatorUser) &&
      _vm.$route.query.section === 'operators' &&
      _vm.$route.query.subsection === 'manageAdmins'
    )?_c('OperatorsManageAdminsDoc',{attrs:{"id":"documentation-operator-disable"}}):_vm._e(),(
      _vm.$route.query.section === 'notifications' && !_vm.$route.query.subsection
    )?_c('NotificationListDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'notificationList'
    )?_c('NotificationListDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'notificationMassiveActions'
    )?_c('NotificationMassiveActionsDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'notificationDetail'
    )?_c('NotificationDetailDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'responsability'
    )?_c('NotificationResponsabilityDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'notificationOpen'
    )?_c('NotificationOpenDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'changeStatus'
    )?_c('NotificationChangeStatusDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'notificationShare'
    )?_c('NotificationShareDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'addNotification'
    )?_c('NotificationAddDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'uploadFiles'
    )?_c('NotificationUploadFilesDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'alertCreate'
    )?_c('AlertCreateDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'alertModify'
    )?_c('AlertModifyDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'addExtraDocument'
    )?_c('NotificationAddExtraDoc'):_vm._e(),(
      _vm.$route.query.section === 'notifications' &&
      _vm.$route.query.subsection === 'addObservation'
    )?_c('NotificationAddObservationDoc'):_vm._e(),(_vm.$route.query.section === 'dashboard' && !_vm.$route.query.subsection)?_c('DashboardDoc'):_vm._e(),(
      _vm.$route.query.section === 'dashboard' &&
      _vm.$route.query.subsection === 'notifications'
    )?_c('DashboardNotificationsDoc'):_vm._e(),(
      _vm.$route.query.section === 'dashboard' &&
      _vm.$route.query.subsection === 'assignments'
    )?_c('DashboardAssignmentsDoc'):_vm._e(),(
      _vm.$route.query.section === 'dashboard' &&
      _vm.$route.query.subsection === 'alerts'
    )?_c('DashboardAlertsDoc'):_vm._e(),(
      _vm.$route.query.section === 'dashboard' &&
      _vm.$route.query.subsection === 'signatures'
    )?_c('DashboardSignaturesDoc'):_vm._e(),(_vm.$route.query.section === 'signatures' && !_vm.$route.query.subsection)?_c('SignatureListDoc',{attrs:{"id":"documentation-operator-create"}}):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureList'
    )?_c('SignatureListDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'createSignature'
    )?_c('SignatureCreateRequestDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureDetail'
    )?_c('SignatureDetailDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureSign'
    )?_c('SignatureSignDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureAbandon'
    )?_c('SignatureAbandonDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureDelete'
    )?_c('SignatureDeleteDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureReject'
    )?_c('SignatureRejectDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureForward'
    )?_c('SignatureForwardDoc'):_vm._e(),(
      _vm.$route.query.section === 'signatures' &&
      _vm.$route.query.subsection === 'signatureIncidence'
    )?_c('SignatureIncidenceStatusDoc'):_vm._e(),(
      _vm.$route.query.section === 'configuration' && !_vm.$route.query.subsection
    )?_c('ConfigurationDoc'):_vm._e(),(
      _vm.$route.query.section === 'configuration' &&
      _vm.$route.query.subsection === 'emailOptions'
    )?_c('ConfigurationEmailOptionsDoc'):_vm._e(),(
      _vm.$route.query.section === 'configuration' &&
      _vm.$route.query.subsection === 'uploadCertificate'
    )?_c('ConfigurationUploadCertificateDoc'):_vm._e(),(
      _vm.$route.query.section === 'configuration' &&
      _vm.$route.query.subsection === 'licenses'
    )?_c('ConfigurationLicensesDoc'):_vm._e(),(_vm.$route.query.section === 'companies' && !_vm.$route.query.subsection)?_c('CompaniesDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'create'
    )?_c('CompaniesCreateDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'update'
    )?_c('CompaniesUpdateDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'delete'
    )?_c('CompaniesDeleteDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'operators'
    )?_c('CompaniesOperatorsDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'export'
    )?_c('CompaniesExportDoc'):_vm._e(),(
      _vm.$route.query.section === 'companies' &&
      _vm.$route.query.subsection === 'createCSV' &&
      !_vm.isEmpresaUser
    )?_c('CompaniesCreateCSVDoc'):_vm._e(),(_vm.$route.query.section === 'clients' && !_vm.$route.query.subsection)?_c('ClientsDoc'):_vm._e(),(
      _vm.$route.query.section === 'clients' &&
      _vm.$route.query.subsection === 'create'
    )?_c('ClientsCreateDoc'):_vm._e(),(
      _vm.$route.query.section === 'clients' &&
      _vm.$route.query.subsection === 'upload'
    )?_c('ClientsUploadDoc'):_vm._e(),(
      _vm.$route.query.section === 'clients' &&
      _vm.$route.query.subsection === 'delete'
    )?_c('ClientsDeleteDoc'):_vm._e(),(
      _vm.$route.query.section === 'clients' && _vm.$route.query.subsection === 'edit'
    )?_c('ClientsEditDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' && !_vm.$route.query.subsection
    )?_c('AutomatedTasksDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'actions'
    )?_c('AutomatedTasksActionsDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'conditions'
    )?_c('AutomatedTasksConditionsDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'targets'
    )?_c('AutomatedTasksTargetsDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'creation'
    )?_c('AutomatedTasksCreationDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'clonation'
    )?_c('AutomatedTasksClonationDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'update'
    )?_c('AutomatedTasksUpdateDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'deletion'
    )?_c('AutomatedTasksDeleteDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'history'
    )?_c('AutomatedTasksHistoryDoc'):_vm._e(),(
      _vm.$route.query.section === 'automatedTasks' &&
      _vm.$route.query.subsection === 'examples'
    )?_c('AutomatedTasksExamplesDoc'):_vm._e(),(_vm.$route.query.section === 'groups' && !_vm.$route.query.subsection)?_c('GroupsDoc'):_vm._e(),(
      _vm.$route.query.section === 'groups' &&
      _vm.$route.query.subsection === 'create'
    )?_c('GroupsCreateDoc'):_vm._e(),(
      _vm.$route.query.section === 'groups' &&
      _vm.$route.query.subsection === 'update'
    )?_c('GroupsUpdateDoc'):_vm._e(),(
      _vm.$route.query.section === 'groups' &&
      _vm.$route.query.subsection === 'delete'
    )?_c('GroupsDeleteDoc'):_vm._e(),(
      _vm.$route.query.section === 'groups' &&
      _vm.$route.query.subsection === 'unregisteredCifs'
    )?_c('GroupsUnregisteredCifsDoc'):_vm._e(),(_vm.$route.query.section === 'analytics' || !_vm.$route.query.subsection)?_c('AnalyticsDoc'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }