var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12","lg":_vm.hasToBeShort ? 6 : 12,"md":_vm.hasToBeShort ? 6 : 12}},[(
      _vm.inputType === 'email' ||
      _vm.inputType === 'emailNoInput' ||
      _vm.inputType === 'groupEmails'
    )?[(_vm.inputType === 'email')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('emails')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-at","value":_vm.emailData.emails,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars), _vm.emailsComa],"dense":"","autocomplete":"false","id":"automated-task-additional-email","hint":_vm.$t('emailsComaHint')},on:{"input":(v) => _vm.setEmailData(v, 'emails')}})],1):_vm._e(),(_vm.inputType === 'groupEmails')?_c('v-col',{attrs:{"cols":"12"}},[_c('GroupAutocomplete',{attrs:{"disabled":_vm.disabled,"objectGroups":_vm.emailData.groups,"groups":_vm.groups,"multiple":true,"rules":[_vm.listNotEmpty]},on:{"setGroups":(groups) => _vm.setEmailData(groups, 'groups')}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('replyTo')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-at","value":_vm.emailData.replyTo,"rules":[_vm.email],"dense":"","autocomplete":"false","id":"automated-task-additional-email-replyTo"},on:{"input":(v) => _vm.setEmailData(v, 'replyTo')}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('emailSubject')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-email-outline","value":_vm.emailData.subject,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars)],"dense":"","autocomplete":"false","id":"automated-task-additional-subject"},on:{"input":(v) => _vm.setEmailData(v, 'subject')}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('text')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-text","value":_vm.emailData.emailText,"auto-grow":"","rows":"1","counter":"","rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-additional-email-text"},on:{"input":(v) => _vm.setEmailData(v, 'emailText')}})],1)]:_vm._e(),(_vm.inputType === 'operator')?_c('v-autocomplete',{attrs:{"dense":"","items":_vm.possibleOperators,"color":"primary","filter":_vm.customFilterOperators,"no-data-text":_vm.$t('emptyOperators'),"label":_vm.$t('selectOperator'),"item-value":"id","item-text":"name","disabled":_vm.disabled,"prepend-icon":"mdi-account","menu-props":{ bottom: true, offsetY: true },"id":"automated-tasks-autocomplete-operator","rules":[_vm.notEmpty]},on:{"input":_vm.setOperator},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{attrs:{"id":"automated-tasks-operator-option"}},[_vm._v(" "+_vm._s(item.name + ' ' + item.surname)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","disabled":_vm.disabled}},[_vm._v(" "+_vm._s(item.name + ' ' + item.surname)+" ")])]}}],null,false,2239383467),model:{value:(_vm.chosenOperator),callback:function ($$v) {_vm.chosenOperator=$$v},expression:"chosenOperator"}}):_vm._e(),(_vm.inputType === 'alert')?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('daysAfter')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-clock-outline","value":_vm.alertData.daysAfter,"rules":[_vm.notEmpty, _vm.maxNumberValue(10000)],"dense":"","autocomplete":"false","type":"number","min":"0","id":"automated-task-days-after-target-input"},on:{"input":(v) => _vm.setAlertData(v, 'daysAfter')}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('text')} - ${_vm.$t(
          'targets.titles.' + _vm.chosenTarget.name
        )}`,"prepend-icon":"mdi-text","value":_vm.alertData.text,"auto-grow":"","rows":"1","counter":"","rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-additional-target-text"},on:{"input":(v) => _vm.setAlertData(v, 'text')}})],1)]:_vm._e(),(_vm.inputType === 'text')?_c('v-textarea',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('text')} - ${_vm.$t('targets.titles.' + _vm.chosenTarget.name)}`,"prepend-icon":"mdi-pencil","value":_vm.text,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","auto-grow":"","rows":"1","counter":"","autocomplete":"false","id":"automated-task-text-observation"},on:{"input":_vm.setData}}):_vm._e(),(_vm.inputType === 'httpsRequest')?[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.methods,"color":"primary","label":_vm.$t('method'),"disabled":_vm.disabled,"prepend-icon":"mdi-meteor","menu-props":{ bottom: true, offsetY: true },"id":"automated-tasks-autocomplete-request-method","rules":[_vm.notEmpty]},on:{"input":(v) => _vm.setRequestData(v, 'method')},model:{value:(_vm.requestData.method),callback:function ($$v) {_vm.$set(_vm.requestData, "method", $$v)},expression:"requestData.method"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`URL - ${_vm.$t('targets.titles.' + _vm.chosenTarget.name)}`,"prepend-icon":"mdi-link","value":_vm.requestData.url,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-days-after-target-input"},on:{"input":(v) => _vm.setRequestData(v, 'url')}})],1)],1),_vm._l((_vm.requestData.headers),function(header,i){return _c('v-row',{key:i + 'header-addition',attrs:{"id":'at-header-' + i}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('headerTitle')} - ${_vm.$t(
            'targets.titles.' + _vm.chosenTarget.name
          )}`,"prepend-icon":"mdi-key-outline","value":header.headerTitle,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-days-after-target-input"},on:{"input":function($event){header.headerTitle = $event}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('headerValue')} - ${_vm.$t(
            'targets.titles.' + _vm.chosenTarget.name
          )}`,"prepend-icon":"mdi-label-outline","value":header.headerValue,"rules":[_vm.notEmpty, _vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-days-after-target-input"},on:{"input":function($event){header.headerValue = $event}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeHeader(i)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('headerTitle')} - ${_vm.$t(
            'targets.titles.' + _vm.chosenTarget.name
          )}`,"prepend-icon":"mdi-key","value":_vm.headerTitle,"rules":[_vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-days-after-target-input"},on:{"input":function($event){_vm.headerTitle = $event}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('headerValue')} - ${_vm.$t(
            'targets.titles.' + _vm.chosenTarget.name
          )}`,"prepend-icon":"mdi-label","value":_vm.headerValue,"rules":[_vm.maxCharacters(_vm.maxChars * 10)],"dense":"","autocomplete":"false","id":"automated-task-days-after-target-input"},on:{"input":function($event){_vm.headerValue = $event}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":_vm.addHeader}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled,"label":`${_vm.$t('bodyJSON')} - ${_vm.$t(
            'targets.titles.' + _vm.chosenTarget.name
          )}`,"prepend-icon":"mdi-code-json","value":_vm.requestData.body,"rules":[_vm.maxCharacters(_vm.maxChars * 100)],"dense":"","auto-grow":"","rows":"3","counter":"","autocomplete":"false","id":"automated-task-text-observation"},on:{"input":(v) => _vm.setRequestData(v, 'body')}})],1)],1)]:_vm._e(),(_vm.inputType === 'state')?_c('v-select',{attrs:{"prepend-icon":"mdi-toggle-switch","disabled":_vm.disabled,"label":_vm.$t('newStatus'),"items":_vm.availableStatus,"variant":"underlined","rules":[_vm.notEmpty]},on:{"input":_vm.setStatus},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item))+" ")]}},{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item))+" ")]}}],null,false,1419837692),model:{value:(_vm.chosenStatus),callback:function ($$v) {_vm.chosenStatus=$$v},expression:"chosenStatus"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }