<template>
  <v-card
    data-v-step="6"
    class="mx-auto"
    outlined
    height="450"
    style="overflow-y: auto"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('userInformation') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('managePersonalUserInfo') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary secondary--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="clickEditUserInfo"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">{{ $t('editCancel') }}</span>
        <span v-else>{{ $t('editInformation') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-form ref="informationForm" v-model="validChangeUserInfo" lazy-validation>
      <v-list-item>
        <v-container>
          <v-row class="pt-4">
            <v-col cols="12">
              <v-text-field
                dense
                disabled
                :value="`${userModified.name} ${userModified.surname}`"
                :label="$t('name')"
                prepend-icon="mdi-account-outline"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                disabled
                v-model="userModified['telefono']"
                :label="$t('phone')"
                prepend-icon="mdi-phone-outline"
              />
            </v-col>
            <v-col cols="12">
              <span v-if="isEditingUserInfo">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      :disabled="!isEditingUserInfo || isSingleSignOnUser"
                      v-model="userModified['email']"
                      :label="$t('email')"
                      prepend-icon="mdi-email-outline"
                      :rules="emailRules"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span v-if="isEditingUserInfo && isSingleSignOnUser">
                    <pre>{{ $t('userSingleSignOnMail') }}</pre>
                  </span>
                </v-tooltip>
              </span>
              <span v-else>
                <v-text-field
                  dense
                  :disabled="!isEditingUserInfo || isSingleSignOnUser"
                  v-model="userModified['email']"
                  :label="$t('email')"
                  prepend-icon="mdi-email-outline"
                  :rules="emailRules"
                />
              </span>
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="isNotificacionesActivas || isFirmasActivas"
                :disabled="!isEditingUserInfo || loading"
                :loading="loading"
                @click.stop="openEmailsDialog()"
                block
                class="primary secondary--text"
              >
                {{ $t('emailsConfiguration.btnCaption') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                :disabled="!isEditingUserInfo || loading"
                :loading="loading"
                @click="updateUserInfo()"
                block
                class="primary secondary--text"
              >
                {{ $t('updateData') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingUser') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
    <SettingsEmailsDialog
      v-if="showDialog"
      :showDialog="showDialog"
      :emailsConfigurationProp="$store.state.user.emailsConfiguration"
      @closeEmailsDialog="closeEmailsDialog"
    />
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateUserEmail } from '@/services/user-service'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import SettingsEmailsDialog from '@/components/settings/SettingsEmailsDialog'

export default {
  components: {
    SettingsEmailsDialog,
  },
  async mounted() {
    this.user = { ...this.$store.state.user }
    this.userModified = { ...this.user }
    if (!this.user) return this.setNotFound(true)
  },
  data() {
    return {
      user: {},
      userModified: {},
      isEditingUserInfo: false,
      loading: false,
      emailRules: [rules.required, rules.email],
      validChangeUserInfo: false,
      showDialog: false,
    }
  },
  computed: {
    ...mapGetters([
      'isNotificacionesActivas',
      'isFirmasActivas',
      'isSingleSignOnUser',
    ]),
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    async updateUserInfo() {
      if (!this.$refs.informationForm.validate()) return

      if (this.userModified.email === this.user.email) {
        this.clickEditUserInfo()
        return
      }
      this.loading = true

      try {
        if (this.userModified.email !== this.user.email) {
          await updateUserEmail({
            newEmail: this.userModified.email,
          })
        }
        this.isEditingUserInfo = false
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })

        this.loading = false
        return
      }

      this.loading = false
      this.user = { ...this.userModified }
    },
    clickEditUserInfo() {
      this.userModified = { ...this.user }
      this.isEditingUserInfo = !this.isEditingUserInfo
    },
    openEmailsDialog() {
      this.showDialog = true
    },
    closeEmailsDialog() {
      this.showDialog = false
    },
  },
}
</script>
