<template>
  <v-col style="max-width: 1200px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.configuration.emailOptions.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.configuration.emailOptions.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 300px" contain :src="pic1Name" />
    <br />
    <p>
      {{ $t('documentation.texts.configuration.emailOptions.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 1200px" contain :src="pic2Name" />
    <br />
    <p>
      {{ $t('documentation.texts.configuration.emailOptions.paragraph3') }}
    </p>
    <p></p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.picEditUser') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.picEmailOptions') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
