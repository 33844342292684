import {
  Firestore,
  SignaturesCollection,
  SignaturesDoc,
  CreateSignature,
  UpdateSignature,
  DeleteSignature,
  SignSignatureRequest,
  GetDocFromReference,
  SignaturesItem,
  SendSignatureOTP,
} from '@/firebase-exports'
import store from '@/store/index'
import { getCifsMap } from './cifs-service'
import { getUserOperatorsDict } from './operator-service'

export async function getUserSignature(userId) {
  const q = Firestore.query(
    SignaturesCollection(userId),
    Firestore.orderBy('createdAt', 'desc')
  )
  const querySnapshot = await Firestore.getDocs(q)
  return querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id }
  })
}

export async function sendSignatureOTP(signatureId, data) {
  return await SendSignatureOTP({ signatureId, ...data })
}

export async function createSignature(userId, data) {
  return await CreateSignature({
    userId,
    ...data,
  })
}

export async function updateSignature(userId, signatureId, data) {
  return await UpdateSignature({
    userId,
    signatureId,
    ...data,
  })
}

export async function deleteSignature(userId, signatureId) {
  return await DeleteSignature({
    userId,
    signatureId,
  })
}

/**
 * Revoca las firmas de los clientes para un documento de firma dado.
 *
 * @param {string} userId - El ID del usuario que inició la solicitud de firma.
 * @param {string} signatureId - El ID del documento de firma.
 * @returns {Promise} Una promesa que se resuelve cuando se han revocado las firmas de los clientes.
 */
export async function revokeClientSignatures(userId, signatureId) {
  // Obtener el documento de firma
  const signatureDoc = await Firestore.getDoc(
    SignaturesDoc(userId, signatureId)
  )

  // Cambiamos estado de la firma de cada cliente
  const updatedClients = signatureDoc.data().clientsToSign.map((client) => {
    client.signed = false
    return client
  })

  const updatedDocumentsVersions = signatureDoc
    .data()
    .documents.map((document) => {
      document.dmsDocVersion = '2'
      return document
    })
  await Firestore.updateDoc(SignaturesDoc(userId, signatureId), {
    clientsToSign: updatedClients,
    clientHistoryAction: store.state.user.id,
    documents: updatedDocumentsVersions,
    signaturePosition: 60,
    isRevoked: true,
    status: 'pending',
  })
}

/**
 * Actualiza la propiedad "needSignature" de un documento en el documento de firma correspondiente en Firestore.
 * @param {string} userId - El ID del usuario propietario del documento de firma.
 * @param {string} signatureId - El ID del documento de firma.
 * @param {string} dmsDocId - El ID del documento en el sistema de gestión de documentos.
 * @returns {Promise<void>} Una promesa que se resuelve cuando se actualiza el documento de firma.
 */
export async function updateSignatureDocumentNeeds(
  userId,
  signatureId,
  dmsDocIdParam
) {
  // Obtener el documento de firma
  const signatureDoc = await Firestore.getDoc(
    SignaturesDoc(userId, signatureId)
  )

  // Mapear el array de documentos, actualizando la propiedad needSignature
  const updatedDocuments = signatureDoc.data().documents.map((document) => {
    if (document.dmsDocId === dmsDocIdParam) {
      // Negar la propiedad needSignature
      document.needSignature = !document.needSignature
    }
    return document
  })

  // Actualizar el documento de firma con el array de documentos modificado
  await Firestore.updateDoc(SignaturesDoc(userId, signatureId), {
    documents: updatedDocuments,
  })
}

export async function signSignatureRequest(userId, signatureId, data) {
  return await SignSignatureRequest({
    userId,
    signatureId,
    ...data,
  })
}
export function getSignatureStatus(signature) {
  const currentDate = new Date()
  if (signature.status == 'signed') {
    return {
      color: 'primary',
      status: 'signatureStatus.signed',
    }
  } else if (signature.status == 'deferred') {
    return {
      color: 'processing',
      status: 'signatureStatus.deferred',
    }
  } else if (signature.status == 'rejected') {
    return {
      color: 'error',
      status: 'signatureStatus.rejected',
    }
  } else if (signature.status == 'incidence') {
    return {
      color: 'error',
      status: 'signatureStatus.incidence',
    }
  } else if (
    signature.status == 'abandoned' ||
    signature.status == 'abandonedByDate'
  ) {
    return {
      color: 'black',
      status: 'signatureStatus.abandoned',
    }
  } else {
    if (currentDate > new Date(signature.dateLimit)) {
      signature.status = 'abandoned'
      return {
        color: 'red',
        status: 'signatureStatus.abandoned',
      }
    } else if (
      currentDate >
      new Date(signature.dateLimit).setDate(
        new Date(signature.dateLimit).getDate() - 3
      )
    ) {
      signature.status = 'urgent'
      return {
        color: 'warning',
        status: 'signatureStatus.urgent',
      }
    } else {
      return {
        color: 'warningLow',
        status: 'signatureStatus.pending',
      }
    }
  }
}

export async function updateSignatureStatus(userId, signatureId, newStatus) {
  return await Firestore.updateDoc(SignaturesDoc(userId, signatureId), {
    status: newStatus,
    clientHistoryAction: store.state.user.id,
  })
}

export async function updateSignatureObservation(userId, signatureId, data) {
  return data.type === 'requester'
    ? await Firestore.updateDoc(SignaturesDoc(userId, signatureId), {
        observationRequester: data.observation,
        isForwarded: data.isForwarded ? true : false,
      })
    : await Firestore.updateDoc(SignaturesDoc(userId, signatureId), {
        observationClient: data.observation,
      })
}

export async function getSignatureSubscription(userId, signatureId, context) {
  try {
    const docSnap = await Firestore.getDoc(SignaturesDoc(userId, signatureId))
    if (!docSnap.exists()) {
      return
    }
  } catch (error) {
    return
  }

  // Snapshot to check the signature
  var signatureUnsubscribe = Firestore.onSnapshot(
    SignaturesDoc(userId, signatureId),
    async (doc) => {
      const document = doc.data()
      // Setting the record in the context
      if (document) {
        if (document.requesterRef)
          document.requester = await GetDocFromReference(document.requesterRef)

        if (document.cifRef)
          document.cif = await GetDocFromReference(document.cifRef)

        if (document.dateDeferred)
          document.dateDeferred = document.dateDeferred.toDate()

        if (document.createdAt) document.createdAt = document.createdAt.toDate()

        if (document.dateLimit) document.dateLimit = document.dateLimit.toDate()

        if (document.alert) document.alert.date = document.alert.date.toDate()

        delete document.infoOTP

        context.signature = { id: signatureId, ...document }

        if (context.loadingSignature) context.loadingSignature = false
      }
    },
    (error) => {
      throw error
    }
  )
  return signatureUnsubscribe
}

export async function signaturesSubscription(userId, user, days = 90) {
  const todaysDate = new Date()

  const from = new Date().setDate(todaysDate.getDate() - days)
  const fromDate = new Date(from)

  const to = new Date().setDate(todaysDate.getDate() + 1) // Add + 1 day to get new docs after initializing subscription
  const toDate = new Date(to)

  const cifsMap = await getCifsMap(userId)
  const requesters = await getUserOperatorsDict(userId, true)

  const unsub = Firestore.onSnapshot(
    days != 0
      ? // Taking the last X days
        Firestore.query(
          SignaturesCollection(userId),
          Firestore.orderBy('createdAt', 'desc'),
          Firestore.startAt(toDate),
          Firestore.endAt(fromDate)
        )
      : // Taking all
        Firestore.query(
          SignaturesCollection(userId),
          Firestore.orderBy('createdAt', 'desc')
        ),
    async (querySnapshot) => {
      let signatures = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          if (document.requesterRef) {
            document.requester =
              requesters[document.requesterRef.id] ??
              (await GetDocFromReference(document.requesterRef))
          }

          if (document.cifRef)
            document.cif =
              cifsMap[document.cifRef.id] ??
              (await GetDocFromReference(document.cifRef))

          if (document.dateDeferred)
            document.dateDeferred = document.dateDeferred.toDate()

          if (document.createdAt)
            document.createdAt = document.createdAt.toDate()

          if (document.dateLimit)
            document.dateLimit = document.dateLimit.toDate()

          if (document.alert) document.alert.date = document.alert.date.toDate()

          delete document.infoOTP

          return { id: doc.id, ...document }
        })
      )

      // Filtramos por cif, si es operador los Ids no coinciden
      if (userId !== user.id) {
        const cifs = user.cifs
          .filter((cif) => cif.firmasActivas)
          .map((cif) => cif.numeroDocIdentidad)
        signatures = signatures.filter((signature) => {
          return (
            cifs.includes(signature.cif?.numeroDocIdentidad) &&
            user.cifsPermissions[signature.cif?.numeroDocIdentidad]
              .firmasActivas &&
            (store.getters.isAdminUser ||
              signature.requesterRef.id === user.id ||
              signature.clientsToSign.some((client) => client.id === user.id))
          )
        })
      }

      // Guardamos en el store
      store.commit('setSignatures', signatures)
    }
  )

  store.commit('setSignaturesUnsubscribe', unsub)
  return unsub
}

export async function listAllSignatures(userId, user) {
  const cifsMap = await getCifsMap(userId)
  const requesters = await getUserOperatorsDict(userId, true)

  const querySnap = await Firestore.getDocs(
    Firestore.query(
      SignaturesCollection(userId),
      Firestore.orderBy('createdAt', 'desc')
    )
  )
  let signatures = await Promise.all(
    querySnap.docs.map(async (doc) => {
      const document = doc.data()
      if (document.requesterRef) {
        document.requester =
          requesters[document.requesterRef.id] ??
          (await GetDocFromReference(document.requesterRef))
      }

      if (document.cifRef)
        document.cif =
          cifsMap[document.cifRef.id] ??
          (await GetDocFromReference(document.cifRef))

      if (document.dateDeferred)
        document.dateDeferred = document.dateDeferred.toDate()

      if (document.createdAt) document.createdAt = document.createdAt.toDate()

      if (document.dateLimit) document.dateLimit = document.dateLimit.toDate()

      if (document.alert) document.alert.date = document.alert.date.toDate()

      delete document.infoOTP

      return { id: doc.id, ...document }
    })
  )

  // Filtramos por cif, si es operador los Ids no coinciden
  if (userId !== user.id) {
    const cifs = user.cifs
      .filter((cif) => cif.firmasActivas)
      .map((cif) => cif.numeroDocIdentidad)
    signatures = signatures.filter((signature) => {
      return (
        cifs.includes(signature.cif?.numeroDocIdentidad) &&
        user.cifsPermissions[signature.cif?.numeroDocIdentidad].firmasActivas &&
        (store.getters.isAdminUser ||
          signature.requesterRef.id === user.id ||
          signature.clientsToSign.some((client) => client.id === user.id))
      )
    })
  }

  return signatures
}

export async function signatureDocument(signature, data) {
  return (
    await SignaturesItem({
      id: signature.id,
      ...data,
    })
  ).data
}
