<template>
  <v-col cols="12" :lg="hasToBeShort ? 6 : 12" :md="hasToBeShort ? 6 : 12">
    <!-- Input when the allowed data type is days -->
    <v-text-field
      v-if="inputType === 'days'"
      :disabled="disabled"
      :label="`${$t('days')} - ${$t(
        'conditions.titles.' + chosenCondition.name
      )}`"
      prepend-icon="mdi-clock"
      :value="days"
      @input="setDays"
      :rules="[notEmpty, maxNumberValue(28)]"
      dense
      autocomplete="false"
      type="number"
      min="1"
      id="automated-task-additional-number"
    />

    <!-- Input when the input type is filter -->
    <v-autocomplete
      v-if="inputType === 'filter'"
      @input="setData"
      dense
      v-model="filter"
      :items="filters"
      color="primary"
      :filter="customFilterFilters"
      :no-data-text="$t('emptyFilters')"
      :label="`${$t('selectFilter')} - ${$t(
        'conditions.titles.' + chosenCondition.name
      )}`"
      item-value="path"
      item-text="name"
      :disabled="disabled"
      prepend-icon="mdi-camera-control"
      :menu-props="{ bottom: true, offsetY: true }"
      id="automated-tasks-autocomplete-filters-additional-data"
      :rules="[notEmpty]"
      multiple
      clearable
    >
      <template v-slot:item="{ item }">
        <span id="automated-task-filter-option">
          {{ item.isCustom ? item.name : $t(`filters.${item.name}`) }}
        </span>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip
          v-if="!item.types"
          small
          class="ma-1"
          :disabled="disabled"
          id="automated-task-filter-selected"
        >
          {{ item.isCustom ? item.name : $t(`filters.${item.name}`) }}
        </v-chip>
        <v-chip
          v-else
          small
          class="ma-1"
          :disabled="disabled"
          id="automated-task-filter-selected"
        >
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>

    <!-- Input when the input type is cif -->
    <v-autocomplete
      v-if="inputType === 'cifs'"
      @input="setData"
      dense
      v-model="cifs"
      :items="cifsProp"
      color="primary"
      :filter="customFilterCifs"
      :no-data-text="$t('emptyCifs')"
      :label="`${$t('selectCifs')} - ${$t(
        'conditions.titles.' + chosenCondition.name
      )}`"
      item-value="numeroDocIdentidad"
      :disabled="disabled"
      prepend-icon="mdi-city"
      :menu-props="{ bottom: true, offsetY: true }"
      id="automated-tasks-autocomplete-cifs-additional-data"
      :rules="[notEmpty]"
      multiple
    >
      <template v-slot:item="{ item }">
        <span id="automated-task-cif-option">
          {{ item.numeroDocIdentidad }} - {{ item.empresa }}
        </span>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip
          small
          class="ma-1"
          :disabled="disabled"
          id="automated-task-cif-selected"
          close
          @click:close="remove(item.numeroDocIdentidad, 'cifs')"
        >
          {{ item.numeroDocIdentidad }} - {{ item.empresa }}
        </v-chip>
      </template>
    </v-autocomplete>

    <!-- Input when the input type is words  -->
    <v-text-field
      v-if="inputType === 'words'"
      :disabled="disabled"
      :label="`${$t('words')} - ${$t(
        'conditions.titles.' + chosenCondition.name
      )}`"
      prepend-icon="mdi-file-word-box-outline"
      :value="words"
      @input="setData"
      :rules="[notEmpty, maxCharacters(maxChars * 10)]"
      dense
      autocomplete="false"
      id="automated-task-condition-additional-data-words"
      :hint="$t('wordsComaHint')"
    />

    <!-- Input when the input type is date  -->
    <v-menu
      v-if="inputType === 'date'"
      dense
      ref="additionalDateMenu"
      v-model="additionalDateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="additionalDate"
          :label="`${$t('date')} - ${$t(
            'conditions.titles.' + chosenCondition.name
          )}`"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          id="additional-conditional-date"
          :disabled="disabled"
          :rules="[notEmpty]"
        ></v-text-field>
      </template>
      <v-date-picker
        @click:date="setDate"
        v-model="additionalDate"
        :locale="$t('javascriptLocale')"
        :min="new Date().toISOString().substring(0, 10)"
        no-title
        scrollable
        :rules="[notEmpty]"
      >
      </v-date-picker>
    </v-menu>

    <!-- Input when the input type is filter -->
    <v-autocomplete
      v-if="inputType === 'notificationType'"
      @input="setData"
      dense
      v-model="notificationType"
      :items="notificationTypes"
      color="primary"
      :filter="customFilterTypes"
      :label="`${$t('selectNotificationType')} - ${$t(
        'conditions.titles.' + chosenCondition.name
      )}`"
      item-value="id"
      item-text="name"
      :disabled="disabled"
      prepend-icon="mdi-camera-control"
      :menu-props="{ bottom: true, offsetY: true }"
      id="automated-tasks-autocomplete-notification-type-additional-data"
      :rules="[notEmpty]"
    >
      <template v-slot:item="{ item }">
        <span id="automated-task-notification-type-option">
          {{ $t('metadata.types.' + item) }}
        </span>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip
          small
          class="ma-1"
          :disabled="disabled"
          id="automated-task-notification-type-selected"
        >
          {{ $t('metadata.types.' + item) }}
        </v-chip>
      </template>
    </v-autocomplete>

    <!-- Input when the input type is groups -->
    <v-col cols="12" v-if="inputType === 'groups'">
      <GroupAutocomplete
        @setGroups="setGroups"
        :disabled="disabled"
        :objectGroups="groups"
        :groups="groupsProp"
        :multiple="true"
        :rules="[listNotEmpty]"
      />
    </v-col>
  </v-col>
</template>

<script>
import GroupAutocomplete from '@/components/groups/GroupAutocomplete'
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    conditions: Array,
    cifsProp: Array,
    filters: Array,
    chosenCondition: Object,
    disabled: Boolean,
    totalAdditionalFields: Number,
    groupsProp: Array,
    fieldIndex: Number,
  },
  components: {
    GroupAutocomplete,
  },
  mounted() {
    // Setting the allowed condition type of the given condition
    const conditionFound = this.conditions.find(
      (condition) => condition.name === this.chosenCondition.name
    )
    if (conditionFound) this.inputType = conditionFound.inputType

    // Setting the data based on the allowed data type
    if (this.inputType === 'days') this.days = this.chosenCondition.data
    if (this.inputType === 'filter') this.filter = this.chosenCondition.data
    if (this.inputType === 'cifs') this.cifs = this.chosenCondition.data
    if (this.inputType === 'words') this.words = this.chosenCondition.data
    if (this.inputType === 'groups') this.groups = this.chosenCondition.data
    if (this.inputType === 'date')
      this.additionalDate = this.chosenCondition.data
    if (this.inputType === 'notificationType')
      this.notificationType = this.chosenCondition.data
  },
  data() {
    return {
      inputType: {},
      currentDate: new Date(),
      maxChars: 100,
      days: undefined,
      filter: undefined,
      cifs: undefined,
      words: undefined,
      groups: undefined,
      notificationType: undefined,
      additionalDate: '',
      additionalDateMenu: false,
      notificationTypes: [
        'resLiqProv',
        'AcImSaInTri',
        'LiInDeDeEx',
        'DiEmCuBa',
        'DiEmCred',
        'AEAT_RE02',
        'embargoSS',
        'smac',
        'juzgadoSocial',
        'juzgadoInstruccion',
        'juzgadoPrimeraInstancia',
        'juzgadoMercantil',
        'juzgadoPaz',
        'juzgadoContenciosoAdministrativo',
        'juzgadoPenal',
        'juzgadoVigilanciaPenitenciaria',
        'juzgadoMenores',
        'juzgadoViogen',
        'licitacion',
        'prevencion',
        'embargoNominaOtros',
        'garantiaTesoreria',
        'seguroCaucion',
        'notificacionDenuncia',
        'registroLicitadores',
        'sistemasControl',
        'culturaCuentasAnuales',
        'culturaPlanesActuacion',
        'culturaOtros',
      ],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Removed a chip
     * @param {Object} item Item to remove.
     */
    remove(item, subject) {
      const index = this[subject].indexOf(item)
      if (index >= 0) this[subject].splice(index, 1)
    },
    /**
     * Sets the selected groups.
     * @param {Array} ids Ids of groups to set.
     */
    setGroups(data) {
      this.groups = data
      this.$emit('setData', data)
    },
    /**
     * Sets the number of days in this and the parent component.
     * @param {String} data Number of days set.
     */
    setDays(data) {
      this.days = data
      this.$emit('setData', parseInt(data))
    },
    /**
     * Stablishes a date.
     * @param {Date} data Date to stablish.
     */
    setDate(data) {
      this.$refs.additionalDateMenu.save(data)
      this.$emit('setData', data)
    },
    /**
     * Sets the filter in this and the parent component.
     * @param {String} data Id of the filter.
     */
    setData(data) {
      this.$emit('setData', data)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterTypes(item, queryText) {
      const name = this.$t('metadata.types.' + item).toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.includes(searchText)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterFilters(item, queryText) {
      const name = item.isCustom
        ? item.name.toLowerCase()
        : this.$t(`filters.${item.name}`).toLocaleLowerCase()
      const searchText = queryText.toLowerCase()
      return name.includes(searchText)
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * Cif version.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @returns True if the item contains the text.
     */
    customFilterCifs(item, queryText) {
      const empresa = item.empresa.toLowerCase()
      const cif = item.numeroDocIdentidad.toLowerCase()
      const searchText = queryText.toLowerCase()
      return empresa.includes(searchText) || cif.includes(searchText)
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser', 'isOperatorUser']),
    hasToBeShort() {
      return (
        (this.fieldIndex % 2 === 0 &&
          this.fieldIndex + 1 < this.totalAdditionalFields) ||
        (this.fieldIndex % 2 !== 0 &&
          this.fieldIndex + 1 <= this.totalAdditionalFields)
      )
    },
  },
  watch: {
    chosenCondition(newValue) {
      this.inputType = newValue.inputType
    },
  },
}
</script>
