<template>
  <v-dialog :value="true" persistent :max-width="dialogWidth">
    <v-card>
      <v-card-title>
        <v-row justify="center">
          <v-col cols="12" align="center">
            <div class="body-1">
              {{ $t('clientActions.title.' + action) }}
            </div>
            <div>
              {{ cif.empresa }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" align="center"> </v-col>

        <template v-if="isDelete">
          <v-col cols="12" align="center">
            <p>{{ $t('sureRemoveClientGD') }}</p>
          </v-col>
        </template>

        <template v-if="isCreate">
          <!-- Step 0 asks for email -->
          <template v-if="step === 0">
            <!-- Asking for the email of the client -->
            <v-col cols="12" class="mt-4">
              <v-form
                ref="emailForm"
                v-model="validEmailForm"
                @submit.prevent="() => {}"
                lazy-validation
              >
                <v-text-field
                  :label="$t('email')"
                  prepend-icon="mdi-email"
                  :value="clientEmail"
                  @input="clientEmail = $event"
                  dense
                  autocomplete="false"
                  id="client-email-input"
                  :disabled="!!existingClient || loading"
                  :rules="[maxCharacters(maxChars), notEmpty, email]"
                />
              </v-form>
            </v-col>

            <!-- If the client already exists we show the data-->
            <v-col cols="12" align="center" v-if="existingClient">
              <h3>{{ $t('userAlreadyRegistered') }}</h3>
              <p>{{ $t('useAlreadyCreated') }}</p>
              <v-container></v-container>
              <v-col cols="8">
                <v-row align="center" style="max-width: 400">
                  <v-col cols="2">
                    <AppAvatar
                      :userName="existingClient.name"
                      avatarColor="primary"
                      initialsColor="secondary"
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-col>
                  <v-col cols="8" align="left">
                    <div class="body-1 grey-darken-4">
                      {{ existingClient.name }}
                    </div>
                    <div>
                      {{ existingClient.email }}
                    </div>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-btn
                      @click="removeExistingClient"
                      icon
                      :disabled="loading"
                    >
                      <v-icon> mdi-window-close </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </template>

          <!-- Step 1 asks for client data, if it exists it fills the data -->
          <template v-else-if="step === 1">
            <v-form
              ref="clientForm"
              v-model="validClientForm"
              @submit.prevent="() => {}"
              lazy-validation
            >
              <v-col cols="12">
                <v-text-field
                  :label="$t('email')"
                  prepend-icon="mdi-email"
                  :value="clientData.email"
                  @input="clientData.email = $event"
                  dense
                  autocomplete="false"
                  id="company-email-user"
                  :disabled="!!existingClient || this.clientEmail !== ''"
                  :rules="[maxCharacters(maxChars), notEmpty, email]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('firstName')"
                  prepend-icon="mdi-account"
                  :value="clientData.name"
                  @input="clientData.name = $event"
                  dense
                  autocomplete="false"
                  id="client-first-name"
                  :disabled="!!existingClient || loading"
                  :rules="[maxCharacters(maxChars), notEmpty]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('lastName')"
                  prepend-icon="mdi-account-settings"
                  :value="clientData.surname"
                  @input="clientData.surname = $event"
                  dense
                  autocomplete="false"
                  id="client-last-name"
                  :disabled="!!existingClient || loading"
                  :rules="[maxCharacters(maxChars), notEmpty]"
                />
              </v-col>
            </v-form>
          </template>
        </template>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          v-if="step === 0"
          color="primary"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="moveToStep(step + 1)"
          id="continue-client"
        >
          {{ $t('continue') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
        <v-btn
          v-if="step === 1"
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="performAction"
          id="confirm-client"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import AppAvatar from '@/components/commons/AppAvatar.vue'
import { getUserClients, isNonClientByEmail } from '@/services/user-service'
import { createClient } from '@/services/client-service'
import { deleteClientGD } from '@/services/cifs-service'
import getErrorText from '@/utils/get-error-text'
export default {
  props: {
    cif: Object,
    action: String,
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    // If there is not a client it means we must create a new one
    if (this.isCreate) this.clients = await getUserClients(userId)
    else if (this.isDelete) this.step = 1
  },
  data() {
    return {
      clientData: {},
      clientEmail: undefined,
      validClientForm: false,
      clients: undefined,
      existingClient: undefined,
      loading: false,
      maxChars: 100,
      step: 0,
      validEmailForm: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeClientDialog')
    },
    confirmDialog() {
      this.$emit('confirmClientDialog', this.clientData.id)
    },
    /**
     * Resets a variable of the context (data) and sets it to undefined.
     * @param {string} key Context variable name.
     */
    resetField(key) {
      this[key] = undefined
    },
    /**
     * Function that moves the dialog to a different step.
     *
     * 0 -> 1: Checks if the email introduced belongs to any client.
     */
    async moveToStep(newStep) {
      this.loading = true
      try {
        if (this.step === 0 && newStep === 1) {
          // Checking if the form is correct
          if (!this.$refs.emailForm?.validate()) return

          const userId = this.isOperatorUser
            ? this.$store.state.user.parentRef.id
            : this.$store.state.user.id

          // Checking if the email of the user is used
          // by someone that is not a client.
          if (
            !this.clients.some(
              (client) =>
                client.email.toLowerCase() === this.clientEmail.toLowerCase()
            )
          ) {
            const result = await isNonClientByEmail(this.clientEmail, userId)
            if (result)
              return this.setSnackbar({
                position: 'top',
                type: 'error',
                message: this.$t('nonClientRegistered'),
              })
          }

          if (!this.existingClient) {
            // Checking if the email exists.
            const client = this.clients.find(
              ({ email }) => email === this.clientEmail
            )

            // If there is a client with the same email we show it to the user
            if (client) {
              this.existingClient = client
              return
            } else {
              // Setting the client data
              this.clientData = {
                email: this.clientEmail,
                name: this.name,
                surname: this.surname,
              }
            }
          } else {
            // Setting the data of the existing client
            this.clientData = { ...this.existingClient }
            await this.performAction(false) // Evitar redundancia de pantalla con los datos rellenados donde ya no hay que indicar nada más
          }
        }

        this.step = newStep
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * Removes the existing client
     */
    removeExistingClient() {
      this.existingClient = undefined
    },
    /**
     * Function to upload create the client
     */
    async performAction(validateForm = true) {
      if (validateForm)
        if (this.isCreate && !this.$refs.clientForm?.validate()) return

      this.loading = true

      try {
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        if (this.isCreate) {
          // If only the client needs to be added
          if (!this.clientData.id)
            this.clientData.id = await createClient(
              userId,
              this.clientData.name,
              this.clientData.surname,
              this.clientData.email
            )

          this.confirmDialog()
          return
        } else if (this.isDelete)
          await deleteClientGD(userId, this.cif.id, this.cif)

        const message = this.$t('clientActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
    isCreate() {
      return this.action === 'createGD'
    },
    isDelete() {
      return this.action === 'deleteGD'
    },
    // Gives the dialog width depending on the screen
    dialogWidth() {
      if (this.isCreate) return 800
      if (this.isDelete) return 650
      return 500
    },
  },
  components: { AppAvatar },
}
</script>
