<template>
  <div>
    <div :style="styleBar">
      <div style="max-height: 535px; height: 535px">
        <apexchart
          type="bar"
          :options="series.chartOptions"
          :series="series.series"
        ></apexchart>
      </div>
      <v-icon
        v-if="series.loading"
        style="top: -50%; left: 50%; z-index: 999; position: relative"
      >
        mdi-autorenew mdi-spin
      </v-icon>
    </div>
    <v-select
      :label="$t('notificationsByCif.optionText')"
      v-model="selectedCIFS"
      :items="series.optionsItems"
      outlined
      chips
      deletable-chips
      multiple
      :clearable="true"
      :menu-props="{ top: true, offsetY: true }"
      :item-text="itemProps"
      item-value="value"
      @change="(selection) => selectionChanged(selection)"
    >
      >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.title }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ selectedCIFS.length - 1 }} CIFS)
        </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

// Valor 1 para las Comunicaciones, Valor 2 para las Notificaciones

const typeComunication = '1'
const typeNotification = '2'

const notificationTypeComunication = 'Comunicacion'
const notificationTypeNotification = 'Notificacion'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartname: String,
    title: String,
    notifications: Array,
    height: String,
  },
  data() {
    var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    return {
      filteredNotifications: [],
      selectedCIFS: [0],
      previousSelectedCIFS: [0],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          stacked: true,
        },
        theme: {
          mode: theme,
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: [],
          scrollbar: {
            enabled: true,
          },
        },
        legend: {
          position: 'top',
        },
        colors: ['#80c7fd', '#008FFB'],
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
      },
      styleBar: {
        'overflow-y': 'auto',
        'white-space': 'nowrap',
        height: this.height - 65 + 'px',
        'min-height': this.height - 65 + 'px',
        'margin-bottom': '10px',
        width: '100%',
      },
    }
  },

  computed: {
    series() {
      //console.log(this.notifications)
      // notifications accumulate by state
      if (this.notifications === undefined || this.notifications.length === 0) {
        return {
          chartOptions: this.chartOptions,
          series: [],
          loading: true,
        }
      }
      let dataMap = {}
      let cifMap = {}
      let nameMap = {}
      let xkeys = []
      let names = []
      this.notifications.forEach((notification) => {
        let type = this.parseType(notification.tipo_envio)
        if (type === '') {
          console.log('unexpected notification type')
          return
        }
        if (
          notification.nif_titular === undefined ||
          notification.nif_titular === ''
        ) {
          return
        }
        var upNif_titular = notification.nif_titular.toUpperCase()
        if (cifMap[upNif_titular] === undefined) {
          cifMap[upNif_titular] = notification.nombre_titular
          nameMap[notification.nombre_titular] = upNif_titular
          names.push(notification.nombre_titular)
        }
        this.updateDataMap(dataMap, notification.nif_titular, type)
      })

      names.sort()

      let seriesCom = {
        group: 'Comunicacion',
        name: 'Comunicacion',
        data: [],
      }
      let seriesNot = {
        group: 'Notificacion',
        name: 'Notificacion',
        data: [],
      }

      for (let idx in names) {
        var name = names[idx]
        var currentData = dataMap[nameMap[name]]

        this.selectedCIFS.find((cif) => {
          if (cif === 0 || cif === name) {
            seriesCom.data.push(currentData.Comunicacion)
            seriesNot.data.push(currentData.Notificacion)
            xkeys.push([name, nameMap[name]])
          }
        })
      }

      var series = [seriesNot, seriesCom]

      let pack = {}
      if (this.notifications.length === 0) {
        pack = {
          chartOptions: this.createCustomOptions(xkeys),
          series: [],
          loading: false,
          optionsItems: [],
        }
      } else {
        var optionsItems = []
        optionsItems.push({
          title: this.$t('operatorsByDate.selectAll'),
          value: 0,
        })
        names.forEach((name) => {
          optionsItems.push({
            title: name,
            value: name,
            subtitle: nameMap[name],
          })
        })
        pack = {
          chartOptions: this.createCustomOptions(xkeys),
          series: series,
          loading: false,
          // nameMap: nameMap,
          optionsItems: optionsItems,
        }
      }
      return pack
    },
  },
  methods: {
    createCustomOptions(xkeys) {
      var heighN = Number(this.height)
      var expectedHeight = xkeys.length * 60
      expectedHeight = (expectedHeight < heighN ? heighN : expectedHeight) - 20 // those 20px avoids unnecesary scroll bars with low amounts of data
      // Building chart options
      return {
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: xkeys,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config)
              setTimeout(() => {
                this.showDetail(
                  xkeys[config.dataPointIndex],
                  config.seriesIndex
                )
              }, 10)
            },
            xAxisLabelClick: (event, chartContext, config) => {
              //console.log(config)
              setTimeout(() => {
                this.showDetail(xkeys[config.labelIndex])
              }, 10)
            },
          },
          height: expectedHeight,

          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
      }
    },
    selectionChanged(/*selection*/) {
      //console.log(selection)
      //console.log(this.selectedCIFS)
      var allIndex = this.selectedCIFS.indexOf(0)
      if (
        //Estaba seleccionado Todos y han elegido otro, así que quitamos automáticamente todos de la seleccion
        (this.previousSelectedCIFS[0] === 0) &
        (this.previousSelectedCIFS.length === 1)
      ) {
        this.selectedCIFS.splice(allIndex, 1)
      }

      if (
        (allIndex > 0) & // Habia otros seleccionados y acabn de seleccionar Todos, borro la selección de los demás
        (this.previousSelectedCIFS.length > 0)
      ) {
        this.selectedCIFS = [0]
      }
      this.previousSelectedCIFS = this.selectedCIFS
    },
    parseType(type) {
      switch (type) {
        case typeComunication:
          return notificationTypeComunication
        case typeNotification:
          return notificationTypeNotification
        default:
          return ''
      }
    },
    updateDataMap(dataMap, cif, type) {
      if (dataMap[cif] === undefined) {
        dataMap[cif] = {
          Comunicacion: 0,
          Notificacion: 0,
        }
      }
      dataMap[cif][type] += 1
    },
    seriesMatchType(notification, serieIdx) {
      return (
        serieIdx === undefined ||
        (serieIdx === 0 && notification.tipo_envio === typeNotification) ||
        (serieIdx === 1 && notification.tipo_envio === typeComunication)
      )
    },
    filterNotifications(name, serieIdx) {
      var cif = name[1]
      this.filteredNotifications = this.notifications.filter((notification) => {
        return (
          !(
            notification.nif_titular === undefined ||
            notification.nif_titular === ''
          ) &&
          notification.nif_titular.toUpperCase() === cif &&
          this.seriesMatchType(notification, serieIdx)
        )
      })
    },
    showDetail(name, serieIdx) {
      this.filterNotifications(name, serieIdx)
      let title =
        serieIdx === 1
          ? this.$t('notificationsByCif.titleComunicationsGrid')
          : this.$t('notificationsByCif.titleNotificationsGrid')
      this.$emit('detail-level', this.filteredNotifications, title)
    },
    itemProps(item) {
      if (item.subtitle) {
        return item.title + ' - ' + item.subtitle
      } else {
        return item.title
      }
    },
  },
  mounted() {
    let dynamicLabelSize =
      this.$el.clientWidth > 960
        ? this.$el.clientWidth * 0.12
        : this.$el.clientWidth * 0.2
    this.series.chartOptions = {
      yaxis: {
        labels: {
          maxWidth: dynamicLabelSize,
        },
      },
    }
  },
}
</script>
