<template>
  <v-dialog :value="true" persistent :max-width="600">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{ $t('missingNotifications') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn @click="closeDialog" icon>
              <v-icon> mdi-window-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="body-1">
        <AppSkeleton
          data-v-step="5"
          :condition="group.unregisteredCifs === undefined"
          type="card"
        >
          <template v-slot:skeleton-content>
            <v-container>
              <p>
                {{ $t('yourCurrentGroupNotifications') }}
              </p>
              <ul>
                <li :key="i" v-for="(cif, i) in group.unregisteredCifs">
                  • <b>{{ cif.empresa }}</b> ({{ cif.numeroDocIdentidad }})
                  <v-tooltip max-width="400" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="loading[cif.id]"
                        icon
                        @click="handleClick(cif, i)"
                      >
                        <v-icon>mdi-account-multiple-plus</v-icon>
                      </v-btn>
                    </template>
                    <div width="300">
                      {{ $t('addCifToGroup', { groupName: group.name }) }}
                    </div>
                  </v-tooltip>
                  <v-progress-circular
                    v-if="loading[cif.id]"
                    indeterminate
                    size="20"
                    width="2"
                  />
                </li>
              </ul>
            </v-container>
          </template>
        </AppSkeleton>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import { addCifToGroup } from '@/services/groups-service'
import { removeUnregistered } from '@/services/groups-service'
import getErrorText from '@/utils/get-error-text'

export default {
  props: {
    group: Object,
  },
  data() {
    return {
      loading: {},
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async handleClick(cif, index) {
      try {
        this.loading[cif.id] = true
        await addCifToGroup(cif.id, this.group.id)
        await removeUnregistered(cif.id, this.group.id)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('cifAddedToGroupSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
        delete this.group.unregisteredCifs[index]
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    /**
     * Closes the dialog
     */
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>
