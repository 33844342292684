<template>
  <v-col style="max-width: 1000px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.operators.createCSV.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.operators.createCSV.paragraph1') }}
    </p>
    <v-img
      style="width: 100%; max-width: 900px"
      class="mb-5"
      contain
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.operators.createCSV.paragraph2') }}
    </p>
    <span> <b>nombre</b>: {{ $t('csvOperators.name') }} </span>
    <br />
    <span>
      <b>apellidos</b>:
      {{ $t('csvOperators.surname') }}
    </span>
    <br />
    <span>
      <b>email</b>:
      {{ $t('csvOperators.email') }}
    </span>
    <br />
    <span>
      <b>alertasEmail</b>: {{ $t('csvOperators.emailAlerts') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>permisosAdmin</b>: {{ $t('csvOperators.adminPermissions') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>notificacionesActivas</b>:
      {{ $t('csvOperators.notificacionesActivas') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>filtros</b>: {{ $t('csvOperators.filters') }} →
      <b>autonomicAndLocal / dgt / aeat / justice / ss / otherMinistries</b>
    </span>
    <br />
    <span>
      <b>permisosFirmaNotificaciones</b>:
      {{ $t('csvOperators.downloadNotifications') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>permisosTramiteNotificaciones</b>:
      {{ $t('csvOperators.processNotifications') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>firmasActivas</b>: {{ $t('csvOperators.firmasActivas') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>empresas</b> ({{ $t('optional') }}): {{ $t('csvOperators.empresas') }}
    </span>
    <br />
    <span>
      <b>nuevasEmpresas</b>: {{ $t('csvOperators.addNewCifs') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <br />
    <p>
      {{ $t('documentation.texts.operators.createCSV.paragraph3') }}
    </p>
    <v-img
      style="width: 100%; max-width: 900px"
      class="mb-5"
      contain
      :src="pic2Name"
    />
    <b>
      <span> {{ $t('csvSeparation') }} </span>
    </b>
    <br />
    <br />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/operators/' +
        this.$t('documentation.texts.operators.createCSV.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/operators/' +
        this.$t('documentation.texts.operators.createCSV.pic2') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
