<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <AutomatedTasksList
          :automatedTasks="automatedTasks"
          :loading="loading"
          :actions="actions"
          :conditions="conditions"
          :targets="targets"
          :cifs="cifs"
          :operators="operators"
          :filters="filters"
          :groups="groups"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAutomatedTasksSubscription } from '@/services/automated-tasks-service'
import AutomatedTasksList from '@/components/automated-tasks/AutomatedTaskList'
import { listCifs } from '@/services/cifs-service'
import { getUserOperators } from '@/services/operator-service'
import { listFilters } from '@/services/filter-service'
import actions from '@/utils/automated-tasks/actions'
import conditions from '@/utils/automated-tasks/conditions'
import targets from '@/utils/automated-tasks/targets'
import { getGroups } from '@/services/groups-service'

export default {
  components: {
    AutomatedTasksList,
  },
  data() {
    return {
      actions: actions,
      conditions: conditions,
      targets: targets,
      cifs: [],
      operators: [],
      filters: [],
      groups: [],
      loading: true,
    }
  },
  async created() {
    if (!this.$store.state.manageAutomatedTasks)
      await getAutomatedTasksSubscription()

    // Performing queries at the same time
    await Promise.all([
      (async () => (this.cifs = await listCifs()))(),
      (async () => (this.operators = await getUserOperators()))(),
      (async () => (this.filters = await listFilters()))(),
      (async () => {
        const { groups } = await getGroups()
        this.groups = groups
      })(),
    ])
    this.loading = false
  },
  computed: {
    automatedTasks() {
      return this.$store.state.manageAutomatedTasks ?? []
    },
  },
}
</script>
