let types = [
  { id: 1, name: 'issue', enabled: true },
  { id: 2, name: 'task', enabled: false },
  { id: 3, name: 'enquiry', enabled: true, default: true },
  { id: 4, name: 'history', enabled: false },
  { id: 5, name: 'meeting', enabled: false },
  { id: 6, name: 'epic', enabled: false },
  { id: 7, name: 'customer', enabled: false },
  { id: 8, name: 'oportunity', enabled: false },
  { id: 9, name: 'invoice', enabled: true },
]

let priority = [
  { id: 3, name: 'low', enabled: true },
  { id: 4, name: 'normal', enabled: true, default: true },
  { id: 5, name: 'high', enabled: true },
  { id: 6, name: 'urgent', enabled: true },
  { id: 7, name: 'inmediate', enabled: false },
]

export const redmineTypes = (getOnlyDefaultItem = false) => {
  return getOnlyDefaultItem
    ? types.find((o) => o.default === true)
    : types.filter((o) => o.enabled === true)
}

export const redminePriority = (getOnlyDefaultItem = false) => {
  return getOnlyDefaultItem
    ? priority.find((o) => o.default === true)
    : priority.filter((o) => o.enabled === true)
}
