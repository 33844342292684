<template>
  <v-card height="180" elevation="5" class="rounded-t-xl">
    <v-container class="pa-5" fill-height fluid>
      <v-row align="center" justify="center">
        <h1 class="ml-5 mr-4">{{ $t('alerts') }}</h1>
        <DialogButton section="dashboard" subsection="alerts" />
      </v-row>
      <v-row align="center">
        <v-col
          v-if="!isMobile"
          class="title d-flex justify-center align-center pt-3"
          cols="2"
        >
          <v-hover v-slot="{ hover }">
            <v-icon
              :class="hover ? 'orange--text' : ''"
              @click="
                $router.push({
                  name:
                    assetType === 'notifications'
                      ? 'NotificationsView'
                      : 'SignaturesView',
                })
              "
              size="80px"
              id="alert-notification-list-dashboard"
            >
              mdi-bell
            </v-icon>
          </v-hover>
        </v-col>
        <v-col
          data-v-step="1"
          class="text-right pa-2 mt-2"
          cols="12"
          md="10"
          lg="10"
        >
          <v-row>
            <v-col class="text-right" cols="6">
              <div class="body-1 d-flex justify-start align-center">
                {{ $t('total') }}: <v-spacer />
                <span>
                  <v-chip small class="bold body-1 grey white--text ml-2">
                    {{ totalCount }}
                  </v-chip>
                </span>
              </div>
              <div class="body-1 mt-3 d-flex justify-start align-center">
                {{ $t('alertStatus.pending') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 accept white--text ml-2">
                    {{ pendingCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex mb-3 body-1 justify-start align-center">
                {{ $t('alertStatus.urgent') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text orange ml-2">
                    {{ urgentCount }}
                  </v-chip>
                </span>
              </div>
              <div class="d-flex body-1 justify-start align-center">
                {{ $t('alertStatus.expired') }}:<v-spacer />
                <span>
                  <v-chip small class="bold body-1 white--text red ml-2">
                    {{ expiredCount }}
                  </v-chip>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { mapGetters } from 'vuex'
export default {
  props: {
    assetType: String,
    urgentCount: {
      type: Number,
      default: 0,
    },
    expiredCount: {
      type: Number,
      default: 0,
    },
    pendingCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DialogButton,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
