<template>
  <v-container fluid>
    <template v-if="!isMobile">
      <v-row justify="center">
        <v-col cols="6" lg="5">
          <NotificationPropertiesCard
            v-if="notification"
            :loadingNotification="loadingNotification"
            :notification="notification"
            :isSharedAccess="isSharedAccess"
            :shared="shared"
          >
          </NotificationPropertiesCard>
        </v-col>
        <v-col v-if="notification.document" cols="6" lg="4">
          <NotificationFiles
            :notification="notification"
            :isSharedAccess="isSharedAccess"
          />
        </v-col>

        <v-col v-if="!isSharedAccess" cols="4" lg="3">
          <v-tabs v-if="shared.length > 0" v-model="tab">
            <v-tab>{{ $t('history') }}</v-tab>
            <v-tab id="shared-with-tab" v-if="shared.length > 0">
              {{ $t('sharedWith') }}
            </v-tab>
          </v-tabs>
          <NotificationHistory
            data-v-step="27"
            v-if="tab == 0"
            :loadingHistory="loadingHistory"
            :history="history"
            :shareCodes="shared.length > 0"
          />
          <NotificationShareCodes
            v-if="tab == 1"
            :loadingShareCodes="loadingShareCodes"
            :shared="shared"
            :notificationId="notification.id"
          />
        </v-col>
      </v-row>
    </template>
    <!-- VersiÃ³n mÃ³vil -->
    <template v-else>
      <NotificationPropertiesCard
        v-if="notification"
        :loadingNotification="loadingNotification"
        :notification="notification"
        :isSharedAccess="isSharedAccess"
        :shared="shared"
      />
      <v-spacer class="my-5" />
      <NotificationFiles
        v-if="notification.document"
        :notification="notification"
        :isSharedAccess="isSharedAccess"
      />
      <v-spacer class="my-5" />
      <v-tabs v-if="!isSharedAccess && shared.length > 0" v-model="tab">
        <v-tab>{{ $t('history') }}</v-tab>
        <v-tab id="shared-with-tab" v-if="shared.length > 0">
          {{ $t('sharedWith') }}
        </v-tab>
      </v-tabs>
      <NotificationHistory
        v-if="tab == 0 && !isSharedAccess"
        :loadingHistory="loadingHistory"
        :history="history"
      />
      <NotificationShareCodes
        v-if="tab == 1"
        :loadingShareCodes="loadingShareCodes"
        :shared="shared"
        :notificationId="notification.id"
      />
    </template>
  </v-container>
</template>

<script>
import NotificationPropertiesCard from '@/components/notifications/detail/NotificationPropertiesCard'
import NotificationFiles from '@/components/notifications/detail/NotificationFiles'
import NotificationHistory from '@/components/commons/AssetHistory'
import NotificationShareCodes from '@/components/notifications/detail/NotificationShareCodes'
import { mapGetters } from 'vuex'
export default {
  props: {
    notification: Object,
    loading: Boolean,
    history: Array,
    shared: Array,
    isSharedAccess: Boolean,
    loadingNotification: Boolean,
    loadingHistory: Boolean,
    loadingShareCodes: Boolean,
  },
  components: {
    NotificationPropertiesCard,
    NotificationFiles,
    NotificationHistory,
    NotificationShareCodes,
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
