<template>
  <v-row justify="center">
    <!-- Column one where the name, email... is -->
    <v-col cols="12" :lg="optionSelected ? 4 : 12">
      <v-row>
        <v-col
          cols="12"
          :md="isUpdate || isDisplay ? 12 : 8"
          :lg="isUpdate || isDisplay ? 12 : 8"
        >
          <v-text-field
            :disabled="disabled"
            :label="$t('email')"
            prepend-icon="mdi-email"
            @input="operatorEmail = $event"
            :value="operatorEmail"
            :rules="[
              notEmpty,
              email,
              ...(isCreate
                ? [unique(operatorsEmails, 'operatorEmailExists')]
                : []),
            ]"
            dense
            autocomplete="false"
            id="operator-email-input"
          />
        </v-col>
        <v-col cols="12" md="4" lg="4" v-if="isCreate">
          <v-checkbox
            :disabled="disabled"
            v-model="emailAlerts"
            :label="$t('emailAlerts')"
          />
        </v-col>
        <v-col cols="12" md="5" lg="5">
          <v-text-field
            :disabled="disabled"
            :label="$t('firstName')"
            prepend-icon="mdi-account"
            :value="name"
            @input="name = $event"
            :rules="[notEmpty]"
            dense
            autocomplete="false"
            id="operator-name-input"
          />
        </v-col>
        <v-col cols="12" md="7" lg="7">
          <v-text-field
            :disabled="disabled"
            :label="$t('lastName')"
            prepend-icon="mdi-account-settings"
            :value="surname"
            @input="surname = $event"
            :rules="[notEmpty]"
            dense
            autocomplete="false"
            id="operator-surname-input"
          />
        </v-col>
        <v-col
          cols="12"
          v-if="
            groups?.length &&
            (isSuperAdminUser ||
              isSuperOperatorUser ||
              (isAdminUser && !isInGroupUser))
          "
        >
          <GroupAutocomplete
            @setGroups="(groups) => (selectedGroups = groups)"
            :disabled="disabled"
            :objectGroups="operatorGroups"
            :groups="groups"
            :multiple="false"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="isDisplay || isUpdate"
            :disabled="disabled"
            :label="$t('changeOperatorPassword')"
            prepend-icon="mdi-alert-outline"
            :type="passwordShown ? 'text' : 'password'"
            @input="password = $event"
            :value="password"
            :append-outer-icon="passwordShown ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="password ? [passwordLength, passwordStrong] : []"
            @click:append-outer="hideShowPassword"
            dense
            :hint="$t('passwordHint')"
            persistent-hint
            autocomplete="new-password"
            id="operator-password-input"
          />
        </v-col>
        <v-col class="pl-6" cols="12">
          <v-row align="center" justify="start">
            <div class="body-1 d-flex mr-2">
              <v-icon class="mr-2"> mdi-account-lock-open </v-icon>
              <span class="mt-2"> {{ $t('operatorPermissions') }}: </span>
            </div>
            <v-row justify="start" align="center" class="mt-5">
              <v-col
                cols="12"
                :md="isSuperAdminUser || isSuperOperatorUser ? 4 : 6"
                class="pt-0 pb-0"
                v-if="isSuperAdminUser || isSuperOperatorUser"
              >
                <v-checkbox
                  class="mt-0"
                  on-icon="mdi-crown"
                  :disabled="disabled"
                  v-model="adminPermissions"
                  :label="$t('adminPermissions')"
                  id="operator-admin-input"
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                :md="isSuperAdminUser || isSuperOperatorUser ? 4 : 6"
                v-if="isNotificacionesActivasEmpresa"
                class="pt-0 pb-0"
              >
                <v-checkbox
                  class="my-checkbox mr-3 mt-0"
                  on-icon="mdi-mailbox"
                  :disabled="disabled"
                  v-model="notificacionesActivas"
                  :label="$t('notifications')"
                  id="operator-notifications-input"
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                :md="isSuperAdminUser || isSuperOperatorUser ? 4 : 6"
                class="pt-0 pb-0"
                v-if="isFirmasActivasEmpresa"
              >
                <v-checkbox
                  class="mr-3 mt-0"
                  on-icon="mdi-draw"
                  :disabled="loading || (!userIsEditing && isDisplay)"
                  x-large
                  v-model="firmasActivas"
                  :label="$t('signatures')"
                  id="operator-signatures-input"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
        <v-col v-if="notificacionesActivas" cols="12">
          <v-autocomplete
            :disabled="disabled"
            v-model="selectedFilters"
            :items="filtersFiltered"
            :filter="customRoleFilter"
            chips
            color="primary"
            :label="$t('selectRole')"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-account-group-outline"
            multiple
            :menu-props="{ bottom: true, offsetY: true }"
            id="operator-filters"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="selectAllFilters">
                <v-list-item-icon class="my-3 mr-2">
                  <v-icon
                    :color="selectedFilters.length > 0 ? 'indigo darken-4' : ''"
                  >
                    {{ iconSelectAllRoles }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title id="filter-select-all">
                    {{ $t('selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider light class="mt-0"></v-divider>
            </template>
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="handleFilterCheckboxClick(data.item)"
                :disabled="loading || (!userIsEditing && isDisplay)"
              >
                {{
                  data.item.isCustom
                    ? data.item.name
                    : $t(`filters.${data.item.name}`)
                }}
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <template>
                <v-list-item-icon class="my-3 mr-0">
                  <v-simple-checkbox
                    :value="selectedFilters.includes(item.id)"
                    :ripple="false"
                    @click="handleFilterCheckboxClick(item)"
                  >
                  </v-simple-checkbox>
                </v-list-item-icon>
                <v-list-item-content :value="selectedFilters.includes(item.id)">
                  <v-list-item-title>
                    {{ item.isCustom ? item.name : $t(`filters.${item.name}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="notificacionesActivas" cols="12">
          <v-divider class="mb-3"></v-divider>
          <div class="body-1 d-flex mr-2">
            <v-icon class="mr-2" large> mdi-mailbox </v-icon>
            <span class="mt-2"> {{ $t('notificationsPermissions') }}: </span>
          </div>
          <v-row justify="start" align="center">
            <v-col cols="6" md="4">
              <v-checkbox
                :disabled="true"
                v-model="readNotification"
                :label="$t('readNotification')"
                id="operator-read-input"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="6" md="4">
              <v-checkbox
                :disabled="disabled"
                v-model="downloadNotification"
                :label="$t('signNotifications')"
                id="operator-sign-notifications"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="6" md="4">
              <v-checkbox
                :disabled="disabled"
                v-model="processNotification"
                :label="$t('processNotifications')"
                id="operator-process-input"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-if="adminPermissions && (isSuperAdminUser || isSuperOperatorUser)"
        >
          <AdminRolesSelector
            @setAdminRoles="(v) => (adminRoles = v)"
            :selectedGroups="selectedGroupsList"
            :userAdminRoles="adminRoles"
            :disabled="disabled"
            :adminPermissions="adminPermissions"
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- Column two where companys are. 
        Only shown when signs or notifications is selected -->
    <v-col cols="12" lg="8" v-if="optionSelected">
      <v-row>
        <v-col cols="12" v-if="firmasOrNotificationsSelected">
          <v-container class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col class="ma-0 pa-0" cols="12">
              <!-- flex-column hace que los elementos se apilen verticalmente en pantallas pequeñas (menos de 600px de ancho, que generalmente son móviles), 
                y flex-sm-row hace que los elementos se alineen horizontalmente en pantallas más grandes.-->
              <div class="body-1 d-flex flex-column flex-sm-row align-center">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-domain</v-icon>
                  {{ $t('companies') }}
                </div>
                <v-spacer></v-spacer>
                <v-checkbox
                  :disabled="disabled"
                  v-model="addNewCifs"
                  :label="$t('addNewCifs')"
                />
              </div>
              <v-expansion-panels
                :disabled="disabled"
                v-model="companyExpansionPanel"
                multiple
                :flat="!companyExpansionPanel.length"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="pa-0 ma-0 max-h-400"
                    expand-icon="mdi-menu-down"
                  >
                    <div
                      v-if="selectedCifs.length"
                      column
                      :style="{
                        'max-height': disabled ? '' : '200px',
                        'overflow-x': 'hidden',
                        'overflow-y': 'scroll',
                      }"
                    >
                      <v-tooltip
                        v-bind:key="cif.id"
                        max-width="400"
                        bottom
                        v-for="cif in selectedCifs"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              (cif.notificacionesActivas &&
                                notificacionesActivas) ||
                              (cif.firmasActivas && firmasActivas)
                            "
                            class="ma-2"
                            :close="!disabled"
                            @click:close="removeCompany(cif)"
                          >
                            {{ shortString(cif.empresa, disabled ? 1000 : 20) }}
                            <v-icon
                              v-if="
                                cif.notificacionesActivas &&
                                notificacionesActivas
                              "
                              @click="
                                toggleCompanyPermissions(
                                  cif,
                                  'notificacionesActivas',
                                  $event
                                )
                              "
                              class="ml-1"
                              color="primary"
                            >
                              mdi-mailbox
                            </v-icon>
                            <v-icon
                              v-if="cif.firmasActivas && firmasActivas"
                              @click="
                                toggleCompanyPermissions(
                                  cif,
                                  'firmasActivas',
                                  $event
                                )
                              "
                              class="ml-1"
                              color="primary"
                            >
                              mdi-draw
                            </v-icon>
                          </v-chip>
                        </template>
                        <div width="300">
                          {{ cif.empresa }}
                        </div>
                      </v-tooltip>
                    </div>
                    <div v-else class="ma-3 pa-2 body-1 d-flex">
                      {{ $t('noSelection') }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content max-height="320">
                    <v-divider class="mb-4"></v-divider>
                    <v-list-item>
                      <v-text-field
                        :disabled="disabled"
                        :label="$t('search')"
                        prepend-icon="mdi-magnify"
                        :value="searchCompany"
                        @input="searchCompany = $event"
                        dense
                        autocomplete="false"
                        id="search-company-input"
                      />
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <template v-slot:default="{}">
                        <v-list-item-action>
                          <v-row>
                            <v-simple-checkbox
                              v-if="notificacionesActivas"
                              @click="selectAllCompanies(true, false)"
                              on-icon="mdi-mailbox"
                              off-icon="mdi-mailbox"
                              color="primary"
                              :ripple="false"
                            >
                            </v-simple-checkbox>
                            <v-simple-checkbox
                              v-if="firmasActivas"
                              @click="selectAllCompanies(false, true)"
                              class="ml-3"
                              on-icon="mdi-draw"
                              off-icon="mdi-draw"
                              color="primary"
                              :ripple="false"
                            >
                            </v-simple-checkbox>
                          </v-row>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            <v-btn
                              @click="selectAllCompanies(true, true)"
                              text
                              id="company-select-all"
                            >
                              {{ $t('selectAll') }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list
                      two-line
                      style="
                        max-height: 360px;
                        overflow-y: auto;
                        overflow-x: hidden;
                      "
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          lg="3"
                          md="6"
                          v-for="company in paginatedCompanies"
                          v-bind:key="company.id"
                        >
                          <v-list-item>
                            <template v-slot:default="{}">
                              <v-list-item-action>
                                <v-row>
                                  <v-simple-checkbox
                                    v-if="notificacionesActivas"
                                    on-icon="mdi-mailbox"
                                    off-icon="mdi-mailbox"
                                    color="primary"
                                    :value="
                                      findCIFinOperator(company)
                                        .notificacionesActivas &&
                                      notificacionesActivas
                                    "
                                    :ripple="false"
                                    @click="
                                      toggleCompanyPermissions(
                                        company,
                                        'notificacionesActivas'
                                      )
                                    "
                                  >
                                  </v-simple-checkbox>
                                  <v-simple-checkbox
                                    v-if="firmasActivas"
                                    class="ml-3"
                                    on-icon="mdi-draw"
                                    off-icon="mdi-draw"
                                    color="primary"
                                    :value="
                                      findCIFinOperator(company)
                                        .firmasActivas && firmasActivas
                                    "
                                    :ripple="false"
                                    @click="
                                      toggleCompanyPermissions(
                                        company,
                                        'firmasActivas'
                                      )
                                    "
                                  >
                                  </v-simple-checkbox>
                                </v-row>
                              </v-list-item-action>

                              <v-list-item-content
                                @click="clickCompany(company)"
                              >
                                <v-list-item-title>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span v-bind="attrs" v-on="on">
                                        {{ shortString(company.empresa, 20) }}
                                      </span>
                                    </template>
                                    <div
                                      style="
                                        white-space: pre-line;
                                        max-width: 400px;
                                      "
                                    >
                                      {{ company.empresa }}
                                    </div>
                                  </v-tooltip>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ company.numeroDocIdentidad }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list>
                    <v-divider />
                    <PaginateBar
                      :initialPageSize="16"
                      :pageSizes="[16, 64, 128]"
                      :search="searchCompany"
                      :items="companiesFiltered"
                      @setPage="(value) => (page = value)"
                      @setPageSize="(value) => (pageSize = value)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-container>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters } from 'vuex'
import { mapFilters } from '@/services/filter-service'
import GroupAutocomplete from '@/components/groups/GroupAutocomplete'
import PaginateBar from '@/components/commons/PaginateBar'
import AdminRolesSelector from '@/components/operators/dialog/AdminRolesSelector'
export default {
  components: {
    GroupAutocomplete,
    PaginateBar,
    AdminRolesSelector,
  },
  props: {
    userIsEditing: Boolean,
    isCreate: Boolean,
    isDisplay: Boolean,
    isUpdate: Boolean,
    operator: Object,
    operators: Array,
    filters: Array,
    companies: Array,
    groupsMap: Object,
    groups: Array,
    cifsMap: Object,
  },
  async mounted() {
    // Getting the id of the "no filter"
    this.noFilterId = this.filters.find(
      (filter) => filter.name === 'noFilter'
    ).id

    if (this.operator.id) {
      this.adminRoles = this.operator.adminRoles ?? {}
      this.adminPermissions = this.operator.adminPermissions
      this.firmasActivas = this.operator.firmasActivas
      this.notificacionesActivas = this.operator.notificacionesActivas
      this.operatorEmail = this.operator.email
      this.name = this.operator.name
      this.surname = this.operator.surname
      this.emailAlerts = this.operator.emailAlerts
      this.cifs = this.operator.cifs
      this.addNewCifs = this.operator.addNewCifs
      this.downloadNotification = this.operator.downloadNotification
      this.processNotification = this.operator.processNotification

      // Adding the selected cifs of the operator
      this.operator.cifsRef?.forEach((cifRef) => {
        const cif = this.cifsMap[cifRef.id]
        this.selectedCifs.push({
          ...cif,
          ...this.operator.cifsPermissions[cif.numeroDocIdentidad],
        })
      })

      // Adding the selected filters of the operator
      this.selectedFilters = this.operator?.filtersRef?.map(
        (ref) => this.filtersMap[ref.id].id
      )

      // Adding the groups
      this.selectedGroups = this.operatorGroups
    }

    // Setting the initial values of the operator
    this.$emit('setOperatorData', 'adminRoles', this.adminRoles)
    this.$emit('setOperatorData', 'adminPermissions', this.adminPermissions)
    this.$emit('setOperatorData', 'firmasActivas', this.firmasActivas)
    this.$emit(
      'setOperatorData',
      'notificacionesActivas',
      this.notificacionesActivas
    )
    this.$emit('setOperatorData', 'email', this.operatorEmail)
    this.$emit('setOperatorData', 'name', this.name)
    this.$emit('setOperatorData', 'surname', this.surname)
    this.$emit('setOperatorData', 'password', this.password)
    this.$emit('setOperatorData', 'emailAlerts', this.emailAlerts)
    this.$emit('setOperatorData', 'addNewCifs', this.addNewCifs)
    this.$emit('setOperatorData', 'readNotification', this.readNotification)
    this.$emit(
      'setOperatorData',
      'downloadNotification',
      this.downloadNotification
    )
    this.$emit(
      'setOperatorData',
      'processNotification',
      this.processNotification
    )

    this.$emit(
      'setOperatorData',
      'groupsIds',
      this.selectedGroups?.length > 0 ? this.selectedGroups : []
    )

    this.setOperatorCifs(this.cifs)
    this.setOperatorFilters(this.selectedFilters)

    this.$emit('setActivas', {
      firmasActivas: this.firmasActivas,
      notificacionesActivas: this.notificacionesActivas,
    })
  },
  data() {
    return {
      // Form data
      passwordShown: false,
      validOperatorForm: false,
      loading: false,
      companyExpansionPanel: this.isDisplay ? [] : [0],
      filtersMap: mapFilters(this.filters),
      noFilterId: '',
      searchCompany: '',
      page: 0,
      pageSize: 0,

      // Operator data
      adminPermissions: false,
      firmasActivas: false,
      notificacionesActivas: false,
      operatorEmail: undefined,
      name: undefined,
      surname: undefined,
      password: undefined,
      emailAlerts: true,
      cifs: undefined,
      addNewCifs: false,
      readNotification: true,
      downloadNotification: false,
      processNotification: false,
      selectedCifs: [],
      selectedFilters: [],
      selectedGroups: undefined,
      adminRoles: {},
    }
  },
  methods: {
    ...rules,
    hideShowPassword() {
      this.passwordShown = !this.passwordShown
    },
    /**
     * Busca un cif por su id (firebase ref) en la lista de cifsRef del operador a modificar. Se utiliza para
     * mostrar activados o no activados los checkboxes del selector de permisos de los cifs totales de la empresa.
     * Es decir por cada empresa del usuario admin se busca si está presente en los cifsRef de un operador. Si está presente
     * se devuelve el objeto (incluyendo dentro sus flags firmas y notificaciones)
     * @param {object} cif el cif (de companies) a buscar en cifsRef
     * @return  {object} El elemento encontrado en cifsRef o un objeto con los flags a false en caso de no encontrarlo
     */
    findCIFinOperator(cif) {
      var cifFindResult = this.selectedCifs.find((sCif) => sCif.id === cif.id)
      return (
        cifFindResult ?? { notificacionesActivas: false, firmasActivas: false }
      )
    },
    /**
     * Modifica el valor de un flag de una entrada en cifsRef
     * @param {object} cif el cif cuyo flag se va a modificar
     * @param {object} key el flag que se va a modificar
     * @param {object} event El evento click que se va a parar para que no se propague
     */
    toggleCompanyPermissions(cif, key, event) {
      if (event) {
        event.stopPropagation()
      }

      const cifIndex = this.selectedCifs.findIndex((sCif) => sCif.id == cif.id)
      if (cifIndex < 0) {
        //si no existe el cif en la lista se añade poniendo el flag correspondiente a true y los demas a false
        this.selectedCifs.push({
          ...cif,
          firmasActivas: key === 'firmasActivas',
          notificacionesActivas: key === 'notificacionesActivas',
          representacionActivas: key === 'representacionActivas',
        })
      } else {
        const cif = this.selectedCifs[cifIndex]
        cif[key] = !cif[key]

        if (
          //si está todo a false se elimina el cif
          !cif.firmasActivas &&
          !cif.notificacionesActivas &&
          !cif.representacionActivas
        )
          this.selectedCifs.splice(cifIndex, 1)
      }
    },
    /**
     * Modifica el valor de un flag de una entrada en cifsRef
     * @param {object} cif el cif cuyo flag se va a modificar
     * @param {object} event El evento click que se va a parar para que no se propague
     */
    clickCompany(cif, event) {
      if (event) {
        event.stopPropagation()
      }

      const cifIndex = this.selectedCifs.findIndex((sCif) => sCif.id == cif.id)
      if (cifIndex < 0) {
        //si no existe el cif en la lista se añade poniendo el flag correspondiente a true y los demas a false
        this.selectedCifs.push({
          ...cif,
          firmasActivas: this.firmasActivas,
          notificacionesActivas: this.notificacionesActivas,
          representacionActivas: this.representacionActivas,
        })
      } else this.selectedCifs.splice(cifIndex, 1)
    },
    /**
     * Removes the cif from the list
     * @param {Object} cif Cif to be removed.
     */
    removeCompany(cif) {
      const cifIndex = this.selectedCifs.findIndex((sCif) => sCif.id == cif.id)
      this.selectedCifs.splice(cifIndex, 1)
    },
    /**
     * Funcion que habilita en el operador el flag seleccionado para todos los cifs.
     * Si ya estan habilitados todos los cifs (en al menos un flag) se desmarcan todos y queda la lista vacia
     * @param {string} notificacionesActivasFlag Si se desea activar el flag notificaciones
     * @param {string} firmasActivasFlag Si se desea activar el flag firmas
     */
    selectAllCompanies(notificacionesActivasFlag, firmasActivasFlag) {
      if (this.companiesFiltered.length === this.selectedCifs.length)
        this.selectedCifs = []
      else {
        this.selectedCifs = this.companiesFiltered.map((cif) => {
          const selectedCif = this.findCIFinOperator(cif)
          return {
            ...cif,
            notificacionesActivas:
              (notificacionesActivasFlag ||
                selectedCif.notificacionesActivas) &&
              this.notificacionesActivas,
            firmasActivas:
              (firmasActivasFlag || selectedCif.firmasActivas) &&
              this.firmasActivas,
            representacionActivas: false,
          }
        })
      }
    },
    customRoleFilter(item, queryText) {
      const name = item.isCustom
        ? item.name.toLowerCase()
        : this.$t(`filters.${item.name}`).toLocaleLowerCase()
      const searchText = queryText.toLowerCase()

      return name.includes(searchText)
    },
    /**
     * Function that selects all the filters
     */
    selectAllFilters() {
      if (this.selectedFilters.length === this.filtersFiltered.length)
        this.selectedFilters = []
      else
        this.selectedFilters = this.filtersFiltered
          .map((filter) => filter.id)
          .filter((filterId) => filterId !== this.noFilterId)
    },
    /**
     * When a checkbox is clicked in the filters autocomplete the filter
     * is either added or removed depending on if it is already there.
     * @param {Object} filter Filter to add.
     */
    handleFilterCheckboxClick(filter) {
      const index = this.selectedFilters.findIndex(
        (filterId) => filterId === filter.id
      )
      if (index > -1) this.selectedFilters.splice(index, 1)
      else this.selectedFilters.push(filter.id)
    },
    setOperatorCifs(cifs = []) {
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id

      const cifsPermissions = {}

      this.$emit(
        'setOperatorData',
        'cifsPath',
        cifs.map((cif) => {
          // Additional action, building the cifsPermissions while iterating over the cifs
          cifsPermissions[cif.numeroDocIdentidad] = {
            firmasActivas: !!cif.firmasActivas,
            representacionActivas: !!cif.representacionActivas,
            notificacionesActivas: !!cif.notificacionesActivas,
          }

          return `users/${userId}/cifs/${cif.id}`
        })
      )

      // Building the cifs permission
      this.$emit('setOperatorData', 'cifsPermissions', cifsPermissions)
    },
    setOperatorFilters(filters = []) {
      const userId = this.isOperatorUser
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id

      const filtersPath = filters.map((filterId) =>
        this.filtersMap[filterId].isCustom
          ? `users/${userId}/filters/${filterId}`
          : `filters/${filterId}`
      )

      this.$emit('setOperatorData', 'filtersPath', filtersPath)
    },
    /**
     * Shortens a string.
     * @param {String} str String to shorten.
     * @param {Number?} length Number of characters that trigger the shorten.
     */
    shortString(str = '', length = 13) {
      return str.length > length ? str.substring(0, length - 4) + '...' : str
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isAdminUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isOperatorUser',
      'isInGroupUser',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
    ]),
    disabled() {
      return this.loading || (!this.userIsEditing && this.isDisplay)
    },
    firmasOrNotificationsSelected() {
      return this.firmasActivas || this.notificacionesActivas
    },
    optionSelected() {
      return (
        this.firmasActivas ||
        this.notificacionesActivas ||
        this.adminPermissions
      )
    },
    newPasswordWritten() {
      return this.password !== ''
    },
    passwordRules() {
      return this.newPasswordWritten ? [] : []
    },
    paginatedCompanies() {
      return this.companiesFiltered.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      )
    },
    companiesFiltered() {
      let result = []

      // If a group is selected we return the cifs of the group
      if (this.selectedGroups?.length > 0)
        result = this.groupsMap[this.selectedGroups].cifsRef.map(
          ({ id }) => this.cifsMap[id]
        )
      // Otherwise, we return the companies variable list that will be:
      // All CIFs for Superadmin or visible CIFs for admin operators.
      // (visible for admin operators will be group cifs or all CIFs if not in a group)
      else result = this.companies

      return result
        .filter(
          (cif) =>
            cif.empresa
              .toLocaleLowerCase()
              .includes(this.searchCompany.toLocaleLowerCase()) ||
            cif.numeroDocIdentidad
              .toLocaleLowerCase()
              .includes(this.searchCompany.toLocaleLowerCase())
        )
        .sort((a, b) => a.empresa.localeCompare(b.empresa))
    },
    filtersFiltered() {
      // If no filter is selected we don't return filters
      if (this.selectedFilters.some((id) => this.noFilterId === id))
        return [this.filters.find((filter) => filter.id === this.noFilterId)]

      if (this.selectedGroups?.length > 0)
        // If a group is selected we return the c of the group
        return this.groupsMap[this.selectedGroups].filtersRef.map(
          ({ id }) => this.filtersMap[id]
        )

      // If I am an operator I return the filters of the operator's group
      if (
        !this.isSuperAdminUser &&
        !this.isSuperOperatorUser &&
        this.isInGroupUser
      )
        return this.groupsMap[
          this.$store.state.user.groupsRef[0].id
        ].filtersRef.map(({ id }) => this.filtersMap[id])

      // If I am a super admin I return all the filters
      return this.filters
    },
    iconSelectAllRoles() {
      if (this.selectedFilters.length === this.filtersFiltered.length)
        return 'mdi-close-box'
      if (this.selectedFilters.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    operatorGroups() {
      return this.operator.groupsRef?.map((ref) => ref.id) ?? []
    },
    operatorsNames() {
      return this.operators.map((operator) => operator.name)
    },
    operatorsEmails() {
      return this.operators.map((operator) => operator.email)
    },
    selectedGroupsList() {
      if (!this.selectedGroups) return []
      return typeof this.selectedGroups === 'object'
        ? this.selectedGroups
        : [this.selectedGroups]
    },
  },
  watch: {
    firmasActivas: function (newValue) {
      this.$emit('setActivas', {
        firmasActivas: newValue,
        adminPermissions: this.adminPermissions,
        notificacionesActivas: this.notificacionesActivas,
      })
      this.$emit('setOperatorData', 'firmasActivas', newValue)
    },
    notificacionesActivas: function (newValue) {
      this.$emit('setActivas', {
        firmasActivas: this.firmasActivas,
        adminPermissions: this.adminPermissions,
        notificacionesActivas: newValue,
      })
      this.$emit('setOperatorData', 'notificacionesActivas', newValue)

      if (!newValue) {
        this.selectedFilters = []
        this.selectedCifs = []
        this.processNotification = false
        this.downloadNotification = false
      }
    },
    adminRoles: function (newValue) {
      this.$emit('setOperatorData', 'adminRoles', newValue)
    },
    adminPermissions: function (newValue) {
      this.$emit('setActivas', {
        firmasActivas: this.firmasActivas,
        notificacionesActivas: this.notificacionesActivas,
        adminPermissions: newValue,
      })
      this.$emit('setOperatorData', 'adminPermissions', newValue)

      if (!newValue)
        for (const key in this.adminRoles) this.adminRoles[key] = false
    },
    operatorEmail: function (newValue) {
      this.$emit('setOperatorData', 'email', newValue)
    },
    name: function (newValue) {
      this.$emit('setOperatorData', 'name', newValue)
    },
    surname: function (newValue) {
      this.$emit('setOperatorData', 'surname', newValue)
    },
    password: function (newValue) {
      this.$emit('setOperatorData', 'password', newValue)
    },
    emailAlerts: function (newValue) {
      this.$emit('setOperatorData', 'emailAlerts', newValue)
    },
    cifs: function (newValue) {
      this.$emit('setOperatorData', 'cifs', newValue)
    },
    addNewCifs: function (newValue) {
      this.$emit('setOperatorData', 'addNewCifs', newValue)
    },
    readNotification: function (newValue) {
      this.$emit('setOperatorData', 'readNotification', newValue)
    },
    downloadNotification: function (newValue) {
      this.$emit('setOperatorData', 'downloadNotification', newValue)
    },
    processNotification: function (newValue) {
      this.$emit('setOperatorData', 'processNotification', newValue)
    },
    selectedCifs: {
      deep: true,
      handler: function (newValue) {
        this.setOperatorCifs(newValue)
      },
    },
    selectedFilters: {
      deep: true,
      handler(newValue, previousValue) {
        // Si se selecciona el filtro "noFilter"
        if (
          newValue?.some((filterId) => filterId === this.noFilterId) &&
          !previousValue?.some((filterId) => filterId === this.noFilterId)
        )
          this.selectedFilters = [this.noFilterId]
        else this.setOperatorFilters(newValue)
      },
    },
    selectedGroups: function (newValue, previousValue) {
      if (!newValue) return this.$emit('setOperatorData', 'groupsIds', [])

      let id
      if (typeof newValue === 'object') {
        if (newValue.length > 0) id = newValue[0]
        else id = ''
      } else id = newValue

      this.$emit('setOperatorData', 'groupsIds', id ? [id] : [])

      if (previousValue) {
        this.selectedFilters = []
        this.selectedCifs = []
      }
    },
    userIsEditing: function (newValue) {
      this.companyExpansionPanel = newValue ? [0] : []
    },
  },
}
</script>
