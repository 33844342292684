import i18n from '@/i18n'
import { HelpLogFunction } from '@/firebase-exports'

export default function (msg) {
  var error
  if (msg.includes('Not signed in')) {
    error = i18n.t('unathenticatedError')
  } else if (msg.includes('Not granted')) {
    error = i18n.t('permissionDeniedError')
  } else if (msg.includes('Some required field not present')) {
    error = i18n.t('missingRequiredFieldsError')
  } else if (msg.includes('Not a valid CIF/NIF')) {
    error = i18n.t('notValidTaxNumberError')
  } else if (msg.includes('Other user exists for that CIF/NIF')) {
    error = i18n.t('inUseTaxNumberError')
  } else if (msg.includes('Other user exists for that ID')) {
    error = i18n.t('inUseIdNumberError')
  } else if (
    msg.includes('The email address is already in use by another account')
  ) {
    error = i18n.t('inUseEmailError')
  } else if (msg.includes('The email address is improperly formatted')) {
    error = i18n.t('invalidEmailError')
  } else if (msg.includes('invalid-email')) {
    error = i18n.t('invalidEmail')
  } else if (msg.includes('user-not-found') || msg.includes('wrong-password')) {
    error = i18n.t('errorLogin')
  } else if (msg.includes('user-disabled')) {
    error = i18n.t('userDisabled')
  } else if (msg.includes('too-many-requests')) {
    error = i18n.t('tooManyRequests')
  } else if (msg.includes('invalid-verification-code')) {
    error = i18n.t('verifying2FAError')
  } else if (msg.includes('Error interno:')) {
    error = msg.substring(30) // 15 for 'FirebaseError: ' and 15 for 'Error interno: '
    error = parseInt(error.slice(0, 4))
      ? error.includes('4209') ||
        error.includes('4210') ||
        error.includes('4307')
        ? i18n.t('alreadyAccessedError') // Error DEHU de ya comparecido
        : error.substring(4) // Error DEHU (diferente a ya comparecido)
      : error.includes('Invalid JSON filter')
      ? i18n.t('invalidJSONFilter') // Error en la consulta del fichero en el DMS
      : i18n.t('genericError') // Cualquier otro error
  } else if (msg.includes('invalid-pin')) {
    error = i18n.t('invalidPin')
  } else if (msg.includes('invalid-plan-cifs')) {
    error = i18n.t('invalidPlanCifs')
  } else if (msg.includes('invalid-plan-certs')) {
    error = i18n.t('invalidPlanCerts')
  } else if (msg.includes('invalid-certificate')) {
    error = i18n.t('invalidCertificate')
  } else if (msg.includes('certificate-expired')) {
    error = i18n.t('certificateExpired')
  } else if (msg.includes('certificate-invalid-id')) {
    error = i18n.t('certificateInvalidId')
  } else if (msg.includes('certificate-alternative-existing')) {
    error = i18n.t('certificateAlternativeExisting')
  } else if (msg.includes('certificate-alternative-different')) {
    error = i18n.t('certificateAlternativeDifferent')
  } else if (msg.includes('no-documents-to-send')) {
    error = i18n.t('noDocumentsToSend')
  } else if (msg.includes('502-error')) {
    error = i18n.t('serviceNotAvailable')
  } else if (msg.includes('client-not-valid')) {
    error = i18n.t('clientNotValid')
  } else if (msg.includes('exportCifStillPending')) {
    error = i18n.t('exportCifStillPending')
  } else if (msg.includes('exportBatchStillPending')) {
    error = i18n.t('exportBatchStillPending')
  } else {
    error = i18n.t('genericError')
  }

  if (error == i18n.t('genericError')) {
    console.log(msg)
    HelpLogFunction({ log: msg }).catch((e) => void e) // Catch to ignore errors in front
  }

  return error
}
