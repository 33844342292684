<template>
  <v-navigation-drawer
    :value="navigationDrawer"
    app
    :width="isMobile ? '100%' : '300'"
    clipped
    right
    mobile-breakpoint="0"
    color="primary"
    touchless
  >
    <v-list>
      <v-list-item two-line>
        <v-list-item-icon v-if="$store.state.user" class="mr-4 my-4">
          <AppAvatar
            :userName="`${$store.state.user.name} ${$store.state.user.surname}`"
            avatarSize="40"
            iconSize="24"
            overlapAvatarColor="secondary"
            overlapIconColor="primary"
          />
        </v-list-item-icon>
        <v-list-item-content v-if="$store.state.user">
          <v-list-item-title class="title secondary--text">
            {{ `${$store.state.user.name} ${$store.state.user.surname}` }}
          </v-list-item-title>
          <v-list-item-subtitle class="secondary--text">
            {{ $store.state.user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <template v-for="item in $store.state.navigationPages">
        <v-fade-transition mode="out-in" :key="item.title">
          <v-list-group
            :group="item.path"
            v-if="!!item.subPages && item.available"
            v-model="item.active"
            :key="item.title"
            no-action
            color="black"
          >
            <AppNavigationDrawerElement
              slot="activator"
              :key="item.title"
              :item="item"
              class="pa-0 secondary--text"
            />
            <AppNavigationDrawerElement
              v-for="subItem in item.subPages"
              :key="subItem.title"
              :item="subItem"
            />
          </v-list-group>
        </v-fade-transition>
        <v-fade-transition mode="out-in" :key="item.title">
          <AppNavigationDrawerElement
            v-if="!item.subPages && item.available"
            :key="item.title"
            :item="item"
          />
        </v-fade-transition>
      </template>
      <v-divider></v-divider>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          block
          outlined
          color="secondary"
          @click="$i18n.locale = $i18n.locale === 'es' ? 'en' : 'es'"
        >
          <v-icon>mdi-translate-variant</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $i18n.locale === 'es' ? 'en' : 'es' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-btn>
      </div>
      <div class="pa-2">
        <v-btn id="logout" block outlined color="secondary" @click="signOut">
          <v-icon>mdi-logout</v-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t('signOut') }} </v-list-item-title>
          </v-list-item-content>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import AppNavigationDrawerElement from '@/components/app-navigation-drawer/AppNavigationDrawerElement'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    AppNavigationDrawerElement,
  },
  methods: {
    ...mapMutations(['setNavigationDrawer']),
    ...mapActions(['logout']),
    async signOut() {
      this.setNavigationDrawer(false)
      await this.logout()
      this.$router.push({ name: 'LoginView' })
    },
  },
  computed: {
    ...mapState(['navigationDrawer']),
    ...mapGetters(['isMobile']),
  },
}
</script>
