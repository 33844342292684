<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.title') }}
    </h1>
    <h2 class="primary--text mt-3">
      {{ $t('documentation.texts.automatedTasks.creation.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p1') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p2') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic2Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p3') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic3Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p4') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p5') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic4Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p6') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p7') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic5Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p8') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic6Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p9') }}
    </p>
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p10') }}
    </p>
    <v-img
      style="
        width: 100%;
        max-width: 800px;
        align-self: center;
        border-radius: 10px;
      "
      contain
      class="mb-5"
      :src="pic7Name"
    />
    <p>
      {{ $t('documentation.texts.automatedTasks.creation.p11') }}
    </p>
    <ul>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle0') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li0') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle1') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li1') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle2') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li2') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle3') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li3') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle4') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li4') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle5') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li5') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle6') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li6') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle7') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li7') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle8') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li8') }}
      </li>
      <li>
        - <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle9') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li9') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle10') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li10') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle11') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li11') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle12') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li12') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle13') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li13') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle14') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li14') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle15') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li15') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle16') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li16') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle17') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li17') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle18') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li18') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle19') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li19') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle20') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li20') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle21') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li21') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle22') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li22') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle23') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li23') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle24') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li24') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle25') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li25') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle26') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li26') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle27') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li27') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle28') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li28') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle29') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li29') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle30') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li30') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle31') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li31') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle32') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li32') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle33') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li33') }}
      </li>
      <li>
        -
        <b>{{
          $t('documentation.texts.automatedTasks.creation.liTitle33-2')
        }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li33-2') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle34') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li34') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle35') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li35') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle36') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li36') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle37') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li37') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle38') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li38') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle39') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li39') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle40') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li40') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle41') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li41') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle42') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li42') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle43') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li43') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle44') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li44') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle45') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li45') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle46') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li46') }}
      </li>
      <li>
        -
        <b>{{ $t('documentation.texts.automatedTasks.creation.liTitle47') }}</b
        >: {{ $t('documentation.texts.automatedTasks.creation.li47') }}
      </li>
    </ul>
    <div class="mb-10" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic5') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic6') +
        '.png')
    },
    pic7Name() {
      return require('@/assets/documentation/automatedTasks/' +
        this.$t('documentation.texts.automatedTasks.creation.pic7') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
