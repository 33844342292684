<template>
  <v-card
    :class="isMobile() ? 'pa-1' : 'mx-auto pa-5'"
    :min-height="isMobile() ? 220 : 320"
    :ripple="false"
    tile
  >
    <AppSkeleton
      data-v-step="105"
      :condition="loadingTableData"
      max-width="300"
      type="card"
    >
      <template v-slot:skeleton-content>
        <v-container fill-height fluid style="min-height: 320px">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <div
                align="center"
                :class="
                  'font-weight-bold ' + (isMobile() ? 'body-2' : 'headline')
                "
              >
                {{ $t(`filters.${filter.name}`) }}
                <DialogButton section="filters" :hasPage="false" />
              </div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-img
              max-height="150"
              max-width="250"
              width="100%"
              :src="filter.logo"
            ></v-img>
          </v-row>
          <v-row align="center">
            <v-expansion-panels flat accordion>
              <v-expansion-panel v-if="filter.sourceEntityAllowed.length > 0">
                <v-expansion-panel-header class="custom-button" expand-icon="">
                  <span align="center" :class="isMobile() ? 'body-2' : ''">
                    {{ $t('enabledEntities') }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span>
                    {{ filter.sourceEntityAllowed.join(', ') }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="filter.administrationLevelAllowed.length > 0"
              >
                <v-expansion-panel-header class="custom-button" expand-icon="">
                  <span align="center" :class="isMobile() ? 'body-2' : ''">
                    {{ $t('administrationLevelAllowed') }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span>
                    {{ filter.administrationLevelAllowed.join(', ') }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="filter.autonomicAllowed.length > 0">
                <v-expansion-panel-header expand-icon="">
                  <v-btn plain block>
                    <span align="center">
                      {{ $t('autonomicAllowed') }}
                    </span>
                  </v-btn>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span>
                    {{ filter.autonomicAllowed.join(', ') }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row align="center"> </v-row>
        </v-container>
      </template>
    </AppSkeleton>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'

export default {
  props: {
    filter: Object,
    loadingTableData: Boolean,
  },
  components: {
    DialogButton,
  },
  data() {
    return {}
  },
  methods: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style>
.custom-button {
  border-radius: 5px;
  height: 20px;
  max-height: 20px;
  transition: 0.3s;
  color: rgb(104, 104, 104);
  font-size: 19px;
  font-weight: 400;
}
.custom-button:active {
  scale: 0.97;
}
</style>
