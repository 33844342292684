<template>
  <div id="licenserenewed">
    <v-dialog
      v-if="isLicenseJustRenewed"
      v-model="show"
      persistent
      width="700"
      max-width="700"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('licenseRenewedDialog.title') }}
          <v-icon large class="ml-2 mb-1" color="green">
            mdi-check-circle
          </v-icon>
        </v-card-title>

        <v-card-text
          v-if="!containsHTML('licenseRenewedDialog.message')"
          class="mt-6 text-h5"
        >
          {{
            $t('licenseRenewedDialog.message', {
              expirationDate: expirationDate,
            })
          }}
        </v-card-text>
        <v-card-text
          v-else
          class="mt-6 text-h5"
          v-html="
            $t('licenseRenewedDialog.message', {
              expirationDate: expirationDate,
            })
          "
        >
        </v-card-text>

        <v-card-actions class="mt-4 justify-start">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog(1)">
            {{ $t('licenseRenewedDialog.close') }}
          </v-btn>

          <v-btn
            :disabled="loading"
            color="primary"
            text
            @click="closeDialog(2)"
          >
            {{ $t('licenseRenewedDialog.dontShowAgain') }}
            <template v-slot:loader>
              <v-progress-circular indeterminate size="20" width="2" />
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Firestore } from '@/firebase-exports'
import { updateUserInfo } from '@/services/user-service'
import { getExpirationDate, formatDate } from '@/utils/license-utils'

export default {
  props: {},
  data() {
    return {
      show: false, // Inicialmente el diálogo está cerrado
      loading: false,
      expirationDate: '',
    }
  },
  mounted() {
    // Abrimos el diálogo cuando el componente se monta
    this.show = true
    this.expirationDate = formatDate(getExpirationDate(this.$store.state.user))
  },
  computed: {
    ...mapGetters(['isLicenseJustRenewed']),
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async closeDialog(option) {
      try {
        this.loading = true
        if (option == 2) {
          // eliminar el atributo una vez renovada la licencia e informado al admin, que se da por enterado.
          await updateUserInfo(this.$store.state.user.id, {
            estadoRenovacionLicencia: Firestore.deleteField(),
          })
        }
        this.loading = false
        this.show = false
      } catch (error) {
        this.loading = false
        this.show = false
        const message = this.$t('licenseRenewedDialog.updateError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    containsHTML(messageKey) {
      const message = this.$t(messageKey)
      return message.includes('<span') // verifica si el mensaje contiene código HTML.
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .highlight {
  font-weight: bold;
  /*color: green;*/
  /*font-size: 1.2em;*/
}
</style>
