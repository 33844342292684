import {
  ExecuteMassiveAction,
  ExportDmsNotifications,
} from '../firebase-exports'

/**
 * Sets the status of several notifications.
 * @param {String} userId Id of the user.
 * @param {Array} items Ids of the notifications to update.
 * @param {String} status Status to set.
 */
export const massiveActionChangeStatus = async (userId, items, status) => {
  await ExecuteMassiveAction({
    userId,
    items,
    status,
    action: 'changeStatus',
  })
}

/**
 * Opens several notifications at once.
 * @param {String} userId Id of the user.
 * @param {Array} items Ids of the notifications to update.
 */
export const massiveOpenNotification = async (userId, items) => {
  await ExecuteMassiveAction({
    userId,
    items,
    action: 'openNotification',
  })
}

/**
 * Sets a responsible to several notifications.
 * @param {String} userId Id of the user with the notifications.
 * @param {String} operatorId Id of the operator to set.
 * @param {Array} items Ids of the notifications to set as responsible.
 */
export const massiveSetResponsible = async (userId, operatorId, items) => {
  await ExecuteMassiveAction({
    userId,
    operatorId,
    items,
    action: 'setResponsible',
  })
}

/**
 * Sets an alert to several notifications.
 * @param {String} userId Id of the user with the notifications.
 * @param {Object} alert Alert object.
 * @param {Array} items Ids of the notifications to set an alert.
 */
export const massiveSetAlert = async (userId, alert, items) => {
  await ExecuteMassiveAction({
    userId,
    alert,
    items,
    action: 'setAlert',
  })
}

/**
 * Sets an observation to several notifications.
 * @param {String} userId Id of the user with the notifications.
 * @param {String} observation Observation to stablish.
 * @param {Array} items Ids of the notifications to add an observation.
 */
export const massiveSetObservation = async (userId, observation, items) => {
  await ExecuteMassiveAction({
    userId,
    observation,
    items,
    action: 'setObservation',
  })
}

/**
 * Downloads the files of all the notifications.
 * @param {Array} notifications Ids of the notifications to download.
 */
export const massiveDownloadFiles = async (notifications) => {
  await ExportDmsNotifications({
    notifications,
  })
}
