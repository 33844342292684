<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="6">
        <CreateSignatureRequest
          v-if="companies"
          :operators="operators"
          :companies="companies"
          :signaturesMax="signaturesMax"
          :signaturesUsed="signaturesUsed"
          :loadingTableData="loadingTableData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateSignatureRequest from '@/components/signatures/CreateSignatureRequest'
import { getCifs } from '@/services/user-service'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateSignatureRequest,
  },
  data() {
    return {
      operators: [],
      companies: [],
      loadingTableData: true,
    }
  },
  async mounted() {
    // await new Promise((resolve) => setTimeout(resolve, 300))
    this.loadingTableData = false
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id
    const cifs = await getCifs(
      userId,
      this.isOperatorUser ? this.$store.state.user : false
    )
    this.companies = this.isOperatorUser
      ? cifs.filter((cif) => {
          return (
            cif.firmasActivas &&
            this.$store.state.user.cifsPermissions[cif.numeroDocIdentidad]
              .firmasActivas &&
            !cif.deletionDate
          )
        })
      : cifs.filter((cif) => {
          return cif.firmasActivas && !cif.deletionDate
        })
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    signaturesUsed() {
      return this.$store.state.plan?.signaturesUsed
    },
    signaturesMax() {
      return this.$store.state.plan?.signaturesMax
    },
  },
}
</script>
