const state = {
  notFound: false,
  navigationDrawer: false,
  snackbar: {},
  dialog: {},
  progress: [],
  brand: null,
  filters: null,
  manageAutomatedTasks: null,
  manageAutomatedTasksUnsubscribe: () => {},
  manageCompanies: null,
  manageCompaniesUnsubscribe: () => {},
  manageOperators: null,
  manageOperatorsUnsubscribe: () => {},
  user: null,
  plan: null,
  planUnsubscribe: () => {},
  loading: false,
  maintenanceMode: false,
  navigationPages: {},
  news: [],
  newsUnsubscribe: () => {},
  notifications: [],
  notificationsUnsubscribe: () => {},
  signatures: [],
  signaturesUnsubscribe: () => {},
  notificationsListOptions: {},
  notificationsSearch: '',
  signaturesListOptions: {},
  signaturesSearch: '',
  localAppVersion: process.env.VUE_APP_VERSION,
  remoteAppVersion: '',
  contractModel: '',
  arrayListener: [],
  pending: {},
}

export default state
