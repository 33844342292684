<template>
  <div>
    <h3>{{ `${operator.name} ${operator.surname}` }}</h3>
    <h3>{{ operator.email }}</h3>
  </div>
</template>
<script>
export default {
  props: {
    operator: Object,
  },
}
</script>
