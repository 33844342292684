<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.signatures.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.signatures.signatureIncidence.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.signatures.signatureIncidence.paragraph1') }}
    </p>
    <p>
      {{ $t('documentation.texts.signatures.signatureIncidence.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="picPopUp" />
    <p>
      {{ $t('documentation.texts.signatures.signatureIncidence.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="picDashboard" />
    <p>
      {{ $t('documentation.texts.signatures.signatureIncidence.paragraph4') }}
    </p>
    <br />
    <br />
  </v-col>
</template>

<script>
export default {
  computed: {
    picPopUp() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureIncidence.picIncidencePopUp'
        ) +
        '.png')
    },
    picDashboard() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureIncidence.picIncidenceDashboard'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
