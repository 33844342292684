import { Firestore, UsersDoc } from '@/firebase-exports'

/**
 * Crea una subscripción al usuario (en principio, el parentRef de un operador),
 * actualizando el estado en la tienda cada vez que hay un cambio.
 *
 * @param {string} userId - El ID del usuario que se está suscribiendo.
 * @param {Object} context - El objeto de contexto donde se almacenará la información del usuario.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function parentuserSubscription(userId, context) {
  // Comprobamos que el usuario existe en la base de datos
  const docSnap = await Firestore.getDoc(UsersDoc(userId))
  // Verificar si docSnap es válido antes de acceder a sus propiedades.
  if (!docSnap.exists() || !docSnap.data()) {
    context.parentUser = { disabled: false }
    return
  }
  // Establece una suscripción a los cambios en el documento del usuario identificado por userId en Firestore.
  const parentuserUnsubscribe = Firestore.onSnapshot(
    UsersDoc(userId),
    async (docSnap) => {
      // Verificar si docSnap es válido antes de acceder a sus propiedades.
      if (!docSnap.exists() || !docSnap.data()) {
        context.parentUser = { disabled: false }
        return
      }

      // Obtiene los datos del documento del usuario.
      const document = docSnap.data()

      // NO me interesan los datos del usuario parentRef de un operador excepto el atributo disabled
      // el resto lo desestimo, si no habria que ir eliminando atributos sensibles, tales como passwords, etc.
      let parentUser = { disabled: document.disabled }
      context.parentUser = parentUser
    }
  )

  return parentuserUnsubscribe
}
