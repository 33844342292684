<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.dashboard.assignments.title') }}
    </h1>
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph1') }}</p>
    <h2 class="primary--text">
      {{ $t('documentation.texts.dashboard.assignments.title1') }}
    </h2>
    <v-img
      style="
        width: 100%;
        max-width: 600px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph2') }}</p>
    <ul>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.assignments.li11Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.assignments.li11') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.assignments.li1Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.assignments.li1') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.assignments.li2Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.assignments.li2') }}
      </li>
      <li>
        <b>
          {{ $t('documentation.texts.dashboard.assignments.li3Title') }}
        </b>
        {{ $t('documentation.texts.dashboard.assignments.li3') }}
      </li>
    </ul>
    <br />
    <h2 class="primary--text">
      {{ $t('documentation.texts.dashboard.assignments.title2') }}
    </h2>
    <v-row>
      <v-img
        style="
          width: 50%;
          max-width: 300px;
          margin-top: 10px;
          margin-bottom: 20px;
        "
        contain
        :src="pic2Name"
      />
      <!-- <p>{{ $t('documentation.texts.dashboard.assignments.paragraph3') }}</p>
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph4') }}</p>
    <h2 class="primary--text">
      {{ $t('documentation.texts.dashboard.assignments.title3') }}
    </h2> -->
      <v-img
        style="
          width: 50%;
          max-width: 300px;
          margin-top: 10px;
          margin-bottom: 20px;
        "
        contain
        :src="pic3Name"
      />
    </v-row>
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph5') }}</p>
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph6') }}</p>
    <p>{{ $t('documentation.texts.dashboard.assignments.paragraph7') }}</p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.assignments.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.assignments.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.assignments.pic3') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
