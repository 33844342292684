<template>
  <v-app-bar data-v-step="8" app color="primary" dark clipped-right>
    <v-img
      data-v-step="0"
      class="logo-custom-button"
      contain
      :src="getImageSource"
      transition="fade-transition"
      max-width="175"
      @click="
        $store.state.user
          ? $router.push({ name: 'DashboardView' })
          : $router.push({ name: 'LoginView' })
      "
    />
    <AppTour
      v-if="$store.state.user"
      :finishFunction="tourFinishFunction"
      :autoStart="!$store.state.user.tourAppFinished"
      :stepsProp="tourSteps"
      name="mainTour"
    >
    </AppTour>
    <v-spacer data-v-step="20" />

    <v-fade-transition mode="out-in">
      <v-toolbar-items
        style="width: 100%"
        v-if="isUserSignedIn && $route.name != 'LoginView'"
      >
        <v-spacer />

        <template v-if="isMobile">
          <AppBarItemButton
            v-if="isNotificacionesActivas"
            includeBadge
            color="secondary"
            :item="notificationItem"
          />
          <AppBarItemButton
            v-if="isFirmasActivas"
            includeBadge
            color="secondary"
            :item="signatureItem"
          />
          <AppBarItemButton
            includeBadge
            color="secondary"
            :item="analyticsItem"
          />
          <AppBarItemButton
            v-if="isMobile"
            color="secondary"
            :item="{ icon: navigationDrawer ? 'mdi-close' : 'mdi-menu' }"
            :clickButton="() => setNavigationDrawer(!navigationDrawer)"
          />
        </template>
        <template v-else>
          <template v-for="item in $store.state.navigationPages">
            <v-fade-transition mode="out-in" :key="item.title">
              <NewsList
                :icon="item.icon"
                :title="item.title"
                v-if="item.component == 'NewsList'"
                data-v-step="7"
              />
              <AppBarItemMenu
                v-if="item.component == 'AppBarItemMenu' && item.available"
                :isUserMenu="item.title === 'profile'"
                :title="item.title"
                :items="item.subPages"
                :icon="item.icon"
                :color="item.color"
                :clickable="item.clickable"
              />
            </v-fade-transition>

            <v-fade-transition
              v-if="item.title"
              mode="out-in"
              :key="item.title"
            >
              <!-- Id is for Cypress test-->
              <AppBarItemButton
                v-if="!item.subPages && item.available && !item.component"
                :item="item"
                :includeBadge="item.includeBadge"
                :includeTitle="item.includeTitle"
                :includeIcon="item.includeIcon"
                :color="item.color"
                :id="
                  item.title == 'notifications'
                    ? 'notifications-menu'
                    : item.title == 'documentation'
                    ? 'documentation-menu'
                    : ''
                "
              />
            </v-fade-transition>
          </template>
        </template>
      </v-toolbar-items>
    </v-fade-transition>
  </v-app-bar>
</template>

<script>
import { updateUserInfo } from '@/services/user-service'
import { mapState, mapGetters, mapMutations } from 'vuex'
import {
  tourAdminFullServicesSteps,
  tourAdminNotificationsSteps,
  tourAdminSignaturesSteps,
  tourOperatorFullServicesSteps,
  tourOperatorNotificationsSteps,
  tourOperatorSignaturesSteps,
  notificationDetailSteps,
  signatureDetailSteps,
} from '@/utils/tour-utils.js'
import AppBarItemMenu from '@/components/app-bar/AppBarItemMenu'
import NewsList from '@/components/news/NewsList'
import AppTour from '@/components/app-bar/AppTour'
import AppBarItemButton from '@/components/app-bar/AppBarItemButton'

export default {
  components: {
    AppBarItemMenu,
    AppBarItemButton,
    AppTour,
    NewsList,
  },
  data() {
    return {
      notificationItem: {
        icon: 'mdi-inbox-arrow-down',
        routerData: {
          name: 'NotificationsView',
        },
      },
      signatureItem: {
        icon: 'mdi-signature-freehand',
        routerData: {
          name: 'SignaturesView',
        },
      },
      analyticsItem: {
        icon: 'mdi-graph-line',
        routerData: {
          name: 'NotificationsView',
        },
      },
      tourAdminFullServicesSteps: tourAdminFullServicesSteps(),
      tourAdminNotificationsSteps: tourAdminNotificationsSteps(),
      tourAdminSignaturesSteps: tourAdminSignaturesSteps(),
      tourOperatorFullServicesSteps: tourOperatorFullServicesSteps(),
      tourOperatorNotificationsSteps: tourOperatorNotificationsSteps(),
      tourOperatorSignaturesSteps: tourOperatorSignaturesSteps(),
      notificationDetailSteps: notificationDetailSteps(),
      signatureDetailSteps: signatureDetailSteps(),
    }
  },
  methods: {
    ...mapMutations(['setNavigationDrawer']),
    async tourFinishFunction() {
      await updateUserInfo(this.$store.state.user.id, {
        tourAppFinished: true,
      })
    },
  },
  computed: {
    ...mapState(['navigationDrawer']),
    ...mapGetters([
      'isMobile',
      'isUserSignedIn',
      'isAdminUser',
      'isNotificacionesActivas',
      'isFirmasActivas',
    ]),
    getImageSource() {
      if (this.$store.state.brand?.appLogo?.startsWith('http')) {
        return this.$store.state.brand.appLogo
      } else {
        return require('@/assets/app-logo.png')
      }
    },
    tourSteps() {
      if (this.isAdminUser) {
        if (this.isNotificacionesActivas && this.isFirmasActivas) {
          return this.tourAdminFullServicesSteps
        } else {
          if (this.isNotificacionesActivas) {
            return this.tourAdminNotificationsSteps
          } else {
            return this.tourAdminSignaturesSteps
          }
        }
      } else if (this.isNotificacionesActivas && this.isFirmasActivas) {
        return this.tourOperatorFullServicesSteps
      } else {
        if (this.isNotificacionesActivas) {
          return this.tourOperatorNotificationsSteps
        } else {
          return this.tourOperatorSignaturesSteps
        }
      }
    },
  },
}
</script>

<style>
.logo-custom-button {
  transition: 0.25s;
  cursor: pointer;
}

.logo-custom-button:hover {
  scale: 1.05;
}

.logo-custom-button:active {
  scale: 0.97;
}
</style>
