import { UsersIp } from '@/firebase-exports'

import { publicIp, publicIpv4, publicIpv6 } from 'public-ip'

export const getIp = async (version) => {
  return version === 'ipv4'
    ? await publicIpv4()
    : version === 'ipv6'
    ? await publicIpv6()
    : await publicIp() // IPv6 with fallsback to IPv4
}

export const getIpFunction = async () => {
  // Get IP using Firebase back function (context.ip) instead of calling third services
  // Will return ipv6 except client only have ipv4 - no option to choose version required
  return (await UsersIp()).data
}

/*export const getIpDetails = (ip) => {
  // return
}*/
