<template>
  <v-card min-height="600" elevation="5" class="rounded-b-xl">
    <v-container>
      <v-row>
        <v-col class="text-right" cols="12">
          <v-tabs :color="tabColor" v-model="tab" fixed-tabs>
            <v-tab>{{ $t('pending') }}</v-tab>
            <v-tab>{{ $t('urgent') }}</v-tab>
            <v-tab>{{ $t('expired') }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <component
        data-v-step="2"
        v-bind:is="AlertListElement"
        :assetList="assetList"
      ></component>
    </v-container>
  </v-card>
</template>

<script>
import AlertListElementNotifications from '@/components/dashboard/dashboardElements/notifications/AlertListElement'
import AlertListElementSignatures from '@/components/dashboard/dashboardElements/signatures/AlertListElement'
export default {
  components: {
    AlertListElementNotifications,
    AlertListElementSignatures,
  },

  props: {
    AlertListElement: { type: String },
    urgent: {
      type: Array,
    },
    expired: {
      type: Array,
    },
    pending: {
      type: Array,
    },
  },
  data() {
    return {
      tab: 1,
    }
  },
  computed: {
    assetList() {
      switch (this.tab) {
        case 0:
          return this.pending
        case 1:
          return this.urgent
        case 2:
          return this.expired

        default:
          return this.pending
      }
    },
    tabColor() {
      switch (this.tab) {
        case 0:
          return 'accept'
        case 1:
          return 'warning'
        case 2:
          return 'red'

        default:
          return 'accept'
      }
    },
  },
}
</script>
