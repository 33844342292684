import vuetify from '@/plugins/vuetify'
import store from '@/store/index'
import hexToRgba from 'hex-to-rgba'

export const getVuetifyLightTheme = () => {
  return vuetify.framework.theme.themes.light
}

export const setVuetifyLightTheme = (primary, secondary) => {
  vuetify.framework.theme.themes.light.primary = primary
  vuetify.framework.theme.themes.light.secondary = secondary
}

export const setVuetifyDarkTheme = (primary, secondary) => {
  vuetify.framework.theme.themes.dark.primary = primary
  vuetify.framework.theme.themes.dark.secondary = secondary
}

export const setBrand = (customBrand) => {
  setVuetifyLightTheme(customBrand.primaryColor, customBrand.secondaryColor)
  setVuetifyDarkTheme(customBrand.primaryColor, customBrand.secondaryColor)
  store.commit('setBrand', customBrand)
}

export const hexToRgbaConv = (hex, opacity = 1) => {
  return hexToRgba(hex, opacity)
}

export const resetBrand = () => {
  setVuetifyLightTheme('#1D9EA4', '#FFFFFF')
  setVuetifyDarkTheme('#1D9EA4', '#EEEEEE')
  store.commit('setBrand', null)
}
