<template>
  <v-tooltip max-width="400" v-if="alert" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :large="large"
        class="ml-1"
        dark
        :color="alertStatus.color"
      >
        {{ alertStatus.icon }}
      </v-icon>
    </template>
    <div width="300">
      <h2>{{ $d(alert.date) }}</h2>
      {{ alert.text }}
    </div>
  </v-tooltip>
</template>

<script>
import { getAlertStatus } from '@/services/alert-service'

export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Object,
    },
  },

  computed: {
    alertStatus() {
      return getAlertStatus(this.alert)
    },
  },
}
</script>

<style lang="scss" scoped></style>
