<template>
  <v-col class="body-1">
    <span> {{ $t('massiveTitleOperators') }} </span>
    <span> {{ $t('csvColumns') }} </span>
    <br />
    <br />
    <span> <b>nombre</b>: {{ $t('csvOperators.name') }} </span>
    <br />
    <span>
      <b>apellidos</b>:
      {{ $t('csvOperators.surname') }}
    </span>
    <br />
    <span>
      <b>email</b>:
      {{ $t('csvOperators.email') }}
    </span>
    <br />
    <span>
      <b>alertasEmail</b>: {{ $t('csvOperators.emailAlerts') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>permisosAdmin</b>: {{ $t('csvOperators.adminPermissions') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>notificacionesActivas</b>:
      {{ $t('csvOperators.notificacionesActivas') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>filtros</b>: {{ $t('csvOperators.filters') }} →
      <b>autonomicAndLocal / dgt / aeat / justice / ss / otherMinistries</b>
    </span>
    <br />
    <span>
      <b>permisosFirmaNotificaciones</b>:
      {{ $t('csvOperators.downloadNotifications') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>permisosTramiteNotificaciones</b>:
      {{ $t('csvOperators.processNotifications') }} → <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>firmasActivas</b>: {{ $t('csvOperators.firmasActivas') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <span>
      <b>empresas</b> ({{ $t('optional') }}): {{ $t('csvOperators.empresas') }}
    </span>
    <br />
    <span>
      <b>nuevasEmpresas</b>: {{ $t('csvOperators.addNewCifs') }} →
      <b>SI / NO</b>
    </span>
    <br />
    <br />
    <span> {{ $t('csvSeparation') }} </span>
    <br />
    <br />
    <p>
      {{ $t('checkTemplate') }}
      <v-btn
        class="ma-0 pa-0"
        small
        text
        chip
        rounded
        color="primary"
        @click="downloadTemplate"
      >
        {{ $t('downloadTemplate') }}
      </v-btn>
      {{ $t('checkTemplate2') }}
    </p>

    <v-file-input
      ref="csvfile"
      show-size
      accept=".csv"
      v-model="file"
      :label="$t('uploadCSV')"
      prepend-icon="mdi-paperclip"
      @change="onFileChange"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
    <GroupAutocomplete
      v-if="
        groups?.length &&
        (isSuperAdminUser ||
          isSuperOperatorUser ||
          (isAdminUser && !isInGroupUser))
      "
      @setGroups="(groups) => (selectedGroup = groups)"
      :disabled="loading"
      :groups="groups"
      :multiple="false"
    />
    <v-container v-if="log.length > 0">
      <v-btn
        chip
        color="primary"
        class="mb-3"
        @click="download"
        v-if="!log[0].includes($t('processingEmailCsv'))"
      >
        {{ $t('downloadLog') }}
      </v-btn>
      <v-col style="max-height: 300px; overflow-y: scroll; overflow-x: hidden">
        <v-row
          class="px-5 font-weight-bold body-1"
          v-for="(item, i) in log"
          :key="i"
        >
          {{ item }}
        </v-row>
      </v-col>
    </v-container>
  </v-col>
</template>

<script>
import GroupAutocomplete from '@/components/groups/GroupAutocomplete.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    loading: Boolean,
    log: Array,
    groups: Array,
  },
  components: {
    GroupAutocomplete,
  },
  computed: {
    ...mapGetters([
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAdminUser',
      'isInGroupUser',
    ]),
  },
  data() {
    return {
      file: undefined,
      fileContent: undefined,
      selectedGroup: undefined,
    }
  },
  methods: {
    download() {
      this.saveFile(this.log.join('\n'), 'log.txt')
    },
    downloadTemplate() {
      const text =
        'nombre;apellidos;email;alertasEmail;permisosAdmin;notificacionesActivas;filtros;permisosFirmaNotificaciones;permisosTramiteNotificaciones;firmasActivas;empresas;nuevasEmpresas \nNombre 1;Apellidos 1;operador_1@email.com;SI;NO;SI;aeat|ss;SI;SI;NO;H49389346|H69013861;NO \nNombre 2;Apellidos 2;operador_2@email.com;SI;NO;SI;;SI;SI;NO;H49389346;NO\n'
      this.saveFile(text, 'plantilla_operadores.csv')
    },
    saveFile(text, name) {
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([text], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = name
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
    },
    onFileChange() {
      if (!this.file) {
        this.fileContent = ''
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => (this.fileContent = e.target.result)
      reader.readAsText(this.file, 'ISO-8859-1')
    },
  },
  watch: {
    selectedGroup: function () {
      this.$emit('setCSVData', {
        file: this.fileContent,
        group: this.selectedGroup,
      })
    },
    fileContent: async function () {
      this.$emit('setCSVData', {
        file: this.fileContent,
        group: this.selectedGroup,
      })
    },
    loading: async function (newValue) {
      if (!newValue) this.$refs.csvfile.reset()
    },
  },
}
</script>
