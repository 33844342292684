<template>
  <v-card
    elevation="10"
    class="mx-auto"
    min-height="740"
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    "
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-row class="mt-2 pt-2 pl-3 pr-3">
            <v-select
              item-disabled="disable"
              dense
              item-text="name"
              :items="documents"
              :label="$t('files')"
              return-object
              v-model="selectedFile"
            ></v-select>
            <v-tooltip v-if="getDocErrorFlag" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 mr-2"
                  large
                  color="warning"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span> {{ $t('missingDocWarning') }}</span>
            </v-tooltip>
            <v-dialog transition="dialog-bottom-transition" max-width="1300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" v-if="!isMobile">
                  <v-icon>mdi-arrow-expand-all</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card min-height="780" max-height="90%">
                  <v-toolbar color="primary" dark>
                    <v-row align="center">
                      <v-col cols="10">
                        <h3>
                          {{ $t('notificationFiles') }}
                        </h3>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end">
                        <v-btn @click="dialog.value = false" icon>
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-col
                    class="pa-0 ma-0"
                    cols="12"
                    style="
                      height: 748px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                    align="center"
                  >
                    <v-row class="mt-2 d-flex justify-center">
                      <v-select
                        class="mt-5"
                        item-disabled="disable"
                        dense
                        item-text="name"
                        :items="documents"
                        :label="$t('files')"
                        return-object
                        v-model="selectedFile"
                        style="max-width: 80%"
                      ></v-select>
                      <v-tooltip v-if="getDocErrorFlag" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2 mr-2"
                            large
                            color="warning"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span> {{ $t('missingDocWarning') }}</span>
                      </v-tooltip>
                    </v-row>
                    <v-col v-if="loadingData" class="ma-0" cols="12" sm="12">
                      <v-progress-linear indeterminate color="primary">
                      </v-progress-linear>
                    </v-col>
                    <v-col
                      class="pa-0 ma-0"
                      cols="12"
                      style="height: 568px; overflow-y: scroll"
                    >
                      <DehuDocumentViewer
                        v-if="selectedFile.file"
                        :blob="selectedFile.file"
                      />
                    </v-col>
                  </v-col>
                  <v-card-actions>
                    <v-row
                      v-if="!isMobile"
                      no-gutters
                      class="flex-wrap"
                      justify="center"
                    >
                      <v-btn
                        v-if="
                          isSharedAccess
                            ? true
                            : !isOperatorUser ||
                              $store.state.user?.processNotification
                        "
                        :disabled="!Object.keys(selectedFile).length"
                        @click="downloadSelectedFile()"
                        color="primary"
                        class="ma-2 secondary--text"
                      >
                        {{ $t('downloadFile') }}
                        <v-icon right dark> mdi-file-download-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="
                          isSharedAccess
                            ? true
                            : !isOperatorUser ||
                              $store.state.user?.processNotification
                        "
                        :disabled="!Object.keys(selectedFile).length"
                        @click="downloadSelectedFile()"
                        color="primary"
                        class="ma-2 secondary--text"
                      >
                        {{ $t('downloadFile') }}
                        <v-icon right dark> mdi-file-download-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="
                          isSharedAccess
                            ? true
                            : !isOperatorUser ||
                              $store.state.user?.processNotification
                        "
                        :disabled="
                          loadingData ||
                          !documents.filter((o) => o.disable === false).length
                        "
                        @click="downloadAllFiles()"
                        color="primary"
                        class="ma-2 secondary--text"
                      >
                        {{ $t('downloadAll') }}
                        <v-icon right dark>
                          mdi-folder-arrow-down-outline
                        </v-icon>
                      </v-btn>
                    </v-row>
                    <v-col align="center" v-else>
                      <v-btn
                        v-if="
                          isSharedAccess
                            ? true
                            : !isOperatorUser ||
                              $store.state.user?.processNotification
                        "
                        :disabled="!Object.keys(selectedFile).length"
                        @click="downloadSelectedFile()"
                        color="primary"
                        class="ma-2 secondary--text"
                      >
                        {{ $t('downloadFile') }}
                        <v-icon right dark> mdi-file-download-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="
                          isSharedAccess
                            ? true
                            : !isOperatorUser ||
                              $store.state.user?.processNotification
                        "
                        :disabled="
                          loadingData ||
                          !documents.filter((o) => o.disable === false).length
                        "
                        @click="downloadAllFiles()"
                        color="primary"
                        class="ma-2 secondary--text"
                      >
                        {{ $t('downloadAll') }}
                        <v-icon right dark>
                          mdi-folder-arrow-down-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-row>
        </v-col>
        <v-col v-if="loadingData" class="ma-0" cols="12" sm="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
        <v-col
          class="pa-0 ma-0"
          cols="12"
          style="height: 568px; overflow-y: scroll"
        >
          <DehuDocumentViewer
            v-if="selectedFile.file"
            :blob="selectedFile.file"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <component
        :is="isMobile ? 'v-col' : 'v-row'"
        :no-gutters="!isMobile"
        :class="!isMobile ? 'flex-wrap' : ''"
        justify="center"
        align="center"
      >
        <v-dialog persistent v-model="addFilesDialog" width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                isSharedAccess || $store.getters.isDemoEnv
                  ? false
                  : !isOperatorUser || $store.state.user?.processNotification
              "
              v-bind="attrs"
              v-on="on"
              @click="shareDialog = true"
              color="green"
              class="ma-2 secondary--text"
              :disabled="
                loadingData ||
                !documents.filter((o) => o.disable === false).length
              "
            >
              {{ $t('addFile') }}
              <v-icon right dark> mdi-plus </v-icon>
            </v-btn>
          </template>

          <v-card
            v-if="!isOperatorUser || $store.state.user?.processNotification"
          >
            <v-card-title class="text-h5 grey lighten-2">
              <v-container>
                <v-row justify="space-between" align="center">
                  <span>
                    {{
                      isMobile
                        ? $t('addNotificationFilesMobile')
                        : $t('addNotificationFiles')
                    }}
                  </span>
                  <v-btn
                    fab
                    @click="addFilesDialog = false"
                    small
                    plain
                    v-if="!loadingDataDialog"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-title>
            <v-col class="pt-5" cols="12" justify="center" align="center">
              <NotificationAttachedFiles
                @update-files-attached="updateFilesAttached"
                :alreadyUploadedFiles="notification.documentExtras"
                :applyRules="true"
                :openOnInit="openOnInit"
                :key="renderAgain"
                :notificationId="notification.id"
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="confirmUploadExtraFiles()"
                color="primary"
                text
                id="confirm-upload-files"
                :loading="loadingDataDialog"
                :disabled="!filesAttached.length"
              >
                {{ $t('confirm') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="
            isSharedAccess
              ? true
              : !isOperatorUser || $store.state.user?.processNotification
          "
          :disabled="!Object.keys(selectedFile).length"
          @click="downloadSelectedFile()"
          color="primary"
          class="ma-2 secondary--text"
        >
          {{ $t('downloadFile') }}
          <v-icon right dark> mdi-file-download-outline </v-icon>
        </v-btn>
        <v-btn
          v-if="
            isSharedAccess
              ? true
              : !isOperatorUser || $store.state.user?.processNotification
          "
          :disabled="
            loadingData || !documents.filter((o) => o.disable === false).length
          "
          @click="downloadAllFiles()"
          color="primary"
          class="ma-2 secondary--text"
        >
          {{ $t('downloadAll') }}
          <v-icon right dark> mdi-folder-arrow-down-outline </v-icon>
        </v-btn>
      </component>
    </v-card-actions>
  </v-card>
</template>

<script>
import DehuDocumentViewer from '@/components/commons/DehuDocumentViewer'
import NotificationAttachedFiles from '@/components/notifications/detail/NotificationAttachedFiles'
import { saveAs } from 'file-saver'
import { mapGetters, mapMutations } from 'vuex'
import {
  getNotificationType,
  openAnnexe,
  openNotification,
  openAck,
  openExtra,
  uploadAddedDocumentsToDMS,
} from '@/services/notification-service'
import {
  demoDocumentBase64,
  demoAckBase64,
  demoAnnexeBase64,
} from '@/services/notification-demo-service'
import { VRow, VCol } from 'vuetify/lib'

export default {
  props: {
    notification: Object,
    isSharedAccess: Boolean,
  },
  components: {
    DehuDocumentViewer,
    NotificationAttachedFiles,
    VRow,
    VCol,
  },
  watch: {
    notification: function () {
      this.getDocuments(this.notification)
      // Re-renderizamos el compoente NotificationAttachedFiles
      this.openOnInit = false
      this.renderAgain += 1
    },
  },
  mounted() {
    this.documents = []
    this.getDocuments(this.notification)
  },
  data() {
    return {
      documents: [],
      loadingData: false,
      loadingDataDialog: false,
      selectedFile: {},
      getDocErrorFlag: false,
      addFilesDialog: false,
      filesAttached: [],
      renderAgain: 0,
      openOnInit: true,
      documentsToDelete: [],
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async updateFilesAttached(newFilesAttached) {
      this.filesAttached = []
      this.documents = []
      const extraFiles = newFilesAttached.filter(
        (document) =>
          (document.alreadyUploadedToDMS === false ||
            document.addedDocument === false) &&
          !document.simpleDelete &&
          !document.deleteFromDMS
      )

      // Solo añadimos aquellos que no han sido añadidos previamente
      if (extraFiles.length !== 0)
        extraFiles.forEach((file) => {
          if (
            !this.filesAttached.some(
              (existingFile) => existingFile.name === file.name
            )
          ) {
            this.filesAttached.push(file)
          }
        })
    },
    async confirmUploadExtraFiles() {
      try {
        if (!this.filesAttached.length) return
        if (
          this.filesAttached.every((file) =>
            this.notification.documentExtras?.some(
              (document) => document.name === file.name
            )
          )
        ) {
          const message = this.$t('extraFilesUpdateSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })
          this.addFilesDialog = false
          return
        }
        this.loadingDataDialog = true

        await uploadAddedDocumentsToDMS(this.notification.id, {
          documents: this.filesAttached,
        })

        this.documents = []
        this.documentsToDelete = []
        this.loadingDataDialog = false

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('extraFilesUpdateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.addFilesDialog = false
      } catch (err) {
        this.loadingDataDialog = false
        const message = this.$t('extraFilesUpdateError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
        return
      }
    },
    async getDocuments(notification) {
      this.loadingData = true
      //get all docs converted into blob from dehu base64 string codification to be displayed in visor component
      if (notification.document.items) {
        for (const doc of notification.document.items) {
          try {
            const mainDoc = {
              file: this.$store.getters.isDemoEnv
                ? new Blob([Buffer.from(demoDocumentBase64(), 'base64')], {
                    type: 'application/pdf',
                  })
                : new Blob(
                    [
                      Buffer.from(
                        await openNotification(
                          notification,
                          doc,
                          this.isSharedAccess
                            ? {
                                userId: this.$route.params.userId,
                                shareCode: this.$route.params.shareCode,
                              }
                            : undefined
                        ),
                        'base64'
                      ),
                    ],
                    {
                      type: 'application/pdf',
                      //type: doc['Mime-Type'], //TODO: Para el futuro si el DMS de Addalia lo acaben guardando
                    }
                  ),
              name: `📄 Documento_${doc['#Id']}.pdf`,
              //name: doc.nombre //TODO: Para el futuro si el DMS de Addalia lo acaben guardando
              name_dehu: `Documento_${doc['#Id']}.pdf`,
              //name_dehu: doc.nombre //TODO: Para el futuro si el DMS de Addalia lo acaben guardando
              disable: false,
            }
            this.documents.push(mainDoc)
          } catch (error) {
            this.documents.push({
              name: `📄 Documento_${doc['#Id']}.pdf`,
              name_dehu: `Documento_${doc['#Id']}.pdf`,
              disable: true,
            })
            //console.log('Error Al obtener documento principal', error)
            this.getDocErrorFlag = true
          }
        }
      }
      this.selectedFile = this.documents.filter((o) => o.disable === false)[0] //Use the first enable element as selectedFile
      if (!this.selectedFile) this.selectedFile = {}

      //get all documents, content
      if (notification.document.referenciaAnexos) {
        for (const annexe of notification.document.referenciaAnexos) {
          try {
            const openedAnnexe = {
              file: this.$store.getters.isDemoEnv
                ? new Blob([Buffer.from(demoAnnexeBase64(), 'base64')], {
                    type: 'application/pdf',
                  })
                : new Blob(
                    [
                      Buffer.from(
                        await openAnnexe(
                          notification,
                          annexe,
                          this.isSharedAccess
                            ? {
                                userId: this.$route.params.userId,
                                shareCode: this.$route.params.shareCode,
                              }
                            : undefined
                        ),
                        'base64'
                      ),
                    ],
                    {
                      type: 'application/pdf',
                    }
                  ),
              name: '🖇️' + annexe.nombre,
              name_dehu: annexe.nombre,
              disable: false,
            }
            this.documents.push(openedAnnexe)
          } catch (error) {
            this.documents.push({
              name: '🖇️' + annexe.nombre,
              name_dehu: annexe.nombre,
              disable: true,
            })
            //console.log('Error Al obtener annexe', annexe, error)
            this.getDocErrorFlag = true
          }
        }
      }
      if (
        getNotificationType(notification).name == 'notification' &&
        (notification.document.csv_resguardo ||
          notification.document.referencia_pdf_acuse)
      ) {
        try {
          this.documents.push({
            file: this.$store.getters.isDemoEnv
              ? new Blob([Buffer.from(demoAckBase64(), 'base64')], {
                  type: 'application/pdf',
                })
              : new Blob(
                  [
                    Buffer.from(
                      await openAck(
                        notification,
                        this.isSharedAccess
                          ? {
                              userId: this.$route.params.userId,
                              shareCode: this.$route.params.shareCode,
                            }
                          : undefined
                      ),
                      'base64'
                    ),
                  ],
                  {
                    type: 'application/pdf',
                  }
                ),
            name: `🧾 Acuse_${notification.id}.pdf`,
            name_dehu: `Acuse_${notification.id}.pdf`,
            disable: false,
          })
        } catch (error) {
          this.documents.push({
            name: `🧾 Acuse_${notification.id}.pdf`,
            disable: true,
          })
          //console.log('Error Al obtener acuse de recibo', error)
          this.getDocErrorFlag = true
        }
      }
      if (notification.documentExtras) {
        for (const extra of notification.documentExtras) {
          try {
            const openedExtra = {
              file: new Blob(
                [
                  Buffer.from(
                    await openExtra(
                      notification,
                      extra,
                      this.isSharedAccess
                        ? {
                            userId: this.$route.params.userId,
                            shareCode: this.$route.params.shareCode,
                          }
                        : undefined
                    ),
                    'base64'
                  ),
                ],
                {
                  type: 'application/pdf',
                }
              ),
              name: '➕' + extra.name,
              name_dehu: extra.name,
              disable: false,
            }
            this.documents.push(openedExtra)
          } catch (error) {
            this.documents.push({
              name: '➕' + extra.name,
              name_dehu: extra.name,
              disable: true,
            })
            //console.log('Error Al obtener extra', extra, error)
            this.getDocErrorFlag = true
          }
        }
      }

      this.loadingData = false
    },
    async downloadAllFiles() {
      try {
        this.loadingData = true
        this.documents.forEach((element) => {
          if (element.file) {
            saveAs(element.file, `${element.name_dehu}`)
          }
        })
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        /*
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: "error"
          message,
        })
        */
      }
    },
    async downloadSelectedFile() {
      try {
        this.loadingData = true
        if (this.selectedFile.file) {
          saveAs(this.selectedFile.file, `${this.selectedFile.name_dehu}`)
        }
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        /*
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: "error"
          message,
        })
        */
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
