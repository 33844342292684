<template>
  <v-dialog :value="true" persistent :max-width="dialogWidth">
    <v-card>
      <v-card-title>
        <v-row justify="center">
          <v-col cols="12" align="center">
            <div class="body-1">
              {{
                !isDisplay
                  ? $t('clientActions.title.' + action)
                  : client.name + ' ' + client.surname
              }}
            </div>
            <div>
              {{ cif.empresa }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" align="center">
          <v-alert
            v-if="clientHasAuthorized && Object.keys(wallet).length"
            outlined
            :color="certificateColor"
            class="mx-4 d-flex justify-center"
            width="500px"
            align="center"
          >
            <b>{{ certificateTitle }} </b>
            <span v-if="certificateExpiration">
              <i style="display: block">
                {{ $t('certificateExpiration.expirationDate') }}
                {{ $d(certificateExpiration.toDate(), 'i18nDate') }}
              </i>
            </span>
          </v-alert>
        </v-col>

        <template v-if="isDelete">
          <v-col cols="12" align="center">
            <h3>{{ client.name }}</h3>
            <p class="body-2">{{ client.email }}</p>
            <p>{{ $t('sureRemoveClient') }}</p>
          </v-col>
        </template>

        <template v-if="isCreate || isUpdate || isDisplay">
          <!-- Step 0 asks for email -->
          <template v-if="step === 0">
            <!-- Asking for the email of the client -->
            <v-col cols="12" class="mt-4">
              <v-form
                ref="emailForm"
                v-model="validEmailForm"
                @submit.prevent="() => {}"
                lazy-validation
              >
                <v-text-field
                  :label="$t('email')"
                  prepend-icon="mdi-email"
                  :value="clientEmail"
                  @input="clientEmail = $event"
                  dense
                  autocomplete="false"
                  id="client-email-input"
                  :disabled="!!existingClient || loading"
                  :rules="[maxCharacters(maxChars), notEmpty, email]"
                />
              </v-form>
            </v-col>

            <!-- If the client already exists we show the data-->
            <v-col cols="12" align="center" v-if="existingClient">
              <h3>{{ $t('userAlreadyRegistered') }}</h3>
              <p>{{ $t('useAlreadyCreated') }}</p>
              <v-container></v-container>
              <v-col cols="8">
                <v-row align="center" style="max-width: 400">
                  <v-col cols="2">
                    <AppAvatar
                      :userName="existingClient.name"
                      avatarColor="primary"
                      initialsColor="secondary"
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-col>
                  <v-col cols="8" align="left">
                    <div class="body-1 grey-darken-4">
                      {{ existingClient.name }}
                    </div>
                    <div>
                      {{ existingClient.email }}
                    </div>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-btn
                      @click="removeExistingClient"
                      icon
                      :disabled="loading"
                    >
                      <v-icon> mdi-window-close </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </template>

          <!-- Step 1 asks for client data, if it exists it fills the data -->
          <template v-else-if="step === 1">
            <v-form
              ref="clientForm"
              v-model="validClientForm"
              @submit.prevent="() => {}"
              lazy-validation
            >
              <v-col cols="12">
                <v-text-field
                  :label="$t('email')"
                  prepend-icon="mdi-email"
                  :value="clientData.email"
                  @input="clientData.email = $event"
                  dense
                  autocomplete="false"
                  id="company-email-user"
                  :disabled="
                    !!existingClient ||
                    this.isUpdate ||
                    this.clientEmail !== '' ||
                    isDisplay
                  "
                  :rules="[maxCharacters(maxChars), notEmpty, email]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('firstName')"
                  prepend-icon="mdi-account"
                  :value="clientData.name"
                  @input="clientData.name = $event"
                  dense
                  autocomplete="false"
                  id="client-first-name"
                  :disabled="
                    !!existingClient ||
                    loading ||
                    clientHasAuthorized ||
                    isDisplay
                  "
                  :rules="[maxCharacters(maxChars), notEmpty]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('lastName')"
                  prepend-icon="mdi-account-settings"
                  :value="clientData.surname"
                  @input="clientData.surname = $event"
                  dense
                  autocomplete="false"
                  id="client-last-name"
                  :disabled="
                    !!existingClient ||
                    loading ||
                    clientHasAuthorized ||
                    isDisplay
                  "
                  :rules="[maxCharacters(maxChars), notEmpty]"
                />
              </v-col>
              <v-row class="mx-1">
                <v-col cols="12">
                  <v-text-field
                    v-if="communicationType === 'sms'"
                    :label="$t('phone')"
                    prepend-icon="mdi-phone"
                    v-model="telefono"
                    :value="telefono"
                    dense
                    autocomplete="false"
                    id="client-phone-input"
                    :disabled="loading || clientHasAuthorized || isDisplay"
                    :rules="[maxCharacters(maxChars), notEmpty, phoneNumber]"
                  />
                </v-col>
              </v-row>
              <v-row
                class="mt-2"
                justify="center"
                v-if="!isCreate && clientHasAuthorized"
              >
                <v-col cols="12" sm="6" align="center">
                  <h3>
                    <v-icon> mdi-message-fast </v-icon>
                    {{ $t('communicationType') }}
                  </h3>
                  <v-radio-group
                    v-model="communicationType"
                    :rules="[notEmpty]"
                    style="max-width: 190px"
                    disabled
                  >
                    <v-radio
                      id="email-radio-button"
                      :label="$t('email')"
                      value="mail"
                    />
                    <v-radio
                      id="sms-radio-button"
                      :label="$t('sms')"
                      value="sms"
                    />
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" align="center">
                  <h3>
                    <v-icon> mdi-book-settings </v-icon>
                    {{ $t('authorizationType') }}
                  </h3>
                  <v-radio-group
                    v-model="authorizationType"
                    :rules="[notEmpty]"
                    style="max-width: 190px"
                    disabled
                  >
                    <v-radio
                      id="pin-radio-button"
                      :label="$t('pin')"
                      value="pin"
                    />
                    <v-radio
                      id="otp-radio-button"
                      :label="$t('otp')"
                      value="otp"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </template>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          v-if="step === 0"
          color="primary"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="moveToStep(step + 1)"
          id="continue-client"
        >
          {{ $t('continue') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
        <v-btn
          v-if="step === 1 && !isDisplay"
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="performAction"
          id="confirm-client"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import AppAvatar from '@/components/commons/AppAvatar.vue'
import {
  getUserClients,
  isNonClientByEmail,
  updateUserInfo,
} from '@/services/user-service'
import {
  createClient,
  clientDenyResponsibility,
  getWallet,
} from '@/services/client-service'
import { createCifWallet } from '@/services/cifs-service'
import getErrorText from '@/utils/get-error-text'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'

export default {
  props: {
    cif: Object,
    client: Object,
    action: String,
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    // If there is not a client it means we must create a new one
    if (this.isCreate) this.clients = await getUserClients(userId)
    // If there is a client it means we must update one
    else if (this.isUpdate || this.isDisplay) {
      this.clientData = { ...this.client }
      this.step = 1

      this.wallet = await getWallet(this.client.id, this.cif.id)

      this.communicationType = this.wallet.communicationType
      this.authorizationType = this.wallet.authorizationType
      this.certificateExpiration = this.wallet.certificateExpiration
      this.telefono = this.wallet.telefono
    } else if (this.isDelete) {
      this.step = 1
    }
  },
  data() {
    return {
      authorizationType: undefined,
      communicationType: undefined,
      certificateExpiration: undefined,
      clientData: {},
      clientEmail: undefined,
      validClientForm: false,
      clients: undefined,
      existingClient: undefined,
      telefono: undefined,
      loading: false,
      maxChars: 100,
      step: 0,
      validEmailForm: false,
      wallet: {},
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeClientDialog')
    },
    /**
     * Resets a variable of the context (data) and sets it to undefined.
     * @param {string} key Context variable name.
     */
    resetField(key) {
      this[key] = undefined
    },
    /**
     * Function that moves the dialog to a different step.
     *
     * 0 -> 1: Checks if the email introduced belongs to any client.
     */
    async moveToStep(newStep) {
      this.loading = true
      try {
        if (this.step === 0 && newStep === 1) {
          // Checking if the form is correct
          if (!this.$refs.emailForm?.validate()) return

          const userId = this.isOperatorUser
            ? this.$store.state.user.parentRef.id
            : this.$store.state.user.id

          // Checking if the email of the user is used
          // by someone that is not a client.
          if (
            !this.clients.some(
              (client) =>
                client.email.toLowerCase() === this.clientEmail.toLowerCase()
            )
          ) {
            const result = await isNonClientByEmail(this.clientEmail, userId)
            if (result)
              return this.setSnackbar({
                position: 'top',
                type: 'error',
                message: this.$t('nonClientRegistered'),
              })
          }

          if (!this.existingClient) {
            // Checking if the email exists.
            const client = this.clients.find(
              ({ email }) => email === this.clientEmail
            )

            // If there is a client with the same email we show it to the user
            if (client) {
              // Checking if the client is already in the company for Signatures
              if (
                client.cifsRef.some((cifRef) => cifRef.id === this.cif.id) &&
                client.cifsPermissions[this.cif.numeroDocIdentidad]
                  .firmasActivas
              )
                return this.setSnackbar({
                  position: 'top',
                  type: 'error',
                  message: this.$t('clientAlreadyInTheCompany'),
                })

              this.existingClient = client
              return
            } else {
              // Setting the client data
              this.clientData = {
                email: this.clientEmail,
                name: this.name,
                surname: this.surname,
              }
            }
          } else {
            // Setting the data of the existing client
            this.clientData = { ...this.existingClient }
            await this.performAction(false) // Evitar redundancia de pantalla con los datos rellenados donde ya no hay que indicar nada más
          }
        }

        this.step = newStep
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * Removes the existing client
     */
    removeExistingClient() {
      this.existingClient = undefined
    },
    /**
     * Function to upload create the client
     */
    async performAction(validateForm = true) {
      if (validateForm)
        if (
          (this.isCreate || this.isUpdate) &&
          !this.$refs.clientForm?.validate()
        )
          return

      this.loading = true

      try {
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        if (this.isCreate) {
          // If only the client needs to be added
          if (!this.clientData.id)
            this.clientData.id = await createClient(
              userId,
              this.clientData.name,
              this.clientData.surname,
              this.clientData.email
            )

          await createCifWallet(userId, this.clientData.id, this.cif.id)
        } else if (this.isUpdate) {
          // If the name or the surname has changed we update it
          if (
            this.clientData.name !== this.client.name ||
            this.clientData.surname !== this.client.surname
          )
            await updateUserInfo(this.client.id, {
              name: this.clientData.name,
              surname: this.clientData.surname,
            })
        } else if (this.isDelete)
          await clientDenyResponsibility(userId, this.client.id, this.cif.id)

        const message = this.$t('clientActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser']),
    isCreate() {
      return this.action === 'create'
    },
    isUpdate() {
      return this.action === 'update'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    // Gives the dialog width depending on the screen
    dialogWidth() {
      if (this.step === 1 && !this.isDelete) return 1000
      if (this.isCreate || this.isUpdate) return 800
      if (this.isDelete) return 650
      return 500
    },
    clientHasAuthorized() {
      if (!this.cif.allowedToSign) return false

      const clientId = Object.keys(this.cif.allowedToSign).find(
        (clientId) => clientId === this.client?.id
      )
      return this.cif.allowedToSign[clientId]
    },
    certificateColor() {
      return certificateColor(this.wallet.certificateExpiration?.toDate())
    },
    certificateTitle() {
      return certificateTitle(this.wallet.certificateExpiration?.toDate())
    },
  },
  components: { AppAvatar },
}
</script>
