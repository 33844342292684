<template>
  <v-dialog :value="true" persistent :max-width="isDelete ? 570 : 1000">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{
              isDisplay
                ? automatedTask.name
                : $t(`automatedTasksActions.title.${action}`)
            }}
            <DialogButton
              v-if="isCreate"
              section="automatedTasks"
              subsection="creation"
            />
            <DialogButton
              v-if="isUpdate"
              section="automatedTasks"
              subsection="update"
            />
            <DialogButton
              v-if="isDelete"
              section="automatedTasks"
              subsection="deletion"
            />
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-tooltip bottom v-if="isAdminUser">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDisplay"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  class="primary secondary--text"
                  fab
                  small
                  @click="clickEditAutomatedTaskInfo"
                  id="edit-automated-task"
                >
                  <v-icon v-if="userIsEditing"> mdi-close </v-icon>
                  <v-icon v-else> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <div>
                {{
                  userIsEditing
                    ? $t('cancelUpdate')
                    : $t('automatedTasksActions.title.update')
                }}
              </div>
            </v-tooltip>
            <v-btn
              v-else
              icon
              small
              @click="closeDialog"
              id="close-automated-task-dialog"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="isDelete" class="body-1">
        <v-col align="center">
          <h2 class="mt-1">{{ automatedTask.name }}</h2>
          <span>{{ $t('deleteAutomatedTask') }}</span>
        </v-col>
      </v-card-text>

      <v-card-text v-else-if="isDisplay || isCreate || isUpdate">
        <v-form
          ref="automatedTaskForm"
          v-model="validAutomatedTaskForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row class="mt-4" align="baseline">
            <v-col cols="9">
              <v-text-field
                :disabled="loading || isDisplayAndUserIsNotEditing"
                :label="$t('automatedTaskName')"
                prepend-icon="mdi-robot"
                :value="name"
                @input="name = $event"
                :rules="[
                  notEmpty,
                  maxCharacters(maxChars),
                  isCreate
                    ? unique(automatedTasksNames, 'automatedTaskAlreadyExists')
                    : unique(
                        automatedTasksNamesWithoutCurrent,
                        'automatedTaskAlreadyExists'
                      ),
                ]"
                dense
                autocomplete="false"
                id="automated-task-name-input"
              />
            </v-col>
            <v-col cols="3">
              <v-checkbox
                class="my-checkbox mr-3 mt-0"
                on-icon="mdi-clock"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                v-model="temporalRestriction"
                :label="$t('temporalRestriction')"
                id="automated-tasks-temporal-restriction"
              >
              </v-checkbox>
            </v-col>
            <template v-if="temporalRestriction">
              <v-col cols="6" class="mt-0 mb-2">
                <v-menu
                  dense
                  class="ma-0 pa-0"
                  ref="initialDateMenu"
                  v-model="initialDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="initialDate"
                      :label="`${$t('initialDate')}`"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="automated-tasks-initial-date"
                      :disabled="loading || isDisplayAndUserIsNotEditing"
                      :rules="noRestrictionDateSelected ? [notEmpty] : []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="$refs.initialDateMenu.save(initialDate)"
                    v-model="initialDate"
                    :locale="$t('javascriptLocale')"
                    :min="new Date().toISOString().substring(0, 10)"
                    :max="maximumDate"
                    no-title
                    scrollable
                    :rules="[notEmpty]"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="mt-0 mb-2">
                <v-menu
                  dense
                  class="ma-0 pa-0"
                  ref="finalDateMenu"
                  v-model="finalDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finalDate"
                      :label="`${$t('finalDate')}`"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="automated-tasks-final-date"
                      :disabled="loading || isDisplayAndUserIsNotEditing"
                      :rules="noRestrictionDateSelected ? [notEmpty] : []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="$refs.finalDateMenu.save(finalDate)"
                    v-model="finalDate"
                    :locale="$t('javascriptLocale')"
                    :min="minimumDate"
                    no-title
                    scrollable
                    :rules="[notEmpty]"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </template>
            <v-col cols="12" lg="4" md="4">
              <v-row align="center">
                <v-autocomplete
                  dense
                  v-model="chosenAction"
                  :items="actions"
                  color="primary"
                  :filter="
                    (item, queryText) =>
                      customFilter(item, queryText, 'actions')
                  "
                  :no-data-text="$t('emptyActions')"
                  :label="$t('actionsLabel')"
                  item-value="name"
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  prepend-icon="mdi-play"
                  :menu-props="{ bottom: true, offsetY: true }"
                  id="automated-tasks-actions"
                  :rules="[notEmpty]"
                >
                  <template v-slot:item="{ item }">
                    <span :id="'automated-tasks-action-' + item.name">
                      {{ $t('actions.titles.' + item.name) }}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      small
                      class="ma-1"
                      :disabled="loading || isDisplayAndUserIsNotEditing"
                    >
                      {{ $t('actions.titles.' + item.name) }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <DialogButton
                  section="automatedTasks"
                  subsection="actions"
                  small
                  classStr="mb-3"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-row align="center">
                <v-autocomplete
                  dense
                  v-model="chosenConditions"
                  :items="possibleConditions"
                  color="primary"
                  :filter="
                    (item, queryText) =>
                      customFilter(item, queryText, 'conditions')
                  "
                  :no-data-text="$t('emptyConditions')"
                  :label="$t('conditionsLabel')"
                  item-value="name"
                  :disabled="
                    loading || !chosenAction || isDisplayAndUserIsNotEditing
                  "
                  prepend-icon="mdi-help"
                  :menu-props="{ bottom: true, offsetY: true }"
                  id="automated-tasks-conditions"
                  :rules="[listNotEmpty]"
                  multiple
                  clearable
                >
                  <template v-slot:item="{ item }">
                    <span :id="'automated-tasks-conditions-' + item.name">
                      {{ $t('conditions.titles.' + item.name) }}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      small
                      class="ma-1"
                      close
                      @click:close="remove(item.name, 'chosenConditions')"
                      :disabled="loading || isDisplayAndUserIsNotEditing"
                      :id="'selected-automated-tasks-conditions-' + item.name"
                    >
                      {{ $t('conditions.titles.' + item.name) }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <DialogButton
                  section="automatedTasks"
                  subsection="conditions"
                  small
                  classStr="mb-3"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-row align="center">
                <v-autocomplete
                  dense
                  v-model="chosenTargets"
                  :items="possibleTargets"
                  color="primary"
                  :filter="
                    (item, queryText) =>
                      customFilter(item, queryText, 'targets')
                  "
                  :no-data-text="$t('emptyTargets')"
                  :label="$t('targetsLabel')"
                  item-text="name"
                  item-value="name"
                  :disabled="
                    loading ||
                    !chosenAction ||
                    isDisplayAndUserIsNotEditing ||
                    actionHasNoTargets
                  "
                  prepend-icon="mdi-target"
                  :menu-props="{ bottom: true, offsetY: true }"
                  id="automated-tasks-targets"
                  :rules="actionHasNoTargets ? [] : [listNotEmpty]"
                  multiple
                  clearable
                >
                  <template v-slot:item="{ item }">
                    <span :id="'automated-tasks-targets-' + item.name">
                      {{ $t('targets.titles.' + item.name) }}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      small
                      class="ma-1"
                      close
                      @click:close="remove(item.name, 'chosenTargets')"
                      :disabled="loading || isDisplayAndUserIsNotEditing"
                    >
                      {{ $t('targets.titles.' + item.name) }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <DialogButton
                  section="automatedTasks"
                  subsection="targets"
                  small
                  classStr="mb-3"
                />
              </v-row>
            </v-col>
            <v-col cols="12" v-if="chosenConditions?.length">
              <h3
                v-if="
                  chosenConditions.length > 1 ||
                  (chosenConditions[0] !== 'metadataLoaded' &&
                    chosenConditions[0]?.name !== 'metadataLoaded' &&
                    chosenConditions[0] !== 'notificationIsNotOpen' &&
                    chosenConditions[0]?.name !== 'notificationIsNotOpen')
                "
              >
                <v-icon> mdi-help </v-icon>
                {{ $t('conditionsAdditionalData') }}
              </h3>
            </v-col>
            <template v-for="(chosenCondition, i) in chosenConditions">
              <ConditionAdditionalDataInput
                :key="'condition-additional-data-' + i"
                :chosenCondition="
                  getObjectFromString(chosenCondition, 'conditions')
                "
                :conditions="conditions"
                :cifsProp="cifs"
                :filters="filters"
                :groupsProp="groups"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                :totalAdditionalFields="chosenConditions?.length"
                :fieldIndex="i"
                @setData="
                  (data) =>
                    setObjectAdditionalData(
                      chosenCondition,
                      data,
                      'conditionsAdditionalData'
                    )
                "
              />
            </template>
            <v-col
              cols="12"
              v-if="
                chosenTargets?.length &&
                getObjectFromString(chosenTargets[0], 'targets').inputType !==
                  'no-input'
              "
            >
              <h3>
                <v-icon> mdi-target </v-icon>
                {{ $t('targetAdditionalData') }}
              </h3>
            </v-col>
            <template v-for="(chosenTarget, i) in chosenTargets">
              <TargetAdditionalDataInput
                :key="'target-additional-data-' + i"
                :chosenTarget="getObjectFromString(chosenTarget, 'targets')"
                :chosenConditions="chosenConditions"
                :targets="targets"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                :totalAdditionalFields="chosenTargets?.length"
                :fieldIndex="i"
                :operators="operators"
                :filters="filters"
                :groups="groups"
                :conditionsAdditionalData="conditionsAdditionalData"
                @setData="
                  (data) =>
                    setObjectAdditionalData(
                      chosenTarget,
                      data,
                      'targetsAdditionalData'
                    )
                "
              />
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center" v-if="isAdminUser">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-automated-task-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || isDisplayAndUserIsNotEditing"
          @click="performAction"
          id="confirm-automated-task"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import {
  createAutomatedTask,
  deleteAutomatedTask,
  updateAutomatedTask,
} from '@/services/automated-tasks-service'
import TargetAdditionalDataInput from '@/components/automated-tasks/TargetAdditionalDataInput'
import ConditionAdditionalDataInput from '@/components/automated-tasks/ConditionAdditionalDataInput'
import DialogButton from '@/components/documentation/buttons/DialogButton'

export default {
  components: {
    TargetAdditionalDataInput,
    ConditionAdditionalDataInput,
    DialogButton,
  },
  props: {
    automatedTasks: Array,
    automatedTask: Object,
    action: String,
    actions: Array,
    conditions: Array,
    targets: Array,
    cifs: Array,
    operators: Array,
    filters: Array,
    groups: Array,
  },
  mounted() {
    if (Object.keys(this.automatedTask).length > 0) {
      this.name = this.automatedTask.name
      this.chosenAction = this.automatedTask.action
      this.chosenConditions = this.automatedTask.conditions
      this.chosenTargets = this.automatedTask.targets

      if (this.automatedTask.temporalRestriction) {
        this.temporalRestriction = this.automatedTask?.temporalRestriction
        this.initialDate = this.automatedTask?.initialDate
        this.finalDate = this.automatedTask?.finalDate
      }

      // Setting the additional data
      for (const condition of this.chosenConditions)
        this.$set(this.conditionsAdditionalData, condition.name, condition.data)
      for (const target of this.chosenTargets)
        this.$set(this.targetsAdditionalData, target.name, target.data)
    }
  },
  data() {
    return {
      validAutomatedTaskForm: false,
      maxChars: 100,
      loading: false,
      userIsEditing: false,
      name: undefined,
      chosenAction: undefined,
      chosenConditions: undefined,
      chosenTargets: undefined,
      temporalRestriction: false,
      initialDate: '',
      initialDateMenu: false,
      finalDate: '',
      finalDateMenu: false,
      targetsAdditionalData: {},
      conditionsAdditionalData: {},
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Removed a chip
     * @param {Object} item Item to remove.
     */
    remove(item, subject) {
      const index = this[subject].findIndex((ob) =>
        typeof ob === 'object' ? ob.name === item : ob === item
      )

      // If we are removing a condition, we remove the additional data
      if (subject === 'chosenConditions')
        delete this.conditionsAdditionalData[item]

      if (index >= 0) this[subject].splice(index, 1)
    },
    /**
     * Closes the dialog
     */
    closeDialog() {
      this.$emit('closeAutomatedTaskDialog')
    },
    /**
     * When the pencil button is clicked, the user is allowed to edit.
     */
    clickEditAutomatedTaskInfo() {
      this.userIsEditing = !this.userIsEditing
    },
    /**
     * Performs the action of the dialog (create, update or delete).
     */
    async performAction() {
      if (!this.isDelete && !this.$refs.automatedTaskForm?.validate()) return

      this.loading = true

      const adaptedTargets = this.chosenTargets?.map((target) => {
        const name = typeof target === 'object' ? target.name : target
        return {
          name,
          data: this.targetsAdditionalData[name] ?? '',
        }
      })

      const adaptedConditions = this.chosenConditions?.map((condition) => {
        const name = typeof condition === 'object' ? condition.name : condition
        return {
          name,
          data: this.conditionsAdditionalData[name] ?? '',
        }
      })

      try {
        if (this.isCreate) {
          await createAutomatedTask({
            name: this.name,
            action: this.chosenAction,
            conditions: adaptedConditions ?? [],
            targets: adaptedTargets ?? [],
            temporalRestriction: this.temporalRestriction,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            active: true,
          })
        } else if (this.isUpdate || this.isDisplay) {
          await updateAutomatedTask(this.automatedTask.id, {
            name: this.name,
            action: this.chosenAction,
            conditions: adaptedConditions ?? [],
            targets: adaptedTargets ?? [],
            temporalRestriction: this.temporalRestriction,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
          })
        } else if (this.isDelete) {
          await deleteAutomatedTask(this.automatedTask.id)
        }

        const message = this.$t('automatedTasksActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * Checks if the given item fullfils the conditions given the querytext.
     * @param {Object} item Item to check.
     * @param {String} queryText Message introduced by the user.
     * @param {String} subject Which object to check (actions, conditions or targets).
     * @returns True if the item contains the text.
     */
    customFilter(item, queryText, subject) {
      const name = this.$t(subject + '.titles.' + item).toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.includes(searchText)
    },
    /**
     * Given a list of objects with names, it returns a new list of just names.
     * @param {Array} list List that contains objects with names.
     * @returns Array with the names of the given list.
     */
    getNames(list) {
      return list.map((item) => item.name)
    },
    /**
     * Gets the full object given a name.
     * @param {String} objectName Object name.
     * @param {String} key Key where the objects are stored.
     * @returns Object.
     */
    getObjectFromString(objectName, key) {
      if (typeof objectName === 'object') return objectName

      const object = this[key].find((object) => object.name === objectName)
      return object ?? {}
    },
    /**
     * Sets the data of an specific target
     * @param {String} target Name of the target.
     * @param {any} data Data to set.
     * @param {String} key Key where the objects are stored.
     */
    setObjectAdditionalData(target, data, key) {
      const name = typeof target === 'object' ? target.name : target
      this.$set(this[key], name, data)
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    isCreate() {
      return this.action === 'create'
    },
    isUpdate() {
      return this.action === 'update'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    noRestrictionDateSelected() {
      return this.initialDate === '' && this.finalDate === ''
    },
    isDisplayAndUserIsNotEditing() {
      return this.action === 'display' && !this.userIsEditing
    },
    actionHasNoTargets() {
      const action = this.actions.find((a) => a.name === this.chosenAction)

      if (!action) return true

      return action.allowedTargetTypes.length === 0
    },
    possibleTargets() {
      const targets = []
      if (!this.chosenAction) return []

      // Getting the action
      const action = this.actions.find(
        (action) => action.name === this.chosenAction
      )

      // Getting the allowed target types of the action
      for (const allowedTargetType of action.allowedTargetTypes) {
        const target = this.targets.find(
          (target) => target.name === allowedTargetType
        )
        if (target) targets.push(target)
      }
      return targets
    },
    possibleConditions() {
      if (!this.chosenAction) return []

      const conditions = []

      const action = this.actions.find(
        (action) => action.name === this.chosenAction
      )

      // Getting the allowed conditions of the actions
      for (const allowedConditionType of action.allowedConditionsTypes) {
        const condition = this.conditions.find(
          (condition) => condition.name === allowedConditionType
        )
        if (condition) conditions.push(condition)
      }

      return conditions
    },
    automatedTasksNames() {
      return this.automatedTasks.map((m) => m.name)
    },
    automatedTasksNamesWithoutCurrent() {
      return this.automatedTasks
        .map((m) => m.name)
        .filter((name) => name !== this.automatedTask.name)
    },
    minimumDate() {
      if (!this.initialDate) return new Date().toISOString().substring(0, 10)
      else return new Date(this.initialDate).toISOString().substring(0, 10)
    },
    maximumDate() {
      if (!this.finalDate) return null
      else return new Date(this.finalDate).toISOString().substring(0, 10)
    },
  },
  watch: {
    chosenAction: function (newValue, previousValue) {
      if (!previousValue) return

      if (newValue !== previousValue) {
        this.chosenConditions = undefined
        this.chosenTargets = undefined
      }
    },
    chosenConditions: function (newValue, previousValue) {
      if (!previousValue) return

      if (
        newValue !== previousValue &&
        newValue?.length > previousValue?.length
      ) {
        let count = 0
        newValue.forEach((c) => (c.includes('afterXDays') ? count++ : null))

        if (count > 1) newValue.pop()
      }
    },
    initialDate: function (newValue) {
      const iD = new Date(newValue)
      const fD = this.finalDate ? new Date(this.finalDate) : new Date()
      if (iD.getTime() > fD.getTime()) this.finalDate = ''
    },
    finalDate: function (newValue) {
      const iD = this.initialDate ? new Date(this.initialDate) : new Date()
      const fD = new Date(newValue)
      if (iD.getTime() > fD.getTime()) this.initialDate = ''
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
