<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          <v-col cols="12" align="center">
            <div>
              {{ $t('alternativeCertificates') }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <AppSkeleton :condition="loading" type="historyList">
        <template v-slot:skeleton-content>
          <v-card-text v-if="alternativeCertificates.length > 0">
            <v-list height="450" class="overflow-y-auto">
              <v-list-item
                v-for="certificate in certificates"
                :key="certificate.id"
                @click="openCertificateDialog(certificate, 'display')"
              >
                <v-row align="center">
                  <v-list-item-icon class="mr-4">
                    <AppAvatar
                      :userName="certificate.name"
                      avatarColor="primary"
                      initialsColor="secondary"
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ certificate.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <!-- En caso de que el ID sea NO_ID, para evitar descuadre mostramos un 'caracter invisible' -->
                      {{
                        certificate.numeroDocIdentidad !== 'NO_ID'
                          ? certificate.numeroDocIdentidad
                          : '‎'
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-col class="d-flex justify-end">
                      <v-tooltip bottom v-if="hasWarning(certificate)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            :color="certificateColor(certificate)"
                            id="warning-no-certificate"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <div>
                          {{ certificateTitle(certificate) }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            elevation="1"
                            icon
                            @click.stop="
                              openCertificateDialog(certificate, 'update')
                            "
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('updateCert') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            elevation="1"
                            icon
                            @click.stop="
                              openCertificateDialog(certificate, 'delete')
                            "
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('removeCert') }}
                        </div>
                      </v-tooltip>
                    </v-col>
                  </v-list-item-content>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-text style="text-align: center" v-else>
            <span>
              {{ $t('noCertsCollaborators') }}
            </span>
          </v-card-text>
        </template>
      </AppSkeleton>
      <v-card-actions class="headline justify-center">
        <v-btn
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text"
          @click="openCertificateDialog(null, 'create')"
          id="add-certificate"
        >
          {{ $t('addCert') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog to create/update an specific certificate -->
    <SettingsCertificateDialog
      v-if="certificateDialog"
      :certificate="selectedCertificate"
      :action="action"
      @closeCertificateDialog="closeCertificateDialog"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { ninetyDays } from '@/utils/date-utils'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'
import SettingsCertificateDialog from './SettingsCertificateDialog.vue'

export default {
  props: {
    alternativeCertificates: Array,
  },
  components: { SettingsCertificateDialog },
  data() {
    return {
      certificateDialog: false,
      selectedCertificate: undefined,
      action: '',
      loading: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  methods: {
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeAlternativeCertDialog')
    },
    openCertificateDialog(certificate, action) {
      this.selectedCertificate = certificate
      this.action = action
      this.certificateDialog = true
    },
    /**
     * Function to close the dialog of an specific certificate
     */
    closeCertificateDialog() {
      this.selectedCertificate = false
      this.certificateDialog = undefined
    },
    /**
     * Checks if the certificate is expired or near to expirate
     * @param {string} certificate The certificate object.
     */
    hasWarning(certificate) {
      const certificateExpiration = certificate.expiration

      if (!certificateExpiration) return false

      const certExpiration = certificateExpiration
        .toDate()
        .toISOString()
        .slice(0, 10)

      return certExpiration < ninetyDays()
    },
    certificateColor(certificate) {
      return certificateColor(certificate.expiration.toDate())
    },
    certificateTitle(certificate) {
      return certificateTitle(certificate.expiration.toDate())
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    certificates() {
      return this.alternativeCertificates
        .map((o) => o)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}
</script>
