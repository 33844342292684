<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.addExtraDocument.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.addExtraDocument.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.notifications.addExtraDocument.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.notifications.addExtraDocument.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic4Name" />
    <p>
      {{ $t('documentation.texts.notifications.addExtraDocument.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic5Name" />

    <p>
      {{ $t('documentation.texts.notifications.addExtraDocument.paragraph5') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic6Name" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picNotifDetail'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picNotifDetailCloseUp'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picAddExtraDoc'
        ) +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picAddedExtraDoc'
        ) +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picDeleteFile'
        ) +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addExtraDocument.picHistoryDelete'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
