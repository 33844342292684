<template>
  <v-card class="pa-5" elevation="0">
    <vue-pdf-embed :source="pdfsrc" />
  </v-card>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    blob: Blob,
  },
  computed: {
    pdfsrc() {
      return URL.createObjectURL(this.blob)
    },
  },
}
</script>
<style lang="scss" scoped></style>
