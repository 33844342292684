<template>
  <v-btn text @click="clickItemButton">
    <v-badge
      v-if="includeBadge && unreadNotifications > 0"
      :color="color"
      :content="unreadNotifications"
    >
      <v-icon :color="color"> {{ item.icon }} </v-icon>
    </v-badge>
    <v-icon :color="color" :id="'app-bar-' + item.title">
      {{ item.icon }}
    </v-icon>
    <template v-if="includeTitle">
      <span :class="color + '--text'">{{
        $t(`navigationPage.${item.title}`)
      }}</span>
    </template>
    <div v-if="this.$store.state.pending[item.title]" class="pending-action" />
  </v-btn>
</template>

<script>
export default {
  props: {
    item: Object,
    clickButton: Function,
    includeBadge: Boolean,
    includeTitle: Boolean,
    includeIcon: Boolean,
    color: String,
  },
  methods: {
    clickItemButton() {
      if (this.$store.state.loading) return
      if (this.clickButton) this.clickButton()
      else this.$router.push(this.item.routerData)
    },
  },
  computed: {
    unreadNotifications() {
      if (!this.$store.state.notifications) return 0
      return this.$store.state.notifications.filter((obj) => obj.read === false)
        .length
    },
  },
}
</script>

<style>
.pending-action {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: white;
  animation-name: fade;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  margin-left: 5px;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
