<template>
  <v-dialog :value="true" persistent :max-width="570">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{ title }}
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        {{ text }}
        <v-form
          ref="groupForm"
          v-model="validGroupForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-col>
            <AppSkeleton :condition="userGroup === undefined" type="item">
              <template v-slot:skeleton-content>
                <h3>
                  {{ userGroup.name }}
                </h3>
              </template>
            </AppSkeleton>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-group-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || groupNotLoaded"
          @click="performAction"
          id="confirm-group"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import { addCifToGroups, listUserGroups } from '@/services/groups-service'
import { cancelDeletion } from '@/services/cifs-service'
import getErrorText from '@/utils/get-error-text'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    objectId: {
      type: String,
      default: '',
    },
    action: String,
  },
  async mounted() {
    const groups = await listUserGroups()

    // Now operators can only have one group so we take the first one
    setTimeout(() => {
      this.userGroup = groups[0]
    }, 800)
  },
  data() {
    return {
      validGroupForm: false,
      loading: false,
      userGroup: undefined,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    async performAction() {
      try {
        if (!this.$refs.groupForm?.validate()) return

        // Adding an object to group
        if (this.action === 'addCifToGroup') {
          await addCifToGroups(this.objectId, [this.userGroup.id])
          await cancelDeletion(
            this.$store.state.user.parentRef.id,
            this.objectId
          )
        }

        const message = this.$t('companiesActions.success.create')
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    groupNotLoaded() {
      return this.userGroup === undefined
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
