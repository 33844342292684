<template>
  <v-col style="max-width: 1200px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.configuration.title') }}
    </h1>
    <p>
      {{ $t('documentation.texts.configuration.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 300px" contain :src="pic1Name" />
    <br />
    <p v-if="isAdminUser && isNotificacionesActivas">
      {{ $t('documentation.texts.configuration.paragraph2') }}
    </p>
    <p v-else>
      {{ $t('documentation.texts.configuration.paragraph2-1') }}
    </p>
    <v-img
      v-if="isAdminUser && isNotificacionesActivas"
      style="width: 100%; max-width: 1200px"
      contain
      :src="pic2Name"
    />
    <v-img
      v-else
      style="width: 100%; max-width: 1200px"
      contain
      :src="pic3Name"
    />
    <p></p>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdminUser', 'isNotificacionesActivas']),
    pic1Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.picConfigAccess') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.picConfig2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/configuration/' +
        this.$t('documentation.texts.configuration.picConfig3') +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
