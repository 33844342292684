<template>
  <v-container
    fill-height
    fluid
    style="display: flex; flex-direction: column; align-content: start"
  >
    <v-row
      v-if="!isMobile"
      style="
        display: flex;
        flex-direction: row;
        align-content: start;
        width: 100%;
      "
    >
      <v-col cols="3 pa-0 ma-0" no-gutters>
        <DocumentationIndex />
      </v-col>
      <v-col cols="9 pa-0 ma-0 pl-9">
        <DocumentationContent />
      </v-col>
    </v-row>
    <v-col v-else>
      <DocumentationIndex />
      <DocumentationContent />
    </v-col>
  </v-container>
</template>

<script>
import DocumentationIndex from '@/components/documentation/DocumentationIndex'
import DocumentationContent from '@/components/documentation/DocumentationContent'
import { mapGetters } from 'vuex'
export default {
  components: {
    DocumentationIndex,
    DocumentationContent,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>
