<template>
  <v-textarea
    v-model="text"
    :label="$t('text')"
    :disabled="disabled"
    :rules="[notEmpty]"
    counter
    no-resize
    id="observation-text"
    rows="5"
  />
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    operators: Array,
    disabled: Boolean,
  },
  data() {
    return {
      text: '',
    }
  },
  methods: {
    ...rules,
  },
  watch: {
    text: function (newValue) {
      if (newValue) this.$emit('setObservation', this.text)
    },
  },
}
</script>
