<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.uploadFiles.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.uploadFiles.paragraph1') }}
    </p>
    <p>
      {{ $t('documentation.texts.notifications.uploadFiles.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 250px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.uploadFiles.paragraph3') }}
    </p>
    <v-img style="width: 100%; max-width: 700px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.notifications.uploadFiles.paragraph4') }}
    </p>
    <ul>
      <li>
        <p>
          <b>
            {{ $t('documentation.texts.notifications.uploadFiles.li1Title') }}:
          </b>
          {{ $t('documentation.texts.notifications.uploadFiles.li1') }}
        </p>
      </li>
      <li>
        <p>
          <b>
            {{ $t('documentation.texts.notifications.uploadFiles.li2Title') }}:
          </b>
          {{ $t('documentation.texts.notifications.uploadFiles.li2') }}
        </p>
      </li>
      <li>
        <p>
          <b>
            {{ $t('documentation.texts.notifications.uploadFiles.li3Title') }}:
          </b>
          {{ $t('documentation.texts.notifications.uploadFiles.li3') }}
        </p>
      </li>
    </ul>
    <p>
      {{ $t('documentation.texts.notifications.uploadFiles.paragraph5') }}
    </p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t('documentation.texts.notifications.uploadFiles.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t('documentation.texts.notifications.uploadFiles.pic2') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
