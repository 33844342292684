<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.groups.delete.title') }}
    </h1>
    <p>{{ $t('documentation.texts.groups.delete.p1') }}</p>
    <v-img
      style="
        width: 100%;
        max-width: 860px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p>{{ $t('documentation.texts.groups.delete.p2') }}</p>
    <v-img
      style="
        width: 100%;
        max-width: 460px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic2Name"
    />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/groups/' +
        this.$t('documentation.texts.groups.delete.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/groups/' +
        this.$t('documentation.texts.groups.delete.pic2') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
