<template>
  <v-card>
    <v-card-title :class="isMobile ? 'ma-0 pa-0' : ''">
      <v-container fluid>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          v-if="!isMobile"
        >
          <v-col cols="4" no-gutters>
            <v-row align="baseline">
              <span class="my-0 mx-2">
                {{ $t('navigationPage.signatureList') }}
              </span>
              <span
                v-if="!loadAllSignatures"
                class="my-0 subtitle-2 text--secondary"
              >
                {{ $t('last90Days') }}
              </span>
              <v-btn
                data-v-step="202"
                v-if="!loadAllSignatures"
                rounded
                small
                :loading="loadingAll"
                class="mx-2 secondary--text"
                color="primary"
                :disabled="false"
                @click="loadAll"
              >
                {{ $t('loadPrevious') }}
              </v-btn>
              <DialogButton section="signatures" subsection="signatureList" />
            </v-row>
          </v-col>
          <v-col cols="4" no-gutters>
            <AppPlanProgress
              icon="mdi-draw"
              v-if="!isClienteDespachoUser"
              :current="signaturesUsed"
              :max="signaturesMax"
            />
          </v-col>
          <v-col cols="2" no-gutters>
            <v-text-field
              data-v-step="203"
              class="my-0 py-0"
              v-model="search"
              @change="searchChange"
              clearable
              prepend-icon="mdi-magnify"
              :label="$t('searchField')"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom v-if="!isClienteDespachoUser">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  class="primary secondary--text ma-1"
                  v-bind="attrs"
                  v-on="on"
                  id="request-signature"
                  @click.stop="openSignatureRequest"
                >
                  <v-icon> mdi-file-sign </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('navigationPage.createSignature') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-col v-else>
          <v-row class="mb-1" align="baseline">
            <span class="my-0 mx-2">
              {{ $t('navigationPage.signatureList') }}
            </span>
            <span
              v-if="!loadAllSignatures"
              class="my-0 subtitle-2 text--secondary"
            >
              {{ $t('last90Days') }}
            </span>
          </v-row>
          <v-row class="mb-1 d-flex justify-end">
            <v-btn
              v-if="!loadAllSignatures"
              rounded
              small
              :loading="loadingAll"
              class="mx-2 secondary--text"
              color="primary"
              :disabled="false"
              @click="loadAll"
            >
              {{ $t('loadPrevious') }}
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="my-0 py-0"
              v-model="search"
              @change="searchChange"
              clearable
              prepend-icon="mdi-magnify"
              :label="$t('searchField')"
              single-line
              hide-details
            >
            </v-text-field>
          </v-row>
          <v-row class="mt-3 d-flex justify-center">
            <v-col>
              <AppPlanProgress
                class="ml-4 mr-4"
                icon="mdi-draw"
                textSize="h5"
                :current="signaturesUsed"
                :max="signaturesMax"
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-3 d-flex justify-center"
            v-if="!isClienteDespachoUser"
          >
            <v-col align="center">
              <v-btn
                elevation="1"
                fab
                small
                class="primary secondary--text ma-1"
                id="request-signature"
                @click.stop="openSignatureRequest"
              >
                <v-icon> mdi-file-sign </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      <v-spacer></v-spacer>
    </v-card-title>
    <AppSkeleton data-v-step="201" :condition="loading" type="signatureList">
      <template v-slot:skeleton-content>
        <v-data-table
          :headers="headers"
          :items="filteredSignatures"
          :items-per-page="5"
          class="elevation-1"
          @click:row="openSignatureDetail"
          :footer-props="{ 'items-per-page-options': [5, 25, 50 /*-1*/] }"
          :options="options"
          @update:options="optionsChange"
        >
          <template v-slot:[`item.alert`]="{ item }">
            <v-container>
              <v-row align="center">
                <AlertIcon :large="true" v-if="item.alert" :alert="item.alert">
                </AlertIcon>
                <AlertDialog
                  v-else-if="!isClienteDespachoUser"
                  :asset="item"
                  :isDisabled="isClienteDespachoUser"
                  objectType="signatures"
                />
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.requester`]="{ item }">
            <span class="text-wrap" v-if="item.requester">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <AppAvatar
                      v-if="item.requester"
                      :userName="`${item.requester.name} ${item.requester.surname}`"
                      :userAvatar="item.requester.avatarURL"
                      avatarSize="30"
                      fab
                      small
                      iconSize="24"
                      avatarColor="primary"
                      initialsColor="secondary"
                    />
                  </span>
                </template>
                <div width="300">
                  {{ `${item.requester.name} ${item.requester.surname}` }}
                </div>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:[`item.cif`]="{ item }">
            <span class="text-wrap" v-if="item.cif" id="select-signature">
              {{ item.cif.numeroDocIdentidad }}
            </span>
          </template>
          <template v-slot:[`item.signatureType`]="{ item }">
            <span class="text-wrap">
              {{ $t(item.signatureType) }}
            </span>
            <small v-if="item.signatureType === 'joint'">
              ({{ item.signaturesRequired }}
              {{ $t('signaturesRequiredShort').toLowerCase() }})
            </small>
          </template>
          <template v-slot:[`item.purpose`]="{ item }">
            <v-tooltip
              max-width="400"
              :disabled="!item.observationRequester"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <span class="text-wrap" v-bind="attrs" v-on="on">
                  {{ item.purpose }}
                </span>
              </template>
              <span>
                <b>{{ $t('observation') + ': ' }} </b>
                {{ item.observationRequester }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span class="text-wrap">
              {{ $d(item.createdAt) }}
            </span>
          </template>
          <template v-slot:[`item.dateDeferred`]="{ item }">
            <span v-if="item.dateDeferred" class="text-wrap">
              {{ $d(item.dateDeferred) }}
            </span>
          </template>
          <template v-slot:[`item.dateLimit`]="{ item }">
            <span class="text-wrap">
              {{ $d(item.dateLimit) }}
            </span>
          </template>
          <template v-slot:[`item.documents`]="{ item }">
            <v-row v-if="item.documents">
              <div
                class="icon-container"
                :set="(docsToSign = numDocumentsToSign(item.documents))"
              >
                <v-icon large color="green darken-2">
                  mdi-file-document-edit
                </v-icon>
                <div class="badge">
                  {{ docsToSign }}
                </div>
              </div>
              <div
                class="icon-container"
                :set="(docsAttached = numDocumentsAttached(item.documents))"
              >
                <v-icon large :color="docsAttached ? 'blue darken-2' : 'grey'">
                  mdi-file-document-plus
                </v-icon>
                <div class="badge" v-if="docsAttached">
                  {{ docsAttached }}
                </div>
              </div>
            </v-row>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-container>
              <v-row>
                <AlertIcon v-if="item.alert && isMobile" :alert="item.alert">
                </AlertIcon>
                <v-chip
                  v-if="getSignatureStatus(item).color"
                  class="ma-2 white--text"
                  :color="getSignatureStatus(item).color"
                  id="enter-signature-detail"
                >
                  {{ $t(getSignatureStatus(item).status) }}
                </v-chip>
                <span v-else> {{ getSignatureStatus(item) }}</span>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </template>
    </AppSkeleton>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import {
  getSignatureStatus,
  listAllSignatures,
} from '@/services/signatures-service'
import AlertDialog from '@/components/alert/AlertDialog'
import AlertIcon from '@/components/alert/AlertIcon'
import AppPlanProgress from '@/components/commons/AppPlanProgress'

export default {
  props: {
    loading: Boolean,
    signaturesMax: Number,
    signaturesUsed: Number,
  },
  components: {
    DialogButton,
    AlertDialog,
    AlertIcon,
    AppPlanProgress,
  },
  data() {
    return {
      loadAllSignatures: false,
      loadingAll: false,
      search: '',
      options: {},
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser', 'isClienteDespachoUser']),
    signatures() {
      return this.loadAllSignatures
        ? this.allSignatures
        : this.$store.state.signatures
    },
    headers() {
      return this.isMobile
        ? [
            {
              text: this.$t('signatureProperties.requester'),
              value: 'requester',
            },
            { text: this.$t('companyCif'), value: 'cif' },
            { text: this.$t('company'), value: 'cif.empresa' },
            {
              text: this.$t('signatureProperties.signatureType'),
              value: 'signatureType',
            },
            {
              text: this.$t('signatureProperties.purpose'),
              value: 'purpose',
              width: '300px',
            },
            { text: this.$t('signatureProperties.status'), value: 'status' },
            {
              text: this.$t('signatureProperties.dateDeferred'),
              value: 'dateDeferred',
            },
            {
              text: this.$t('signatureProperties.dateLimit'),
              value: 'dateLimit',
            },
          ]
        : [
            { text: 'ID', value: 'id', sortable: false },
            {
              text: this.$t('alert'),
              value: 'alert',
              sort: (a, b) =>
                a && b ? b.date - a.date : a && !b ? -1 : !a && b ? 1 : 0,
            },
            {
              text: this.$t('signatureProperties.requester'),
              value: 'requester',
            },
            { text: this.$t('companyCif'), value: 'cif' },
            { text: this.$t('company'), value: 'cif.empresa' },
            {
              text: this.$t('signatureProperties.signatureType'),
              value: 'signatureType',
            },
            {
              text: this.$t('signatureProperties.purpose'),
              value: 'purpose',
              width: '300px',
            },
            { text: this.$t('signatureProperties.status'), value: 'status' },
            {
              text: this.$t('signatureProperties.createdAt'),
              value: 'createdAt',
            },
            {
              text: this.$t('signatureProperties.dateDeferred'),
              value: 'dateDeferred',
            },
            {
              text: this.$t('signatureProperties.dateLimit'),
              value: 'dateLimit',
            },
            { text: this.$t('documents'), value: 'documents' },
          ]
    },
    filteredSignatures() {
      return this.signatures.filter((elem) => {
        // String de búsqueda en mayúscula
        const searchCap = this.search ? this.search.toUpperCase() : ''

        // Obtenemos los elementos de la solicitud de firma
        const {
          id,
          requester,
          clientName,
          clientLastName,
          cif,
          purpose,
          createdAt,
          dateDeferred,
          dateLimit,
        } = elem

        // Guardamos los elementos en mayúscula
        let dataCap = [
          id?.toUpperCase(),
          clientName?.toUpperCase(),
          clientLastName?.toUpperCase(),
          purpose?.toUpperCase(),
          this.$t(getSignatureStatus(elem).text).toUpperCase(),
          this.$d(createdAt, 'i18nDate').toUpperCase(),
          this.$d(dateDeferred, 'i18nDate').toUpperCase(),
          this.$d(dateLimit, 'i18nDate').toUpperCase(),
        ]
        // Requester
        const requesterData = requester
          ? [
              requester.email.split('@')[0].toUpperCase(),
              `${requester.name} ${requester.surname}`.toUpperCase(),
            ]
          : false
        if (requesterData) dataCap = [...dataCap, ...requesterData]

        // Cif
        const cifData = cif
          ? [cif.numeroDocIdentidad.toUpperCase(), cif.empresa.toUpperCase()]
          : false
        if (cifData) dataCap = [...dataCap, ...cifData]

        // Filtramos por dataCap
        for (let i = 0; i < dataCap.length; i++)
          if (dataCap[i]?.includes(searchCap)) return elem
      })
    },
  },
  methods: {
    ...mapMutations(['setSignaturesListOptions', 'setSignaturesSearch']),
    getSignatureStatus(signature) {
      return getSignatureStatus(signature)
    },
    numDocumentsToSign(documents) {
      return documents.filter((file) => file.needSignature).length
    },
    numDocumentsAttached(documents) {
      return documents.filter((file) => !file.needSignature).length
    },
    optionsChange(options) {
      this.setSignaturesListOptions(options)
    },
    searchChange(search) {
      this.setSignaturesSearch(search ? search : '') //If clearable, store empty string instead of null
    },
    async loadAll() {
      this.loadingAll = true
      this.allSignatures = await listAllSignatures(
        this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id,
        this.$store.state.user
      )

      this.loadAllSignatures = true
      this.loadingAll = false
    },
    async openSignatureDetail(value) {
      this.$router.push({
        name: 'SignatureDetailView',
        params: { id: value.id },
      })
    },
    async openSignatureRequest() {
      this.$router.push({
        name: 'CreateSignatureRequest',
      })
    },
  },
  created() {
    this.options = this.$store.state.signaturesListOptions
    this.search = this.$store.state.signaturesSearch
  },
}
</script>

<style lang="scss" scoped>
.icon-container {
  position: relative;
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
</style>
