<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            {{ title }}
          </v-card-title>
          <OperatorsTable
            :loading="false"
            :headers="headers"
            :mobileHeaders="mobileHeaders"
            :operators="operators"
          ></OperatorsTable>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="close">
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import OperatorsTable from '@/components/operators/OperatorsTable'

export default {
  components: {
    OperatorsTable,
  },
  props: {
    showDialog: Boolean,
    operators: [],
    title: String,
  },
  data() {
    return {
      headers: ['avatar', 'name'],
      mobileHeaders: ['avatar', 'name'],
      mustBeShow: this.showDialog,
    }
  },
  computed: {
    show() {
      return this.showDialog
    },
  },
  methods: {
    close() {
      this.mustBeShow = false
      this.$emit('dialog-closed')
    },
  },
  created() {},
  watch: {},
}
</script>
