<template>
  <v-footer dark padless>
    <v-card class="flex primary text-center" flat tile>
      <AppFooterVersion />
      <template v-if="isMobile">
        <v-card-text>
          <strong class="secondary--text"> {{ $t('addaliaFooter') }} </strong>
          <v-spacer />
          <AppFooterSocials />
        </v-card-text>
        <v-divider class="ma-0" />
        <v-card-text class="py-2 text-center secondary--text">
          <strong>Addalia Technology ©</strong> —v{{
            $store.state.localAppVersion
          }}
          <br /><br />
          <span class="legal-link" @click="getInfoPage('LegalAdvice')">
            {{ $t('legalAdvice') }}
          </span>
          •
          <span class="legal-link" @click="getInfoPage('PrivacyPolicy')">
            {{ $t('privacyPolicy') }}
          </span>
          •
          <span class="legal-link" @click="getInfoPage('CookiesPolicy')">
            {{ $t('cookiesPolicy') }}
          </span>
        </v-card-text>
      </template>
      <v-container v-else fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="4" align="start">
            <v-card-text class="pa-0 secondary--text">
              <strong>Addalia Technology ©</strong> —v{{
                $store.state.localAppVersion
              }}
              <br />
              <span class="legal-link" @click="getInfoPage('LegalAdvice')">
                {{ $t('legalAdvice') }}
              </span>
              •
              <span class="legal-link" @click="getInfoPage('PrivacyPolicy')">
                {{ $t('privacyPolicy') }}
              </span>
              •
              <span class="legal-link" @click="getInfoPage('CookiesPolicy')">
                {{ $t('cookiesPolicy') }}
              </span>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <v-card class="flex primary" flat tile>
              <v-card-text class="pa-0 secondary--text">
                <strong class=""> {{ $t('addaliaFooter') }} </strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" align="end">
            <AppFooterSocials />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
import AppFooterSocials from '@/components/app-footer/AppFooterSocials'
import AppFooterVersion from '@/components/app-footer/AppFooterVersion'
import addaliaSocials from '@/utils/socials.json'

export default {
  components: {
    AppFooterSocials,
    AppFooterVersion,
  },
  data() {
    return {
      socials: addaliaSocials,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    getInfoPage(name) {
      this.$router.push({
        name,
      })
    },
  },
}
</script>
<style>
.legal-link {
  text-decoration: none;
  color: var(--secondary-color);
  cursor: pointer;
}

.legal-link:hover {
  text-decoration: underline;
}
</style>
