<template>
  <v-dialog :value="true" persistent max-width="500">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center" class="my-2">
          <h2>{{ $t('changeStatus') }}</h2>
          <DialogButton section="notifications" subsection="changeStatus" />
        </v-row>
      </v-card-title>
      <v-card-text class="body-1">
        <v-col class="black--text">
          <p class="ma-0 mt-2 ml-3">
            {{ $t('changeNotificationStatusInfo') }}
          </p>
          <v-form ref="statusForm" v-model="validStatusForm" lazy-validation>
            <v-col>
              <v-select
                v-model="newStatus"
                :label="$t('newStatus')"
                :items="availableStatus"
                variant="underlined"
                :rules="[notEmpty]"
              >
                <template v-slot:selection="data">
                  {{ $t(data.item) }}
                </template>
                <template v-slot:item="data">
                  {{ $t(data.item) }}
                </template>
              </v-select>
            </v-col>
          </v-form>
        </v-col>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          width="150px"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          width="150px"
          :disabled="
            loading ||
            !userCanChangeStatus ||
            !newStatus ||
            newStatus === getNotificationStatus(notification).status
          "
          :loading="loading"
          @click="performAction"
          id="confirm-change-status"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  getNotificationStatus,
  notificationProcessing,
  notificationCompleted,
  notificationRevert,
} from '@/services/notification-service'
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import DialogButton from '@/components/documentation/buttons/DialogButton'

export default {
  props: {
    notification: Object,
  },
  components: {
    DialogButton,
  },
  async mounted() {
    this.newStatus = this.getNotificationStatus(this.notification).status
  },
  data() {
    return {
      newStatus: undefined,
      validStatusForm: false,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    closeDialog() {
      this.$emit('closeChangeStatusDialog')
      this.$refs.statusForm?.reset()
      this.$refs.statusForm?.resetValidation()
      this.loading = false
    },
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    async notificationProcessing(notification) {
      await notificationProcessing(notification.id)
    },
    async notificationCompleted(notification) {
      await notificationCompleted(notification.id)
    },
    async notificationRevert(notification) {
      await notificationRevert(notification.id)
    },
    async performAction() {
      if (
        !this.$refs.statusForm?.validate() ||
        !this.userCanChangeStatus ||
        this.newStatus === this.getNotificationStatus(this.notification).status
      )
        return
      this.loading = true

      try {
        this.newStatus === 'notificationStatus.processing'
          ? await this.notificationProcessing(this.notification)
          : this.newStatus === 'notificationStatus.completed'
          ? await this.notificationCompleted(this.notification)
          : await this.notificationRevert(this.notification)

        const message = this.$t('changeStatusSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })

        this.closeDialog()
      } catch (error) {
        const message = this.$t('changeStatusError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isAdminUser']),
    userCanChangeStatus() {
      return (
        this.notification.document &&
        (this.isAdminUser ||
          this.$store.state.user?.processNotification ||
          (!this.isAdminUser &&
            this.notification.manager &&
            this.notification.manager.id === this.$store.state.user?.id))
      )
    },
    availableStatus() {
      return [
        this.notification.estadoOriginal === 'Aceptado'
          ? 'notificationStatus.accepted'
          : 'notificationStatus.expired',
        'notificationStatus.processing',
        'notificationStatus.completed',
      ]
    },
  },
}
</script>
