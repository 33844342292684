<template>
  <div>
    <v-dialog v-model="show" persistent>
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <OperatorTraceTable
          :loading="false"
          :headers="headers"
          :mobileHeaders="mobileHeaders"
          :operatorsTraces="operatorsTracesParsed"
          @clickRow="operatorsTracesClicked"
        ></OperatorTraceTable>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OperatorTraceTable from '@/components/operators/OperatorTraceTable'

export default {
  components: {
    OperatorTraceTable,
  },
  props: {
    showDialog: Boolean,
    operatorsTraces: [],
    title: String,
  },
  data() {
    return {
      headers: ['operator', 'actionType', 'createdAt', 'ip', 'city', 'country'],
      mobileHeaders: [
        'operator',
        'actionType',
        'createdAt',
        'ip',
        'city',
        'country',
      ],
      mustBeShow: this.showDialog,
    }
  },
  computed: {
    show() {
      return this.showDialog
    },
    operatorsTracesParsed() {
      return this.operatorsTraces.map((n) => ({
        ...n,
        createdAt: new Date(n.createdAt),
      }))
    },
  },
  methods: {
    close() {
      this.mustBeShow = false
      this.$emit('dialog-closed')
    },
    operatorsTracesClicked(operatorsTracesSelected) {
      //TODO: si nos piden algo que hacer con la traza clickeada
      'operatorsTracesClicked', operatorsTracesSelected
      //window.open('/operator' + '/' + operatorsTracesSelected.id, '_blank')
    },
  },
  created() {},
  watch: {},
}
</script>
