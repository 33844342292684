<template>
  <v-container class="ma-0 pa-0">
    <v-row :align="align" justify="center">
      <v-col class="d-flex justify-center" cols="1">
        <v-icon v-if="icon" :class="progressColor(current, max) + '--text '">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col class="d-flex justify-center" cols="7">
        <v-row justify="center" align="center">
          <v-progress-linear
            class="mt-3"
            :color="progressColor(current, max)"
            :value="(100 / max) * current"
            :buffer-value="buffer"
            :stream="stream"
          >
          </v-progress-linear>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-center" cols="4">
        <v-row justify="start" align="center">
          <component
            :is="textSize"
            :class="progressColor(current, max) + '--text ml-3'"
            cols="3"
          >
            {{ progressByBuffer ? Math.round((buffer * max) / 100) : current }}
            /
            {{ max }}
          </component>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    buffer: {
      type: Number,
      default: 100,
    },
    stream: {
      type: Boolean,
      default: false,
    },
    progressByBuffer: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: 'h3',
    },
    align: {
      type: String,
      default: 'center',
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    progressColor(current, max) {
      const percent = this.progressByBuffer
        ? this.buffer
        : (100 / max) * current

      if (this.disabled) return 'grey'
      else if (percent >= 0 && percent < 25) return 'green'
      else if (percent >= 25 && percent < 50) return 'blue'
      else if (percent >= 50 && percent < 75) return 'yellow'
      else if (percent >= 75 && percent < 100) return 'orange'
      else return 'red'
    },
  },
}
</script>

<style lang="scss" scoped></style>
