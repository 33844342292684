<template>
  <v-dialog :value="true" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mb-5">
          <div>{{ $t('clientActions.title.' + action) }}</div>
        </v-row>
      </v-card-title>
      <v-card-text>
        {{ $t('denyLegalResponsibility') }}
        <b>{{ cif.empresa }}</b> <br /><br />
        {{ $t('certificateWillBeDeleted') }}
      </v-card-text>
      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-cif-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading"
          @click="performAction"
          id="confirm-cif"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { clientDenyResponsibility } from '@/services/client-service'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: {
    cif: Object,
    action: String,
  },
  data() {
    return {
      authorizationType: undefined,
      communicationType: undefined,
      certificateType: undefined,
      certificateUser: undefined,
      certificate: undefined,
      certificateB64: [],
      showPassword: false,
      certificatePassword: undefined,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Closes the current dialog
     */
    closeDialog() {
      this.$emit('closeDenyAuthorizationDialog')
    },
    /**
     * Function called when confirm is pressed. Denys the responsibility.
     */
    async performAction() {
      try {
        this.loading = true
        await clientDenyResponsibility(
          this.$store.state.user.parentRef.id,
          this.$store.state.user.id,
          this.cif.id
        )

        this.$emit('removeCif')

        const message = this.$t('clientActions.success.deny')
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  watch: {},
}
</script>
