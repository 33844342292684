<template>
  <v-card class="mx-auto" outlined height="450">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('certificate') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('manageCertificate') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip
        bottom
        :open-on-click="false"
        :open-on-focus="!isMobile"
        :open-on-hover="!isMobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary secondary--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="isEditingUserInfo = !isEditingUserInfo"
            :disabled="loading"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">{{ $t('editCancel') }}</span>
        <span v-else>{{ $t('editCertificate') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-form
      v-if="isEditingUserInfo || user.needsCertEmpresa"
      ref="certificateForm"
      v-model="validChangeUserInfo"
      lazy-validation
    >
      <v-list-item>
        <v-container>
          <v-row
            align="center"
            justify="center"
            no-gutters
            style="height: 350px"
          >
            <v-col cols="12">
              <v-file-input
                dense
                accept=".p12,.pfx"
                chips
                :label="$t('certificate')"
                prepend-icon="mdi-file-document-outline"
                @click:append="showPassword = !showPassword"
                v-model="certificate"
                :disabled="!isEditingUserInfo || loading"
                persistent-hint
                :rules="[
                  (v) => (!!v && isEditingUserInfo) || $t('requiredField'),
                ]"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                :label="$t('certificatePassword')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                autocomplete="new-password"
                :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-form-textbox-password"
                :rules="[(v) => !!v || $t('requiredField')]"
                :value="certificatePassword"
                @input="certificatePassword = $event"
                :disabled="!isEditingUserInfo || loading"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :disabled="!isEditingUserInfo || loading"
                  :loading="loading"
                  @click="updateUserCertificate()"
                  block
                  class="primary secondary--text"
                >
                  {{ $t('uploadCertificateConfig') }}
                  <template v-slot:loader>
                    <v-progress-circular indeterminate size="20" width="2" />
                    <span class="ml-2">{{ $t('uploadingCertificate') }}</span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
    <div
      v-else-if="!isEditingUserInfo || !user.needsCertEmpresa"
      class="d-flex align-center justify-center"
      style="height: 75%"
    >
      <v-alert outlined :color="certificateColor" width="100%" class="mx-4">
        <b>{{ certificateTitle }} </b>
        <span>
          <br />
          <br />
          <div v-if="user.certificateFilename">
            <i style="display: block">
              {{ $t('certificateExpiration.file') }}
            </i>
            <i style="display: block; word-break: break-all">
              {{ user.certificateFilename }}
            </i>
            <br />
          </div>
          <div v-if="user.certificateExpiration">
            <i style="display: block">
              {{ $t('certificateExpiration.expirationDate') }}
              {{ $d(user.certificateExpiration.toDate(), 'i18nDate') }}
            </i>
          </div>
          <div>
            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="deleteUserCertificate()"
              block
              class="mt-5 red white--text"
            >
              {{ $t('deleteCertificateConfig') }}
              <template v-slot:loader>
                <v-progress-circular indeterminate size="20" width="2" />
                <span class="ml-2">{{ $t('deletingCertificate') }}</span>
              </template>
            </v-btn>
          </div>
        </span>
      </v-alert>
    </div>
    <div
      v-if="
        allowedAltCertificates && !isEditingUserInfo && !user.needsCertEmpresa
      "
      class="mx-4"
    >
      <v-btn
        @click="alternativeCertificatesDialog = true"
        block
        class="primary secondary--text"
        style="position: relative; top: -20px"
      >
        {{ $t('alternativeCertificates') }}
      </v-btn>
    </div>

    <SettingsCertificatesDialog
      v-if="alternativeCertificatesDialog"
      :alternativeCertificates="user.alternativeCertificates"
      @closeAlternativeCertDialog="closeAlternativeCertDialog"
    />
  </v-card>
</template>
<script>
import SettingsCertificatesDialog from './SettingsCertificatesDialog.vue'
import { mapGetters, mapMutations } from 'vuex'
import {
  updateUserCertificate,
  deleteUserCertificate,
} from '@/services/user-service'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'
import { toBase64 } from '@/utils/file'
import getErrorText from '@/utils/get-error-text'

export default {
  components: { SettingsCertificatesDialog },
  async mounted() {
    this.user = { ...this.$store.state.user }
    if (!this.user) return this.setNotFound(true)
  },
  watch: {
    /**
     * Watcher to check when a certificate is added so that
     * it can be saved in the base64 format.
     */
    certificate: function (newValue) {
      if (newValue) {
        this.certificateB64 = []
        toBase64(newValue, 'certificateB64', this)
      }
    },
    '$store.state.user': async function (newValue) {
      if (newValue) {
        this.user = { ...newValue }
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser', 'isEmpresaUser']),
    allowedAltCertificates() {
      return !this.isEmpresaUser
    },
    certificateAttached() {
      return !!this.certificate
    },
    certificateColor() {
      return certificateColor(this.user.certificateExpiration?.toDate())
    },
    certificateTitle() {
      return certificateTitle(this.user.certificateExpiration?.toDate())
    },
  },
  data() {
    return {
      certificateB64: [],
      certificate: undefined,
      certificatePassword: undefined,
      showPassword: false,
      user: {},
      userModified: {},
      isEditingUserInfo: false,
      loading: false,
      validChangeUserInfo: false,
      alternativeCertificatesDialog: false,
    }
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    async updateUserCertificate() {
      if (!this.$refs.certificateForm.validate()) return

      if (this.certificateB64.length === 0)
        return this.setSnackbar({
          position: 'top',
          type: 'error',
          message: this.$t('certificateMandatory'),
        })

      this.loading = true
      try {
        // Llamamos al service de user para la subida del certificado
        await updateUserCertificate({
          certificateFilename: this.certificateB64[0].name,
          certBase64: this.certificateB64[0].base64,
          certPassword: this.certificatePassword,
          certType: 'main',
        })
        const message = this.$t('uploadCertificateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
        //Limpiamos el certificado
        this.certificate = undefined
        this.certificatePassword = undefined
        this.isEditingUserInfo = false
        // Quitamos el progressBar tras la subida correcta del certificado
        this.$store.commit('setProgress', { isDisplayed: false })

        // Los operadores, recargar la página para que se refresque el store cuando los nuevos parentAttributes
        if (this.isOperatorUser)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
      this.loading = false
    },
    async deleteUserCertificate() {
      this.loading = true
      try {
        // Llamamos al service de user para el borrado del certificado
        await deleteUserCertificate({
          certType: 'main',
        })
        const message = this.$t('deleteCertificateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
        //Limpiamos el certificado
        this.certificate = undefined
        this.certificatePassword = undefined
        this.isEditingUserInfo = false

        // Colocamos el progressBar si se borra el certificado principal
        this.$store.commit('setProgress', {
          message: 'uploadCertificateWarning',
          height: 50,
          color: 'error',
          movement: false,
          link: {
            text: 'redirectToConfiguration',
            route: '/settings',
          },
        })

        // Los operadores, recargar la página para que se refresque el store cuando los nuevos parentAttributes
        if (this.isOperatorUser)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
      this.loading = false
    },
    closeAlternativeCertDialog() {
      this.alternativeCertificatesDialog = false
    },
  },
}
</script>
