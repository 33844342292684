<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.dashboard.title') }}
    </h1>
    <p>{{ $t('documentation.texts.dashboard.paragraph1') }}</p>
    <v-img style="width: 100%; max-width: 900px" contain :src="picName" />
    <p>{{ $t('documentation.texts.dashboard.paragraph2') }}</p>
    <p>{{ $t('documentation.texts.dashboard.paragraph2-5') }}</p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1" />
    <p>{{ $t('documentation.texts.dashboard.paragraph3') }}</p>
    <ul>
      <li
        @click="goDoc('dashboard', 'notifications')"
        class="custom-link"
        v-if="isNotificacionesActivas"
      >
        {{ $t('documentation.texts.dashboard.link1') }}
      </li>
      <li
        @click="goDoc('dashboard', 'assignments')"
        class="custom-link"
        v-if="isNotificacionesActivas"
      >
        {{ $t('documentation.texts.dashboard.link2') }}
      </li>
      <li @click="goDoc('dashboard', 'alerts')" class="custom-link">
        {{ $t('documentation.texts.dashboard.link3') }}
      </li>
      <li
        @click="goDoc('dashboard', 'signatures')"
        class="custom-link"
        v-if="isFirmasActivas"
      >
        {{ $t('documentation.texts.dashboard.link4') }}
      </li>
    </ul>
    <p></p>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isNotificacionesActivas', 'isFirmasActivas']),
    picName() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.pic') +
        '.png')
    },
    pic1() {
      return require('@/assets/documentation/dashboard/' +
        this.$t('documentation.texts.dashboard.pic1') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
