import store from '@/store'

import LoginView from '@/views/LoginView'
import DashboardView from '@/views/DashboardView'
import AnalyticsDashboardView from '@/views/AnalyticsDashboardView'
import DocumentationView from '@/views/DocumentationView'
import NotificationsView from '@/views/NotificationsView'
import SignatureCreateRequestView from '@/views/SignatureCreateRequestView'
import SignaturesView from '@/views/SignaturesView'
import SignatureDetailView from '@/views/SignatureDetailView'
import NotificationDetailView from '@/views/NotificationDetailView'
import SharedNotificationDetailView from '@/views/SharedNotificationDetailView'
import SettingsView from '@/views/SettingsView'
import FilterView from '@/views/FilterView'
import CifsView from '@/views/CifsView'
import OperatorsView from '@/views/OperatorsView'
import GroupsView from '@/views/GroupsView'
import AutomatedTasksView from '@/views/AutomatedTasksView'
import HelpView from '@/views/HelpView'
import LegalAdviceView from '@/views/LegalAdviceView'
import PrivacyPolicyView from '@/views/PrivacyPolicyView'
import CookiesPolicyView from '@/views/CookiesPolicyView'
import NotPurchasedView from '@/views/NotPurchasedView'
import NotFound from '@/views/NotFoundView'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {
      tabTitle: 'login',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
    meta: {
      tabTitle: 'panel',
      userCanAccess: () => store.getters.isUserSignedIn,
      navigationConfig: () => [
        {
          parent: undefined,
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          includeTitle: true,
          includeIcon: false,
        },
      ],
    },
  },
  {
    path: '/documentation',
    name: 'DocumentationView',
    component: DocumentationView,
    meta: {
      tabTitle: 'documentation',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isFirmasActivas ||
          store.getters.isNotificacionesActivas),
      navigationConfig: () => [
        {
          parent: undefined,
          title: 'documentation',
          icon: 'mdi-help-circle-outline',
          includeTitle: false,
          includeIcon: true,
        },
      ],
    },
  },
  {
    path: '/operators',
    name: 'OperatorsView',
    component: OperatorsView,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isColectivoUser) {
        next()
      } else {
        next('/notPurchased')
      }
    },
    meta: {
      tabTitle: 'operators',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isSuperAdminUser || store.getters.isSuperOperatorUser || 
          store.getters.isAdminAndCanManageUsers) &&
        (store.getters.isFirmasActivas ||
          store.getters.isNotificacionesActivas),
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'operators',
          icon: 'mdi-account',
          includeTitle: true,
          includeIcon: false,
        },
      ],
    },
  },
  {
    path: '/groups',
    name: 'GroupsView',
    component: GroupsView,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isColectivoUser) {
        next()
      } else {
        next('/notPurchased')
      }
    },
    meta: {
      tabTitle: 'groups',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isSuperAdminUser || store.getters.isSuperOperatorUser ||
          store.getters.isAdminAndCanManageGroups) &&
        !store.getters.isDemoEnv,
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'groups',
          icon: 'mdi-account-group',
          includeTitle: true,
          includeIcon: false,
        },
      ],
    },
  },
  {
    path: '/cifs',
    name: 'CifsView',
    component: CifsView,
    meta: {
      tabTitle: 'cifs',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isFirmasActivas ||
          store.getters.isNotificacionesActivas),
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'cifs',
          icon: 'mdi-city',
          includeTitle: true,
          includeIcon: false,
        },
      ],
    },
  },
  {
    path: '/filters',
    name: 'FiltersView',
    component: FilterView,
    meta: {
      tabTitle: 'filters',
      userCanAccess: () =>
        //Esta lógica deberá ser (tras el isUserSignedIn) para los personalizados, cuando pongamos dentro de Filtros botón de Personalizados
        /*&&
        /*!store.getters.isColectivoUser &&
        (store.getters.isSuperAdminUser || store.getters.isSuperOperatorUser ||
          store.getters.isAdminAndCanManageFilters)*/
        store.getters.isUserSignedIn,
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'filters',
          icon: 'mdi-camera-control',
          includeTitle: true,
          includeIcon: false,
        },
      ],
    },
  },
  {
    path: '/automated-tasks',
    name: 'AutomatedTasksView',
    component: AutomatedTasksView,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isColectivoUser) {
        next()
      } else {
        next('/notPurchased')
      }
    },
    meta: {
      tabTitle: 'automatedTasks',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isSuperAdminUser || store.getters.isSuperOperatorUser ||
          store.getters.isAdminAndCanManageAutomatedTasks) &&
        !store.getters.isDemoEnv,
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'automatedTasks',
          icon: 'mdi-robot',
          includeTitle: false,
          includeIcon: true,
        },
      ],
    },
  },
  {
    path: '/analytics',
    name: 'AnalyticsDashboardView',
    component: AnalyticsDashboardView,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isColectivoUser) {
        next()
      } else {
        next('/notPurchased')
      }
    },
    meta: {
      tabTitle: 'analytics',
      userCanAccess: () =>
        store.getters.isUserSignedIn && store.getters.isAllowedAnalytics,
      navigationConfig: () => [
        {
          parent: 'management',
          title: 'analytics',
          icon: 'mdi-chart-line',
          includeTitle: true,
          includeIcon: true,
        },
      ],
    },
  },
  {
    path: '/notifications',
    name: 'NotificationsView',
    component: NotificationsView,
    meta: {
      tabTitle: 'notifications',
      userCanAccess: () =>
        store.getters.isUserSignedIn && store.getters.isNotificacionesActivas,
      navigationConfig: () => {
        var navConfig = []
        if (!store.getters.isMobile) {
          navConfig.push({
            parent: undefined,
            icon: 'mdi-inbox-arrow-down',
            title: 'notifications',
            includeTitle: true,
            includeIcon: true,
            includeBadge: true,
            //color: 'warningLow',
          })
        }
        return navConfig
      },
    },
  },
  {
    path: '/signatures',
    name: 'SignaturesView',
    component: SignaturesView,
    meta: {
      tabTitle: 'signatures',
      userCanAccess: () =>
        store.getters.isUserSignedIn && store.getters.isFirmasActivas,
      navigationConfig: () => {
        var navConfig = []
        if (!store.getters.isMobile) {
          navConfig.push({
            parent: 'signatures',
            icon: 'mdi-draw-pen',
            title: 'mySignatures',
            includeTitle: true,
            includeBadge: true,
            includeIcon: true,
            //color: 'warningLow',
          })
        }
        return navConfig
      },
    },
  },
  {
    path: '/createSignatureRequest',
    name: 'CreateSignatureRequest',
    component: SignatureCreateRequestView,
    meta: {
      tabTitle: 'createSignature',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        store.getters.isFirmasActivas &&
        !store.getters.isClienteDespachoUser,
      navigationConfig: () => [
        {
          parent: 'signatures',
          title: 'createSignature',
          icon: 'mdi-file-sign',
          includeIcon: true,
          includeTitle: true,
        },
      ],
    },
  },
  {
    path: '/signature/:id',
    name: 'SignatureDetailView',
    component: SignatureDetailView,
    meta: {
      tabTitle: 'signatureDetail',
      userCanAccess: () =>
        store.getters.isUserSignedIn && store.getters.isFirmasActivas,
      navigationConfig: () => [{}],
    },
  },
  {
    path: '/notification/:id',
    name: 'NotificationDetailView',
    component: NotificationDetailView,
    meta: {
      tabTitle: 'notificationDetail',
      userCanAccess: () =>
        store.getters.isUserSignedIn && store.getters.isNotificacionesActivas,
      navigationConfig: () => [{}],
    },
  },
  {
    path: '/sharedNotification/:userId/:id/:shareCode',
    name: 'SharedNotificationDetailView',
    component: SharedNotificationDetailView,
    meta: {
      tabTitle: 'notificationDetail',
      userCanAccess: () => true,
      navigationConfig: () => [{}],
    },
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      tabTitle: 'profile',
      userCanAccess: () => store.getters.isUserSignedIn,
      navigationConfig: () => [
        {
          parent: 'profile',
          title: 'settings',
          icon: 'mdi-cog-outline',
          includeIcon: true,
        },
      ],
    },
  },
  {
    path: '/support',
    name: 'HelpView',
    component: HelpView,
    meta: {
      tabTitle: 'support',
      userCanAccess: () =>
        store.getters.isUserSignedIn &&
        (store.getters.isSuperAdminUser || store.getters.isSuperOperatorUser || store.getters.isAdminUser),
      navigationConfig: () => [
        {
          parent: 'profile',
          title: 'support',
          icon: 'mdi-face-agent',
          includeIcon: true,
        },
      ],
    },
  },
  {
    path: '/legalAdvice',
    name: 'LegalAdvice',
    component: LegalAdviceView,
    meta: {
      tabTitle: 'legalAdvice',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyView,
    meta: {
      tabTitle: 'privacyPolicy',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/cookiesPolicy',
    name: 'CookiesPolicy',
    component: CookiesPolicyView,
    meta: {
      tabTitle: 'cookiesPolicy',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/notPurchased',
    name: 'NotPurchased',
    component: NotPurchasedView,
    meta: {
      tabTitle: 'notPurchased',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '*',
    name: 'NotFoundView',
    component: NotFound,
    meta: {
      tabTitle: 'notFound',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
]

export default routes
