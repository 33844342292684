import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/i18n'
import VueTour from 'vue-tour'

import AppAvatar from '@/components/commons/AppAvatar'
import AppSkeleton from '@/components/commons/AppSkeleton'

import { App as FirebaseApp, Analytics } from '@/firebase-exports'
import './registerServiceWorker'
import 'leaflet/dist/leaflet.css'

Vue.component('AppAvatar', AppAvatar)
Vue.component('AppSkeleton', AppSkeleton)

Analytics.getAnalytics(FirebaseApp)

Vue.config.productionTip = false
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
