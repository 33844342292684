<template>
  <div :style="styleBar">
    <apexchart
      type="pie"
      :options="series.chartOptions"
      :series="series.series"
      :height="height"
    ></apexchart>
    <v-icon
      v-if="series.loading"
      style="top: -40%; left: 50%; z-index: 999; position: relative"
    >
      mdi-autorenew mdi-spin
    </v-icon>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartname: String,
    title: String,
    notifications: Array,
    operators: Array,
    height: String,
  },
  data() {
    var theme = this.$vuetify.theme.dark ? 'dark' : 'light'
    return {
      filteredNotifications: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          stacked: true,
        },
        theme: {
          mode: theme,
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: [],
          scrollbar: {
            enabled: false,
          },
        },
        // colors: ['#80c7fd','#008FFB','#80f1cb','#00E396','#e3928d','#eb4034',    ],
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
      },
      styleBar: {
        'white-space': 'nowrap',
        width: '100%',
      },
    }
  },

  computed: {
    series() {
      if (this.notifications === undefined || this.notifications.length === 0) {
        return {
          chartOptions: this.chartOptions,
          series: [],
          loading: true,
        }
      }

      let nameMap = {}
      let xkeys = []
      let notificationsByCif = {}
      let series = []
      let labels = []

      this.notifications.forEach((notification) => {
        if (
          notification.estado !== 'Expirada' &&
          notification.estado !== 'Pendiente'
        ) {
          let operatorID = notification.lastActionByRef?.id
          let oidx = this.operators.findIndex(
            (operator) => operator.id === operatorID
          )
          if (oidx < 0) {
            return
          }
          let operator = this.operators[oidx]
          this.updateDataMap(notificationsByCif, nameMap, operator)
        }
      })

      let cifsKeys = Object.keys(notificationsByCif)
      for (let i = 0; i < cifsKeys.length; i++) {
        labels.push(notificationsByCif[cifsKeys[i]].name)
        series.push(notificationsByCif[cifsKeys[i]].accum)
      }

      let pack = {}
      if (this.notifications.length === 0) {
        pack = {
          chartOptions: this.createCustomOptions(xkeys, labels, cifsKeys),
          series: [],
          labels: [],
          loading: true,
        }
      } else {
        pack = {
          chartOptions: this.createCustomOptions(xkeys, labels, cifsKeys),
          series: series,
          labels: labels,
          nameMap: nameMap,
          cifsKeys: cifsKeys,
          loading: false,
        }
      }
      return pack
    },
  },
  methods: {
    createCustomOptions(xkeys, labels, cifsKeys) {
      // Building chart options
      return {
        noData: {
          text: this.$t('graphs.loading'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Helvetica',
          },
        },
        title: {
          text: this.title,
          align: 'left',
        },
        xaxis: {
          categories: xkeys,
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              setTimeout(() => {
                this.showDetail(config.dataPointIndex, cifsKeys)
              }, 10)
            },
            xAxisLabelClick: (event, chartContext, config) => {
              setTimeout(() => {
                this.showDetail(config.dataPointIndex, cifsKeys)
              }, 10)
            },
          },
          //height: expectedHeight,

          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        labels: labels,
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
      }
    },
    updateDataMap(notificationsByCif, nameMap, operator) {
      if (notificationsByCif[operator.id] === undefined) {
        notificationsByCif[operator.id] = {
          name: operator.name + ' ' + operator.surname,
          accum: 1,
        }
        nameMap[operator.name + ' ' + operator.surname] = operator.id
      } else {
        notificationsByCif[operator.id].accum += 1
      }
    },
    filterNotifications(cif) {
      this.filteredNotifications = this.notifications.filter((notification) => {
        return (
          cif === notification.lastActionByRef?.id &&
          notification.estado !== 'Expirada' &&
          notification.estado !== 'Pendiente'
        )
      })
    },
    showDetail(selectedId, cifsKeys) {
      this.filterNotifications(cifsKeys[selectedId])
      console.log('', this.filteredNotifications)
      let title = this.$t('notificationsByOperator.titleGrid')
      this.$emit('detail-level', this.filteredNotifications, title)
    },
  },
}
</script>
