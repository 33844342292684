<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.groups.create.title') }}
    </h1>
    <p>{{ $t('documentation.texts.groups.create.p1') }}</p>
    <v-img
      style="
        width: 100%;
        max-width: 880px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic1Name"
    />
    <p>{{ $t('documentation.texts.groups.create.p2') }}</p>
    <ul>
      <li>
        <b> - {{ $t('documentation.texts.groups.create.li1T') }}: </b>
        {{ $t('documentation.texts.groups.create.li1P') }}
      </li>
      <li>
        <b> - {{ $t('documentation.texts.groups.create.li2T') }}: </b>
        {{ $t('documentation.texts.groups.create.li2P') }}
      </li>
      <li>
        <b> - {{ $t('documentation.texts.groups.create.li3T') }}: </b>
        {{ $t('documentation.texts.groups.create.li3P') }}
      </li>
      <li>
        <b> - {{ $t('documentation.texts.groups.create.li4T') }}: </b>
        {{ $t('documentation.texts.groups.create.li4P') }}
      </li>
    </ul>
    <v-img
      style="
        width: 100%;
        max-width: 880px;
        margin-top: 10px;
        margin-bottom: 20px;
      "
      contain
      :src="pic2Name"
    />
    <p>{{ $t('documentation.texts.groups.create.p3') }}</p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/groups/' +
        this.$t('documentation.texts.groups.create.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/groups/' +
        this.$t('documentation.texts.groups.create.pic2') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
