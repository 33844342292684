<template>
  <v-list v-if="notificationsList.length != 0" subheader two-line>
    <v-list-item
      v-for="notification in notificationsList"
      :key="notification.id"
      id="notification-item-dashboard"
      @click="
        $router.push({
          name: 'NotificationDetailView',
          params: {
            id: notification.id,
          },
        })
      "
    >
      <v-chip
        style="width: 130px"
        class="white--text justify-center"
        :color="getNotificationStatus(notification).color"
      >
        <v-icon :class="getNotificationStatus(notification).color" dark>
          {{ getNotificationType(notification).icon }}
        </v-icon>
        <span class="ml-1">
          {{ $t(getNotificationStatus(notification).status) }}
        </span>
      </v-chip>
      <div class="ml-2 mr-2 subtitle">
        {{ $d(notification.fecha_puesta_disposicion, 'i18nDate') }}
      </div>
      <v-list-item-content class="text-left align-self-start">
        <v-list-item-title>
          <b>
            {{ notification.nombre_titular }}
          </b>
        </v-list-item-title>
        <v-list-item-title>
          {{ notification.nombre_organismo_emisor }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ notification.nombre_organismo_emisor_raiz }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-container v-else style="text-align: center">
    <b>
      <span> {{ $t('emptyTypeNotificationList') }} </span>
    </b>
  </v-container>
</template>

<script>
import {
  getNotificationStatus,
  getNotificationType,
} from '@/services/notification-service'

export default {
  props: {
    notificationsList: {
      type: Array,
    },
  },
  methods: {
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
    /**
     * Return the name of the company. If it is to long it adds '...'
     * @param {String} name Name of the company.
     */
    companyName(name) {
      return name.length > 15 ? name.substring(0, 13) + '...' : name
    },
  },
}
</script>

<style lang="scss" scoped></style>
