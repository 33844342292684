import {
  CIFDoc,
  CIFsCollection,
  Firestore,
  CreateCif,
  CreateCifBulk,
  UpdateCif,
  ProgramDelete,
  DeleteCif,
  ExportDmsNotifications,
  UploadCertificate,
  CreateWallet,
  UserClientsQuery,
} from '@/firebase-exports'
import store from '@/store/index'
import { getUserOperatorsDict } from './operator-service'
import { updateDoc } from 'firebase/firestore'
const lodash = require('lodash')

/**
 * Crea un CIF asociado a un usuario.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} cifData - Los datos del CIF a crear.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del CIF creado.
 */
export async function createCIF(userId, cifData) {
  return await CreateCif({
    userId,
    ...cifData,
  })
}

/**
 * Crea múltiples CIFs de manera masiva a partir de un archivo.
 *
 * @param {string} userId - El ID del usuario.
 * @param {File} file - Archivo que contiene los datos de los CIFs a crear.
 * @param {Array<string>} groups - Los grupos a los que pertenecerán los CIFs.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos de los CIFs creados.
 */
export async function createCIFBulk(userId, file, groups = []) {
  return await CreateCifBulk({ userId, file, groupsIds: groups })
}

/**
 * Obtiene una versión simplificada de los CIFs de un usuario.
 * Opcionalmente, incluye información de los usuarios asociados.
 *
 * @param {string} userId - El ID del usuario.
 * @param {boolean} [includeUsers=false] - Si se incluyen los datos de usuarios asociados.
 * @returns {Promise<Array>} Una promesa que se resuelve con un arreglo de CIFs.
 */
export async function getCifsSimplified(userId, includeUsers = false) {
  let operators = {}
  let cifOperators = {}

  if (includeUsers) {
    operators = await getUserOperatorsDict(userId)

    // Dictionary where the keys are the cifs and the values are lists with the ids of the operators
    Object.keys(operators).forEach((operatorId) =>
      operators[operatorId].cifsRef.forEach((cifRef) => {
        if (!cifOperators[cifRef.id])
          cifOperators[cifRef.id] = [operators[operatorId]]
        else cifOperators[cifRef.id].push(operators[operatorId])
      })
    )
  }

  const docsSnap = await Firestore.getDocs(
    Firestore.query(CIFsCollection(userId), Firestore.orderBy('empresa', 'asc'))
  )
  return docsSnap.docs.map((doc) => {
    const document = doc.data()

    // Delete some back variables that could be unsafe to store in front
    delete document.password

    return includeUsers
      ? { id: doc.id, ...document, users: cifOperators[doc.id] ?? [] }
      : { id: doc.id, ...document }
  })
}

/**
 * Returns the cifs.
 * @params userIdParam Id of the parent user.
 * @returns {Promise<Array>} Array of objects with the cifs.
 */
export const listCifs = async (userIdParam) => {
  const userId =
    userIdParam ??
    (store.state.user.parentRef
      ? store.state.user.parentRef.id
      : store.state.user.id)

  const snapshot = await Firestore.getDocs(CIFsCollection(userId))

  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

/**
 * Obtiene CIFs con información adicional basada en el ID del usuario y opcionalmente del hijo.
 * Puede incluir operadores asociados.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} [childId=undefined] - ID opcional del usuario hijo.
 * @param {boolean} [includeUsers=false] - Si se incluyen los datos de operadores asociados.
 * @returns {Promise<Array>} Una promesa que se resuelve con un arreglo de CIFs.
 */
export async function getCifs(
  userId,
  childId = undefined,
  includeUsers = false
) {
  let operators = {}
  let cifOperators = {}
  let groupsOperator = store.state.user.groups?.length
    ? lodash.cloneDeep(store.state.user.groups)
    : undefined

  if (includeUsers) {
    operators = await getUserOperatorsDict(userId)

    // Dictionary where the keys are the cifs and the values are lists with the ids of the operators
    Object.keys(operators).forEach((operatorId) =>
      operators[operatorId].cifsRef.forEach((cifRef) => {
        if (!cifOperators[cifRef.id])
          cifOperators[cifRef.id] = [operators[operatorId]]
        else cifOperators[cifRef.id].push(operators[operatorId])
      })
    )
  }

  const docsSnap = await Firestore.getDocs(
    Firestore.query(CIFsCollection(userId), Firestore.orderBy('empresa', 'asc'))
  )
  let cifs = docsSnap.docs.map((doc) => {
    const document = doc.data()

    // Delete some back variables that could be unsafe to store in front
    delete document.password

    // Load users and client data only if specified by flag (to save resources if not needed)
    let users = []

    if (includeUsers) users = cifOperators[doc.id] ?? []

    // Si es admin y no pertenece a un grupo
    if (!childId && !groupsOperator)
      return {
        ...doc.data(),
        id: doc.id,
        users,
      }
    // Si es admin y además pertenece a un grupo
    else if (
      !childId &&
      groupsOperator &&
      groupsOperator[0].cifsRef.some((cif) => cif.id === doc.id)
    )
      return {
        ...doc.data(),
        id: doc.id,
        users,
      }
    // Si es operador/cliente, tiene la empresa y la empresa no se va a borrar
    else if (
      childId &&
      users.some((user) => user.id === childId) &&
      !doc.data().deletionDate
    )
      return {
        ...doc.data(),
        id: doc.id,
        users,
      }
    else return undefined
  })

  // Si es operador o administrador de un grupo filtramos
  const finalCifs =
    childId || groupsOperator ? cifs.filter((cif) => cif !== undefined) : cifs

  return finalCifs.sort((a, b) => a.empresa.localeCompare(b.empresa))
}

/**
 * Checks the cifs in the state or listCifs and returns all of them in a Map
 * @params userId Id of the parent user.
 * @returns Object with all the cifs where the key is the id.
 */
export async function getCifsMap(userId) {
  const cifsMap = {}

  const cifs = store.getters.isSuperAdmin
    ? store.state.user.cifs
    : await listCifs(userId)

  cifs.forEach((cif) => (cifsMap[cif.id] = cif))
  return cifsMap
}

/**
 * Subscripción a los CIFs de un usuario. Puede incluir operadores asociados.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} [childId=undefined] - ID opcional del usuario hijo.
 * @param {boolean} [includeUsers=false] - Si se incluyen los datos de operadores asociados.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function getCifsSubscription(
  userId,
  childId = undefined,
  includeUsers = false
) {
  let operators = {}
  let cifOperators = {}
  let groupsOperator = store.state.user.groups?.length
    ? lodash.cloneDeep(store.state.user.groups)
    : undefined

  if (includeUsers) {
    operators = await getUserOperatorsDict(userId)

    // Dictionary where the keys are the cifs and the values are lists with the ids of the operators
    Object.keys(operators).forEach((operatorId) =>
      operators[operatorId].cifsRef.forEach((cifRef) => {
        if (!cifOperators[cifRef.id])
          cifOperators[cifRef.id] = [operators[operatorId]]
        else cifOperators[cifRef.id].push(operators[operatorId])
      })
    )
  }

  var cifsUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      CIFsCollection(userId),
      Firestore.orderBy('empresa', 'asc')
    ),
    (snapShot) => {
      let cifs = snapShot.docs.map((doc) => {
        const document = doc.data()

        // Delete some back variables that could be unsafe to store in front
        delete document.password

        // Load users and client data only if specified by flag (to save resources if not needed)
        let users = []

        if (includeUsers) users = cifOperators[doc.id] ?? []

        // Si es admin y no pertenece a un grupo
        if (!childId && !groupsOperator)
          return {
            ...doc.data(),
            id: doc.id,
            users,
          }
        // Si es admin y además pertenece a un grupo
        else if (
          !childId &&
          groupsOperator &&
          groupsOperator[0].cifsRef.some((cif) => cif.id === doc.id)
        )
          return {
            ...doc.data(),
            id: doc.id,
            users,
          }
        // Si es operador/cliente, tiene la empresa y la empresa no se va a borrar
        else if (
          childId &&
          users.some((user) => user.id === childId) &&
          !doc.data().deletionDate
        )
          return {
            ...doc.data(),
            id: doc.id,
            users,
          }
        else return undefined
      })

      // Si es operador o administrador de un grupo filtramos
      const finalCifs = (
        childId || groupsOperator
          ? cifs.filter((cif) => cif !== undefined)
          : cifs
      ).sort((a, b) => a.empresa.localeCompare(b.empresa))

      // Guardamos en el store
      store.commit('setManageCompanies', finalCifs)
    },
    (error) => {
      throw error
    }
  )

  store.commit('setManageCompaniesUnsubscribe', cifsUnsubscribe)
  return cifsUnsubscribe
}

/**
 * Subscribes to the cifs of a user in a simplified way.
 * @param {Object} context Context of the component where the
 * subscription is going to be made.
 * @returns Function to unsubscribe.
 */
export function getCifsSubscriptionSimplified(userId, context) {
  var cifsUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      CIFsCollection(userId),
      Firestore.orderBy('empresa', 'asc')
    ),
    (snapShot) => {
      const cifs = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      context.cifs = cifs
    },
    (error) => {
      throw error
    }
  )
  return cifsUnsubscribe
}

/**
 * Subscripción a los CIFs de un cliente específico.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} clientId - El ID del cliente.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function getClientCifsSubscription(userId, clientId) {
  var cifsUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      CIFsCollection(userId),
      Firestore.orderBy('empresa', 'asc')
    ),
    (snapShot) => {
      const filteredCifs = []
      for (let i = 0; i < snapShot.docs.length; i++) {
        const doc = snapShot.docs[i]
        const documentData = doc.data()

        // Delete some back variables that could be unsafe to store in front
        delete documentData.password

        const cif = { id: doc.id, ...documentData }
        // ¿Tiene el cliente el cif?
        cif.allowedToSign = cif.allowedToSign ?? []
        let hasCif = cif.allowedToSign
          ? Object.keys(cif.allowedToSign).some(
              (allowed) => allowed == clientId
            ) || cif.clientGD?.id == clientId
          : false

        if (hasCif) filteredCifs.push(cif)
      }

      const finalCifs = filteredCifs.sort((a, b) =>
        a.empresa.localeCompare(b.empresa)
      )

      // Guardamos en el store
      store.commit('setManageCompanies', finalCifs)
    },
    (error) => {
      throw error
    }
  )

  store.commit('setManageCompaniesUnsubscribe', cifsUnsubscribe)
  return cifsUnsubscribe
}

/**
 * Lista los clientes asociados a un CIF específico.
 *
 * @param {Object} cifRef - Referencia del CIF.
 * @returns {Promise<Array>} Una promesa que se resuelve con un arreglo de clientes.
 */
export async function listCifClients(cifRef) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const q = Firestore.query(
    UserClientsQuery(userId),
    Firestore.where('cifsRef', 'array-contains', cifRef)
  )
  const querySnapshot = await Firestore.getDocs(q)
  return querySnapshot.docs.map((doc) => {
    const document = doc.data()

    // Delete some back variables that could be unsafe to store in front
    delete document.password

    return { id: doc.id, ...document }
  })
}

/**
 * Obtiene un CIF específico basado en el ID del usuario y el ID del CIF.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del CIF.
 */
export async function getCIF(userId, cifId) {
  const docSnap = await Firestore.getDoc(CIFDoc(userId, cifId))
  const document = docSnap.data()

  // Delete some back variables that could be unsafe to store in front
  delete document.password

  return { id: docSnap.id, ...document }
}

/**
 * Actualiza un CIF específico.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF a actualizar.
 * @param {Object} cifData - Los nuevos datos del CIF.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos actualizados del CIF.
 */
export async function updateCIF(userId, cifId, cifData) {
  return await UpdateCif({
    userId,
    cifId,
    ...cifData,
  })
}

/**
 * Function to create a wallet about a company to a client.
 * @param {string} userId Id of the parent (admin).
 * @param {string} clientId Id of the client.
 * @param {string} cifId Id of the CIF that the client is going to have.
 */
export async function createCifWallet(userId, clientId, cifId) {
  return await CreateWallet({
    userId,
    clientId,
    cifId,
  })
}

/**
 * Sube un certificado asociado a un usuario, cliente y CIF.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} clientId - El ID del cliente.
 * @param {string} cifId - El ID del CIF.
 * @param {...} - Otros parámetros relevantes para el certificado.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos del certificado subido.
 */
export async function uploadCertificate(
  userId,
  clientId,
  cifId,
  certificateType,
  communicationType,
  authorizationType,
  certificate,
  certificatePassword,
  certificateUser,
  nombre,
  apellidos,
  email,
  telefono,
  pin
) {
  return await UploadCertificate({
    userId,
    clientId,
    cifId,
    certificateType,
    communicationType,
    authorizationType,
    ...(certificate ? { certificate } : {}),
    ...(certificateUser ? { certificateUser } : {}),
    certificatePassword,
    nombre,
    apellidos,
    email,
    telefono,
    pin,
  })
}

/**
 * Elimina el Gran Destinatario de un CIF específico.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF.
 * @param {Object} cifData - Datos adicionales del CIF.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos actualizados del CIF.
 */
export async function deleteClientGD(userId, cifId, cifData) {
  return await UpdateCif({
    userId,
    cifId,
    ...cifData,
    ...{ granDestinatario: false, clientGD: undefined },
  })
}

/**
 * Programa la eliminación de un CIF.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF a eliminar.
 * @returns {Promise<Object>} Una promesa que se resuelve cuando la eliminación está programada.
 */
export async function programDeleteCIF(userId, cifId) {
  return await ProgramDelete({
    userId,
    cifId,
  })
}

/**
 * Elimina un CIF específico.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF a eliminar.
 * @returns {Promise<Object>} Una promesa que se resuelve cuando el CIF ha sido eliminado.
 */
export async function deleteCIF(userId, cifId) {
  return await DeleteCif({
    userId,
    cifId,
  })
}

/**
 * Cancela la programación de eliminación de un CIF.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cifId - El ID del CIF cuya eliminación se va a cancelar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la cancelación ha sido completada.
 */
export async function cancelDeletion(userId, cifId) {
  await Firestore.updateDoc(CIFDoc(userId, cifId), {
    deletionDate: Firestore.deleteField(),
  })
}

/**
 * Exporta notificaciones DMS asociadas a los CIFs proporcionados.
 *
 * @param {Array<Object>} cifs - Los CIFs asociados a las notificaciones DMS.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos de las notificaciones exportadas.
 */
export async function exportDmsNotifications(cifs) {
  return await ExportDmsNotifications({
    cifs,
  })
}

/**
 * Maps cifs and groups giving an object where the key is the group id and
 * the value are the cifs that belong to that group.
 * @param {Array} cifs Array of cifs to map with their groups.
 * @returns Object with group ids as keys and a list of cifs as values.
 */
export function mapCifsAndGroups(cifs) {
  if (!cifs) return {}

  const mapped = {}

  for (const cif of cifs) {
    if (!cif.groupsRef) continue

    for (const group of cif.groupsRef) {
      if (!mapped[group.id]) mapped[group.id] = [cif]
      else mapped[group.id].push(cif)
    }
  }

  return mapped
}

/**
 * Updates the field 'unregistered cifs'
 * @param {Array} unregisteredCifs Array of unregistered cifs to write.
 */
export const ignoreUnregisteredCif = async (unregisteredCifs = []) => {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  await updateDoc(CIFDoc(userId, userId), {
    unregisteredCifs,
  })
}
