<template>
  <v-fade-transition mode="out-in">
    <v-skeleton-loader v-if="condition" :type="type" :types="customTypes" />
    <slot name="skeleton-content" v-else />
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    condition: Boolean,
    type: String,
  },
  data() {
    return {
      customTypes: {
        item: 'list-item',
        assignManager: 'list-item-avatar',
        cifList: 'list-item-avatar-two-line@6',
        operatorList: 'list-item-avatar-two-line@6',
        groupList: 'list-item@10',
        automatedTaskList: 'list-item@10',
        notificationList: 'table',
        signatureList: 'table',
        historyList: 'list-item-avatar-two-line@4',
        shareCodesList: 'list-item-avatar-two-line@4',
        notificationProperties: 'list-item-two-line@8, actions',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep
  .v-skeleton-loader__procedureListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone {
  height: 101.97px;
}
::v-deep
  .v-skeleton-loader__procedureListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone
  > .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  margin-top: 16px;
  margin-bottom: 16px;
}
::v-deep
  .v-skeleton-loader__notificationListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone {
  height: 101.97px;
}
::v-deep
  .v-skeleton-loader__notificationListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone
  > .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  margin-top: 16px;
  margin-bottom: 16px;
}
::v-deep
  .v-skeleton-loader__certificationListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone {
  height: 101.97px;
}
::v-deep
  .v-skeleton-loader__certificationListMobile
  > .v-skeleton-loader__list-item-avatar-three-line.v-skeleton-loader__bone
  > .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  margin-top: 16px;
  margin-bottom: 16px;
}
::v-deep
  .v-skeleton-loader__certificationTitle
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 38px;
}
::v-deep
  .v-skeleton-loader__procedureTitle
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 34px;
}
::v-deep
  .v-skeleton-loader__certificationSubtitle
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  width: 50%;
  height: 16px;
}
::v-deep
  .v-skeleton-loader__procedureCurrentStatus
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 26px;
}
::v-deep .v-skeleton-loader__certificationFile {
  padding: 12px;
}
::v-deep .v-skeleton-loader__procedureFile {
  padding: 12px;
}
::v-deep
  .v-skeleton-loader__certificationIssuerAvatar
  > .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  width: 60px;
  height: 60px;
}
::v-deep
  .v-skeleton-loader__procedureIssuerAvatar
  > .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  width: 60px;
  height: 60px;
}
::v-deep
  .v-skeleton-loader__certificationIssuerName
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 19px;
}
::v-deep
  .v-skeleton-loader__procedureIssuerName
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 19px;
}
::v-deep
  .v-skeleton-loader__certificationIssuerShortname
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 17px;
  width: 50%;
  display: inline-block;
}
::v-deep
  .v-skeleton-loader__procedureIssuerShortname
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 17px;
  width: 50%;
  display: inline-block;
}
::v-deep
  .v-skeleton-loader__certificationAttributes
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 18px;
}
::v-deep
  .v-skeleton-loader__procedureAttributes
  > .v-skeleton-loader__text.v-skeleton-loader__bone {
  height: 18px;
}
::v-deep .v-skeleton-loader__customCertificationTypes {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep
  .v-skeleton-loader__customCertificationTypes
  > .v-skeleton-loader__chip.v-skeleton-loader__bone {
  margin-bottom: 8px;
  width: 100%;
  height: 45px;
}
::v-deep .v-skeleton-loader__createCertificationClientList {
  height: 272px;
  padding-top: 8px;
  padding-bottom: 8px;
}
::v-deep
  .v-skeleton-loader__createCertificationClientList
  > .v-skeleton-loader__list-item-avatar-two-line {
  height: 64.38px;
  width: 75%;
}
::v-deep .v-skeleton-loader__contactList {
  height: 272px;
  padding-top: 8px;
  padding-bottom: 8px;
}
::v-deep
  .v-skeleton-loader__contactList
  > .v-skeleton-loader__list-item-avatar-two-line {
  height: 64px;
  width: 75%;
}
::v-deep .v-skeleton-loader__groupList {
  height: 272px;
  padding-top: 8px;
  padding-bottom: 8px;
}
::v-deep
  .v-skeleton-loader__groupList
  > .v-skeleton-loader__list-item-avatar-two-line {
  height: 64px;
  width: 75%;
}
::v-deep .v-skeleton-loader__userDetailField {
  padding: 17px;
}
::v-deep .v-skeleton-loader__userDetailSwitch {
  padding: 17px;
  height: 66px;
}
::v-deep .v-skeleton-loader__button.v-skeleton-loader__bone {
  width: 100%;
}
::v-deep .v-skeleton-loader__heading.v-skeleton-loader__bone {
  width: 100%;
}
</style>
