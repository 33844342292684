import { Firestore, MetadataVersion } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Crea una subscripción a los metadatos de la aplicación, actualizando la versión y el estado del modo de mantenimiento en la tienda de estado.
 * Esta función escucha los cambios en los metadatos y los refleja automáticamente en el estado global de la aplicación.
 *
 * @returns {Function} Función para cancelar la subscripción. Al llamar a esta función, se detendrá la escucha de cambios en los metadatos de la aplicación.
 */
export function getAppMetadataSubscription() {
  const appMetadataUnsubscribe = Firestore.onSnapshot(
    MetadataVersion,
    (doc) => {
      store.commit('setRemoteAppVersion', doc.data().version)
      store.commit('setMaintenanceMode', doc.data().maintenanceMode)
    },
    (error) => {
      throw error
    }
  )
  return appMetadataUnsubscribe
}
