<template>
  <v-container>
    <div>
      <h1>POLÍTICA DE COOKIES</h1>
      <p>
        Las cookies son pequeños archivos de datos que se envían al ordenador
        del Usuario, teléfono móvil u otro dispositivo de acceso cuando visita
        una página web y que permiten obtener información relativa a su
        navegación o un código que permite identificar unívocamente al usuario.
      </p>
      <p>
        Siguiendo las directrices de la Agencia Española de Protección de Datos
        procedemos a detallar el uso de cookies que hace esta web con el fin de
        informarle con la máxima exactitud posible.
      </p>
      <h2>QUE TIPO DE COOKIES USAMOS:</h2>
      <p>
        Esenciales: algunas cookies son esenciales para que pueda experimentar
        la funcionalidad completa de nuestro sitio. Nos permiten mantener las
        sesiones de los usuarios y prevenir cualquier amenaza a la seguridad. No
        recopilan ni almacenan ninguna información personal. Por ejemplo, estas
        cookies le permiten iniciar sesión en su cuenta, agregar productos a su
        carrito y realizar el pago de forma segura.
      </p>
      <p>
        El Usuario acepta expresamente, por la utilización de este Site, el
        tratamiento de la información recabada en la forma y con los fines
        anteriormente mencionados. Y asimismo reconoce conocer la posibilidad de
        rechazar el tratamiento de tales datos o información rechazando el uso
        de Cookies mediante la selección de la configuración apropiada a tal fin
        en su navegador. Si bien esta opción de bloqueo de Cookies en su
        navegador puede no permitirle el uso pleno de todas las funcionalidades
        del Website.
      </p>
      <p>
        Puede usted permitir, bloquear o eliminar las cookies instaladas en su
        equipo mediante la configuración de las opciones del navegador instalado
        en su ordenador:
      </p>
      <p>
        Google Chrome: Herramientas → Configuración → Mostrar opciones avanzadas
        → configuración de contenido → Cookies → Bloquear los datos de sitios y
        las cookies de terceros
      </p>
      <p>
        Mozilla Firefox: Herramientas → Opciones → Privacidad → Historial → Usar
        una configuración personalizada para el historial (desmarcar todas las
        casillas).
      </p>
      <p>
        Internet Explorer: Herramientas → Opciones de Internet → Privacidad →
        Configuración para la zona de Internet (subir la barra de navegación
        hasta Bloquear todas las cookies).
      </p>
      <p>
        Safari: Edición → Preferencias → Privacidad → Bloquear cookies
        (seleccionar “siempre”).
      </p>
      <p>
        Si tiene dudas sobre esta política de cookies, puede contactar con
        nosotros en <a href="mailto:info@addalia.com">info@addalia.com</a>
      </p>
    </div>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
h1,
h2 {
  color: var(--v-primary-base);
}
</style>
