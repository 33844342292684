var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"data-v-step":"8","app":"","color":"primary","dark":"","clipped-right":""}},[_c('v-img',{staticClass:"logo-custom-button",attrs:{"data-v-step":"0","contain":"","src":_vm.getImageSource,"transition":"fade-transition","max-width":"175"},on:{"click":function($event){_vm.$store.state.user
        ? _vm.$router.push({ name: 'DashboardView' })
        : _vm.$router.push({ name: 'LoginView' })}}}),(_vm.$store.state.user)?_c('AppTour',{attrs:{"finishFunction":_vm.tourFinishFunction,"autoStart":!_vm.$store.state.user.tourAppFinished,"stepsProp":_vm.tourSteps,"name":"mainTour"}}):_vm._e(),_c('v-spacer',{attrs:{"data-v-step":"20"}}),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.isUserSignedIn && _vm.$route.name != 'LoginView')?_c('v-toolbar-items',{staticStyle:{"width":"100%"}},[_c('v-spacer'),(_vm.isMobile)?[(_vm.isNotificacionesActivas)?_c('AppBarItemButton',{attrs:{"includeBadge":"","color":"secondary","item":_vm.notificationItem}}):_vm._e(),(_vm.isFirmasActivas)?_c('AppBarItemButton',{attrs:{"includeBadge":"","color":"secondary","item":_vm.signatureItem}}):_vm._e(),_c('AppBarItemButton',{attrs:{"includeBadge":"","color":"secondary","item":_vm.analyticsItem}}),(_vm.isMobile)?_c('AppBarItemButton',{attrs:{"color":"secondary","item":{ icon: _vm.navigationDrawer ? 'mdi-close' : 'mdi-menu' },"clickButton":() => _vm.setNavigationDrawer(!_vm.navigationDrawer)}}):_vm._e()]:[_vm._l((_vm.$store.state.navigationPages),function(item){return [_c('v-fade-transition',{key:item.title,attrs:{"mode":"out-in"}},[(item.component == 'NewsList')?_c('NewsList',{attrs:{"icon":item.icon,"title":item.title,"data-v-step":"7"}}):_vm._e(),(item.component == 'AppBarItemMenu' && item.available)?_c('AppBarItemMenu',{attrs:{"isUserMenu":item.title === 'profile',"title":item.title,"items":item.subPages,"icon":item.icon,"color":item.color,"clickable":item.clickable}}):_vm._e()],1),(item.title)?_c('v-fade-transition',{key:item.title,attrs:{"mode":"out-in"}},[(!item.subPages && item.available && !item.component)?_c('AppBarItemButton',{attrs:{"item":item,"includeBadge":item.includeBadge,"includeTitle":item.includeTitle,"includeIcon":item.includeIcon,"color":item.color,"id":item.title == 'notifications'
                  ? 'notifications-menu'
                  : item.title == 'documentation'
                  ? 'documentation-menu'
                  : ''}}):_vm._e()],1):_vm._e()]})]],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }