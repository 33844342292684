<template>
  <v-row cols="12">
    <v-col cols="12">
      <v-file-input
        ref="fileInput"
        multiple
        dense
        :clearable="false"
        truncate-length="15"
        @change="attachFiles"
        :placeholder="$t('uploadFiles')"
        :label="$t('uploadFiles')"
        :disabled="disabled"
        :error-messages="errors"
      >
        <!-- :rules="applyRules ? fileRules : noRules" -->
        <template v-slot:append>
          {{ $t('numberAttached') + ': ' + `${filesAttachedChild.length}` }}
        </template>
        <template v-slot:selection></template>
      </v-file-input>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <ul>
        <li
          v-for="(file, index) in filesAttachedChild"
          :key="index"
          class="pa-0 ma-0"
        >
          <v-row
            v-if="filesAttachedChild[index]"
            align="center"
            justify="center"
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col class="ma-0" cols="10">
              <i
                ><b>{{ file.name }}</b></i
              >
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon class="ma-0">
                    <v-icon
                      class="pa-0 ma-0"
                      v-bind="attrs"
                      v-on="on"
                      id="delete-file"
                      :disabled="disabled"
                      @click.stop="deleteFile(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </template>
                <div>
                  {{ $t('deleteFile') }}
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </li>
      </ul>
    </v-col>
    <v-dialog v-model="dialogDelete" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <v-container>
            <v-row justify="space-between" align="center">
              <span>
                {{ $t('confirmDeleteTitle') }}
              </span>
              <v-btn
                v-if="!loadingData"
                fab
                @click="dialogDelete = false"
                small
                plain
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text> {{ $t('confirmDeleteText') }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmDelete()"
            :loading="loadingData"
            color="primary"
            text
          >
            {{ $t('confirmDelete') }}
            <template v-slot:loader>
              <v-progress-circular indeterminate size="20" width="2" />
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapMutations } from 'vuex'
import { uploadAddedDocumentsToDMS } from '@/services/notification-service'
export default {
  props: {
    alreadyUploadedFiles: Array,
    applyRules: Boolean,
    disabled: Boolean,
    openOnInit: Boolean,
    notificationId: String,
    errors: [],
  },
  components: {},
  async mounted() {
    if (this.alreadyUploadedFiles)
      this.filesAttachedChild = [...this.alreadyUploadedFiles]
    if (this.openOnInit)
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click()
  },
  data() {
    return {
      dialogDelete: false,
      filesAttachedChild: [],
      documentsContent: [],
      loadingData: false,
      fileRules: [() => this.filesAttachedChild.length !== 0],
      noRules: [],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    truncateText(text) {
      const extensionStart = text.lastIndexOf('.')
      const extension = text.substring(extensionStart)
      if (text.length - extension.length <= 12) return text
      return text.substring(0, 20 - extension.length) + '...' + extension
    },
    deleteFile(index) {
      this.deleteIndex = index
      this.dialogDelete = true
    },
    async confirmDelete() {
      // Eliminar el archivo seleccionado emitiendo el evento al componente padre
      if (this.filesAttachedChild[this.deleteIndex].alreadyUploadedToDMS)
        this.filesAttachedChild[this.deleteIndex].deleteFromDMS = true
      else this.filesAttachedChild[this.deleteIndex].simpleDelete = true

      const fileToDeleteFromDMS = this.filesAttachedChild.find(
        (doc) => doc.deleteFromDMS === true
      )

      if (fileToDeleteFromDMS) {
        try {
          this.loadingData = true
          await uploadAddedDocumentsToDMS(this.notificationId, {
            documents: [fileToDeleteFromDMS],
          })

          const message = this.$t('extraFilesDeleteSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })

          this.loadingData = false
        } catch (err) {
          const message = this.$t('extraFilesDeleteError')
          this.setSnackbar({ position: 'top', type: 'error', message })

          this.loadingData = false
          return
        }
      }

      this.filesAttachedChild.splice(this.deleteIndex, 1)
      this.dialogDelete = false
      // Emitimos evento de cambios
      this.$emit('update-files-attached', this.filesAttachedChild)
    },
    cancelDelete() {
      // Cancelar la eliminación del archivo seleccionado
      this.dialogDelete = false
      this.deleteIndex = null
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const fileInfo = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            base64: e.target.result.split(',')[1],
          }
          this.filesAttachedChild.push({
            name: fileInfo.name,
            base64: fileInfo.base64,
            mimeType: fileInfo.mimeType,
            addedDocument: false,
            alreadyUploadedToDMS: false,
          })
          resolve(this.filesAttachedChild)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async attachFiles() {
      await Promise.all(
        this.$refs.fileInput.internalValue.map(async (element) => {
          await this.toBase64(element)
        })
      )

      // if (!arr.every((obj) => !/\s/.test(obj.name)));

      this.filesAttachedChild = this.filesAttachedChild.map((document) => {
        return { ...document, name: document.name.replace(/\s/g, '_') }
      })
      this.$emit('update-files-attached', this.filesAttachedChild)

      await new Promise((resolve) => setTimeout(resolve, 1))
      window.scrollTo(0, document.body.scrollHeight)
    },
  },
  computed: {
    fileNames() {
      return this.files.map((file) => file.name).join(', ')
    },
  },
  watch: {
    files(value) {
      this.files = value
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-file-input__text {
  display: none;
}
ul {
  list-style-type: none;
}
</style>
