<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.titles.signatures.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.signatures.signatureList.title') }}
    </h2>
    <p v-if="!isClienteDespachoUser">
      {{ $t('documentation.texts.signatures.signatureList.paragraph1') }}
    </p>
    <v-img
      v-if="!isClienteDespachoUser"
      style="width: 100%; max-width: 400px"
      contain
      :src="pic1Name"
    />
    <br />
    <p>{{ $t('documentation.texts.signatures.signatureList.paragraph2') }}</p>
    <p>{{ $t('documentation.texts.signatures.signatureList.paragraph3') }}</p>
    <p>{{ $t('documentation.texts.signatures.signatureList.paragraph4') }}</p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic2Name" />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isClienteDespachoUser']),
    pic1Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureList.picSignatureMenu'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/signatures/' +
        this.$t(
          'documentation.texts.signatures.signatureList.picSignaturesList'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
