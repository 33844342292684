<template>
  <v-card class="mx-auto sticky-card">
    <v-row class="d-flex justify-start pa-0 ma-0" sticky>
      <v-list>
        <v-list-group
          :disabled="section.disabled"
          v-for="(section, i) in sectionTitles"
          :key="i"
          v-model="section.active"
          @click.stop="
            () => {
              if (section.disabled) {
                return
              }
              if (section.subSections.length == 0) goDoc(section)
            }
          "
          :append-icon="
            section.disabled
              ? 'mdi-lock'
              : section.subSections.length > 0
              ? 'mdi-menu-down'
              : ''
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                @click.stop="
                  () => {
                    if (section.disabled) return
                    goDoc(section)
                  }
                "
                style="font-size: large"
                :id="'documentation-' + section.title"
              >
                <b>
                  {{ $t('documentation.titles.' + section.title + '.title') }}
                </b>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-if="!section.disabled">
            <v-list-item
              v-for="(subsection, n) in section.subSections"
              :key="n"
              @click.stop="goDoc(section, subsection)"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    'pl-2 ' +
                    (subsection === $route.query?.subsection
                      ? 'primary--text'
                      : '')
                  "
                  style="font-size: large"
                  :id="'documentation-' + section.title + '-' + subsection"
                >
                  {{
                    $t(
                      'documentation.titles.' +
                        section.title +
                        '.subsections.' +
                        subsection
                    )
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  beforeMount() {
    // If user is not Admin or Operator with Admin Permissions, operators, companies and AT is hidden
    if (!this.isAdminUser) {
      this.sectionTitles = this.sectionTitles.filter(function (section) {
        return (
          section.title !== 'operators' &&
          section.title !== 'companies' &&
          section.title !== 'automatedTasks'
        )
      })

      // Si es un Operador sin ser administrador y DespachoCliente, también elimina las notificaciones del menú lateral
      if (this.isClienteDespachoUser) {
        this.sectionTitles = this.sectionTitles.filter(function (section) {
          return section.title !== 'notifications' && section.title !== 'groups'
        })
      }
    }
    // En el caos de que no tenga acceso a analiticas, se le oculta el acceso al menu
    if (!this.isAllowedAnalytics) {
      this.sectionTitles = this.sectionTitles.filter(function (section) {
        return section.title !== 'analytics'
      })
    }
  },
  data() {
    return {
      sectionTitles: [
        {
          title: 'home',
          subSections: [],
          active: false,
          disabled: false,
        },
        {
          title: 'dashboard',
          subSections:
            this.$store.state.user.notificacionesActivas &&
            this.$store.state.user.firmasActivas
              ? ['notifications', 'assignments', 'alerts', 'signatures']
              : this.$store.state.user.notificacionesActivas
              ? ['notifications', 'assignments', 'alerts']
              : this.$store.state.user.firmasActivas
              ? ['signatures', 'alerts']
              : [],

          active: false,
          disabled: false,
        },
        {
          title: 'operators',
          subSections: [
            'create',
            ...(!this.$store.getters.isSuperAdminUser &&
            !this.$store.getters.isSuperOperatorUser
              ? []
              : ['createCSV']),
            'update',
            'disable',
            ...(!this.$store.getters.isSuperAdminUser &&
            !this.$store.getters.isSuperOperatorUser
              ? []
              : ['manageAdmins']),
          ],
          active: false,
          disabled: false,
        },
        {
          title: 'groups',
          subSections: ['create', 'update', 'delete', 'unregisteredCifs'],
          active: false,
          disabled:
            !this.$store.getters.isSuperAdminUser &&
            !this.$store.getters.isSuperOperatorUser,
        },
        {
          title: 'notifications',
          subSections: [
            'notificationList',
            'notificationMassiveActions',
            'notificationDetail',
            'responsability',
            'notificationOpen',
            'changeStatus',
            'notificationShare',
            'addNotification',
            'uploadFiles',
            'alertCreate',
            'alertModify',
            'addExtraDocument',
            'addObservation',
          ],
          active: false,
          disabled: !this.$store.state.user.notificacionesActivas,
        },
        {
          title: 'companies',
          subSections: [
            'create',
            ...(this.$store.state.user.tipoEmpresa === 'EMPRESA' ||
            (!this.$store.getters.isSuperAdminUser &&
              !this.$store.getters.isSuperOperatorUser)
              ? []
              : ['createCSV']),
            'update',
            'delete',
            'operators',
            'export',
          ],
          active: false,
          disabled: false,
        },
        {
          title: 'clients',
          subSections: [
            ...(this.$store.state.user.tipo === 'clienteDespacho'
              ? []
              : ['create']),
            'upload',
            'delete',
            'edit',
          ],
          active: false,
          disabled: !this.$store.state.user.firmasActivas,
        },
        {
          title: 'signatures',
          subSections: [
            'signatureList',
            ...(this.$store.state.user.tipo === 'clienteDespacho'
              ? []
              : ['createSignature']),
            'signatureDetail',
            'signatureSign',
            'signatureAbandon',
            ...(this.$store.state.user.tipo === 'clienteDespacho'
              ? []
              : ['signatureDelete']),
            'signatureReject',
            'signatureForward',
            'signatureIncidence',
          ],
          active: false,
          disabled: !this.$store.state.user.firmasActivas,
        },
        {
          title: 'automatedTasks',
          subSections: [
            'actions',
            'conditions',
            'targets',
            'creation',
            'clonation',
            'update',
            'deletion',
            'history',
            'examples',
          ],
          active: false,
          disabled: false,
        },
        {
          title: 'analytics',
          subSections: [],
          active: false,
          disabled: false,
        },
        {
          title: 'configuration',
          subSections:
            this.$store.state.user.adminPermissions ||
            !this.$store.state.user.parentRef
              ? this.$store.state.user.notificacionesActivas
                ? ['emailOptions', 'uploadCertificate', 'licenses']
                : ['emailOptions', 'licenses']
              : ['emailOptions'],
          active: false,
          disabled: false,
        },
      ],
    }
  },
  created() {
    // Cogemos la página actual y la ponemos como active
    const key = this.$route.query?.section
    if (key) {
      const index = this.sectionTitles.findIndex(({ title }) => title === key)
      this.sectionTitles[index].active = true
    }
  },
  methods: {
    goDoc(section, subsection = '') {
      if (section.title === 'analytics' && this.isColectivoUser)
        this.$router.push('/notPurchased')
      else if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section.title },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section.title, subsection },
        })
    },
  },
  computed: {
    ...mapGetters([
      'isAdminUser',
      'isClienteDespachoUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAllowedAnalytics',
      'isColectivoUser',
    ]),
  },
}
</script>

<style>
.sticky-card {
  width: 250px;
  position: fixed;
  z-index: 1;
}
</style>
