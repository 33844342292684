<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.addObservation.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.addObservation.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic1Name" />
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addObservation.picNotifDetailObservation'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.addObservation.picAddedObservationHistory'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
