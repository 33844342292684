<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <AppTour
          :finishFunction="tourFinishFunction"
          :autoStart="!$store.state.user.tourNotificationsFinished"
          :stepsProp="steps"
          name="notificationsTour"
        >
        </AppTour>
        <NotificationDetail
          v-if="notification"
          :notification="notification"
          :history="history"
          :shared="shared"
          :isSharedAccess="false"
          :loadingNotification="loadingNotification"
          :loadingHistory="loadingHistory"
          :loadingShareCodes="loadingShareCodes"
        >
        </NotificationDetail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationDetail from '@/components/notifications/NotificationDetail'
import { listHistorySubscription } from '@/services/history-service'
import {
  getNotificationSubscription,
  filterNotifications,
} from '@/services/notification-service'
import { listShareCodesSubscription } from '@/services/share-codes-service'
import { updateUserInfo } from '@/services/user-service'
import { mapGetters, mapMutations } from 'vuex'
import AppTour from '@/components/app-bar/AppTour'
import { notificationDetailSteps } from '@/utils/tour-utils.js'
import { listFilters } from '@/services/filter-service'

export default {
  components: {
    NotificationDetail,
    AppTour,
  },
  async mounted() {
    //si el usuario es operador se debe coger el id del padre para pedir notificaciones
    this.userNotificationsId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id
    await this.getNotification()
    await this.getHistory()
    await this.getShareCodes()

    if (!this.$store.state.filters) {
      const filtersDict = {}
      const result = await listFilters()
      result.forEach((filter) => (filtersDict[filter.id] = filter))
      this.setFilters(filtersDict)
    }
  },
  destroyed() {
    if (this.historyUnsubscribe) this.historyUnsubscribe()
    if (this.shareCodesUnsubscribe) this.shareCodesUnsubscribe()
    if (this.notificationUnsubscribe) this.notificationUnsubscribe()
  },
  data() {
    return {
      userNotificationsId: '',
      notification: {},
      history: [],
      shared: [],
      steps: notificationDetailSteps(),
      notificationUnsubscribe: () => {},
      historyUnsubscribe: () => {},
      shareCodesUnsubscribe: () => {},
      loadingNotification: true,
      loadingHistory: true,
      loadingShareCodes: true,
    }
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
  },
  methods: {
    ...mapMutations(['setNotFound', 'setFilters']),
    async getNotification() {
      this.notificationUnsubscribe = await getNotificationSubscription(
        this.userNotificationsId,
        this.$route.params.id,
        this
      )
      // If the notificationUnsuscribe is undefined it means this notification doesn't exist
      if (!this.notificationUnsubscribe) return this.setNotFound(true)

      // If the user is not allowed to see the notification, set it to not found
      if (this.isOperatorUser) {
        // Waiting subscription data is retrieved
        while (this.loadingNotification) {
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        if (
          !filterNotifications([this.notification], this.$store.state.user)
            .length
        )
          return this.setNotFound(true)
      }
    },
    async getHistory() {
      this.historyUnsubscribe = await listHistorySubscription(
        this.userNotificationsId,
        'notifications',
        this.$route.params.id,
        this,
        'asc'
      )

      // If the historynUnsubscribe is undefined it means this history doesn't exist
      if (!this.historyUnsubscribe) this.history = []
    },
    async getShareCodes() {
      this.shareCodesUnsubscribe = await listShareCodesSubscription(
        this.userNotificationsId,
        this.$route.params.id,
        this
      )
      // If the shareCodesUnsubscribe is undefined it means this shared doesn't exist
      if (!this.shareCodesUnsubscribe) this.shared = []
    },
    async tourFinishFunction() {
      await updateUserInfo(this.$store.state.user.id, {
        tourNotificationsFinished: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
