<template>
  <v-card
    elevation="10"
    max-height="800"
    :min-width="isMobile ? 0 : 400"
    :min-height="!isMobile ? (shareCodes ? 692 : 740) : ''"
    class="pt-4"
  >
    <v-row>
      <v-col class="pa-3">
        <v-card-title> {{ $t('history') }} </v-card-title>
      </v-col>
      <v-col class="pa-3">
        <v-card-subtitle align="right">
          <strong>
            <v-icon @click="changeOrder()"> mdi-swap-vertical-bold </v-icon>
          </strong>
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 d-flex flex-row-reverse" style="width: 100%">
      <v-checkbox
        class="ma-0 pr-5"
        id="sistema"
        v-model="sistema"
        :label="$t('sistema')"
      />
    </v-row>
    <AppSkeleton :condition="loadingHistory" type="historyList">
      <template v-slot:skeleton-content>
        <v-timeline
          v-if="historyComputed.length != 0"
          :class="isMobile ? 'ma-1' : 'ma-5'"
          align-top
          dense
          over
        >
          <v-virtual-scroll
            :items="historyComputed"
            :item-height="180"
            height="580"
          >
            <template v-slot:default="{ item }">
              <v-timeline-item
                :key="item.id"
                :color="getHistoryIconBackColor(item.actionType)"
                :icon-color="getHistoryIconColor(item.actionType)"
                :icon="getHistoryIcon(item.actionType)"
                fill-dot
              >
                <v-col>
                  <v-row>
                    <strong>
                      {{ $t('historyActions.' + item.actionType) }}
                    </strong>
                  </v-row>
                  <v-row :class="isMobile ? 'body-2' : 'body-1'">
                    <strong>
                      {{
                        $d(item.createdAt?.toDate(), 'i18nDateAndHourSeconds')
                      }}
                    </strong>
                  </v-row>
                  <v-row
                    v-if="
                      item.actionType !== 'signatureAbandonedByDate' &&
                      item.actionType !== 'created'
                    "
                  >
                    <span v-if="item.user || item.authorText" class="body-2">
                      <b>{{ $t('sourceUser') + ': ' }} </b>
                      <b
                        :style="{ color: $vuetify.theme.currentTheme.primary }"
                        v-if="
                          item.authorText &&
                          item.authorText !== 'DEHUC' &&
                          item.actionType !== 'mailSystem' &&
                          item.actionType !== 'NoMailSystem'
                        "
                      >
                        {{ $t('at') }}</b
                      >
                      {{
                        !item.authorText
                          ? `${item.user.name} ${item.user.surname}`
                          : item.authorText === 'DEHUC'
                          ? $t('dehuc')
                          : item.actionType !== 'mailSystem' &&
                            item.actionType !== 'NoMailSystem'
                          ? `- ${item.authorText}`
                          : `${item.authorText}`
                      }}
                    </span>
                  </v-row>
                  <v-row>
                    <span v-if="item.targetUser" class="body-2">
                      <b>{{ $t('targetUser') + ': ' }} </b>
                      {{ `${item.targetUser.name} ${item.targetUser.surname}` }}
                    </span>
                  </v-row>
                  <!--v-row
                    v-if="
                      item.actionType === 'sendEmail' ||
                      item.actionType === 'mailSystem'
                    "
                  >
                    <span class="body-2">
                      <b>{{ $t('sentDate') + ': ' }} </b>
                      {{ $d(item.date?.toDate(), 'i18nDate') }}
                    </span>
                  </v-row-->
                  <v-row
                    v-if="
                      item.actionType === 'mailSystem' ||
                      item.actionType === 'NoMailSystem' ||
                      item.actionType === 'sendEmail'
                    "
                  >
                    <v-tooltip max-width="400" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="body-2" v-bind="attrs" v-on="on">
                          <b
                            >{{
                              (item.actionType === 'NoMailSystem'
                                ? $t('noEmailsCause')
                                : $t('emails')) + ': '
                            }}
                          </b>
                          {{
                            item.emails
                              ? shortText(item.emails)
                              : $t('withoutEmails')
                          }} </span
                        ><br />
                        <span
                          class="body-2"
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.noEmails"
                        >
                          <b>{{ $t('noEmails') }}</b
                          >: {{ shortText(item.noEmails) }}<br />
                        </span>
                      </template>
                      <div width="300">
                        <h2>
                          {{ $d(item.createdAt?.toDate(), 'i18nDate') }}
                        </h2>
                        <b>{{
                          (item.actionType === 'NoMailSystem'
                            ? $t('noEmailsCause')
                            : $t('emails')) + ': '
                        }}</b
                        >: {{ item.emails ? item.emails : $t('withoutEmails')
                        }}<br />
                        <p v-if="item.noEmails">
                          <b>{{ $t('noEmailsCause') }}</b
                          >: {{ item.noEmails }}<br />
                        </p>
                        <b>{{ $t('emailSubject') }}</b
                        >: {{ item.subject }}<br />
                        <p v-if="item.actionType === 'sendEmail'">
                          <b>{{ $t('text') }}</b
                          >: {{ item.emailText }}
                        </p>
                        <p v-if="item.actionType === 'NoMailSystem'">
                          <b>{{ $t('emailAllowedText') }}</b>
                        </p>
                      </div>
                    </v-tooltip>
                  </v-row>

                  <v-row v-if="item.date">
                    <span class="body-2">
                      <b>{{ $t('alertDate') + ': ' }} </b>
                      {{ $d(item.date?.toDate(), 'i18nDate') }}
                    </span>
                  </v-row>
                  <v-row v-if="item.text">
                    <v-tooltip max-width="400" v-if="item.text" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <b>{{ $t('alertText') + ': ' }} </b>
                          {{ alertText(item.text) }}
                        </span>
                      </template>
                      <div width="300">
                        <h2>{{ $d(item.date?.toDate(), 'i18nDate') }}</h2>
                        {{ item.text }}
                      </div>
                    </v-tooltip>
                    <span class="body-2"> </span>
                  </v-row>
                  <v-row v-if="item.observation">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="body-2">
                          <b>{{ $t('observation') + ': ' }} </b>
                          <!-- se muestran solo los primeros 20 caracteres -->
                          {{ item.observation.slice(0, 20) }}
                          <!-- se agrega ... si el texto es más largo -->
                          {{ item.observation.length > 20 ? '...' : '' }}
                        </span>
                      </template>
                      <div style="max-width: 500px">{{ item.observation }}</div>
                    </v-tooltip>
                  </v-row>
                  <v-row v-if="item.documents">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="body-2">
                          <b>{{ $t('document') + ': ' }} </b>
                          <!-- se muestran solo los primeros 20 caracteres -->
                          {{ item.documents.slice(0, 20) }}
                          <!-- se agrega ... si el texto es más largo -->
                          {{ item.documents.length > 20 ? '...' : '' }}
                        </span>
                      </template>
                      <div style="max-width: 500px">{{ item.documents }}</div>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-timeline-item>
            </template>
          </v-virtual-scroll>
        </v-timeline>
        <v-container v-else style="text-align: center">
          <span> {{ $t('emptyHistory') }} </span>
        </v-container>
      </template>
    </AppSkeleton>
    <br />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    history: Array,
    loadingHistory: Boolean,
    shareCodes: Boolean,
  },
  data() {
    return {
      orderFlag: false,
      sistema: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    historyComputed() {
      var sortedHistory = [...this.history]
      var history = this.orderFlag
        ? sortedHistory.sort(
            (a, b) => b.createdAt?.toDate() - a.createdAt?.toDate()
          )
        : sortedHistory.sort(
            (a, b) => a.createdAt?.toDate() - b.createdAt?.toDate()
          )
      const filteredItems = history.filter((item) => {
        if (this.sistema) {
          // Si sistema es true, se devuelven todos los elementos
          return true
        } else {
          // Si sistema es false, se devuelven solo los elementos cuyo actionType sea distinto de "mailSystem"
          return (
            item.actionType !== 'mailSystem' &&
            item.actionType !== 'NoMailSystem'
          )
        }
      })
      return filteredItems
    },
  },
  methods: {
    changeOrder() {
      this.orderFlag = !this.orderFlag
    },
    getHistoryIconBackColor(type) {
      switch (type) {
        case 'NoMailSystem':
          return 'white'
        case 'mailSystem':
          return 'white'
        default:
          return 'primary'
      }
    },
    getHistoryIconColor(type) {
      switch (type) {
        case 'NoMailSystem':
          return 'orange'
        case 'mailSystem':
          return 'green'
        default:
          return 'white'
      }
    },
    getHistoryIcon(type) {
      switch (type) {
        case 'signatureRequestCreated':
          return 'mdi-call-received'

        case 'notificationObservationDeleted':
          return 'mdi-comment-remove-outline'

        case 'addedNotificationDocument':
          return 'mdi-file-document-plus'

        case 'removedNotificationDocument':
          return 'mdi-file-document-minus'

        case 'notificationObservation':
          return 'mdi-comment-processing-outline'

        case 'signatureRevoked':
          return 'mdi-file-undo'

        case 'signatureAbandoned':
          return 'mdi-note-remove-outline'

        case 'signatureForwarded':
          return 'mdi-send'

        case 'signatureDeferred':
          return 'mdi-clock-time-four-outline'

        case 'signatureSigned':
          return 'mdi-draw'

        case 'signatureAbandonedByDate':
          return 'mdi-note-remove-outline'

        case 'signatureRejected':
          return 'mdi-file-document-alert'

        case 'created':
          return 'mdi-call-received'

        case 'open':
          return 'mdi-email-open-outline'

        case 'completed':
          return 'mdi-check'

        case 'processing':
          return 'mdi-file-edit-outline'

        case 'reverted':
          return 'mdi-undo'

        case 'shared':
          return 'mdi-share'

        case 'assignManager':
          return 'mdi-account-hard-hat-outline'

        case 'unassignManager':
          return 'mdi-account-off-outline'

        case 'alertCreate':
          return 'mdi-bell-plus'

        case 'alertDone':
          return 'mdi-bell-check'

        case 'alertModify':
          return 'mdi-bell-ring'

        case 'managerDecline':
          return 'mdi-account-remove'

        case 'managerAccept':
          return 'mdi-account-plus'

        case 'addDoc':
          return 'mdi-file-document-plus-outline'

        case 'addNotification':
          return 'mdi-file-document-plus-outline'

        case 'sendEmail':
          return 'mdi-email'

        case 'mailSystem':
          return 'mdi-email'

        case 'NoMailSystem':
          return 'mdi-email-alert'

        case 'openNotificationAutomatedError':
          return 'mdi-alert-octagram-outline'

        case 'httpsRequest':
          return 'mdi-link'

        default:
          return ''
      }
    },
    shortText(text, maxLength = 25) {
      return text.length < maxLength
        ? text
        : text.substring(0, maxLength) + '...'
    },
    alertText(text) {
      return text.length >= 7 ? text.substring(0, 7) + '...' : text
    },
  },
}
</script>
