<template>
  <div id="termstoaccept">
    <v-dialog v-model="show" persistent width="700" max-width="700">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('legal.termsToAcceptDialogTitle') }}
        </v-card-title>

        <v-card-text class="mt-6 text-h5">
          {{ $t('legal.toAcceptDialogMsg') }}
        </v-card-text>

        <v-card-text class="mt-6 consent-section">
          <!-- Checkbox with label -->
          <v-checkbox
            v-model="consentGiven"
            class="large-checkbox"
          ></v-checkbox>
          <!-- Label with link -->
          <span v-html="checkboxLabel" class="large-checkbox-label"></span>
        </v-card-text>

        <v-card-actions class="mt-4 justify-start">
          <v-btn color="primary" text @click="declineCloseDialog">
            {{ $t('legal.decline') }}
          </v-btn>
          <v-btn
            :disabled="!consentGiven || loading"
            color="primary"
            text
            @click="updateAcceptance"
          >
            {{ $t('legal.accept') }}
            <template v-slot:loader>
              <v-progress-circular indeterminate size="20" width="2" />
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { updateUserInfo } from '@/services/user-service'
import { createTrace } from '@/services/trace-service'

export default {
  props: {},
  data() {
    return {
      show: false, // Inicialmente el diálogo está cerrado
      consentGiven: false, // Si el usuario ha dado su consentimiento
      termsUrl: '', // Url del store donde se encuentran los terminos que debe aceptar
      privacyRoute: '/privacyPolicy', // Ruta interna para la política de privacidad
      cookiesRoute: '/cookiesPolicy', // Ruta interna para la política de cookies
      loading: false,
      legal: {},
    }
  },
  mounted() {
    // Abrimos el diálogo cuando el componente se monta
    this.show = true
    this.termsUrl = this.termsToAccept()
    //console.log('this.termsUrl=', this.termsUrl)
    this.legal = this.$store.state.user.legal
  },
  methods: {
    ...mapMutations(['setNavigationDrawer', 'setSnackbar']),
    ...mapActions(['logout']),
    async signOut() {
      this.setNavigationDrawer(false)
      await this.logout()
      this.$router.push({ name: 'LoginView' })
    },
    declineCloseDialog() {
      // Si no acepta los terminos hacemos un logout
      this.show = false
      this.signOut()
    },
    termsToAccept() {
      const url = this.getURLFromContract()
      //console.log('url userContractModel=', url)
      return url
    },
    getURLFromContract() {
      const userContractModel =
        this.$store.state.user?.legal?.contractModel ?? undefined
      //console.log('userContractModel=', userContractModel)
      //console.log('contractModel en store=', this.$store.state.contractModel)
      if (
        userContractModel &&
        userContractModel in this.$store.state.contractModel
      ) {
        return (
          this.$store.state.contractModel[userContractModel]?.contractPdfURL ??
          undefined
        )
      } else {
        return undefined
      }
    },
    getPdfRefFromContract() {
      const userContractModel =
        this.$store.state.user?.legal?.contractModel ?? undefined
      if (
        userContractModel &&
        userContractModel in this.$store.state.contractModel
      ) {
        return (
          this.$store.state.contractModel[userContractModel]?.contractPdfRef ??
          undefined
        )
      } else {
        return undefined
      }
    },
    getInfoPage(name) {
      this.$router.push({
        name,
      })
    },
    async updateAcceptance() {
      try {
        this.loading = true
        // Crea los atributos necesarios, asigna su valor y actualiza el objeto 'legal' del usuario
        this.legal.accepted = true
        this.legal.contractPdfRef = this.getPdfRefFromContract()
        await updateUserInfo(this.$store.state.user.id, {
          legal: this.legal,
        })

        // Y crea un traza con la evidencia, fecha y referencia al documento aceptado
        await createTrace(this.$store.state.user.id, {
          actionType: 'termsAccepted',
          createdAt: new Date(),
          contractPdfRef: this.legal.contractPdfRef,
        })

        this.loading = false
        this.show = false // cierra este dialogo modal
        const message = this.$t('legal.updateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.loading = false
        this.show = false // cierra este dialogo modal
        const message = this.$t('legal.updateError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
  computed: {
    ...mapState(['navigationDrawer']),
    ...mapGetters(['isMobile']),
    checkboxLabel() {
      let label = this.$t('legal.acceptTerms')
      const termsText = label.includes('Términos del Servicio')
        ? 'Términos del Servicio'
        : 'Terms of Service'
      label = label.replace(
        termsText,
        `<a href="${this.termsUrl}" target="_blank">${termsText}</a>`
      )

      const policyText = label.includes('Política de Privacidad')
        ? 'Política de Privacidad'
        : 'Privacy Policy'
      label = label.replace(
        policyText,
        `<a href="${this.privacyRoute}" @click.prevent="getInfoPage(privacyRoute)">${policyText}</a>`
      )

      const cookiesText = label.includes('Política de Cookies')
        ? 'Política de Cookies'
        : 'Cookies Policy'
      label = label.replace(
        cookiesText,
        `<a href="${this.cookiesRoute}" @click.prevent="getInfoPage(cookiesRoute)">${cookiesText}</a>`
      )

      return label
    },
  },
}
</script>

<style scoped>
.consent-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.large-checkbox .v-input--selection-controls__input {
  font-size: 1.2rem; /* Ajusta el tamaño del checkbox */
}

.large-checkbox-label {
  font-size: 1.2rem; /* Ajusta el tamaño del texto del label */
}

.v-card-actions {
  display: flex;
  justify-content: flex-start; /* Alineación a la izquierda */
}

.v-btn {
  margin-right: 10px;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
