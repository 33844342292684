<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.companies.export.title') }}
    </h1>
    <p>{{ $t('documentation.texts.companies.export.paragraph1') }}:</p>
    <ul>
      <li>
        -
        <b>
          {{ $t('documentation.texts.companies.export.paragraph1Li1Title') }}
        </b>
        : {{ $t('documentation.texts.companies.export.paragraph1Li1Text') }}
      </li>
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.companies.export.paragraph1Li2Title') }}
        </b>
        : {{ $t('documentation.texts.companies.export.paragraph1Li2Text') }}
      </li>
      <p></p>
      <v-img style="width: 100%; max-width: 500px" contain :src="pic1Name" />
    </ul>
    <p></p>
    <p>{{ $t('documentation.texts.companies.export.paragraph2') }}:</p>
    <ul>
      <li>
        -
        <b>
          {{ $t('documentation.texts.companies.export.paragraph2Li1Title') }}
        </b>
        : {{ $t('documentation.texts.companies.export.paragraph2Li1Text') }}
      </li>
      <!--  ELIMINAR COMENTARIO Y ACTUALIZAR PIC2 CUANDO TENGAMOS LA OPCIÓN DEL ZIP PARA TODAS LAS EMPRESAS
      <p></p>
      <li>
        -
        <b>
          {{ $t('documentation.texts.companies.export.paragraph2Li2Title') }}
        </b>
        : {{ $t('documentation.texts.companies.export.paragraph2Li2Text') }}
      </li>-->
      <p></p>
      <v-img style="width: 100%; max-width: 500px" contain :src="pic2Name" />
    </ul>

    <p></p>
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.export.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/companies/' +
        this.$t('documentation.texts.companies.export.pic2') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
