<template>
  <v-menu absolute offset-y style="max-width: 400px">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            color="primary"
            x-large
            icon
            v-bind="{ ...attrs, ...attrsTooltip }"
            v-on="{ ...on, ...onTooltip }"
            v-if="isAdminUser"
          >
            <v-icon> mdi-database-search </v-icon>
          </v-btn>
        </template>
        <div>
          {{ $t('notificationMetadata') }}
        </div>
      </v-tooltip>
    </template>
    <v-card class="portrait" width="400">
      <v-card-title>
        {{ $t('notificationMetadata') }}
      </v-card-title>
      <v-card-text>
        <div
          v-for="(metadata, i) in notification.metadatas"
          :key="'metadata-file-' + i"
        >
          <span
            v-for="(key, n) in Object.keys(metadata)"
            :key="'metadata-key-value-' + n"
          >
            - <b>{{ $t('metadata.' + key) }}</b
            >:{{
              key === 'tipo' &&
              !metadata.tipoXML &&
              metadata[key] !== 'AEAT_RE02'
                ? $t('metadata.types.' + metadata[key])
                : metadata[key]
            }}
            <br />
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    notification: Object,
  },
  computed: {
    ...mapGetters(['isAdminUser']),
  },
}
</script>
