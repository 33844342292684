<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="primary secondary--text"
        fab
        small
        @click="handleClick"
        id="edit-button"
      >
        <v-icon v-if="isBeingEdited"> mdi-close </v-icon>
        <v-icon v-else> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <div>
      {{ isBeingEdited ? $t('cancelUpdate') : editText }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    isBeingEdited: Boolean,
    editText: String,
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>
