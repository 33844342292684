const validDNI = function (dni) {
  var dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE'
  var letter = dni_letters.charAt(parseInt(dni, 10) % 23)

  return letter == dni.charAt(8)
}

const validNIE = function (nie) {
  // Change the initial letter for the corresponding number and validate as DNI
  var nie_prefix = nie.charAt(0)

  switch (nie_prefix) {
    case 'X':
      nie_prefix = 0
      break
    case 'Y':
      nie_prefix = 1
      break
    case 'Z':
      nie_prefix = 2
      break
  }

  return validDNI(nie_prefix + nie.substr(1))
}

const validNIFM = function (nifm) {
  // Removes first character and then check the number like DNI
  var nifm_letters = 'TRWAGMYFPDXBNJZSQVHLCKE'
  var letter = nifm_letters.charAt(parseInt(nifm.substr(1), 10) % 23)

  return letter == nifm.charAt(8)
}

const validCIF = function (cif) {
  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  const control = cif.substr(cif.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = Math.floor(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

const validNSS = function (nss) {
  const control = parseInt(nss.substr(nss.length - 2))
  let digits = nss.slice(0, -2)

  if (digits.substring(2, 3) === '0') {
    digits = digits.substring(0, 2) + digits.substring(3)
  }
  let dc = parseInt(digits) % 97

  return dc === control
}

module.exports = function (type, str) {
  var DNI_REGEX = /^(\d{8})([A-Z])$/
  var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
  var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/
  var NIFM_REGEX = /^[M]\d{7}[A-Z]$/
  var NSS_REGEX = /^[0-9]{11,12}$/

  // Ensure upcase and remove whitespace
  const idNumber = str.toUpperCase().replace(/\s/, '').replace(/-/g, '')
  type = type?.toUpperCase()

  if (idNumber.match(DNI_REGEX) && type === 'DNI') return validDNI(idNumber)
  else if (idNumber.match(NIE_REGEX) && type === 'NIE')
    return validNIE(idNumber)
  else if (idNumber.match(CIF_REGEX) && type === 'CIF')
    return validCIF(idNumber)
  else if (idNumber.match(NIFM_REGEX) && type === 'NIF-M')
    return validNIFM(idNumber)
  else if (idNumber.match(NSS_REGEX) && type === 'NSS')
    return validNSS(idNumber)
  else return false
}
