<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8">
        <v-img width="600" src="@/assets/login-logo.png" />
        <div class="pl-9">
          <div class="div-1"><b>503. </b> {{ $t('maintenanceTitle') }}</div>
          <br />
          <div class="div-1">
            {{ $t('maintenanceText') }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <v-img src="@/assets/maintenance.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  async mounted() {
    await this.logout()
  },
  methods: {
    ...mapActions(['logout']),
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
.div-1 {
  font-family: 'VT323', monospace;
  font-size: 25px;
}
</style>
