<template>
  <div>
    <div
      v-for="(progress, index) in items"
      :key="index"
      :class="{ 'mt-1': index > 0 }"
    >
      <div v-if="progress.isDisplayed">
        <v-progress-linear
          :model-value="progress.movement ? undefined : 100"
          :indeterminate="progress.movement"
          :color="progress.color"
          rounded
          :height="progress.message ? progress.height : 5"
        >
          <div class="text-container mx-4" ref="textElement">
            <strong v-html="formattedMessages[index]"></strong>
            <a
              v-if="progress.link"
              @click="$router.push(progress.link.route)"
              style="text-decoration: underline; margin-left: 10px"
            >
              <b>{{ $t(progress.link.text) }}</b>
            </a>
          </div>
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    formattedMessages() {
      return this.items.map((item) => {
        // Formatear el mensaje solo si tiene params, si no, usar la traducción del mensaje original
        item.formattedMessage = item.messageParams
          ? this.formatMessage(item.message, item.messageParams)
          : this.$t(item.message)
        return item.formattedMessage
      })
    },
  },
  methods: {
    handleResize() {
      this.items.forEach((item, index) => {
        // en el caso original de banner indicando que está actualizando, no hacer nada
        if (item.message == 'updatingNotificationsText') {
          // si aparece "Obteniendo nuevas notificaciones..." entonces saltar al siguiente elemento que hubiera
          return
        }

        // ajustar la altura del componente v-progress-linear
        // guardamos el valor original para poder restaurarlo mas tarde si es necesario
        if (item.originalHeight === undefined) {
          item.originalHeight = item.height
        }

        // Calcular la longitud de la parte visible del mensaje mensaje formateado/traducido (sin etiquetas html)
        //const visibleTextLength = this.getVisibleTextLength(
        //  item.formattedMessage
        //)

        // Seleccionar un elemento de texto para obtener los estilos
        const textElement = this.$refs.textElement[index]

        const textHeight = textElement ? textElement.offsetHeight : 0

        // Usa textHeight para calcular la altura del componente
        item.height = Math.max(
          textHeight,
          item.originalHeight || item.originalHeight
        )
      })
    },
    formatMessage(message, messageParams) {
      let translatedMessage = this.$t(message, messageParams)

      // Extraer los parámetros de estilo
      const { hightlightTextColor, hightlightTextBackground } = messageParams
      // Asignar valores cuando vengan en los parámetros de estilo
      const styleColor = hightlightTextColor
        ? `color: ${hightlightTextColor} !important;`
        : ''
      const styleBackground = hightlightTextBackground
        ? `background-color: ${hightlightTextBackground};`
        : ''

      // Buscar y resaltar fechas en formato dd/mm/aaaa
      translatedMessage = translatedMessage.replace(
        /\b\d{2}\/\d{2}\/\d{4}\b/g,
        (match) => {
          return `<span class="highlight--custom" style="${styleColor} ${styleBackground}">${match}</span>`
        }
      )
      // Buscar y resaltar numeros de dos digitos que no formen parte de una fecha
      translatedMessage = translatedMessage.replace(
        /(?<!\/)\b\d{2}\b(?!\/)/g,
        (match) => {
          return `<span class="highlight--custom" style="${styleColor} ${styleBackground}">${match}</span>`
        }
      )
      return translatedMessage
    },
    getVisibleTextLength(message) {
      // Eliminar etiquetas HTML para contar solo el texto visible
      return message.replace(/<[^>]*>/g, '').length
    },
  },
}
</script>

<style scoped>
.text-container {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

::v-deep .highlight--custom {
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 4px;
}
</style>
