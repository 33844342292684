<template>
  <v-list
    v-if="notificationList.length != 0"
    subheader
    two-line
    height="450"
    :style="{ 'overflow-y': scrollList }"
  >
    <v-list-item
      style="overflow-x: hidden"
      v-for="notification in notificationList"
      :key="notification.id"
      @click="
        $router.push({
          name: 'NotificationDetailView',
          params: {
            id: notification.id,
          },
        })
      "
    >
      <v-list-item-avatar>
        <v-icon :class="getNotificationStatus(notification).color" dark>
          {{ getNotificationType(notification).icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-row>
        <v-col cols="8">
          <span> {{ conceptoText(notification.concepto) }} </span>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-btn
            class="mx-3"
            color="accept"
            icon
            plain
            @click.stop="() => acceptNotification(notification)"
            id="accept-responsability"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            class="mx-3"
            color="error"
            icon
            plain
            @click.stop="() => declineNotification(notification)"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list>
  <v-container v-else style="text-align: center">
    <b>
      <span> {{ $t('emptyTypeNotificationList') }} </span>
    </b>
  </v-container>
</template>
<script>
import {
  getNotificationStatus,
  getNotificationType,
  managerAcceptNotification,
  managerDeclineNotification,
} from '@/services/notification-service'

export default {
  props: {
    notificationList: {
      type: Array,
    },
  },
  methods: {
    getNotificationStatus(notification) {
      return getNotificationStatus(notification)
    },
    getNotificationType(notification) {
      return getNotificationType(notification)
    },
    conceptoText(concepto) {
      return concepto.length > 30 ? concepto.substring(0, 27) + '...' : concepto
    },
    async acceptNotification(notification) {
      await managerAcceptNotification(notification.id)
    },
    async declineNotification(notificacion) {
      await managerDeclineNotification(notificacion.id)
    },
  },
  computed: {
    scrollList() {
      return this.notificationList.length > 5 ? 'scroll' : 'hidden'
    },
  },
}
</script>
