<template>
  <v-row class="d-flex justify-start pa-0 ma-0" v-if="!isMobile">
    <DocumentationListIndex />
  </v-row>
  <v-row v-else>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" absolute left temporary>
      <DocumentationListIndex />
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentationListIndex from '@/components/documentation/DocumentationListIndex'
export default {
  components: {
    DocumentationListIndex,
  },
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style></style>
