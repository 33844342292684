<template>
  <v-dialog :value="true" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-col cols="11">
          {{ $t('pendingToAuthorizeTitle') }}
        </v-col>
        <v-col cols="1">
          <v-btn @click="closeDialog" icon>
            <v-icon> mdi-window-close </v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <p>{{ $t('pendingToAuthorizeMessage') }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="goToCompanies">
          {{ $t('goToCompanies') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    /**
     * Calls to the parent function to close the dialog.
     */
    closeDialog() {
      this.$emit('close')
    },
    goToCompanies() {
      this.$router.push({ name: 'CifsView' })
      this.$emit('close')
    },
  },
}
</script>

<style>
.my-checkbox .v-label {
  font-size: 15px;
}
</style>
