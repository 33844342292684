<template>
  <div>
    <v-card max-width="1200" data-v-step="11" class="mx-auto" outlined>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="6" xl="auto" class="order-0">
            <v-row align="baseline" class="ml-1">
              <v-icon class="mr-1">mdi-account-group</v-icon>
              <span class="headline">{{ $t('groups') }}</span>
              <span class="subtitle-2 font-weight-light ml-1">
                ({{ groupsCount }})
              </span>
              <div class="mx-2" />
              <DialogButton
                section="groups"
                subsection="main"
                :title="$t('groups')"
                hideSubtitle
              />
            </v-row>
          </v-col>
          <v-col
            class="d-flex justify-end align-center order-1 order-xl-2"
            cols="6"
            xl="auto"
          >
            <v-tooltip bottom v-if="isAdminUser">
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="primary secondary--text ml-1 mt-1 mb-1"
                  fab
                  elevation="1"
                  small
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  :disabled="loading"
                  @click.stop="openGroupDialog({}, 'create')"
                  id="create-group"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('groupActions.title.create') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>

      <v-list-item>
        <v-list-item-content>
          <v-row class="pa-3">
            <v-text-field
              class="mr-1"
              v-model="search"
              prepend-icon="mdi-magnify"
              clearable
              :label="$t('searchGroups')"
              single-line
              hide-details
              :disabled="loading"
            />
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <AppSkeleton :condition="loading" type="groupList">
        <template v-slot:skeleton-content>
          <v-list v-if="groups.length" max-height="400" class="overflow-y-auto">
            <v-list-item
              :key="i"
              v-for="(group, i) in pageGroups"
              @click.stop="openGroupDialog(group, 'display')"
              :id="'at-' + group.name"
            >
              <v-row
                :class="isMobile ? 'mb-3 px-4' : ''"
                justify="center"
                align="center"
              >
                <v-col cols="9">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ group.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-col>

                <v-col cols="3" md="3" v-if="isAdminUser">
                  <v-row justify="end" no-gutters>
                    <!-- Warning icon -->
                    <v-tooltip
                      bottom
                      v-if="
                        group.unregisteredCifs &&
                        group.unregisteredCifs.length > 0
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'warning-unregistered-group-' + group.name"
                          @click.stop="openUnregisteredGroupDialog(group)"
                        >
                          <v-icon class="mx-2"> mdi-alert </v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('warningUnregisteredCifs') }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'update-group-' + group.name"
                          @click.stop="openGroupDialog(group, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('groupActions.title.update') }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :id="'delete-group-' + group.name"
                          @click.stop="openGroupDialog(group, 'delete')"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('groupActions.title.delete') }}
                      </div>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-card-text style="text-align: center" v-else>
            <span>
              {{ $t('noGroups') }}
            </span>
          </v-card-text>
        </template>
      </AppSkeleton>
      <v-divider />
      <v-card-actions class="headline justify-end" style="max-height: 50px">
        <PaginateBar
          :initialPageSize="10"
          :pageSizes="[10, 25, 50]"
          :search="search"
          :items="filteredGroups"
          @setPage="(value) => (page = value)"
          @setPageSize="(value) => (pageSize = value)"
        />
      </v-card-actions>
    </v-card>

    <GroupDialog
      v-if="groupDialog"
      :groups="groups"
      :group="selectedGroup"
      :operators="groupLessOperators"
      :filters="filters"
      :cifs="cifs"
      :action="action"
      :mappedOperators="mappedOperators"
      :mappedCifs="mappedCifs"
      @closeDialog="() => (groupDialog = false)"
    />
    <GroupUnregisteredDialog
      v-if="groupUnregisteredDialog"
      :group="selectedGroup"
      @closeDialog="() => (groupUnregisteredDialog = false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import GroupDialog from './GroupDialog.vue'
import GroupUnregisteredDialog from './GroupUnregisteredDialog.vue'
import { mapOperatorsAndGroups } from '@/services/operator-service'
import { mapCifsAndGroups } from '../../services/cifs-service'
import PaginateBar from '@/components/commons/PaginateBar'

export default {
  props: {
    groups: Array,
    operators: Array,
    filters: Array,
    cifs: Array,
    loading: Boolean,
  },
  components: {
    DialogButton,
    GroupDialog,
    GroupUnregisteredDialog,
    PaginateBar,
  },
  data() {
    return {
      page: 0,
      pageSize: 0,
      search: '',
      selectedGroup: undefined,
      action: '',
      groupDialog: false,
      groupUnregisteredDialog: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Opens the dialog to create/update/display/delete a group.
     * @param {Object} group Group to open (empty when creating)
     * @param {String} action Action to perform (create, update, display, delete).
     */
    openGroupDialog(group, action) {
      this.selectedGroup = group
      this.action = action

      this.groupDialog = true
    },
    /**
     * Opens the unregistered groups dialog.
     * @param {Object} group Group to open.
     */
    openUnregisteredGroupDialog(group) {
      this.selectedGroup = group
      this.groupUnregisteredDialog = true
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser']),
    groupsCount() {
      return this.groups?.length ?? 0
    },
    filteredGroups() {
      const searchUpperCase = this.search ? this.search.toUpperCase() : ''
      let filtered = this.groups?.filter((group) =>
        group.name?.toUpperCase().includes(searchUpperCase)
      )
      return filtered
    },
    pageGroups() {
      return this.filteredGroups.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      )
    },
    mappedOperators() {
      if (this.operators) return mapOperatorsAndGroups(this.operators)
      else return {}
    },
    mappedCifs() {
      if (this.cifs) return mapCifsAndGroups(this.cifs)
      else return {}
    },
    groupLessOperators() {
      let groupLessOperators = this.operators.filter(
        (operator) => !operator.groupsRef || operator.groupsRef?.length === 0
      )

      // If there is a group selected we add the operators in that group
      if (this.selectedGroup)
        return [
          ...(this.mappedOperators[this.selectedGroup.id] ?? []),
          ...groupLessOperators,
        ]
      else return groupLessOperators
    },
  },
}
</script>
<style></style>
