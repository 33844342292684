<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.clients.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.clients.upload.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph1') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph2') }}
    </p>
    <v-img
      style="width: 100%; max-width: 700px; align-self: center"
      contain
      :src="pic1Name"
    />
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph3') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic2Name" />
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph5') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph6') }}
    </p>
    <v-img style="width: 100%; max-width: 800px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph7') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph7-1') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph7-2') }}
    </p>
    <v-img style="width: 100%; max-width: 800px" contain :src="pic4Name" />
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph9') }}
    </p>
    <p>
      {{ $t('documentation.texts.clients.upload.paragraph10') }}
    </p>
    <v-img style="width: 100%; max-width: 800px" contain :src="pic5Name" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.upload.pic1') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.upload.pic2') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.upload.pic3') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.upload.pic4') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/clients/' +
        this.$t('documentation.texts.clients.upload.pic5') +
        '.png')
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
