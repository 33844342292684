<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <OperatorList
          :operators="operators"
          :companies="companies"
          :filters="filters"
          :groupsMap="groupsMap"
          :groups="groups"
          :cifsMap="cifsMap"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import OperatorList from '@/components/operators/OperatorList'
import { getCifs } from '@/services/cifs-service'
import { listFilters } from '@/services/filter-service'
import { getOperatorsSubscription } from '@/services/operator-service'
import { getGroups } from '../services/groups-service'

export default {
  components: {
    OperatorList,
  },
  async beforeMount() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    if (!this.$store.state.manageOperators)
      await getOperatorsSubscription(userId)

    await Promise.all([
      (async () => {
        this.companies = await getCifs(userId)
        this.companies.forEach((cif) => (this.cifsMap[cif.id] = cif))
      })(),
      (async () => (this.filters = await listFilters()))(),
      (async () => {
        const { groups, groupsMap } = await getGroups()
        this.groups = groups
        this.groupsMap = groupsMap
      })(),
    ])

    this.loading = false
  },
  data() {
    return {
      companies: [],
      filters: [],
      groups: [],
      groupsMap: {},
      cifsMap: {},
      loading: true,
    }
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    operators() {
      return this.$store.state.manageOperators ?? []
    },
  },
}
</script>
