import i18n from '@/i18n'
import { today, ninetyDays } from '@/utils/date-utils'

export const certificateColor = (certificateExpiration) => {
  if (!certificateExpiration) return 'green'

  const certExpiration = certificateExpiration.toISOString().slice(0, 10)
  if (certExpiration < today()) return 'red'
  if (certExpiration < ninetyDays()) return 'orange'
  return 'green'
}

export const certificateTitle = (certificateExpiration) => {
  if (!certificateExpiration) return i18n.t('alredyUploadCertificate')

  const certExpiration = certificateExpiration.toISOString().slice(0, 10)

  if (certExpiration < today())
    return i18n.t('certificateExpiration.alreadyExpired')
  if (certExpiration < ninetyDays())
    return i18n.t('certificateExpiration.nearToExpiration')

  return i18n.t('alredyUploadCertificate')
}
