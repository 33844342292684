<template>
  <div id="test" class="ma-2">
    <v-row class="justify-center">
      <v-col
        col="1"
        v-if="
          data !== undefined &&
          data.notificationsPaid !== undefined &&
          data.notificationsPaid !== 0
        "
      >
        <v-card
          class="mx-auto ma-1"
          variant="tonal"
          :color="progressColor(data.notificationsUsed, data.notificationsPaid)"
        >
          <v-card-title class="pa-1">
            <v-icon color="secondary" style="flex: auto">mdi-mailbox</v-icon>
          </v-card-title>

          <v-card-subtitle
            class="ma-1 justify-center"
            color="secondary"
            style="color: white; display: flex; flex: auto; font-size: 1em"
          >
            {{ $t('resumeCard.card1') }}
          </v-card-subtitle>

          <v-card-text
            class="justify-center"
            style="color: white; display: flex; flex: auto; font-size: 1.5em"
            >{{ data.notificationsUsed }} /
            {{ data.notificationsPaid }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col
        col="1"
        v-if="
          data !== undefined &&
          data.companiesPaid !== undefined &&
          data.companiesPaid !== 0
        "
      >
        <v-card
          class="mx-auto ma-1"
          variant="tonal"
          :color="progressColor(data.companiesUsed, data.companiesPaid)"
        >
          <v-card-title class="pa-1">
            <v-icon color="secondary" style="flex: auto">mdi-city</v-icon>
          </v-card-title>

          <v-card-subtitle
            class="ma-1 justify-center"
            color="secondary"
            style="color: white; display: flex; flex: auto; font-size: 1em"
          >
            {{ $t('resumeCard.card2') }}
          </v-card-subtitle>

          <v-card-text
            class="justify-center"
            style="color: white; display: flex; flex: auto; font-size: 1.5em"
            >{{ data.companiesUsed }} / {{ data.companiesPaid }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col
        col="1"
        v-if="
          data !== undefined &&
          data.signsPaid !== undefined &&
          data.signsPaid !== 0
        "
      >
        <v-card
          class="mx-auto ma-1"
          variant="tonal"
          :color="progressColor(data.signsUsed, data.signsPaid)"
        >
          <v-card-title class="pa-1">
            <v-icon color="secondary" style="flex: auto">mdi-draw</v-icon>
          </v-card-title>

          <v-card-subtitle
            class="ma-1 justify-center"
            color="secondary"
            style="color: white; display: flex; flex: auto; font-size: 1em"
          >
            {{ $t('resumeCard.card3') }}
          </v-card-subtitle>

          <v-card-text
            class="justify-center"
            style="color: white; display: flex; flex: auto; font-size: 1.5em"
            >{{ data.signsUsed }} / {{ data.signsPaid }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col
        col="1"
        v-if="
          data !== undefined &&
          data.certificatesPaid !== undefined &&
          data.certificatesPaid !== 0
        "
      >
        <v-card
          class="mx-auto ma-1"
          variant="tonal"
          :color="progressColor(data.certificatesUsed, data.certificatesPaid)"
        >
          <v-card-title class="pa-1">
            <v-icon color="secondary" style="flex: auto"
              >mdi-account-key</v-icon
            >
          </v-card-title>

          <v-card-subtitle
            class="ma-1 justify-center"
            color="secondary"
            style="color: white; display: flex; flex: auto; font-size: 1em"
          >
            {{ $t('resumeCard.card4') }}
          </v-card-subtitle>

          <v-card-text
            class="justify-center"
            style="color: white; display: flex; flex: auto; font-size: 1.5em"
            >{{ data.certificatesUsed }} /
            {{ data.certificatesPaid }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col col="1" v-if="operators !== undefined && operators.length !== 0">
        <v-card class="mx-auto ma-1" variant="tonal" color="primary">
          <v-card-title class="pa-1">
            <v-icon color="secondary" style="flex: auto">mdi-account</v-icon>
          </v-card-title>

          <v-card-subtitle
            class="ma-1 justify-center"
            color="secondary"
            style="color: white; display: flex; flex: auto; font-size: 1em"
          >
            {{ $t('resumeCard.card5') }}
          </v-card-subtitle>

          <v-card-text
            class="justify-center"
            style="color: white; display: flex; flex: auto; font-size: 1.5em"
            >{{ operators.length }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    operators: [],
    planData: {},
  },
  data() {
    //console.log('componente',this.planData)
    return {}
  },
  computed: {
    data() {
      //console.log('componente', this.planData)
      return this.planData
    },
  },
  methods: {
    progressColor(current, max) {
      const percent = this.progressByBuffer
        ? this.buffer
        : (100 / max) * current

      if (this.disabled) return 'grey'
      else if (percent >= 0 && percent < 25) return 'primary'
      else if (percent >= 25 && percent < 50) return 'blue'
      else if (percent >= 50 && percent < 75) return '#BFC42B'
      else if (percent >= 75 && percent < 100) return 'orange'
      else return 'red'
    },
  },
  watch: {},
}
</script>
