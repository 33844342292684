var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppSkeleton',{attrs:{"condition":_vm.cifs === undefined,"max-width":"300","type":"card"},scopedSlots:_vm._u([{key:"skeleton-content",fn:function(){return [_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","color":"transparent"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"noteBoxes typeBlue",staticStyle:{"display":"flex"}},[_c('img',{staticClass:"note-box-icon",attrs:{"src":require("@/assets/info.png")}}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"baseline","justify-content":"space-between"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('cifsNotRegistered')))]),_c('v-checkbox',{staticClass:"pa-0 ma-0 ml-9",attrs:{"label":_vm.$t('showHiddenCifs')},model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}})],1),_c('p'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('cifsNotRegisteredInfo'))+" ")]),_c('ul',_vm._l((_vm.filteredCifs),function(cif){return _c('li',{key:cif.cif},[_c('span',{style:({
                        color: cif.rejected
                          ? 'grey'
                          : !_vm.$vuetify.theme.dark
                          ? '#000000DE'
                          : '#FFFFFF',
                      })},[_vm._v(" • "+_vm._s(cif.name)+" ("+_vm._s(cif.cif)+") ")]),_c('v-btn',{attrs:{"plain":"","small":"","loading":_vm.loadingCreate,"disabled":_vm.loadingCreate || _vm.loadingIgnore},on:{"click":function($event){return _vm.createCompany(cif)}}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]),(!cif.rejected)?_c('v-btn',{attrs:{"plain":"","small":"","loading":_vm.loadingIgnore,"disabled":_vm.loadingCreate || _vm.loadingIgnore},on:{"click":function($event){return _vm.updateIgnore(cif, true)}}},[_vm._v(" "+_vm._s(_vm.$t('ignore'))+" ")]):_vm._e(),(cif.rejected)?_c('v-btn',{attrs:{"plain":"","small":"","loading":_vm.loadingIgnore,"disabled":_vm.loadingCreate || _vm.loadingIgnore},on:{"click":function($event){return _vm.updateIgnore(cif, false)}}},[_vm._v(" "+_vm._s(_vm.$t('show'))+" ")]):_vm._e()],1)}),0),_c('p')])])])],1)],1)]},proxy:true}])}),(_vm.cifDialog)?_c('CifDialog',{attrs:{"cifs":_vm.cifs,"groups":_vm.groups,"groupMap":_vm.groupMap,"cif":_vm.selectedCif,"action":'createUnregistered',"title":'companiesActions.title.createUnregistered',"notificationsMaxCifs":_vm.notificationsMaxCifs,"cifsCountNotifications":_vm.cifsCountNotifications},on:{"closeCifDialog":() => (_vm.cifDialog = false)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }