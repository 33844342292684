<template>
  <v-card
    data-v-step="704"
    elevation="10"
    class="mx-auto"
    min-height="740"
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    "
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-row class="mt-2 pt-2 pl-3 pr-3">
            <v-select
              item-disabled="disable"
              dense
              item-text="displayName"
              :items="documents"
              :label="$t('files')"
              return-object
              v-model="selectedFile"
            >
              <template v-slot:selection="{ item }">
                <span>{{ getShortName(item.name) }}</span>
              </template>
            </v-select>
            <v-tooltip v-if="getDocErrorFlag" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 mr-2"
                  large
                  color="warning"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span> {{ $t('missingDocWarning') }}</span>
            </v-tooltip>
            <v-dialog transition="dialog-bottom-transition" max-width="1300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" v-if="!isMobile">
                  <v-icon>mdi-arrow-expand-all</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card min-height="780" max-height="90%">
                  <v-toolbar color="primary" dark>
                    <v-row align="center">
                      <v-col cols="10">
                        <h3>
                          {{ $t('signatureFiles') }}
                        </h3>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end">
                        <v-btn @click="dialog.value = false" icon>
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-col
                    class="pa-0 ma-0"
                    cols="12"
                    style="
                      height: 748px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                    align="center"
                  >
                    <v-row class="mt-2 d-flex justify-center">
                      <v-select
                        class="mt-5"
                        item-disabled="disable"
                        dense
                        item-text="displayName"
                        :items="documents"
                        :label="$t('files')"
                        return-object
                        v-model="selectedFile"
                        style="max-width: 80%"
                      ></v-select>
                      <v-tooltip v-if="getDocErrorFlag" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2 mr-2"
                            large
                            color="warning"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span> {{ $t('missingDocWarning') }}</span>
                      </v-tooltip>
                    </v-row>
                    <v-col v-if="loadingData" class="ma-0" cols="12" sm="12">
                      <v-progress-linear
                        indeterminate
                        color="primary"
                      ></v-progress-linear>
                    </v-col>
                    <v-col
                      class="pa-0 ma-0"
                      cols="12"
                      style="height: 568px; overflow-y: scroll"
                    >
                      <SignatureDocumentViewer
                        v-if="selectedFile.file"
                        :selectedFile="selectedFile"
                      />
                    </v-col>
                  </v-col>
                  <v-card-actions>
                    <component
                      :is="isMobile ? 'v-col' : 'v-row'"
                      :no-gutters="!isMobile"
                      :class="!isMobile ? 'flex-wrap' : ''"
                      justify="center"
                      align="center"
                    >
                      <v-btn
                        :disabled="!Object.keys(selectedFile).length"
                        @click="downloadSelectedFile()"
                        color="primary"
                        class="ma-2 secondary--text"
                        id="download-selected"
                      >
                        {{ $t('downloadFile') }}
                        <v-icon right dark> mdi-file-download-outline </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="
                          loadingData ||
                          !documents.filter((o) => o.disable === false).length
                        "
                        @click="downloadAllFiles()"
                        color="primary"
                        class="ma-2 secondary--text"
                        id="download-all"
                      >
                        {{ $t('downloadAll') }}
                        <v-icon right dark>
                          mdi-folder-arrow-down-outline
                        </v-icon>
                      </v-btn>
                    </component>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-row>
        </v-col>
        <v-col v-if="loadingData" class="ma-0" cols="12" sm="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
        <v-col
          class="pa-0 ma-0"
          cols="12"
          style="height: 568px; overflow-y: scroll"
        >
          <SignatureDocumentViewer
            v-if="selectedFile.file"
            :selectedFile="selectedFile"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <component
        :is="isMobile ? 'v-col' : 'v-row'"
        :no-gutters="!isMobile"
        :class="!isMobile ? 'flex-wrap' : ''"
        justify="center"
        align="center"
      >
        <v-btn
          :disabled="!Object.keys(selectedFile).length"
          @click="downloadSelectedFile()"
          color="primary"
          class="ma-2 secondary--text"
          id="download-selected"
        >
          {{ $t('downloadFile') }}
          <v-icon right dark> mdi-file-download-outline </v-icon>
        </v-btn>
        <v-btn
          :disabled="
            loadingData || !documents.filter((o) => o.disable === false).length
          "
          @click="downloadAllFiles()"
          color="primary"
          class="ma-2 secondary--text"
          id="download-all"
        >
          {{ $t('downloadAll') }}
          <v-icon right dark> mdi-folder-arrow-down-outline </v-icon>
        </v-btn>
      </component>
    </v-card-actions>
  </v-card>
</template>

<script>
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'
import { signatureDocument } from '@/services/signatures-service'
import SignatureDocumentViewer from '@/components/commons/SignatureDocumentViewer'
import { VRow, VCol } from 'vuetify/lib'

export default {
  props: {
    signature: Object,
  },
  components: {
    SignatureDocumentViewer,
    VRow,
    VCol,
  },
  watch: {
    signature: function () {
      this.getDocuments(this.signature)
    },
  },
  mounted() {
    this.documents = []
    this.getDocuments(this.signature)
  },
  data() {
    return {
      documents: [],
      loadingData: false,
      selectedFile: {},
      getDocErrorFlag: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    async getDocuments(signature) {
      this.loadingData = true
      //get all docs sorted by first needSignature converted into blob from base64 string codification to be displayed in visor component
      const signatureDocs = signature.documents.sort((a, b) =>
        a.needSignature === b.needSignature ? 0 : a.needSignature ? -1 : 1
      )

      for (const doc of signatureDocs) {
        try {
          const mainDoc = {
            file: new Blob(
              [
                Buffer.from(
                  await signatureDocument(signature, {
                    dmsDocId: doc.dmsDocId,
                    dmsDocVersion: doc.dmsDocVersion,
                  }),
                  'base64'
                ),
              ],
              {
                type: doc.mimeType,
              }
            ),
            name: doc.name,
            displayName:
              `${doc.addedDocument ? '➕' : ''}` +
              `${doc.needSignature ? '📝' : '📎'} ${doc.name}`,
            disable: false,
            needSignature: doc.needSignature,
          }
          this.documents.push(mainDoc)
        } catch (error) {
          this.documents.push({
            name: doc.name,
            disable: true,
          })
          this.getDocErrorFlag = true
        }
      }

      this.selectedFile = this.documents.filter((o) => o.disable === false)[0] //Use the first enable element as selectedFile
      if (!this.selectedFile) this.selectedFile = {}

      this.loadingData = false
    },
    async downloadAllFiles() {
      try {
        this.loadingData = true
        this.documents.forEach((element) => {
          if (element.file) {
            saveAs(element.file, element.name)
          }
        })
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        /*
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: "error",
          message,
        })
        */
      }
    },
    async downloadSelectedFile() {
      try {
        this.loadingData = true
        if (this.selectedFile.file) {
          saveAs(this.selectedFile.file, this.selectedFile.name)
        }
        this.loadingData = false
      } catch (err) {
        this.loadingData = false
        /*
        const message = getErrorText(err.toString())
        this.setSnackbar({
          position: 'top',
          type: "error",
          message,
        })
        */
      }
    },
    /**
     * Function to short the name.
     * @param {String} name Name to short.
     * @returns Shortened string
     */
    getShortName(name) {
      return name.length > 16 ? name.substring(0, 16) + '...' : name
    },
  },
}
</script>

<style>
.v-select__selection.v-select__selection--comma {
  font-size: 17.5px;
}
</style>
