<template>
  <v-select
    v-model="operator"
    :label="$t('operators')"
    :items="operators"
    variant="underlined"
    item-value="id"
    :rules="[notEmpty]"
  >
    <template v-slot:selection="{ item }">
      {{ item.name + ' ' + item.surname }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.name + ' ' + item.surname }}
    </template>
  </v-select>
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    operators: Array,
  },
  data() {
    return {
      operator: undefined,
    }
  },
  methods: {
    ...rules,
  },
  watch: {
    operator: function (newValue) {
      if (newValue) this.$emit('setOperator', newValue)
    },
  },
}
</script>
