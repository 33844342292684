<template>
  <v-form ref="alertForm" v-model="validAlertForm" lazy-validation>
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="
        initAlert
        userIsEditing = alert.date && alert.text ? false : true
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span :class="`${alertStatus.color}--text`">
          {{ !!asset.alert ? $d(new Date(asset.alert.date), 'i18nDate') : '' }}
        </span>

        <v-spacer></v-spacer>
        <v-btn
          v-if="
            !isOperatorUser && (asset.alert || (!asset.alert && !isDisabled))
          "
          id="alert-button"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="asset.alert" large :color="alertStatus.color">
            {{ alertStatus.icon }}
          </v-icon>
          <v-icon
            v-else-if="!asset.alert && !isDisabled"
            large
            color="warningLow"
          >
            mdi-bell-outline
          </v-icon>
        </v-btn>
        <v-icon
          v-if="!isOperatorUser && !asset.alert && isDisabled"
          large
          color="warningLow"
        >
          mdi-bell-outline
        </v-icon>

        <v-btn
          v-if="
            isOperatorUser && (asset.alert || (!asset.alert && !isDisabled))
          "
          id="alert-button"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon large :color="alertStatus.color">
            {{ alertStatus.icon }}
          </v-icon>
        </v-btn>
      </template>

      <!--  <v-card v-if="isDisabled">-->
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!isDisabled">
          <v-row justify="space-between" align="center" width="100">
            <v-col no-gutters>
              {{
                asset.alert
                  ? userIsEditing
                    ? $t('modifyAlert')
                    : $t('alert')
                  : $t('createAlert')
              }}
            </v-col>
            <v-col class="d-flex justify-end" no-gutters>
              <v-btn
                v-if="!isDisabled && asset.alert"
                class="primary secondary--text"
                fab
                small
                @click="clickEditUserInfo"
              >
                <v-icon id="close-alert" v-if="userIsEditing">
                  mdi-close
                </v-icon>
                <v-icon id="modify-alert" v-else> mdi-pencil </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="alert.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="alert.date"
                    :label="$t('selectDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isDisabled || !userIsEditing"
                    :rules="dateRules"
                    id="alert-date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @click:date="$refs.menu.save(alert.date)"
                  v-model="alert.date"
                  :locale="$t('javascriptLocale')"
                  :min="new Date().toISOString().substring(0, 10)"
                  no-title
                  scrollable
                  :rules="dateRules"
                >
                  <!--<v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('clear') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(alert.date)"
                    id="confirm-alert-date"
                  >
                    {{ $t('ok') }}
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <p v-if="isDisabled || !userIsEditing">
                {{ asset.alert?.text }}
              </p>
              <v-textarea
                v-else
                v-model="alert.text"
                :label="$t('alertText')"
                :disabled="isDisabled || !userIsEditing"
                :rules="textRules"
                auto-grow
                id="alert-text"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            v-if="
              asset.alert &&
              !userIsEditing &&
              (!isOperatorUser || $store.state.user.processNotification)
            "
            color="primary"
            text
            @click="alertDone"
          >
            <v-icon>mdi-check</v-icon> {{ $t('markAsCompleted') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alertConfirm"
            v-if="userIsEditing && !isDisabled"
            :disabled="!userIsEditing"
            id="confirm-alert"
          >
            {{ asset.alert ? $t('modifyAlert') : $t('createAlert') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <!--- <v-card v-else>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('noProcessPermissionTitle') }}
        </v-card-title>
        <v-card-text>
          {{ $t('noProcessPermissionText') }}
        </v-card-text>
        <v-divider></v-divider>
      </v-card>-->
    </v-dialog>
  </v-form>
</template>

<script>
import {
  getAlertStatus,
  alertCreate,
  alertUpdate,
  alertDone,
} from '@/services/alert-service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    asset: Object,
    isDisabled: Boolean,
    objectType: String,
  },
  mounted() {
    this.initAlert()
  },
  watch: {
    asset: function () {
      this.initAlert()
    },
  },
  data() {
    return {
      alertCompleted: false,
      dialog: false,
      alert: { date: '', text: '' },
      menu: false,
      validAlertForm: false,
      dateRules: [() => this.alert.date !== ''],
      textRules: [() => this.alert.text !== ''],
      userIsEditing: false,
    }
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    alertStatus() {
      return getAlertStatus(this.asset.alert)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    clickEditUserInfo() {
      this.initAlert()
      this.userIsEditing = !this.userIsEditing
    },
    initAlert() {
      if (this.asset.alert) {
        this.alert = {
          date: new Date(this.asset.alert.date).toISOString().substring(0, 10),
          text: this.asset.alert.text,
        }
      } else {
        this.userIsEditing = true
        this.alert = {
          date: '',
          text: '',
        }
      }
    },
    async alertConfirm() {
      if (!this.$refs.alertForm?.validate()) {
        return
      }

      try {
        // Si no hay alerta creamos evento en el historial de create
        const message = this.asset.alert
          ? this.$t('alertUpdatedSuccessfully')
          : this.$t('alertCreatedSuccessfully')

        this.asset.alert
          ? await alertUpdate(
              this.asset.id,
              {
                date: new Date(this.alert.date),
                text: this.alert.text,
              },
              this.objectType
            )
          : await alertCreate(
              this.asset.id,
              {
                date: new Date(this.alert.date),
                text: this.alert.text,
              },
              this.objectType
            )
        this.dialog = false
        this.userIsEditing = false

        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.asset.alert
          ? this.$t('alertUpdatedError')
          : this.$t('alertCreatedError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    async alertDone() {
      try {
        this.dialog = false
        await alertDone(
          this.asset.id,
          {
            date: new Date(this.alert.date),
            text: this.alert.text,
          },
          this.objectType
        )
        const message = this.$t('alertCompletedSuccessfully')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        this.dialog = true
        const message = this.$t('alertCompletedError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
