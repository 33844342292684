<template>
  <v-row>
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="$t('selectDate')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :rules="[notEmpty]"
            id="alert-date"
          />
        </template>
        <v-date-picker
          @click:date="$refs.menu.save(date)"
          v-model="date"
          :locale="$t('javascriptLocale')"
          :min="new Date().toISOString().substring(0, 10)"
          no-title
          scrollable
          :rules="[notEmpty]"
        />
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="text"
        :label="$t('alertText')"
        :disabled="disabled"
        :rules="[notEmpty]"
        counter
        no-resize
        id="alert-text"
        rows="5"
      />
    </v-col>
  </v-row>
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    operators: Array,
    disabled: Boolean,
  },
  data() {
    return {
      alert: { date: '', text: '' },
      date: '',
      text: '',
      menu: false,
      validAlertForm: false,
    }
  },
  methods: {
    ...rules,
  },
  watch: {
    date: function (newValue) {
      if (newValue) {
        this.alert.date = newValue
        this.$emit('setAlert', this.alert)
      }
    },
    text: function (newValue) {
      if (newValue) {
        this.alert.text = newValue
        this.$emit('setAlert', this.alert)
      }
    },
  },
}
</script>
