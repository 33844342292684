import { Storage } from '@/firebase-exports'
import store from '@/store/index'

/**
 * Sube un archivo a Firebase Storage.
 *
 * @param {File} fileObject - El objeto de archivo que se va a subir.
 * @param {string} fullName - El nombre completo del archivo, incluyendo la ruta en Firebase Storage.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de carga.
 */
export async function uploadFile(fileObject, fullName) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fullName)
  return Storage.uploadBytes(fileRef, fileObject)
}

/**
 * Elimina un archivo de Firebase Storage.
 *
 * @param {string} name - El nombre del archivo a eliminar, incluyendo la ruta en Firebase Storage.
 * @returns {Promise<void>} Una promesa que se resuelve cuando el archivo ha sido eliminado.
 */
export async function removeFile(name) {
  if (!name) return
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, name)
  return await Storage.deleteObject(fileRef)
}

/**
 * Elimina una carpeta y su contenido de Firebase Storage.
 *
 * @param {string} folderReference - La referencia de la carpeta a eliminar, incluyendo su ruta en Firebase Storage.
 * @param {boolean} [recursive=true] - Indica si la eliminación debe ser recursiva para subcarpetas.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la carpeta y su contenido han sido eliminados.
 */
export async function removeFolder(folderReference, recursive = true) {
  if (!folderReference) return
  const storage = Storage.getStorage()
  const folderRef = Storage.ref(storage, folderReference)
  const docs = await Storage.listAll(folderRef)
  for (const item of docs.items) await Storage.deleteObject(item)
  if (recursive)
    for (const folder of docs.prefixes) await removeFolder(folder.fullPath)
}

/**
 * Sube un avatar de usuario a Firebase Storage y elimina el anterior si existe.
 *
 * @param {File} fileObject - El objeto de archivo del avatar a subir.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de carga del avatar.
 */
export async function uploadAvatar(fileObject) {
  const fileName = 'avatar.' + fileObject.name.split('.').pop()
  await removeFile(store.state.user.avatarRef)
  return await uploadFile(
    fileObject,
    'user/' + store.state.user.id + '/' + fileName
  )
}

/**
 * Obtiene la URL de descarga de un archivo almacenado en Firebase Storage.
 *
 * @param {string} fileReference - La referencia del archivo en Firebase Storage.
 * @returns {Promise<string>} Una promesa que se resuelve con la URL de descarga del archivo.
 */
export async function getFileURL(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getDownloadURL(fileRef)
}

/**
 * Obtiene los metadatos de un archivo almacenado en Firebase Storage.
 *
 * @param {string} fileReference - La referencia del archivo en Firebase Storage.
 * @returns {Promise<Object>} Una promesa que se resuelve con los metadatos del archivo.
 */
export async function getFileMetadata(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getMetadata(fileRef)
}

/**
 * Obtiene un objeto Blob de un archivo almacenado en Firebase Storage.
 *
 * @param {string} fileReference - La referencia del archivo en Firebase Storage.
 * @returns {Promise<Blob>} Una promesa que se resuelve con el Blob del archivo.
 */
export async function getBlob(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getBlob(fileRef)
}
