<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.notificationShare.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.notificationShare.paragraph1') }}
    </p>
    <b
      ><h3>
        {{ $t('documentation.texts.notifications.notificationShare.viaLink') }}
      </h3></b
    >
    <p>
      {{ $t('documentation.texts.notifications.notificationShare.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationShare.paragraph3') }}
    </p>

    <b
      ><h3>
        {{ $t('documentation.texts.notifications.notificationShare.viaEmail') }}
      </h3></b
    >
    <p>
      {{ $t('documentation.texts.notifications.notificationShare.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic2Name" />
    <v-img style="width: 100%; max-width: 600px" contain :src="pic3Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationShare.paragraph5') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic4Name" />
    <v-img style="width: 100%; max-width: 600px" contain :src="pic5Name" />
  </v-col>
</template>

<script>
export default {
  computed: {
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationShare.picViaLink'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationShare.picViaEmailSingle'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationShare.picViaEmailSingleHistory'
        ) +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationShare.picViaEmailMultiple'
        ) +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationShare.picViaEmailMultipleHistory'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
