<template>
  <div>
    <v-card>
      <v-row class="ma-2">
        <v-col cols="3">
          <v-label class="ma-0 pa-0">{{
            $t('graphs.selectDates.title')
          }}</v-label>
        </v-col>
        <v-col cols="2" class="pa-0">
          <v-select
            class="d-flex justify-content pa-0 text-body-2"
            :label="$t('graphs.selectDates.title')"
            v-model="select"
            :items="items"
            persistent-hint
            return-object
            single-line
            item-text="title"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <div class="text-center">
            <v-btn
              data-v-step="103"
              rounded
              small
              :loading="loading"
              class="mx-2 secondary--text"
              color="primary"
              :disabled="loading"
              @click="loadData"
              justify="center"
            >
              {{ $t('graphs.btnApply') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-col cols="auto">
          <!--v-label style="font-size: 12px"-->
          <span style="color: grey; font-size: 14px" class="pr-1 mt-3">
            {{ $t('graphs.lastUpdate') }}
            {{ $d(lastUpdate, 'i18nDateAndHourSeconds') }}
            <!--/v-label-->
          </span>
          <!--span
            class="mdi mdi-refresh"
            style="margin-top: -6px; margin-left: 5px; color: #1d9ea4"
            @click="loadData"
          /-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                style="text-transform: none"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click.stop="loadData"
                :disabled="loading"
              >
                <v-icon v-if="!loading"> mdi-reload </v-icon>
                <v-icon v-else> mdi-autorenew mdi-spin </v-icon>
              </v-btn>
            </template>
            <div>
              {{ $t('refreshNow') }}
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  loadAllNotifications,
  loadStoreNotifications,
} from '@/services/analytics-notifications-service'

import { loadAllOperators } from '@/services/analytics-operators-service'

import { loadAllRootEmitters } from '@/services/analytics-emitter-service.js'
import { getOperatorsSubscriptionWithTraces } from '@/services/operator-service'
import { loadAllLicenseData } from '@/services/analytics-plan-service.js'

export default {
  components: {},
  props: {},
  data() {
    var to = moment().format('YYYYMMDD')
    var initialMonthsValue = -3
    return {
      dateTo: to,
      dateFrom: moment(to).add(initialMonthsValue, 'month').format('YYYYMMDD'),
      select: { title: this.$t('graphs.selectDates.3month'), value: -3 },
      items: [
        { title: this.$t('graphs.selectDates.3month'), value: -3 },
        { title: this.$t('graphs.selectDates.6month'), value: -6 },
        { title: this.$t('graphs.selectDates.9month'), value: -9 },
        { title: this.$t('graphs.selectDates.year'), value: -12 },
        { title: this.$t('graphs.selectDates.always'), value: 0 },
      ],
      allNotifications: [],
      rootEmitters: {},
      planData: {},
      loading: false,
      operatorsUnsubscribe: () => {},
      data: {
        dateFrom: this.dateFrom,
        notifications: [],
        operators: [],
      },
      lastUpdate: moment(),
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isOperatorUser', 'isAdminUser']),
  },
  methods: {
    async loadData() {
      this.loading = true

      if (this.select.value !== 0) {
        this.dateFrom = moment(this.dateTo)
          .add(this.select.value, 'month')
          .format('YYYYMMDD')
        this.dateFrom = this.dateFrom.substring(0, 6) + '01'
      } else {
        this.dateFrom = moment('20200101').format('YYYYMMDD')
      }
      // console.log('loadData() - this.dateFrom=' + this.dateFrom)
      await this.loadAll()
      this.data.notifications = [...this.allNotifications]
      this.data.operators = this.data.operators ?? []
      this.data.dateFrom = this.dateFrom
      this.data.rootEmitters = { ...this.rootEmitters }
      this.data.planData = { ...this.planData }
      this.$emit('data-change', this.data)
      this.loading = false
      this.lastUpdate = moment()
    },
    async loadAll() {
      if (this.select.value === -3) {
        this.allNotifications = await loadStoreNotifications()
      } else {
        this.allNotifications = await loadAllNotifications(this.isOperatorUser)
      }
      this.operatorsFromService = await loadAllOperators(this.operators)
      this.rootEmitters = await loadAllRootEmitters()
      this.planData = await loadAllLicenseData()
    },
  },
  async created() {
    await this.loadData()
  },
  async destroyed() {
    if (this.operatorsUnsubscribe) this.operatorsUnsubscribe()
  },
  async beforeMount() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id
    this.operatorsUnsubscribe = await getOperatorsSubscriptionWithTraces(
      userId,
      (operators) => {
        // console.log('GraphsFilter - Update operators ', operators)
        this.data.operators = operators
        this.$emit('data-change', this.data)
      },
      'logged',
      true
    )
  },
  watch: {
    '$i18n.locale': function () {
      //console.log('cambiado el idioma: ', this.$t('graphs.selectDates.3month'))
      this.items = [
        { title: this.$t('graphs.selectDates.3month'), value: -3 },
        { title: this.$t('graphs.selectDates.6month'), value: -6 },
        { title: this.$t('graphs.selectDates.9month'), value: -9 },
        { title: this.$t('graphs.selectDates.year'), value: -12 },
        { title: this.$t('graphs.selectDates.always'), value: 0 },
      ]
      //this.select = { title: this.$t('graphs.selectDates.3month'), value: -3 }
    },
  },
}
</script>
