<template>
  <v-col style="max-width: 900px">
    <h1 class="primary--text">
      {{ $t('documentation.texts.notifications.title') }}
    </h1>
    <h2 class="primary--text">
      {{ $t('documentation.texts.notifications.notificationList.title') }}
    </h2>
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph2') }}
    </p>
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph3') }}
    </p>
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 3000px" contain :src="pic2Name" />
    <p v-if="!isOperatorUser">
      {{ $t('documentation.texts.notifications.notificationList.paragraph5') }}
    </p>
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph6') }}
    </p>
    <p>
      {{ $t('documentation.texts.notifications.notificationList.paragraph7') }}
    </p>
    <v-img style="width: 100%; max-width: 600px" contain :src="pic3Name" />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isOperatorUser']),
    pic1Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationList.picSuperiorMenu'
        ) +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          this.isOperatorUser
            ? 'documentation.texts.notifications.notificationList.picNotificationDetailOperator'
            : 'documentation.texts.notifications.notificationList.picNotifDetail'
        ) +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/notifications/' +
        this.$t(
          'documentation.texts.notifications.notificationList.picUpdateNotif'
        ) +
        '.png')
    },
  },
  methods: {},
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
